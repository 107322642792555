import React, { useEffect, useMemo, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField'

//UnoButton
import UnoButton from '../../utils/UnoButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { unoTheme } from "../../../assets/styles/unoTheme";

//RadioGroup
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//BoostrapInput
import InputBase from '@material-ui/core/InputBase';
import MySelect from '../../utils/MySelect'
import MyRadioGroup from '../../utils/MyRadioGroup'
import MyDatePicker from '../../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Grid
import { Grid } from "@material-ui/core";

//API
import { listActivesInstituitions } from '../../../API/instituition'
import { createAccount, updateAccount, listClientAccounts, deleteAccount } from '../../../API/account'
import { getRegimes } from '../../../API/account_regime'

import CircularProgress from '@material-ui/core/CircularProgress';

import ConfirmActionRightContent from './ConfirmActionRightContent'

import moment from 'moment'
import MyInfo from '../../utils/MyInfo';
import { COD_NO_VINC_ACCOUNTS, getNextNoVincAccountNumber, NO_MORE_NO_VINC_ACCOUNTS, POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS } from '../../../controllers/AccountController';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    bottom: {

        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

}));

export default function NewAccount(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    //form            
    const [submitted, setSubmitted] = useState('');

    //CONTA CORRENTE
    const [instituitionSelected, setInstitutionSelected] = React.useState(0);
    const [noneInstituition, setNoneInstituition] = React.useState(false);
    const [instituitionDescription, setInstituitionDescription] = React.useState("");
    const [instituitionInactive, setInstituitionInactive] = React.useState(false);

    const [disableCreateAccount, setDisableCreateAccount] = useState(false);

    const handleChangeInstitutionSelected = (value) => {

        console.log('handleChangeInstitutionSelected: ', value);
        //Caso seja a instituição sem vínculo
        //Os campos de agencia e numero de contas são preenchidos com ZEROS e desabilitados
        //A instituição sem vínculo sempre será a última da lista
        if (activesInstituitions[value].cod == COD_NO_VINC_ACCOUNTS) {

            const nextNoVincAccountToCreate = getNextNoVincAccountNumber(content.clientAccounts);

            if (nextNoVincAccountToCreate == NO_MORE_NO_VINC_ACCOUNTS) {

                setDisableCreateAccount(true);
                setInputNumberAccount(nextNoVincAccountToCreate);
                setInputAgency(nextNoVincAccountToCreate);
                setNoneInstituition(true);

            } else {

                setDisableCreateAccount(false);
                setInputNumberAccount(nextNoVincAccountToCreate);
                setInputAgency(nextNoVincAccountToCreate);
                setNoneInstituition(true);

            }

        } else {
            if (noneInstituition) {
                setInputNumberAccount('');
                setInputAgency('');
                setNoneInstituition(false);
            }
        }



        setInstitutionSelected(value);
    };

    const [inputDateInit, setInputDateInit] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

    const handleChangeInputDateInit = (date) => {
        setInputDateInit(date);
    };

    const [activesInstituitions, setActiveInstituitions] = React.useState([]);
    const [regimes, setRegimes] = React.useState([]);
    const [accountRegime, setAccountRegime] = React.useState(1);

    //INPUTS CRIAÇÃO DE ACCOUNT
    const [inputAgency, setInputAgency] = useState("");

    const onChangeAgency = value => {
        setInputAgency(value);
    };

    const [inputNumberAccount, setInputNumberAccount] = useState("");
    const [prevNumberAccount, setPrevNumberAccount] = useState("");

    const onChangeNumberAccount = value => {
        setInputNumberAccount(value);
    };

    const handleChangeAccountRegime = (event) => {
        console.log("event.target.value: ", event.target.value)
        setAccountRegime(parseInt(event.target.value));
    };

    function orderByPriority(instituitions) {

        let firstOrderedList = [];
        let finalOrderedList = [];
        let others = [];
        console.log("Instituitions: ", instituitions);

        instituitions.forEach(element => {

            //BB
            if (element.cod == '001') {

                firstOrderedList[0] = element;

                //Caixa
            } else if (element.cod == '104') {

                firstOrderedList[1] = element;

                //BNB
            } else if (element.cod == '004') {

                firstOrderedList[2] = element;

                //Bradesco
            } else if (element.cod == '237') {

                firstOrderedList[3] = element;

                //Itau
            } else if (element.cod == '341') {

                firstOrderedList[4] = element;

                //Santander
            } else if (element.cod == '502') {

                firstOrderedList[5] = element;

            } else {
                others.push(element);
            }

        });

        for (let i = 0; i < firstOrderedList.length; i++) {

            const element = firstOrderedList[i];
            if (element) {

                finalOrderedList.push(element);

            }

        }

        //Colcando o 'sem vínculo' para último da lista
        others.push(others.shift());

        others.forEach(element => {

            finalOrderedList.push(element);

        });

        return finalOrderedList;

    }

    async function getActiveInstituitions(selectedIntituitionID) {

        console.log('selectedIntituitionID: ', selectedIntituitionID);
        const response = await listActivesInstituitions();

        if (response.success) {

            let instituitionMatch = false;
            let instituitionsArray = response.body.rows;

            //Colocando a primeira instituição como última 
            //A primeira é a 000 - SEM VÍNCULO
            //instituitionsArray.push(instituitionsArray.shift());            

            instituitionsArray = orderByPriority(instituitionsArray)
            setActiveInstituitions(instituitionsArray);


            if (selectedIntituitionID) {
                //No caso de ser um update selecionar a instituição                
                for (let i = 0; i < instituitionsArray.length; i++) {

                    if (instituitionsArray[i].id == selectedIntituitionID) {
                        if (i == (instituitionsArray.length - 1)) {
                            setInputNumberAccount('11111');
                            setInputAgency('11111');
                            setNoneInstituition(true);
                        }

                        console.log("INSTITUIITION SELETED: ", i);
                        instituitionMatch = true;
                        setInstitutionSelected(i);

                        setInstituitionDescription(instituitionsArray[i].description);
                        break;
                    }

                }
            }

            //Caso instituitionMatch seja false, a instituição foi inativada 
            if (selectedIntituitionID && !instituitionMatch) {

                setInstituitionInactive(true)
                setValidationMessage('A instituição relacionada a essa conta foi inativada')
            }



        } else {

            console.log("error: ", response.error)

        }


        const reponseRegimes = await getRegimes();
        if (reponseRegimes.success) {

            setRegimes(reponseRegimes.body.rows)

        } else {
            console.log("error: ", reponseRegimes.error)
        }

        setOpenBackdrop(false)
    }

    function loadInfos(data) {

        setInputAgency(data.agency);

        setInputNumberAccount(data.number_account);
        setPrevNumberAccount(data.number_account);

        setAccountRegime(data.account_regime_id);
        setInputDateInit(moment.utc(data.account_init))

    }

    async function doSaveAccount() {

        setValidationMessage('')
        setSubmitted(true);
        setOpenBackdrop(true)

        // if (inputAgency != ""
        //     && inputNumberAccount != "") {

        if (inputNumberAccount != "") {

            //Verificar se o número da conta é diferente de um dos números reservados
            if (prevNumberAccount !== inputNumberAccount
                && activesInstituitions[instituitionSelected].cod != "000"
                && POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS.some(el => el == inputNumberAccount)) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: "Os números de conta 1111-1, 2222-2 e 3333-3 são reservados a contas sem vínculos"
                }))

                setValidationMessage('')
                setSubmitted(false);
                setOpenBackdrop(false)
                return;
            }

            //Foi definido que não é necessária a informação da agência (Facilitar a criação de contas por importação da planilha)

            let acc = {
                instituition_id: activesInstituitions[instituitionSelected].id,
                client_id: clientOn.client.id,
                account_regime_id: accountRegime,
                agency: inputAgency,
                number_account: inputNumberAccount,
                account_init: moment.utc(inputDateInit).format('DD/MM/YYYY'),
                //acrescentando month_validation e year_validation para balance_validation
                //month_validation: parseInt(moment.utc(inputDateInit).format('MM')),
                //year_validation: parseInt(moment.utc(inputDateInit).format('YYYY')),
            }

            console.log("ACC: ", acc)
            if (content.update) {
                //inclusindo o id da conta a ser atualizada
                acc.id = content.data.id;

                const responseAccount = await updateAccount(acc);

                if (responseAccount.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Conta atualizada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao atualizar a conta: " + responseAccount.body.message
                    }))
                }

            } else {

                const responseAccount = await createAccount(acc);

                if (responseAccount.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Conta cadastrada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao cadastrar a conta: " + responseAccount.body.message
                    }))
                }
            }

            dispatch(allActions.rightContentActions.update())

        }

        setOpenBackdrop(false)




    }

    async function doDeleteAccount() {

        setValidationMessage('')
        setOpenBackdrop(true)

        //content contem data que contem a conta que foi aberta no componente lateral
        const responseAccount = await deleteAccount(content.data.id);

        if (responseAccount.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: responseAccount.body.message
            }))

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao exluir a conta: " + responseAccount.body.message
            }))
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update());

    }


    //deleção da conta
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteAccount();
    }

    useEffect(() => {

        console.log("DATA NEW ACCOUNT: ", content)
        if (clientOn && clientOn.client) {


            setOpenBackdrop(true);
            if (content.update) {

                getActiveInstituitions(content.data.instituition_id);
                loadInfos(content.data);

            } else {

                getActiveInstituitions();
                if (clientOn.client.portfolio_closed) {
                    let splittedDate = clientOn.client.portfolio_closed.split('/');
                    setInputDateInit(new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]) - 1, 1, 0, 0, 0, 0))
                } else {
                    setInputDateInit(moment.utc(clientOn.client.portfolio_init))
                }

            }

        }
    }, []);


    const rolesDisabled = useMemo(() => currentUser?.user?.isViewer, [currentUser]);

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?
                    <>
                        <div className={classes.header}>
                            <IconButton
                                className={classes.hiddenIcon}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>

                                    {content.update ? "Editar conta" : "Cadastrar conta"}

                                </Typography>

                            </div>


                            <IconButton
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>
                            <Grid container spacing={2}>

                                <Grid item lg={9} md={9} sm={9} xs={12} >

                                    {
                                        !instituitionInactive ?
                                            <MySelect
                                                disabled={rolesDisabled}
                                                outerlabel={'Instituição'}
                                                value={instituitionSelected}
                                                onChange={(e) => handleChangeInstitutionSelected(e.target.value)}
                                                options={activesInstituitions.map((row, index) => (
                                                    <option key={'instituition-' + row.id} value={index}>{row.description}</option>
                                                ))}
                                            />
                                            :
                                            <MyInfo
                                                outerlabel={'Instituição Inativa'}
                                                label={content && content.data && content.data.description}>

                                            </MyInfo>
                                    }

                                </Grid>

                                <Grid item xs={12} sm={3} md={3} lg={3}>

                                    <MyDatePicker
                                        disabled={rolesDisabled}
                                        className={classes.myDatePicker}
                                        views={["month", "year"]}
                                        margin="dense"
                                        id="date-picker-dialog"
                                        outerlabel="Posição inicial"
                                        format="MM/YYYY"
                                        value={inputDateInit}
                                        onChange={handleChangeInputDateInit}
                                        KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12} sm={12} className={classes.alignLeft}>
                                    <MyRadioGroup
                                        outerlabel={'Regime da conta'}
                                        row
                                        aria-label="regime" name="account_regime"
                                        value={accountRegime}
                                        onChange={handleChangeAccountRegime}
                                        options={regimes.map((row, index) => {
                                            const disabled = (!clientOn.client.portfolio_segregated
                                                && (row.id == 2)) || rolesDisabled;

                                            return (

                                                //Incluindo regra que deixa desabilitados os radios 
                                                //de Financeiro e Taxa de administração caso a carteira
                                                //seja segregada
                                                <FormControlLabel
                                                    disabled={disabled}
                                                    //&& (row.id == 2 || row.id == 3)}
                                                    value={row.id}
                                                    control={<Radio />}
                                                    label={
                                                        <span className={classes.itemRegime}>{row.description}</span>
                                                    } />
                                            )
                                        })}
                                    />

                                </Grid>



                                <Grid item lg={6} md={6} sm={12} xs={12} >
                                    <MyTextField
                                        id="agency"
                                        disabled={noneInstituition || rolesDisabled}
                                        size={'small'}
                                        outerlabel={'Agência'}
                                        value={inputAgency}
                                        onChange={onChangeAgency}
                                        variant="outlined"
                                        placeholder={'Insira a agência'}
                                        mask={["onlyNumbers"]} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} >
                                    <MyTextField
                                        id="accountNumber"
                                        disabled={noneInstituition || rolesDisabled}
                                        size={'small'}
                                        variant="outlined"
                                        outerlabel={'Número da conta'}
                                        value={inputNumberAccount}
                                        onChange={onChangeNumberAccount}
                                        validator={inputNumberAccount == "" && submitted ? true : undefined}
                                        helperText={"Insira o número da conta"}
                                        placeholder={'Insira o Número da conta'}
                                        mask={["onlyNumbers"]} />
                                </Grid>

                            </Grid>

                            <br />
                            <div className={classes.bottom}>

                                {
                                    validationMessage != '' ?
                                        <Typography className={classes.validationMessage}>
                                            {validationMessage}
                                        </Typography>
                                        : null

                                }


                                <div className={'bottomButtons'}>


                                    {!rolesDisabled && <UnoButton
                                        className={
                                            !content.update ? classes.hiddenIcon : null}
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir conta"}
                                    </UnoButton>}

                                    {
                                        !instituitionInactive && !rolesDisabled ?


                                            <UnoButton
                                                disabled={disableCreateAccount}
                                                onClick={() => doSaveAccount()}
                                                type={content.update ? 'success' : 'primary'}>
                                                {content.update ? "Atualizar conta" : "Criar conta"}
                                            </UnoButton>
                                            : null
                                    }

                                </div>



                            </div>

                        </div>
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                        a conta da instituição ${instituitionDescription} 
                        de número ${content.data.number_account} e agência ${content.data.agency ? content.data.agency : '-'}?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }


        </div>
    );
}



