import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


import { getArrayYears, applyMaskAmount, applyMaskAccount } from '../utils/utils';
import UnoButton from '../utils/UnoButton';
import MyInnerLoad from '../utils/MyInnerLoad';

import { useDebounceEffect } from '../utils/useDebounceEffect'
import { canvasPreview } from '../utils/canvasPreview'

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

//Upload Logo
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        },

        '& .titlesModal': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            '& span': {
                width: '50%',
            }
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    cropImages: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',

        '& .realImage': {
            background: "#eeeeee",
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& .cropImage': {
            paddingLeft: '16px',
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '& .logo': {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',

                '& span': {
                    marginBottom: '8px',
                    fontSize: '18px',
                }
            }
        }
    }


}));

export default function SelectAndCropImageModal(props) {

    const { open, imgSrc, handleAction } = props;

    const classes = useStyles();

    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    const [load, setLoad] = useState(false)

    const previewCanvasRef = useRef(null)
    const [aspect, setAspect] = useState(1)
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()
    const imgRef = useRef(null)
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    async function prepareToSendImage() {
        // setLoad(true);
        // const base64Url = await previewCanvasRef.current.toDataURL();
        // setLoad(true);
        handleAction(previewCanvasRef.current);
    }

    useEffect(() => {
        //setCrop(undefined) // Makes crop preview update between images.
    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => handleAction(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                <div className={'titlesModal'}>
                    <span>{'Selecione a área da imagem '}</span>
                    <span style={{ textAlign: 'center' }}>Resultado Logo</span>
                </div>

            </DialogTitle>

            <DialogContent
                className={classes.dialogContent}>
                <div className={classes.cropImages}>
                    <div className={'realImage'}>
                        {imgSrc ?
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={aspect}>
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop> : null
                        }
                    </div>

                    <div className={'cropImage'}>
                        <div className={'logo'}>
                            {completedCrop && (
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        //width: completedCrop.width,
                                        //height: completedCrop.height,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>

            </DialogContent>


            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() => {
                    setCrop(undefined) // Makes crop preview update between images.
                    handleAction(false)
                }
                } color="secondary">
                    {'Cancelar'}
                </UnoButton>

                <UnoButton
                    className={classes.spaceRight2}
                    onClick={() => prepareToSendImage()}
                    color="primary">
                    {'Confirmar'}
                </UnoButton>

            </DialogActions>

        </Dialog>
    );
}