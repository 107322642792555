/**
 * @typedef {'notifications' | 'pluggy_integration' | undefined} ScrollDialogType
 * @typedef {{
 *  open: boolean;
 *  data?: any;
 *  type: ScrollDialogType
 * }} ScrollDialog
 * @typedef {{
 *  type: 'SET_SCROLL_DIALOG';
 *  payload: ScrollDialog
 * }} ScrollDialogReducer 
 * @param {ScrollDialog} state
 * @param {ScrollDialogReducer} action
 * @returns 
 */
const scrollDialog = (state = {
    open: false,
    update: false,
}, action) => {
    switch (action.type) {
        case "SET_SCROLL_DIALOG":
            return action.payload
        default:
            return state
    }
}

export default scrollDialog;