import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Styles
import { makeStyles } from '@material-ui/core/styles';
import { headerSearch, tabs as tabsStyle, toogleButton, unoTheme } from '../../../../assets/styles/unoTheme';
// Utils
import moment from "moment";
import { roles } from "../../../utils/user.js";
import UnoButton from '../../../utils/UnoButton';
import { MyTab, MyTabs } from "../../../utils/MyTabs";
import { path } from '../../../utils/routesPaths';
// Routing
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
// Components
import ConfirmActionModal from "../../../modals/ConfirmActionModal";
import ResetPotfolioModal from "../../../modals/ResetPotfolioModal";
import ResetAssetsAccountsModal from "../../../modals/ResetAssetsAccountsModal";
import ResetTransactionsModal from "../../../modals/ResetTransactionsModal";
import NewClientScreen from '../NewClientScreen';
import ClientManagersScreen from './ClientManagersScreen';
import ClientSubManagersScreen from "./ClientSubManagersScreen";
import ClientCadprevScreen from "./ClientCadprevScreen";

import HistoryIcon from '@material-ui/icons/History';
import { getConsultingId } from "../../../utils/utils";
import ClientGesconScreen from "./ClientGesconScreen";
import { Box, Tooltip } from "@material-ui/core";
import ClientViewersScreen from "./Viewers";
import allActions from "../../../../actions/index.js";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

        '&:hover': {
            color: 'white',
            background: unoTheme.mainColor,
            '& > *': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                }

            }
        }

    },
    tabs: tabsStyle,
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center'
    },
    resetButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: `${theme.spacing(2)}px`
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },

    toogleButton: toogleButton,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },
}));

export default function DetailClientScreen({
    setShowMonths,
    setShowYears,
    locationOnScreen,
    setLocationOnScreen,
    setBackButton
}) {

    const dispatch = useDispatch();
    const classes = useStyles();

    const history = useHistory();
    const location = useLocation();

    const currentUser = useSelector(state => state.currentUser);

    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [tabs, setTabs] = useState([
        {
            title: clientOnScreen ? 'Editar cliente' : 'Novo cliente',
            path: path.administration.client.details.newClient,
            allowedRoles: [
                roles.superadmin.title,
                roles.admin.title,
                roles.advisor.title,
                roles.manager.title,
                roles.submanager.title
            ]
        },
        {
            title: 'Gestores',
            path: path.administration.client.details.managers,
            allowedRoles: [roles.superadmin.title, roles.admin.title, roles.advisor.title, roles.manager.title],
        },
        {
            title: 'Subgestores',
            path: path.administration.client.details.submanagers,
            allowedRoles: [roles.superadmin.title, roles.admin.title, roles.advisor.title, roles.manager.title]
        },
        {
            title: 'Visualizadores',
            path: path.administration.client.details.viewers,
            allowedRoles: [roles.superadmin.title, roles.admin.title, roles.advisor.title, roles.manager.title]
        },
        {
            title: 'CADPREV',
            path: path.administration.client.details.cadprev,
            allowedRoles: [roles.superadmin.title, roles.admin.title, roles.advisor.title],
            //justLEMA: true, //apenas a LEMA consultoria tem acesso
        },
        {
            title: 'GESCON',
            path: path.administration.client.details.gescon,
            allowedRoles: [roles.superadmin.title, roles.admin.title, roles.advisor.title],
            justLEMA: true, //apenas a LEMA consultoria tem acesso
        },
    ]);

    //Confirm action modal    
    const [openConfirmActionModal, setOpenConfirmActionModal] = useState(false);
    const [openResetClosedPortfolioModal, setOpenResetClosedPortfolioModal] = useState(false);
    const [openResetAssetsAccountsModal, setOpenResetAssetsAccountsModal] = useState(false);
    const [openResetTransactionsModal, setOpenResetTransactionsModal] = useState(false);
    //const [openConfirmActionModal2, setOpenConfirmActionModal2] = useState(false);

    const HOME_TAB = 0;
    const [tabValue, setTabValue] = useState(HOME_TAB);

    const [flagSaveClient, setFlagSaveClient] = React.useState(undefined);
    const [flagUpdateClient, setFlagUpdateClient] = React.useState(undefined);
    const [flagResetPortfolioClient, setFlagResetPortfolioClient] = React.useState(undefined);

    const redirectTab = (newTabValue) => {
        tabs.forEach((tab, index) => {
            if (newTabValue === index) {
                return history.push(tab.path);
            }
        });
    }

    const handleChangeTabValue = (event, newTabValue) => {
        setTabValue(newTabValue);
        redirectTab(newTabValue);
    };

    function handleActionResetPortfolio(action) {

        setOpenResetClosedPortfolioModal(false);

    }
    function handleAction(action) {

        setOpenConfirmActionModal(false);
        if (action) {
            setFlagResetPortfolioClient(clientOnScreen.id)
        }

    }

    function handleActionResetAssetsAccounts(action) {

        setOpenResetAssetsAccountsModal(false);

    }

    function handleActionResetTransactions(action) {

        setOpenResetTransactionsModal(false);

    }
    // function handleAction2(action) {

    //     setOpenConfirmActionModal2(false);
    //     if (action) {
    //         setFlagResetAssetsClient(clientOnScreen.id)
    //     }

    // };

    const filterTabsByCurrentUserRole = (client) => {
        return tabs.filter((tab, index) => {

            console.log("Clientxxx: ", client);
            if (index === 0) {
                tab.title = client ? 'Editar cliente' : 'Novo cliente';
            }
            let tabAllowedRolesLength = tab.allowedRoles.length;
            let isAllowedTab = false;

            for (let i = 0; i < tabAllowedRolesLength; i++) {
                let roleAllowedRoles = tab.allowedRoles[i];
                let isCurrentUserRoleAllowedRole = currentUser.user && roleAllowedRoles === currentUser.user.role;
                if (isCurrentUserRoleAllowedRole) {
                    i = tabAllowedRolesLength;
                    isAllowedTab = true;
                };
            };

            if (tab.justLEMA && getConsultingId(currentUser) != 1) {
                isAllowedTab = false;
            }

            if (isAllowedTab) return tab;
        });
    };

    function handleClientOnScreen() {
        if (tabValue === HOME_TAB && location && location.state) {
            setClientOnScreen(location.state.client);
            return location.state.client;
        };

        return null;
    };

    function handleBackButtonState() {
        const isCurrentUserRoleManager = currentUser.user && currentUser.user.role === roles.manager.title;
        let newBackButton = true;
        if (isCurrentUserRoleManager) newBackButton = false;
        setBackButton(newBackButton);
    };

    useEffect(() => {
        if ((location && !locationOnScreen)
            || (location && location.pathname != locationOnScreen.pathname)) {

            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            const client = handleClientOnScreen();

            let newTabs = filterTabsByCurrentUserRole(client ?? clientOnScreen);
            setTabs(newTabs);

            setShowMonths(false);
            setShowYears(false);
            handleBackButtonState();
            setLocationOnScreen(location);
        }
    }, [location]);

    const ResetBalancesButton = () => <Tooltip key="resetAssetsAndAccounts" arrow title='RESETAR ATIVOS/CONTAS'>
        <span>
            <UnoButton
                size='small'
                type="error"
                isIconButton
                removeDefaultLeftMargin
                myIcon={<HistoryIcon />}
                // onClick={() => setOpenConfirmActionModal2(true)}>
                onClick={() => setOpenResetAssetsAccountsModal(true)} />
        </span>
    </Tooltip>;

    const ResetTransactionsButton = () => <Tooltip key="resetTransactions" arrow title='RESETAR MOVIMENTAÇÕES'>
        <span>
            <UnoButton
                size='small'
                type="warning"
                isIconButton
                removeDefaultLeftMargin
                myIcon={<HistoryIcon />}
                onClick={() => setOpenResetTransactionsModal(true)} />
        </span>
    </Tooltip>;

    const ReopenPortfolioButton = () => <Tooltip key="resetClosedPortfolio" arrow title='FECHAMENTO'>
        <span>
            <UnoButton
                size='small'
                variant="contained"
                type="primary"
                isIconButton
                removeDefaultLeftMargin
                myIcon={<HistoryIcon />}
                onClick={() => setOpenResetClosedPortfolioModal(true)} />
        </span>
    </Tooltip>;

    const UpdateClientButton = () => <UnoButton
        size='small'
        className={classes.marginLeft}
        float='right'
        variant="contained"
        type="success"
        onClick={(e) => setFlagUpdateClient(e)}>
        Atualizar cliente
    </UnoButton>;

    console.log("currentUser: ", currentUser)
    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        {tabs ? tabs.map((tab, index) => {
                            return <MyTab id={index} label={tab.title} />
                        }) : null}
                    </MyTabs>

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>


                {/* Colocar botões */}

                {
                    tabValue === HOME_TAB ?
                        <>
                            {
                                clientOnScreen ?
                                    <div className={classes.rightSection}>
                                        <Box className={classes.resetButtonWrapper}>
                                            {currentUser && currentUser.user
                                                && (currentUser.user.isAdmin
                                                    || currentUser.user.isSuperAdmin) && <ResetBalancesButton />}
                                            {currentUser && currentUser.user
                                                && (currentUser.user.isAdmin
                                                    || currentUser.user.isSuperAdmin
                                                    || currentUser.isAdvisor) && <ResetTransactionsButton />}
                                            {
                                                clientOnScreen && clientOnScreen.portfolio_closed
                                                && currentUser && currentUser.user
                                                && (currentUser.user.isAdmin
                                                    || currentUser.user.isSuperAdmin
                                                    || currentUser.user.isAdvisor) && <ReopenPortfolioButton />}
                                        </Box>
                                        <UpdateClientButton />
                                    </div>
                                    :
                                    <UnoButton
                                        size={'small'}
                                        float={'right'}
                                        variant="contained"
                                        type="primary"
                                        onClick={(e) => setFlagSaveClient(e)}>
                                        {"Salvar cliente"}
                                    </UnoButton>
                            }

                        </>
                        : null
                }

            </div>

            <Switch className={classes.switch}>
                <Route path={path.administration.client.details.newClient}>

                    {/* Atualizando flag para dispparar useEffect na tela de NewClient e realizar as funções */}
                    <NewClientScreen
                        clientOnScreen={clientOnScreen}
                        flagSaveClient={flagSaveClient}
                        flagUpdateClient={flagUpdateClient}
                        flagResetPortfolioClient={flagResetPortfolioClient} />
                </Route>
                <Route path={path.administration.client.details.managers}>
                    <ClientManagersScreen clientOnScreen={clientOnScreen} />
                </Route>
                <Route path={path.administration.client.details.submanagers}>
                    <ClientSubManagersScreen clientOnScreen={clientOnScreen} />
                </Route>
                <Route path={path.administration.client.details.viewers}>
                    <ClientViewersScreen clientOnScreen={clientOnScreen} />
                </Route>
                <Route path={path.administration.client.details.cadprev}>
                    <ClientCadprevScreen clientOnScreen={clientOnScreen} />
                </Route>

                <Route path={path.administration.client.details.gescon}>
                    <ClientGesconScreen clientOnScreen={clientOnScreen} />
                </Route>
            </Switch>

            <ConfirmActionModal
                open={openConfirmActionModal}
                title={'Resetar Carteira'}
                message={'Tem certeza de que deseja resetar os fechamentos de carteira desse cliente?'}
                handleAction={handleAction}>
            </ConfirmActionModal>


            {/* <ConfirmActionModal
                open={openConfirmActionModal2}
                title={'Resetar Ativos e Contas'}
                message={'Tem certeza de que deseja resetar os ativos e contas da carteira desse cliente?'}
                handleAction={handleAction2}>
            </ConfirmActionModal> */}

            {
                openResetAssetsAccountsModal && clientOnScreen ?
                    <ResetAssetsAccountsModal
                        open={openResetAssetsAccountsModal}
                        clientOnScreen={clientOnScreen}
                        initDate={moment.utc(clientOnScreen.portfolio_init)}
                        portfolioClosed={clientOnScreen.portfolio_closed}
                        handleActionResetAssetsAccounts={handleActionResetAssetsAccounts}>

                    </ResetAssetsAccountsModal>
                    : null
            }

            {
                openResetTransactionsModal && clientOnScreen ?
                    <ResetTransactionsModal
                        open={openResetTransactionsModal}
                        clientOnScreen={clientOnScreen}
                        initDate={moment.utc(clientOnScreen.portfolio_init)}
                        portfolioClosed={clientOnScreen.portfolio_closed}
                        handleActionResetTransactions={handleActionResetTransactions}>

                    </ResetTransactionsModal>
                    : null
            }

            {
                openResetClosedPortfolioModal && clientOnScreen ?
                    <ResetPotfolioModal
                        open={openResetClosedPortfolioModal}
                        clientOnScreen={clientOnScreen}
                        initDate={moment.utc(clientOnScreen.portfolio_init)}
                        portfolioClosed={clientOnScreen.portfolio_closed}
                        handleActionResetPortfolio={handleActionResetPortfolio}>

                    </ResetPotfolioModal>
                    : null
            }

        </div>
    );
}



