import API from './config'

export const ArticleRulesAPI = {

    async create(articleRule) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post('/articles_rules/create', articleRule, config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR: ", error);

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }
    },

    async update(articleRule) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post('/articles_rules/update', articleRule, config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR: ", error);

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }
    },

    async delete(articleRuleId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.delete('/articles_rules/' + articleRuleId, config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR: ", error);

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }
    },

    async listAll() {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get('/articles_rules/listAll', config)

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            console.log("ERROR: ", error);

            return {
                success: false,
                body: error.response ? error.response.data : 'NO_DATA_ERROR'
            };
        }
    }



}

