import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

import { headerSearch, tabs, unoTheme } from '../../../assets/styles/unoTheme';

//Utils
import { applyMaskAmount, applyMaskAmountFreeDecimals, getFirstDayMonth, getLastDayInMonthByMonthAndYear } from "../../utils/utils";

import { MyTab, MyTabs } from "../../utils/MyTabs";

import { TableFooter, Tooltip } from "@material-ui/core";

import UnoButton from '../../utils/UnoButton';

//Api

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import moment from 'moment';
import { getAnaliticTitlesByPeriod, getDueDateTitle } from "../../../controllers/TitleController";
import { MARKING_MARKET } from "../../utils/paramsToTitles";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    arrow: {
        color: theme.palette.common.white,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },

    tabs: tabs,

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    smallTextsCells: {
        '& .MuiTableCell-root ': {
            fontSize: '0.9em',
        }
    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'unset',
            color: 'white',
            background: unoTheme.mainColor,
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            textWrap: 'nowrap'
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            whiteSpace: 'nowrap'
        }
    },

}));

export default function TitlesAnalyticScreen() {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const [clientOnScreen, setClientOnScreen] = useState(null);

    const [tabValue, setTabValue] = React.useState(0);

    //titles 
    const [allTitles, setAllTitles] = React.useState(null);
    const [titlesToShow, setTitlesToShow] = React.useState(null);
    const [totals, setTotals] = React.useState(null);
    const [totalsBySector, setTotalsBySector] = React.useState(null);

    const currentTabTotal = useMemo(() => totalsBySector ? tabValue === 1 ? totalsBySector.totalsPrivate : totalsBySector.totalsPublic : undefined, [tabValue, totalsBySector]);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);
        if (newValue == 1) {
            setTitlesToShow(allTitles?.filter(el => el.sector != 'PUBLICO'))
        } else {
            setTitlesToShow(allTitles?.filter(el => el.sector == 'PUBLICO'))
        }

    };

    async function loadTitles(client) {

        const { selectedPortfolioMonth, selectedPortfolioYear } = client;
        console.log('selectedPortfolioMonth: ', selectedPortfolioMonth)
        console.log('selectedPortfolioYear: ', selectedPortfolioMonth)
        const startDate = getFirstDayMonth(selectedPortfolioMonth, selectedPortfolioYear);
        const endDate = getLastDayInMonthByMonthAndYear(selectedPortfolioMonth, selectedPortfolioYear);

        //const { allTitles, totals, finalTotalsPublic, finalTotalsPrivate } = await getAnaliticTitles(client.id, startDate, endDate, selectedPortfolioMonth, selectedPortfolioYear);

        const { allTitles, totalsPublic, totalsPrivate, totals } = await getAnaliticTitlesByPeriod(client.id, startDate, endDate, selectedPortfolioMonth, selectedPortfolioYear);

        if (allTitles) {
            setAllTitles(allTitles);
            setTitlesToShow(allTitles.filter(el => el.sector == 'PUBLICO'));
            setTotalsBySector({
                totalsPublic,
                totalsPrivate
            });
            setTotals(totals);
        };
    };

    useEffect(() => {
        handleChangeTabValue(null, 0);
    }, [totals]);

    useEffect(() => {

        console.log("Chamando title");
        if (clientOn
            && clientOn.client
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

            loadTitles(clientOn.client);
        }

    }, [clientOn]);

    const currentSector = useMemo(() => tabValue === 0 ? 'Públicos' : 'Privados', [tabValue]);

    const getCurrentPu = useCallback((row) => {

        row.balance_now = row.balance_now ? parseFloat(row.balance_now) : null;
        row.quota_amount_now = row.quota_amount_now ? parseFloat(row.quota_amount_now) : null;
        return row.balance_now && row.quota_amount_now ?
            applyMaskAmountFreeDecimals(row.balance_now / row.quota_amount_now, null, 4) : applyMaskAmount(0, true)
    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Públicos" />
                        <MyTab label="Privados" />

                    </MyTabs>

                </div>
                <div className={classes.rightHeader}>
                    {
                        allTitles ?
                            <UnoButton
                                isIconButton
                                onClick={() => dispatch(allActions.reportLoadActions.setReportLoad({
                                    show: true,
                                    reportType: 'myTitles',
                                    message: 'Gerando o relatório...',
                                }))}
                                size='small'
                                type='primary'
                            />
                            : null
                    }

                </div>
            </div>

            <div class={'defaultScrollWithHeaderContent'}>

                <MyTable
                    stickyHeader
                    size="small">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col width="13%" />
                        <col width="13%" />
                        <col width="17%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="left">Título</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="center">Venc.</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="center">Compra</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="center">Qtd</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">P.U. Compra</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">P.U. Atual</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="center">Marcação</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">Valor Compra</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">Valor Atual</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} align="right">Retorno</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {titlesToShow && titlesToShow.map((row, index) => (
                            <TableRow key={'title-analisys-' + index} className={classes.smallTextsCells}>
                                <TableCell align="left">{row.title_name}</TableCell>
                                <TableCell align="center">{moment.utc(getDueDateTitle(row)).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="center">{moment.utc(row.purchase_date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="center">{row.quantity_purchased}</TableCell>
                                {/* <TableCell align="right">{row.purchase_pu ? applyMaskAmountFreeDecimals(row.purchase_pu, null, 4) : '-'}</TableCell> */}
                                <TableCell align="right">{row.price_title ? applyMaskAmountFreeDecimals(row.price_title, null, 4) : '-'}</TableCell>
                                <TableCell align="right">{getCurrentPu(row)}</TableCell>
                                <TableCell align="center">{
                                    <>
                                        <p style={{ margin: '0px' }}>{row.marking}</p>
                                        <p style={{ margin: '0px' }}>{(row.pre_tax && row.marking != MARKING_MARKET ? applyMaskAmount(row.pre_tax) + "%" : '')}</p>
                                    </>
                                }</TableCell>
                                <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{
                                    applyMaskAmount(row.price_title * row.quantity_purchased, true)}
                                </TableCell>
                                <TableCell align="right" className={classes.green}>{row.balance_now ? applyMaskAmount(parseFloat(row.balance_now), true) : applyMaskAmount(0, true)}</TableCell>
                                <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>{applyMaskAmount(row.rentMoneyPeriod, true) + " ( " + applyMaskAmount(row.rentPeriod) + "%" + " )"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    {
                        totals && currentTabTotal ?
                            <TableFooter className={classes.myTableFooter}>
                                <TableRow key='footer-t-titles-sector-analysis-total' className="totalLineInvestiments">
                                    <TableCell align="left">Total Títulos {currentSector}</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(currentTabTotal.purchaseAmount, true)}</span></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(currentTabTotal.currentAmount, true)}</span></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(currentTabTotal.rentMoneyPeriod, true) + " ( " + applyMaskAmount(currentTabTotal.rentPeriod) + "% )"}</span></TableCell>
                                </TableRow>
                                <TableRow className='totalLinePL' key={'footer-t-titles-analisys-total'}>
                                    <TableCell align="left">Total Global</TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(totals.purchaseAmount, true)}</span></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(totals.currentAmount, true)}</span></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(totals.rentMoneyPeriod, true) + " ( " + applyMaskAmount(totals.rentPeriod) + "% )"}</span></TableCell>
                                </TableRow>
                            </TableFooter> : null
                    }
                </MyTable>

            </div>


        </div >
    );
}



