import { saveLogAction } from "../API/log_action"

/**
 * Salva a ação de reset portfolio
 * @param {int} userId
 * @param {int} clientId
 * @param {Date} toDate  
 * @param {Date} portfolioClosed  --data em que atualmente está fechada a carteira do cliente
 */
export async function saveLogResetPortfolio(userId, clientId, toDate, portfolioClosed) {

    //Caso seja nulo envia-se nulo para o banco e na exibição indica-e que foi um reset total da carteira
    if (toDate) {

        let splitted = toDate.split('/');
        toDate = new Date(splitted[1], parseInt(splitted[0]) - 1, 1);

    }

    if (portfolioClosed) {
        let splitted = portfolioClosed.split('/');
        portfolioClosed = new Date(splitted[1], parseInt(splitted[0]) - 1, 1);
    }

    let log_action = {
        type_action_id: 1, //RESET_PORTFOLIO
        user_id: userId,
        client_id: clientId,
        to_date_reset: toDate,
        from_date_reset: portfolioClosed,
    }

    console.log("DATE TO SAVE: ", log_action);
    let response = await saveLogAction(log_action);

    return response;

}

/**
 * Salva a ação de reset assets e accounts
 * @param {int} userId
 * @param {int} clientId
 */
export async function saveLogResetAssetsAndAccounts(userId, clientId, toDate) {

    let log_action = {
        type_action_id: 2, //RESET_ASSETS_AND_ACCOUNTS
        user_id: userId,
        client_id: clientId,
        to_date_reset: toDate,
    }

    let response = await saveLogAction(log_action);

    return response;

}

/**
 * Salva a ação de reset de movimentações
 * @param {int} userId
 * @param {int} clientId
 * @param {string} toDate
 */
export async function saveLogResetTransactions(userId, clientId, toDate) {

    let log_action = {
        type_action_id: 3, //RESET_TRANSACTIONS
        user_id: userId,
        client_id: clientId,
        to_date_reset: toDate,
    }

    let response = await saveLogAction(log_action);

    return response;

}

/**
 * Salva a ação lançamento de carteira
 * @param {int} userId
 * @param {int} clientId
 * @param {Date} launchDate
 */
export async function saveLogPortfolioLaunch(userId, clientId, launchDate) {

    let log_action = {
        type_action_id: 4, //PORTFOLIO_LAUNCH
        user_id: userId,
        client_id: clientId,
        portfolio_date_launch: launchDate,
    }

    let response = await saveLogAction(log_action);

    return response;

}