import API from './config'

export async function getAllCertifications() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/certifications/getAllCertifications', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

