import React, { useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";

import ptBrLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment';

import "moment/locale/pt-br";

moment.locale('pt-BR');

class UTCUtils extends MomentUtils {

    format(value, formatString) {
        return moment(value)
            .utc()
            .format(formatString);
    }

}

/*
* Mask options
* [onlyNumbers] - apenas números podem ser inseridos no campo
*/

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': {
            marginTop: '11px !important',
        },
        '& .MuiInputBase-inputMarginDense': {
            paddingBottom: '7px',
        }
    },
    verySmall: {
        '& .MuiTextField-root': {
            margin: '0px !important',
            width: '110px',
        },
        '& .MuiInputBase-inputMarginDense': {
            margin: '0px !important',
            paddingBottom: '3px !important',
            fontSize: '14px',
        },
        '& .MuiIconButton-root': {
            padding: '3px !important'
        }
    },
    disabled: {
        background: '#eeeeee'
    },
    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },

    label: myLabelInput,
    noMarginTop: {
        marginTop: '5px'
    }


}));

export default function MyTextField(props) {

    const {
        outerlabel,
        verysmall,
        validator,
        helperText,
    } = props

    const classes = useStyles();

    return (
        <div className={[
            classes.root,
            verysmall ? classes.verySmall : ""
        ].join(' ')}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={classes.label}>
                        {outerlabel}
                    </Typography> : null
            }
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}> */}
            <MuiPickersUtilsProvider moment={moment} utils={UTCUtils} locale={'pt-br'}>
                <KeyboardDatePicker
                    {...props}
                    error={validator}
                    helperText={validator ? helperText : ""}
                />
            </MuiPickersUtilsProvider>
        </div>


    )
}