import React, { useEffect, useState, useCallback, createRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import _, { set } from 'lodash';

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { unoTheme, tabs, headerSearch, toogleButton } from '../../../assets/styles/unoTheme'

import { MyTabs, MyTab } from "../../utils/MyTabs";
import { verifyClientPortfolioIsSgregated } from "../../utils/utils";

import ValidationFundsScreen from "./ValidationFundsScreen";
import ValidationTitlesScreen from "./ValidationTitlesScreen";
import ValidationAccountsScreen from "./ValidationAccountsScreen";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import MyToggleSegregationButton from "../../utils/MyToggleSegregationButton";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    tabs: tabs,

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },
    toogleButton: toogleButton,



}));

export default function BalancesValidationScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const [tabValue, setTabValue] = React.useState(0);

    const clientOn = useSelector(state => state.clientOn)

    const [formats, setFormats] = React.useState(null);

    const handleFormat = (event, newFormats) => {

        console.log("NEW FORMAT: ", newFormats)

        switch (newFormats) {

            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;

        }

        setFormats(newFormats);
    };


    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push('/transactions/balancesValidation/funds')
        } else if (newValue == 1) {
            history.push('/transactions/balancesValidation/titles')
        } else if (newValue == 2) {
            history.push('/transactions/balancesValidation/accounts')
        }

    };

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={classes.tabs}>

                    {/* <div className={classes.contentTabs}> */}
                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        <MyTab label="Fundos" />
                        <MyTab label="Títulos" />
                        <MyTab label="Contas" />

                    </MyTabs>

                    {/* </div> */}



                    {/* <div className={classes.bottomTabs}></div> */}

                </div>

                <MyToggleSegregationButton
                    value={formats}
                    handleValue={handleFormat}
                    //disabled={innerLoad.show}
                    segregated={verifyClientPortfolioIsSgregated(clientOn)}
                />

            </div>




            <Switch className={classes.switch}>
                <Route
                    path="/transactions/balancesValidation/funds">
                    <ValidationFundsScreen regime={formats} />
                </Route>
                <Route
                    path="/transactions/balancesValidation/titles">
                    <ValidationTitlesScreen regime={formats} />
                </Route>
                <Route
                    path="/transactions/balancesValidation/accounts">
                    <ValidationAccountsScreen regime={formats} />
                </Route>
            </Switch>



        </div>
    );
}



