// Styles
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { headerSearch } from '../../../assets/styles/unoTheme';
import MyTable from '../../utils/MyTable';
import { Pie } from 'react-chartjs-2';
import { applyMaskAmount, formatDataToPieChartPerSizeRightContent, getConsultingId } from "../../utils/utils";
import UnoButton from '../../utils/UnoButton'
import PrintIcon from '@material-ui/icons/Print';

// Data
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import allActions from "../../../actions";
import { getRankingFundsPerClient } from '../../../API/fund';
import { listAllClients } from '../../../API/client';

// Stylesheet
const useStyles = makeStyles({
    // General Styles
    root: {
        display: 'flex',
        flexDirection: 'column'
    },

    // Header
    headerSearch,

    // Table
    clientCell: {
        width: '125px',
        textAlign: 'center',
        padding: 0
    },

    // Chart Styles
    main: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        borderRadius: '10px 0px 0px 10px',
        backgroundColor: 'white',
    },
    divTable: {
        width: '60%',
        maxWidth: '60%',
        height: '100%',

        '& table': {


            '& thead': {
                '& tr': {
                    cursor: 'pointer',
                }
            }
        },

        '& .MuiTableRow-root .MuiTableCell-sizeSmall:last-child': {
            paddingRight: '12px',
        }
    },
    divChart: {
        width: '40%',
        maxWidth: '40%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

});

export default function FundsRanking() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const chartRef = useRef();

    const currentUser = useSelector(state => state.currentUser)
    const [rankingFunds, setRankingFunds] = useState(null);
    const [dataChart, setDataChart] = useState({});

    const [totalClientsNumber, setTotalClientsNumber] = useState(null)

    // Chart.js Config
    const options = {
        legend: false,
        onClick: (evt, elements) => {
            const data = dataChart.datasets[0].rankingFunds.data;
            let clickedCanvasSlice = elements[0]?._index;

            const getClickedSliceData = data.filter((fundData, index) => index === clickedCanvasSlice)[0];

            dispatch(allActions.rightContentActions.setRightContent({
                path: 'clientsFromFund',
                data: getClickedSliceData[0],
            }))
        },
        onHover: (evt, elements) => {
            const section = elements[0];
            const currentStyle = evt.currentTarget.style;
            currentStyle.cursor = section ? 'pointer' : 'default';
        }
    };

    async function doGetRankingFunds() {
        const response = await getRankingFundsPerClient(getConsultingId(currentUser));
        //const responseVigentFunds = await getRankingFundsPerClientByPeriod();

        if (response.success && response.body) {
            console.log('response.body.rows: ', response.body.rows);
            setRankingFunds(response.body.rows);
        } else {
            console.error("RESPONSE ERROR: ", response.error);
        }
    }

    async function doGetTotalClientsNumber() {
        const response = await listAllClients();

        if (response.success && response.body) {
            setTotalClientsNumber(response.body.rowCount)
        } else {
            console.error("RESPONSE ERROR: ", response.error);
        }
    }

    function doReport() {
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'fundsRanking',
            message: 'Gerando relatório...'
        }))
    }

    useEffect(() => {
        if (!rankingFunds) {
            doGetRankingFunds();
        } else {
            setDataChart(formatDataToPieChartPerSizeRightContent(rankingFunds, 10));
        }
        if (!totalClientsNumber) {
            doGetTotalClientsNumber()
        }
    }, [rankingFunds]);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>
                <Typography variant="h6" gutterBottom align="left">
                    Ranking de fundos mais utilizados pelos clientes
                </Typography>
                <UnoButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    size={'small'}
                    onClick={() => doReport()}
                    type='primary'
                    startIcon={<PrintIcon />}>
                    {"Gerar relatório"}
                </UnoButton>
            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.main}>
                    {/* <div className={classes.divTable}> */}
                    <MyTable
                        stickyHeader
                        nofixed={true}
                        size="small"
                        selectable="true"
                    >
                        <colgroup>
                            <col width="12.5%" />
                            <col width="12.5%" />
                            <col width="25%" />
                            <col width="35%" />
                            <col width="15%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.clientCell} align="left">QTD</TableCell>
                                {/* <TableCell className={classes.clientCell} align="left">Vigentes</TableCell> */}
                                <TableCell className={classes.clientCell} align="left">% do Total</TableCell>
                                <TableCell className={classes.clientCell} align="left">CNPJ</TableCell>
                                <TableCell align="left">Fundo</TableCell>
                                {/* <TableCell className={classes.clientCell} align="center">{rankingFunds && rankingFunds[0] && rankingFunds[0].resolution_name}</TableCell> */}
                                {/* <TableCell className={classes.clientCell} align="right">Total Invest.</TableCell> */}
                                <TableCell className={classes.clientCell} align="center">Benchmark</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rankingFunds && rankingFunds.map((row, index) => (
                                <TableRow
                                    key={'ranking-funds-clients-' + index}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'clientsFromFund',
                                        data: row,
                                    }))}>
                                    <TableCell className={classes.clientCell} align="left">{row.number_of_clients}</TableCell>
                                    {/* <TableCell className={classes.clientCell} align="left">{row.number_of_clients}</TableCell> */}
                                    <TableCell className={classes.clientCell} align="left">{applyMaskAmount((row.number_of_clients / totalClientsNumber) * 100) + '%'}</TableCell>
                                    <TableCell className={classes.clientCell} align="left">{row.fund_cnpj}</TableCell>
                                    <TableCell align="left">{row.fund_name}</TableCell>
                                    {/* <TableCell align="center">{row.device_abbreviation}</TableCell> */}
                                    {/* <TableCell align="right">{'-'}</TableCell> */}
                                    <TableCell align="center">{row.benchmark}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </MyTable>
                    {/* </div> */}
                    {/* <div className={classes.divChart}>
                        <Pie data={dataChart} options={options} ref={chartRef} />
                    </div> */}

                </div>
            </div>
        </div >
    );
}
