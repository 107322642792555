import { makeStyles } from "@material-ui/core";

export const unoTheme = {
    mainColor: '#0049C6',
    hoverMainColor: '#0B59DE',
    hoverMainButtonColor: '#003897',
    menuContent: '#EBEEF2',
    iconMenuColor: '#768191',
    //secondaryColor: 'rgba(12,32,67,0.7)',
    secondaryColor: '#9EA6B2',
    thirdColor: '#768191',
    defaultTextColor: '#768191',
    defaultInputBackground: '#F8FAFC',
    borderInputColor: '#E4E7EB',
    //hoverTable: '#eeeeee',
    hoverTable: '#F3F5F8',

    secondPrimaryButtonColor: '#F2F4FB',
    secondErrorButtonColor: '#FFEBEB',

    //tables
    secondaryTuplaTable: '#F8FAFC',
    greenMoney: '#08C21B',
    redMoney: '#FF2A2A',

    //buttons
    successColor: "#08C21B",

    warningColor: "#FAC72E",
    warningColorStrong: "#D35400",

    errorColor: "#FF2A2A",
    errorHoverColor: "#CB1F1F",

    mainGrayColor: "#DCDCDC",

    //chartColors
    chartColors: [
        "#15EC8F",
        "#6C5DD3",
        "#2749F1",
        "#21B9F8",
        "#9C27B0",
        "#FFCE73",
        "#C5D336",
        "#3F51B5",
        "#E96439",
        "#FA065E",
        "#FF2A2A",
        "#00BCD4",
        "#8BC34A",
        "#4CAF50",
        "#009688",
        "#FFA2C0",
        "#FF9800",
        "#FFEB3B",
        "#795548",
        "#768191",
        "#b3c0a5",
        "#01d129",
        "#1f7734",
        "#fa2cbd",
        "#ccfe75",
        "#469dc8",
        "#c85a7b",
        "#f768eb",
        "#71fcdb",
        "#d6caef",
        "#0e4773",
        "#871425",
        "#d407dd",
        "#5bff36",
        "#6d9f1a",
        "#6406c9",
        "#17093d",
        "#4d1c92",
        "#ab7b22",
        "#b72d4e",
    ],

    chartColorsCompareFunds: [
        "#2749F1",
        "#9627B0",
        "#FFEB3B",
        "#FFA2C0",
        "#FF9800"
    ],

    suitabilityColorConservador: '#15EC8F',
    suitabilityColorModerado: '#00BCD4',
    suitabilityColorAgressivo: '#FF9800',

    topToolbarTableSize: '72px',

    topToolbarSize: '66px',

    //distancia do bottom do fab button
    bottomFab: '96px',

    heightInput: '26px',

}

// TODO [REFACTOR]: Criar alguma estrutura para agrupar estilos e botões, utilizando o próprio theme do material, por exemplo
export const unoThemeStyles = makeStyles((theme) => ({
    fabAdd: {
        color: 'white !important',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor + ' !important',
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
}));

export const useColors = makeStyles((theme) => ({
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
}));

//Component styles
export const myInput = {
    width: "100%",
    borderRadius: '6px',
    '& .MuiInputBase-input': {
        height: unoTheme.heightInput,
        borderRadius: '6px !important',
        fontSize: '14px',
        padding: '6px 18px',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '6px !important',
        background: unoTheme.defaultInputBackground,
    },
    '& .MuiFormHelperText-root': {
        marginLeft: '0px',
    },
    '& .MuiInputBase-multiline': {
        '& .MuiInputBase-input': {
            borderRadius: '6px !important',
            padding: '0px',
        },
    }
}

export const myLabelInput = {
    fontSize: '14px',
    fontWeight: 400,
    color: unoTheme.defaultTextColor, //defaultTextColor
    textTransform: 'uppercase',
    marginTop: '0px',
}

export const tabs = {
    height: '72px',
    minHeight: '72px',
    width: '100%',
    display: 'flex',
    background: unoTheme.menuContent,
    flexDirection: 'column',
    textAlign: 'center',

    '& .MuiTabs-root': {
        minHeight: '72px',

        '& .MuiTabs-fixed': {
            display: 'flex',
        }
    },

}

export const headerSearch = {
    minHeight: unoTheme.topToolbarTableSize,
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',

    '& .MuiTypography-root': {
        marginBottom: '0px',
        fontSize: '16px',
        fontWeight: '500',
        color: unoTheme.mainColor,
    },

    '& .groupHorizontalFlex': {
        display: 'flex',
        alignItems: 'center',
    },

    //RESPONSIVE_MOBILE_CODE
    '@media(max-width: 768px)': {
        minHeight: '10% !important',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
}


export const toogleButton = {

    background: 'white',
    height: '38px',
    borderRadius: '30px',

    '& *': {
        borderRadius: '30px',
    },

    // '& .MuiButtonBase-root': {
    //     borderRadius: '30px',
    // },
    // '& .MuiButtonBase-root:last-child': {
    //     borderRadius: '0px 30px 30px 0px',
    // },

    '& .MuiButtonBase-root': {
        border: 'none !important',
        background: 'white',
        fontSize: '0.9em',
        margin: '0px 2px',
        padding: '8px 16px 8px 16px',
        transaction: 'all .3s ease',
        color: '#000000',
        textTransform: 'none !important',
        minWidth: '90px',

        '& .MuiToggleButton-label': {
            whiteSpace: 'nowrap',
        }
    },

    '& .MuiButtonBase-root:hover': {
        borderRadius: '30px',
        background: unoTheme.secondaryTuplaTable,
    },

    '& .Mui-selected': {
        color: 'white',
        borderRadius: '30px',
        background: unoTheme.mainColor,
        boxShadow: '0 0 0 1px rgba(0,0,0,.25)',
    },

    '& .Mui-selected:hover': {
        background: unoTheme.hoverMainButtonColor,
    }
}


