const setMainAlert = (mainAlertObj) => {
    return {
        type: "SET_MAIN_ALERT",
        payload: mainAlertObj
    }
}


export default {
    setMainAlert
}