import userActions from './userActions'
import clientActions from './clientActions'
import mainAlertActions from './mainAlertActions'
import fullLoadActions from './fullLoadActions'
import innerLoadActions from './innerLoadActions'
import reportLoadActions from './reportLoadActions'
import rightContentActions from './rightContentActions'
import notificationActions from './notificationActions';
import scrollDialogActions from './scrollDialogActions';

const allActions = {
    userActions,
    clientActions,
    mainAlertActions,
    fullLoadActions,
    innerLoadActions,
    reportLoadActions,
    rightContentActions,
    notificationActions,
    scrollDialogActions
}

export default allActions