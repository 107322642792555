import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyButton from '../utils/MyButton'
import MyTextField from '../utils/MyTextField'
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

//Native select
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';

import MySelect from '../utils/MySelect'

//API
import { createNewLegislationArticle, updateLegislationArticle } from '../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions';
import { calendarFormat } from 'moment';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    spaceRight2: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    }

}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function NewLegislationArticleModal({ open, handleModal, legislationSegments, legislationArticle }) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const [registerNewArticle, setRegisterNewArticle] = useState("");

    const [inputLegislationSegmentArticle, setInputLegislationSegmentArticle] = useState(0);
    const [inputAssetArticle, setInputAssetArticle] = useState("");
    const [inputDeviceArticle, setInputDeviceArticle] = useState("");
    const [inputLimitArticle, setInputLimitArticle] = useState("");
    const [inputDescriptionArticle, setInputDescriptionArticle] = useState("");

    function handleLegislationSegmentArticle(event) {
        console.log(event.target.value)
        // console.log(legislationSegments)
        setInputLegislationSegmentArticle(event.target.value)
    }

    function handleAssetArticle(value) {
        setInputAssetArticle(value)
    }
    function handleDeviceArticle(value) {
        setInputDeviceArticle(value)
    }
    function handleLimitArticle(value) {
        setInputLimitArticle(value)
    }
    function handleDescriptionArticle(value) {
        setInputDescriptionArticle(value)
    }

    useEffect(() => {

        // if (clientOn && descriptionsApr.length == 0 && transaction) {
        //     loadInfos(clientOn.client.id, transaction);
        // }

        // if (descriptionsApr.length != 0 && transaction) {

        //     initInfos(transaction);
        // }        


        if (legislationArticle) {
            setRegisterNewArticle(false);
            loadInfos(legislationArticle);
        } else {
            setRegisterNewArticle(true);
        }


    }, [clientOn, legislationArticle])

    function doHandleClose(update) {
        handleModal(update);
        cleanForm();
    }

    const [submittedNewLegislationArticle, setSubmittedNewLegislationArticle] = useState(false);

    async function doSaveNewLegislationArticle() {

        setSubmittedNewLegislationArticle(true);

        if (inputLegislationSegmentArticle != undefined
            && inputAssetArticle != ""
            && inputDeviceArticle != ""
            && inputLimitArticle != ""
            && inputDescriptionArticle != "") {

            // legislation_segment_id INT NOT NULL,
            // asset VARCHAR(150),
            // device VARCHAR(150),    
            // limit_resolution DECIMAL,
            // description VARCHAR(1024),

            let newLegislationArticle = {
                legislation_segment_id: legislationSegments[inputLegislationSegmentArticle].id,
                asset: inputAssetArticle,
                device: inputDeviceArticle,
                limit_resolution: parseFloat(inputLimitArticle),
                description: inputDescriptionArticle,
            }

            console.log("ARTICLE TO SAVE: ", newLegislationArticle)

            let response = await createNewLegislationArticle(newLegislationArticle);
            if (response.success) {

                doHandleClose(true);
                cleanForm()
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'Artigo de resolução cadastrado com sucesso'
                }))
            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao cadastrar artigo de resolução'
                }))
            }

        }


    }

    function loadInfos(article) {

        setInputLegislationSegmentArticle(article.legislation_segment_id - 1);
        setInputAssetArticle(article.asset);
        setInputDeviceArticle(article.device);
        setInputLimitArticle(article.limit_resolution);
        setInputDescriptionArticle(article.description);
    }

    function cleanForm() {
        setInputLegislationSegmentArticle(0);
        setInputAssetArticle("");
        setInputDeviceArticle("");
        setInputLimitArticle("");
        setInputDescriptionArticle("");
    }

    async function doUpdateArticle() {
        setSubmittedNewLegislationArticle(true);

        if (inputLegislationSegmentArticle != undefined
            && inputAssetArticle != ""
            && inputDeviceArticle != ""
            && inputLimitArticle != ""
            && inputDescriptionArticle != "") {

            // legislation_segment_id INT NOT NULL,
            // asset VARCHAR(150),
            // device VARCHAR(150),    
            // limit_resolution DECIMAL,
            // description VARCHAR(1024),

            let newLegislationArticle = {
                legislation_segment_id: legislationSegments[inputLegislationSegmentArticle].id,
                asset: inputAssetArticle,
                device: inputDeviceArticle,
                limit_resolution: inputLimitArticle,
                description: inputDescriptionArticle,
            }

            console.log("ARTICLE ID: ", legislationArticle.id)
            console.log("ARTICLE TO SAVE: ", newLegislationArticle)

            let response = await updateLegislationArticle(legislationArticle.id, newLegislationArticle);
            if (response.success) {

                doHandleClose(true);
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'Artigo de resolução atualizado com sucesso'
                }))
            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao atualizar artigo de resolução'
                }))
            }

        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle
                className={classes.dialogTitleApr}
                id="dialog-title-apr">
                {
                    !registerNewArticle ?
                        "Editar artigo de resolução" : "Cadastrar novo artigo de resolução"
                }
            </DialogTitle>
            <DialogContent
                className={classes.dialogContent}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputAssetArticle}
                            // defaultValue={inputAssetArticle}
                            onChange={handleAssetArticle}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Tipo de ativo'}
                            type="text"
                            validator={inputAssetArticle == "" && submittedNewLegislationArticle}
                            placeholder="Insira o nome do ativo"
                            helperText="O nome do ativo deve ser preenchido"
                            id="inputAssetArticle"
                            fullWidth />
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDeviceArticle}
                            onChange={handleDeviceArticle}
                            // defaultValue={inputDeviceArticle}
                            // onBlur={handleDeviceArticle}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Dispositivo'}
                            placeholder="Insira o dispositivo"
                            validator={inputDeviceArticle == "" && submittedNewLegislationArticle}
                            helperText="O dispositivo deve ser preenchido"
                            type="text"
                            id="inputDeviceArticle"
                            fullWidth />
                    </Grid>


                    <Grid item lg={3} md={3} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputLimitArticle}
                            onChange={handleLimitArticle}
                            // defaultValue={inputLimitArticle}
                            // onBlur={handleLimitArticle}
                            variant="outlined"
                            margin="dense"
                            align='right'
                            outerlabel={'Limite Res. CMN nº 3.922'}
                            placeholder="Insira o limite"
                            validator={inputLimitArticle == "" && submittedNewLegislationArticle}
                            helperText="O limite deve ser preenchido"
                            type="text"
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            id="inputLimitArticle"
                            fullWidth />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        {/* <FormControl
                            className={classes.formControl}
                            focused={true}>
                            <InputLabel id="select-label-new-legislation-article">Segmento da legislação</InputLabel>
                            {
                                legislationSegments ?
                                    <NativeSelect id="select-native-new-legislation-article"
                                        value={inputLegislationSegmentArticle}
                                        onChange={handleLegislationSegmentArticle}
                                        input={<BootstrapInput />}>
                                        {legislationSegments.map((row, index) => (
                                            <option key={'segment-select-' + index} value={index}>{row.description}</option>
                                        ))}
                                    </NativeSelect> : null
                            }

                        </FormControl> */}
                        <MySelect
                            outerlabel={'Segmento da legislação'}
                            value={inputLegislationSegmentArticle}
                            onChange={handleLegislationSegmentArticle}
                            options={legislationSegments.map((row, index) => (
                                <option key={'segment-select-' + index} value={index}>{row.description}</option>
                            ))}
                        />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDescriptionArticle}
                            onChange={handleDescriptionArticle}
                            // defaultValue={inputDescriptionArticle}
                            // onBlur={handleDescriptionArticle}
                            variant="outlined"
                            multiline={true}
                            rows={8}
                            rowsMax={8}
                            margin="dense"
                            outerlabel={'Descrição do artigo de resolução'}
                            placeholder="Insira a descrição da resolução"
                            validator={inputDescriptionArticle == "" && submittedNewLegislationArticle}
                            helperText="A descrição deve ser preenchida"
                            type="text"
                            id="inputDescriptionArticle"
                            fullWidth />
                    </Grid>


                </Grid>

            </DialogContent>
            {
                registerNewArticle ?
                    <DialogActions className={classes.actionsFlex}>

                        <MyButton onClick={() =>
                            doHandleClose(false)} color="secondary">
                            Cancelar
                        </MyButton>
                        <div>
                            <MyButton
                                className={classes.spaceRight2}
                                onClick={() => doSaveNewLegislationArticle()} color="primary">
                                Salvar
                            </MyButton>
                        </div>
                    </DialogActions>
                    :
                    <DialogActions className={classes.actionsFlex}>

                        <MyButton onClick={() =>
                            doHandleClose()} color="secondary">
                            Fechar
                        </MyButton>
                        <div>
                            <MyButton
                                className={classes.spaceRight2}
                                type={'success'}
                                onClick={() => doUpdateArticle()}>
                                Atualizar
                            </MyButton>
                        </div>
                    </DialogActions>

            }

        </Dialog >
    );
}