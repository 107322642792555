import React from 'react';
import Button from '@material-ui/core/Button';
import { green, deepOrange } from '@material-ui/core/colors';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/core/styles';
import { unoTheme } from '../../assets/styles/unoTheme'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 16px",
        borderRadius: '20px !important',
        whiteSpace: 'nowrap',
        fontWeight: 600,
    },

    //Sizes
    verySmall: {
        padding: "4px 8px !important",

        '& .MuiButton-label': {
            fontSize: "10px !important",
        }
    },

    myIconButton: {
        padding: theme.spacing(1),
        marginLeft: ({ removeDefaultLeftMargin }) => removeDefaultLeftMargin ? undefined : theme.spacing(2),
        borderRadius: '50% !important',
        // background: theme.palette.primary.main,
        // color: theme.palette.getContrastText(theme.palette.primary.main),
        // '&:hover': {
        //     background: theme.palette.primary.dark,
        // }
    },
    //margins
    mRight: {
        marginRight: '16px',
    },
    mLeft: {
        marginLeft: '16px',
    },
    default: {
        color: unoTheme.defaultTextColor,
        fontWeight: 600,
    },

    defaultClean: {
        color: unoTheme.defaultTextColor,
        fontWeight: 600,
        border: 'solid 1px ' + unoTheme.defaultTextColor
    },

    primary: {
        color: "white",
        borderRadius: '6px',
        border: 'unset',
        backgroundColor: unoTheme.mainColor,
        '&:hover': {
            backgroundColor: unoTheme.hoverMainButtonColor,
        },

        '&.Mui-disabled': {
            background: '#ccc',
        }


    },

    success: {
        color: "white",
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        '&.Mui-disabled': {
            background: '#ccc',
        }
    },
    successClean: {
        color: green[500],
        //fontWeight: 600,
    },
    primaryClean: {
        color: unoTheme.mainColor,
        fontWeight: 600,
        border: 'solid 1px ' + unoTheme.mainColor
    },
    warning: {
        color: "white",
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
        '&.Mui-disabled': {
            background: '#ccc',
        }
    },
    error: {
        color: "white",
        backgroundColor: unoTheme.errorColor,
        '&:hover': {
            backgroundColor: unoTheme.errorHoverColor,
        },
    },
    errorClean: {
        color: unoTheme.errorColor,
        fontWeight: 600,
        border: 'solid 1px ' + unoTheme.errorColor
    },

    right: {
        float: 'right',
    },
}));

export default function UnoButton(props) {

    const {
        type,
        float,
        className,
        isIconButton,
        myIcon,
        margin
    } = props
    const classes = useStyles(props);

    return (
        <>
            {isIconButton ?
                <IconButton
                    {...props}
                    className={
                        [
                            classes.myIconButton,
                            type ? classes[type] : null,
                            float ? classes[float] : null,
                            className,
                        ].join(' ')
                    }
                //className={classes.reportButton}
                >
                    {
                        myIcon ?
                            myIcon
                            :
                            <PrintIcon />
                    }
                </IconButton>
                :
                <Button
                    disableElevation
                    {...props}
                    className={
                        [
                            classes.root,
                            type ? classes[type] : null,
                            float ? classes[float] : null,
                            margin ? classes[margin] : null,
                            props.size ? classes[props.size] : null,
                            className,
                        ].join(' ')
                    } />
            }
        </>
    );
}