export function disponibilizationDicionary(disp) {

    if (!disp) {
        return {
            label: '-'
        };
    }

    //console.log('DISP: ', disp);

    if (disp.includes('a partir da data de encerramento do Fundo')) {
        return {
            tip: disp,
            label: disp.substring(0, 3)
        };
    } else if (disp.includes('vide regulamento')) {
        return {
            tip: disp,
            label: 'VR'
        }
    } else if (disp.includes('Data de encerramento do Fundo')) {
        return {
            tip: disp,
            label: 'EF'
        }
    }
    else {
        return {
            label: disp
        };
    }

}