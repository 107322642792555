import React, { useEffect, useRef, useState } from "react";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { unoTheme, myLabelInput } from "../../assets/styles/unoTheme";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

//pagination
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { IconButton, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    rootTable: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%', //Verificar se não ocasionará problema
    },
    root: {

        tableLayout: 'fixed',
        borderRadius: '10px',

        '& .MuiTableRow-root': {

            borderRadius: '10px',

            '&:nth-of-type(odd)': {
                backgroundColor: unoTheme.secondaryTuplaTable,
                //backgroundColor: 'red',
            },

            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
                //padding: '3px 12px 3px 8px !important',
            },

            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            //Fazendo a tupla ficar arredondada

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },

            '& .MuiTableCell-root.noPaddingCell': {
                padding: '0px',
            },

            '& .MuiTableCell-root.highlighted': {
                //background: '#e67e22',
                background: unoTheme.mainColor,
                borderBottom: 'solid 0.5px #ffffff',
                color: 'white',
            },

            // tr: first - child td: first - child { border- top - left - radius: 10px; }
            // tr: first - child td: last - child { border - top - right - radius: 10px; }
            // tr: last - child td: first - child { border - bottom - left - radius: 10px; }
            // tr: last - child td: last - child { border - bottom - right - radius: 10px; }

            '& .MuiIconButton-root': {
                padding: '0px',
            },

        },

        '& .divisor-table-1': {
            '& > *': {
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: unoTheme.mainColor,
            }
        },

        '& .divisor-table-2': {
            '& > *': {
                fontWeight: 'bold',
                backgroundColor: unoTheme.mainGrayColor,
            }

        },

        '& .MuiTableRow-head': {

            '& .MuiTableCell-root': {
                background: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
                borderRadius: '0px !important',
            },
        },

    },

    noFixed: {
        tableLayout: 'auto !important',
    },

    //sizeHeaders
    verySmall: {

        '& .MuiTableCell-root': {
            padding: '5px 8px 5px 8px !important',

        },

        '& .MuiTableRow-head': {

            '& .MuiTableCell-root': {
                background: 'white',
                lineHeight: 'inherit',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '10px',
                padding: '8px 8px 8px 8px',
                borderRadius: '0px !important',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },

        // '& .MuiTableCell-sizeSmall': {
        //     paddingRight: '8px',
        // },

    },
    small: {

        '& .MuiTableCell-root': {
            padding: '6px 8px 6px 8px',
        },

        '& .MuiTableRow-head': {

            '& .MuiTableCell-root': {
                background: 'white',
                lineHeight: 'inherit',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '8px 8px 8px 8px',
                borderRadius: '0px !important',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },

        '& .MuiTableCell-sizeSmall:last-child': {
            paddingRight: '8px',
        },
    },

    selectable: {
        '& .MuiTableRow-root': {
            cursor: "pointer",
            '&:hover': {
                background: unoTheme.hoverTable,
            },
        },
    },


    tableContainerDefault: {
        borderRadius: '10px',
        paddingBottom: '16px',
        background: 'none', //verificar se não vai afetar outras coisas
    },

    tableContainer: {
        padding: '0px 18px',
        borderRadius: '10px',
        paddingBottom: '16px',
        height: '100%',
        background: 'none', //verificar se não vai afetar outras coisas
    },

    noScroll: {
        overflow: 'unset',
    },

    heightAuto: {
        height: 'auto !important',
    },

    myTablePagination: {

        padding: '4px 32px 4px 16px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',

        borderTop: 'solid 0.5px #efefef',

        '& .labelsPagination': {
            fontSize: '14px',
            paddingRight: '16px',
        }

    },

    infosBlack: {
        '& .MuiTableRow-root': {
            cursor: 'pointer',
            '& > *': {
                borderBottom: 'unset',
                padding: '6px 16px 6px 16px !important',
                textTransform: 'uppercase',
                color: 'black',
                fontWeight: 500,
                fontSize: '14px',
            },
        }


    },

    withBorderColumn: {

        '& .MuiTableBody-root': {
            '& .MuiTableRow-root': {

                '& .MuiTableCell-root': {
                    borderRight: 'solid 1px #efefef',
                    paddingRight: '16px',
                    cursor: 'pointer',

                    '&:hover': {
                        background: unoTheme.hoverTable,
                    }
                },

            },
        },



        '& .MuiTableRow-head': {

            '& .MuiTableCell-root': {
                borderRight: 'solid 1px #efefef',
                paddingRight: '16px',
            },

        },

    },

}));

export default function MyTextField(props) {

    const classes = useStyles();

    const {
        selectable,
        onScroll,
        customclass,
        pagination,
        itemspage,
        totalitems,
        handlePage,
        sizeheaders,
        nofixed,
        verysmallheads,
        infosBlack,
        noscroll,
        currentPage,
        withBorderColumn,
        heightAuto,
        initSizeTable,
        setCurrentSizeTable
    } = props

    const [scrollTop, setScrollTop] = useState(0);
    const containerRef = useRef(null);

    function onScrollTable(e) {

        if (containerRef?.current && initSizeTable && setCurrentSizeTable) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

            //somando 50 para disparar método um pouco antes de 
            const isAtBottom = scrollTop + clientHeight + 150 >= scrollHeight;
            const isAtTop = e.target.scrollTop === 0;

            if (isAtBottom) {
                // Você atingiu o final do scroll
                setCurrentSizeTable((prev) => prev + initSizeTable);
            } else if (isAtTop) {
                setCurrentSizeTable(initSizeTable);
            }
        }

        if (e.target.scrollTop < scrollTop) {
            onScroll(true);
        } else {
            onScroll(false);
        }

        setScrollTop(e.target.scrollTop);

    }

    const [firstItemPage, setFirstItemPage] = React.useState(1);

    function handleChangePage(action) {

        console.log("FirstItemPage: ", firstItemPage);

        if (action == 'up') {
            setFirstItemPage(firstItemPage + itemspage);
            handlePage(1);

        } else {
            setFirstItemPage(firstItemPage - itemspage);
            handlePage(-1);
        }

    }

    useEffect(() => {

        if (currentPage) {
            setFirstItemPage(1 + ((currentPage - 1) * itemspage));
        }

    }, [currentPage])

    return (
        <div className={classes.rootTable}>
            <TableContainer ref={containerRef} className={[
                customclass ? classes.tableContainerDefault : classes.tableContainer,
                selectable == "true" || selectable ? classes.selectable : null,
                noscroll ? classes.noScroll : null,
                heightAuto ? classes.heightAuto : null,
            ].join(' ')
            } component={Paper} elevation={0}
                onScroll={onScroll ? (e) => onScrollTable(e) : null}>
                <Table className={[
                    customclass ? customclass : classes.root,
                    classes[sizeheaders],
                    nofixed ? classes.noFixed : "",
                    infosBlack ? classes.infosBlack : "",
                    withBorderColumn ? classes.withBorderColumn : ""
                ].join(' ')
                } {...props}>

                </Table>
            </TableContainer>
            {
                pagination ?
                    <div className={classes.myTablePagination}>

                        <Typography
                            className={'labelsPagination'}
                            variant={'h6'}>

                            {/* {`${firstItemPage} - ${firstItemPage + (itemspage - 1) <= totalitems ? firstItemPage + (itemspage - 1) : totalitems} de ${totalitems}`} */}
                            <div>
                                <span className="firstItemPagePagination">{firstItemPage}</span> - <span className="totalItemsPagePagination">{firstItemPage + (itemspage - 1) <= totalitems ? firstItemPage + (itemspage - 1) : totalitems}</span> de <span className="totalItemsPagination">{totalitems}</span>
                            </div>


                        </Typography>

                        <IconButton
                            size={'small'}
                            disabled={firstItemPage == 1}
                            onClick={() => handleChangePage('down')}>
                            <ArrowBackIcon />
                        </IconButton>

                        <IconButton size={'small'}
                            disabled={firstItemPage + (itemspage - 1) >= totalitems}
                            onClick={() => handleChangePage('up')}>
                            <ArrowForwardIcon />
                        </IconButton>

                    </div>
                    :
                    null
            }

        </div>

    )
}