export const reportTypes = {
    dashboard: { cod: 'dashboard', label: 'Dashboard', },
    portfolio: { cod: 'portfolio', label: 'Carteira' },
    provisional: { cod: 'provisional', label: 'Provisório' },
    distribuition: { cod: 'distribuition', label: 'Distribuição' },
    articles: { cod: 'articles', label: 'Enquadramentos' },
    targets: { cod: 'targets', label: 'Metas' },
    myFunds: { cod: 'myFunds', label: 'Fundos' },
    assetsAndAccounts: { cod: 'assetsAndAccounts', label: 'Ativos e Contas' },
    transactionsInPeriod: { cod: 'transactionsInPeriod', label: 'Movimentações por período' },
    myTitles: { cod: 'myTitles', label: 'Análise de títulos' },
    risk: { cod: 'risk', label: 'Risco' },
};