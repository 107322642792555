//MenuIcons
import InstituitionsIcon from '@material-ui/icons/AccountBalance';
import PortfolioIcon from '@material-ui/icons/AccountBalanceWallet';
import ArticlesIcon from '@material-ui/icons/Assignment';
import AdivisorsIcon from '@material-ui/icons/AssignmentInd';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import LegislationSegmentsIcon from '@material-ui/icons/ChromeReaderMode';
import LogsIcon from '@material-ui/icons/Code';
import InflationRatesIcon from '@material-ui/icons/CollectionsBookmark';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ValidationIcon from '@material-ui/icons/LocalAtm';
import ClientsIcon from '@material-ui/icons/People';
import DistribuitionIcon from '@material-ui/icons/PieChart';
import TransactionsIcon from '@material-ui/icons/PlaylistAdd';
import ReturnsIcon from '@material-ui/icons/Timeline';
import FundsIcon from '@material-ui/icons/Toc';
import TitlesIcon from '@material-ui/icons/ChromeReaderMode';
import TargetsIcon from '@material-ui/icons/TrackChanges';
import IcomeIcon from '@material-ui/icons/TrendingUp';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BuildIcon from '@material-ui/icons/Build';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React from 'react';
import { path } from '../utils/routesPaths';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { roles } from '../utils/user';

export default {
  // backOfficeMenu: [
  //     {
  //         name: 'Clientes',
  //         path: '/clients',
  //         icon: <ClientsIcon />,
  //         selected: true,
  //     },
  //     {
  //         name: 'Consultores',
  //         path: '/advisors',
  //         icon: <AdivisorsIcon />
  //     }
  // ],
  investimentMenu: [
    {
      name: 'Rentabilidade',
      path: '/investiments/income',
      icon: <IcomeIcon />,
      justPortfolioOn: true,
      roles: [
        'ROLE_ADMIN',
        'ROLE_ADVISOR',
        'ROLE_MANAGER',
        'ROLE_SUBMANAGER',
        roles.viewer.title,
        'ROLE_SUPER_ADMIN'
      ],
      selected: true,
    },
    {
      name: 'Carteira',
      path: '/portfolio/assets',
      icon: <PortfolioIcon />,
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', , 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      //selected: true,
    },
    {
      name: 'Coleta de Saldos',
      path: '/transactions/balancesCollect',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_SUPER_ADMIN'],
      specificClientsIds: [444, 473, 275, 477], //Nova andradina //Vilhena //Fazenda Rio branco
      specificUsersIds: [659, 665], //Gestora da Sra. FRANCIELLE ELGITA DE OLIVEIRA COSTA do RPPS de Guanhães/MG
      icon: <TransactionsIcon />
    },
    {
      //name: 'Validação de Saldos',
      name: 'Ativos e Contas',
      path: '/transactions/balancesValidation/funds',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      icon: <ValidationIcon />
    },
    {
      name: 'Metas',
      path: '/investiments/targets',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      icon: <TargetsIcon />
    },
  ],
  analyticsMenu: [

    {
      name: 'Retornos da carteira',
      path: '/analysis/returns',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      icon: <ShowChartIcon />
    },
    {
      name: 'Fundos',
      path: '/analysis/funds/my',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      icon: <FundsIcon />
    },
    {
      name: 'Títulos',
      path: '/analysis/titles/public',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN', roles.viewer.title,],
      icon: <TitlesIcon />
    },
    {
      name: 'Comparador de fundos',
      path: '/analysis/compareFunds',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN'],
      icon: <MultilineChartIcon />
    },
    {
      name: 'Distribuição',
      path: '/analysis/distribuition',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN', roles.viewer.title,],
      icon: <DistribuitionIcon />
    },
    {
      name: 'Enquadramentos',
      //path: '/analysis/articles/fixed',
      path: '/analysis/articles/list',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', roles.viewer.title, 'ROLE_SUPER_ADMIN'],
      icon: <ArticlesIcon />
    },
    {
      name: 'Riscos',
      path: '/analysis/risk',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER'],
      icon: <ReturnsIcon />
    },

  ],

  registersMenu: [
    // {
    //     name: 'Coleta de Saldos',
    //     path: '/collectionOfBalances',
    //     icon: <CollectionOfBalancesIcon />
    // },
    {
      name: 'Clientes',
      path: path.administration.clients.root,
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN'],
      icon: <ClientsIcon />,
    },
    {
      name: 'Status dos clientes',
      path: path.registers.statusClients.root,
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_SUPER_ADMIN'],
      icon: <BlurLinearIcon />,
    },

    {
      name: 'Informações Atuariais',
      path: path.registers.actuarysClients.root,
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_SUPER_ADMIN'],
      icon: <TrackChangesIcon />,
    },

    {
      name: 'Instituições',
      path: '/administration/instituitions',
      //roles: ['ROLE_ADMIN', 'ROLE_ADVISOR'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <InstituitionsIcon />
    },
    {
      name: 'Segmentos de resolução',
      path: '/administration/admin_legislation_segments',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <LegislationSegmentsIcon />
    },
    {
      name: 'Artigos de resolução',
      //path: '/administration/admin_legislation_articles/fixed',
      path: '/administration/admin_legislation_articles/list',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <ArticlesIcon />
    },
    {
      name: 'Regras de enquadramentos',
      path: '/administration/admin_articles_rules',
      roles: ['ROLE_SUPER_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <ListAltIcon />
    },

    // {
    //     name: 'Anbima',
    //     path: '/administration/anbima',
    //     icon: <FundsIcon />,
    //     roles: ['ROLE_ADMIN'],
    // }
  ],

  administrativeMenu: [
    {
      name: 'Arquivos e Documentos',
      path: '/administrative/filesAndDocuments',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN', roles.viewer.title],
      icon: <FileCopyIcon />
    },
    {
      name: 'Demonstrativos',
      path: path.administrative.demonstratives.funds,
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN',],
      icon: <AssignmentIcon />
    },
    {
      name: 'Ferramentas',
      path: '/administrative/tools',
      roles: ['ROLE_ADMIN', 'ROLE_ADVISOR', 'ROLE_MANAGER', 'ROLE_SUBMANAGER', 'ROLE_SUPER_ADMIN'],
      icon: <BuildIcon />
    },
    {
      name: 'Contábil',
      path: '/administrative/accounting',
      roles: ['ROLE_SUPER_ADMIN'],
      specificClientsIds: [482], //Maracaju
      icon: <ReceiptIcon />
    },

  ],

  backofficeMenu: [
    {
      name: 'Consultores',
      path: '/administration/advisors',
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
      icon: <AdivisorsIcon />
    },
    {
      name: 'Indices de Inflação',
      path: '/administration/inflation_rates',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <InflationRatesIcon />
    },
    {
      name: 'Fundos e Títulos',
      path: path.administration.funds.root,
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <FundsIcon />
    },
    {
      name: 'Benchmarks',
      path: '/administration/localBenchmarks',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <CompareArrowsIcon />
    },
    {
      name: 'Logs',
      path: '/administration/logs/clientUpdates',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN'],
      icon: <LogsIcon />
    },
    {
      name: 'Rentabilidades dos clientes',
      path: path.administration.clientsRents.root,
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
      icon: <EqualizerIcon />
    },
    {
      name: 'Ranking de Fundos',
      path: '/administration/fundsRanking',
      //roles: ['ROLE_ADMIN'],
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'],
      icon: <BubbleChartIcon />
    },

    // {
    //   name: 'Busca por Conta',
    //   path: '/administration/searchAccounts',
    //   roles: ['ROLE_ADMIN'],
    //   icon: <ConfirmationNumberIcon />
    // },
  ]

};