import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//Tables
import MyTable from '../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//redux
import MyDatePicker from '../utils/MyDatePicker'

//Utils
import {
    portfolioClosedToDate,
    applyMaskAccount,
    applyMaskAmountFreeDecimals, applyMaskAmount, isDateBeforePortfolioClosed,
    formatAnbimaDateToPt, getRentMonthAsset, getInMonthRentFundInPortfolio, getHistoricRentFundInPortfolio
} from '../utils/utils'

import { useDispatch, useSelector } from "react-redux";

//Controllers
import { saveLogResetAssetsAndAccounts } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    labelTopModal: {
        float: 'right',
        fontSize: '14px',
        color: unoTheme.defaultTextColor
    },

    infoRentHistoric: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        fontWeight: 'bold',
        fontSize: '16px',
        '& div': {
            marginLeft: '16px',
            background: '#eeeeee',
            padding: '4px 12px',
            borderRadius: '8px',
        },
        '& span': {
            fontSize: '12px',
            marginRight: '8px',
        }
    }




}));

export default function QuotizationAssetModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { open, asset,
        handleCloseQuotizationModal } = props;

    useEffect(() => {

        if (asset) {
            console.log("arrayToAverageQuotas: ", asset.arrayToAverageQuotas);
        }
        // console.log("Years: ", years);


    }, [open])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            onClose={() => handleCloseQuotizationModal()}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Cotização"}
                {
                    asset && asset.asset_init && asset.quotas_anbima && asset.arrayToAverageQuotas ?
                        <div className={classes.infoRentHistoric}>
                            <div>
                                <span>Fundo no mês [ COMDINHEIRO ]: </span>{
                                    applyMaskAmountFreeDecimals(getRentMonthAsset(asset), null, 6) + '%'
                                }
                            </div>
                            <div>
                                <span>Fundo na carteira no mês: </span>{
                                    applyMaskAmountFreeDecimals(getInMonthRentFundInPortfolio(asset), null, 6) + '%'
                                    // applyMaskAmountFreeDecimals(((asset.quotas_anbima[asset.quotas_anbima.length - 1].valor_cota
                                    //     /
                                    //     asset.arrayToAverageQuotas[asset.arrayToAverageQuotas.length - 1].averageQuotaInMonth) - 1) * 100, null, 6) + '%'
                                }
                            </div>
                            <div>

                                {/* ((asset.quotas_anbima[asset.quotas_anbima.length - 1].valor_cota
                                /
                                asset.arrayToAverageQuotas[asset.arrayToAverageQuotas.length - 1].averageQuota) - 1) * 100, null, 6 */}
                                <span>Histórica [ {moment.utc(asset.asset_init).format('DD/MM/YYYY')} ]: </span>{
                                    applyMaskAmountFreeDecimals(getHistoricRentFundInPortfolio(asset), null, 6) + '%'
                                }
                            </div>
                        </div>
                        : null
                }

            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                <MyTable
                    style={{ marginTop: '8px' }}
                    stickyHeader
                    sizeheaders="verySmall"
                    size="small">
                    {/* <colgroup>
                                        <col width="30%" />
                                        <col width="30%" />
                                        <col width="40%" />
                                    </colgroup> */}

                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center">Operação</TableCell>
                            <TableCell align="right">QTD. COTAS</TableCell>
                            <TableCell align="right">Saldo Cotas</TableCell>
                            <TableCell align="right">Cota média histórica</TableCell>
                            <TableCell align="right">Cota média no mês</TableCell>
                            <TableCell align="right">Valor cota diária</TableCell>
                            <TableCell align="right">Movimento</TableCell>
                        </TableRow>
                    </TableHead>

                    {
                        asset && asset.arrayToAverageQuotas ?

                            <TableBody>
                                {
                                    asset.arrayToAverageQuotas.map((row, index) => (


                                        <TableRow className={classes.detailRow}
                                            key={'detail-average-quota-' + index}>

                                            <TableCell align="center">
                                                {row.dateQuota ? formatAnbimaDateToPt(row.dateQuota) : 'Início'}
                                            </TableCell>

                                            <TableCell align="center">
                                                {row.operation ? (row.operation === 1 ? 'APP' : 'RES') : '-'}
                                            </TableCell>

                                            <TableCell align="right">
                                                {row.operation ? (row.operation === 1 ? applyMaskAmountFreeDecimals(row.qtdQuotaApplications, null, 7) : applyMaskAmountFreeDecimals(row.qtdQuotaRescues, null, 7)) : '-'}
                                            </TableCell>

                                            <TableCell align="right">
                                                {applyMaskAmount(row.balanceQuotas, null)}
                                            </TableCell>

                                            {/* Cota média histórica */}
                                            <TableCell align="right">
                                                {applyMaskAmountFreeDecimals(row.averageQuota, true, 8)}
                                            </TableCell>

                                            {/* Cota média mês */}
                                            <TableCell align="right">
                                                {
                                                    applyMaskAmountFreeDecimals(row.averageQuotaInMonth, true, 8)

                                                }
                                            </TableCell>

                                            <TableCell align="right" className={classes.blue}>
                                                {row.valueQuota ? applyMaskAmountFreeDecimals(row.valueQuota, true, 8) : '-'}
                                            </TableCell>

                                            <TableCell align="right" className={
                                                row.operation == 1 ? classes.green : (
                                                    row.operation == 2 ? classes.red : classes.blue
                                                )
                                            }>
                                                {

                                                    row.operation ? (
                                                        row.valueQuota !== null && row.operation == 1 && row.qtdQuotaApplications !== null ?
                                                            applyMaskAmount(row.valueQuota * row.qtdQuotaApplications, true)
                                                            :
                                                            applyMaskAmount(row.valueQuota * row.qtdQuotaRescues, true)
                                                    ) : '-'


                                                }
                                            </TableCell>


                                        </TableRow>



                                    ))

                                }
                            </TableBody>
                            : null
                    }

                </MyTable>


            </DialogContent>


            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() =>
                    handleCloseQuotizationModal()} color="secondary">
                    {'Fechar'}
                </UnoButton>



            </DialogActions>





        </Dialog >
    );
}