import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

//API




//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import MySelect from '../../utils/MySelect';


import {
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    formatAnbimaDateToPt,
    getMonthName
} from '../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';

//Controllers
import {
    doGetAllLocalQuotasByFundId,
    formatFundLocalQuotasMonthYear
} from '../../../controllers/FundLocalQuotasController';
import { Divider } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px #f4f4f4',

        background: unoTheme.hoverTable,

        '& .closeIcon': {
            padding: theme.spacing(3),
        }
    },

    main: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',

        '& .topQuotas': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px 24px',
            '& div': {
                display: 'flex',
                alignItems: 'center',
            }
        },

        '& .tableQuotas': {
            height: 'calc(100vh - 181px)',
        }

    },

    myDatePicker: {
        marginBottom: '16px',
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    rightDivBackdrop: {
        zIndex: 3,
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    infosFundContent: {
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    leftHeader: {
        display: 'flex',
        padding: "24px",
        flexDirection: 'column',
        maxWidth: "calc(100% - 96px)"
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    cnpjLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    overflowEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }

}));

export default function FundLocalQuotas(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");

    //Tab de cotas
    const [monthSelected, setMonthSelected] = useState(0);
    const [yearSelected, setYearSelected] = useState(0);

    const [monthsInYearQuotas, setMonthsInYearQuotas] = useState([]);
    const [yearsWithMonths, setYearsWithMonths] = useState([]);
    const [yearsQuotas, setYearsQuotas] = useState([]);

    //Cotas
    const [fundLocalQuotas, setFundLocalQuotas] = useState(null);


    function handleChangeMonthQuotas(month) {

        console.log("MONTH SELECTED: ", month);
        setMonthSelected(month);
    }

    function handleChangeYearQuotas(year) {
        console.log("YEAR SELECTED: ", year);
        setYearSelected(year);
        setMonthsInYearQuotas(yearsWithMonths[yearsQuotas[year]]);
        setMonthSelected(0);

    }

    async function loadLocalQuotas(fundId) {


        console.log("LOAD LOCAL QUOTAS: ", fundId);
        let responseLocalQuotas = await doGetAllLocalQuotasByFundId(fundId);

        if (responseLocalQuotas.success && responseLocalQuotas.body && responseLocalQuotas.body.length > 0) {

            let formatted = formatFundLocalQuotasMonthYear(responseLocalQuotas.body);

            console.log("QUOTAS: ", formatted);

            setFundLocalQuotas(formatted.data);

            if (yearsQuotas.length == 0) {
                setYearsQuotas(formatted.years);
                setYearsWithMonths(formatted.yearsWithMonths);
                const theMonths = formatted.yearsWithMonths[formatted.years[formatted.years.length - 1]];
                setMonthsInYearQuotas(theMonths);
                console.log("SETANDO");
                console.log(monthSelected);
                console.log(yearSelected);

                //setYearSelected(yearSelected);
                setYearSelected(formatted.years.length - 1);
                //setMonthSelected(monthSelected);
                setMonthSelected(theMonths.length - 1);
            }




        }

        setOpenBackdrop(false);
        setMessageBackdrop('');


    }

    const [firstEffect, setFirstEffect] = useState(true);

    useEffect(() => {

        if (firstEffect) {

            setOpenBackdrop(true);

            setFirstEffect(false);
            console.log("CONTENT: ", content);

            const { data: { fundId } } = content;

            setTimeout(() => {
                loadLocalQuotas(parseInt(fundId));
            }, 500)
        }

    }, [])

    return (
        <div className={classes.root}>

            <Paper elevation={0}>
                <div className={classes.header}>

                    <div className={classes.leftHeader}>

                        <Typography
                            align={'left'}
                            style={{ color: unoTheme.secondaryColor }}>
                            Histórico de Cotas
                        </Typography>
                        <Typography
                            align={'left'}
                            className={classes.fundLabel}>
                            {content?.data?.fund_name ?? ""}

                        </Typography>

                        <Typography
                            align={'left'}
                            className={classes.cnpjLabel}>
                            {content?.data?.cnpj ?? ""}
                        </Typography>
                    </div>

                    <IconButton className="closeIcon" onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                        <CloseIcon />
                    </IconButton>

                </div>
            </Paper>

            <Divider />
            <div className={classes.main}>

                {
                    fundLocalQuotas ?
                        <div className="topQuotas">

                            <Typography variant={'body1'}>
                                {'Selecione o mês e o ano >'}
                            </Typography>

                            <div>
                                <MySelect
                                    style={{ marginLeft: '16px', marginRight: '16px' }}
                                    value={monthSelected}
                                    onChange={(e) => handleChangeMonthQuotas(e.target.value)}
                                    options={monthsInYearQuotas.map((row, index) => (
                                        <option key={'month-local-quota-' + index} value={index}>{getMonthName(row)}</option>
                                    ))}
                                />
                                <MySelect
                                    value={yearSelected}
                                    onChange={(e) => handleChangeYearQuotas(e.target.value)}
                                    options={yearsQuotas.map((row, index) => (
                                        <option key={'year-local-quota-' + index} value={index}>{row}</option>
                                    ))}
                                />
                            </div>
                        </div> : null
                }

                <Divider />

                <div className="tableQuotas">

                    {
                        fundLocalQuotas ?

                            <MyTable
                                stickyHeader
                                size="small"
                                selectable="true">

                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Data</TableCell>
                                        <TableCell align="right">Valor Cota</TableCell>
                                        <TableCell align="right">PL</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fundLocalQuotas
                                        && fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]]
                                        ? fundLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]].map((row, index) => (

                                            <TableRow key={"row-quota-local-index-" + index}>
                                                <TableCell align="left">{formatAnbimaDateToPt(row['data_referencia'])}</TableCell>
                                                <TableCell align="right" style={{ color: unoTheme.greenMoney }}>{applyMaskAmountFreeDecimals(row['valor_cota'], true, 10)}</TableCell>
                                                <TableCell align="right">{row['patrimonio_liquido'] ? applyMaskAmount(row['patrimonio_liquido'], true) : '-'}</TableCell>
                                            </TableRow>

                                        )) : null}
                                </TableBody>

                            </MyTable>

                            :

                            <Typography variant={'body1'} style={{ padding: '32px', textAlign: 'center' }}>
                                {'Nenhuma cota local foi salva para esse fundo até agora'}
                            </Typography>
                    }

                </div>


            </div>

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'} style={{ marginTop: '16px' }}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }

        </div>
    );
}