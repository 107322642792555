import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//Tables
import MyTable from '../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//redux
import MyDatePicker from '../utils/MyDatePicker'

//Utils
import { portfolioClosedToDate, applyMaskAccount, applyMaskAmountFreeDecimals, applyMaskAmount, isDateBeforePortfolioClosed } from '../utils/utils'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio, resetAccounts, resetAssets, resetTransactions } from '../../API/client'
import { getTransactionsByDateRangeWithAPR, getClientAllTransactionByDateRange } from '../../API/transaction'

//Controllers
import { saveLogResetAssetsAndAccounts, saveLogResetTransactions } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    labelTopModal: {
        float: 'right',
        fontSize: '14px',
        color: unoTheme.defaultTextColor
    }


}));

export default function ResetTransactionsModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { open,
        initDate,
        portfolioClosed,
        clientOnScreen,
        // months,
        // years,
        handleActionResetTransactions } = props;

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(initDate);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [transactionsToRemove, setTransactionsToRemove] = useState(null);

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");


    //A data selecionada não pode ser anterior ao fechamento da carteira
    function verifyDateToReset(toDate) {

        console.log("toDate: ", toDate);
        console.log("portfolioClosed: ", portfolioClosed);

        let statusToSave = 'OK';
        if (toDate == null && portfolioClosed != null) {
            statusToSave = 'BEFORE_PORTFOLIO'
        }

        if (toDate != null && portfolioClosed != null && isDateBeforePortfolioClosed(moment.utc(toDate).format('DD/MM/YYYY'), portfolioClosed)) {
            statusToSave = 'BEFORE_PORTFOLIO'
        }

        if (toDate == null && portfolioClosed == null) {
            statusToSave = 'OK'
        }

        if (toDate) {

            toDate = moment.utc(toDate).format('MM/YYYY');
        }

        if (statusToSave == 'OK') {

            //Para o método resetPortfolio basta enviar a data como string
            let toDateToSave = toDate != null && toDate.startsWith('0') ? toDate.substring(1) : toDate;

            let dateTimestampToLog = null;
            const dateToPortfolioClosed = toDateToSave;

            if (toDateToSave) {

                let splittedDateToSave = toDateToSave.split('/');
                let month = splittedDateToSave[0];
                let year = splittedDateToSave[1];

                dateTimestampToLog = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');

                month++;
                if (month == 13) {
                    month = 1;
                    year++;
                }

                //firstDay
                toDateToSave = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');
            }

            console.log("APAGANDO TUDO QUE SEJA DEPOIS DE: ", toDateToSave);

            return {
                toDate: toDate,
                statusToSave: statusToSave,
                toDateToSave: toDateToSave,
                dateToPortfolioClosed: dateToPortfolioClosed,
                dateTimestampToLog: dateTimestampToLog

            }
        }


        return {
            toDate: toDate,
            statusToSave: statusToSave,
        }
    }

    async function doPreResetTransactionsClient(clientId, date) {

        setValidateMessage('');
        setLoader(true);
        setTransactionsToRemove(null);

        let {
            toDate,
            statusToSave, //se deve ou não ser permitida a requisição
            toDateToSave, //Data que será usada como parâmetro no SQL: caso a carteira seja resetada para Fevereiro, toDateToSave será 01/03/XXXX, tudo que for com data >= a isso será excluido
            dateTimestampToLog, //Data a ser usada no Log
            dateToPortfolioClosed,
        } = verifyDateToReset(date);

        if (statusToSave == "OK") {

            //Verificar se existem APRs preenchidas para esse período
            const responseDoneAprs = await getTransactionsByDateRangeWithAPR(clientId, toDateToSave, null);
            console.log('responseDoneAprs: ', responseDoneAprs);

            if (responseDoneAprs.success && responseDoneAprs.body && responseDoneAprs.body.rows && responseDoneAprs.body.rows.length == 0) {
                //Obter movimentações que serão excluídas
                const responseTransactionsToRemove = await getClientAllTransactionByDateRange(clientId, toDateToSave, null);
                if (responseTransactionsToRemove.success) {

                    console.log('responseTransactionsToRemove: ', responseTransactionsToRemove);
                    if (responseTransactionsToRemove.body && responseTransactionsToRemove.body.rows && responseTransactionsToRemove.body.rows.length > 0) {

                        setTransactionsToRemove(responseTransactionsToRemove.body.rows);
                    } else if (responseTransactionsToRemove.body.rows.length == 0) {

                        setValidateMessage("Não existem movimentações cadastradas para esse período");

                    }
                } else {

                }

            } else {
                setLoader(false);
                if (responseDoneAprs.body && responseDoneAprs.body.rows) {

                    setValidateMessage("Não é possível resetar os ativos para " + (dateToPortfolioClosed ? dateToPortfolioClosed : 'o Início da carteira') + " pois existem APRs preenchidas nesse período [ " + responseDoneAprs.body.rows.length + " ]");
                } else {
                    setValidateMessage("Falha ao verificar existência de APRs preenchidas!");
                }
            }

            setLoader(false);



        } else {

            setLoader(false);
            setValidateMessage("Não é possível excluir movimentações de meses que já foram lançados");

        }


    }

    //Resetando ativos do cliente
    async function doResetTransactionsClient(clientId, date) {

        setLoader(true);
        setValidateMessage("");

        let {
            toDate,
            statusToSave, //se deve ou não ser permitida a requisição
            toDateToSave, //Data que será usada como parâmetro no SQL: caso a carteira seja resetada para Fevereiro, toDateToSave será 01/03/XXXX, tudo que for com data >= a isso será excluido
            dateTimestampToLog, //Data a ser usada no Log
            dateToPortfolioClosed
        } = verifyDateToReset(date);

        if (statusToSave == "OK") {

            //Caso existe uma data específica para não são excluídos todos os ativos e as contas
            //Apenas os que nasceram após a data especificadas
            if (toDate) {

                //Remover contas que nasceram após a data especificada
                const responseResetTransactions = await resetTransactions(clientId, toDateToSave);
                if (responseResetTransactions.success) {

                    //Registrando logAction
                    let responseLogAction = await saveLogResetTransactions(currentUser.user.id, clientId, dateTimestampToLog);

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Movimentações resetadas com sucesso!"

                    }));

                    handleActionResetTransactions(false)
                    history.push('/administration/clients');


                } else {

                    setLoader(false);
                    setValidateMessage("Falha ao resetar as movimentações!");

                }


            } else {
                //Resetar tudo
                //Remover contas que nasceram após a data especificada
                const responseResetTransactions = await resetTransactions(clientId, null);
                if (responseResetTransactions.success) {

                    //Registrando logAction
                    let responseLogAction = await saveLogResetTransactions(currentUser.user.id, clientId, null);

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Movimentações resetadas com sucesso!"

                    }));

                    handleActionResetTransactions(false)
                    history.push('/administration/clients');


                } else {

                    setLoader(false);
                    setValidateMessage("Falha ao resetar as movimentações!");

                }

            }

        } else {
            setLoader(false);
            setValidateMessage("A data deve estar entre o início da carteira e a última carteira fechada");
        }


    }

    useEffect(() => {

        // console.log("Months: ", months);
        // console.log("Years: ", years);


    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={transactionsToRemove ? 'md' : 'sm'}
            open={open}
            onClose={() => handleActionResetTransactions(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Resetar Movimentações"}
                {
                    transactionsToRemove ?
                        <span className={classes.labelTopModal}>Após {moment.utc(selectedDate).format('MM/YYYY')} - {transactionsToRemove.length} movimentações</span>
                        : null
                }
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {
                    loader ?
                        <div className={classes.progress}>
                            <CircularProgress
                                disableShrink color="inherit" />
                            <Typography variant={"h6"}>
                                {"Realizando operação..."}
                            </Typography>
                        </div>
                        :
                        <>

                            {/* Movimentações a serem removidas */}

                            {
                                transactionsToRemove ?
                                    <MyTable
                                        style={{ marginTop: '8px' }}
                                        stickyHeader
                                        sizeheaders="verySmall"
                                        size="small">
                                        {/* <colgroup>
                                        <col width="20%" />
                                        <col width="17%" />
                                        <col width="18%" />
                                        <col width="20%" />
                                        <col width="25%" />
                                        <col width="10%" />
                                    </colgroup> */}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableTh} align="center">Data</TableCell>
                                                <TableCell className={classes.tableTh} align="center">Operação</TableCell>
                                                <TableCell className={classes.tableTh} align="center">Conta</TableCell>
                                                <TableCell className={classes.tableTh} align="right">Valor</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {
                                                //content.data.transactions.map((row, index) => (
                                                transactionsToRemove.map((row, index) => (


                                                    <TableRow className={classes.detailRow}
                                                        key={'detail-transaction-' + index}>

                                                        <TableCell align="center" className={classes.selectableInfo}>
                                                            {moment.utc(row.transaction_date).format('DD/MM/YYYY')}
                                                        </TableCell>

                                                        <TableCell align="center" >
                                                            {row.operation}
                                                        </TableCell>

                                                        <TableCell align="center" >
                                                            {applyMaskAccount(row.account)}
                                                        </TableCell>

                                                        <TableCell align="right" className={
                                                            row.operation == 'Aplicação' ? classes.green : (
                                                                row.operation == 'Resgate' ? classes.red : classes.blue
                                                            )
                                                        }>
                                                            {row && row.amount ? applyMaskAmount(row.amount, true) : null}
                                                        </TableCell>

                                                    </TableRow>



                                                ))

                                            }
                                        </TableBody>

                                    </MyTable>

                                    :

                                    <>
                                        <Typography style={{ textAlign: 'center', marginBottom: '16px' }}>
                                            {`Selecione o mês e o ano para onde deseja voltar na carteira`}
                                        </Typography>
                                        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '16px' }}>
                                            {`Atual mês de lançamento: ${portfolioClosed ? portfolioClosed : '-'}`}
                                        </Typography>

                                        <MyDatePicker
                                            className={classes.myDatePicker}
                                            views={["month", "year"]}
                                            margin="dense"
                                            id="date-picker-dialog"
                                            format="MM/YYYY"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                                    </>
                            }

                        </>
                }

                {
                    validateMessage != "" ?
                        <Typography variant={"body2"} style={{ color: 'red', textAlign: 'center', marginTop: '16px', padding: '16px', backgroundColor: '#eeeeee', borderRadius: '5px' }}>
                            {validateMessage}
                        </Typography>
                        :
                        null
                }


            </DialogContent>


            {
                !loader ?
                    <>
                        {
                            transactionsToRemove ?

                                <DialogActions className={classes.actionsFlex}>

                                    <UnoButton onClick={() =>
                                        setTransactionsToRemove(null)} color="secondary">
                                        {'Voltar'}
                                    </UnoButton>

                                    <UnoButton
                                        className={classes.spaceRight2}
                                        onClick={() => doResetTransactionsClient(clientOnScreen.id, selectedDate)}
                                        color="primary">
                                        {'Confirmar'}
                                    </UnoButton>

                                </DialogActions>


                                :

                                <DialogActions className={classes.actionsFlex}>

                                    <UnoButton onClick={() =>
                                        handleActionResetTransactions(false)} color="secondary">
                                        {'Cancelar'}
                                    </UnoButton>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <UnoButton
                                            className={classes.spaceRight2}
                                            onClick={() => doPreResetTransactionsClient(clientOnScreen.id, null)}
                                            type={'successClean'}>
                                            {'Resetar tudo'}
                                        </UnoButton>

                                        <UnoButton
                                            // disabled
                                            className={classes.spaceRight2}
                                            onClick={() => doPreResetTransactionsClient(clientOnScreen.id, selectedDate)}
                                            color="primary">
                                            {'Resetar para [' + moment.utc(selectedDate).format('MM/YYYY') + "]"}
                                        </UnoButton>
                                    </div>

                                </DialogActions>

                        }

                    </>
                    : null

            }




        </Dialog >
    );
}