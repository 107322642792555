import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Controllers
import { formatTargetsToShow, loadClientDiaryByRange } from '../../../controllers/ClientController'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

import moment from 'moment'
import { applyMaskAmount, applyMaskAmountFreeDecimals, greenOrRed } from "../../utils/utils";
import UnoButton from "../../utils/UnoButton";
import { codsInnerLoad } from "../../utils/MyInnerLoad";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    }
});

export default function ReturnsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [clientReturns, setClientReturns] = useState([]);

    async function doGetAllClientReturns(clientId, month, year) {

        let response = await loadClientDiaryByRange(clientId,
            month,
            year, 1); //Passando parâmetro 1 pois se quer apenas o período informado

        if (response.isSuccessful) {
            const clientReturns = response.arrayRents[0].diarys.reverse();

            console.log("CLIENTS RETURNS: ", clientReturns);
            setClientReturns(clientReturns);
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
                cod: codsInnerLoad.returnsScreen
            }));

        } else {
            console.log("RESPONSE ERROR: ", response.error)
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Erro ao consultar retornos",
                cod: codsInnerLoad.returnsScreen
            }));
        }

    }

    function makeReport() {
        const reportType = 'myReturns';

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType,
            message: 'Gerando relatório...'
        }));
    }

    useEffect(() => {

        console.log("User effect")
        if (clientOn && clientOn.client && clientOn.client.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

            setClientReturns([]);
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando retornos...',
                cod: codsInnerLoad.returnsScreen
            }));
            doGetAllClientReturns(clientOn.client.id, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear);

        } else {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Os retornos se dão a partir do lançamento de pelo menos um mês de carteira",
                cod: codsInnerLoad.returnsScreen
            }));
        }


    }, [clientOn]);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Retornos
                </Typography>

                <div style={{ marginRight: '16px' }}>
                    <UnoButton
                        size={'small'}
                        className={classes.spacingLeft1}
                        onClick={() => makeReport()}
                        type='primary'
                        startIcon={<PrintIcon />}>
                        {"Gerar relatório"}
                    </UnoButton>
                </div>
            </div>
            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    stickyHeader
                    size="small">
                    {/* <colgroup>
                        <col width="10%" />
                        <col width="20%" />
                        <col width="20%" />
                        
                        <col width="25%" />
                        <col width="25%" />
                    </colgroup> */}
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="right">PL <span style={{ color: unoTheme.mainColor, fontSize: '10px' }}>(Início do dia)</span></TableCell>
                            <TableCell align="right">Movimentações</TableCell>
                            <TableCell align="right">Retorno(R$)</TableCell>
                            <TableCell align="right">Retorno(%)</TableCell>
                            <TableCell align="right">PL <span style={{ color: unoTheme.mainColor, fontSize: '10px' }}>(Fim do dia)</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientReturns.map((row, index) => (
                            <TableRow key={'diarys-' + index}>
                                <TableCell align="left">{moment.utc(row.date_quota).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="right" style={{ color: unoTheme.mainColor }}>{applyMaskAmount(row.pl_before, true)}</TableCell>
                                <TableCell align="right" style={{ color: parseFloat(row.result_app_rescues) >= 0 ? unoTheme.greenMoney : unoTheme.redMoney }}>{applyMaskAmount(row.result_app_rescues, true)}</TableCell>
                                <TableCell align="right" style={{ color: greenOrRed(row.new_pl - parseFloat(row.result_app_rescues) - row.pl_before) }}>{applyMaskAmount(row.new_pl - parseFloat(row.result_app_rescues) - row.pl_before, true)}</TableCell>
                                <TableCell align="right" style={{ color: greenOrRed(row.rent_day) }}>{applyMaskAmountFreeDecimals(row.rent_day)}%</TableCell>
                                <TableCell align="right" style={{ color: unoTheme.mainColor }}>{applyMaskAmount(row.new_pl, true)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MyTable>
            </div>
        </div >
    );
}



