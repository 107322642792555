import { combineReducers } from 'redux'
import currentUser from './currentUser'
import clientOn from './clientOn'
import notifications from './notifications'
import activeClients from './activeClients'
import mainAlert from './mainAlert'
import fullLoad from './fullLoad'
import innerLoad from './innerLoad'
import reportLoad from './reportLoad'
import rightContent from './rightContent'
import scrollDialog from './scrollDialog'

const rootReducer = combineReducers({
    currentUser,
    clientOn,
    notifications,
    activeClients,
    mainAlert,
    fullLoad,
    innerLoad,
    reportLoad,
    rightContent,
    scrollDialog
})

export default rootReducer