import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

//Tables
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';


//Utils
import {
    applyMaskAccount,
    applyMaskAmount, getDayMonthYearByPtDate, getMonthAgo, getMonthName
} from '../../utils/utils'
import moment from 'moment'

//Api
import {
    getClientAllTransactionByDateRangeAndRegime,
    getAprsByDateRangeAndRegime

} from '../../../API/transaction'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

import { unoTheme } from '../../../assets/styles/unoTheme'

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from "../../../controllers/ExcelController";
import ConfirmBatchAprModal from "../../modals/ConfirmBatchAprModal";

const rightDivWidth = 450;

//HTML TO DOCX
//https://github.com/privateOmega/html-to-docx/blob/master/example/example.js

//Exibição de ícone de ordenação das colunas
function showColumnOrderIcon(column, order) {
    if (column != order.column) {
        return
    }
    let style = { verticalAlign: 'bottom' };
    if (order.direction == 'ASC') {
        return <ArrowDropDown style={style} />
    } else {
        return <ArrowDropUp style={style} />
    }

}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    iconWhite: {
        color: 'white',
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    headerDetail: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    input: {
        display: 'none',
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: "blue",
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    optionNativeSelect: {
        padding: "10px !important"
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                background: 'white',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
        '& .MuiTableCell-sizeSmall:last-child': {
            paddingRight: '24px',
        }
    },
    tableTh: {
        fontWeight: 'bold'
    },
    cellStatusApr: {
        paddingTop: '13px',
    },
    textCenter: {
        textAlign: 'center',
    },
    textAutoComplete: {
        fontSize: '14px',
    },
    bold: {
        fontWeight: 'bold',
    },
    monthYear: {
        color: unoTheme.secondaryColor
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .5s ease-out',
    },
    rightDiv: {
        height: '100%',
        minWidth: rightDivWidth,
        width: rightDivWidth,
        background: 'white',
        transition: 'all .5s ease-out',
        position: 'fixed',
        right: 0,
        //paddingRight: theme.spacing(2),
        marginRight: rightDivWidth * (-1),
    },
    rightDivOpen: {
        height: '100%',
        //marginRight: rightDivWidth - 24,
        marginRight: rightDivWidth,
        borderRight: `solid 24px ${unoTheme.menuContent}`,
    },
    rightDivClose: {
        height: '100%',
        marginRight: '0px',
    },
    rightDivContentOpen: {
        marginRight: '0px',

    },
    rightDivContentClose: {
        marginRight: rightDivWidth * (-1),
    },
    leftRightDiv: {
        background: unoTheme.menuContent,
        height: '100%',
        width: '24px',
        minWidth: '24px',
    },
    mainRightDiv: {
        float: 'right',
        height: '100%',
        //width: rightDivWidth - 24,
        //minWidth: rightDivWidth - 24,        
        width: rightDivWidth,
        minWidth: rightDivWidth,
    },
    openButton: {
        transition: 'all .3s ease',
    },
    rightDivButtonOpen: {
        //opacity: 0,
        display: 'none',
        cursor: 'unset'
    },
    rightDivButtonClose: {
        //opacity: 1,
        display: 'flex',
        cursor: 'pointer',
    },
    headerRightDiv: {
        display: "flex",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        },

        '& .MuiIconButton-root': {
            padding: '8px',
        }
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    formRightDiv: {
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    ok: {
        color: unoTheme.greenMoney,

        fontSize: '1.2em',

    },
    pendent: {
        color: unoTheme.warningColor,

        fontSize: '1.2em',

    },

    printIcon: {
        color: unoTheme.mainColor,
        verticalAlign: 'middle',
        fontSize: '18px',
    }

}));

export default function AprsScreen(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn);
    const rightContent = useSelector(state => state.rightContent);
    const currentUser = useSelector(state => state.currentUser);


    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [regimeOnScreen, setRegimeOnScreen] = useState(undefined);

    const [transactions, setTransactions] = React.useState(undefined);
    //List transactions with assets
    const [listAssetsWithTransactions, setListAssetsWithTransactions] = useState(undefined);

    const [selectedTransaction, setSelectedTransaction] = React.useState(undefined);

    const [resultAmount, setResultAmount] = useState(0);

    const [filterAsset, setFilterAsset] = React.useState(0);
    const [listFilterClientAssets, setListFilterClientAssets] = React.useState([]);

    //Exibindo apenas as movimentações do fundo selecionado no filtro
    const [transactionsToShow, setTransactionsToShow] = React.useState(undefined);
    const [clientClosedMonth, setClientClosedMonth] = React.useState(undefined);

    const [openBatchAprModal, setOpenBatchAprModal] = React.useState(false);

    const [hintNumberApr, setHintNumberApr] = React.useState(undefined);

    const [orderTable, setOrderTable] = React.useState({
        column: 'transaction_date',
        direction: 'ASC',
    });
    function handleOrderTable(order) {

        let newOrder = {};
        if (order.column != orderTable.column) {

            newOrder = {
                column: order.column,
                direction: 'ASC',
            }
            setOrderTable(newOrder);

        } else if (order.column == orderTable.column) {

            const newDirection = orderTable.direction === 'ASC' ? 'DESC' : 'ASC';
            newOrder = {
                column: order.column,
                direction: newDirection,
            }
            setOrderTable(newOrder);

        }

        //LOAD INFOS
        loadData(clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear, accountRegimeId, newOrder);

    }

    async function loadData(clientId, month, year, regimeId, order) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Consultando aprs...',
        }));

        var firstDay = new Date(year, parseInt(month) - 1, 1);
        var lastDay = new Date(year, parseInt(month), 0);

        //Verificando se existem contaas
        if (firstDay != null
            && lastDay != undefined) {

            getTransactionsByDateRange(
                clientId,
                moment(firstDay).format("DD/MM/YYYY"),
                moment(lastDay).format("DD/MM/YYYY"), regimeId, order)
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));


    }

    function sumResultAmount(transactions) {

        let resultAmount = 0.0;
        transactions.forEach(element => {
            if (element.operation_id == 1) {
                resultAmount += parseFloat(element.amount);
            } else if (element.operation_id == 2) {
                resultAmount -= parseFloat(element.amount);
            }

        });

        return resultAmount;
    }

    function getAssetsToFilter(transactions) {

        let assets = [];

        transactions.forEach(element => {

            let filteredAssets = assets.filter(el => el.fund_id == element.fund_id);
            let assetLoop = undefined;
            if (filteredAssets.length == 0) {

                assetLoop = {
                    fund_id: element.fund_id,
                    fund_cnpj: element.fund_cnpj,
                    fund_name: element.fund_name,
                    balance: element.balance,
                    article: element.article,
                }
                assets.push(assetLoop);
            }
        })

        return assets;

    }

    function doFilterAsset(value, assets, transactions) {
        console.log("TRANSACTIONS: ", transactions)
        console.log("ASSETS: ", assets)
        if (value == 0) {
            setTransactionsToShow(transactions);
            setResultAmount(sumResultAmount(transactions));
        } else {
            let filtered = transactions.filter(el => assets[value].asset.fund_id == el.fund_id);
            setTransactionsToShow(filtered);
            setResultAmount(sumResultAmount(filtered));
        }
        setFilterAsset(value);
    }

    function getNextLastAprNumber(aprs, yearApr) {

        let lastAprNumber1 = 0;
        let lastAprNumber2 = 0;

        let yearLoopApr = null;

        aprs.forEach(element => {
            const numberApr = element.number_apr;
            if (numberApr) {

                //Modelo 005/2023
                const numberAprSplitted = numberApr.split('/');

                if (numberAprSplitted.length == 2) {

                    if (parseInt(numberAprSplitted[0]) > lastAprNumber1) {

                        lastAprNumber1 = parseInt(numberAprSplitted[0]);
                    }

                    console.log("yearLoopApr: ", yearLoopApr);
                    if (yearLoopApr != null && numberAprSplitted[1] != yearLoopApr) {
                        lastAprNumber1 = 1;
                    }

                    yearLoopApr = numberAprSplitted[1];


                    if (parseInt(numberAprSplitted[1]) > lastAprNumber2) {

                        lastAprNumber2 = parseInt(numberAprSplitted[1]);
                    }
                }

            }
        });

        if (yearLoopApr != yearApr) {
            return "1".padStart(3, '0') + '/' + yearApr;
        }

        if (lastAprNumber1 != 0) {

            return (lastAprNumber1 + 1).toString().padStart(3, '0') + '/' + yearApr;
        } else {
            return null;
        }

    }

    async function getTransactionsByDateRange(clientId, startDate, endDate, regimeId, order) {

        //let response = await getClientAllTransactionByDateRange(accounts, startDate, endDate);
        console.log("CONSULTA CLIENTE: ", clientId);
        console.log("CONSULTA START DATE: ", startDate);
        console.log("CONSULTA END DATE: ", endDate);

        const { month, year } = getDayMonthYearByPtDate(startDate);

        const startDateBefore = getMonthAgo(month - 1, year, 1)

        console.log("START DATE BEFORE: ", startDateBefore);
        //let response = await getClientAllTransactionByDateRange(clientId, startDate, endDate);
        const response = await getAprsByDateRangeAndRegime(clientId, startDateBefore, endDate, regimeId, order);

        if (response.success) {

            const currentMonthAprs = response.body.rows.filter(el => {
                const transactionDate = moment.utc(el.transaction_date);
                if (transactionDate.month() + 1 == month && transactionDate.year() == year) {
                    return true;
                } else {
                    return false;
                }
            })

            const beforeMonthAprs = response.body.rows.filter(el => {
                const transactionDate = moment.utc(el.transaction_date);
                if (transactionDate.month() + 1 == month && transactionDate.year() == year) {
                    return false;
                } else {
                    return true;
                }
            })

            const { year: yearApr } = getDayMonthYearByPtDate(endDate);

            console.log("currentMonthAprs: ", currentMonthAprs);
            console.log("beforeMonthAprs: ", beforeMonthAprs);

            console.log("APRS LENGTH: ", response.body.rows.length);
            console.log("APRS LENGTH: ", response.body.rows);

            const aprsRows = response.body.rows;

            if (response.body.rows.length) {
                const nextLastAprNumber = getNextLastAprNumber(aprsRows, yearApr);
                console.log('nextLastAprNumber: ', nextLastAprNumber);
                setHintNumberApr(nextLastAprNumber);
            }


            let filter = [
                {
                    asset: null,
                    label: 'Todos',
                }
            ];

            let assetsToFilter = getAssetsToFilter(currentMonthAprs)
            assetsToFilter.forEach((element) => {
                filter.push({
                    asset: element,
                    label: element.fund_name
                })
            })


            setListFilterClientAssets(filter)

            setResultAmount(sumResultAmount(currentMonthAprs));

            setTransactions(currentMonthAprs);

            setTransactionsToShow(currentMonthAprs);

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter transações"
            }))
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    function doReportBatchAprs(hideCpf) {

        let allSave = true;

        if (transactionsToShow && transactionsToShow.length) {

            for (let i = 0; i < transactionsToShow.length; i++) {

                const transactionRow = transactionsToShow[i];
                if (!(transactionRow.apr_description_id || (transactionRow.apr_description != "" && transactionRow.apr_description != null))) {
                    allSave = false;
                    break;
                }

            }

            //Pode-se realizar a geração de aprs em lote
            if (allSave) {

                dispatch(allActions.reportLoadActions.setReportLoad({
                    show: true,
                    reportType: "batchAprs",
                    order: orderTable,
                    hideCpf: hideCpf,
                    accountRegimeId: accountRegimeId,
                    message: 'Gerando lote de APRS...'
                }))

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    message: 'Todas as APRS devem ser preenchidas e salvas para a geração em lote',
                    type: 'warning',
                    show: true,
                }))
            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                message: 'Não existem APRS a serem geradas',
                type: 'warning',
                show: true,
            }))

        }


        return;


    }

    function doExcelTrasanctions(titleFile, month, year, transactions) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        console.log("TRANSACTIONs: ", transactions);

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Movimentações",
            Author: "UNO",
        }

        const sheetName = "Movimentações " + getMonthName(month) + "-" + year;
        wb.SheetNames.push("Movimentações " + getMonthName(month) + "-" + year);

        const ws_data = [];

        ws_data.push(['Nº APR', 'SEGREGAÇÃO', 'CNPJ', 'FUNDO', 'CC', 'DATA', 'TIPO', 'OPERAÇÃO', 'VALOR', 'DESCRIÇÃO'])

        transactions.forEach(element => {
            ws_data.push([
                element.number_apr ? element.number_apr : 'Não preenchida',
                element.account_regime,
                element.fund_cnpj,
                element.fund_name,
                applyMaskAccount(element.account),
                moment.utc(element.transaction_date).format('DD/MM/YYYY'),
                element.operation_id,
                element.operation,
                applyMaskAmount(element.amount, true),
                element.apr_description_id ? element.apr_description_pre : element.apr_description
            ])
        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), titleFile + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    function handleBatchAprs(confirm, hideCpf) {

        setOpenBatchAprModal(false);
        if (confirm) {
            doReportBatchAprs(hideCpf);
        }

    }

    const { accountRegimeId, flagDoReportBatchAprs, flagDoExcelTransactions, resetFlag } = props;

    useEffect(() => {


        if (clientOn && clientOn.client
            && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

            console.log("ENTRANDO AQUI 1");

            setTransactions(undefined)
            setListAssetsWithTransactions(undefined);
            setTransactionsToShow(undefined)

            setClientOnScreen(Object.assign({}, clientOn.client));
            setRegimeOnScreen(accountRegimeId);

            if (clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

                loadData(clientOn.client.id,
                    clientOn.client.selectedPortfolioMonth,
                    clientOn.client.selectedPortfolioYear, accountRegimeId);

            } else {

                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                }));

            }


            dispatch(allActions.rightContentActions.setRightContent(null))

        } else if (rightContent && rightContent.action || accountRegimeId != regimeOnScreen) {


            console.log("ENTRANDO AQUI 2");

            setTransactions(undefined)
            setListAssetsWithTransactions(undefined);
            setTransactionsToShow(undefined)
            setRegimeOnScreen(accountRegimeId);

            loadData(clientOn.client.id,
                clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear, accountRegimeId);

            dispatch(allActions.rightContentActions.setRightContent(null))
        } else if (clientOn && clientOn.client && clientOnScreen
            && clientOn.client.id == clientOnScreen.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
            && (
                clientOn.client.selectedPortfolioMonth != clientOnScreen.selectedPortfolioMonth
                ||
                clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear
            )
        ) {


            console.log("ENTRANDO AQUI 3");

            setTransactions(undefined)
            setListAssetsWithTransactions(undefined);
            setTransactionsToShow(undefined)
            setRegimeOnScreen(accountRegimeId);

            setClientOnScreen(Object.assign({}, clientOn.client));

            loadData(clientOn.client.id,
                clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear, accountRegimeId);

        }

        //Gatilho para gerar lote de APRS
        if (flagDoReportBatchAprs) {

            console.log('currentUser: ', currentUser);
            if (currentUser?.user?.isViewer) {
                handleBatchAprs(true, true)
            } else {
                setOpenBatchAprModal(true);
            }
            resetFlag();

        }

        if (flagDoExcelTransactions) {
            if (clientOn.client.selectedPortfolioMonth &&
                clientOn.client.selectedPortfolioYear && transactionsToShow && transactionsToShow.length > 0) {

                doExcelTrasanctions(
                    clientOn.client.label_name + "_MOVS_" + getMonthName(clientOn.client.selectedPortfolioMonth) + "_" + clientOn.client.selectedPortfolioYear,
                    clientOn.client.selectedPortfolioMonth,
                    clientOn.client.selectedPortfolioYear,
                    transactionsToShow
                )
            }

            resetFlag();
        }

    }, [clientOn,
        selectedTransaction,
        rightContent,
        accountRegimeId,
        flagDoReportBatchAprs, flagDoExcelTransactions]);

    const selectable = useMemo(() => currentUser?.user?.isViewer ? undefined : "true", [currentUser]);
    const openTransactionDetails = useCallback((transactionSelected) => currentUser?.user?.isViewer ? undefined : dispatch(allActions.rightContentActions.setRightContent({
        path: 'newApr',
        data: {
            hintNumberApr,
            transactionSelected,

        },
    })), [dispatch, hintNumberApr]);

    return (
        <div className={classes.root}>
            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    stickyHeader
                    selectable={selectable}
                    size="small">
                    <colgroup>
                        <col width="10%" />
                        <col width="30%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="15%" />
                        <col width="10%" />
                        {/* <col width="5%" /> */}
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' onClick={() => handleOrderTable({ column: 'transaction_date', direction: 'ASC' })}>Data {showColumnOrderIcon('transaction_date', orderTable)}</TableCell>
                            <TableCell align='left'>Fundo</TableCell>
                            <TableCell align='center'>Operação</TableCell>
                            <TableCell align="center">Conta</TableCell>
                            <TableCell align='center' onClick={() => handleOrderTable({ column: 'number_apr', direction: 'ASC' })}>Num. {showColumnOrderIcon('number_apr', orderTable)}</TableCell>
                            <TableCell align='right'>Valor</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {transactionsToShow ?
                        <TableBody>
                            {transactionsToShow.map((transactionRow, index) => (
                                <TableRow
                                    key={'apr-transaction-' + index}
                                    onClick={() => openTransactionDetails(transactionRow)}>
                                    <TableCell align="center">
                                        {moment.utc(transactionRow.transaction_date).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell align="left">{transactionRow.fund_name}</TableCell>
                                    <TableCell align="center">{transactionRow.operation}</TableCell>
                                    <TableCell align="center">{applyMaskAccount(transactionRow.account)}</TableCell>
                                    <TableCell align="center" style={{ color: 'blue' }}>{transactionRow.number_apr ? transactionRow.number_apr : '-'}</TableCell>
                                    <TableCell align="right" className={
                                        transactionRow.operation == 'Aplicação' ? classes.green : (
                                            transactionRow.operation == 'Resgate' ? classes.red : classes.blue
                                        )
                                    }>{applyMaskAmount(transactionRow.amount, true)}</TableCell>
                                    <TableCell
                                        align="center">
                                        {
                                            transactionRow.apr_description_id || (transactionRow.apr_description != "" && transactionRow.apr_description != null) ?

                                                <CheckCircleIcon className={classes.ok} style={{ verticalAlign: 'middle' }} />
                                                :
                                                <ReportProblemIcon className={classes.pendent} style={{ verticalAlign: 'middle' }} />
                                        }

                                    </TableCell>
                                    {/* <TableCell
                                        align="center">
                                        <IconButton size={'small'}
                                            color="inherit"
                                            onClick={(e) => createDocxApr(e, transactionRow, clientOn.client)}>
                                            <PrintIcon

                                                className={classes.printIcon} />
                                        </IconButton>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                        :
                        null
                    }

                    {/* {
                        transactionsToShow && transactionsToShow.length > 0 ?
                            <TableFooter>
                                <TableRow key={'footer-t-transactions'}>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="right">{applyMaskAmount(resultAmount, true)}</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableFooter> : null
                    } */}
                </MyTable>
            </div>

            <ConfirmBatchAprModal
                open={openBatchAprModal}
                handleAction={(confirm, hideCpf) => handleBatchAprs(confirm, hideCpf)}
            />

        </div >
    );
}



