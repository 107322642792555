import moment from 'moment';
import {
    getCDI,
    getCotasDiariasPorPeríodo,
    getFundCompositionByCnpj,
    getFundFullCompositionByCnpj,
    getIBOVAndCDI,
    getIGPM,
    getIPCAdp,
    getInfosFundComdinheiroByDate,
    getInfosReturnTableComdinheiroByDate,
    getOpenPortfolioFund,
    getPU_med,
    getRankingAdmins,
    getRankingManagers,
    getRentsMes3m6m12m,
    getSelicMeta,
    getTablePatrimonioComdinheiroByPeriod,
    getTableRiscoRetornoComdinheiroByDate,
    getTitlesInfos
} from '../API/comdinheiro-api';
import {
    getDayMonthYearByPtDate,
    getDayMonthYearByStringDate,
    getDayMonthsAgo,
    getFirstBeforeBusinessDay,
    getFirstDayMonth, getLastDayMonth,
    getMonthAndYearBefore,
    getTitleCod,
    isMonthAbleToQuotas,
    toJsonQuotasAssets
} from '../components/utils/utils';

import _ from 'lodash';
import { getLocalBenchmarkById } from '../API/local_benchmaks';
import allActions from "../actions";
import { doGetCDIMonth, doSaveCDIMonth } from './CdiLocalController';
import { doGetLocalInfosByFundIdMonthYear, getClientJsonQuotas, getLocalInfosByListFunds, getLocalQuotasByListFunds, trySaveListLocalInfosByFundId, trySaveListLocalQuotas } from './FundLocalQuotasController';
import { doGetIGPMMonth, doSaveIGPMMonth } from './IgpmLocalController';
import { doGetIpcaDpMonthAndAgoMonth, doSaveIpcaDpMonth } from './IpcaDpController';
import { needQuotasTitle } from './TitleController';
import { trySaveTitlesListLocalQuotas } from './TitleLocalQuotasController';
import { RankingFundsManagersAPI } from '../API/ranking_funds_managers';
import { RankingFundsAdminsAPI } from '../API/ranking_funds_admins';
import { IbovCdiAPI } from '../API/ibov_cdi';
import { REQ_COMDINHEIRO_TYPE } from '../API/reqs_comdinheiro';

export async function getBladeComdinheiroByDate(referenceDate, fund, dispatch) {

    console.log("FUND: ", fund);

    const cnpj = fund.cnpj

    console.log("COMDINHEIRO");
    console.log("referenceDate: ", referenceDate);
    console.log("cnpjs: ", cnpj);

    referenceDate = getFirstBeforeBusinessDay(referenceDate);

    console.log("referenceDate business: ", referenceDate);

    let allResponse = {
        comdinheiro: {}
    };

    dispatch(allActions.innerLoadActions.setInnerLoad({
        show: true,
        // extraMargin: true,
        loadMessage: 'Carregando Características do Fundo...',
    }));

    let responseInfosFund = null;

    //Verificar se essa informação já existe na base de dados local
    const { month: mInfo, year: yInfo } = getDayMonthYearByPtDate(referenceDate);
    const responseInfosLocal = await doGetLocalInfosByFundIdMonthYear(parseInt(fund.fundId), mInfo, yInfo);
    console.log('responseInfosLocal: ', responseInfosLocal);
    if (responseInfosLocal.success && responseInfosLocal.body[0]) {
        const { json_fund_infos } = responseInfosLocal.body[0];
        responseInfosFund = JSON.parse(json_fund_infos);

    } else {
        const preResponseInfosFund = await getInfosFundComdinheiroByDate(referenceDate, cnpj, REQ_COMDINHEIRO_TYPE.LAMINA);
        responseInfosFund = preResponseInfosFund.body[cnpj];
    }

    dispatch(allActions.innerLoadActions.setInnerLoad({
        show: true,
        // extraMargin: true,
        loadMessage: 'Carregando Retornos do Fundo...',
    }));


    console.log("FUND TO BLADE: ", fund);
    //Obtendo bechmark comdinheiro a partir do local_benchmark_id
    //Veririca-se se há o benchamark local da anbima, caso contrário coloca-se o que vem da COMDINHEIRO
    const localBenchmark = fund.local_benchmark_id ? await getLocalBenchmarkById(fund.local_benchmark_id) : null;
    console.log("LOCAL BENCHMARK: ", localBenchmark);
    const fundBenchmark = localBenchmark && localBenchmark.success ? localBenchmark.body.rows[0].comdinheiro_name : responseInfosFund.benchmark_anbima
    const fundBenchmarkName = localBenchmark && localBenchmark.success ? localBenchmark.body.rows[0].name : responseInfosFund.benchmark_anbima

    const responseInfosReturnTableFund = await getInfosReturnTableComdinheiroByDate(referenceDate, cnpj, fundBenchmark);
    //Obtendo data de 36 meses atrás
    let initDate = getDayMonthsAgo(referenceDate, 37);
    dispatch(allActions.innerLoadActions.setInnerLoad({
        show: true,
        // extraMargin: true,
        loadMessage: 'Carregando Patrimônios do Fundo...',
    }));
    const responsePatrimonioFund = await getTablePatrimonioComdinheiroByPeriod(initDate, referenceDate, cnpj, REQ_COMDINHEIRO_TYPE.LAMINA);

    dispatch(allActions.innerLoadActions.setInnerLoad({
        show: true,
        // extraMargin: true,
        loadMessage: 'Carregando Riscos e Retornos do Fundo...',
    }));
    const responseRiscoRetornoFund = await getTableRiscoRetornoComdinheiroByDate(referenceDate, cnpj, responseInfosFund.benchmark_anbima);

    const allResponseBlade = [
        responseInfosReturnTableFund.success ? responseInfosReturnTableFund.body : false,
        responsePatrimonioFund.success ? responsePatrimonioFund.body : false,
        responseRiscoRetornoFund.success ? responseRiscoRetornoFund.body : false,
    ]

    console.log("ALL RESPONSE BLADE: ", allResponseBlade);

    if (allResponseBlade && !allResponseBlade.includes(false)) {

        console.log('fundBenchmark: ', fundBenchmarkName);

        allResponse.comdinheiro.dadosCadastrais = responseInfosFund.dados_cadastrais;
        allResponse.comdinheiro.returnTable = allResponseBlade[0];
        allResponse.comdinheiro.patrimonio36 = allResponseBlade[1];
        allResponse.comdinheiro.riscoRetorno = allResponseBlade[2];
        allResponse.comdinheiro.realBenchmark = fundBenchmarkName;

        return {
            success: true,
            body: allResponse,
        }

    } else {

        return {
            success: false,
        }

    }


}

export async function getFundCompare(cnpjs, benchmarks, startDate, endDate) {

    const responsePatrimony = await getTablePatrimonioComdinheiroByPeriod(startDate, endDate, cnpjs);
    console.log('responsePatrimony: ', responsePatrimony);

}


/**
 * Retorna o próximo mês a ser lançado do cliente
 * @param {Array} realAssets ativos a serem requisitados (cnpjs)
 * @param {String} startDate primeiro dia a ser obtido cota
 * @param {String} endDate último dia a ser obtida a cota
 * @param {Boolean} doQuotas indica se é necessário ou não o processamento de obteção de cotas da API
 * @returns {Object} 
 * 
 * O método deve verificar quais informações já existem na base local, pelo atributo fund_local_quota_id
 * Caso haja valor é devido já haver informação salva localmente
 * Após obtenção das novas informações o método deve salvar as novas informações obtidas localmente
 **/
export async function getCotasAndSaveByListAssets(realAssets, startDate, endDate, doQuotas) {

    //Cotas a serem retornadas
    let cotas = {};
    let cotasBefore = {};

    //Cnpjs dos fundos que ainda não possuem cotas na base local    
    let dataToGetApi = [];
    let dataBeforeToGetApi = [];
    let dataToGetLocal = [];
    //Infos de admnistração do fundo
    let infosToGetApi = [];
    let infosToGetLocal = [];

    const realMonth = parseInt(startDate.substring(3, 5));
    const realYear = parseInt(startDate.substring(6, 10));

    console.log("ASSETS GET COTAS: ", _.cloneDeep(realAssets));

    realAssets.forEach(asset => {

        if (!asset.fund_local_quota_id) {

            dataToGetApi.push({
                cnpj: asset.cnpj + (asset.suffix ? '_' + asset.suffix : ''), //adicionando lógica para caso de fundo com SUFIXO
                fund_id: asset.fund_id
            });

            //Caso existe o fund_local_quota_id os dados são obtidos da base própria
        } else if (asset.fund_local_quota_id) {

            dataToGetLocal.push({
                fund_id: asset.fund_id,
                fund_local_quota_id: asset.fund_local_quota_id
            });

            infosToGetLocal.push({
                fund_id: asset.fund_id,
                fund_local_quota_id: asset.fund_local_quota_id
            })

        }

        //Garantindo que quotas_anbima_before seja um array, pois poderia ser "[]"
        if (asset.quotas_anbima_before && !Array.isArray(asset.quotas_anbima_before)) {
            //NEW VALUE QUOTA
            //asset.quotas_anbima_before = JSON.parse(asset.quotas_anbima_before);            
            asset.quotas_anbima_before = getClientJsonQuotas(JSON.parse(asset.quotas_anbima_before), asset.client_id);
        }

        //if (!asset.quotas_anbima_before || asset.quotas_anbima_before.length === 0) {
        if (!asset.quotas_anbima_before) {

            ////Removendo fundos especiais da requisição pois não possuem cotas
            if (asset.quotas_anbima_before && asset.quotas_anbima_before.length === 0 && asset.special) {
                //Não realiza
            } else {
                dataBeforeToGetApi.push({
                    cnpj: asset.cnpj + (asset.suffix ? '_' + asset.suffix : ''), //adicionando lógica para caso de fundo com SUFIXO
                    fund_id: asset.fund_id
                });
            }

            //}

            //Caso existe o fund_local_quota_id os dados são obtidos da base própria
        }


    });

    if (doQuotas) { //Processamento demorado, sempre importante verificar a necessidade dele            

        //As cotas só devem ser trazidas no caso de o mês já ter se encerrado
        //Pois deve-se salvar localmente quando já houverem sido lançados todos os valores de cota 
        //de todos os dias, pela anbima

        if (isMonthAbleToQuotas(realMonth, realYear)) {
            // if (isMonthEnded(endDate)) {

            //Obtendo Quotas que ainda não existem localmente
            if (dataToGetApi.length > 0) {

                console.log("OBTENDO DA API");
                let responseApiCotas = await getCotasByListCnpjs(dataToGetApi, startDate, endDate);

                if (responseApiCotas.success) {

                    //Formtando para modelo de fund_id > cotas MODELO_ANTIGO
                    //Formtando para modelo de quotas_anbima direto do banco NOVO_MODELO
                    Object.entries(responseApiCotas.cotas).map(([key, row]) => {
                        //cotas[row.fund_id] = row.serie_historica;
                        realAssets.find(el => el.fund_id == row.fund_id).quotas_anbima = row.serie_historica;
                    })

                    //Tentar salvar series localmente caso hajam            
                    //Foramatando para trySaveListCotas
                    let listToSave = [];
                    Object.entries(responseApiCotas.cotas).map(([key, row]) => {
                        listToSave.push({ ...row });
                    })

                    trySaveListLocalQuotas(listToSave, startDate);

                } else {

                    console.log("////////////FALHA AO OBTER COTAS DA API///////////");
                }

            }

            if (dataBeforeToGetApi.length > 0) {

                console.log("OBTENDO DA API QUOTAS BEFORE: ", dataBeforeToGetApi);
                const splittedStartDate = startDate.split('/');

                //Obtendo mês anterior    
                let agoMonth = parseInt(splittedStartDate[1]) - 1;
                let agoYear = parseInt(splittedStartDate[2]);

                if (agoMonth == 0) {
                    agoMonth = 12;
                    agoYear--;
                }
                const startDateBefore = getFirstDayMonth(agoMonth, agoYear);
                const endDateBefore = moment.utc(getLastDayMonth(agoMonth, agoYear)).format('DD/MM/YYYY');

                let responseApiCotas = await getCotasByListCnpjs(dataBeforeToGetApi, startDateBefore, endDateBefore);

                if (responseApiCotas.success) {

                    //Formtando para modelo de fund_id > cotas MODELO_ANTIGO
                    //Formtando para modelo de quotas_anbima direto do banco NOVO_MODELO
                    Object.entries(responseApiCotas.cotas).map(([key, row]) => {
                        //cotasBefore[row.fund_id] = row.serie_historica;
                        realAssets.find(el => el.fund_id == row.fund_id).quotas_anbima_before = row.serie_historica;
                    })

                    //Tentar salvar series localmente caso hajam            
                    //Foramatando para trySaveListCotas
                    let listToSave = [];
                    Object.entries(responseApiCotas.cotas).map(([key, row]) => {
                        listToSave.push({ ...row });
                    })

                    //Adicionando cotas before obtidas da API nos ativos quotas_anbima_before
                    // realAssets.forEach(asset => {

                    //     if (!asset.quotas_anbima_before) {
                    //         asset.quotas_anbima_before = cotasBefore[asset.fund_id];
                    //     }

                    // })

                    trySaveListLocalQuotas(listToSave, startDateBefore);

                } else {

                    console.log("////////////FALHA AO OBTER COTAS DA API///////////");
                }

            }

            //Obtendo Quotas localmente
            // if (dataToGetLocal.length > 0) {

            //     console.log("OBTENDO LOCAL");
            //     //Obtendo-se as cotas que já estão armazenadas localmente e agregando ao objeto "cotas"
            //     await getLocalQuotasByListFunds(cotas, dataToGetLocal, startDate, "fund_id",
            //         realAssets[0] ? realAssets[0].client_id : null);

            // }
        }
    }

    //Atribuindo vetor vazio para cotas de fundos speciais
    realAssets.filter(el => el.special).forEach(asset => {

        if (!asset.quotas_anbima) {
            asset.quotas_anbima = [];
        }

        // if (!cotas[asset.fund_id]) {

        //     cotas[asset.fund_id] = [];
        // }

    });

    //No caso da tela de CARTEIRA onde não são necessários os valores de quota é nesse método que obtemos informações relevantes 
    //Ex: Disponibilidade (D + 0)

    //Obtendo Infos localmente
    //if (dataToGetLocal.length > 0) {
    if (infosToGetLocal.length > 0) {

        console.log("OBTENDO INFORMAÇÕES LOCALMENTE: ", infosToGetLocal);
        let localFundQuotasIds = [];
        infosToGetLocal.forEach(element => {
            localFundQuotasIds.push(element.fund_local_quota_id);
        });

        realAssets = await getLocalInfosByListFunds(realAssets, localFundQuotasIds);

    }

    if (!doQuotas) {
        //TRECHO ADICIONADO APÓS DECISÃO DE TRAZER SEMPRE AS COTAS PARA OS CÁLCULOS
        //PROCESSAMENTO NÃO SERÁ TÃO CUSTOSO VISTO QUE AS COTAS ESTAO ARMAZENADAS LOCALMENTE
        //Obtendo Quotas localmente
        if (dataToGetLocal.length > 0) {
            //no processmento o objeto filteredQuotas já é atualizado automaticamente       
            await getLocalQuotasByListFunds(cotas, dataToGetLocal, startDate, "fund_id",
                realAssets[0] ? realAssets[0].client_id : null);
        }
    }


    //Verificar se há a necessidade de trazer da API, quando ainda não existem as informações no banco local    
    //let assetsNoLocalInfos = realAssets.filter(el => !el.dados_cadastrais && !el.special);
    let assetsNoLocalInfos = realAssets.filter(el =>
        !el.dados_cadastrais
        && !el.no_infos_api //indo buscar apenas os que posuem informação na API
    );

    console.log("assetsNoLocalInfos: ", assetsNoLocalInfos);
    assetsNoLocalInfos = _.cloneDeep(assetsNoLocalInfos);
    if (assetsNoLocalInfos && assetsNoLocalInfos.length > 0) {

        let cnpjsAndFundIdNoInfos = [];
        //Mapa para ser usado no save local
        let mapCnpjFundId = {};
        //É necessário criar um objeto com o código anbima que será utilizado pela API
        //e o código da linha da tabela fund_local_quota pra ser usado no update posterior
        //com as informações corretas
        assetsNoLocalInfos.forEach(assetNoInfo => {

            if (!cnpjsAndFundIdNoInfos.includes(assetNoInfo.cnpj)) {
                cnpjsAndFundIdNoInfos.push(assetNoInfo.cnpj);
                mapCnpjFundId[assetNoInfo.cnpj] = assetNoInfo.fund_id;
            }

        });

        console.log("TRAZER DA API INFOS DE: ", assetsNoLocalInfos);

        //Trazer da API                
        let responseInfos = await getInfosByListCnpjs(cnpjsAndFundIdNoInfos, getFirstBeforeBusinessDay(endDate));

        if (responseInfos.success) {

            console.log("RESPONSE INFOS: ", responseInfos);
            //atribuindo informações aos fundos
            realAssets.forEach(realAsset => {

                //Condição necessária pois o asset já pode possuir os dados cadastrais obtidos localmente
                if (!realAsset.dados_cadastrais) {
                    realAsset.dados_cadastrais = responseInfos.infos[realAsset.cnpj];
                }


            });

            //trySaveListLocalInfos(responseNormalize.infosToSaveLocal);
            //Preparando lista com os fund_ids
            let infosToSaveLocal = [];
            Object.entries(responseInfos.infos).map(([key, row]) => {
                infosToSaveLocal.push({
                    fund_id: mapCnpjFundId[key],
                    infos: JSON.stringify(row),
                });
            })

            //Agora o save no banco é feito a partir do fund_id junto do month e do year
            trySaveListLocalInfosByFundId(infosToSaveLocal, realMonth, realYear);

        } else {

            console.log("/////////FALHA AO OBTER INFORMAÇÕES DOS FUNDOS DA API/////////");
            console.log("/////////VERIFICAR POSSIBILIDADE DE NAO SER UM DIA UTIL/////////");

        }

    }

    //Obtendo cotas JSON
    toJsonQuotasAssets(realAssets);

    return {
        toReturnAssets: realAssets,
        cotas: cotas,
    }

}

//Obtenção das [Cotas diárias] do FUNDO
export async function getCotasByListCnpjs(cnpjsAndFundIds, startDate, endDate, noRepeatLastQuotaWhenNoQuota) {

    let response = await getCotasDiariasPorPeríodo(cnpjsAndFundIds, startDate, endDate, noRepeatLastQuotaWhenNoQuota);

    if (response.success) {

        return {
            success: true,
            cotas: response.body
        }

    } else {

        return {
            success: false
        }

    }

}


//Obtenção dos [Dados Cadastrais] do FUNDO
export async function getInfosByListCnpjs(cnpjsAndFundIds, referenceDate) {

    console.log(cnpjsAndFundIds);
    console.log(referenceDate);
    let response = await getInfosFundComdinheiroByDate(referenceDate, cnpjsAndFundIds);

    if (response.success) {

        return {
            success: true,
            infos: response.body
        }

    } else {

        return {
            success: false
        }

    }

}

async function doGetTitleInfos(noQuotasTitles, startDate, endDate, preLoadIpcaDp, isBefore) {

    const responseInfosTitle = await getTitlesInfos(noQuotasTitles, startDate, endDate);
    if (responseInfosTitle.success && responseInfosTitle.body) {

        const { mapTitleCotas, ipcaDp } = responseInfosTitle.body;
        console.log('RESPONSE TITLES FORMATTED: ', responseInfosTitle.body);

        //montando objeto para ser salvo localmente 
        const toSave = [];
        const mapTitleIdSerie = {};
        Object.entries(mapTitleCotas).map(([key, row]) => {

            const titleId = noQuotasTitles.find(el => getTitleCod(el, true) === key).title_id;
            toSave.push({
                title_id: titleId,
                cotas: row,
            });

            mapTitleIdSerie[titleId] = row;
        })

        console.log('toSave: ', toSave);

        //Salvar informações localmente
        trySaveTitlesListLocalQuotas(toSave, startDate);

        if (!preLoadIpcaDp) { //não houve retorno de ipca dp

            doSaveIpcaDpMonth(ipcaDp, startDate);

        }

        //povoando title_local_quotas
        noQuotasTitles.forEach(element => {
            element[isBefore ? 'title_local_quotas_before' : 'title_local_quotas'] = mapTitleIdSerie[element.title_id];
            element[isBefore ? 'ipcadp_local_before' : 'ipcadp_local'] = preLoadIpcaDp ? JSON.parse(preLoadIpcaDp.json_ipcadp) : ipcaDp;
        });
    }

    return noQuotasTitles;
}

//Obtenção de informações do título na comdinheiro
export async function getInfosTitlesAndSaveByListCodTitles(titleAssets, startDate, endDate) {

    const { month, year } = getDayMonthYearByStringDate(startDate);

    if (isMonthAbleToQuotas(month, year)) {

        if (titleAssets && titleAssets.length > 0) {

            //Verificando se já existe a lista de ipcadp_local
            //const ipcaDpLocal = await doGetIpcaDpMonth(startDate);
            const { ipcaDpActualMonth, ipcaDpAgoMonth } = await doGetIpcaDpMonthAndAgoMonth(startDate);
            console.log('ipcaDpActualMonth: ', ipcaDpActualMonth);
            console.log('ipcaDpAgoMonth: ', ipcaDpAgoMonth);

            const noQuotasTitles = [];
            const noQuotasTitlesBefore = [];
            //Verificar se existem informações locais dos títulos
            titleAssets.forEach(titleAssetLoop => {

                //Retirando requisições desnecessáruas para o caso de titulos que não possuem essas informações na comdinheiro
                if (needQuotasTitle(titleAssetLoop)) {
                    //Verificando cotas atuais
                    if (!titleAssetLoop.title_local_quotas) {

                        noQuotasTitles.push(titleAssetLoop);

                    } else {

                        titleAssetLoop.title_local_quotas = JSON.parse(titleAssetLoop.title_local_quotas);
                        if (ipcaDpActualMonth) {
                            titleAssetLoop.ipcadp_local = JSON.parse(ipcaDpActualMonth.json_ipcadp);
                        }

                    }

                    //Verificando cotas anteriores
                    if (!titleAssetLoop.title_local_quotas_before) {

                        noQuotasTitlesBefore.push(titleAssetLoop);

                    } else {

                        titleAssetLoop.title_local_quotas_before = JSON.parse(titleAssetLoop.title_local_quotas_before);
                        if (ipcaDpAgoMonth) {
                            titleAssetLoop.ipcadp_local_before = JSON.parse(ipcaDpAgoMonth.json_ipcadp);
                        }

                    }
                }

            });

            //Caso não haja, fazer requisição a API
            if (noQuotasTitles.length > 0) {

                await doGetTitleInfos(noQuotasTitles, startDate, endDate, ipcaDpActualMonth);

            }

            //Caso não haja, fazer requisição a API
            if (noQuotasTitlesBefore.length > 0) {

                const { monthBefore, yearBefore } = getMonthAndYearBefore(startDate);
                const startDateBefore = getFirstDayMonth(monthBefore, yearBefore);
                const endDateBefore = moment.utc(getLastDayMonth(monthBefore, yearBefore)).format('DD/MM/YYYY');

                await doGetTitleInfos(noQuotasTitlesBefore, startDateBefore, endDateBefore, ipcaDpAgoMonth, true);

            }


            return titleAssets;

        } else {

            return false;

        }
    }



}

//Obtendo Informações de CDI
export async function getCDIAndSave(startDate, endDate) {

    console.log("INDO BUSCAR CDI");

    const { month, year } = getDayMonthYearByStringDate(startDate);

    console.log('MONTH: ', month);
    console.log('YEAR: ', year);
    console.log('isMonthAbleToQuotas(month, year): ', isMonthAbleToQuotas(month, year))
    if (isMonthAbleToQuotas(month, year)) {
        //verificando se existe CDI local
        const cdiLocal = await doGetCDIMonth(month, year);
        if (cdiLocal.success) {

            return cdiLocal.body;

        } else {

            const responseCDI = await getCDI(startDate, endDate);
            if (responseCDI.success) {

                doSaveCDIMonth(responseCDI.body, startDate);
                return responseCDI.body;

            } else {

                console.error("FALHA AO BUSCAR CDI NA API");

            }

        }

    } else {
        return [];
    }

}

//Obtendo Informações de IGPMN
export async function getIGPMAndSave(startDate, endDate) {

    console.log("INDO BUSCAR IGPM");

    const { month, year } = getDayMonthYearByStringDate(startDate);

    console.log('MONTH: ', month);
    console.log('YEAR: ', year);
    console.log('isMonthAbleToQuotas(month, year): ', isMonthAbleToQuotas(month, year))
    if (isMonthAbleToQuotas(month, year)) {
        //verificando se existe CDI local
        const igpmLocal = await doGetIGPMMonth(month, year);
        console.log('igpmLocal: ', igpmLocal);
        if (igpmLocal.success) {

            return igpmLocal.body;

        } else {

            console.log("Não existe IGPM: ", month, year);

            const responseIGPM = await getIGPM(startDate, endDate);
            if (responseIGPM.success) {

                doSaveIGPMMonth(responseIGPM.body, startDate);
                return responseIGPM.body;

            } else {

                console.error("FALHA AO BUSCAR CDI NA API");

            }

        }

    } else {
        return [];
    }

}

//Obtendo composição do do fundo

export async function doGetFundCompositionByCnpj(cnpj) {

    const response = await getFundCompositionByCnpj(cnpj);

    if (response.success) {
        return response.body[cnpj];
    } else {
        return null;
    }
}

export async function doGetFundFullCompositionByCnpj(cnpj) {

    const response = await getFundFullCompositionByCnpj(cnpj);

    if (response.success) {
        return response.body;
    } else {
        return null;
    }
}

export async function doGetRankingManagers(referenceDate) {

    //verificar se existe a informação local
    const { month, year } = getDayMonthYearByPtDate(referenceDate);
    const responseLocal = await RankingFundsManagersAPI.getData(month, year);

    console.log('responseLocal managers: ', responseLocal);

    if (responseLocal.success
        && responseLocal.body
        && responseLocal.body.rows
        && responseLocal.body.rows[0]
        && responseLocal.body.rows[0].data) {

        return JSON.parse(responseLocal.body.rows[0].data);
    }

    const response = await getRankingManagers(referenceDate);

    if (response.success && response.body) {
        const saveLocalManagers = await RankingFundsManagersAPI.save(month, year, JSON.stringify(response.body));
        if (saveLocalManagers.success) {
            console.log("MANAGERS SALVOS LOCALMENTE!");
        } else {
            console.error("FALHA AO SALVAR MANAGERS LOCALMENTE!");
        }
    }

    if (response.success) {
        return response.body;
    } else {
        return null;
    }


}

export async function doGetRankingAdmins(referenceDate) {

    //verificar se existe a informação local
    const { month, year } = getDayMonthYearByPtDate(referenceDate);
    const responseLocal = await RankingFundsAdminsAPI.getData(month, year);

    console.log('responseLocal admins: ', responseLocal);

    if (responseLocal.success
        && responseLocal.body
        && responseLocal.body.rows
        && responseLocal.body.rows[0]
        && responseLocal.body.rows[0].data) {

        return JSON.parse(responseLocal.body.rows[0].data);
    }

    const response = await getRankingAdmins(referenceDate);

    if (response.success && response.body) {
        const saveLocalAdmins = await RankingFundsAdminsAPI.save(month, year, JSON.stringify(response.body));
        if (saveLocalAdmins.success) {
            console.log("ADMINS SALVOS LOCALMENTE!");
        } else {
            console.error("FALHA AO SALVAR ADMINS LOCALMENTE!");
        }
    }

    if (response.success) {
        return response.body;
    } else {
        return null;
    }


}

export async function doGetRentsMes3m6m12m(cnpjs, benchmarks, referenceDate) {

    const response = await getRentsMes3m6m12m(cnpjs, benchmarks, referenceDate);

    if (response.success) {
        return response.body;
    } else {
        return null;
    }

}

export async function doGetIBOVAndCDI(startDate, endDate) {

    //verificar se existe a informação local
    const { month, year } = getDayMonthYearByPtDate(endDate);
    const responseLocal = await IbovCdiAPI.getData(month, year);

    console.log('responseLocal IbovCdiAPI: ', responseLocal);

    if (responseLocal.success
        && responseLocal.body
        && responseLocal.body.rows
        && responseLocal.body.rows[0]
        && responseLocal.body.rows[0].data) {
        const jsonData = JSON.parse(responseLocal.body.rows[0].data);

        console.log("IbovCdi local: ", jsonData);
        return jsonData;
    }

    const response = await getIBOVAndCDI(startDate, endDate);

    if (response.success && response.body) {
        const saveLocalIbovCdi = await IbovCdiAPI.save(month, year, JSON.stringify(response.body));
        if (saveLocalIbovCdi.success) {
            console.log("IbovCdi SALVOS LOCALMENTE!");
        } else {
            console.error("FALHA AO SALVAR IbovCdi LOCALMENTE!");
        }
    }

    if (response.success) {
        return response.body;
    } else {
        return null;
    }

}

export async function doGetIPCAdp(startDate, endDate) {

    const response = await getIPCAdp(startDate, endDate);
    if (response.success) {
        return response.body
    } else {
        return null;
    }

}

export async function doGetCDI(startDate, endDate, isAcum, logCod) {

    const response = await getCDI(startDate, endDate, isAcum, logCod);
    if (response.success) {
        return response.body
    } else {
        return null;
    }

}

export async function doGetIGPM(startDate, endDate) {

    const response = await getIGPM(startDate, endDate);
    if (response.success) {
        return response.body
    } else {
        return null;
    }

}

export async function doSelicMeta(startDate, endDate) {

    const response = await getSelicMeta(startDate, endDate);
    if (response.success) {
        return response.body
    } else {
        return null;
    }

}

export async function doGetPU_med(titles, startDate, endDate) {

    const response = await getPU_med(titles, startDate, endDate);
    if (response.success) {
        return response.body
    } else {
        return null;
    }

}