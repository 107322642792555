import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';

//Text field 
import MyTextField from '../../../utils/MyTextField'
import MySwitch from '../../../utils/MySwitch'

//UnoButton
import UnoButton from '../../../utils/UnoButton'
import { unoTheme } from "../../../../assets/styles/unoTheme";

//Grid
import { Divider, Grid } from "@material-ui/core";

//API
import { updateClientGesconInfos, getClientGesconInfos } from '../../../../API/client'

//Redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import allActions from "../../../../actions";

import moment from 'moment'
import { headerSearch } from "../../../../assets/styles/unoTheme";

// Utils
import _, { isEmpty } from 'lodash';
import { isValidCpf } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    main: {
        padding: theme.spacing(2),
    },

    headerSearch: headerSearch,
    switchs: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

        '& .MuiTypography-root': {
            marginTop: '-2px',
        }

    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },

    buttonsHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    maginRight2: {
        marginRight: theme.spacing(2),
    },
    input: {
        display: 'none',
    },

    imageSelected: {
        width: '100px',
        borderRadius: '10px',
        float: 'left',
        cursor: 'pointer',
        //border: 'solid 1px ' + unoTheme.mainColor
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    }

}));

export default function ClientGesconScreen({
    clientOnScreen }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [namePresidentGescon, setNamePresidentGescon] = useState('')
    const [cpfPresidentGescon, setCpfPresidentGescon] = useState('')
    const [passwordPresidentGescon, setPasswordPresidentGescon] = useState('')

    const [nameManagerGescon, setNameManagerGescon] = useState('')
    const [cpfManagerGescon, setCpfManagerGescon] = useState('')
    const [passwordManagerGescon, setPasswordManagerGescon] = useState('')


    // const [loginPresidentActive, setLoginPresidentActive] = useState(false)
    // const [loginManagerActive, setLoginManagerActive] = useState(false)
    // const [loginLemaActive, setLoginLemaActive] = useState(false)

    //form    
    const [submitted, setSubmitted] = useState(false);

    async function doSaveInfosGesconClient(event) {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: 'Atualizando informações CADPREV'
        }))

        event.preventDefault();

        const cadprevInfosClient = {

            name_president: namePresidentGescon,
            cpf_president: cpfPresidentGescon,
            password_president: passwordPresidentGescon,

            name_manager: nameManagerGescon,
            cpf_manager: cpfManagerGescon,
            password_manager: passwordManagerGescon,

        }

        const responseGesconUpdateInfos = await updateClientGesconInfos(clientOnScreen.id, cadprevInfosClient);

        if (responseGesconUpdateInfos.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Informações do GESCON atualizadas com sucesso'
            }))

            //updateActiveClients();

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            history.push('/administration/clients');


        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao atualizar informações do GESCON'
            }))
        }

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))


    }

    async function loadInfos(clientOnScreen) {


        const responseGesconInfos = await getClientGesconInfos(clientOnScreen.id);
        console.log('responseGesconInfos: ', responseGesconInfos);
        if (responseGesconInfos.success) {

            initForm(responseGesconInfos.body)
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao obter informações do GESCON'
            }))
        }

    }

    function initForm(client) {

        setCpfPresidentGescon(client.cpf_president);
        setPasswordPresidentGescon(client.password_president)
        setNamePresidentGescon(client.name_president)

        setCpfManagerGescon(client.cpf_manager);
        setPasswordManagerGescon(client.password_manager)
        setNameManagerGescon(client.name_manager)

    };

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        if (clientOnScreen) {

            loadInfos(clientOnScreen);

        }

    }, [clientOnScreen]);

    return (
        <div className={classes.root}>

            <div class='defaultScrollWithHeaderContent'>
                <div className={classes.main}>
                    <form className={classes.rootForm} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField required
                                    outerlabel="Nome Presidente"
                                    size={'small'}
                                    variant="outlined"
                                    value={namePresidentGescon}
                                    placeholder={'Insira o Nome'}
                                    onChange={(value) => setNamePresidentGescon(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField required
                                    outerlabel="CPF Presidente"
                                    size={'small'}
                                    variant="outlined"
                                    value={cpfPresidentGescon}
                                    mask={['cpf']}
                                    placeholder={'Insira o CPF'}
                                    onChange={(value) => setCpfPresidentGescon(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="Senha presidente"
                                    value={passwordPresidentGescon}
                                    onChange={(value) => setPasswordPresidentGescon(value)}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={"Insira a senha"}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={2} md={2}>
                                <MySwitch
                                    outerlabel="LOGIN PRESIDENTE"
                                    checked={loginPresidentActive}
                                    onChange={(e) => handleActiveLoginGescon(e.target.checked, 'president')}
                                    color="primary" />
                            </Grid> */}
                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField required
                                    outerlabel="Nome Gestor"
                                    size={'small'}
                                    variant="outlined"
                                    value={nameManagerGescon}
                                    placeholder={'Insira o Nome'}
                                    onChange={(value) => setNameManagerGescon(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="CPF GESTOR"
                                    size={'small'}
                                    variant="outlined"
                                    value={cpfManagerGescon}
                                    mask={['cpf']}
                                    placeholder={'Insira o CPF'}
                                    onChange={(value) => setCpfManagerGescon(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="Senha gestor"
                                    value={passwordManagerGescon}
                                    onChange={(value) => setPasswordManagerGescon(value)}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={"Insira a senha"}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={2} md={2}>
                                <MySwitch
                                    outerlabel="LOGIN GESTOR"
                                    checked={loginManagerActive}
                                    onChange={(e) => handleActiveLoginGescon(e.target.checked, 'manager')}
                                    color="primary" />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12}>
                                <UnoButton
                                    size={'small'}
                                    variant="contained"
                                    type="primary"
                                    onClick={(e) => doSaveInfosGesconClient(e)}>
                                    {"Salvar informações"}
                                </UnoButton>
                            </Grid>

                        </Grid>

                    </form>
                </div>
            </div>


        </div>
    );
}
