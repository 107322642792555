import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import allActions from '../../actions'
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { unoTheme } from '../../assets/styles/unoTheme';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 999,
        color: unoTheme.mainColor,

        width: '100%',
        height: '100%',
        position: 'relative',

        background: 'white',
        opacity: 0.85,
        //width: 'calc(100% - 144px)',

        //marginTop: 'calc(100vh / 2)',
        borderRadius: '10px 10px 0px 0px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .small': {
            zIndex: 2,
            '& .MuiCircularProgress-root': {
                width: '25px !important',
                height: '25px !important',
            },

            '& h6': {
                fontSize: '14px !important',
            }
        },

        '&.small': {
            zIndex: 2,
            '& .MuiCircularProgress-root': {
                width: '25px !important',
                height: '25px !important',
            },

            '& h6': {
                fontSize: '14px !important',
            }
        },

        '& .background': {

            //height: 'calc(100% - 138px)',
            width: 'calc(100% - 144px)',
            height: 'calc(100vh - 138px)',
            position: 'absolute',
            background: 'white',
            opacity: 0.85,
            zIndex: 1,
            width: '100%',
            borderRadius: '10px 10px 0px 0px',


        },

        '& .background.extraMargin': {
            marginTop: "-46px",
        }

    },




}));

/**
* Exibir inner load
* @param {String} show 
* @param {String} message 
* @returns {Object} inner load
*/
export default function MyInnerLoad(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { show,
        message, small } = props

    useEffect(() => {


    }, [show, message])

    return (

        <div className={[classes.root, small ? 'small' : ''].join(' ')}>
            {
                show ?
                    <>
                        <CircularProgress
                            disableShrink color="inherit" />

                        {
                            message ?
                                <Typography style={{ marginLeft: '16px' }} variant={"h6"}>
                                    {message}
                                </Typography> : null
                        }

                    </>

                    : null
            }

        </div>

    );
}