import { Divider, makeStyles, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { getAllImprovements } from '../../../API/improvement';
import { unoTheme } from '../../../assets/styles/unoTheme';
import EventIcon from '@material-ui/icons/Event';
import UnoButton from '../UnoButton';
import { useDispatch, useSelector } from 'react-redux';
import PluggyAlert, { useDisplayErrorMessageOnPluggyError } from '../../PluggyAlert';
import allActions from '../../../actions';
import { CustomDialogTitle } from './CustomDialogTitle';
import { downloadFile } from '../utils';
import GetAppIcon from '@material-ui/icons/GetApp';
import PluggyController from '../../../controllers/PluggyController';

export const useStyles = makeStyles((theme) => ({
  actionsFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  dateStyle: {

    display: 'flex',
    alignItems: 'center',
    //justifyContent: 'center',
    color: '#686868',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '10px',
    //padding: '0 0 0 8px'
    '& svg': {
      marginRight: '8px',
    }
  },

  improvementDiv: {
    cursor: 'pointer',
    borderRadius: '15px',
    '&:hover': {
      backgroundColor: unoTheme.hoverTable,

    },

    '& .titleAndType': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '8px',

      '& h6': {
        margin: '0px',
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'black',
      }
    }
  },

  descriptionAndLabelStyle: {
    fontSize: '14px',
    marginBottom: '20px',

  },

  descriptionStyle: {
    padding: '2px 0px',
    transition: '.2s linear',
    // '&:hover': {
    //   backgroundColor: '#21B9F8',
    //   cursor: 'pointer',
    //   borderRadius: '15px',
    //   color: 'white',
    // }
  },

  dividerStyle: {
    marginBottom: '20px'
  },

  titleDescriptionStyle: {
    fontSize: '14px'
  },

  improvementLabelStyle: {
    borderRadius: '15px',
    color: 'white',
    padding: '4px 14px',
    margin: '0 10px',
    fontSize: '14px',
  }
}));

/**
 * 
 * @param {{
 *  update: boolean;
 *  handleListUpdate: () => void;
*   handleClose: () => void;
*   handleOpenRegistration: (element: any) => void;
 * } & import('../../../reducers/scrollDialog').ScrollDialog} props 
 * @returns 
 */
export default function MyScrollDialog(props) {
  const {
    open,
    data,
    type,
    update,
    handleListUpdate,
    handleClose,
    handleOpenRegistration,
  } = props

  const classes = useStyles()
  const dispatch = useDispatch();

  const [scroll, setScroll] = useState('paper');
  const { clientOn, currentUser } = useSelector(state => state);

  const [improvements, setImprovements] = useState(null);
  const [formattedImprovements, setFormattedImprovements] = useState(null);

  function formatByDate(improvements) {
    const mapImprovementsByDate = {};

    improvements && improvements.forEach(improvement => {

      const createdDate = moment(improvement.created_date).format('DD/MM/YYYY')

      if (!mapImprovementsByDate[createdDate]) {
        mapImprovementsByDate[createdDate] = [];
      }
      mapImprovementsByDate[createdDate].push(improvement);
    });

    return mapImprovementsByDate
  }

  function renderImprovements(mapImprovementsByDate) {

    const arrayToRender = [];

    const numberOfElements = Object.keys(mapImprovementsByDate).length
    let iterator = 0

    Object.entries(mapImprovementsByDate).map(([key, rows]) => {
      iterator++

      arrayToRender.push(
        <div className={classes.dateStyle}>
          <EventIcon />{key}
        </div>
      )

      rows.forEach(element => {
        arrayToRender.push(
          <div className={classes.improvementDiv}
            onClick={() => {
              if (currentUser && currentUser.user && (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_SUPER_ADMIN")) {
                handleClose();
                handleOpenRegistration(element);
              }
            }}>
            <div className="titleAndType">
              <h6>{element.improvement_title}</h6>
              <span
                className={classes.improvementLabelStyle}
                style={{ backgroundColor: element.improvement_type_color }}>
                {element.improvement_type}
              </span>
            </div>
            <div className={classes.descriptionAndLabelStyle}>
              <span
                className={classes.descriptionStyle}>
                {element.improvement_description}
              </span>
            </div>
          </div>
        )
      });

      if (iterator < numberOfElements) {
        arrayToRender.push(
          <div className={classes.dividerStyle}>
            <Divider />
          </div>
        )
      }
    })
    return (arrayToRender);
  }

  async function doGetImprovements() {
    const response = await getAllImprovements();

    if (response.success && response.body) {
      setImprovements(response.body.rows);
    } else {
      console.error("RESPONSE ERROR: ", response.error);
    }
  }

  useEffect(() => {
    if (open && type === 'notifications') doGetImprovements();
  }, [open]);

  useEffect(() => {
    setFormattedImprovements(renderImprovements(formatByDate(improvements)));
  }, [improvements, currentUser]);

  useEffect(() => {
    if (update) {
      doGetImprovements();
      handleListUpdate()
    }
  }, [update]);

  const JOAO_PESSOA_ID = 4;
  const SAO_GONCALO_CE_ID = 185;
  const isClientOnPluggyClient = useMemo(() => clientOn?.client && (clientOn?.client.id === JOAO_PESSOA_ID || clientOn?.client.id === SAO_GONCALO_CE_ID), [clientOn]);
  const isCurrentUserManager = useMemo(() => currentUser?.user && currentUser?.user.isManager, [currentUser]);
  const isCurrentUserSuperAdmin = useMemo(() => currentUser?.user && currentUser?.user.isSuperAdmin, [currentUser]);
  const isCurrentUserAbleToIntegrate = useMemo(() => (isClientOnPluggyClient && isCurrentUserManager) || (isClientOnPluggyClient && isCurrentUserSuperAdmin), [isClientOnPluggyClient, isCurrentUserManager, isCurrentUserSuperAdmin]);
  const [clientAlreadyIntegrate, setClientAlreadyIntegrate] = useState();

  useEffect(() => {
    if (isCurrentUserAbleToIntegrate) {
      setClientAlreadyIntegrate(!!clientOn?.client.pluggy_item_id);
    };
  }, [clientOn]);

  const triggerPluggyAlert = useMemo(() => {
    const condition = isCurrentUserAbleToIntegrate && clientAlreadyIntegrate === false;

    if (condition) {
      dispatch(allActions.notificationActions.setNotifications({
        newNotifications: [{
          type: 'pluggy_integration'
        }]
      }));
    }

    return condition;
  }, [isCurrentUserAbleToIntegrate, clientAlreadyIntegrate]);

  const [token, setToken] = useState();
  const { triggerErrorAlert } = useDisplayErrorMessageOnPluggyError();

  useEffect(() => {
    async function fetchToken() {
      try {
        const { data: {
          accessToken
        } } = await PluggyController.auth(clientOn?.client.id);
        setToken(accessToken);
      } catch (error) {

        if(clientOn?.client?.pluggy_item_id){

          triggerErrorAlert(error);
        }
      }
    }

    if (clientOn?.client) fetchToken();
  }, [clientOn]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={'sm'}
        fullWidth={true}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <CustomDialogTitle
          title={type === 'notifications' ? 'Novidades' : type === 'pluggy_integration' ? 'Dados da Pluggy' : ''}
          subtitle={type === 'notifications' ? 'Veja as novidades que chegaram!' : type === 'pluggy_integration' ? 'Faça o download dos dados disponíveis a partir da API da Pluggy' : ''}
        />
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description" style={{
            wordBreak: 'break-all'
          }}>
            {type === 'notifications' ?
              (
                <>
                  {triggerPluggyAlert && token && (
                    <Box style={{
                      marginBottom: 16
                    }}>
                      <PluggyAlert connectToken={token} setPluggyAlertVisibility={setClientAlreadyIntegrate} />
                    </Box>
                  )
                  }
                  {formattedImprovements}
                </>
              )
              : type === 'pluggy_integration' ? (
                <>
                  <UnoButton
                    type="success"
                    startIcon={<GetAppIcon />}
                    onClick={() => {
                      downloadFile(data, 'pluggy-data.json', "text/plain");
                    }}
                  >
                    Baixar
                  </UnoButton>
                  <p>
                    {JSON.stringify(data)}
                  </p>
                </>
              ) : ''
            }
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.actionsFlex}>
          <UnoButton
            onClick={handleClose}
            color="secondary">
            Fechar
          </UnoButton>

          {type === 'notifications' && currentUser && currentUser.user && (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_SUPER_ADMIN") &&
            <UnoButton
              onClick={() => {
                handleClose();
                handleOpenRegistration(null);
              }}
              color="primary">
              Nova Melhoria
            </UnoButton>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
