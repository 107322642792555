import React, { useEffect } from 'react';
import { IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import allActions from '../../../actions';
import { unoTheme, useColors } from '../../../assets/styles/unoTheme';
import MyTable from '../../utils/MyTable';
import { applyMaskAmount, applyMaskAccount, getTitleCod } from '../../utils/utils';
import { checkFinalLabelDistribuitionTable } from '../../utils/distribution';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiTableContainer-root': {
      height: 'calc(100vh - 103px) !important',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: unoTheme.hoverTable,
  },
  leftHeader: {

    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
  },
  fundLabel: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#1C2228'
  },
  accountLabel: {
    fontSize: '14px',
    color: unoTheme.secondaryColor,
  },
  spacingLeft2: {
    marginLeft: theme.spacing(2)
  },
  green: {
    color: unoTheme.greenMoney,
  },
}));


export default function AssetsFromFormat({ content }) {
  const dispatch = useDispatch();

  const {
    data: { assets, info }
  } = content;

  const classes = useStyles();
  const colors = useColors();

  useEffect(() => {
    console.log("AssetsFromFormat: ", assets);
  })

  return (
    <div className={classes.root}>

      <Paper style={{ marginBottom: '4px' }}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div>
              <Typography
                align={'left'}
                className={classes.fundLabel}>
                {checkFinalLabelDistribuitionTable(info.label)}
              </Typography>
              <Typography
                align={'left'}
                className={classes.accountLabel}>
                <span>PERCENTUAL: <span style={{ color: unoTheme.mainColor }}>{info.percent}%</span></span>
                <span className={classes.spacingLeft2}>
                  VALOR:
                  <Typography component="strong" className={colors.green}> {info.formatedAmount} </Typography>
                </span>

              </Typography>
            </div>
          </div>

          <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
            <CloseIcon />
          </IconButton>

        </div>
      </Paper>
      <MyTable
        stickyHeader
        nofixed={true}
        size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Fundo / Título</TableCell>
            <TableCell align="center">CC</TableCell>
            {/* <TableCell align="center">Agência</TableCell> */}
            <TableCell align="right">Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset, index) => (
            <TableRow key={'clients-from-fund-' + index}>
              <TableCell align="left">{asset.fund_id ? asset.fund_name : getTitleCod(asset)}</TableCell>
              <TableCell align="center">{asset.number_account ? applyMaskAccount(asset.number_account) : '-'}</TableCell>
              {/* <TableCell align="center">{asset.agency ? asset.agency : "-"}</TableCell> */}
              <TableCell align="right" className={classes.green}>{asset.balance_now != null ? applyMaskAmount(asset.balance_now, true) : "-"}</TableCell>
            </TableRow>))}

        </TableBody>
      </MyTable>
    </div >
  )
}
