import React, {
    useEffect,
    useState,
    useRef
} from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import _, { set } from 'lodash';

//API
import {
    getClientAssetsTitles,
} from '../../../API/title'

import {
    getClientTitlesBalancesValidationsByYearAndRegime
} from '../../../API/client'

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'
import { applyMaskAmount, applyMaskAccount, getTitleCod } from "../../utils/utils";
import { Typography } from "@material-ui/core";

//Tables
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment';

//Utils
import { justNumbers, removeAccents, getMonthAndYearBefore } from '../../utils/utils';
import { formatInfosToShow, isGenericTitle } from "../../../controllers/TitleController";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney,

    },
    greenIcon: {
        color: unoTheme.greenMoney,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }

    },
    pendent: {
        color: unoTheme.warningColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '& td:first-child': {
            paddingLeft: '16px !important',
        },
        '&:hover': {
            background: unoTheme.hoverTable,
        }

    },
    tabs: {
        height: '64px',
        minHeight: '64px',
        width: '100%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',

        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: unoTheme.mainColor,
        },

        '& .MuiTabs-indicator': {
            backgroundColor: unoTheme.mainColor,
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    headerYear: {
        background: unoTheme.mainColor,
        color: 'white',
    },
    table: {

        '& .MuiTableRow-head': {
            borderRadius: '10px',
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 16px 16px 16px',
                background: 'white',
            },
        },

        '& .MuiTableCell-root': {

            padding: '4px',
            maxWidth: '0px',

            '& .spanAsset': {
                color: 'blue'
            }

        }

    },
    labelCell: {
        fontSize: '0.8em',
    },
    labelCellTop: {
        fontSize: '0.8em',
        fontWeight: 'bold'
    },
    labelCellBottom: {
        fontSize: '0.8em',
    },
    validInput: {
        cursor: 'pointer',
        '& input': {
            cursor: 'pointer',
        }
    },
    customDisable: {
        '& .MuiOutlinedInput-root': {
            background: "#e7ffe5 !important",
        },
    },
    smallText: {
        fontSize: '.9em'
    },

    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    input: {
        display: 'none',
    },

    validationCell: {
        '& div': {
            '& span': {
                color: 'black',
                fontWeight: '900',
                fontSize: '12px',
                marginRight: '8px'
            }
        }
    }



}));

export default function ValidationFundsScreen(props) {

    const classes = useStyles();

    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)

    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [regimeOnScreen, setRegimeOnScreen] = useState(undefined);

    const [assetsTitles, setAssetsTitles] = useState(undefined);




    async function doGetAssetTitles(year, clientId, regime) {

        setAssetsTitles([]);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: "Carregando ativos de títulos",
        }))

        const responseTitleAssets = await getClientAssetsTitles(year, clientId, regime);
        console.log("RESPONSE TITLES: ", responseTitleAssets);
        if (responseTitleAssets.success) {

            if (responseTitleAssets.body.rows.length === 0) {

                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Nenhum ativo de título cadastrado",
                }))
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                }))

                const responseBv = await getClientTitlesBalancesValidationsByYearAndRegime(year, clientId, regime);

                if (responseBv.success) {

                    console.log("RESPONSE: ", responseBv)
                    setAssetsTitles(formatInfosToShow(year,
                        responseTitleAssets.body.rows,
                        responseBv.body.rows));

                } else {
                    console.log("error: ", responseBv.error)
                }


            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "error",
                message: "Falha ao consultar ativos de título: " + responseTitleAssets.body.message
            }))

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }))
        }

    }

    function getCellValidation(assetTitleId, init, balanceMonthValidation, month) {

        if (month < init) {
            return (

                <TableCell
                    key={'title-month-validation-' + month + "-" + assetTitleId}
                    align="center" >
                    <Typography className={classes.smallText}>
                        {'-'}
                    </Typography>
                </TableCell>
            )

        } else {
            return (
                <TableCell
                    key={'title-month-validation-' + month + "-" + assetTitleId}
                    align="center"
                    className={[classes.validInput].join(' ')}>
                    <Tooltip
                        title={applyMaskAmount(balanceMonthValidation && balanceMonthValidation.balance ? balanceMonthValidation.balance : 0, true)}
                        placement="top"
                        arrow>
                        <Typography className={[classes.smallText, balanceMonthValidation && balanceMonthValidation.balance !== null ? classes.greenIcon : classes.pendent].join(' ')}>
                            {balanceMonthValidation && balanceMonthValidation.balance !== null ? <CheckCircleIcon /> : <ReportProblemIcon />}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )
        }

    }

    function getDescriptionTax(row) {

        let taxType = '';
        let taxDescription = '';

        if (row.indexer) {
            if (row.indexer == "CDI") {
                taxType += row.indexer + ":";
                taxDescription = applyMaskAmount(row.indexer_percent) + "%";
            } else {
                taxType = 'IPCA'
            }

        } else if (row.pre_tax) {
            taxType += "Taxa pré:";
            taxDescription = applyMaskAmount(row.pre_tax) + "%";
        }

        return (
            <><span style={{ marginLeft: '8px' }}>{taxType}</span>{taxDescription}</>
        );
    }

    const { regime } = props

    useEffect(() => {

        if (clientOn && clientOn.client
            && (
                !clientOnScreen
                || clientOnScreen.id != clientOn.client.id
                || regime != regimeOnScreen
            ) && clientOn.client.selectedValidationYear) {

            setRegimeOnScreen(regime);
            setClientOnScreen(_.cloneDeep(clientOn.client));


            doGetAssetTitles(clientOn.client.selectedValidationYear,
                clientOn.client.id,
                regime);

        } else if (clientOn
            && clientOn.client
            && clientOnScreen
            && clientOn.client.selectedValidationYear
            && (clientOn.client.selectedValidationYear != clientOnScreen.selectedValidationYear)) {

            console.log('PROCESSO 2')
            setRegimeOnScreen(regime);


            setClientOnScreen(_.cloneDeep(clientOn.client));
            doGetAssetTitles(clientOn.client.selectedValidationYear,
                clientOn.client.id,
                regime);

        }

        //Quando salva-se o que foi feito na tela de rightcontent e precisa-se atualizar os dados 
        //da tela principal
        if (rightContent && rightContent.action) {
            console.log('PROCESSO 3')
            setRegimeOnScreen(regime);
            dispatch(allActions.rightContentActions.setRightContent(null))

            doGetAssetTitles(clientOn.client.selectedValidationYear,
                clientOn.client.id,
                regime);
        }

    }, [
        clientOn,
        rightContent,
        regime
    ])

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>


                <MyTable
                    stickyHeader
                    size="small"
                    selectable="true"
                    customclass={classes.table}>

                    <colgroup>
                        <col width="25%" />
                    </colgroup>


                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Título</TableCell>
                            <TableCell align="center">JAN</TableCell>
                            <TableCell align="center">FEV</TableCell>
                            <TableCell align="center">MAR</TableCell>
                            <TableCell align="center">ABR</TableCell>
                            <TableCell align="center">MAI</TableCell>
                            <TableCell align="center">JUN</TableCell>
                            <TableCell align="center">JUL</TableCell>
                            <TableCell align="center">AGO</TableCell>
                            <TableCell align="center">SET</TableCell>
                            <TableCell align="center">OUT</TableCell>
                            <TableCell align="center">NOV</TableCell>
                            <TableCell align="center">DEZ</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {assetsTitles && assetsTitles.map((row, index) => (
                            <TableRow key={'asset-title-' + index}
                                className={classes.selectableRow}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'detailBalanceTitleValidation',
                                    update: true,
                                    selectedYear: clientOn.client.selectedValidationYear,
                                    data: row,
                                }))}>

                                <TableCell className={classes.validationCell} align="left">
                                    <div><span>CC:</span>{row.number_account ? applyMaskAccount(row.number_account) : '-'}</div>
                                    <div><span>Título:</span>{row.label_title_type + (row.due_date ? (' - ' + moment.utc(row.due_date).format('DD/MM/YYYY')) : '')}</div>
                                    <div><span>COD:</span>{getTitleCod(row)}</div>
                                    {
                                        isGenericTitle(row) ?
                                            <div><span>NOME:</span>{row.asset_name}</div>
                                            :
                                            <div><span>MARC:</span>{row.marking} {getDescriptionTax(row)}</div>
                                    }
                                    {/* <div><span>COMPRA:</span>{moment.utc(row.purchase_date).format("DD/MM/YYYY")}</div>
                                    <div><span>VENC:</span>{moment.utc(row.due_date).format("DD/MM/YYYY")}</div> */}
                                </TableCell>

                                {row.yearValidation
                                    && row.yearValidation.months
                                    && _.map(row.yearValidation.months, (monthValidation, m) => (

                                        getCellValidation(row.id, row.yearValidation.init, monthValidation, m)

                                    ))
                                }


                                {/* <TableCell align="left" style={{ paddingLeft: '16px', paddingRight: '16px' }}>{getTitleCod(row)}</TableCell>
                                <TableCell align="right" style={{ paddingLeft: '16px', paddingRight: '16px' }}>{applyMaskAmount(row.price_title, true)}</TableCell>
                                <TableCell style={{ paddingLeft: '16px', paddingRight: '16px' }} align="right">{applyMaskAmount(row.quantity_purchased)}</TableCell>
                                <TableCell align="center">{moment.utc(row.purchase_date).format("DD/MM/YYYY")}</TableCell>
                                <TableCell align="center">{moment.utc(row.due_date).format("DD/MM/YYYY")}</TableCell> */}

                            </TableRow>
                        ))}
                    </TableBody>

                </MyTable>

            </div>



        </div>
    );
}



