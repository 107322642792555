import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import _ from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getArrayYears, applyMaskAmount, applyMaskAccount, getMonthName, isValidCpf, justNumbers, isValidEmail } from '../utils/utils';
import UnoButton from '../utils/UnoButton';

import { Box, Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import InfoIcon from '@material-ui/icons/Info';

import MyTextField from '../utils/MyTextField';

import ReactCSSTransitionGroup from 'react-transition-group';
import { getUserEmailByCpf } from '../../API/user';
import MyInnerLoad from '../utils/MyContentLoadModal';
import { saveReqPass, sendResetingPassword } from '../../API/mail';
import allActions from '../../actions';

const useStyles = makeStyles((theme) => ({

    root: {

        '& .MuiPaper-root': {
            overflow: 'hidden',
        }
    },

    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',

    },

    dialogContent: {

        '& .MuiBox-root': {
            textAlign: 'center',
        },

        '& .processReset': {
            height: '150px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
            overflow: 'hidden',

            '& .part1': {
                height: '150px',
                position: 'absolute',
                transition: 'all .5s ease',
                display: 'none',
            },

            '& .part1.active': {
                display: 'block',
            },

            '& .part2': {
                width: '396px',
                minWidth: '396px',
                transition: 'all .5s ease',
                height: '150px',
                display: 'none',
                position: 'absolute',
                left: '100%',
            },

            '& .part2.block': {
                display: 'block',
            },

            '& .part2.active': {
                display: 'block',
                left: '0px',
            },

            '& .part3': {
                width: '396px',
                minWidth: '396px',
                transition: 'all .5s ease',
                height: '150px',
                display: 'none',
                position: 'absolute',
                left: '100%',
            },

            '& .part3.block': {
                display: 'block',
            },

            '& .part3.active': {
                display: 'block',
                left: '0px',
            },
        }




    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function ResetingPasswordModal(props) {

    const { cpf, open, handleCloseResetingPasswordModal } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const [inputCpf, setInputCpf] = useState("");
    const [hintEmail, setHintEmail] = useState("");
    const [originalEmail, setOriginalEmail] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [processActivePart, setProcessActivePart] = useState(1);
    const [processing, setProcessing] = useState(false);
    const [blockPart2, setBlockPart2] = useState(false);
    const [blockPart3, setBlockPart3] = useState(false);

    const [submmitedCpf, setSubmmitedCpf] = useState(false);
    const [submmitedEmail, setSubmmitedEmail] = useState(false);


    const [validateMessage, setValidateMessage] = useState("");

    function formatHintEmail(email) {

        const showBeforeHides = 2;
        const showAfterHides = 1;

        const indexOfArroba = email.indexOf("@");
        const sizeEmailBeforeArroba = indexOfArroba;
        const numberHideChars = sizeEmailBeforeArroba - showBeforeHides - showAfterHides;

        let hides = '';
        for (let index = 1; index <= numberHideChars; index++) {
            hides += "*";
        }
        return email.substring(0, showBeforeHides) + hides + email.substring(indexOfArroba - showAfterHides);
    }

    function errorResetPass() {
        handleCloseResetingPasswordModal('Falha ao obter email válido do cadastro!');
    }

    async function submmitCpf(cpf) {

        setProcessing(true);

        setSubmmitedCpf(true);
        if (cpf && isValidCpf(justNumbers(cpf))) {

            if (!blockPart2) {
                setBlockPart2(true);
            }

            const response = await getUserEmailByCpf(cpf);
            if (response.success && response.body.rows && response.body.rows.length == 1) {

                const originalEmail = response.body.rows[0].email;
                setHintEmail(formatHintEmail(originalEmail));

                const responseSaveReqPass = await saveReqPass(originalEmail, cpf);
                if (responseSaveReqPass.success) {

                    setProcessActivePart(2);

                } else {
                    errorResetPass();
                }
            } else {
                errorResetPass();
            }
        }

        setProcessing(false);


    }

    useEffect(() => {
        if (open === true) {

            setProcessing(false);

            setProcessActivePart(1);
            setBlockPart2(false);
            setBlockPart3(false);
            setInputCpf("");
            setHintEmail("");
            setInputEmail("");

            setSubmmitedCpf(false);
            setSubmmitedEmail(false);

        }
    }, [open])

    return (
        <Dialog
            className={classes.root}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
        //onClose={() => handleCloseResetingPasswordModal()}
        >
            <DialogTitle
                className={classes.dialogTitleApr}>

                {
                    <>

                        {
                            processActivePart === 2 ?
                                <CheckIcon style={{ color: 'green', marginRight: '16px' }} />
                                :
                                <InfoIcon className={[classes.spacingRight2, classes.blue].join(' ')} />
                        }

                        {processActivePart === 1 ? 'Confirme o CPF' : 'Verifique seu email'}

                    </>
                }

            </DialogTitle>


            <DialogContent
                className={classes.dialogContent}>

                <div className={'processReset'}>
                    <div className={`part1 ${processActivePart === 1 ? 'active' : ''}`}>
                        <Typography component="legend">Para proosseguir no processo de resgate de senha insira seu <span style={{ fontWeight: 'bold' }}>CPF</span> e clique em avançar.</Typography>
                        <MyTextField
                            style={{ marginTop: '16px' }}
                            fullWidth={true}
                            value={inputCpf}
                            name="cpf-reset"
                            size={'small'}
                            onChange={(value) => setInputCpf(value)}
                            variant="outlined"
                            helperText={"Insira um CPF válido"}
                            validator={!isValidCpf(inputCpf) && submmitedCpf ? true : undefined}
                            autoFocus={true}
                            mask={['cpf']}
                            label="CPF" />
                    </div>


                    <div className={`part2 ${processActivePart === 2 ? 'active' : ''} ${blockPart2 ? 'block' : ''}`}>

                        <Typography component="legend">Foi enviado ao seu email um link para redefinição de senha.</Typography>
                        <Typography style={{ fontWeight: 'bold', padding: '8px 16px', borderRadius: '10px', backgroundColor: '#8ded8d', textAlign: 'center', margin: '24px 2px' }}>{hintEmail}</Typography>
                        {/* <Typography component="legend">Insira o email cadastrado no UNO, ele segue o seguinte padrão:</Typography>
                        <Typography style={{ fontWeight: 'bold', margin: '0px 2px' }}>{hintEmail}</Typography>

                        <MyTextField
                            style={{ marginTop: '16px' }}
                            fullWidth={true}
                            value={inputEmail}
                            name="email-reset"
                            size={'small'}
                            onChange={(value) => setInputEmail(value)}
                            variant="outlined"
                            helperText={"Insira um email válido"}
                            validator={!isValidEmail(inputEmail) && submmitedEmail ? true : undefined}
                            autoFocus={true}
                            label="Email" /> */}
                    </div>

                    <div className={`part3 ${processActivePart === 3 ? 'active' : ''} ${blockPart3 ? 'block' : ''}`}>

                        <Typography component="legend">Foi enviado ao seu email um link para redefinição de senha.</Typography>
                        <Typography style={{ fontWeight: 'bold', textAlign: 'center', margin: '24px 2px' }}>{originalEmail}</Typography>

                    </div>

                    {
                        processing ?
                            <MyInnerLoad small message="Validando informações" show={processing} /> : null
                    }
                </div>


            </DialogContent>




            <DialogActions className={classes.actionsFlexRight}>

                <UnoButton
                    onClick={() => handleCloseResetingPasswordModal()}>
                    {'Fechar'}
                </UnoButton>

                {
                    processActivePart == 1 ?
                        <UnoButton
                            disabled={processing}
                            onClick={() => {
                                //processActivePart == 1 ? submmitCpf(inputCpf) : submmitEmail(inputEmail)
                                submmitCpf(inputCpf)
                            }}
                            color="primary">
                            {'Avançar'}
                        </UnoButton> : null
                }



            </DialogActions>




        </Dialog >
    );
}