import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import { unoTheme, tabs, toogleButton, headerSearch } from '../../../assets/styles/unoTheme'

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';

//Utils
import { getLastDayInMonthByMonthAndYear } from "../../utils/utils";

import { MyTabs, MyTab } from "../../utils/MyTabs";
import ArticlesTableScreen from "./ArticlesTableScreen"

import { Tooltip } from "@material-ui/core";

//Controllers
import { getDataToArticleReport } from "../../../controllers/ReportController";


//Api
import { getResolutionVersions, listLegislationSegmentsByDate } from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import UnoButton from "../../utils/UnoButton";


import PrintIcon from '@material-ui/icons/Print';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    arrow: {
        color: theme.palette.common.white,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableArticles: {
        width: '100%',

    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        paddingBottom: theme.spacing(1),
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
    toogleButton: toogleButton,

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },
    fabEdit: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    fabDone: {
        position: 'absolute',
        backgroundColor: green[500],
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: green[700],
        }
    },


}));

export default function ArticlesScreen(props) {

    const { nextYearPolicySelected } = props;

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const [formats, setFormats] = React.useState(() => 'carteira');
    const handleFormat = (event, newFormats) => {
        if (newFormats && newFormats.length) {
            setFormats(newFormats);
        }
    };

    const [resolutionsVersions, setResolutionsVersions] = useState(null);
    const [resolutionVersionSelected, setResolutionVersionSelected] = useState(null);

    const [clientOnScreen, setClientOnScreen] = useState(null);

    const [tabs, setTabs] = React.useState(null);
    const [legislationSegments, setListLegislationSegments] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [editTargets, setEditTargets] = React.useState(false);

    //Variável que guarda os inputs alterados nas telas das tabs
    //Alterações referentes ao inputamento dos targets
    const [inputs, setInputs] = useState({});
    const [appearFab, setAppearFab] = useState(true);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        // if (newValue == 0) {
        //     history.push('/analysis/articles/fixed')
        // } else if (newValue == 1) {
        //     history.push('/analysis/articles/variable')
        // } else {
        //     history.push('/analysis/articles/external')
        // }

    };


    async function loadSegmentsByDate(date) {

        console.log("DATE: ", date);

        let responseLegislationSegments = await listLegislationSegmentsByDate(date);

        if (responseLegislationSegments.success) {

            console.log("SEGMENTS: ", responseLegislationSegments.body.rows);
            let myTabs = [];
            setListLegislationSegments(responseLegislationSegments.body.rows);
            responseLegislationSegments.body.rows.forEach(segment => {

                myTabs.push(<MyTab label={segment.description} />);

            });
            setTabs(myTabs);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os segmentos de legislação'
            }))

        }
    }

    function doEditTargets() {
        if (editTargets) {
            setEditTargets(false)
        } else {
            setFormats('todos')
            setEditTargets(true)
        }
    }

    async function doReport() {

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'articles',
            message: 'Gerando o relatório...'
        }))

    }

    useEffect(() => {

        console.log('nextYearPolicySelected: ', nextYearPolicySelected);

        if (clientOn
            && clientOn.client
            && clientOn.client.selectedPortfolioMonth
            && clientOn.client.selectedPortfolioYear) {

            loadSegmentsByDate(
                getLastDayInMonthByMonthAndYear(
                    clientOn.client.selectedPortfolioMonth,

                    nextYearPolicySelected ? (nextYear) : clientOn.client.selectedPortfolioYear
                )
            );
        }

    }, [clientOn,
        nextYearPolicySelected])

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>
                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    {tabs ?
                        <MyTabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTabValue}>

                            {tabs}

                            {/* <MyTab label="Renda fixa" />
                        <MyTab label="Renda variável" />
                        <MyTab label="Investimentos no exterior" /> */}

                        </MyTabs> : null
                    }

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>




                <ToggleButtonGroup
                    className={classes.toogleButton}
                    exclusive value={formats} onChange={handleFormat}>
                    <ToggleButton value="carteira" aria-label="carteira">
                        {'Carteira'}
                    </ToggleButton>
                    <ToggleButton value="todos" aria-label="todos">
                        {'Todos'}
                    </ToggleButton>
                </ToggleButtonGroup>

                <div style={{ marginRight: '16px' }}>
                    <UnoButton
                        isIconButton
                        onClick={() => doReport()}
                        size='small'
                        type='primary'
                    />
                    {/* <UnoButton
                        size={'small'}
                        className={classes.spacingLeft1}
                        onClick={() => doReport()}
                        type='primary'
                        startIcon={<PrintIcon />}>
                    </UnoButton> */}
                </div>

            </div>

            <Switch className={classes.switch}>
                <Route path="/analysis/articles/list">
                    <ArticlesTableScreen
                        handleFab={(value) => setAppearFab(value)}
                        formats={formats}
                        editTargets={editTargets}
                        segment={legislationSegments[tabValue] ? legislationSegments[tabValue] : null}
                        nextYearPolicySelected={nextYearPolicySelected} />
                </Route>
            </Switch>

            {

                currentUser && currentUser.user
                    && (
                        currentUser.user.role == "ROLE_ADMIN"
                        || currentUser.user.role == "ROLE_SUPER_ADMIN"
                        || currentUser.user.role == "ROLE_ADVISOR"
                    ) ?

                    <Zoom
                        in={appearFab}
                        unmountOnExit>

                        <LightTooltip title={editTargets ? "" : "Editar política"} placement="right">
                            <Fab className={editTargets ? classes.fabDone : classes.fabEdit}
                                onClick={() => doEditTargets()}
                                color="primary"
                                aria-label="add">
                                {
                                    editTargets ?
                                        <DoneIcon />
                                        :
                                        <EditIcon />
                                }

                            </Fab>
                        </LightTooltip>
                    </Zoom> : null
            }


        </div>
    );
}



