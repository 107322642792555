import API from './config'

const CLIENT_ID = 'oiMgEDaqwj63';
const ANBIMA_URL = 'https://api.anbima.com.br';
const ANBIMA_SANDBOX_URL = 'https://api-sandbox.anbima.com.br';

// const tokenConfig = {
//     headers: {
//         'Authorization': 'Basic OTNmRXczaDNwM3RPOkNWeEdNRnRKbWxlVQ==',
//         'Content-Type': 'application/json'
//     }
// }

const tokenConfig = {
    headers: {
        'Authorization': 'Basic b2lNZ0VEYXF3ajYzOnhJR3JCeUcwMHhiYQ==',
        'Content-Type': 'application/json'
    }
}

export async function getToken() {

    try {

        const response = await API
            .post('https://api.anbima.com.br/oauth/access-token', {
                "grant_type": "client_credentials"
            }, tokenConfig)

        console.log("RESPONSE TOKEN: ", response)

        return {
            success: true,
            body: response.data
        }

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function serieHistoricaFundoAnbima() {

    // let quotasConfig = {
    //     headers: {
    //         'client_id': '93fEw3h3p3tO',
    //         'access_token': localStorage.getItem('anbimaToken'),
    //     }
    // }

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    try {

        const response = await API
            .get(ANBIMA_URL + '/feed/fundos/v1/fundos-estruturados/25341083000103/serie-historica?data-inicio=2019-11-19&data-fim=2020-11-14',
                quotasConfig)

        console.log("RESPONSE SERIE: ", response)

        return {
            success: true,
            body: response.data.content
        };

    } catch (error) {

        //console.log("ERROR: ", error.response.status)
        if (error.response && error.response.status == 401
            || error.response.status == 403) {

        }

        return {
            success: false,
            body: "RENOVAR TOKEN"
        };
    }

}

export async function getFundsAnbima(page, type) {


    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    let url = "";
    if (type == "555") {
        url = ANBIMA_URL + '/feed/fundos/v1/fundos?page=' + page
    } else if (type == "estruturados") {
        url = ANBIMA_URL + '/feed/fundos/v1/fundos-estruturados?page=' + page
    } else if (type == "offshore") {
        url = ANBIMA_URL + '/feed/fundos/v1/fundos-offshore?page=' + page
    }

    try {

        const response = await API
            .get(url,
                quotasConfig)

        //console.log("RESPONSE QUOTAS: ", response)

        return {
            success: true,
            body: response.data.content
        };

    } catch (error) {

        if (error.response && error.response.status == 401) {
            console.log(error.response);
            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };
        } else {
            return {
                success: false,
                body: error.response
            };
        }


    }

}
export async function getQuotas() {

    // let quotasConfig = {
    //     headers: {
    //         'client_id': '93fEw3h3p3tO',
    //         'access_token': localStorage.getItem('anbimaToken'),
    //     }
    // }

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    try {

        const response = await API
            .get(ANBIMA_URL + '/feed/fundos/v1/fundos?page=0&size=1000',
                quotasConfig)

        //console.log("RESPONSE QUOTAS: ", response)

        return {
            success: true,
            body: response.data.content
        };

    } catch (error) {

        if (error.response && error.response.status == 401) {
            console.log(error.response);
            return {
                success: false,
                body: "RENOVAR TOKEN"
            };
        } else {
            return {
                success: false,
                body: error.response
            };
        }


    }

}

export async function getDetailFund(quotaCod) {

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    if (quotaCod != undefined) {

        quotaCod = quotaCod.toString().padStart(6, "0");

    }

    const urlToCall = ANBIMA_URL + '/feed/fundos/v1/fundos/' + quotaCod;

    try {

        const response = await API
            .get(urlToCall,
                quotasConfig)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {


        if (error.response && error.response.status == 401) {

            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };

        } else {
            return {
                success: false,
                body: error.response.status
            };
        }
    }

}

export async function getHistoricSeriesFund(quotaCod, startDate, endDate) {

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    quotaCod = quotaCod.toString().padStart(6, "0");
    const urlToCall = ANBIMA_URL + '/feed/fundos/v1/fundos/' + quotaCod + "/serie-historica?" + "data-inicio=" + startDate + "&data-fim=" + endDate;

    //console.log("URL TO CALL: ", urlToCall);

    try {

        const response = await API
            .get(urlToCall,
                quotasConfig)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        if (error.response && error.response.status == 401) {

            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };

        } else {
            return {
                success: false,
                body: error.response.status
            };
        }
    }

}

export async function getDetailQuota(quotaCod) {

    quotaCod = quotaCod.toString().padStart(6, "0");

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    try {

        const response = await API
            .get(ANBIMA_URL + '/feed/fundos/v1/fundos/' + quotaCod,
                quotasConfig)

        console.log("RESPONSE QUOTAS: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getExplicationNote(quotaCod) {

    quotaCod = quotaCod.toString().padStart(6, "0");

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    try {

        const response = await API
            .get(ANBIMA_URL + '/feed/fundos/v1/fundos/' + quotaCod + '/notas-explicativas',
                quotasConfig)

        console.log("RESPONSE QUOTAS: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getIndexes() {

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    const urlToCall = ANBIMA_URL + '/feed/precos-indices/v1/indices-mais/resultados-ida';

    try {

        const response = await API
            .get(urlToCall,
                quotasConfig)

        console.log("RESPONSE QUOTAS: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        if (error.response && error.response.status == 401) {

            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };

        } else {
            return {
                success: false,
                body: error.response.data
            };
        }
    }

}

export async function getTitlesByDate(dateAnbima) {

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    const urlToCall = ANBIMA_URL + '/feed/precos-indices/v1/titulos-publicos/mercado-secundario-TPF?data=' + dateAnbima;

    try {

        const response = await API
            .get(urlToCall,
                quotasConfig)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        if (error.response
            && error.response.status == 401) {

            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };

        } else {
            return {
                success: false,
                body: error.response.data
            };
        }
    }

}

export async function getIndexesResultadosIma(month, year) {

    let quotasConfig = {
        headers: {
            'client_id': CLIENT_ID,
            'access_token': localStorage.getItem('anbimaToken'),
        }
    }

    //const urlToCall = ANBIMA_URL + '/feed/precos-indices/v1/indices/resultados-ihfa-fechado?data=2021-05-31';
    const urlToCall = ANBIMA_URL + '/feed/precos-indices/v1/indices/carteira-teorica-ima?mes=' + month.toString().padStart(2, "0") + '&ano=' + year;

    try {

        const response = await API
            .get(urlToCall,
                quotasConfig)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        if (error.response
            && error.response.status == 401) {

            return {
                success: false,
                body: "RENOVAR_TOKEN"
            };

        } else {
            return {
                success: false,
                body: error.response.data
            };
        }
    }

}





