import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


//redux
import { useDispatch } from 'react-redux';



import { myLabelInput, unoTheme } from '../../assets/styles/unoTheme';

import { Tooltip } from "@material-ui/core";
import { Typography } from '@material-ui/core';
import Survey1 from '../../assets/imgs/surveys/survey1_2.png';
import { SurveysAPI } from '../../API/surveys';


const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',

    },

    dialogContent: {

        '& .MuiBox-root': {
            textAlign: 'center',
        },

        '& .imgSurveyContainer': {
            position: "relative",

            '& .coverClick': {
                transition: "ease .3s all",
                opacity: 1,
                position: "absolute",
                backgroundColor: "black",
                top: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                zIndex: 999,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                '&:hover': {
                    opacity: 0.1,
                }
            }
        }



    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #E6E8ED",
        color: "#4A4A4A",
        fontSize: '16px',
    }
}))(Tooltip);

export default function SurveyModal(props) {

    const { client, user, surveyId, open, handleClickSurvey } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();


    async function doClick(surveyId, client, user) {

        const response = await SurveysAPI.postClick(surveyId, client.id, user.id);
        Object.assign(document.createElement('a'), {
            target: '_blank', href: "https://forms.office.com/r/AGxmwm76D4",
        }).click();
        handleClickSurvey();

    }

    useEffect(() => {

    }, [])

    return (
        <Dialog
            open={open}
        >

            <DialogContent className={classes.dialogContent} style={{ padding: "0px", overflow: 'hidden' }}>

                <div className='imgSurveyContainer' style={{ width: "500px", height: "500px", overflow: 'hidden' }}>

                    <img src={Survey1} style={{ width: "100%", height: "100%", cursor: "pointer" }} />

                    <LightTooltip onClick={() => doClick(surveyId, client, user)} button title={"Clique para responder"} enterDelay={100} leaveDelay={100} placement="bottom" arrow>

                        <div className="coverClick">

                        </div>

                    </LightTooltip>

                </div>

            </DialogContent>



        </Dialog >
    );
}