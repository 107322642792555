import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
//API
import { getAllUsersClientByRole } from '../../../../API/client';
import { updateStatus } from '../../../../API/user';
import { headerSearch, unoTheme } from '../../../../assets/styles/unoTheme';
import SituationCell from "../../../utils/SituationCell";
import { applyMaskPhone } from "../../../utils/utils";
import { roles } from '../../../utils/user.js';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
}));


export default function ClientManagersScreen({ clientOnScreen }) {

    const classes = useStyles();

    const currentUser = useSelector(state => state.currentUser)
    const rightContent = useSelector(state => state.rightContent)

    const [managers, setManagers] = useState([]);

    const dispatch = useDispatch();

    const [appearFab, setAppearFab] = useState(true);

    async function doGetAllClientManagers(client) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando gestores...'
        }));

        const response = await getAllUsersClientByRole(client.id, roles.manager.id);
        const managersFromResponse = response.body.rows;

        if (response.success) {

            const isManagersResponseEmpty = isEmpty(managersFromResponse);

            if (isManagersResponseEmpty) {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem gestores registrados' }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            };

            setManagers(managersFromResponse);

        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    }

    async function updateStatusManager(e, clientId, status) {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Atualizando situação do gestor...'
        }));

        const response = await updateStatus(clientId, status);
        if (response.success) {

            await doGetAllClientManagers(clientOnScreen);

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Situação do gestor atualizada com sucesso"
            }));

            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));

        } else {
            console.erro("RESPONSE ERROR: ", response.error)
        }
    }

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        if (clientOnScreen) {
            doGetAllClientManagers(clientOnScreen);
        }

        if (rightContent && rightContent.action) {

            doGetAllClientManagers(clientOnScreen);
            dispatch(allActions.rightContentActions.setRightContent(null))
        };

        return () => {
            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        };

    }, [clientOnScreen, rightContent]);

    return (

        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    onScroll={(value) => setAppearFab(value)}
                    stickyHeader
                    size="small"
                    selectable='true'>
                    {/* <colgroup>
                        <col width="55%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="10%" />
                    </colgroup> */}
                    <TableHead>
                        <TableRow>
                            {/* <TableCell /> */}
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="center">Contato</TableCell>
                            {
                                currentUser
                                    && currentUser.user
                                    && (
                                        currentUser.user.role === roles.admin.title
                                        ||
                                        currentUser.user.role === roles.advisor.title
                                        ||
                                        currentUser.user.role === roles.superadmin.title
                                    ) ?
                                    <TableCell align="center">Situação</TableCell>
                                    : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {managers.map((row, index) => (
                            <TableRow
                                key={'table-row-manager-client-' + row.id}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'newClientManager',
                                    update: true,
                                    clientOnScreen,
                                    data: row
                                }))}>

                                <TableCell align="left" component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="center" >{applyMaskPhone(row.phone_number)}</TableCell>
                                {
                                    currentUser
                                        && currentUser.user
                                        && (
                                            currentUser.user.role === roles.admin.title
                                            ||
                                            currentUser.user.role === roles.advisor.title
                                            ||
                                            currentUser.user.role === roles.superadmin.title
                                        ) ?
                                        <SituationCell align="center" data={row} updateStatus={updateStatusManager} />
                                        : null
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </MyTable>
            </div>

            {
                currentUser
                    && currentUser.user
                    && (
                        currentUser.user.role === roles.admin.title
                        ||
                        currentUser.user.role === roles.advisor.title
                        ||
                        currentUser.user.role === roles.superadmin.title
                    ) ?

                    <Zoom
                        in={appearFab}
                        unmountOnExit>
                        <Fab className={classes.fabAdd}
                            onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                path: 'newClientManager',
                                clientOnScreen
                            }))}>
                            <AddIcon />
                        </Fab>
                    </Zoom>
                    : null
            }


        </div >
    );
}


