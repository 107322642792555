import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
// import Table from '@material-ui/core/Table';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';

//Api
import { listAllLegislationArticles } from '../../../../API/legislation_article'
import { createFund, getAllFunds, getFundsBySearch, updateInfosCarteiraComdinheiroByCNPJ } from '../../../../API/fund'

//redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";

import { unoTheme, headerSearch } from '../../../../assets/styles/unoTheme'
// import UnoButton from "../../../utils/UnoButton";

//Import excel
import XLSX from 'xlsx';
import { SheetJSFT } from '../../../utils/types';
import PublishIcon from '@material-ui/icons/Publish';

import MyInnerLoad from '../../../utils/MyInnerLoad';

//Import fab
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import { isEmpty } from "lodash";
import { useFundsAdministrationStyles } from "./styles";

/**
 * Componente para:
 * - Listagem de fundos do sistema;
 * - Pesquisa de fundos;
 * - Alteração de dados dos fundos;
 * @param {String} search Valor para ser consultado;
 * @returns {JSX.Element}
 */
export default function FundsScreen({
    search
}) {

    const classes = useFundsAdministrationStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)

    const [appearFab, setAppearFab] = useState(true);

    const [funds, setFunds] = useState(undefined);
    const [itemsPage, setItemsPage] = useState(15);
    const [page, setPage] = useState(1);

    //Importação
    const inputFile = useRef(null);
    const [file, setFile] = useState(undefined);
    const [data, setData] = useState([]);
    const [dataFormatted, setDataFormatted] = useState(undefined);

    //const [search, setSearchText] = useState("");

    // const delayedChange = (text) => {
    //     setTimeout(() => {
    //         setSearchText(text)
    //     }, 800)
    // }

    // function changeSearch(e) {

    //     delayedChange(e.target.value.toUpperCase())
    //     clearTimeout(delayedChange);
    // }

    function handleChangeFile(e) {
        const files = e.target.files;
        if (files && files[0]) {
            setFile(files[0])
        }
    };

    function handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            //setData([])
            //console.log("DATA: ", data);
            setData(data);
            console.log("LIMPANDO FILE")
            setFile(undefined);
            //setCols(make_cols(ws['!ref']));
            // this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
            //     console.log(JSON.stringify(this.state.data, null, 2));
            // });

        };

        if (rABS) {
            //reader.readAsBinaryString(this.state.file);
            reader.readAsBinaryString(file);
        } else {
            //reader.readAsArrayBuffer(this.state.file);
            reader.readAsArrayBuffer(file);
        };
    }

    function getArticleIdByDevice(articles, device) {


        let articlesFilter = articles.filter(element =>
            element.device == device
        );
        console.log("ARTICLES FILTER[" + device + "]: ", articlesFilter)
        if (articlesFilter.length == 1) {
            return articlesFilter[0].id;
        } else {
            return "NOT_FOUND_" + device
        }

    }

    async function doInsertsFunds(funds) {

        let fundsToInsert = [];
        console.log("FUNDS: ", funds)

        let responseLegislationArticles = await listAllLegislationArticles();

        console.log("ARTICLES: ", responseLegislationArticles.body.rows);
        const articles = responseLegislationArticles.body.rows;

        let artclesNoFound = [];

        // ID_ANBIMA: "272604"
        // ENQUADRAMENTO: "Artigo 9º - A, Inciso III"
        // CNPJ: "12.987.743/0001-86"
        // ATIVO: "ALASKA BLACK FIC AÇÕES BDR NÍVEL I"

        funds.forEach(element => {

            if (element['UTILIZAÇÃO']
                && element['UTILIZAÇÃO'] != null
                && element['UTILIZAÇÃO'] != ""
                && element['UTILIZAÇÃO'] != " ") {

                let laId = null;
                if (element['ENQUADRAMENTO']
                    && !element['ENQUADRAMENTO'].toString().includes("Em Enquadramento")) {
                    laId = getArticleIdByDevice(articles, element['ENQUADRAMENTO']);

                    if (laId.toString().includes("NOT_FOUND_")
                        // && !artclesNoFound.includes(laId.toString())
                    ) {
                        //artclesNoFound.push(laId);
                        laId = null;
                        artclesNoFound.push(element['CNPJ'] + " / " + element['ENQUADRAMENTO']);
                    }
                }

                let newFund = {
                    legislation_article_id: laId,
                    anbima_cod: element['ID_ANBIMA'],
                    cnpj: element['CNPJ'],
                    fund_name: element['ATIVO'],
                    benchmark: element['BENCHMARK'] ? element['BENCHMARK'] : "",
                    manager_fund: "",
                    cnpj_manager_fund: "",
                    admin_fund: "",
                    cnpj_admin_fund: "",
                }

                fundsToInsert.push(newFund)

            }


        });

        console.log("FUNDS TO INSERT: ", fundsToInsert)
        console.log("FUNDS NOT FOUND: ", artclesNoFound)

        //Pode-se incluir os fundos
        let allSuccess = true;
        //if (artclesNoFound.length == 0) {

        //const responseFund = await createFund(fundsToInsert[0]);

        fundsToInsert.forEach(async (element, index) => {

            const responseFund = await createFund(element);

            if (responseFund.success) {

            } else {
                allSuccess = false;
            }

        });

        //Reinicializando
        setData([]);
        setDataFormatted(undefined);

        if (allSuccess) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: '[' + fundsToInsert.length + ']' + ' fundos importados com sucesso!'
            }))

            getFunds();

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao importar fundos"
            }))
        }

        //} 
        // else {
        //     dispatch(allActions.mainAlertActions.setMainAlert({
        //         show: true,
        //         type: 'warning',
        //         message: "Existem fundos irregulares na base de dados"
        //     }))
        // }

    };

    async function getFunds(search = '') {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando fundos...'
        }));

        let responseFunds = {};

        if (isEmpty(search)) {
            responseFunds = await getAllFunds();
        } else {
            responseFunds = await getFundsBySearch(search);
        };

        if (responseFunds.success) {
            const responseFundsData = responseFunds.body.rows;

            if (isEmpty(responseFundsData)) {
                setFunds([]);
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem fundos' }));
            } else {
                setFunds(responseFundsData);
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            };
        } else {
            setFunds([]);
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter fundos"
            }));
        }

    }

    function doClickinputFile() {
        inputFile.current.click()
    };

    useEffect(() => {


        if (file && data.length == 0) {
            handleFile();
        }

        if (data && data.length > 0 && !dataFormatted) {

            //console.log("DATA: ", data)

            let dataFormatted = [];
            for (let i = 0; i < data.length; i++) {
                dataFormatted.push(data[i])
            }

            console.log(dataFormatted);

            setDataFormatted(dataFormatted);

        };

        if (!funds
            //|| search || isEmpty(search)
        ) {
            getFunds();
        }

        if (funds) {
            //console.log("SEARCHING: ", search);
            getFunds(search);
        }

        if (dataFormatted) {
            //console.log("DATA FORMATADA: ", dataMonthYearFormatted)
            doInsertsFunds(dataFormatted);
        }

        if (rightContent && rightContent.action) {

            getFunds();
            //setSearchText("");
            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [clientOn,
        file,
        dataFormatted,
        rightContent,
        search
    ])

    return (
        <div className={classes.root}>

            {/* <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Fundos
                </Typography>
                <div className={classes.rightHeader}>

                    {
                        funds && funds.length > 0 ?
                            <>
                                <TextField id="standard-basic"
                                    onChange={(event) => changeSearch(event)}
                                    className={classes.inputSearch}
                                    label="Buscar..."
                                    variant="outlined"
                                    size="small" />

                            </> : null
                    }

                    <UnoButton
                        className={classes.spacingLeft2}
                        variant="contained"
                        type='primary'
                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                            path: 'newFund',
                        }))}
                        startIcon={<AddIcon />}>
                        {"NOVO FUNDO"}
                    </UnoButton>

                    <input
                     ref={inputFile}
                        className={classes.input}
                        id="file"
                        multiple
                        accept={SheetJSFT}
                        type="file"
                        onChange={handleChangeFile}
                    />

                    <label htmlFor="file" className={classes.spacingLeft2}>
                        <UnoButton
                            variant="contained"
                            type='success'
                            component="span"
                            endIcon={<PublishIcon />}>
                            {"Importar fundos"}
                        </UnoButton>
                    </label>

                    <UnoButton className={classes.spacingLeft2}
                        variant="contained"
                        type='warning'>
                        {"Verificar BENCHMARKS"}
                    </UnoButton>
                </div>

            </div> */}
            <div class={'defaultScrollWithHeaderContent'}>
                {
                    funds ?

                        <MyTable
                            pagination={isEmpty(search) && funds && funds.length > 0}
                            itemspage={itemsPage}
                            totalitems={funds ? funds.length : 0}
                            handlePage={(value) => setPage(page + value)}
                            stickyHeader
                            selectable="true"
                            size="small">
                            <colgroup>
                                {/* <col width="5%" /> */}
                                <col width="45%" />
                                <col width="15%" />
                                <col width="25%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell className={classes.tableTh} align="center">Cod</TableCell> */}
                                    <TableCell className={classes.tableTh} align="left">Nome do fundo</TableCell>
                                    <TableCell className={classes.tableTh} align="center">CNPJ</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Enquadramento</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Benchmark</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(isEmpty(search) ? funds
                                    .slice(
                                        (page * itemsPage) - (itemsPage),
                                        itemsPage + (itemsPage * (page - 1))
                                    ) : funds).map((row) => (
                                        <>
                                            {
                                                <TableRow
                                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                                        path: 'newFund',
                                                        update: true,
                                                        data: row
                                                    }))}
                                                    className={classes.quotaRow}
                                                    key={'fund' + row.fund_name}>
                                                    {/* <TableCell align="center">{row.id}</TableCell> */}
                                                    <TableCell align="left" component="th" scope="row">{row.fund_name}</TableCell>
                                                    <TableCell align="center">{row.cnpj}</TableCell>
                                                    <TableCell align="center">{row.article_v2}</TableCell>
                                                    {/* <TableCell align="center">{row.article} - <span style={{ color: unoTheme.mainColor }}>{
                                                        row.segment == 'Renda Fixa' ?
                                                            'RF'
                                                            :
                                                            <>
                                                                {
                                                                    row.segment == 'Renda Variável' ?
                                                                        'RV'
                                                                        :
                                                                        'IE'
                                                                }
                                                            </>
                                                    }</span></TableCell> */}
                                                    <TableCell align="center">{row.benchmark}</TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                            </TableBody>
                        </MyTable>

                        :
                        <>
                            <MyInnerLoad message={'Carregando fundos...'} />
                        </>
                }


            </div>

            <input
                ref={inputFile}
                className={classes.input}
                id="file"
                multiple
                accept={SheetJSFT}
                type="file"
                onChange={handleChangeFile}
            />


            {

                //Caso não existam fundos exibir opção de importação
                funds && funds.length == 0 ?

                    <Zoom
                        in={appearFab}
                        unmountOnExit>
                        <Fab className={classes.fabAdd}
                            onClick={() => doClickinputFile()}
                            color="primary" aria-label="add">
                            <PublishIcon />
                        </Fab>
                    </Zoom>
                    : null
            }



        </div >
    );
}



