// Styles
import { IconButton, makeStyles, Typography, Paper } from '@material-ui/core';
import { unoTheme } from '../../../assets/styles/unoTheme';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

// Data
import React, { useEffect, useState } from 'react';
import { getAllClientsFromFund } from '../../../API/fund';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';
import { getConsultingId } from '../../utils/utils';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',

    '& .tableContainer': {
      height: 'calc(100% - 100px)',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: unoTheme.hoverTable,
  },
  leftHeader: {

    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
  },
  fundLabel: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#1C2228'
  },
  accountLabel: {
    fontSize: '14px',
    color: unoTheme.secondaryColor,
  },
  spacingLeft2: {
    marginLeft: theme.spacing(2)
  },
}));


function ClientsFromFund({ content }) {
  const [clients, setClients] = useState([]);
  const { data } = content;
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser)

  const classes = useStyles();

  async function doGetClientsFromFund(fund_id) {
    const { success, body, error } = await getAllClientsFromFund(fund_id, getConsultingId(currentUser));

    if (success && body) {
      setClients(body.rows);
    } else {
      console.error("RESPONSE ERROR: ", error);
    }
  }

  useEffect(() => {
    doGetClientsFromFund(data.fund_id);
  }, []);

  return (
    <div className={classes.root}>

      <Paper style={{ marginBottom: '4px' }}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div>
              <Typography
                align={'left'}
                className={classes.fundLabel}>
                {data.fund_name}
              </Typography>
              <Typography
                align={'left'}
                className={classes.accountLabel}>
                <span>CNPJ: {data.fund_cnpj}</span>
                <span className={classes.spacingLeft2}>QTD CLIENTES: {data.number_of_clients}</span>

              </Typography></div>

          </div>

          <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
            <CloseIcon />
          </IconButton>

        </div>
      </Paper>
      <div className="tableContainer">
        <MyTable
          stickyHeader
          nofixed={true}
          size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Município - UF</TableCell>
              <TableCell align="left">Nome do RPPS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, index) => (
              <TableRow key={'clients-from-fund-' + index}>
                <TableCell align="left">{client.city_uf}</TableCell>
                <TableCell align="left">{client.rpps_name.toUpperCase()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MyTable>
      </div>
    </div >
  )
}

export default ClientsFromFund;
