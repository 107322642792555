import React, { useEffect } from "react";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { unoTheme, myInput, myLabelInput, toogleButton } from "../../assets/styles/unoTheme";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
    toogleButton: toogleButton,
}));

export default function MyToggleSegments(props) {

    const classes = useStyles();

    const { segments, value, handleValue, disabled, marginRight } = props;

    return (
        <ToggleButtonGroup
            style={
                { marginRight: marginRight ? '16px' : '0px' }
            }
            className={classes.toogleButton}
            exclusive
            value={value}
            onChange={handleValue} >
            <ToggleButton
                disabled={disabled}
                value={0}
                aria-label="Todos">
                {"Todos"}
            </ToggleButton>

            {
                segments.map((seg, index) => (

                    <ToggleButton
                        disabled={disabled}
                        value={seg.id}
                        aria-label={seg.description}>
                        {seg.description}
                    </ToggleButton>

                ))
            }


        </ToggleButtonGroup>
    )
}