import API from './config'

export const RankingFundsManagersAPI = {


    async getData(month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/ranking_funds_managers?month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async save(month, year, data) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post(`/ranking_funds_managers/save`, {
                month: month,
                year: year,
                data: data,
            }, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },


}