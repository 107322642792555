import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom';

// Styles
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { unoTheme, tabs, toogleButton, headerSearch } from '../../../../assets/styles/unoTheme';

// Components
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { MyTabs, MyTab } from "../../../utils/MyTabs";
import { Tooltip } from "@material-ui/core";
import UnoButton from '../../../utils/UnoButton';

// Screens
import FundsClientScreen from "./FundsClientScreen";
import FundsAllScreen from "./FundsAllScreen";

//redux
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../../actions';

import { listLegislationSegmentsByDate } from '../../../../API/legislation_article';

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import SearchBar from "../../../utils/SearchBar";
import MySelect from '../../../utils/MySelect'
import MyToggleSegments from "../../../utils/MyToggleSegments";
import { getFirstPtDayMonth, getLastDayInMonthByMonthAndYear, getLastPtDayMonth } from "../../../utils/utils";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
    arrow: {
        color: theme.palette.common.white,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    // searchBar: {
    //     marginRight: theme.spacing(1.5)
    // },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableArticles: {
        width: '100%',

    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        paddingBottom: theme.spacing(1),
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
    toogleButton: toogleButton,

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },
    tabs: tabs,
    fabEdit: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    fabDone: {
        position: 'absolute',
        backgroundColor: green[500],
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: green[700],
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function FundsAnalyticScreen() {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const innerLoad = useSelector(state => state.innerLoad)

    const clientOn = useSelector(state => state.clientOn)
    const [segments, setSegments] = useState(null);
    const [segmentId, setSegmentId] = useState(0);
    const handleClassification = (newSegmentId) => {
        setSegmentId(newSegmentId);
    };

    const [tabValue, setTabValue] = useState(undefined);
    const [search, setSearch] = useState('');

    let isMyFundsTab = tabValue == 0;
    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push('/analysis/funds/my')
        } else if (newValue == 1) {
            history.push('/analysis/funds/all')
        }

    };

    async function doReport(segmentId) {

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'myFunds',
            message: 'Gerando o relatório...',
            startDate: getFirstPtDayMonth(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear),
            endDate: getLastPtDayMonth(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear),
            periodReport: 'mensal',
            segmentId: segments[segmentId].id
        }))

    }

    async function loadSegments(date) {
        const responseLegislationSegments = await listLegislationSegmentsByDate(date);
        if (responseLegislationSegments.success) {

            let segmentsRows = responseLegislationSegments.body.rows;
            segmentsRows.unshift({ id: 0, description: 'Todos' });

            console.log("SEGMENTS TO SHOW: ", segmentsRows);
            setSegmentId(0);
            setSegments(segmentsRows)

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao consultar segmentos por data"
            }))
        }
    }

    useEffect(() => {

        //Caso seja fruo do retorno do detalhamento (blade) de um fundo
        if (location && location.pathname.includes('all')) {
            setTabValue(1);
        } else {
            setTabValue(0);
        }

        if (clientOn
            && clientOn.client
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

            loadSegments(getLastDayInMonthByMonthAndYear(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear));
        }

    }, [location, clientOn])

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>
                <div className={classes.tabs}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Meus fundos" />
                        <MyTab label="Outros fundos" />

                    </MyTabs>
                </div>

                {/* <form className={classes.searchBar}> */}

                <div className={classes.rightHeader}>
                    <SearchBar
                        name='search_query'
                        size='large'
                        spacingRight
                        mask='text_cnpj'
                        variant='outlined'
                        label='Nome ou CNPJ'
                        setSearchQuery={setSearch}
                    />
                    {/* </form> */}

                    {/* {segments ? <MyToggleSegments
                        segments={segments}
                        value={segmentId}
                        handleValue={handleClassification}
                        disabled={innerLoad.show && !innerLoad.emptyMessage} /> : null} */}

                    {segments ?
                        <MySelect
                            style={{ marginRight: '8px', width: '200px' }}
                            value={segmentId}
                            //verysmall
                            onChange={(e) => handleClassification(e.target.value)}
                            options={segments.map((row, index) => (

                                <option key={'segment-select-' + index} value={index}>{row.description}</option>

                            ))}
                        /> : null}


                    {/* <ToggleButtonGroup
                        className={classes.toogleButton}
                        exclusive value={segmentId} onChange={handleClassification}>
                        <ToggleButton value={0}>
                            {'Todos'}
                        </ToggleButton>
                        <ToggleButton value={1}>
                            {'Renda Fixa'}
                        </ToggleButton>
                        <ToggleButton value={2}>
                            {'Renda Variável'}
                        </ToggleButton>
                        <ToggleButton value={3}>
                            {'Exterior'}
                        </ToggleButton>
                    </ToggleButtonGroup> */}

                    {
                        isMyFundsTab ?
                            <UnoButton
                                isIconButton
                                onClick={() => doReport(segmentId)}
                                size='small'
                                type='primary'
                            />
                            : null
                    }

                </div>
            </div>

            <Switch className={classes.switch}>
                <Route path="/analysis/funds/my">
                    <FundsClientScreen
                        search={search}
                        segmentId={segments && segments[segmentId] && segments[segmentId].id}
                    />
                </Route>
                <Route path="/analysis/funds/all">
                    <FundsAllScreen
                        search={search}
                        segmentId={segments && segments[segmentId] && segments[segmentId].id}
                    />
                </Route>
            </Switch>


        </div>
    );
};