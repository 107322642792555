import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import _ from 'lodash';

import moment from 'moment';

//Api
import {
    listLegislationSegments,
    listLegislationSegmentsByType,
    listLegislationArticles, listLegislationArticlesByType
} from '../../../API/legislation_article'
import { getClientAllAssetsByDate } from '../../../API/fund'
import { createUpdateClientPolicy } from '../../../API/client'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

//Modals
import NewLegislationArticleModal from "../../modals/NewLegislationArticleModal";
import UpdatePolicyModal from "../../modals/UpdatePolicyModal";

import { unoTheme, tabs, headerSearch } from '../../../assets/styles/unoTheme'

import { applyMaskAmount, groupArticlePolicys, markArticlesInPortfolio } from "../../utils/utils";

import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from "@material-ui/core";

import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.redMoney,
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },

    sectionTableCellData: {
        display: 'table-cell',
        padding: '0px !important',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '25%',
        },
        '& .blue': {
            color: 'blue',
        },
    },
    toogleButton: {
        maxHeight: '40px',
    },

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },

    iconExtraPolicys: {

        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            verticalAlign: 'middle',
        }

    },

    tabs: tabs,


}));

export default function AdminArticlesTableScreen(props) {

    const classes = useStyles();
    const location = useLocation();

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)

    const dispatch = useDispatch();

    //const [legislationSegments, setListLegislationSegments] = useState([]);
    const [legislationArticles, setListLegislationArticles] = useState([]);

    //Selecionado para ao modal
    const [legislationArticleSelected, setLegislationArticleSelected] = useState(undefined);

    const [presentationNameMode, setPresentationNameMode] = useState('name');

    //Input de targets        
    const [segmentOnScreen, setSegmentOnScreen] = useState(0);
    const [clientOnScreen, setClientOnScreen] = useState("");

    async function getLegislationByType(type) {


        //Como o filtro é feito apenas pelo ano os registros de artigo podem vir duplicados pois
        //podem existir mais de uma politica para cada artigo
        //a diferença está no mês       

        // let responseLegislationArticles = await listLegislationArticles(type,
        //     null,
        //     null);

        //console.log("ARTICLES COM POLITICAS: ", responseLegislationArticles.body.rows);

        let responseLegislationArticles = await listLegislationArticlesByType(type);

        console.log("ARTICLES COM POLITICAS: ", responseLegislationArticles.body.rows);

        if (!responseLegislationArticles.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os enquadramentos de resolução'
            }))
        } else {


            let formattedArticles = groupArticlePolicys(responseLegislationArticles.body.rows);
            setListLegislationArticles(formattedArticles);
        }




    }

    const { segment, legislationSegments, resolutionVersionSelected } = props

    useEffect(() => {

        //Deve fazer a recarga apenas no caso de ter mudado o client, o ano selecionado ou a tab                
        if (segment && (segment.id != segmentOnScreen) || (rightContent && rightContent.action)) {

            //No caso de ser uma atualização do rightContent limpa-se o rightContent
            if (rightContent && rightContent.action) {
                dispatch(allActions.rightContentActions.setRightContent(null))
            }

            setListLegislationArticles([]);

            if (segment) {
                setSegmentOnScreen(segment.id);
                getLegislationByType(segment.id);
            }


        }


    }, [
        legislationArticleSelected,
        segment,
        rightContent,
        legislationSegments,
    ]);

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.tableSideGraph}>


                    <MyTable stickyHeader size="small"
                        selectable="true">
                        {/* selectable="true"> */}
                        <colgroup>
                            <col width="35%" />
                            <col width="15%" />
                            <col width="50%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className={[classes.clickAble].join(' ')} align="left"
                                    onClick={() => setPresentationNameMode(presentationNameMode == 'name' ? 'class' : 'name')}>
                                    {'Dispositivo'}
                                </TableCell>
                                {/* <TableCell  align="center">Dispositivo</TableCell> */}
                                <TableCell align="center">Lim. Resolução (%)</TableCell>
                                <TableCell className={classes.sectionTableCell}
                                    align="center">
                                    <div>{'Limites de Pró-Gestão'}</div>
                                    <div className={classes.multipleHorizontalTableCell}>
                                        <div>{'PG I'}</div>
                                        <div>{'PG II'}</div>
                                        <div>{'PG III'}</div>
                                        <div>{'PG IV'}</div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {legislationArticles
                                .map((row) => (

                                    <TableRow key={'article-' + row.id}
                                        className={classes.selectableRow}
                                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                            path: 'detailAdminLegislationArticle',
                                            data: {
                                                legislationArticle: row,
                                                legislationSegmentSelected: segmentOnScreen,
                                                legislationSegments: legislationSegments,
                                                resolutionVersionSelected: resolutionVersionSelected,
                                            }
                                        }))}>
                                        <Tooltip button title={row.description} enterDelay={100} leaveDelay={100} placement="bottom" arrow>
                                            <TableCell align="left">
                                                {presentationNameMode == 'name' ? row.asset : row.device}
                                            </TableCell>
                                        </Tooltip>
                                        <TableCell align="center">{row.limit_resolution}%</TableCell>
                                        <TableCell className={classes.sectionTableCellData} align="center">
                                            {

                                                <div className={classes.multipleHorizontalTableCell}>
                                                    <div className={'blue'}>{row.limit_pg1 ? row.limit_pg1 + '%' : '-'}</div>
                                                    <div className={'blue'}>{row.limit_pg2 ? row.limit_pg2 + '%' : '-'}</div>
                                                    <div className={'blue'}>{row.limit_pg3 ? row.limit_pg3 + '%' : '-'}</div>
                                                    <div className={'blue'}>{row.limit_pg4 ? row.limit_pg4 + '%' : '-'}</div>
                                                </div>

                                            }

                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </MyTable>


                </div>

            </div>

        </div >
    );
}



