import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField'

//UnoButton
import UnoButton from '../../utils/UnoButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { unoTheme } from "../../../assets/styles/unoTheme";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Grid
import { Grid } from "@material-ui/core";

//API
import { createLocalBenchmark, updateLocalBenchmark, deleteLocalBenchmark } from '../../../API/local_benchmaks'

import CircularProgress from '@material-ui/core/CircularProgress';

import ConfirmActionRightContent from './ConfirmActionRightContent'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    bottom: {

        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

}));

export default function NewLocalBenchmark(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    //form            
    const [submitted, setSubmitted] = useState('');

    //Local benchmark
    const [nameLocalBenchmark, setNameLocalBenchmark] = React.useState('');
    const [comdinheiroNameLocalBenchmark, setComdinheiroNameLocalBenchmark] = React.useState('');
    const [descriptionLocalBenchmark, setDescriptionLocalBenchmark] = React.useState('');

    function loadInfos(data) {



        setNameLocalBenchmark(data.name);
        setComdinheiroNameLocalBenchmark(data.comdinheiro_name);
        setDescriptionLocalBenchmark(data.description);


    }

    async function doSaveBenchmark() {

        setValidationMessage('')
        setSubmitted(true);
        setOpenBackdrop(true)

        if (nameLocalBenchmark != "") {

            //Foi definido que não é necessária a informação da agência (Facilitar a criação de contas por importação da planilha)

            let localBenchmark = {
                name: nameLocalBenchmark,
                comdinheiro_name: comdinheiroNameLocalBenchmark,
                description: descriptionLocalBenchmark,
            }

            if (content.update) {
                //inclusindo o id a ser atualizada
                localBenchmark.id = content.data.id;

                const responseBenchmark = await updateLocalBenchmark(localBenchmark);

                if (responseBenchmark.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Benchmark atualizada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao atualizar a benchmark: " + responseBenchmark.body.message
                    }))
                }

            } else {

                const responseBenchmark = await createLocalBenchmark(localBenchmark);

                if (responseBenchmark.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Benchmark cadastrada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao cadastrar a benchmark: " + responseBenchmark.body.message
                    }))
                }
            }

            dispatch(allActions.rightContentActions.update())

        }

        setOpenBackdrop(false)



    }

    async function doDeleteBenchmark() {

        setValidationMessage('')
        setOpenBackdrop(true)

        //content contem data que contem o local benchmark que foi aberta no componente lateral
        const responseBenchmark = await deleteLocalBenchmark(content.data.id);

        if (responseBenchmark.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: responseBenchmark.body.message
            }))

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao exluir a benchmark: " + responseBenchmark.body.message
            }))
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update());

    }

    //deleção do benchmark
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteBenchmark();
    }

    useEffect(() => {

        if (content.update) {
            loadInfos(content.data);
        }

    }, [])

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?
                    <>
                        <div className={classes.header}>
                            <IconButton
                                className={classes.hiddenIcon}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>

                                    {content.update ? "Editar Benchmark" : "Cadastrar Benchmark"}

                                </Typography>

                            </div>


                            <IconButton
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>
                            <Grid container spacing={2}>

                                <Grid item lg={6} md={6} sm={12} xs={12} >
                                    <MyTextField
                                        outerlabel={'Nome'}
                                        value={nameLocalBenchmark}
                                        onChange={(value) => setNameLocalBenchmark(value)}
                                        validator={nameLocalBenchmark == "" && submitted ? true : undefined}
                                        helperText={"è necessário informar o nome"}
                                        placeholder={'Insira o nome'} />
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} >
                                    <MyTextField
                                        variant="outlined"
                                        outerlabel={'Correspondência COMDINHEIRO'}
                                        value={comdinheiroNameLocalBenchmark}
                                        onChange={(value) => setComdinheiroNameLocalBenchmark(value)}
                                        placeholder={'Insira a correspondência na comdinheiro'} />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12} >
                                    <MyTextField
                                        value={descriptionLocalBenchmark}
                                        onChange={(value) => setDescriptionLocalBenchmark(value)}
                                        multiline={true}
                                        rows={5}
                                        rowsMax={5}
                                        outerlabel={'Descrição'}
                                        placeholder="Insira a descrição"
                                        type="text"
                                        fullWidth />

                                </Grid>

                            </Grid>

                            <br />
                            <div className={classes.bottom}>

                                <div className={'bottomButtons'}>


                                    <UnoButton
                                        className={
                                            !content.update ? classes.hiddenIcon : null}
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir becnhmark"}
                                    </UnoButton>


                                    <UnoButton
                                        onClick={() => doSaveBenchmark()}
                                        type={content.update ? 'success' : 'primary'}>
                                        {content.update ? "Atualizar becnhmark" : "Criar becnhmark"}
                                    </UnoButton>

                                </div>

                            </div>

                        </div>
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir o Benchmark ${content.data.name}?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }


        </div>
    );
}



