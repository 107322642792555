import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import MyTable from '../../../utils/MyTable';


import { headerSearch, unoTheme } from '../../../../assets/styles/unoTheme';


import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getUpdateLogList } from '../../../../API/client';
import allActions from '../../../../actions';
import { formatStringToDisplay } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	green: {
		color: 'green'
	},
	red: {
		color: 'red',
	},
	headerSearch: headerSearch,
	inputSearch: {
		width: "300px",
		background: "white"
	},
	toStickyHeader: {
		display: 'flex',
		height: '100%',
	},
}));

export default function ClientUpdateLogs({ selectedClient }) {

	const classes = useStyles();
	const dispatch = useDispatch();

	const [logs, setLogs] = useState([]);

	async function doGetLogActions(selectedClient) {

		setLogs([])
		dispatch(allActions.innerLoadActions.setInnerLoad({
			show: true,
			loadMessage: 'Carregando alterações...'
		}));

		const response = await getUpdateLogList(selectedClient?.id);

		if (response.success && response.body) {

			const { rows } = response.body;
			if (!rows.length) {
				dispatch(allActions.innerLoadActions.setInnerLoad({
					show: true,
					emptyMessage: 'Sem alterações registradas para esse cliente',
				}));
				return;
			} else {
				setLogs(rows)
				dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
			}

		} else {
			console.error("RESPONSE ERROR: ", response.error);
			dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
		}
	}

	function renderDiffsCell(row) {

		const diffsAttrs = Object.keys(JSON.parse(row.new_info_json)).join(', ');
		return (
			<TableCell align="left" style={{ fontWeight: 'bold' }}>
				{diffsAttrs}
			</TableCell>
		)
	}

	useEffect(() => {
		doGetLogActions(selectedClient);
	}, [selectedClient]);


	return (
		<div className={classes.root}>
			<div class={'defaultScrollWithHeaderContent'}>
				<MyTable
					stickyHeader
					selectable
					size="small"
				>
					<TableHead>
						<TableRow>
							<TableCell align="left">Data</TableCell>
							<TableCell align="left">Usuário</TableCell>
							<TableCell align="left">Cliente</TableCell>
							<TableCell align="left">Alterações</TableCell>
						</TableRow>
					</TableHead>
					{logs.length > 0 ?
						<TableBody>
							{logs.map((row, index) => (
								<TableRow key={'client-update-logs-' + index}
									onClick={() => dispatch(allActions.rightContentActions.setRightContent({
										path: 'clientUpdates',
										data: { ...row },
									}))}>
									<TableCell align="left" style={{ color: unoTheme.mainColor }}>{moment(row.created_date).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
									<TableCell align="left">{formatStringToDisplay(row.username.toUpperCase(), 2)}</TableCell>
									<TableCell align="left">{row.client_label.toUpperCase()}</TableCell>
									{renderDiffsCell(row)}
								</TableRow>
							))}
						</TableBody>
						: <TableBody></TableBody>}
				</MyTable>
			</div>
		</div>
	);
}