const setClient = (clientObj) => {
    return {
        type: "SET_CLIENT",
        payload: clientObj
    }
}

const setActiveClients = (activeClients) => {
    return {
        type: "SET_ACTIVE_CLIENTS",
        payload: activeClients
    }
}


export default {
    setClient,
    setActiveClients
}