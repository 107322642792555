import API, { config } from './config'

export async function getInfosByCep(cep) {

    try {

        //const response = await API.get('https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/' + cep + '/json/')
        const response = await API.get('https://viacep.com.br/ws/' + cep + '/json/')

        //console.log("RESPONSE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        //console.log("ERROR: ", error)
        return {
            success: false,
            body: error.response.data
        };
    }

}