import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../utils/utils'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Collapse from '@material-ui/core/Collapse';

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

import ModalScrollHeight from '../utils/ModalScrollHeight'

import dataJson from '../data/data.json'

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Logo from '../../assets/imgs/logo_uno.png'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import ModalToPdf from "../utils/ModalToPDF";
import PaperGraficos from "./PaperGraficos";

import Fab from '@material-ui/core/Fab';


import Zoom from '@material-ui/core/Zoom';

import { lemaTheme } from '../../assets/styles/lemaTheme'
import InstituitionsScreen from "./administration/InstituitionsScreen";

//Bootstrap input
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions'

import { me } from '../../API/user'
import { listAllActiveClients } from '../../API/client'
import MyAlert from "../utils/MyAlert";
import FundsScreen from "./administration/FundsAndTitlesScreen/FundsScreen";
import ClientScreen from "./backOffice/ClientScreen";

import MyFullLoad from "../utils/MyFullLoad";
import AdvisorsScreen from "./backOffice/AdvisorsScreen";
//import ClientDetailScreen from "./backOffice/ClientDetailScreen";
import AdvisorsDetailScreen from "./backOffice/AdvisorsDetailScreen";
import QuotaDetailScreen from "./administration/QuotaDetailScreen";
import CollectionOfBalancesScreeen from "./administration/CollectionOfBalancesScreeen";
import ArticlesScreen from "./analysis/ArticlesScreen";
import TransactionsScreen from "./investimentos/TransactionsScreen";
import ImportExcel from "./investimentos/ImportExcel";

import Tooltip from '@material-ui/core/Tooltip';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: lemaTheme.mainColor,
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    rightToolbar: {
        display: 'flex',
        alignItems: 'center',
    },
    labelClient: {
        textAlign: "left",
        color: "#aaaaaa",
        display: "flex"
    },
    nameUser: {
        marginRight: theme.spacing(2),
        fontSize: "0.6em",
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        transition: 'all .5s ease',
        [theme.breakpoints.down('sm')]: {
            //display: 'none',
            marginLeft: drawerWidth * (-1),
        },
    },
    drawerPaper: {
        width: drawerWidth,
        transition: 'all .5s ease',
        [theme.breakpoints.down('sm')]: {
            //display: 'none',
            marginLeft: drawerWidth * (-1),
        },
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        '& .switch': {
            transition: 'all .3s ease',
            marginTop: '-65px',
        },
        '& .switch.mainAlert': {
            marginTop: '0px',
        }
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    logo: {
        width: '90px',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    fabRightBottom: {
        position: "absolute",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },

    selectClient: {
        padding: theme.spacing(2),
    },

    optionClient: {
        padding: "10px !important"
    },

    // sectionMobile: {
    //     display: 'flex',
    //     [theme.breakpoints.up('md')]: {
    //         display: 'none',
    //     },
    // },
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '2px 26px 2px 12px',
        lineHeight: '20px',
        height: '40px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function Dashboard() {

    const history = useHistory();

    let location = useLocation();

    const currentUser = useSelector(state => state.currentUser)
    const activeClients = useSelector(state => state.activeClients)
    const mainAlert = useSelector(state => state.mainAlert)
    const fullLoad = useSelector(state => state.fullLoad)

    const dispatch = useDispatch();

    async function getMe() {

        console.log("CALL ME")
        const responseMe = await me();
        console.log("RESPONSE ME: ", responseMe)
        if (responseMe
            && !responseMe.success
            && responseMe.body
            && responseMe.body.name == "TokenExpiredError") {

            dispatch(allActions.userActions.logOut())

        } else {
            dispatch(allActions.userActions.setUser(responseMe.body))
        }

        if (responseMe.body.role == "ROLE_ADMIN") {
            await getActiveClients();
            //history.push('/clients');
            // history.push('/advisors');
            // history.push('/quotas')
            // history.push('/collectionOfBalances')
            // history.push('/transactions')
            history.push('/articles')
        } else {
            console.log("GO TO PORTFOLIO")
            history.push('/portfolio');
        }
    }

    async function getActiveClients() {

        const responseClients = await listAllActiveClients();
        console.log("CLIENTS: ", responseClients)
        //setClients(responseClients.body.rows)
        if (responseClients
            && responseClients.body
            && responseClients.body.rows
            && responseClients.body.rows.length > 0) {

            console.log("Clients recebidos: ", responseClients.body.rows);
            dispatch(allActions.clientActions.setActiveClients(responseClients.body.rows))
            console.log("SETTING CLIENT ON: ", responseClients.body.rows[0])
            dispatch(allActions.clientActions.setClient(responseClients.body.rows[0]))

        }
    }

    const [selectedClient, setSelectedClient] = React.useState({});

    const handleChangeClient = (event) => {
        setSelectedClient(event.target.value)
        dispatch(allActions.clientActions.setClient(activeClients[event.target.value]))
    };

    useEffect(() => {

        console.log("USE EFFECT DASHBOARD: ", currentUser)

        if (!isAuthenticated()) {
            history.push('/login');
        }

        console.log("CURRENT USER: ", currentUser)

        if (!currentUser.user
            && currentUser.loggedIn != false) { //jump of the cat

            getMe();

        }

        // if (currentUser.user
        //     && currentUser.loggedIn) {

        //     if (activeClients.length == 0) {
        //         getActiveClients();
        //     }

        //     if (currentUser.user.role == "ROLE_ADMIN" && location.pathname == "/") {
        //         history.push('/clients');
        //     } else if (currentUser.user.role == "ROLE_ADVISOR" && location.pathname == "/") {
        //         history.push('/portfolio');
        //     }


        // }

        if (currentUser && currentUser.loggedIn == false) {
            handleMenuLogout();
        }

    }, [currentUser, activeClients]);

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();

    };

    const handleMenuLogout = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        localStorage.clear();
        console.log("TOKEN AS DESLOGAR: ", localStorage.getItem("token"));
        history.push('/login');
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Perfil</MenuItem>
            <MenuItem onClick={() => dispatch(allActions.userActions.logOut())}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}>
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    const [showPDF, setShowPDF] = React.useState(false);

    function handleModalPdf(open) {
        setShowPDF(open);
    }

    const [showModalToPDF, setShowModalToPDF] = React.useState(false);

    function handleModalToPdf(open) {
        setShowModalToPDF(open);
    }

    const [showModalNewTransaction, setShowModalNewTransaction] = React.useState(false);

    function handleModalNewTransaction(open) {
        setShowModalNewTransaction(open);
    }

    function printPDF() {
        const domElement = document.getElementById('main-content')
        html2canvas(domElement, {
            onclone: (document) => {
                //document.getElementById('print-button').style.visibility = 'hidden'
            }
        }).then((canvas) => {
            const img = canvas.toDataURL('image/png')
            //const pdf = new jsPdf('landscape', 'pt', 'a4')
            const pdf = new jsPdf('l', 'mm', [297, 210]);
            pdf.addImage(img, 'JPEG', 0, 0, 297, 210);
            pdf.addPage();
            pdf.addImage(img, 'JPEG', 0, 0, 297, 210);
            pdf.save('your-filename.pdf')
        })
    }

    const [openNestedMenuInvestment, setOpenNestedMenuInvestiment] = React.useState(false);

    const handleClickNestedMenuInvestiment = () => {
        setOpenNestedMenuInvestiment(!openNestedMenuInvestment);
    };

    const [openNestedMenuAdministration, setOpenNestedMenuAdministration] = React.useState(false);

    const handleClickNestedMenuAdministration = () => {
        setOpenNestedMenuAdministration(!openNestedMenuAdministration);
    };

    const [openNestedMenuBackOffice, setOpenNestedMenuBackOffice] = React.useState(false);

    const handleClickNestedMenuBackOffice = () => {
        setOpenNestedMenuBackOffice(!openNestedMenuBackOffice);
    };

    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <div id="testePdf" className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant="dense"
                    className={classes.toolbar}>
                    <img className={classes.logo}
                        src={Logo}></img>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit">
                            <div className={classes.rightToolbar}>
                                <Typography className={classes.nameUser} variant="h6">
                                    {currentUser && currentUser.user ? currentUser.user.name : ""}
                                </Typography>
                                <AccountCircle />
                            </div>

                        </IconButton>
                    </div>
                    {/* <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <Toolbar variant="dense" />
                <div className={classes.drawerContainer}>
                    <div className={classes.selectClient}>

                        {
                            currentUser && currentUser.user
                                && (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_ADVISOR") ?
                                <>
                                    {
                                        currentUser.user.role == "ROLE_ADMIN" ?

                                            <>
                                                <Typography align={"left"} variant="overline" className={classes.labelClient}>
                                                    {"Cliente"}
                                                </Typography>
                                                <FormControl className={classes.formControl}>
                                                    {
                                                        activeClients ?
                                                            <NativeSelect id="demo-customized-select-native"
                                                                value={selectedClient.label_name}
                                                                onChange={handleChangeClient}
                                                                input={<BootstrapInput />}>
                                                                {activeClients.map((row, index) => (
                                                                    <option className={classes.optionClient} value={index}>
                                                                        {row.label_name}
                                                                    </option>
                                                                ))}
                                                            </NativeSelect> : null
                                                    }

                                                </FormControl>
                                            </>
                                            :
                                            <>
                                                <Typography align={"left"} variant="overline" className={classes.labelClient}>
                                                    {"Consultor"}
                                                </Typography>
                                                <Typography align={"left"} variant="h6">
                                                    {currentUser && currentUser.user ? currentUser.user.name : ""}
                                                </Typography>
                                            </>
                                    }
                                </>
                                :
                                <>
                                    <Typography align={"left"} variant="overline" className={classes.labelClient}>
                                        {"Cliente"}
                                    </Typography>
                                    <Typography align={"left"} variant="h6">
                                        {currentUser && currentUser.user ? currentUser.user.label_name : ""}
                                    </Typography>
                                </>

                        }



                        {/* <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Instituição</InputLabel>
                            <NativeSelect id="demo-customized-select-native"
                                value={client.label_name}
                                onChange={handleChangeClient}
                                input={<BootstrapInput />}>
                                {clients.map((row) => (
                                    <option value={row.id}>{row.label_name}</option>
                                ))}                                
                            </NativeSelect>
                        </FormControl> */}
                    </div>
                    {/* <List>
                        <ListItem button key={'Tabs'} onClick={() => history.push('/tabs')}>
                            <ListItemIcon><MailIcon /></ListItemIcon>
                            <ListItemText primary={'Tabs'} />
                        </ListItem>
                    </List> */}
                    <Divider />
                    <List>
                        <ListItem button onClick={handleClickNestedMenuBackOffice}>
                            <ListItemIcon>
                                <EqualizerIcon />
                            </ListItemIcon>
                            <ListItemText primary="Back-Office" />
                            {openNestedMenuBackOffice ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openNestedMenuBackOffice} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem className={classes.nested} button key={'Clientes'} onClick={() => history.push('/clients')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Clientes'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'Consultores'} onClick={() => history.push('/advisors')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Consultores'} />
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button onClick={handleClickNestedMenuInvestiment}>
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Investimentos" />
                            {openNestedMenuInvestment ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openNestedMenuInvestment} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem className={classes.nested} button key={"Enquadramentos"} onClick={() => history.push('/articles')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Enquadramentos"} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'Movimentações'} onClick={() => history.push('/transactions')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Movimentações'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={"Import Excel"} onClick={() => history.push('/importExcel')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Import Excel"} />
                                </ListItem>
                                {/* <ListItem className={classes.nested} button key={"Menu 1"} onClick={() => history.push('/menu1')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Menu 1"} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={"Menu 2"} onClick={() => history.push('/menu2')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Menu 2"} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={"Menu 3"} onClick={() => history.push('/menu3')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Menu 3"} />
                                </ListItem> */}
                            </List>
                        </Collapse>
                        <BootstrapTooltip open={false} title="Administração" arrow placement="right">
                            <ListItem button onClick={handleClickNestedMenuAdministration}>
                                <ListItemIcon>
                                    <SupervisorAccountIcon />
                                </ListItemIcon>
                                <ListItemText primary="Administração" />
                                {openNestedMenuAdministration ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                        </BootstrapTooltip>
                        <Collapse in={openNestedMenuAdministration} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem className={classes.nested} button key={"Coleta de saldos"} onClick={() => history.push('/collectionOfBalances')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Coleta de saldos"} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'Instituições'} onClick={() => history.push('/instituitions')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Instituições'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'Fundos'} onClick={() => history.push('/quotas')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Fundos'} />
                                </ListItem>
                                {/* <ListItem className={classes.nested} button key={'Modal'} onClick={() => handleModalPdf(true)}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Modal'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'ModalToPdf'} onClick={() => handleModalToPdf(true)}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'ModalToPdf'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={'Html2Pdf'} onClick={() => printPDF()}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={'Html2Pdf'} />
                                </ListItem>
                                <ListItem className={classes.nested} button key={"Paper/Graficos"} onClick={() => history.push('/paper')}>
                                    <ListItemIcon><ChevronRightIcon /></ListItemIcon>
                                    <ListItemText primary={"Paper/Gráficos"} />
                                </ListItem> */}
                            </List>
                        </Collapse>
                        {/* {['Gerar pdf', 'Ler excel', 'Menu 1', 'Menu 2'].map((text, index) => ( */}
                    </List>
                </div>

            </Drawer>
            <main className={classes.content} id="main-content">
                <Toolbar variant="dense" />
                <MyAlert alert={mainAlert} />
                <div class={`switch ${mainAlert.show ? 'mainAlert' : ''}`}>
                    <Switch className={classes.switch}>
                        <Route path="/clients">
                            <ClientScreen />
                        </Route>
                        {/* <Route path="/clientDetail">
                            <ClientDetailScreen />
                        </Route> */}
                        <Route path="/advisors">
                            <AdvisorsScreen />
                        </Route>
                        <Route path="/advisorDetail">
                            <AdvisorsDetailScreen />
                        </Route>
                        <Route path="/collectionOfBalances">
                            <CollectionOfBalancesScreeen />
                        </Route>
                        <Route path="/articles">
                            <ArticlesScreen />
                        </Route>
                        <Route path="/transactions">
                            <TransactionsScreen />
                        </Route>
                        {/* <Route exact path="/tabs">
                            <MyTabs />
                        </Route> */}
                        {/* <Route path="/menu1">
                            <MenuExtra1 />
                        </Route>
                        <Route path="/menu2">
                            <MenuExtra2 />
                        </Route>
                        <Route path="/menu3">
                            <MenuExtra3 />
                        </Route> */}
                        <Route path="/paper">
                            <PaperGraficos />
                        </Route>
                        <Route path="/instituitions">
                            <InstituitionsScreen />
                        </Route>
                        <Route path="/quotas">
                            <FundsScreen />
                        </Route>
                        <Route path="/quotaDetail">
                            <QuotaDetailScreen />
                        </Route>
                        <Route path="/importExcel">
                            <ImportExcel />
                        </Route>

                    </Switch>
                </div>
            </main >
            {renderMenu}
            <ModalScrollHeight
                open={showPDF}
                handleModal={(open) => handleModalPdf(open)
                } />
            <ModalToPdf
                open={showModalToPDF}
                handleModal={(open) => handleModalToPdf(open)} />

            {/* <Zoom
                key={'fabNewTransaction'}
                in={location.pathname == '/dashboard'}
                timeout={transitionDuration}
                style={{
                    transitionDelay: `${location.pathname == '/dashboard' ? transitionDuration.exit : 0}ms`,
                }}
                unmountOnExit>
                <Fab className={classes.fabRightBottom} color="primary" aria-label="add"
                    onClick={() => handleModalNewTransaction(true)}>
                    <AddIcon />
                </Fab>
            </Zoom> */}

            <MyFullLoad fullLoad={fullLoad} />
        </div >
    );
}



