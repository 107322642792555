import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Controllers
import { formatTargetsToShow, loadClientDiaryByRange } from '../../../controllers/ClientController'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

import moment from 'moment'
import { applyMaskAmount, applyMaskAmountFreeDecimals, getConsultingId, greenOrRed } from "../../utils/utils";
import UnoButton from "../../utils/UnoButton";
import { getFilesByDir } from "../../../API/server_files";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import { REPORTS_URL } from "../../../API/config";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    }
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//AO ALTERAR O PATH DO CREDENCIMENTO LEMBRAR DE ALTERAR A LIBERACAO LA NO ARQUIVO server.js DO BACKEND
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const FILES_AND_DOCUMENTS_PATH = 'arquivos';

const BLOCK_TO_VIWERS = ['CREDENCIAMENTO', 'MODELOS']

export default function FilesAndDocumentsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser)

    const [files, setFiles] = useState(null);
    const [totalPath, setTotalPath] = useState(FILES_AND_DOCUMENTS_PATH + '/' + getConsultingId(currentUser));
    const [paths, setPaths] = useState([]);

    function getTotalPathByPaths(myPaths) {

        let totalPath = FILES_AND_DOCUMENTS_PATH + '/' + getConsultingId(currentUser);
        myPaths.forEach(path => {
            totalPath += '/' + path;
        });
        return totalPath;
    }

    function downloadFile(fileName) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Baixando o arquivo...',
        }));

        const downloadPath = REPORTS_URL + totalPath + '/' + fileName;
        console.log('downloadPath: ', downloadPath);
        fetch(downloadPath)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();

                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: false,
                    }));
                });
                //window.location.href = response.url;
            });
    };

    async function doGetFilesByDir(dir, indexPath) {

        console.log("DIR: ", dir);
        console.log("indexPath: ", indexPath);
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando diretório...',
        }));

        let newPaths = null;

        if (indexPath != null) { //clicado a partir da lista criada 

            newPaths = [...paths.slice(0, indexPath + 1)];
            setPaths(newPaths);

        } else {

            newPaths = [...paths];
            if (dir) {
                newPaths.push(dir)
            }
            setPaths(newPaths);


        }

        console.log("NEW PATHS: ", newPaths);


        const newTotalPath = getTotalPathByPaths(newPaths);
        setTotalPath(newTotalPath);

        const response = await getFilesByDir(newTotalPath);
        console.log("FILEs: ", response);
        if (response.success) {

            if (newPaths.length === 0 && currentUser?.user?.isViewer) { //indica raiz dos arquivo

                setFiles(response.body.files.filter(el => !BLOCK_TO_VIWERS.includes(el.fileName)))
            } else {

                setFiles(response.body.files)
            }
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    useEffect(() => {

        doGetFilesByDir();

    }, []);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    <span className={classes.dirName} onClick={() => doGetFilesByDir(null, -1)}>{'Arquivos e Documentos'}</span>
                    {paths && paths.map((row, index) => (
                        <span className={classes.dirName} onClick={() => doGetFilesByDir(row, index)}>{' > '}{row}</span>
                    ))}

                </Typography>

            </div>
            <div class={'defaultScrollWithHeaderContent'}>

                <List component="nav"
                    style={{ width: '100%', padding: '16px', overflowY: 'scroll' }}
                    aria-label="main mailbox folders">
                    {files && files.map((row, index) => (
                        <ListItem
                            key={'file-in-dir-' + row.fileName}
                            button={row.isDir}
                            style={{ width: '100%' }}
                            className={!row.isDir ? classes.file : ''}
                            onClick={() => row.isDir ? doGetFilesByDir(row.fileName) : downloadFile(row.fileName)}>
                            <ListItemIcon>
                                {
                                    row.isDir ?
                                        <FolderIcon style={{ color: '#e1b12c' }} />
                                        :
                                        <DescriptionIcon style={{ color: '#9c88ff' }} />
                                }
                            </ListItemIcon>

                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                <ListItemText primary={row.fileName} />
                                {
                                    !row.isDir ?
                                        <span style={{ fontSize: '10px' }}>Clique para baixar</span> : null
                                }
                            </div>

                        </ListItem>
                    ))}


                </List>


            </div>
        </div >
    );
}



