import { LOGOUT_TYPE } from './currentUser';

const NOTIFICATION_DEFAULT_STATE = [];

export const NOTIFICATION_TYPE = "SET_NOTIFICATIONS";

const clearNotifications = (store) => {
    const { notifications } = store.getState();

    store.dispatch({
        type: NOTIFICATION_TYPE, payload: {
            notificationsToRemove: notifications
        }
    });
};

export const notificationMiddleware = store => next => action => {
    if (action.type === LOGOUT_TYPE) clearNotifications(store);

    return next(action);
};

/**
 * @param {import('../actions/notificationActions').Notification[]} state 
 * @param {{
 *  payload: import('../actions/notificationActions').NotificationPayload
 *  type: "SET_NOTIFICATIONS"
 * }} action 
 * @returns 
 */
const notifications = (state = NOTIFICATION_DEFAULT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_TYPE:
            const {
                newNotifications = [],
                notificationsToRemove = []
            } = action.payload;

            // Adiciona as novas notificações ao estado existente
            let newState = [...state, ...newNotifications];

            // Remove as notificações que precisam ser removidas
            if (notificationsToRemove.length > 0) {
                const notificationsToRemoveType = notificationsToRemove.map(({ type }) => type);
                newState = newState.filter(({ type }) => !notificationsToRemoveType.includes(type));
            }

            return newState;
        default:
            return state
    }
}

export default notifications;