import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';
//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import PrintIcon from '@material-ui/icons/Print';


import {
    applyMaskAccount,
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    formatPtStringDateToAnbimaDate,
    getLastDayInMonthByMonthAndYear,
    getNextPeriod,
    getTheLastDayCompleteMonthPt,
    isPeriodBefore,
    OperationStringMap
} from '../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';

//Controllers
import { Divider } from '@material-ui/core';
import moment from 'moment';
import { getTransactionsByAsset } from '../../../API/transaction';
import { getLocalQuotasByFundIdAndPeriods } from '../../../API/fund_local_quotas';
import _ from 'lodash';
import { BalancesValidationsAPI } from '../../../API/balances_validations';
import UnoButton from '../../utils/UnoButton';



const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px #f4f4f4',

        background: unoTheme.hoverTable,

        '& .closeIcon': {
            padding: theme.spacing(3),
        }
    },

    main: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',

        '& .tableTransactions': {
            height: 'calc(100vh - 123px)',
        }

    },

    myDatePicker: {
        marginBottom: '16px',
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    rightDivBackdrop: {
        zIndex: 3,
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    infosFundContent: {
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    leftHeader: {
        display: 'flex',
        padding: "24px",
        flexDirection: 'column',
        maxWidth: "calc(100% - 96px)"
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    cnpjLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    overflowEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    green: {
        color: unoTheme.greenMoney + ' !important'
    },
    red: {
        color: unoTheme.errorColor + ' !important',
    },
    blue: {
        color: 'blue !important',
    },

}));

export default function IPC14(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [transactions, setTransactions] = useState([]);

    const [lines, setLines] = useState([]);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");

    const blankLine = {
        blankLine: true,
        date: "=",
        description: "=",
        amount: "=",
        qtdQuotas: "=",
        quotaValue: "=",
        balanceQuotas: "=",
        profit: "=",
        pasep: "=",
        balanceApplicationsQuotas: "=",
    }

    const formatLinesReturnsIPC14 = async (selectedAsset, transactions) => {

        let currentBalanceQuotas = parseFloat(selectedAsset.init_quotas);
        let currentBalance = parseFloat(selectedAsset.init_balance);

        const primaryValueQuotas = currentBalance / currentBalanceQuotas;

        const fullTransactionsArray = transactions.map((el) => ({
            date: moment.utc(el.transaction_date).format("DD/MM/YYYY"),
            amount: parseFloat(el.amount),
            operation_id: el.operation_id,
        }));

        fullTransactionsArray.unshift({
            date: "31/" + moment.utc(selectedAsset.asset_init).format("MM/YYYY"),
            amount: currentBalance,
            operation_id: 1,
            qtdQuotas: currentBalanceQuotas,
            quotaValue: currentBalance / currentBalanceQuotas,
        });

        const applicationsArray = _.cloneDeep(fullTransactionsArray.filter(el => el.operation_id === 1));
        console.log("applicationsArray: ", applicationsArray);
        const rescuesArray = fullTransactionsArray.filter(el => el.operation_id === 2);

        // let temp = fullTransactionsArray[6];
        // fullTransactionsArray[6] = fullTransactionsArray[7];
        // fullTransactionsArray[7] = temp;
        console.log("fullTransactionsArray: ", fullTransactionsArray);

        // const periodsToGetQuotas = [];
        // fullTransactionsArray.forEach(el => {
        //     const periodApplication = el.date.substring(3);
        //     if (!periodsToGetQuotas.includes(periodApplication)) {
        //         periodsToGetQuotas.push(periodApplication);
        //     }
        // });

        const allPeriods = [];
        let thePeriod = moment.utc(selectedAsset.asset_init).format("MM/YYYY");
        const endPeriod = clientOn.client.selectedPortfolioMonth.toString().padStart(2, "0") + "/" + clientOn.client.selectedPortfolioYear;

        console.log("thePeriod: ", thePeriod);
        console.log("endPeriod: ", endPeriod);

        let secureCount = 0;

        if (isPeriodBefore(thePeriod, endPeriod)) {
            while (thePeriod != endPeriod && secureCount != 999) {
                allPeriods.push(thePeriod);
                thePeriod = getNextPeriod(thePeriod, true);
                console.log("thePeriod: ", thePeriod);
                secureCount++;
            }
            allPeriods.push(endPeriod);
        }
        console.log("allPeriods: ", allPeriods);


        const mapQuotasByPeriod = {};
        const mapBalancesByPeriod = {};
        if (allPeriods.length) {

            const response = await getLocalQuotasByFundIdAndPeriods(selectedAsset.fund_id, allPeriods)
            console.log("response cotas: ", response);
            if (response.success) {
                response.body.forEach(quotas => {
                    mapQuotasByPeriod[`${quotas.month.toString().padStart(2, "0")}/${quotas.year}`] = JSON.parse(quotas.json_quotas);
                });
            }

            const responseBalances = await BalancesValidationsAPI.getAssetBalancesByPeriods(selectedAsset.id, allPeriods);
            console.log("responseBalances: ", responseBalances);
            if (responseBalances.success) {
                responseBalances.body.forEach(balance => {
                    mapBalancesByPeriod[`${balance.period}`] = parseFloat(balance.balance);
                });
            }
        }




        console.log("mapQuotasByPeriod: ", mapQuotasByPeriod);
        console.log("mapBalancesByPeriod: ", mapBalancesByPeriod);

        const monetizedPeriods = [];

        const formattedLines = [];
        //DATA	
        //DESCRIÇÃO	
        //VALOR	
        //QUANTIDADE DE COTAS	
        //VALOR COTA	
        //SALDO COTAS	
        //RECEITA ORÇAMENTÁRIA	 
        //PASEP 	
        //SALDO DAS APLICAÇÕES
        // formattedLines.push({
        //     date: moment.utc(selectedAsset.asset_init).format("MM/YYYY"),
        //     description: "SALDO ANTERIOR",
        //     amount: currentBalance,
        //     qtdQuotas: currentBalanceQuotas,
        //     quotaValue: "",
        //     balanceQuotas: "",
        //     profit: "",
        //     pasep: "",
        //     balanceApplicationsQuotas: currentBalanceQuotas,
        // });

        const getQuotaInfos = (amount, date, periodQuotas, theInit) => {

            if (periodQuotas && !theInit) {
                const quota = periodQuotas.find(el => el.data_referencia == formatPtStringDateToAnbimaDate(date));
                // console.log("Quota: ", quota);
                if (quota) {

                    return {
                        qtdQuotas: amount / quota.valor_cota,
                        quotaValue: quota.valor_cota,
                    };
                }
            } else if (primaryValueQuotas) {
                return {
                    qtdQuotas: null,
                    quotaValue: primaryValueQuotas,
                };
            }

            // if (transaction.qtdQuotas && transaction.quotaValue) {

            //     return {
            //         qtdQuotas: transaction.qtdQuotas,
            //         quotaValue: transaction.quotaValue,
            //     };
            // }
            return {
                qtdQuotas: null,
                quotaValue: null
            };
        }

        applicationsArray.forEach(app => {

            const { qtdQuotas, quotaValue } = getQuotaInfos(
                app.amount,
                app.date,
                mapQuotasByPeriod[app.date.substring(3)]
            );
            app.balanceQuotas = app.qtdQuotas ? app.qtdQuotas : qtdQuotas;

        });

        console.log("applicationsArray: ", applicationsArray);

        const getApplicationsToRescue = (rescueAmount, fullQtdQuotas, rescueQuotaValue, rescueDate) => {

            let currentRescueAmount = rescueAmount;
            const theApplicationsToRescue = [];
            for (let index = 0; index < applicationsArray.length; index++) {
                const application = applicationsArray[index];

                if (currentRescueAmount == 0) {
                    break;
                }

                if (application.amount > 0) {

                    //corrigindo valor da trnasação no momento da testagem
                    application.amount = rescueQuotaValue * application.balanceQuotas;

                    if (application.amount > currentRescueAmount) {
                        //console.log("$$$$$$$$$$$$$$$$$$$$$");
                        //console.log("application.amount: ", application.amount);
                        const qtdQuotasRescued = currentRescueAmount * fullQtdQuotas / rescueAmount;
                        // console.log("currentRescueAmount: ", currentRescueAmount);
                        // console.log("fullQtdQuotas: ", fullQtdQuotas);
                        // console.log("application.amount: ", application.amount);
                        application.amount -= currentRescueAmount;
                        application.rescuedAmount = currentRescueAmount;
                        application.balanceQuotas -= qtdQuotasRescued;

                        if (application.amount.toString().startsWith("0.00")) {
                            application.amount = 0;
                            application.balanceQuotas = 0;
                            application.qtdQuotas = 0;
                        }


                        theApplicationsToRescue.push(application);

                        break;
                    } else {

                        application.rescuedAmount = application.amount;
                        application.amount -= currentRescueAmount;
                        currentRescueAmount = application.amount * (-1);
                        if (currentRescueAmount.toString().startsWith("0.00")) {
                            currentRescueAmount = 0;
                        }
                        application.amount = 0;
                        application.balanceQuotas = 0;
                        theApplicationsToRescue.push(application);

                    }

                }
            }

            return theApplicationsToRescue;
        }

        const getPeriodsToRent = (nextPeriod) => {

            console.log("NEXT PERIOD: ", nextPeriod);
            console.log("allPeriods: ", allPeriods);
            const toRentPeriods = [];
            for (let index = 0; index < allPeriods.length; index++) {
                const element = allPeriods[index];
                if (element == nextPeriod) {
                    break;
                }
                if (!monetizedPeriods.includes(element)) {
                    toRentPeriods.push(element);
                }
            }

            return toRentPeriods;
        }

        const doRentApplications = (appsArray, beforePeriod, currentPeriod) => {

            // console.log("appsArray: ", appsArray);
            // console.log("beforePeriod: ", beforePeriod);
            // console.log("currentPeriod: ", currentPeriod);
            const monetizedApplications = [];

            const quotaBeforePeriod = mapQuotasByPeriod[beforePeriod][mapQuotasByPeriod[beforePeriod].length - 1].valor_cota;
            const lastQuotaPeriod = mapQuotasByPeriod[currentPeriod][mapQuotasByPeriod[currentPeriod].length - 1].valor_cota;
            //appsArray.filter(el => el.amount > 0 && (el.quotaValue || el.date.substring(3) == period)).forEach(app => {
            appsArray.filter(el => el.amount > 0 && (
                monetizedPeriods.includes(el.date.substring(3))
                ||
                el.date.substring(3) == beforePeriod
                ||
                el.date.substring(3) == currentPeriod
            )).forEach(app => {

                // // console.log("Rentabilizar: ", app);
                const lastQuotaBeforePeriod = app.quotaValue ? app.quotaValue : quotaBeforePeriod;

                // console.log("111111###########################");
                // console.log("period: ", period);
                // console.log("lastQuotaPeriod: ", lastQuotaPeriod);
                // console.log("lastQuotaBeforePeriod: ", lastQuotaBeforePeriod);

                // console.log("222222###########################");
                // console.log(JSON.parse(JSON.stringify(app)));
                // console.log(app.date);
                // console.log("amount antes: ", app.amount);
                // console.log("app.balanceQuotas: ", app.balanceQuotas);

                const rentAmount = (lastQuotaPeriod - lastQuotaBeforePeriod) * app.balanceQuotas;

                //console.log("rentAmount: ", rentAmount);

                const beforeAmount = app.amount;

                //app.amount += rentAmount;
                app.amount = app.balanceQuotas * lastQuotaPeriod;


                monetizedApplications.push({
                    monetized: true,
                    date: app.date,
                    beforeAmount: beforeAmount,
                    rentAmount: rentAmount,
                    resultAmount: app.amount,
                    balanceQuotas: app.balanceQuotas,
                    lastQuotaPeriod: `${lastQuotaPeriod}[${currentPeriod}]`,
                })


            });

            console.error("====MONETIZED=========================");
            console.log("[" + beforePeriod + "]");
            console.log("======================================");
            console.log(JSON.parse(JSON.stringify(applicationsArray)));
            console.log(JSON.parse(JSON.stringify(monetizedApplications)));
            console.log("======================================");

            monetizedPeriods.push(beforePeriod);

            return monetizedApplications

        }


        // let currentPeriod = fullTransactionsArray[1].date.substring(3);
        // console.log("currentPeriod: ", currentPeriod);
        let beforePeriod = allPeriods[0];

        allPeriods.forEach((thePeriod, index) => {


            if (index !== 0) {

                const periodLoop = thePeriod;

                const splittedBeforePeriod = beforePeriod.split("/");
                const lastDayBeforeMonth = getLastDayInMonthByMonthAndYear(parseInt(splittedBeforePeriod[0]), parseInt(splittedBeforePeriod[1]));

                //LINHA SALDO ANTERIOR
                formattedLines.push({
                    date: lastDayBeforeMonth,
                    description: "SALDO ANTERIOR",
                    amount: mapBalancesByPeriod[beforePeriod],
                    qtdQuotas: currentBalanceQuotas,
                    quotaValue: "",
                    balanceQuotas: "",
                    profit: "",
                    pasep: "",
                    balanceApplicationsQuotas: "",
                });

                let profitPeriod = 0;
                let pasepPeriod = 0;

                //console.log("Period: ", periodLoop);
                //console.log(fullTransactionsArray.filter(el => el.date.substring(3) == periodLoop));
                let fullProfit = 0;
                fullTransactionsArray.filter(el => el.date.substring(3) == periodLoop &&
                    !el.quotaValue //removendo o saldo inicial
                ).forEach((transactionLoop, index) => {

                    const { operation_id } = transactionLoop;

                    if (operation_id === 1) { //APLICAÇÃO

                        currentBalance += transactionLoop.amount;
                        const { qtdQuotas, quotaValue } = getQuotaInfos(
                            transactionLoop.amount,
                            transactionLoop.date,
                            mapQuotasByPeriod[transactionLoop.date.substring(3)]
                        );
                        currentBalanceQuotas += qtdQuotas;

                        //LINHA DE APLICAÇÃO
                        formattedLines.push({
                            date: transactionLoop.date,
                            description: "APLICAÇÃO",
                            amount: transactionLoop.amount,
                            qtdQuotas: qtdQuotas,
                            quotaValue: quotaValue,
                            balanceQuotas: currentBalanceQuotas,
                            profit: "",
                            pasep: "",
                            balanceApplicationsQuotas: qtdQuotas,
                        });

                    } else { //RESGATE

                        const rescuePeriod = transactionLoop.date.substring(3);

                        // console.log("rescue.date.substring(3): ", rescue.date.substring(3));
                        const { qtdQuotas, quotaValue } = getQuotaInfos(
                            transactionLoop.amount,
                            transactionLoop.date,
                            mapQuotasByPeriod[rescuePeriod]
                        );
                        // console.log("qtdQuotas, quotaValue: ", qtdQuotas, quotaValue);

                        //LINHA DE RESGATE
                        formattedLines.push({
                            date: transactionLoop.date,
                            description: "RESGATE",
                            amount: transactionLoop.amount,
                            qtdQuotas: qtdQuotas,
                            quotaValue: quotaValue,
                            balanceQuotas: currentBalanceQuotas - qtdQuotas,
                            profit: "",
                            pasep: "",
                            balanceApplicationsQuotas: parseFloat(selectedAsset.init_quotas),
                        });

                        const theApplications = getApplicationsToRescue(transactionLoop.amount, qtdQuotas, quotaValue, transactionLoop.date);

                        //console.log("theApplications: ", theApplications);

                        theApplications.forEach(application => {

                            ///console.log("RESCUED APPLICATION: ", application);

                            application.rescuedDate = transactionLoop.date;

                            const {
                                qtdQuotas: qtdQuotasApplication,
                                quotaValue: quotaValueInRescueDate
                            } = getQuotaInfos(
                                application.rescuedAmount,
                                transactionLoop.date,
                                mapQuotasByPeriod[rescuePeriod]
                            );

                            const {
                                qtdQuotas: _none,
                                quotaValue: quotaValueApplication
                            } = getQuotaInfos(
                                application.amount,
                                application.date,
                                mapQuotasByPeriod[application.date.substring(3)],
                                application.quotaValue ? true : false,
                            );

                            currentBalance -= application.rescuedAmount;
                            currentBalanceQuotas -= qtdQuotasApplication;

                            const profit = (quotaValue - quotaValueApplication) * qtdQuotasApplication;
                            const pasep = profit * 1 / 100;

                            profitPeriod += profit;
                            pasepPeriod += pasep;

                            //LINHA DE APLICAÇÃO
                            formattedLines.push({
                                date: "",
                                description: "APLICAÇÃO " + application.date,
                                amount: application.rescuedAmount,
                                qtdQuotas: qtdQuotasApplication,
                                quotaValue: quotaValueApplication,
                                balanceQuotas: application.balanceQuotas,
                                profit: profit,
                                pasep: pasep,
                                balanceApplicationsQuotas: parseFloat(selectedAsset.init_quotas),
                            });

                            //application.amount += profit;

                        });

                    }



                });

                /////////////////////////////
                //Fechando período mensal////
                /////////////////////////////      

                const splittedCurrentPeriod = periodLoop.split("/");
                const lastDayMonth = getLastDayInMonthByMonthAndYear(parseInt(splittedCurrentPeriod[0]), parseInt(splittedCurrentPeriod[1]));

                //LINHA SALDO ATUAL
                formattedLines.push({
                    date: lastDayMonth,
                    description: "SALDO ATUAL",
                    amount: mapBalancesByPeriod[periodLoop],
                    qtdQuotas: currentBalanceQuotas,
                    quotaValue: "",
                    balanceQuotas: currentBalanceQuotas,
                    profit: profitPeriod,
                    pasep: pasepPeriod,
                    balanceApplicationsQuotas: "",
                });

                formattedLines.push(blankLine);
                //const periodsToRent = getPeriodsToRent(periodLoop);
                //console.log("periodsToRent: ", beforePeriod);
                const monetized = doRentApplications(applicationsArray, beforePeriod, periodLoop);

                //rentabilizando primeiro aporte caso exista
                // if (periodsToRent.length === 0 && applicationsArray[0]?.quotaValue && applicationsArray[0].amount > 0) { //aporte inicial por saldo deve ser rentabilizado

                //     const app = applicationsArray[0];
                //     const lastQuotaPeriod = mapQuotasByPeriod[periodLoop][mapQuotasByPeriod[periodLoop].length - 1].valor_cota;
                //     const beforeAmount = app.amount;
                //     const rent = (lastQuotaPeriod - app.quotaValue) * app.balanceQuotas;
                //     const resultAmount = beforeAmount + rent;
                //     app.amount = resultAmount;

                //     monetized.push({
                //         monetized: true,
                //         date: app.date,
                //         beforeAmount: beforeAmount,
                //         rentAmount: rent,
                //         resultAmount: resultAmount,
                //         description: `${lastQuotaPeriod} - ${app.quotaValue} * ${app.balanceQuotas}`
                //     })
                // }


                beforePeriod = periodLoop;

                // monetized.forEach(element => {

                //     formattedLines.push(element);
                // });

                formattedLines.push(blankLine);

                /////////////////////////////
                /////////////////////////////

            }


        });


        // const splittedCurrentPeriod = currentPeriod.split("/");
        // const lastDayMonth = getLastDayInMonthByMonthAndYear(parseInt(splittedCurrentPeriod[0]), parseInt(splittedCurrentPeriod[1]));

        // //LINHA SALDO ATUAL
        // formattedLines.push({
        //     date: lastDayMonth,
        //     description: "SALDO ATUAL",
        //     amount: mapBalancesByPeriod[currentPeriod],
        //     qtdQuotas: currentBalanceQuotas,
        //     quotaValue: "",
        //     balanceQuotas: currentBalanceQuotas,
        //     profit: "",
        //     pasep: "",
        //     balanceApplicationsQuotas: "",
        // });
        console.log("formattedLines: ", formattedLines);
        console.log("formattedLines: ", formattedLines.filter(el => el.description.includes("APLICAÇÃO ")));
        return formattedLines;
    }

    const loadTransactions = async (selectedAsset) => {
        const response = await getTransactionsByAsset(selectedAsset.id);

        console.log("response: ", response);
        if (response.success) {
            setTransactions(response.body.rows);
        }
        setLines(await formatLinesReturnsIPC14(selectedAsset, response.body.rows));
    }

    const getLineRow = (row, index) => {

        if (row.monetized) {
            return <TableRow
                key={'asset-returns-ipc14-lines' + index}>

                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="right">{row.lastQuotaPeriod}</TableCell>
                <TableCell align="left">{" x "}</TableCell>
                <TableCell align="left">{applyMaskAmount(row.balanceQuotas)}</TableCell>
                <TableCell align="left">{"="}</TableCell>
                <TableCell align="left">{applyMaskAmount(row.resultAmount, true)}</TableCell>
                <TableCell align="left">{""}</TableCell>
                <TableCell align="right">{""}</TableCell>
                {/* <TableCell align="center">{row.balanceApplicationsQuotas ? applyMaskAmount(row.balanceApplicationsQuotas) : ""}</TableCell> */}


            </TableRow>
        } else {
            return <TableRow
                key={'asset-returns-ipc14-lines' + index}>

                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="right" className={row.description == "RESGATE" ? classes.red : classes.green}>{applyMaskAmount(row.amount, true)}</TableCell>
                <TableCell align="right">{row.qtdQuotas ? applyMaskAmount(row.qtdQuotas) : ""}</TableCell>
                <TableCell align="right">{row.quotaValue ? applyMaskAmountFreeDecimals(row.quotaValue, null, 5) : ""}</TableCell>
                <TableCell align="right" className={row.description.includes("APLICAÇÃO") && row.profit ? classes.blue : ""}>{row.balanceQuotas ? applyMaskAmount(row.balanceQuotas) : ""}</TableCell>
                <TableCell align="right">{row.profit ? applyMaskAmount(row.profit, true) : ""}</TableCell>
                <TableCell align="right">{row.pasep ? applyMaskAmount(row.pasep, true) : ""}</TableCell>
                {/* <TableCell align="center">{row.balanceApplicationsQuotas ? applyMaskAmount(row.balanceApplicationsQuotas) : ""}</TableCell> */}


            </TableRow>

        }
    }

    function doReport(reportLines) {

        const { data: asset } = content;
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'ipc14_returns',
            message: 'Gerando relatório...',
            reportData: {
                asset: asset.fund_name + " - CC: " + applyMaskAccount(asset.number_account) + " Ag: " + asset.agency,
                lines: reportLines.map(row => {

                    if (row.blankLine) {
                        return {
                            ...row,
                        };
                    }

                    return {
                        ...row,
                        amount: row.amount ? applyMaskAmount(row.amount, true) : "-",
                        qtdQuotas: row.qtdQuotas ? applyMaskAmount(row.qtdQuotas) : "-",
                        quotaValue: row.quotaValue ? applyMaskAmountFreeDecimals(row.quotaValue, null, 5) : "-",
                        balanceQuotas: row.balanceQuotas ? applyMaskAmount(row.balanceQuotas) : "-",
                        profit: row.profit ? applyMaskAmount(row.profit, true) : "-",
                        pasep: row.pasep ? applyMaskAmount(row.pasep, true) : "-",
                    }

                })
            }
        }))
    }

    useState(() => {
        if (content?.data) {
            const { data: asset } = content;
            console.log("asset: ", asset);
            loadTransactions(asset)
        }
    }, [])

    return (
        <div className={classes.root}>

            <Paper elevation={0}>
                <div className={classes.header}>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                        <div className={classes.leftHeader}>

                            <Typography
                                align={'left'}
                                style={{ color: unoTheme.secondaryColor }}>
                                IPC14
                            </Typography>
                            <Typography
                                align={'left'}
                                className={classes.fundLabel}>
                                {content?.data?.fund_name ?? ""}

                            </Typography>

                            <Typography
                                align={'left'}
                                className={classes.cnpjLabel}>
                                {content?.data?.cnpj ?? ""}
                                <span style={{ marginLeft: "12px" }}>CC: {content?.data?.number_account ? applyMaskAccount(content.data.number_account) : "-"}</span>
                                <span style={{ marginLeft: "12px" }}>Ag: {content?.data?.agency ?? "-"}</span>
                            </Typography>
                        </div>
                        <UnoButton
                            style={{ marginRight: '16px' }}
                            size={'small'}
                            onClick={() => doReport(lines)}
                            type='primary'
                            disabled={!lines?.length}
                            startIcon={<PrintIcon />}>
                            {"Gerar relatório"}
                        </UnoButton>
                    </div>



                    <IconButton className="closeIcon" onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                        <CloseIcon />
                    </IconButton>

                </div>
            </Paper>

            <Divider />
            <div className={classes.main}>
                <div className='tableTransactions'>
                    <MyTable stickyHeader
                        selectable
                        size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="15%" />
                            {/*<col width="10%" />
                            <col width="10%" />
                            <col width="10%" /> */}
                        </colgroup>
                        <TableHead>
                            <TableRow>

                                <TableCell align="center">DATA</TableCell>
                                <TableCell align="left">DESCRIÇÃO</TableCell>
                                <TableCell align="right">VALOR</TableCell>
                                <TableCell align="right">QUANTIDADE DE COTAS</TableCell>
                                <TableCell align="right">VALOR COTA</TableCell>
                                <TableCell align="right">SALDO COTAS</TableCell>
                                <TableCell align="right">RECEITA ORÇAMENTÁRIA</TableCell>
                                <TableCell align="right">PASEP</TableCell>
                                {/* <TableCell align="center">SALDO DAS APLICAÇÕES</TableCell> */}

                            </TableRow>
                        </TableHead>


                        <TableBody>
                            {lines.map((row, index) => row.blankLine ?
                                (<TableRow
                                    key={'asset-returns-ipc14-lines' + index}>

                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    <TableCell align="center">=</TableCell>
                                    {/* <TableCell align="center">{row.balanceApplicationsQuotas ? applyMaskAmount(row.balanceApplicationsQuotas) : ""}</TableCell> */}


                                </TableRow>)
                                :
                                (

                                    getLineRow(row, index)

                                ))}
                        </TableBody>

                    </MyTable>
                </div>

            </div>

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'} style={{ marginTop: '16px' }}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }

        </div>
    );
}