import React from 'react';
import { Box, Grid, makeStyles } from "@material-ui/core";
import { unoTheme } from "../../../../../assets/styles/unoTheme";

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    },
    formFooter: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    footerButtons: {
        paddingTop: theme.spacing(2),
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

export const Wrapper = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.wrapper}>{children}</Box>;

};

export const FormWrapper = ({ children }) => (
    <Grid container spacing={2}>
        {children}
    </Grid>
);

export const Footer = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.formFooter}>{children}</Box>;
};

export const FooterButtonsWrapper = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.footerButtons}>{children}</Box>;
};