import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Paper, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme } from "../../../assets/styles/unoTheme";

//Tables
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTextField from "../../utils/MyTextField";

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import Switch from '@material-ui/core/Switch';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    getArrayMonthsWithNames,
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    applyMaskAccount,
    parseFloatByPtCurrency
} from "../../utils/utils";

//API
import { createInflationRate, updateInflationRate } from '../../../API/inflation_rate';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        backgroundColor: unoTheme.hoverTable,
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiTableContainer-root': {
            height: 'calc(100% - 170px)',
        },

        '& .MuiTableHead-root': {
            '& .MuiTableCell-head': {

                //background: `${unoTheme.hoverTable} !important`,
            }
        }
    },

    leftPadding24: {
        paddingLeft: theme.spacing(3),
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        height: 'calc(100% - 1px)',

        '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
                background: 'white',
            }
        }
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '12px',
        },

        '& .MuiTableCell-root:first-child': {
            paddingLeft: '24px !important',
        }
        // '&:nth-of-type(odd)': {
        //     backgroundColor: unoTheme.secondaryTuplaTable,
        // },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },

    headerDate: {
        display: 'flex',
        alignItems: 'center',
        color: unoTheme.secondaryColor,
        fontSize: '12px',
        '& svg': {
            marginRight: '5px',
        },
        '& > *': {
            fontSize: '14px',
        }
    },

    accountLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    dateLabel: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228',

        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1),
        }
    },

    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    tabsAsset: {
        background: '#F3F5F8',
    }


}));

export default function NewInflationRate(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props;

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [months, setMonths] = useState(getArrayMonthsWithNames())

    const [inputs, setInputs] = useState([
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
    ]);

    function initInputs(data) {


        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        let newInputs = []

        months.forEach(m => {

            newInputs.push({
                //No momento de salvar os índices testa-se se o ID existe para que então seja enviado para o método update em vez do create
                inflation_rate_id: data.inflationRates[m] && data.inflationRates[m].id ? data.inflationRates[m].id : null,
                month: m,
                year: data.year,
                ipca: data.inflationRates[m] && data.inflationRates[m].ipca ? applyMaskAmount(data.inflationRates[m].ipca) : null,
                inpc: data.inflationRates[m] && data.inflationRates[m].inpc ? applyMaskAmount(data.inflationRates[m].inpc) : null,
            })

        });

        console.log("NEW INPUTS: ", newInputs)
        setInputs(newInputs);
        //setInitInputs(newInputs);

    }

    function handleChangeIpcaInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].ipca = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    function handleChangeInpcInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].inpc = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    async function doSaveInflationRates(ipts, year) {

        setOpenBackdrop(true)

        //let filterStatusOk = ipts.filter(el => el.status || el.statusChange)
        console.log("TO SAVE INFLATES: ", ipts);
        //São filtrados os objetos que sofreram alteração e que estão com status de OK
        let filterStatusOk = ipts.filter(el => el.statusChange)
        console.log("TO FILTERES: ", filterStatusOk);

        let allSuccess = true;
        for (let i = 0; i < filterStatusOk.length; i++) {

            let inflationRateLoop = filterStatusOk[i];
            inflationRateLoop.ipca_to_save = inflationRateLoop.ipca ? parseFloatByPtCurrency(inflationRateLoop.ipca) : null;
            inflationRateLoop.inpc_to_save = inflationRateLoop.inpc ? parseFloatByPtCurrency(inflationRateLoop.inpc) : null;

            // let inflationRate = {
            //     ipca: parseFloatByPtCurrency(IPCA),
            //     inpc: parseFloatByPtCurrency(INPC),
            //     date_rate: dateRate,
            //     month: parseInt(moment.utc(dateRate).format("MM")),
            //     year: parseInt(moment.utc(dateRate).format("YYYY")),
            // }

            console.log("INFLATE TO SAVE: ", inflationRateLoop);

            if (inflationRateLoop.inflation_rate_id) {

                const responseInflationRate = await updateInflationRate(inflationRateLoop);
                if (!responseInflationRate.success) {
                    allSuccess = false
                    break;
                }

            } else {

                const responseInflationRate = await createInflationRate(inflationRateLoop);
                if (!responseInflationRate.success) {
                    allSuccess = false
                    break;
                }

            }

        }

        if (allSuccess) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Índices ' + content.data.year + ' atualizados com sucesso!'
            }))

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao atualizar Índices!'
            }))
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update())

    }



    useEffect(() => {

        console.log("USE EFFECT")
        if (content && content.data) {

            //console.log("DATA: ", content)
            console.log("DATA INFLATION RATES: ", content.data)
            initInputs(content.data);

        }
    }, [])

    return (
        <div className={classes.root}>


            <div className={classes.header}>

                <div className={classes.leftHeader}>

                    <div>
                        <Typography
                            align={'left'}
                            className={classes.dateLabel}>
                            <EventIcon />{content && content.data ? content.data.year : ""}
                        </Typography>
                        <Typography
                            align={'left'}
                            className={classes.accountLabel}>
                            <span>{"IPCA Acum: "}<span style={{ color: 'blue' }}>{content.data.ipcaAcum + '%'}</span></span>
                            <span className={classes.spacingLeft2}>{"INPC Acum: "}<span style={{ color: 'blue' }}>{content.data.inpcAcum + '%'}</span></span>
                        </Typography>
                    </div>

                </div>

                <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div>
            <div className={classes.main}>


                <TableContainer
                    component={Paper}
                    elevation={0}>
                    <Table className={classes.table}
                        stickyHeader
                        size="small">

                        <colgroup>
                            <col width="40%" />
                            <col width="30%" />
                            <col width="30%" />
                        </colgroup>

                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.leftPadding24} align="left">MÊS</TableCell>
                                <TableCell align="center">IPCA</TableCell>
                                <TableCell align="center">INPC</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.table}>
                            {
                                months.map((row) => (
                                    <>

                                        <TableRow className={classes.detailRow}
                                            key={'detail-month' + row.id}>

                                            <TableCell align="left">
                                                {row.name}
                                            </TableCell>

                                            <TableCell align="left">

                                                <MyTextField
                                                    align="right"
                                                    onChange={(value) => handleChangeIpcaInput(value, parseInt(row.id) - 1)}
                                                    value={inputs[parseInt(row.id) - 1].ipca}
                                                    verysmall
                                                    placeholder="0.00"
                                                    //mask={["currency"]}
                                                    size={'small'}
                                                    variant="outlined" />

                                            </TableCell>


                                            <TableCell align="left">
                                                <MyTextField
                                                    align="right"
                                                    onChange={(value) => handleChangeInpcInput(value, parseInt(row.id) - 1)}
                                                    value={inputs[parseInt(row.id) - 1].inpc}
                                                    verysmall
                                                    placeholder="0.00"
                                                    size={'small'}
                                                    variant="outlined" />

                                            </TableCell>


                                        </TableRow >
                                    </>
                                ))

                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <div className={classes.bottom}>

                    <UnoButton
                        onClick={() => doSaveInflationRates(inputs, content.data.year)}
                        className={classes.spacingRight2}
                        type='primary'
                        size={'small'}>
                        {"Salvar índices"}
                    </UnoButton>
                </div>

            </div>

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }

        </div >
    );
}



