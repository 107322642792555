import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { unoTheme } from '../../assets/styles/unoTheme';

export const MyTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 80,
            width: '100%',
            backgroundColor: unoTheme.mainColor,
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const MyTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: unoTheme.mainColor,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab {...props} />);
//}))((props) => <Tab disableRipple {...props} />);