import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyButton from '../../utils/MyButton'
import MyTextField from '../../utils/MyTextField'
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';

//API
import {
    createNewLegislationArticle,
    listLegislationSegments,
    updateLegislationArticle,
    updateLegislationSegment,
} from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import MySelect from '../../utils/MySelect';
import UnoButton from '../../utils/UnoButton';

import CircularProgress from '@material-ui/core/CircularProgress';
import MyInfo from '../../utils/MyInfo';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function DetailAdminLegislationSegment(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [inputDescriptionSegment, setInputDescriptionSegment] = useState("");
    const [inputLimitSegment, setInputLimitSegment] = useState("");

    //Pro gestão
    const [inputPGI, setInputPGI] = useState("");
    const [inputPGII, setInputPGII] = useState("");
    const [inputPGIII, setInputPGIII] = useState("");
    const [inputPGIV, setInputPGIV] = useState("");

    const [submitted, setSubmitted] = useState(false);

    async function doUpdateLegislationSegment() {

        setSubmitted(true);

        if (inputLimitSegment != ""
            && inputDescriptionSegment != "") {

            let legislationSegment = {
                limit_segment: parseFloat(inputLimitSegment),
                description: inputDescriptionSegment,
                limit_pg1: inputPGI,
                limit_pg2: inputPGII,
                limit_pg3: inputPGIII,
                limit_pg4: inputPGIV,

            }

            console.log("ARTICLE TO SAVE: ", legislationSegment)

            let response = await updateLegislationSegment(content.data.id, legislationSegment);

            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'Artigo de resolução atualizado com sucesso'
                }))

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao atualizar artigo de resolução'
                }))
            }


            dispatch(allActions.rightContentActions.update())

        }


    }

    async function loadInfos(data) {

        setInputDescriptionSegment(data.description);
        setInputLimitSegment(data.limit_segment);

        setInputPGI(data.limit_pg1);
        setInputPGII(data.limit_pg2);
        setInputPGIII(data.limit_pg3);
        setInputPGIV(data.limit_pg4);

    }


    useEffect(() => {

        if (content && content.data) {

            console.log("DATA: ", content.data);
            loadInfos(content.data);
        }

    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.header}>
                <IconButton className={classes.hiddenIcon}>
                    <CloseIcon />
                </IconButton>

                <div>
                    <Typography
                        variant={'h6'}
                        align={'left'}>
                        {'Editar Segmento de Resolução'}

                    </Typography>
                </div>


                <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div>
            <div className={classes.main}>

                <Grid container spacing={2}>

                    <Grid item xs={12} lg={7} md={7}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDescriptionSegment}
                            // defaultValue={inputAssetArticle}
                            onChange={(value) => setInputDescriptionSegment(value)}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Descrição segmento'}
                            type="text"
                            validator={inputDescriptionSegment == "" && submitted}
                            placeholder="Insira a descrição do segmento"
                            helperText="A descrição do segmento é necessária"
                            fullWidth />
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputLimitSegment}
                            onChange={(value) => setInputLimitSegment(value)}
                            variant="outlined"
                            margin="dense"
                            align='right'
                            outerlabel={'Limite'}
                            validator={inputLimitSegment == "" && submitted}
                            helperText="O limite deve ser preenchido"
                            type="text"
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>

                    {/* PRE-GESTAO */}
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGI}
                            value={inputPGI}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG I'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGII}
                            value={inputPGII}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG II'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGIII}
                            value={inputPGIII}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG III'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGIV}
                            value={inputPGIV}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG IV'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>

                </Grid>

                <br />
                <div className={classes.bottom}>


                    {
                        validationMessage != '' ?
                            <Typography className={classes.validationMessage}>
                                {validationMessage}
                            </Typography>
                            : null

                    }


                    <UnoButton
                        type={'success'}
                        float={'right'}
                        onClick={() => doUpdateLegislationSegment()}>
                        {'Atualizar'}
                    </UnoButton>

                </div>


            </div>
            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }
        </div>
    );
}