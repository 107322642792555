import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../utils/MyTable';

//Utils
import { applyMaskAmount } from '../utils/utils';

import { useDispatch } from "react-redux";
import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { myLabelInput, unoTheme } from '../../assets/styles/unoTheme';

import moment from 'moment';
import { updateClientAprDataTransactions, updateNumberApr } from '../../API/transaction';
import allActions from '../../actions';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    labelTopModal: {
        float: 'right',
        fontSize: '14px',
        color: unoTheme.defaultTextColor
    }


}));

export default function UpdateNumbersAprsTransactionsModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { clientId, newNumberApr,
        referenceDate,
        transactionsToUpdate,
        handleAction } = props;

    const [loaderMessage, setLoaderMessage] = useState("");

    async function doUpdate() {

        setLoaderMessage("Atualizando números das APRs...");
        console.log("UPDATE: ", transactionsToUpdate);

        const arrayIdsTransactions = [];
        transactionsToUpdate.forEach(element => {
            arrayIdsTransactions.push(element.id);
        });

        let nextNewNumber = newNumberApr;
        let processedAprs = 0;
        for (let index = 0; index < arrayIdsTransactions.length; index++) {
            const transactionId = arrayIdsTransactions[index];
            const splittedNumber = nextNewNumber.split("/");
            nextNewNumber = `${(parseInt(splittedNumber[0]) + 1).toString().padStart(3, "0")}/${splittedNumber[1]}`;
            await updateNumberApr(transactionId, nextNewNumber);
            processedAprs++;
            setLoaderMessage(() => "Atualizando números das APRs..." + Number.parseInt((processedAprs / arrayIdsTransactions.length * 100)) + "%")
        }

        dispatch(allActions.mainAlertActions.setMainAlert({
            show: true,
            type: 'success',
            message: 'APRs Atualizadas com sucesso!'
        }))

        setLoaderMessage("");
        handleAction();

    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={newNumberApr ? true : false}
            onClose={() => handleAction()}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"APRs a serem Atualizadas"}
                {
                    transactionsToUpdate ?
                        <span className={classes.labelTopModal}>Após {moment.utc(referenceDate).format('DD/MM/YYYY')} - {transactionsToUpdate.length} movimentações</span>
                        : null
                }
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {
                    loaderMessage ?
                        <div className={classes.progress}>
                            <CircularProgress
                                disableShrink color="inherit" />
                            <Typography variant={"h6"}>
                                {loaderMessage}
                            </Typography>
                        </div>
                        :
                        <>

                            <Typography variant={"body1"} color={'primary'} style={{ marginBottom: '16px' }}>
                                {"As seguintes APRs possuem data posterior a data da APR em que foi atualizado o NÚMERO DA APR. Você deseja alterar os números das APRs seguintes do mesmo ano?"}
                            </Typography>
                            {/* Movimentações a serem removidas */}

                            <MyTable
                                style={{ marginTop: '8px' }}
                                stickyHeader
                                sizeheaders="verySmall"
                                size="small">

                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableTh} align="center">Núm.</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Data</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Operação</TableCell>
                                        <TableCell className={classes.tableTh} align="right">Valor</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        //content.data.transactions.map((row, index) => (
                                        transactionsToUpdate && transactionsToUpdate.map((row, index) => (


                                            <TableRow className={classes.detailRow}
                                                key={'detail-transaction-' + index}>

                                                <TableCell align="center" style={{ color: unoTheme.mainColor }}>
                                                    {row.number_apr}
                                                </TableCell>

                                                <TableCell align="center" className={classes.selectableInfo}>
                                                    {moment.utc(row.transaction_date).format('DD/MM/YYYY')}
                                                </TableCell>

                                                <TableCell align="center" className={
                                                    row.operation_id == 1 ? classes.green : (
                                                        row.operation_id == 2 ? classes.red : classes.blue
                                                    )
                                                }>
                                                    {row.operation_id == 1 ? 'Aplicação' : (row.operation_id == 2 ? 'Resgate' : 'Amortização')}
                                                </TableCell>

                                                <TableCell align="right" className={
                                                    row.operation_id == 1 ? classes.green : (
                                                        row.operation_id == 2 ? classes.red : classes.blue
                                                    )
                                                }>
                                                    {row && row.amount ? applyMaskAmount(row.amount, true) : null}
                                                </TableCell>

                                            </TableRow>



                                        ))

                                    }
                                </TableBody>

                            </MyTable>


                        </>
                }


            </DialogContent>


            {
                !loaderMessage ?
                    <>

                        <DialogActions className={classes.actionsFlex}>

                            <UnoButton onClick={() =>
                                handleAction()} color="secondary">
                                {'Não atualizar'}
                            </UnoButton>

                            <UnoButton
                                className={classes.spaceRight2}
                                onClick={() => doUpdate()}
                                color="primary">
                                {'Confirmar'}
                            </UnoButton>

                        </DialogActions>

                    </>
                    : null

            }




        </Dialog >
    );
}