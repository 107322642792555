import { formatTextForValidDisplay } from "../utils";

export const ManagersDictionary = {

    dictionary: {
        "4UM GESTÃO DE RECURSOS LTDA.": "4UM INVESTIMENTOS",
        "ALASKA INVESTIMENTOS LTDA.": "ALASKA INVESTIMENTOS",
        "ARENA CAPITAL ASSET ADMINISTRAÇÃO DE RECURSOS LTDA": "ARENA CAPITAL",
        "ARX INVESTIMENTOS LTDA": "ARX INVESTIMENTOS",
        "AZ QUEST INVESTIMENTOS LTDA.": "AZ QUEST INVESTIMENTOS",
        "BANCO DO NORDESTE DO BRASIL SA": "BANCO DO NORDESTE",
        "BANCO J. SAFRA S.A.": "BANCO J. SAFRA",
        "BANESTES DTVM SA": "BANESTES",
        "BB GESTAO DE RECURSOS DTVM S.A": "BB ASSET",
        "BRAM - BRADESCO ASSET MANAGEMENT S.A. DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS": "BRAM - BRADESCO ASSET",
        "BRPP GESTÃO DE PRODUTOS ESTRUTURADOS LTDA.": "BRPP GESTÃO",
        "BRZ INVESTIMENTOS LTDA": "BRZ INVESTIMENTOS",
        "BTG PACTUAL ASSET MANAGEMENT S/A DTVM": "BTG PACTUAL ASSET",
        "BTG PACTUAL GESTORA DE RECURSOS LTDA": "BTG PACTUAL ASSET",
        "CAIXA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.": "CAIXA ASSET",
        "CLARITAS ADMINISTRAÇÃO DE RECURSOS LTDA": "CLARITAS ",
        "CONFEDERAÇÃO INTERESTADUAL DAS COOPERATIVAS LIGADAS AO SICREDI": "SICREDI",
        "CONSTÂNCIA INVESTIMENTOS LTDA.": "CONSTÂNCIA INVESTIMENTOS",
        "DAYCOVAL ASSET MANAGEMENT ADMINISTRACAO DE RECURSOS LTDA": "DAYCOVAL ASSET",
        "FINACAP INVESTIMENTOS LTDA": "FINACAP INVESTIMENTOS",
        "GENIAL GESTÃO LTDA.": "GENIAL GESTÃO",
        "GRAPHEN INVESTIMENTOS LTDA.": "GRAPHEN INVESTIMENTOS",
        "GUEPARDO INVESTIMENTOS LTDA": "GUEPARDO INVESTIMENTOS",
        "ICATU VANGUARDA GESTÃO DE RECURSOS LTDA": "ICATU VANGUARDA",
        "INDIE CAPITAL INVESTIMENTOS LTDA.": "INDIE CAPITAL",
        "ITAU DTVM S.A.": "ITAU DTVM",
        "ITAU UNIBANCO ASSET MANAGEMENT LTDA.": "ITAU ASSET",
        "KINEA PRIVATE EQUITY INVESTIMENTOS S/A": "KINEA PRIVATE EQUITY",
        "META ASSET MANAGEMENT LTDA.": "META ASSET",
        "MONGERAL AEGON INVESTIMENTOS LTDA": "MONGERAL AEGON",
        "MULTINVEST CAPITAL ADMINISTRADORA DE RECURSOS LTDA": "MULTINVEST CAPITAL",
        "NAVI CAPITAL ADMINISTRADORA E GESTORA DE RECURSOS FINANCEIROS LTDA.": "NAVI CAPITAL",
        "OCCAM BRASIL GESTÃO DE RECURSOS LTDA.": "OCCAM BRASIL",
        "OURO PRETO GESTÃO DE RECURSOS S.A.": "OURO PRETO",
        "PLURAL INVESTIMENTOS GESTÃO DE RECURSOS LTDA.": "PLURAL INVESTIMENTOS",
        "QLZ GESTÃO DE RECURSOS FINANCEIROS LTDA": "QLZ GESTÃO",
        "QUELUZ GESTÃO DE RECURSOS FINANCEIROS LTDA": "QUELUZ GESTÃO",
        "RB CAPITAL ASSET MANAGEMENT LTDA": "RB CAPITAL",
        "RENDA ASSET ADMINISTRADORA DE RECURSOS LTDA": "RENDA ASSET",
        "RIO BRAVO INVESTIMENTOS LTDA": "RIO BRAVO INVESTIMENTOS",
        "RJI CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA": "RJI CORRETORA",
        "SANTANDER BRASIL GESTÃO DE RECURSOS LTDA": "SANTANDER ASSET",
        "SCHRODER INVESTMENT MANAGEMENT BRASIL LTDA.": "SCHRODER INVESTMENT",
        "SICOOB DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.": "SICOOB DISTRIBUIDORA",
        "SINGULARE ADMINISTRAÇÃO FIDUCIÁRIA LTDA.": "SINGULARE ADMINISTRAÇÃO",
        "SOMMA INVESTIMENTOS S.A.": "SOMMA INVESTIMENTOS",
        "SUL AMÉRICA INVESTIMENTOS GESTORA DE RECURSOS S.A.": "SUL AMÉRICA INVESTIMENTOS",
        "TPE GESTORA DE RECURSOS LTDA.": "TARPON GESTORA",
        "TRÍGONO CAPITAL LTDA.": "TRÍGONO CAPITAL",
        "TRUXT INVESTIMENTOS LTDA.": "TRUXT INVESTIMENTOS",
        "VANQUISH ASSET MANAGEMENT LTDA.": "VANQUISH ASSET",
        "VCM GESTÃO DE CAPITAL LTDA": "VERITAS CAPITAL",
        "VILA RICA CAPITAL GESTORA DE RECURSOS LTDA": "VILA RICA CAPITAL",
        "VINCI SOLUÇÕES DE INVESTIMENTOS LTDA": "VINCI SOLUÇÕES",
        "WESTERN ASSET MANAGEMENT COMPANY DTVM LIMITADA": "WESTERN ASSET",
        "XP ALLOCATION ASSET MANAGEMENT LTDA.": "XP ASSET",
        "XP GESTÃO DE RECURSOS LTDA": "XP GESTÃO",
        "ZION GESTAO DE RECURSOS LTDA": "ZION INVEST",
    },

    getMyManagerAnbima(managerAnbima) {

        const correspondence = this.dictionary[managerAnbima];
        return correspondence ? correspondence : formatTextForValidDisplay(managerAnbima, 2);

    }

}