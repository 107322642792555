import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//redux
import MyDatePicker from '../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio } from '../../API/client'

//Controllers
import { saveLogResetPortfolio } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
import { formatPortfolioClosed } from '../utils/utils';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    }


}));

export default function ResetPotfolioModal(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { open,
        initDate,
        portfolioClosed,
        clientOnScreen,
        // months,
        // years,
        handleActionResetPortfolio } = props;

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(initDate);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    //Resetando campo portfolio_closed do objeto client
    //Esse campo é responsável por fazer exibir o próximo mês a ser lançado a carteira na coleta de saldos
    async function doResetPortfolioClient(clientId, toDate) {

        console.log("Do reset");
        setLoader(true);
        setValidateMessage("");

        //formatando caso não seja null        
        if (toDate) {
            toDate = moment.utc(toDate).format('MM/YYYY');
            console.log("To date: ", toDate);
        }

        //Verificando se a data de reset é permitida
        //Ela tem que ser maior do que o inicio da carteira e tem que ser menor do que a data da ultima carteira fechada

        //Verificando se é menor do que a ultima carteira fechada

        let statusToSave = "NO";

        console.log('portfolioClosed: ', portfolioClosed);
        console.log('toDate: ', toDate);
        if (portfolioClosed && toDate) {

            let splitted1 = portfolioClosed.split('/');
            let monthClosed = parseInt(splitted1[0]);
            let yearClosed = parseInt(splitted1[1]);

            let splitted2 = toDate.split('/');
            let newMonthClosed = parseInt(splitted2[0]);
            let newYearClosed = parseInt(splitted2[1]);

            // 12 / 2021

            // 9 / 2021
            // newYearClosed = 12
            // yearClosed = 9

            if (newYearClosed < yearClosed) {
                statusToSave = "OK";
            } else if (newYearClosed > yearClosed) {
                statusToSave = "AFTER_DATE";
            } else if (newYearClosed == yearClosed) {

                if (newMonthClosed < monthClosed) {
                    statusToSave = "OK";
                } else {
                    statusToSave = "AFTER_DATE";
                }
            }

            console.log("statusToSave: ", statusToSave);

            //Verificando se é igual ao portfolio_init
            //Caso seja manda-se null
            let formattedInitDate = moment.utc(initDate).format("MM/YYYY");
            let splitted3 = formattedInitDate.split('/');

            let initMonthClosed = splitted3[0];
            let initYearClosed = splitted3[1];

            if (initMonthClosed == newMonthClosed && initYearClosed == newYearClosed) {
                toDate = null;
            }

            if (statusToSave == "OK") {

                if (newYearClosed < initYearClosed) {
                    statusToSave = "BEFORE_DATE"
                } else if (newYearClosed > initYearClosed) {
                    statusToSave = "OK"
                } else if (newYearClosed == initYearClosed) {

                    if (newMonthClosed >= initMonthClosed) {
                        statusToSave = "OK";
                    } else {
                        statusToSave = "BEFORE_DATE";
                    }

                }

            }

        }

        if (!toDate) {
            statusToSave = "OK"
        }

        if (statusToSave == "OK") {

            console.log("RESETANDO PARA: ", toDate);

            //caso comece com 0 como 01/2020, deve-se enviar para o banco apenas 1/2020
            let toDateToSave = toDate != null && toDate.startsWith('0') ? toDate.substring(1) : toDate;
            const dateToPortfolioClosed = toDateToSave;

            if (toDateToSave) {
                let splitted = toDateToSave.split('/');
                let month = splitted[0];
                let year = splitted[1];

                month++;
                if (month == 13) {
                    month = 1;
                    year++;
                }

                //firstDay
                toDateToSave = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');
            }

            let response = await resetPortfolio(clientId, toDateToSave, dateToPortfolioClosed, true);

            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Carteira resetada com sucesso!"

                }));

                if (clientOn.client.id == clientId) {

                    clientOn.client.portfolio_closed = dateToPortfolioClosed;
                    clientOn.client.updateInfosClient = true;
                    let updatedClient = {
                        ...clientOn.client,
                    }

                    console.log("Atualizando cliente: ", updatedClient);
                    dispatch(allActions.clientActions.setClient(updatedClient))

                }

                //Registrando logAction
                let responseLogAction = await saveLogResetPortfolio(currentUser.user.id, clientId, toDateToSave, portfolioClosed);

                history.push('/administration/clients');

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao resetar a carteira!"

                }));
            }
            setLoader(false);
            handleActionResetPortfolio(false)
        } else {
            setLoader(false);
            setValidateMessage("A data deve estar entre o início da carteira e a última carteira fechada");
        }


    }

    useEffect(() => {

        // console.log("Months: ", months);
        // console.log("Years: ", years);


    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => handleActionResetPortfolio(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Reabrir carteira [" + (clientOn.client.portfolio_closed ? formatPortfolioClosed(clientOn.client.portfolio_closed) : '-') + "]"}
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>



                {
                    loader ?
                        <div className={classes.progress}>
                            <CircularProgress
                                disableShrink color="inherit" />
                            <Typography variant={"h6"}>
                                {"Realizando operação..."}
                            </Typography>
                        </div>
                        :
                        <>
                            <Typography style={{ textAlign: 'center', marginBottom: '16px' }}>
                                {'Selecione o mês e o ano para onde deseja voltar na carteira'}
                            </Typography>
                            <MyDatePicker
                                className={classes.myDatePicker}
                                views={["month", "year"]}
                                margin="dense"
                                id="date-picker-dialog"
                                format="MM/YYYY"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                        </>
                }

                {
                    validateMessage != "" ?
                        <Typography variant={"body2"} style={{ color: 'red' }}>
                            {validateMessage}
                        </Typography>
                        :
                        null
                }


            </DialogContent>


            {
                !loader ?
                    <DialogActions className={classes.actionsFlex}>

                        <UnoButton onClick={() =>
                            handleActionResetPortfolio(false)} color="secondary">
                            {'Cancelar'}
                        </UnoButton>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <UnoButton
                                className={classes.spaceRight2}
                                onClick={() => doResetPortfolioClient(clientOnScreen.id, null)}
                                type={'successClean'}>
                                {'Resetar tudo'}
                            </UnoButton>

                            <UnoButton
                                className={classes.spaceRight2}
                                onClick={() => doResetPortfolioClient(clientOnScreen.id, selectedDate)}
                                color="primary">
                                {'Resetar para [' + moment.utc(selectedDate).format('MM/YYYY') + "]"}
                            </UnoButton>
                        </div>

                    </DialogActions>
                    : null

            }




        </Dialog >
    );
}