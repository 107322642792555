import React, { useState, useEffect, } from 'react';
import logo from "./logo.svg";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import SignInSide from "./components/login/SignInSide"
import Dashboard from "./components/main/Dashboard"
import UnoDashboard from './components/main/UnoDashboard';
import { path } from './components/utils/routesPaths';
import ResetPassScreen from './components/login/ResetPassScreen';
import ContactLema from './components/lema/ContactLema';

export default function App() {

  return (

    <Router>
      <div className="App">
        <Switch>
          <Route path="/login">
            <SignInSide />
          </Route>
          <Route path={path.resetPass}>
            <ResetPassScreen />
          </Route>
          <Route path={path.contactLema}>
            <ContactLema />
          </Route>
          <Route path="/">
            {/* <Dashboard /> */}
            <UnoDashboard />
          </Route>
        </Switch>
      </div>
    </Router>

  );
}

