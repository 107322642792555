import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyButton from '../../utils/MyButton'
import MyTextField from '../../utils/MyTextField'
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';

//API
import {
    createNewLegislationArticle,
    listLegislationSegments,
    updateLegislationArticle
} from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import MySelect from '../../utils/MySelect';
import UnoButton from '../../utils/UnoButton';

import CircularProgress from '@material-ui/core/CircularProgress';
import MyInfo from '../../utils/MyInfo';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 0.5px #f4f4f4'
    },

    main: {
        height: 'calc(100% - 96px)', //o header tem 96px
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',
        overflowY: 'scroll',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function DetailAdminLegislationArticle(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [resolutionVersionId, setResolutionVersionId] = useState(2);

    const [validationMessage, setValidationMessage] = useState('');

    const [inputLegislationSegmentArticle, setInputLegislationSegmentArticle] = useState(0);
    const [legislationSegments, setLegislationSegments] = useState([]);
    const [inputAssetArticle, setInputAssetArticle] = useState("");
    const [inputDeviceArticle, setInputDeviceArticle] = useState("");
    const [inputDeviceAbbreviationArticle, setInputDeviceAbbreviationArticle] = useState("");
    const [inputLimitArticle, setInputLimitArticle] = useState("");
    const [inputLimitArt13, setInputLimitArt13] = useState("");
    const [inputLimitArt14, setInputLimitArt14] = useState("");
    const [inputDescriptionArticle, setInputDescriptionArticle] = useState("");

    //Pro gestão
    const [inputPGI, setInputPGI] = useState("");
    const [inputPGII, setInputPGII] = useState("");
    const [inputPGIII, setInputPGIII] = useState("");
    const [inputPGIV, setInputPGIV] = useState("");

    function handleLegislationSegmentArticle(event) {
        console.log(event.target.value)
        setInputLegislationSegmentArticle(event.target.value)
    }

    function handleAssetArticle(value) {
        setInputAssetArticle(value)
    }
    function handleDeviceArticle(value) {
        setInputDeviceArticle(value)
    }
    function handleDeviceAbbreviationArticle(value) {
        setInputDeviceAbbreviationArticle(value)
    }
    function handleLimitArticle(value) {
        setInputLimitArticle(value)
    }
    function handleLimitArt13(value) {
        setInputLimitArt13(value)
    }
    function handleLimitArt14(value) {
        setInputLimitArt14(value)
    }
    function handleDescriptionArticle(value) {
        setInputDescriptionArticle(value)
    }

    const [submittedNewLegislationArticle, setSubmittedNewLegislationArticle] = useState(false);

    async function doUpdateLegislationArticle() {

        setSubmittedNewLegislationArticle(true);

        if (inputLegislationSegmentArticle != undefined
            && inputAssetArticle != ""
            && inputDeviceArticle != ""
            && inputLimitArticle != ""
            && inputDescriptionArticle != "") {


            let newLegislationArticle = {
                legislation_segment_id: parseInt(inputLegislationSegmentArticle) + 1, //+1 pois o id no banco começa com 1 e no select 0
                asset: inputAssetArticle,
                device: inputDeviceArticle,
                device_abbreviation: inputDeviceAbbreviationArticle,
                limit_resolution: parseFloat(inputLimitArticle),
                limit_art13: parseFloat(inputLimitArt13),
                limit_art14: parseFloat(inputLimitArt14),
                description: inputDescriptionArticle,
                limit_pg1: inputPGI,
                limit_pg2: inputPGII,
                limit_pg3: inputPGIII,
                limit_pg4: inputPGIV,

            }

            console.log("ARTICLE TO SAVE: ", newLegislationArticle)

            let response = await updateLegislationArticle(content.data.legislationArticle.id, newLegislationArticle);

            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'Artigo de resolução atualizado com sucesso'
                }))

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao atualizar artigo de resolução'
                }))
            }


            dispatch(allActions.rightContentActions.update())

        }


    }

    async function loadInfos(data) {

        if (data.legislationSegments && data.legislationSegments.length > 0) {

            setLegislationSegments(data.legislationSegments);

            if (data.legislationSegments.length) {
                setResolutionVersionId(data.legislationSegments[0].resolution_version_id);
            }

            //Inicializando o Select com o segmento selecionado
            setInputLegislationSegmentArticle(data.legislationSegmentSelected - 1);
            setInputAssetArticle(data.legislationArticle.asset);
            setInputDeviceArticle(data.legislationArticle.device);
            setInputDeviceAbbreviationArticle(data.legislationArticle.device_abbreviation);
            setInputLimitArticle(data.legislationArticle.limit_resolution);
            setInputLimitArt13(data.legislationArticle.limit_art13);
            setInputLimitArt14(data.legislationArticle.limit_art14);
            setInputDescriptionArticle(data.legislationArticle.description);

            setInputPGI(data.legislationArticle.limit_pg1);
            setInputPGII(data.legislationArticle.limit_pg2);
            setInputPGIII(data.legislationArticle.limit_pg3);
            setInputPGIV(data.legislationArticle.limit_pg4);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os segmentos de legislação'
            }))
            dispatch(allActions.rightContentActions.setRightContent(null))
        }



    }


    useEffect(() => {

        if (content && content.data) {

            console.log("DATA: ", content.data);
            loadInfos(content.data);
        }

    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.header}>
                <IconButton className={classes.hiddenIcon}>
                    <CloseIcon />
                </IconButton>

                <div>
                    <Typography
                        variant={'h6'}
                        align={'left'}>
                        {'Editar Artigo de Resolução'}

                    </Typography>
                </div>


                <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div>
            <div className={classes.main}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputAssetArticle}
                            // defaultValue={inputAssetArticle}
                            onChange={handleAssetArticle}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Tipo de ativo'}
                            type="text"
                            validator={inputAssetArticle == "" && submittedNewLegislationArticle}
                            placeholder="Insira o nome do ativo"
                            helperText="O nome do ativo deve ser preenchido"
                            id="inputAssetArticle"
                            fullWidth />
                    </Grid>

                    <Grid item lg={7} md={7} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDeviceArticle}
                            onChange={handleDeviceArticle}
                            // defaultValue={inputDeviceArticle}
                            // onBlur={handleDeviceArticle}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Dispositivo'}
                            placeholder="Insira o dispositivo"
                            validator={inputDeviceArticle == "" && submittedNewLegislationArticle}
                            helperText="O dispositivo deve ser preenchido"
                            type="text"
                            id="inputDeviceArticle"
                            fullWidth />
                    </Grid>


                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <MySelect
                            outerlabel={'Seg. da legislação'}
                            value={inputLegislationSegmentArticle}
                            onChange={handleLegislationSegmentArticle}
                            options={legislationSegments.map((row, index) => (
                                <option key={'segment-select-' + index} value={index}>{row.description}</option>
                            ))}
                        />
                    </Grid>

                    <Grid item xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDeviceAbbreviationArticle}
                            onChange={handleDeviceAbbreviationArticle}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Abreviação do dispositivo'}
                            type="text"
                            placeholder="Insira a abreviação"
                            helperText="Ex: Art. 7º, I, “a”"
                            fullWidth />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputLimitArticle}
                            onChange={handleLimitArticle}
                            variant="outlined"
                            margin="dense"
                            align='right'
                            outerlabel={`Limite ${content?.data?.resolutionVersionSelected?.resolution_name}`}
                            validator={inputLimitArticle == "" && submittedNewLegislationArticle}
                            helperText="O limite deve ser preenchido"
                            type="text"
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            id="inputLimitArticle"
                            fullWidth />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputLimitArt13}
                            onChange={handleLimitArt13}
                            variant="outlined"
                            margin="dense"
                            align='right'
                            outerlabel={'LIMITE PL DO RPPS'}
                            type="text"
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>

                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputLimitArt14}
                            onChange={handleLimitArt14}
                            variant="outlined"
                            margin="dense"
                            align='right'
                            outerlabel={'LIMITE PL DO FUNDO'}
                            type="text"
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>

                    {/* PRE-GESTAO */}
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGI}
                            value={inputPGI}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG I'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGII}
                            value={inputPGII}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG II'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGIII}
                            value={inputPGIII}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG III'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <MyTextField
                            align={'right'}
                            labelclassname={classes.noMarginTop}
                            onChange={setInputPGIV}
                            value={inputPGIV}
                            variant="outlined"
                            margin="dense"
                            outerlabel={'Lim. PG IV'}
                            type="text"
                            placeholder={'0'}
                            endadornment={<InputAdornment position="end">%</InputAdornment>}
                            mask={['onlyNumbers']}
                            fullWidth />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <MyTextField
                            labelclassname={classes.noMarginTop}
                            value={inputDescriptionArticle}
                            onChange={handleDescriptionArticle}
                            // defaultValue={inputDescriptionArticle}
                            // onBlur={handleDescriptionArticle}
                            variant="outlined"
                            multiline={true}
                            rows={8}
                            rowsMax={8}
                            margin="dense"
                            outerlabel={'Descrição do artigo de resolução'}
                            placeholder="Insira a descrição da resolução"
                            validator={inputDescriptionArticle == "" && submittedNewLegislationArticle}
                            helperText="A descrição deve ser preenchida"
                            type="text"
                            id="inputDescriptionArticle"
                            fullWidth />
                    </Grid>


                </Grid>

                <br />
                <div className={classes.bottom}>


                    {
                        validationMessage != '' ?
                            <Typography className={classes.validationMessage}>
                                {validationMessage}
                            </Typography>
                            : null

                    }


                    <UnoButton
                        type={'success'}
                        float={'right'}
                        onClick={() => doUpdateLegislationArticle()}>
                        {'Atualizar'}
                    </UnoButton>

                </div>


            </div>
            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }
        </div>
    );
}