import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { unoTheme } from "../../../../../../assets/styles/unoTheme";
import { RightDrawerActionEnum, rightDrawerActionTitles } from "../../../../RightContent";
import { Wrapper, CloseButton, GoBackButton, SpaceFiller } from './styles';

export default function Header(props) {
    const { action, labelName } = props;

    const isUpdate = action === RightDrawerActionEnum.Editar;
    const isCreate = action === RightDrawerActionEnum.Cadastrar;
    const currentActionTitle = `${rightDrawerActionTitles[action]} Visualizador`;

    return (
        <Wrapper>
            {isUpdate ? <GoBackButton /> : <SpaceFiller />}

            <Box>
                <Typography
                    variant={'h6'}
                    align='center'>
                    {currentActionTitle}
                </Typography>

                <Typography
                    variant='body1'
                    align='center'
                    style={{ color: unoTheme.defaultTextColor }}>
                    {labelName}
                </Typography>

            </Box>
            {isCreate ? <CloseButton /> : <SpaceFiller />}
        </Wrapper>
    );
}