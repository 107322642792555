import API from './config'

export async function getCitByCityNameAndUf(city_name, uf) {

    try {

        console.log("CHAMANDO CITY NAME: ", city_name)
        console.log("CHAMANDO CITY UF: ", uf)

        const response = await API.post('/citys/getCityId', {
            city_name: city_name.toUpperCase(),
            uf: uf,
        })

        if (response.data
            && response.data.rows
            && response.data.rows.length == 1) {

            return {
                success: true,
                body: response.data.rows[0]
            };
        } else {

            console.log("RESPONSE: ", response);
            return {
                success: false,
                body: "Erro na obtenção do id da cidade"
            };
        }



    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

