import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import PrintIcon from '@material-ui/icons/Print';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../actions';
import { unoTheme } from '../../../../assets/styles/unoTheme';
import { getSimpleNormalizedAssets } from '../../../../controllers/AssetsController';
import {
    applyMaskAmount,
    formatTextForValidDisplay,
    groupByDistributionOptions,
    getMonthName,
    getLastDayMonth
} from "../../../utils/utils";
import MyDistributionTable from './MyDistributionTable';
import { useStyles } from './styles';
import { distributionOptionClassification, isOptionValidToJustTwoWords, order, sortTableRows } from '../../../utils/distribution';
import MyDistributionChart from './MyDistributionChart';

const {
    classe,
    device,
    administrator_anbima,
    benchmark_anbima,
    manager_anbima,
    disponibilization
} = distributionOptionClassification;

const defaultOrder = order.getColumn(order.ASC, 2);

export default function DistribuitionScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [option, setOption] = useState(classe);

    const [listClientAssets, setListClientAssets] = useState([]);
    const [dataToTable, setDataToTable] = useState([]);
    const [dataToChart, setDataToChart] = useState([]);

    const [columnOrder, setColumnOrder] = useState(defaultOrder);
    const [totalLine, setTotalLine] = useState(0);

    const endDayPortfolioPeriodSelected = useMemo(() => {
        if (clientOn?.client?.selectedPortfolioMonth && clientOn?.client?.selectedPortfolioYear) {

            return getLastDayMonth(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear)
        }
        return null;
    }, [clientOn?.client?.selectedPortfolioMonth, clientOn?.client?.selectedPortfolioYear])

    const handleSelect = (event, newOption) => {

        if (newOption && newOption.length) {
            let justTwoWordsValue = isOptionValidToJustTwoWords(newOption);

            handleGroupByDistributionOptions(listClientAssets, newOption, justTwoWordsValue, columnOrder, endDayPortfolioPeriodSelected);
            setOption(newOption);
        }
    };

    /**
     * 
     * @param {Array} assets
     * @param {String} selectedOption {@link distributionOptionClassification}
     * @param {Boolean} justTwoWords
     * @param {String} columnOrder {@link order}
     * @returns 
     */
    function handleGroupByDistributionOptions(assets, selectedOption, justTwoWords, columnOrder, endDayPeriod) {
        let { dataToFormat, dataToChart, dataToTable } = groupByDistributionOptions(assets, selectedOption, justTwoWords, columnOrder, endDayPeriod);
        let { totalAmount, assetClasses } = dataToFormat;

        let indexToColor = 0;
        Object.entries(assetClasses).map(([label, row]) => {
            row.formatedLabel = label;
            if (justTwoWords) row.formatedLabel = formatTextForValidDisplay(label, 2);
            row.formatedAmount = applyMaskAmount(row.amount, true);
            row.legColor = unoTheme.chartColors[indexToColor];

            indexToColor++;

            dataToTable.data.push(row);
        });

        setTotalLine(totalAmount);

        if (dataToTable.data && dataToTable.data.length > 0) {
            let sortedDataToTable = sortTableRows(columnOrder, dataToTable.data);
            dataToTable.data = sortedDataToTable;

            setDataToTable(dataToTable);
        }

        setDataToChart(dataToChart);
    }

    /**
     * 
     * @param {Number} clientId 
     * @param {Number} month 
     * @param {Number} year 
     * @param {Number} accountRegimeId
     */
    async function loadInfosAssets(clientId, month, year, accountRegimeId) {

        // Caso o month e o year sejam undefined (quando ainda não existem movimentações cadastradas)
        // dentro do percusso desse método deve se parar no momento em que trás as contas
        let responseInfosAssets = await getSimpleNormalizedAssets(clientId,
            month,
            year,
            accountRegimeId)

        if (responseInfosAssets.success) {

            // Atualizando componentes da tela                   
            setListClientAssets(responseInfosAssets.assets);

            handleGroupByDistributionOptions(responseInfosAssets.assets, option, false, columnOrder, endDayPortfolioPeriodSelected);

            if (responseInfosAssets.assets.length == 0) {

                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Nenhum ativo com aplicações para o mês de " + getMonthName(month) + "/" + year
                }));

            } else {

                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                    cod: 'distribuition_screen',
                }));

            }

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: responseInfosAssets.message,
            }))
        }
    }

    /**
     * Trigger dispatch a report state based on the given report type.
     * @param {'distribuition'} reportType
     * @returns {void} No return value.
    */
    function doReport(reportType) {
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType,
            message: 'Gerando relatório...'
        }));
    }

    useEffect(() => {
        /**
         * @type {boolean}
         */
        const didClientChange =
            clientOn &&
            clientOn.client &&
            clientOn.client.selectedPortfolioMonth &&
            clientOn.client.selectedPortfolioYear && (!clientOnScreen || clientOn.client.id != clientOnScreen.id);

        /**
         * @type {boolean}
         */
        const didSelectedPortfolioMonthOrYearChange =
            clientOn &&
            clientOn.client &&
            clientOnScreen && (clientOn.client.selectedPortfolioMonth !=
                clientOnScreen.selectedPortfolioMonth ||
                clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear);

        /**
         * @type {boolean}
         */
        const didMonthOrYearChanged = clientOn && clientOn.client && (!clientOn.client.selectedPortfolioMonth || !clientOn.client.selectedPortfolioYear);

        /**
         * @type {boolean}
         */
        const generateReport =
            clientOn &&
            clientOn.client &&
            clientOn.client.doReport &&
            clientOn.client.doReport === "/analysis/distribuition";

        const initComponentFields = () => {
            setClientOnScreen(_.cloneDeep(clientOn.client));
            setListClientAssets([]);
            setDataToTable([]);
            setDataToChart([]);
        };

        if (didClientChange || didSelectedPortfolioMonthOrYearChange || didMonthOrYearChanged) {
            const loadMessage = didMonthOrYearChanged ? 'A distribuição se dá a partir do lançamento da primeira carteira' : 'Carregando informações...';

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                cod: 'distribuition_screen',
                loadMessage,
            }));

            initComponentFields();

            if (!didMonthOrYearChanged) {
                loadInfosAssets(clientOn.client.id,
                    clientOn.client.selectedPortfolioMonth,
                    clientOn.client.selectedPortfolioYear, null);
            }

        } else if (generateReport) {
            doReport('distribuition');
        }

    }, [clientOn, option]);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Distribuição
                </Typography>

                {
                    clientOn
                        && clientOn.client
                        && clientOn.client.portfolio_closed ?
                        <ToggleButtonGroup
                            className={classes.toogleButton}
                            exclusive value={option} onChange={handleSelect} aria-label="text formatting">
                            <ToggleButton value={classe} aria-label="classe">
                                {'Classe'}
                            </ToggleButton>
                            <ToggleButton value={device} aria-label="enquadramento">
                                {'Enquadramento'}
                            </ToggleButton>
                            <ToggleButton value={manager_anbima} aria-label="manager">
                                {'Gestor'}
                            </ToggleButton>
                            <ToggleButton value={administrator_anbima} aria-label="administrator">
                                {'Administração'}
                            </ToggleButton>
                            <ToggleButton value={benchmark_anbima} aria-label="benchmark">
                                {'Benchmark'}
                            </ToggleButton>
                            <ToggleButton value={disponibilization} aria-label="liquidez">
                                {'Liquidez'}
                            </ToggleButton>

                        </ToggleButtonGroup>
                        :
                        null
                }

            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.main}>

                    <MyDistributionTable
                        handleGroupByDistributionOptions={handleGroupByDistributionOptions}
                        endDayPortfolioPeriodSelected={endDayPortfolioPeriodSelected}
                        setColumnOrder={setColumnOrder}
                        columnOrder={columnOrder}
                        selectedDistributionOption={option}
                        dataToTable={dataToTable}
                        listClientAssets={listClientAssets}
                        totalLine={totalLine}
                    />

                    <MyDistributionChart dataToChart={dataToChart} selectedDistributionOption={option} />

                </div>

            </div>

            {
                clientOn
                    && clientOn.client
                    && clientOn.client.portfolio_closed ?
                    <Zoom
                        in={true}
                        unmountOnExit>
                        <Fab className={classes.fabAdd}
                            onClick={() => doReport('distribuition')}
                            color="primary" aria-label="add">
                            <PrintIcon />
                        </Fab>
                    </Zoom> : null
            }

        </div>
    );
}



