import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Controllers
import { formatTargetsToShow, loadClientDiaryByRange } from '../../../controllers/ClientController'

import { unoTheme, headerSearch, tabs } from '../../../assets/styles/unoTheme'

import moment from 'moment'
import { applyMaskAmount, applyMaskAmountFreeDecimals, getFirstBeforeBusinessDay, getLastPtDayMonth, getMonthName, greenOrRed } from "../../utils/utils";
import UnoButton from "../../utils/UnoButton";
import { codsInnerLoad } from "../../utils/MyInnerLoad";
import { MyTab, MyTabs } from "../../utils/MyTabs";
import { path } from "../../utils/routesPaths";
import RiskMarketScreen from "./RiskTabs/RiskMarketScreen";
import RiskArticlesScreen from "./RiskTabs/RiskArticlesScreen";
import RiskBenchmarksScreen from "./RiskTabs/RiskBenchmarksScreen";
import PrintIcon from '@material-ui/icons/Print';
import RiskPolicyScreen from "./RiskTabs/RiskPolicyScreen";
import { reportTypes } from "../../utils/ReportTypes";
import { ClientRiskDataController } from "../../../controllers/ClientRiskDataController";
import { CircularProgress, Typography } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import { RiskController } from "../../../controllers/RiskController";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '100px !important',
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '16px',
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    flexCenterDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default function RiskScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const riskMarketRef = useRef();

    const clientOn = useSelector(state => state.clientOn)
    const [tabValue, setTabValue] = useState(null);

    const [ableToRisk, setAbleToRisk] = useState(false);

    const [marketData, setMarketData] = useState();
    const [articlesData, setArticlesData] = useState();
    const [benchmarksData, setBenchmarksData] = useState();
    const [policyData, setPolicyData] = useState();

    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const handleChangeTabValue = (event, newValue) => {

        console.log('newValue: ', newValue);
        setTabValue(newValue);

        if (newValue == 0) {
            history.push(path.analysis.risk.market)
        } else if (newValue == 1) {
            history.push(path.analysis.risk.articles)
        } else if (newValue == 2) {
            history.push(path.analysis.risk.benchmarks)
        } else {
            history.push(path.analysis.risk.policy)
        }

    };

    async function verifyAndCreateClientRiskData(clientId, month, year) {
        const {
            able,
            data
        } = await ClientRiskDataController.createData(clientId, month, year);

        if (data) {

            const {
                articles_data,
                benchmarks_data,
                market_data,
            } = data;

            if (articles_data && benchmarks_data && market_data) {

                const endDate = getFirstBeforeBusinessDay(getLastPtDayMonth(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear));

                //Obtendo data de política que é obtida via banco 
                //Policy
                const dataPolicy = await RiskController.getPolicyData(clientOn.client, endDate);

                setMarketData(JSON.parse(market_data))
                setArticlesData(JSON.parse(articles_data))
                setBenchmarksData(JSON.parse(benchmarks_data))
                setPolicyData(dataPolicy)
            }
        }

        if (able) {
            setAbleToRisk(true);
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    async function processFullRisk() {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando informações de Mercado... ( 1/4 )'
        }))

        const endDate = getFirstBeforeBusinessDay(getLastPtDayMonth(clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear));

        //Market
        const dataMarket = await RiskController.getMarketData(clientOn.client.id, endDate);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando informações de Enquadramentos... ( 2/4 )'
        }))


        //Articles
        const dataArticles = await RiskController.getArticlesData(clientOn.client.id, endDate);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando informações de Benchmarks... ( 3/4 )'
        }))

        //Benchmarks
        const dataBenchmarks = await RiskController.getBenchmarksData(clientOn.client.id, endDate);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando informações de Política... ( 4/4 )'
        }))

        //Policy
        const dataPolicy = await RiskController.getPolicyData(clientOn.client, endDate);

        setMarketData(dataMarket)
        setArticlesData(dataArticles)
        setBenchmarksData(dataBenchmarks)
        setPolicyData(dataPolicy)

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    function doReport() {

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: reportTypes.risk.cod,
            periodReport: 'mensal',
            message: 'Gerando o relatório de Risco...'
        }))

    }

    function load() {

        if (clientOn
            && clientOn.client
            && clientOn.client.id
            && clientOn.client.selectedPortfolioMonth
            && clientOn.client.selectedPortfolioYear) {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando...',
            }));
            verifyAndCreateClientRiskData(clientOn.client.id, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear);
        }
    }

    useEffect(() => {

        //Caso em que alterou-se o cliente
        if (clientOn && clientOn.client
            && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

            setAbleToRisk(null)
            setMarketData(null)
            setArticlesData(null)
            setBenchmarksData(null)
            setPolicyData(null)
            setTabValue(null)

            load();

            //Caso em que alterou-se o mês ou o ano no controller no dashboard
        } else if (clientOn && clientOn.client && clientOnScreen
            && clientOn.client.id == clientOnScreen.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
            && clientOn.client.portfolio_closed != clientOnScreen.portfolio_closed) {

            setAbleToRisk(null)
            setMarketData(null)
            setArticlesData(null)
            setBenchmarksData(null)
            setPolicyData(null)
            setTabValue(null)

            load();
        }


    }, [clientOn])

    const isReadyToUse = marketData && articlesData && benchmarksData && policyData;

    useEffect(() => {
        if (marketData && articlesData && benchmarksData && policyData && tabValue == null) {
            handleChangeTabValue(null, 0)
        }
    }, [marketData, articlesData, benchmarksData, policyData])

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>

                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="MERCADO" disabled={!isReadyToUse} />
                        <MyTab label="ENQUADRAMENTOS" disabled={!isReadyToUse} />
                        <MyTab label="BENCHMARKS" disabled={!isReadyToUse} />
                        <MyTab label="POLÍTICA DE INVESTIMENTOS" disabled={!isReadyToUse} />

                    </MyTabs>

                </div>

                <div className={classes.rightHeader}>
                    {/* <UnoButton
                        size={'small'}
                        onClick={() => riskMarketRef && riskMarketRef.current ? riskMarketRef.current.excelValidation() : null}
                        type='success'
                        startIcon={<PrintIcon />}>
                        {"Tabela validação"}
                    </UnoButton> */}

                    {
                        isReadyToUse ?
                            <UnoButton
                                size={'small'}
                                type='primary'
                                onClick={() => doReport()}
                                startIcon={<PrintIcon />}>
                                {"Gerar Relatório de Risco"}
                            </UnoButton> : null
                    }



                </div>
            </div>

            {
                ableToRisk ?

                    <>
                        {

                            isReadyToUse ?
                                <Switch className={classes.switch}>
                                    <Route path={path.analysis.risk.market}>
                                        <RiskMarketScreen ref={riskMarketRef} data={marketData} />
                                    </Route>
                                    <Route path={path.analysis.risk.articles}>
                                        <RiskArticlesScreen data={articlesData} />
                                    </Route>
                                    <Route path={path.analysis.risk.benchmarks}>
                                        <RiskBenchmarksScreen data={benchmarksData} />
                                    </Route>
                                    <Route path={path.analysis.risk.policy}>
                                        <RiskPolicyScreen data={policyData} />
                                    </Route>
                                </Switch>

                                : null
                        }

                        {
                            clientOn?.client?.selectedPortfolioMonth
                                && clientOn.client.selectedPortfolioYear
                                && !isReadyToUse ?

                                <div class={'defaultScrollWithHeaderContent'}>

                                    <div className={classes.flexCenterDiv}>

                                        <UnoButton
                                            size={'small'}
                                            type='primary'
                                            onClick={() => processFullRisk()}
                                            startIcon={<LaunchIcon />}>
                                            {`Gerar as Informações de risco [ ${getMonthName(clientOn.client.selectedPortfolioMonth)}/${clientOn.client.selectedPortfolioYear} ]`}
                                        </UnoButton>


                                    </div>
                                </div>
                                :
                                null
                        }

                    </> : null
            }



        </div>
    );
}



