import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

//Table
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../../utils/MyTable';

import { useDispatch, useSelector } from "react-redux";



import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import allActions from "../../../../actions";
import { headerSearch, unoTheme } from "../../../../assets/styles/unoTheme";
import { RiskController } from "../../../../controllers/RiskController";
import { codsInnerLoad } from "../../../utils/MyInnerLoad";
import { getFirstBeforeBusinessDay, getLastPtDayMonth, greenOrRed } from "../../../utils/utils";


const rightDivWidth = 450;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        //boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.common.black,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    headerSearch: headerSearch,

    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableContainer: {
        padding: '0px 18px 16px 18px',
        borderRadius: '10px 0px 0px 10px',
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    clickableHead: {
        cursor: 'pointer',
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },

    leftChart: {
        padding: theme.spacing(2),
        width: '50%',
    },

    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .3s ease',
    },

    sectionTableCellData: {
        width: '30%',
        display: 'table-cell',
        padding: '0px !important',
        //fontSize: '12px',
    },

    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
        fontSize: '12px',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '50%',
        },
        // '& .balanceMoney': {
        //     width: '70%',
        // },
        // '& .balancePercent': {
        //     width: '30%',
        // }
    },

    subHeaderTableRow: {
        background: unoTheme.mainColor + " !important",
        '& .MuiTableCell-root': {
            color: 'white !important',
        }
    }

}));

export default function RiskPolicyScreen(props) {

    const { data } = props;

    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {



    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.mainRoot}>
                <div class={'defaultScrollWithHeaderContent'}>
                    <div>
                        <MyTable
                            size="small"
                            stickyHeader>
                            <colgroup>
                                <col width="20%" />
                                <col />
                                <col width="10%" />
                                <col />
                                <col width="7%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>

                                    <TableCell align="left">Tipo de ativo</TableCell>
                                    <TableCell className={[classes.sectionTableCell].join(' ')} align="center">
                                        <div>{'Carteira Atual'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div className="balanceMoney">{'(R$)'}</div>
                                            <div className="balancePercent">{'(%)'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">Lim. 4.963</TableCell>
                                    <TableCell className={[classes.sectionTableCell].join(' ')} align="center">
                                        <div>{'Estratégia de alocação'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Inferior (%)'}</div>
                                            <div>{'Alvo (%)'}</div>
                                            <div>{'Superior (%)'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">Conf?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((row, index) => {
                                    if (row.segmentName) {
                                        return (
                                            <TableRow key={'policy-risk-' + index} className={row.segmentName ? classes.subHeaderTableRow : null}>
                                                <TableCell align="left">{row.segmentName}</TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left"></TableCell>
                                            </TableRow>
                                        )
                                    } else {
                                        return (
                                            <TableRow key={'policy-risk-' + index} >

                                                <TableCell align="left">{row.asset}</TableCell>
                                                <TableCell align="center" className={[classes.sectionTableCellData].join(' ')}>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div style={{ color: greenOrRed(row.balance) }} className="balanceMoney">{row.balance}</div>
                                                        <div className="balancePercent" style={row.outLowerTarget || row.outTopTarget ? { color: unoTheme.redMoney } : null}>{row.participation}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{row.limitResolution}</TableCell>
                                                <TableCell align="center" className={[classes.sectionTableCellData].join(' ')}>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div style={row.outLowerTarget ? { color: unoTheme.redMoney } : null}>{row.lowerTarget}</div>
                                                        <div>{row.target}</div>
                                                        <div style={row.outTopTarget ? { color: unoTheme.redMoney } : null}>{row.topTarget}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{<FiberManualRecordIcon style={{ color: row.isPolicyAccording ? 'green' : 'red' }} />}</TableCell>

                                            </TableRow>
                                        )
                                    }
                                })}
                            </TableBody>
                        </MyTable>
                    </div>

                </div>


            </div>

        </div >
    );
}



