import moment from 'moment';
import {
    saveTitleLocalQuotas,
} from "../API/title_local_quotas"
import {
    formatPtStringDate,
    formatPtStringDateToAnbimaDate,
    getMonthAgo,
    getDayMonthsAgo
} from '../components/utils/utils'


/**
 * @param {Array} seriesList lista de séries obtidas da API 
 * @param {Array} date data que se refere ao mês que deve ser salva a quotização Ex: 01/01/2021 (Primeiro dia do mês)
 * @returns {obj} objFormatado
 */
export function trySaveTitlesListLocalQuotas(list, date) {

    console.log("TENTANDO SALVANDO SERIES: ", list);
    console.log("DATE: ", date);
    let splittedDate = date.split('/');

    let month = parseInt(splittedDate[1]);
    let year = parseInt(splittedDate[2]);

    console.log("MONTH: ", month);
    console.log("YEAR: ", year);

    list.forEach(element => {
        //console.log("Iniciando save quota: ", element);
        doSaveTitleLocalQuotas(element, date, month, year)
    });

};

/**
 * @param {obj} serie objeto de serie, contém as séries, o código_anbima e o fund_id
 * @param {obj} month mês a ser salvo 
 * @param {obj} year ano a ser salvo 
 * @returns {obj} objFormatado
 */
export async function doSaveTitleLocalQuotas(serie, date, month, year, update) {

    console.log("SERIE: ", serie)
    const titleLocalQuotas = {
        title_id: serie.title_id,
        json_quotas: JSON.stringify(serie.cotas),
        month: month,
        year: year,
        date_quotas: date,
        update: update,
    }

    console.log("OBJ COTAS TO SAVE: ", titleLocalQuotas);

    let response = await saveTitleLocalQuotas(titleLocalQuotas);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
};

export function formatTitleLocalQuotasMonthYear(allQuotas) {

    let formattedQuotas = {
        years: [],
        yearsWithMonths: {},
        data: {},
    };

    allQuotas.forEach(fundQuotas => {

        if (!formattedQuotas.yearsWithMonths[fundQuotas.year]) {

            formattedQuotas.years.push(fundQuotas.year);
            //formattedQuotas[fundQuotas.year] = [];
            formattedQuotas.yearsWithMonths[fundQuotas.year] = [];

        }

        formattedQuotas.yearsWithMonths[fundQuotas.year].push(fundQuotas.month);

        formattedQuotas.data[fundQuotas.month + "/" + fundQuotas.year] = JSON.parse(fundQuotas.json_quotas);
    });

    return formattedQuotas;

}
