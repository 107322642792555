import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Styles
import { makeStyles } from '@material-ui/core/styles';
// Routing
import { useHistory, Route, Switch, useLocation } from 'react-router-dom';
// Components
import ClientList from './ClientList';
// Utils
import { roles } from '../../../utils/user.js';
import { clientScreenAllowedPaths, path } from '../../../utils/routesPaths';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

export default function ClientScreen({
    setBackButton,
    setShowMonths,
    setShowYears,
    locationOnScreen,
    setLocationOnScreen,
    selectedYearClosure
}) {
    const currentUser = useSelector(state => state.currentUser);
    const clientOn = useSelector(state => state.clientOn);
    const history = useHistory();
    const location = useLocation();
    const isCurrentUserRoleManager = currentUser.user && currentUser.user.role === roles.manager.title;

    const DEFAULT_PATH = clientScreenAllowedPaths.admin;

    function getPathByUserRole() {

        let isCurrentPathManager = location.pathname === clientScreenAllowedPaths.manager;
        let isCurrentPathDefault = location.pathname === clientScreenAllowedPaths.component;
        let isValidPath = isCurrentPathManager || isCurrentPathDefault;

        if (isCurrentUserRoleManager && isValidPath) {
            return {
                pathname: clientScreenAllowedPaths.manager,
                state: { client: clientOn.client }
            };
        };
        return DEFAULT_PATH;
    }

    function redirect() {
        let pathToRedirect = currentUser.user ? getPathByUserRole(isCurrentUserRoleManager) : {
            pathname: '/'
        };
        return history.push(pathToRedirect);
    };

    const classes = useStyles();

    useEffect(() => {

        if ((location && !locationOnScreen)
            || (location && location.pathname != locationOnScreen.pathname)) {
            if (location.pathname != path.administration.clients.closures) {

                redirect();
            }
            const isUserFirstTimeOnScreen = location.pathname === path.administration.clients.root;
            if (isUserFirstTimeOnScreen) {
                setShowMonths(false);
                setShowYears(false);
                setBackButton(false);
            };
            setLocationOnScreen(location);
        }
    }, [location]);

    return (
        <div className={classes.root}>

            <Switch>
                <Route path={clientScreenAllowedPaths.admin}>
                    <ClientList selectedYearClosure={selectedYearClosure} />
                </Route>
            </Switch>

        </div>
    );
}


