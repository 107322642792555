import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme } from "../../../assets/styles/unoTheme";

//AutoComplete
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import {
    applyMaskAmount,
    parseFloatByPtCurrency,
    isDateAfterClosedMonth,
    applyMaskAccount, applyMaskAmountFreeDecimals, portfolioClosedToDate, formatPortfolioClosed
} from "../../utils/utils";

import InputAdornment from '@material-ui/core/InputAdornment';

//Api
import {
    getAllTitles,
    createAssetTitle,
    updateAssetTitle,
    deleteAssetTitle,
    updateTradingNoteAssetTitle
} from '../../../API/title'

import {
    getAccountById,
    listClientAccountsByRegime
} from '../../../API/account'

//MyInputs
import MyTextField from "../../utils/MyTextField";
import MySelect from '../../utils/MySelect'
import MyAutoComplete from '../../utils/MyAutoComplete'
import MyDatePicker from '../../utils/MyDatePicker'

import { checkAssetIsLaunched } from '../../../controllers/AssetsController'

import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import moment from 'moment'
import MyInfo from "../../utils/MyInfo";

import ConfirmActionRightContent from './ConfirmActionRightContent'

import { getOptionsMarkingTitle, getOptionsSectorTitle, getOptionsIndexersTitle } from '../../utils/paramsToTitles';
import { isGenericTitle, needQuotasTitle } from "../../../controllers/TitleController";
import { checkUserIsRole, roles } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100vh',
        display: 'flex',
        overflowY: 'scroll',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: '24px',
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px',
            },
        },
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '12px',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    warning: {
        color: unoTheme.warningColor
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));

export default function NewAsset(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { content } = props

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    const [allTitles, setAllTitles] = useState([]);

    //cadastrar ativo    
    const [inputAccountNewAsset, setInputAccountNewAsset] = React.useState(undefined);
    const [inputAssetTitleToCreate, setInputAssetTitleToCreate] = React.useState(undefined);
    const [inputQuantityPurchased, setInputQuantityPurchased] = useState("");

    //atualizar ativos
    const [clientAccounts, setClientAccounts] = useState([]);
    //Para indicar se o ativo já foi lançado alguma vez na carteira do cliente
    //Caso já tenha sido lançado o ativo não pode ser editado
    const [alreadyLaunched, setAlreadyLaunched] = useState(true);
    const [accountToShow, setAccountToShow] = useState(true);

    //Novos campos
    const [titleTypes, setTitleTypes] = useState([]);
    const [optionsMarkings,] = useState(getOptionsMarkingTitle());
    const [inputMarkingTitle, setInputMarkingTitle] = useState(0);
    const [inputPriceTitle, setInputPriceTitle] = useState("");
    const [applicationValue, setApplicationValue] = useState("");
    const [inputPurchasedDateTitle, setInputPurchasedDateTitle] = useState(new Date());
    const [inputPreTaxTitle, setInputPreTaxTitle] = useState("");
    const [inputIndexerPercentTitle, setInputIndexerPercentTitle] = useState("");
    const [inputWithLiquidityTitle, setInputWithLiquidityTitle] = useState(0);
    const [optionsLiquidity,] = useState(['Não', 'Sim']);
    //trandingNote
    const [inputTradingNote, setInputTradingNote] = useState('');
    //Ativos genérivos ex: imóveis
    const [inputAssetNameTitle, setInputAssetNameTitle] = useState('');
    const [inputAssetDescriptionTitle, setInputAssetDescriptionTitle] = useState('');


    //datas do ativo, exibidas em caso de título privados
    const [inputAssetIssueDateTitle, setInputAssetIssueDateTitle] = useState(null);
    const [inputAssetSaleOffDateTitle, setInputAssetSaleOffDateTitle] = useState(null);
    const [inputAssetDueDateTitle, setInputAssetDueDateTitle] = useState(null);


    async function doSaveClientAsset() {

        setValidationMessage('')
        setSubmitted(true);
        setOpenBackdrop(true)

        // console.log(inputAccountNewAsset)
        // console.log(inputAssetTitleToCreate.id)
        // console.log(inputQuantityPurchased != "")
        // console.log(inputPriceTitle != "")
        // console.log(inputMarkingTitle != null)
        // console.log(moment(inputPurchasedDateTitle).isValid());

        if (inputAccountNewAsset !== null
            && inputAssetTitleToCreate.id
            && inputQuantityPurchased != ""
            && inputPriceTitle != ""
            && inputMarkingTitle != null
            && moment(inputPurchasedDateTitle).isValid()
        ) {


            if (!isGenericTitle(inputAssetTitleToCreate)) {
                if (inputAssetTitleToCreate.sector == "PRIVADO") {

                    if ((inputAssetIssueDateTitle != null && !moment(inputAssetIssueDateTitle).isValid())
                        || (inputAssetSaleOffDateTitle != null && !moment(inputAssetSaleOffDateTitle).isValid())
                        || (inputAssetDueDateTitle != null && !moment(inputAssetDueDateTitle).isValid())) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'warning',
                            message: 'Informe datas válidas para o ativo'
                        }));

                        setOpenBackdrop(false)

                        return;
                    }

                }
            }


            //O título deve ser comparado appós a última carteira fechada
            if (clientOn.client.portfolio_closed && moment.utc(inputPurchasedDateTitle).isBefore(portfolioClosedToDate(clientOn.client.portfolio_closed))) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'A data da compra deve ser superior ao fechamento da carteira do cliente > ' + formatPortfolioClosed(clientOn.client.portfolio_closed)
                }));

                setOpenBackdrop(false)
                return;
            }


            //Verificar se a data da compra é anterior ao vencimento do título 
            // console.log('inputAssetTitleToCreate.due_date: ', inputAssetTitleToCreate.due_date);
            // console.log('inputPurchasedDateTitle: ', inputPurchasedDateTitle);
            // console.log('moment is after: ', moment(inputAssetTitleToCreate.due_date).isAfter(inputPurchasedDateTitle));
            // console.log('moment is before: ', moment(inputAssetTitleToCreate.due_date).isBefore(inputPurchasedDateTitle));
            if (moment(inputAssetTitleToCreate.due_date).isBefore(inputPurchasedDateTitle)) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'A data da compra deve ser anterior ao vencimento do título'
                }));
            } else {
                setSubmitted(false);

                let assetTitle = {
                    account_id: clientAccounts[inputAccountNewAsset].id,
                    title_id: inputAssetTitleToCreate.id,
                    quantity_purchased: parseFloatByPtCurrency(inputQuantityPurchased),

                    marking: optionsMarkings[inputMarkingTitle],
                    purchase_date: moment.utc(inputPurchasedDateTitle).format("DD/MM/YYYY"),
                    price_title: parseFloatByPtCurrency(inputPriceTitle),
                    pre_tax: inputPreTaxTitle && inputPreTaxTitle != "" ? parseFloatByPtCurrency(inputPreTaxTitle) : null,
                    indexer_percent: inputIndexerPercentTitle && inputIndexerPercentTitle != "" ? parseFloatByPtCurrency(inputIndexerPercentTitle) : null,
                    with_liquidity: inputWithLiquidityTitle == 0 ? false : true,

                    //datas do ativo
                    asset_title_issue_date: inputAssetIssueDateTitle ? moment.utc(inputAssetIssueDateTitle).format("DD/MM/YYYY") : null,
                    asset_title_sale_off_date: inputAssetSaleOffDateTitle ? moment.utc(inputAssetSaleOffDateTitle).format("DD/MM/YYYY") : null,
                    asset_title_due_date: inputAssetDueDateTitle ? moment.utc(inputAssetDueDateTitle).format("DD/MM/YYYY") : null,

                    //Caso não tenha alterado a conta carteira o asset pode passar direto sem precisar testar se o ativo já existe no banco
                    same_account: clientAccounts[inputAccountNewAsset].id == content.data.selectedAccountId,

                    trading_note: inputTradingNote,

                    asset_name: inputAssetNameTitle,
                    asset_description: inputAssetDescriptionTitle,
                }

                console.log("ASSET TO CREATE: ", assetTitle);

                if (content.update) {

                    //Adicionando ID do asset para ser atualizado
                    assetTitle.id = content.data.id;

                    let response = await updateAssetTitle(assetTitle);
                    if (response.success) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: 'Ativo atualizado com sucesso!'
                        }));

                    } else {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: response.body.message
                        }));
                    }

                } else {

                    let response = await createAssetTitle(assetTitle);
                    if (response.success) {
                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: 'Ativo cadastrado com sucesso!'
                        }));
                    } else {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: response.body.message
                        }));
                    }
                }

                dispatch(allActions.rightContentActions.update())
            }






        }

        setOpenBackdrop(false)


    }

    async function doUpdateTradingNoteAssetTitle() {
        if (content.update) {

            let response = await updateTradingNoteAssetTitle(content.data.id, inputTradingNote);
            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'Nota de negociação atualizada com sucesso!'
                }));

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: response.body.message
                }));
            }

            dispatch(allActions.rightContentActions.update())

        }


    }

    async function doDeleteClientAssetTitle() {

        setValidationMessage('')
        setOpenBackdrop(true)

        //console.log("CONTENT DATA: ", content.data.assetId);
        let response = await deleteAssetTitle(content.data.id);
        if (response.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Ativo de Título excluído com sucesso!'
            }));
        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: response.error.message
            }));
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update())
    }

    async function loadInfosUpdate(data, clientId, portfolioClosed) {

        setInputAssetTitleToCreate({ ...data });
        setInputQuantityPurchased(applyMaskAmount(data.quantity_purchased));
        setInputPriceTitle(applyMaskAmountFreeDecimals(data.price_title, null, 20));
        setInputPurchasedDateTitle(data.purchase_date);
        setInputPreTaxTitle(data.pre_tax ? applyMaskAmountFreeDecimals(data.pre_tax) : '')
        setInputIndexerPercentTitle(data.indexer_percent ? applyMaskAmount(data.indexer_percent) : '')
        setInputWithLiquidityTitle(data.with_liquidity ? 1 : 0)
        const markingIndex = optionsMarkings.findIndex(el => el === data.marking);
        setInputMarkingTitle(markingIndex != -1 ? markingIndex : 0);

        let alreadyLaunchedLocal = checkAssetIsLaunched(data.purchase_date, portfolioClosed);
        setAlreadyLaunched(alreadyLaunchedLocal);

        setApplicationValue(parseFloat(data.price_title) * parseFloat(data.quantity_purchased))

        //datas
        setInputAssetIssueDateTitle(data.asset_title_issue_date);
        setInputAssetSaleOffDateTitle(data.asset_title_sale_off_date);
        setInputAssetDueDateTitle(data.asset_title_due_date);

        setInputTradingNote(data.trading_note);

        //genéricos
        setInputAssetNameTitle(data.asset_name)
        setInputAssetDescriptionTitle(data.asset_description)

        console.log("ALREADY LAUNCHED: ", alreadyLaunchedLocal);
        if (!alreadyLaunchedLocal) {
            const response = await listClientAccountsByRegime(clientId, null);

            console.log("RESPONSE: ", response)

            if (response.success) {

                setClientAccounts(response.body.rows)
                for (let i = 0; i < response.body.rows.length; i++) {

                    if (response.body.rows[i].id == data.account_id) {
                        setInputAccountNewAsset(i);
                        break;
                    }

                }

            } else {

                console.log("error: ", response.error)

            }

            //Caso seja um usuário manager ele pode acessar apenas a conta por extenso 
            if ((currentUser && currentUser.user
                && (currentUser.user.role == "ROLE_MANAGER" && currentUser.user.role == "ROLE_SUBMANAGER"))) {
                const response = await getAccountById(data.account_id);
                if (response.success) {
                    console.log("BODY ACCOUNT: ", response.body)
                    setAccountToShow(response.body)

                } else {
                    console.log("error: ", response.error)
                }
            }

        } else {
            const response = await getAccountById(data.account_id);
            if (response.success) {
                console.log("BODY ACCOUNT: ", response.body)
                //setAccountToShow(response.body.description + ' - Ag: ' + response.body.agency + ' - cc: ' + response.body.number_account)
                setAccountToShow(response.body)

            } else {
                console.log("error: ", response.error)
            }

        }



    }

    function handleChangeAssetDescription(value) {
        if (value.length <= 500) {
            setInputAssetDescriptionTitle(value);
        }
    }

    //deleção do ativo de título
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteClientAssetTitle();
    }

    async function doGetAllTitles() {

        let responseListTitles = await getAllTitles();

        if (responseListTitles.success) {

            console.log("Títulos: ", responseListTitles.body.rows);
            setAllTitles(responseListTitles.body.rows)

            setInputAssetTitleToCreate(responseListTitles.body.rows[0]);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter lista de títulos"
            }))

        }


    }

    function isPublicTitle() {

        if (titleTypes
            &&
            (inputAssetTitleToCreate
                && inputAssetTitleToCreate.sector == "PUBLICO")
            ||
            (content && content.data && content.data.sector == "PUBLICO")) {
            return true;
        } else {
            return false;
        }

    }

    function isGeneric() {

        if (titleTypes
            &&
            inputAssetTitleToCreate) {

            const titleToTest = inputAssetTitleToCreate;

            return isGenericTitle(titleToTest);

        } else {

            return false;
        }
    }

    function isPrivacyTitle() {

        console.log("inputAssetTitleToCreate: ", inputAssetTitleToCreate);

        if (titleTypes && (inputAssetTitleToCreate && inputAssetTitleToCreate.sector == "PRIVADO")
            ||
            (content && content.data && content.data.sector == "PRIVADO")) {
            return true;
        } else {
            return false;
        }

    }

    function isMarkingCurveTitle() {

        if (optionsMarkings[inputMarkingTitle] == "CURVA") {
            return true;
        } else {
            return false;
        }

    }

    useEffect(() => {

        console.log("DATA NEW ASSET: ", content)

        //Caso de update de Asset (Asset só pode ser alterado caso ainda não tenha movimentações cadastrdas para ele)
        if (content
            && content.update
            && clientOn && clientOn.client) {

            //Iniciar seletor de conta com a atual conta selecionada [content.data.selectedAccountId]  
            loadInfosUpdate(content.data, clientOn.client.id, clientOn.client.portfolio_closed);

        } else {

            setClientAccounts(content.data.accounts);
            doGetAllTitles();

        }

    }, [])

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?
                    <>
                        {content && content.update ?

                            null
                            // <div style={{ height: '24px' }}></div>

                            :

                            <div className={classes.header}>

                                <IconButton className={content && content.data && content.update ? "" : classes.hiddenIcon}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent(content.data.dataToBack))}>
                                    <ArrowBackIcon />
                                </IconButton>

                                <div>
                                    <Typography
                                        variant={'h6'}
                                        align={'left'}>
                                        {content && content.data && content.update ? "Editar Ativo de Título" : "Cadastrar Ativo de Título"}

                                    </Typography>

                                </div>


                                <IconButton
                                    className={content && content.data && content.update ? classes.hiddenIcon : ""}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                    <CloseIcon />
                                </IconButton>

                            </div>
                        }

                        <div className={classes.main} style={content && content.data && content.update
                            ? { paddingTop: '24px', height: 'calc(100vh - 148px)' }
                            : { height: 'calc(100vh - 96px)' }}>


                            <Grid container spacing={2}>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {
                                        (allTitles && allTitles.length > 0) ?


                                            <MyAutoComplete
                                                outerlabel={'TíTULO'}
                                                onChange={(event, newValue) => {
                                                    setInputQuantityPurchased('');
                                                    if (isGenericTitle(newValue)) {
                                                        setInputQuantityPurchased('1');
                                                    }
                                                    setInputAssetTitleToCreate(newValue);
                                                }}
                                                //normallayout={'true'}
                                                options={allTitles}
                                                autoComplete
                                                value={inputAssetTitleToCreate ? inputAssetTitleToCreate : null}
                                                //includeInputInList
                                                getOptionLabel={(option) => {
                                                    if (option.sector == 'PRIVADO') {
                                                        return option.title_name;
                                                    } else {
                                                        return option.label_title_type + ' - ' + moment.utc(option.due_date).format('DD/MM/YYYY');
                                                    }

                                                }}
                                                renderOption={(option) => (
                                                    <React.Fragment key={'autocomplete-titles-' + option.id}>
                                                        {
                                                            option.sector == 'PRIVADO' ?
                                                                <span className={classes.textAutoComplete}>{option.title_name}</span>
                                                                :
                                                                <span className={classes.textAutoComplete}>{option.title_name + " - " + option.label_title_type + " - " + moment.utc(option.due_date).format('DD/MM/YYYY')}</span>
                                                        }

                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                                            />
                                            :
                                            <MyInfo
                                                outerlabel={'TÍTULO'}
                                                label={content && content.data ? content.data.title_name : ''}>

                                            </MyInfo>

                                    }
                                </Grid>

                                {
                                    inputAssetTitleToCreate && !isGeneric() && needQuotasTitle(inputAssetTitleToCreate) ?
                                        <>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyInfo
                                                    outerlabel={'Emissão(Título)'}
                                                    label={inputAssetTitleToCreate && inputAssetTitleToCreate.issue_date ? moment.utc(inputAssetTitleToCreate.issue_date).format('DD/MM/YYYY') : '-'}>

                                                </MyInfo>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyInfo
                                                    outerlabel={'Liquidação(Título)'}
                                                    label={inputAssetTitleToCreate && inputAssetTitleToCreate.sale_off_date ? moment.utc(inputAssetTitleToCreate.sale_off_date).format('DD/MM/YYYY') : '-'}>

                                                </MyInfo>
                                            </Grid>

                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyInfo
                                                    outerlabel={'Vencimento(Título)'}
                                                    label={inputAssetTitleToCreate && inputAssetTitleToCreate.due_date ? moment.utc(inputAssetTitleToCreate.due_date).format('DD/MM/YYYY') : '-'}>

                                                </MyInfo>
                                            </Grid>
                                        </> : null
                                }

                                {
                                    isPrivacyTitle() && !isGeneric() ?
                                        <>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyDatePicker
                                                    defaultValue={null}
                                                    className={classes.myDatePicker}
                                                    margin="dense"
                                                    outerlabel="Emissão(Ativo)"
                                                    format="DD/MM/YYYY"
                                                    value={inputAssetIssueDateTitle}
                                                    onChange={(value) => setInputAssetIssueDateTitle(value)}
                                                    validator={inputAssetIssueDateTitle && !moment(inputAssetIssueDateTitle).isValid() && submitted}
                                                    helperText={"Insira uma data válida"}
                                                    KeyboardButtonProps={{ 'aria-label': 'change date', }} />


                                            </Grid>



                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyDatePicker
                                                    defaultValue={null}
                                                    className={classes.myDatePicker}
                                                    margin="dense"
                                                    outerlabel="Liquidação(Ativo)"
                                                    format="DD/MM/YYYY"
                                                    value={inputAssetSaleOffDateTitle}
                                                    onChange={(value) => setInputAssetSaleOffDateTitle(value)}
                                                    validator={inputAssetSaleOffDateTitle && !moment(inputAssetSaleOffDateTitle).isValid() && submitted}
                                                    helperText={"Insira uma data válida"}
                                                    KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                            </Grid>


                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <MyDatePicker
                                                    defaultValue={null}
                                                    className={classes.myDatePicker}
                                                    margin="dense"
                                                    outerlabel="Vencimento(Ativo)"
                                                    format="DD/MM/YYYY"
                                                    value={inputAssetDueDateTitle}
                                                    onChange={(value) => setInputAssetDueDateTitle(value)}
                                                    validator={inputAssetDueDateTitle && !moment(inputAssetDueDateTitle).isValid() && submitted}
                                                    helperText={"Insira uma data válida"}
                                                    KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                            </Grid>
                                        </> : null
                                }





                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    {
                                        !isGeneric() ?
                                            <MySelect
                                                disabled={isPrivacyTitle()}
                                                outerlabel={'Marcação'}
                                                value={inputMarkingTitle}
                                                onChange={(event) => setInputMarkingTitle(event.target.value)}
                                                options={optionsMarkings.map((row, index) => (
                                                    <option key={'kingcation-title-select-' + index} value={index}>{row}</option>
                                                ))}
                                                helperText={"Insira a marcação"}
                                                validator={inputMarkingTitle == undefined && submitted ? true : undefined}
                                            />
                                            :
                                            <MySelect
                                                disabled={true}
                                                outerlabel={'Marcação'}
                                                value={0}
                                                options={
                                                    <option key={'generic-marc-1'}>{'-'}</option>
                                                }
                                            />
                                    }


                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        startadornment={<InputAdornment position="start">R$</InputAdornment>}
                                        size={'small'}
                                        outerlabel="Preço do título"
                                        outerlabelright={'true'}
                                        placeholder="0,00"
                                        value={inputPriceTitle}
                                        helperText={"Insira o artigo"}
                                        validator={inputPriceTitle == "" && submitted ? true : undefined}
                                        variant="outlined"
                                        onChange={(value) => {
                                            if (value && value != ''
                                                && inputQuantityPurchased && inputQuantityPurchased != '') {
                                                setApplicationValue(parseFloatByPtCurrency(value) * parseFloatByPtCurrency(inputQuantityPurchased))
                                            } else {
                                                setApplicationValue('');
                                            }
                                            setInputPriceTitle(value)
                                        }}
                                    //mask={["currency"]} 
                                    />
                                </Grid>

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <MyDatePicker
                                        className={classes.myDatePicker}
                                        margin="dense"
                                        outerlabel="Compra"
                                        format="DD/MM/YYYY"
                                        value={inputPurchasedDateTitle}
                                        onChange={(value) => setInputPurchasedDateTitle(value)}
                                        KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                        validator={!moment(inputPurchasedDateTitle).isValid() && submitted}
                                        helperText={'Data inválida'}
                                    />


                                </Grid>



                                {
                                    content && content.data
                                        && content.update && alreadyLaunched
                                        || (currentUser && currentUser.user
                                            && (currentUser.user.role == "ROLE_MANAGER" && currentUser.user.role == "ROLE_SUBMANAGER")) ?

                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <MyInfo
                                                        outerlabel={'Instituição (CC)'}
                                                        label={accountToShow.description}>

                                                    </MyInfo>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <MyInfo
                                                        align={'right'}
                                                        outerlabel={'Agência'}
                                                        label={accountToShow.agency ? accountToShow.agency : "-"}>

                                                    </MyInfo>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <MyInfo
                                                        align={'right'}
                                                        outerlabel={'CC'}
                                                        label={accountToShow.number_account ? applyMaskAccount(accountToShow.number_account) : null}>

                                                    </MyInfo>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        :

                                        <Grid item lg={8} md={8} sm={12} xs={12} >
                                            <MySelect
                                                outerlabel={'Conta'}
                                                value={inputAccountNewAsset}
                                                initblank={'true'}
                                                validator={inputAccountNewAsset == null && submitted}
                                                helperText="Informe a conta"
                                                onChange={(e) => setInputAccountNewAsset(e.target.value)}
                                                options={clientAccounts.map((row, index) => (
                                                    <option key={'account-new-transaction-' + row.id} value={index}>
                                                        {row.description + (row.agency ? ' - Ag: ' + row.agency : "") + ' - CC: ' + row.number_account}
                                                    </option>
                                                ))}

                                            />
                                        </Grid>
                                }

                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        disabled={isGeneric()}
                                        // startadornment={<InputAdornment position="start">R$</InputAdornment>}
                                        size={'small'}
                                        outerlabel="Qtd. adquirida"
                                        outerlabelright={'true'}
                                        placeholder="0,00"
                                        value={inputQuantityPurchased}
                                        variant="outlined"
                                        validator={inputQuantityPurchased == "" && submitted}
                                        helperText="Um valor deve ser informado"
                                        onChange={(value) => {
                                            if (inputPriceTitle && inputPriceTitle != ''
                                                && value && value != '') {
                                                setApplicationValue(parseFloatByPtCurrency(inputPriceTitle) * parseFloatByPtCurrency(value))
                                            } else {
                                                setApplicationValue('');
                                            }
                                            setInputQuantityPurchased(value)
                                        }}
                                    // mask={["currency"]}
                                    />

                                </Grid>

                                {
                                    !isGeneric() && ((isPublicTitle() &&
                                        isMarkingCurveTitle())
                                        || (!isPublicTitle() && (inputAssetTitleToCreate
                                            && (
                                                inputAssetTitleToCreate.indexer == null
                                                ||
                                                inputAssetTitleToCreate.indexer == 'IPCA'
                                            )))) ?

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyTextField
                                                align={'right'}
                                                size={'small'}
                                                outerlabel="Taxa pré"
                                                placeholder="0,00"
                                                outerlabelright={'true'}
                                                value={inputPreTaxTitle}
                                                onChange={(value) => setInputPreTaxTitle(value)}
                                            // validator={inputPreTaxTitle == "" && submitted}
                                            // helperText="Informe a taxa"
                                            />
                                        </Grid> : null
                                }

                                {
                                    !isGeneric() && (!isPublicTitle() &&
                                        isMarkingCurveTitle() && inputAssetTitleToCreate && (inputAssetTitleToCreate.indexer && inputAssetTitleToCreate.indexer == "CDI"
                                            || (content && content.data && content.data.indexer && content.data.indexer == "CDI"))) ?


                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyTextField
                                                align={'right'}
                                                size={'small'}
                                                outerlabel={"(%) " +
                                                    (
                                                        (inputAssetTitleToCreate && inputAssetTitleToCreate.indexer)
                                                        ||
                                                        (content && content.data && content.data.indexer)
                                                    )
                                                }
                                                placeholder="0,00"
                                                outerlabelright={'true'}
                                                value={inputIndexerPercentTitle}
                                                onChange={(value) => setInputIndexerPercentTitle(value)}
                                            // validator={inputPreTaxTitle == "" && submitted}
                                            // helperText="Informe a taxa"
                                            />
                                        </Grid> : null
                                }

                                {
                                    !isGeneric() && !isPublicTitle() &&
                                        isMarkingCurveTitle() ?

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MySelect
                                                outerlabel={'Liquidez'}
                                                value={inputWithLiquidityTitle}
                                                onChange={(e) => setInputWithLiquidityTitle(e.target.value)}
                                                options={optionsLiquidity.map((row, index) => (
                                                    <option key={'options-liquidity-' + index} value={index}>
                                                        {row}
                                                    </option>
                                                ))}

                                            />
                                        </Grid> : null
                                }



                                {
                                    isGeneric() ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MyTextField
                                                outerlabel={"Nome"}
                                                placeholder="Insira o nome do ativo"
                                                value={inputAssetNameTitle}
                                                onChange={(value) => setInputAssetNameTitle(value)}
                                            />
                                        </Grid> : null

                                }

                                {
                                    isGeneric() ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MyTextField
                                                outerlabel={"Descrição"}
                                                value={inputAssetDescriptionTitle}
                                                multiline={true}
                                                rows={5}
                                                rowsMax={5}
                                                onChange={(value) => handleChangeAssetDescription(value)}
                                            />
                                            <span style={{ float: 'right', fontSize: '12px' }}>Caracteres {inputAssetDescriptionTitle.length}/500</span>
                                        </Grid> : null

                                }

                                <Grid item lg={4} md={4} sm={12} xs={12}>

                                    <div style={{ textAlign: 'right' }}>
                                        <span>Valor aplicação: <h3 style={{ color: unoTheme.greenMoney }}>{applicationValue ? applyMaskAmountFreeDecimals(applicationValue, true) : '-'}</h3></span>
                                    </div>
                                </Grid>~

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <MyTextField
                                        outerlabel={"Nota de negociação"}
                                        placeholder="Insira a nota de negociação"
                                        value={inputTradingNote}
                                        onChange={(value) => setInputTradingNote(value)}
                                    />
                                </Grid>

                                {
                                    checkUserIsRole(currentUser, [roles.admin, roles.advisor, roles.superadmin]) && content.data && content.update ?
                                        <UnoButton
                                            stye
                                            onClick={() => doUpdateTradingNoteAssetTitle()}
                                            type='primary'>
                                            {"Atualizar Nota"}
                                        </UnoButton> : null
                                }

                                {content.data && content.update ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography style={{ color: unoTheme.defaultTextColor }} variant={'body1'}>
                                            {'A edição do ATIVO só é permitida no caso em que ainda não foi fechada nenhuma carteira que o contenha, assim como nenhuma movimentação relacionada a ele'}
                                        </Typography>
                                    </Grid>
                                    : null
                                }
                            </Grid>

                            <br />

                            {
                                currentUser && currentUser.user
                                    && (currentUser.user.role == "ROLE_ADMIN"
                                        || currentUser.user.role == "ROLE_SUPER_ADMIN"
                                        || currentUser.user.role == "ROLE_ADVISOR") ?
                                    <div className={classes.bottom}>

                                        {
                                            validationMessage != '' ?
                                                <Typography className={classes.validationMessage}>
                                                    {validationMessage}
                                                </Typography>
                                                : null

                                        }

                                        {

                                            content.data && content.update ?
                                                <>
                                                    {
                                                        content && content.data && content.update
                                                            //&& !alreadyLaunched
                                                            ?

                                                            <div className={'bottomButtons'}>
                                                                <UnoButton
                                                                    onClick={() => setConfirmingAction(true)}
                                                                    type='errorClean'>
                                                                    {"Excluir ativo"}
                                                                </UnoButton>

                                                                <UnoButton
                                                                    onClick={() => doSaveClientAsset()}
                                                                    type='success'>
                                                                    {"Atualizar Ativo"}
                                                                </UnoButton>
                                                            </div>
                                                            : null

                                                    }

                                                </>
                                                :

                                                <UnoButton
                                                    onClick={() => doSaveClientAsset()}
                                                    type='primary'>
                                                    {"Salvar Ativo"}
                                                </UnoButton>
                                        }


                                    </div>
                                    : null
                            }


                        </div>

                        {
                            openBackdrop ?
                                <div className={classes.rightDivBackdrop}>
                                    <CircularProgress className={'progress'} />
                                </div> : null
                        }
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                        esse ativo?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }

        </div >
    );
}



