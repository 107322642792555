export const hollidays = (year) => {

    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    // AO ALTERAR ESSE ARQUIVO TAMBÉM ALTERAR O ARQUIVO allHollidays
    // DO BACKEND
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////

    return [

        '01/01/' + year, //Confraternização Universal
        '15/02/' + year, //Carnaval
        '16/02/' + year, //Carnaval
        '02/04/' + year, //Paixão de Cristo
        '21/04/' + year, //Tiradentes
        '01/05/' + year, //Dia do Trabalho
        '03/06/' + year, //Corpus Christi
        '07/09/' + year, //Independência do Brasil
        '12/10/' + year, //Nossa Sr.a Aparecida - Padroeira do Brasil
        '02/11/' + year, // Finados
        '15/11/' + year, // Proclamação da República
        '25/12/' + year, // Natal

        //Carnaval 2017
        '28/02/2017',
        '27/02/2017',
        '26/02/2017',
        '25/02/2017',

        //Carnaval 2022
        '28/02/2022',
        '01/03/2022',
        '02/03/2022',

        '30/03/2018', //Paixão de Cristo
        '31/05/2018', //Corpus Christi
        '29/03/2024',//Paixão de Cristo
    ]
}

