import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import AddIcon from '@material-ui/icons/Add';

import UnoButton from "../../utils/UnoButton";

import { getLocalBenchmarks } from '../../../API/local_benchmaks'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

import allActions from "../../../actions";

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    }
}));

export default function LocalBenchmarksScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const rightContent = useSelector(state => state.rightContent)

    const [localBenchmarks, setLocalBenchmarks] = useState([]);

    async function doGetLocalBenchmarks() {

        const response = await getLocalBenchmarks();
        if (response.success) {

            setLocalBenchmarks(response.body.rows);

        } else {

            console.log("RESPONSE ERROR: ", response.error)
            dispatch(allActions.mainAlertActions.setMainAlert({

                show: true,
                type: "error",
                message: "Falha ao listar benchmarks locais"

            }))

        }
    }

    useEffect(() => {

        console.log("User effect")
        doGetLocalBenchmarks();

        if ((rightContent && rightContent.action)) {

            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [rightContent]);

    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(e.target.value.toUpperCase())
        clearTimeout(delayedChange);
    }

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>


                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Benchmarks
                    </Typography>
                </div>

                <div className={classes.rightHeader}>

                    <TextField id="standard-basic"
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Buscar..."
                        variant="outlined"
                        size="small" />

                    <UnoButton
                        style={{ marginLeft: '16px' }}
                        variant="contained"
                        type='primary'
                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                            path: 'newLocalBenchmark'
                        }))}
                        startIcon={<AddIcon />}>
                        {"NOVO BENCHMARK"}
                    </UnoButton>
                </div>
            </div>
            {/* <Divider /> */}
            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    selectable="true"
                    stickyHeader
                    size="small">
                    <colgroup>
                        <col width="15%" />
                        <col width="15%" />
                        <col width="55%" />
                        <col width="15%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh} align="left">Nome</TableCell>
                            <TableCell className={classes.tableTh} align="left">Nome na COMDINHEIRO</TableCell>
                            <TableCell className={classes.tableTh} align="left">Descrição</TableCell>
                            <TableCell className={classes.tableTh} align="center">Data de cadastro</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            localBenchmarks && localBenchmarks.length ? localBenchmarks.map((row) => (
                                <>
                                    {
                                        searchText == "" || (
                                            row.name.includes(searchText)
                                            || row.description.includes(searchText)) ?
                                            <TableRow key={row.id} onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                                path: 'newLocalBenchmark',
                                                update: true,
                                                data: row
                                            }))}>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.comdinheiro_name}</TableCell>
                                                <TableCell align="left">{row.description}</TableCell>
                                                <TableCell align="center">
                                                    {moment.utc(row.created_date).format('DD/MM/YYYY')}
                                                </TableCell>
                                            </TableRow> : null
                                    }
                                </>
                            )) : null
                        }
                    </TableBody>
                </MyTable>
                {/* </div> */}
            </div>
        </div>
    );
}



