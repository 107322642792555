import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

//Api
import { getResolutionVersions, listLegislationSegments, listLegislationArticles, listLegislationSegmentsByVersionId } from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

//UnoButton
import UnoButton from "../../utils/UnoButton";

import MySelect from "../../utils/MySelect";
import { ArticleRulesAPI } from "../../../API/article_rules";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            color: 'white',
            background: unoTheme.mainColor,
            '& > *': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                }

            }
        }
    },
    overflow: {
        overflowY: 'scroll',
    },

    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },

    sectionTableCellData: {
        display: 'table-cell',
        padding: '0px !important',
    },

    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '25%',
        },
        '& .blue': {
            color: 'blue',
        },
    },

}));

export default function AdminArticlesRulesScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const rightContent = useSelector(state => state.rightContent)

    const [resolutionsVersions, setResolutionsVersions] = useState([]);
    const [resolutionVersionSelected, setResolutionVersionSelected] = useState(null);
    const [articlesRules, setArticlesRules] = useState([]);

    function compareSortArticleId(a, b) {

        if (a.id > b.id)
            return 1;
        if (a.id < b.id)
            return -1;
        return 0;
    }

    async function loadRules() {
        const responseArticlesRules = await ArticleRulesAPI.listAll();
        if (responseArticlesRules.success) {
            const rows = responseArticlesRules.body.rows;
            console.log('rows: ', rows);
            const toSorteArray = [...responseArticlesRules.body.rows];
            toSorteArray.forEach(element => {
                if (element.articles?.length)
                    element.articles = [...element.articles.sort(compareSortArticleId)]
            });
            setArticlesRules(responseArticlesRules.body.rows)
        }
    }

    async function getLegislation() {

        let responseResolutionsVersions = await getResolutionVersions();

        if (responseResolutionsVersions.success
            && responseResolutionsVersions.body
            && responseResolutionsVersions.body.rows
            && responseResolutionsVersions.body.rows.length > 0) {

            setResolutionsVersions(responseResolutionsVersions.body.rows);
            setResolutionVersionSelected(1);

            loadRules();

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar versões de resolução'
            }))
        }

    }

    const handleChangeResoltuoinVersionSelected = (value) => {

        setResolutionVersionSelected(value);

    };

    const getArticleLabel = (option) => {

        if (option.device_abbreviation) {
            return option.device_abbreviation + ' / ' + option.asset
        }
        return option.asset
    }

    function renderRulesRows(rules) {

        const nodes = [];

        rules.forEach(row => {

            nodes.push(<TableRow className="divisor-table-2" key={'article-rule-' + row.id}
                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                    path: 'detailAdminArticleRule',
                    update: true,
                    data: {
                        resolutionId: resolutionsVersions[resolutionVersionSelected]?.id,
                        rule: row,
                    }
                }))}>
                <TableCell align="left">
                    {row.name}
                </TableCell>
                <TableCell align="center">
                    {'-'}
                </TableCell>
                <TableCell align="center">
                    <span style={{ color: unoTheme.mainColor }}>{row.limit_rule ? row.limit_rule + '%' : '-'}</span>
                </TableCell>
                <TableCell align="center">{row.limit_pg1 ? row.limit_pg1 + '%' : '-'}</TableCell>
                <TableCell align="center">{row.limit_pg2 ? row.limit_pg2 + '%' : '-'}</TableCell>
                <TableCell align="center">{row.limit_pg3 ? row.limit_pg3 + '%' : '-'}</TableCell>
                <TableCell align="center">{row.limit_pg4 ? row.limit_pg4 + '%' : '-'}</TableCell>
            </TableRow>)

            if (row.articles?.length) {
                row.articles.forEach(article => {
                    nodes.push(<TableRow key={'article-in-rule-' + row.id + '-' + article.id}>
                        <TableCell align="left">
                            {article.asset}
                        </TableCell>
                        <TableCell align="center">
                            {article.device_abbreviation}
                        </TableCell>
                        <TableCell align="center">

                        </TableCell>
                        <TableCell align="center">

                        </TableCell>
                        <TableCell align="center">

                        </TableCell>
                        <TableCell align="center">

                        </TableCell>
                        <TableCell align="center">

                        </TableCell>
                        {/* <TableCell className={classes.sectionTableCellData} align="center">
                            {

                                <div className={classes.multipleHorizontalTableCell}>
                                    <div className={'blue'}>{'-'}</div>
                                    <div className={'blue'}>{'-'}</div>
                                    <div className={'blue'}>{'-'}</div>
                                    <div className={'blue'}>{'-'}</div>
                                </div>

                            }

                        </TableCell> */}
                    </TableRow>)
                });
            }

        });

        return nodes;

    }

    useEffect(() => {


        if (articlesRules.length == 0) {
            getLegislation();
        }

        //No caso de ser uma atualização do rightContent limpa-se o rightContent
        if (rightContent && rightContent.action) {

            loadRules();
            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [rightContent]);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <div className={classes.leftHeaderDetail}>

                    <div className='groupHorizontalFlex'>
                        <Typography variant="h6" gutterBottom align="left">
                            {'Regras de enquadramentos'}
                        </Typography>

                        <MySelect
                            disabled
                            style={{ marginLeft: '16px' }}
                            value={resolutionVersionSelected}
                            verysmall
                            onChange={(e) => handleChangeResoltuoinVersionSelected(e.target.value)}
                            options={resolutionsVersions.map((row, index) => (

                                <option key={'resolution-version-' + index} value={index}>{row.resolution_name}</option>

                            ))}
                        />

                        <div style={{ marginLeft: '16px' }}>

                            <span>{resolutionsVersions[resolutionVersionSelected] ? resolutionsVersions[resolutionVersionSelected].description : ''}</span>

                        </div>



                    </div>
                </div>

                <div className={classes.rightHeader}>

                    <UnoButton
                        size={'small'}
                        startIcon={<AddIcon />}
                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                            path: 'detailAdminArticleRule',
                            data: {
                                resolutionId: resolutionsVersions[resolutionVersionSelected]?.id,
                            },
                        }))}
                        type='primary'>
                        {"Nova regra"}
                    </UnoButton>

                </div>



            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable stickyHeader size="small"
                    selectable="true">

                    <colgroup>
                        <col width="30%" />
                        <col width="12%" />
                        <col width="10%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="12%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                {'Nome da regra'}
                            </TableCell>
                            <TableCell align="center">
                                {'Artigo'}
                            </TableCell>

                            <TableCell align="center">
                                {'Limite inicial'}
                            </TableCell>
                            {/* <TableCell className={classes.sectionTableCell}
                                align="center">
                                <div>{'Limites de Pró-Gestão'}</div>
                                <div className={classes.multipleHorizontalTableCell}>
                                    <div>{'PG I'}</div>
                                    <div>{'PG II'}</div>
                                    <div>{'PG III'}</div>
                                    <div>{'PG IV'}</div>
                                </div>
                            </TableCell> */}
                            <TableCell align="center">
                                {'Nível I'}
                            </TableCell>
                            <TableCell align="center">
                                {'Nível II'}
                            </TableCell>
                            <TableCell align="center">
                                {'Nível III'}
                            </TableCell>
                            <TableCell align="center">
                                {'Nível IV'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articlesRules?.length && renderRulesRows(articlesRules)}
                    </TableBody>
                </MyTable>
            </div>

        </div >
    );
}



