const setFullLoad = (fullLoadObj) => {
    return {
        type: "SET_FULL_LOAD",
        payload: fullLoadObj
    }
}


export default {
    setFullLoad
}