import React, { useEffect } from "react";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',

        //justifyContent: 'center'

    },

    horizontal: {
        flexDirection: 'row !important',
        alignItems: 'center !important',

        '& .label': {
            marginRight: '5px',
            fontWeight: 'bold',
            color: '#1C2228',
            textTransform: 'capitalize',
        },

        '& .info': {
            color: unoTheme.defaultTextColor,
        }


    },
    disabled: {
        background: '#eeeeee'
    },
    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    mainLabel: {
        color: 'black',
    },
    marginTop: {
        marginTop: '5px'
    }
}));

export default function MyInfo(props) {

    const {
        outerlabel,
        label,
        align,
        direction, //horizontal ou vertical
        colorInfo
    } = props

    const classes = useStyles();

    useEffect(() => {
    }, [colorInfo])

    return (
        <div className={[classes.root, classes[direction], 'myInfo'].join(' ')}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        align={align}
                        className={[classes.label, 'label'].join(' ')}>
                        {outerlabel}
                    </Typography> : null
            }

            <Typography
                align={align}
                className={[classes.mainLabel, 'info'].join(' ')}
                style={colorInfo ? { color: colorInfo.toString() } : null}>
                {label}
            </Typography>

        </div>
    )
}