const innerLoad = (state = {
    show: false,
    message: "",
}, action) => {

    //console.log("INNER LOAD ACTION: ", action.type)
    switch (action.type) {
        case "SET_INNER_LOAD":
            return action.payload
        default:
            return state
    }
}

export default innerLoad;