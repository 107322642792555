import moment from 'moment';
import {
    saveCDIMonth, getCDIMonth
} from "../API/cdi_local"
import { getMonthAndYearBefore, getDayMonthYearByStringDate } from '../components/utils/utils';
import { getIGPMMonth, saveIGPMMonth } from '../API/igpm_local';

export async function doGetIGPMMonth(month, year) {

    let response = await getIGPMMonth(month, year);

    if (response.success && response.body && response.body.rows.length === 1) {
        console.log("RESPONSE GET IGPM: ", response.body);
        return {
            success: true,
            body: JSON.parse(response.body.rows[0].json_igpm)
        }
    } else {
        return {
            success: false,
        }
    }
};

/**
 * @param {obj} serie contem a lista de IGPM do mês
 * @returns {obj} objFormatado
 */
export async function doSaveIGPMMonth(serie, date) {

    const { month, year } = getDayMonthYearByStringDate(date);

    const igpmMonth = {
        json_igpm: JSON.stringify(serie),
        month: month,
        year: year,
        date_igpm: date,
    }

    let response = await saveIGPMMonth(igpmMonth);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
};
