import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import MyTable from '../../../utils/MyTable';

import _, { isEmpty } from 'lodash';

//Api
import { getFundsInfosByFundIds, getSettledFundsIdsByFundsIdsAndReferenceDate } from '../../../../API/fund';

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../actions';

import InfoIcon from '@material-ui/icons/Info';
import MoreIcon from '@material-ui/icons/MoreVert';

import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from '@material-ui/core';
import moment from "moment";
import { testEndpoint } from '../../../../API/comdinheiro-api';
import { getLocalInfosByFundIdMonthYear } from '../../../../API/fund_local_quotas';
import { headerSearch, tabs, unoTheme } from '../../../../assets/styles/unoTheme';
import { doGetFullFundsClientByPeriodRent, formatMyFundsToShow } from "../../../../controllers/FundController";
import { rentsByPeriod } from "../../../../controllers/ReportController";
import InfosFundModal from '../../../modals/InfosFundModal';
import { codsInnerLoad } from "../../../utils/MyInnerLoad";
import {
    applyMaskAmount,
    getConsultingId,
    getDayMonthYearByDate,
    getFirstDayMonth,
    getLastDayInMonthByMonthAndYear
} from "../../../utils/utils";

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #000000"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #000000",
        color: "#000000"
    }
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.redMoney,
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '20%',
        }
    },
    toogleButton: {
        maxHeight: '40px',
    },

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },

    iconExtraPolicys: {

        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            verticalAlign: 'middle',
        }

    },

    tabs: tabs,

    sectionTableCellData: {
        display: 'table-cell',
        padding: '0px !important',
    },

    infoIcon: {
        display: 'flex',
        fontSize: '18px',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    }


}));

export default function FundsClientScreen({ segmentId, search }) {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser);

    const dispatch = useDispatch();

    const [funds, setFunds] = useState([]);
    const [fundsFormatteds, setFundsFormatteds] = useState([]);

    const [filter, setFilter] = useState(1);

    const [clientOnScreen, setClientOnScreen] = useState("");

    const [settledFundsIds, setSettledFundsIds] = useState([]);

    async function getClientFundsOld(client, month, year, segmentId) {

        let responseFunds = {};
        // if (isEmpty(search)) {
        //     //responseFunds = await getAllClientFunds(clientId)
        //     responseFunds = await doGetFundsClientByPeriodRent(clientId, month, year)
        // } else {
        //     responseFunds = await getClientFundsBySearch(search, clientId);
        // }

        console.log("SEGMENT ID: ", segmentId);
        responseFunds = await doGetFullFundsClientByPeriodRent(client, month, year, segmentId, search)
        //responseFunds = await doGetFundsClientByPeriodRent(clientId, month, year, search)

        const startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');
        const endDate = getLastDayInMonthByMonthAndYear(month, year);
        console.log('startDate: ', startDate);
        console.log('endDate: ', endDate);
        const responseRents = await rentsByPeriod(client.id, startDate, endDate);
        console.log('responseRents: ', responseRents);

        if (responseFunds.success) {

            //const responseFundsData = responseFunds.body.rows;
            const responseFundsData = responseFunds.data;
            console.log('responseFundsData: ', responseFundsData);

            if (isEmpty(responseFundsData)) {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: 'Não existem fundos',
                    cod: codsInnerLoad.fundsClientScreen,
                }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false, cod: codsInnerLoad.fundsClientScreen }));
            };

            //await formatRentsFundsByRange(responseFundsData, client.id);

            //Normalizando cotas do cliente
            // responseFundsData.forEach(fund => {
            //     if (fund.rentsFunds) {
            //         fund.rentsFunds.forEach(periodRent => {
            //             if (periodRent.quotas_anbima_before && periodRent.quotas_anbima_before.length)
            //                 periodRent.quotas_anbima_before = getClientJsonQuotas(periodRent.quotas_anbima_before, clientOn.client.id);
            //             if (periodRent.quotas_anbima_now && periodRent.quotas_anbima_now.length)
            //                 periodRent.quotas_anbima_now = getClientJsonQuotas(periodRent.quotas_anbima_now, clientOn.client.id);
            //         });
            //     }

            // });


            setFunds(responseFundsData);
        } else {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
                cod: codsInnerLoad.fundsClientScreen,
            }));

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao trazer fundos do cliente",
                type: 'error',
            }))
        }
    }

    async function getClientFunds(client, month, year, segmentId, search) {

        const currentPeriod = month + '/' + year;
        const startDate = moment.utc(client.portfolio_init).format('DD/MM/YYYY');
        const { month: initMonth, year: initYear } = getDayMonthYearByDate(moment.utc(client.portfolio_init));
        const endDate = getLastDayInMonthByMonthAndYear(month, year);
        // console.log('startDate: ', startDate);
        // console.log('endDate: ', endDate);
        // console.log("SEGMENT ID getClientFunds: ", segmentId);
        const responseRents = await rentsByPeriod(client.id, startDate, endDate, search, segmentId);

        // console.log('responseRents: ', responseRents);

        if (responseRents.integralFundsBalancesMap) {

            if (Object.keys(responseRents.integralFundsBalancesMap).length) {

                const fundsIds = Object.keys(responseRents.integralFundsBalancesMap);

                const responseSettledFundsIds = await getSettledFundsIdsByFundsIdsAndReferenceDate(fundsIds, getFirstDayMonth(month, year));
                if (responseSettledFundsIds?.success) {

                    const setteldsIds = responseSettledFundsIds.body.rows.map(el => el.id + "");
                    console.log("setteldsIds: ", setteldsIds);
                    setSettledFundsIds(setteldsIds);
                }

                const responseFundsInfos = await getFundsInfosByFundIds(fundsIds);

                if (responseFundsInfos.success) {
                    //transformar em map
                    const mapFundsInfos = {};
                    responseFundsInfos.body.rows.forEach(el => {
                        mapFundsInfos[el.id] = el;
                    })

                    console.log('responseFundsInfos: ', responseFundsInfos);

                    if (isEmpty(responseRents.integralFundsBalancesMap)) {
                        dispatch(allActions.innerLoadActions.setInnerLoad({
                            show: true,
                            emptyMessage: 'Não existem fundos',
                            cod: codsInnerLoad.fundsClientScreen,
                        }));
                    } else {
                        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false, cod: codsInnerLoad.fundsClientScreen }));
                    };

                    setFundsFormatteds(formatMyFundsToShow(responseRents.integralFundsBalancesMap, currentPeriod, mapFundsInfos));

                } else {
                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: false,
                        cod: codsInnerLoad.fundsClientScreen,
                    }));

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: "Falha ao trazer fundos do cliente",
                        type: 'error',
                    }))
                }
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: 'Não existem fundos',
                    cod: codsInnerLoad.fundsClientScreen,
                }));
            }



        } else {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
                cod: codsInnerLoad.fundsClientScreen,
            }));

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao trazer fundos do cliente",
                type: 'error',
            }))
        }
    }

    function bladeFund(row) {
        console.log("FUND ROW: ", _.cloneDeep(row))
        history.push(
            {
                pathname: "/blade/all",
                state: { fund: row }
            }
        )
    };

    useEffect(() => {
        //Deve fazer a recarga apenas no caso de ter mudado o client, o ano selecionado ou a tab                
        const hasSearch = search || isEmpty(search) && clientOnScreen.id;
        if (
            (clientOn
                &&
                clientOn.client
                &&
                clientOn.client.id != clientOnScreen.id
            )
            || (clientOn && clientOn.client && clientOn.client.id && hasSearch)
        ) {

            if (segmentId != null && segmentId != undefined) {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    cod: codsInnerLoad.fundsClientScreen,
                    loadMessage: 'Carregando fundos...',
                }));

                setClientOnScreen(_.cloneDeep(clientOn.client))

                if (clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {
                    console.log("CHAMOU GET CLIENT FUNDS");
                    setFundsFormatteds(undefined);
                    getClientFunds(clientOn.client, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear, segmentId, search);
                } else {
                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: true,
                        emptyMessage: 'Não existem fundos',
                        cod: codsInnerLoad.fundsClientScreen,
                    }));
                }
            }

        }

    }, [
        clientOn,
        location,
        segmentId,
        search
    ]);

    const selectable = useMemo(() => currentUser?.user?.isViewer ? undefined : "true", [currentUser]);

    //MENU
    const [selectedFundMenu, setSelectedFundMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const openOptionsFundMenu = (e, fundRow) => {
        e.stopPropagation();
        console.log("openOptionsFundMenu: ", fundRow);
        setAnchorEl(e.currentTarget);
        setSelectedFundMenu(fundRow);
    }

    const handleCloseOptionsMenuFund = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setSelectedFundMenu(undefined);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setSelectedFundMenu(undefined);
        }
    }

    const handleBladeFund = (fund) => {
        if (!fund || currentUser?.user?.isViewer) return;
        bladeFund(fund);
    }

    const handleHistoricQuotasFund = (fund) => {
        if (!fund || currentUser?.user?.isViewer) return;
        dispatch(allActions.rightContentActions.setRightContent({
            path: 'fundLocalQuotas',
            data: fund,
        }))
    }

    const [openFundInfos, setOpenFundInfos] = useState();
    const [openFundFullCompositionModal, setOpenFundFullCompositionModal] = useState();

    const [fundInfos, setFundInfos] = useState();

    const handleInfosFund = async (fund, isFull) => {
        if (!fund || currentUser?.user?.isViewer) return;
        const { selectedMonth, selectedYear } = clientOn.client;
        const responseInfosLocais = await getLocalInfosByFundIdMonthYear(fund.fundId, selectedMonth, selectedYear);
        if (responseInfosLocais.success && responseInfosLocais.body?.length === 1) {
            const { json_fund_infos } = responseInfosLocais.body[0];
            setFundInfos({
                ...selectedFundMenu,
                fund_id: parseInt(selectedFundMenu.fundId),
                ...JSON.parse(json_fund_infos)
            });
            isFull ? setOpenFundFullCompositionModal(true) : setOpenFundInfos(true);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "warning",
                message: "Falha ao obter informações locais do fundo."
            }))
        }
    }

    //const [openFundFullCompositionModal, setOpenFundFullCompositionModal] = useState();
    // const handleOpenPortfolioFund = async (fund) => {
    //     if (!fund || currentUser?.user?.isViewer) return;
    //     const { selectedMonth, selectedYear } = clientOn.client;
    //     const responseInfosLocais = await getLocalInfosByFundIdMonthYear(fund.fundId, selectedMonth, selectedYear);
    //     if (responseInfosLocais.success && responseInfosLocais.body?.length === 1) {
    //         const { json_fund_infos } = responseInfosLocais.body[0];
    //         setFundInfos({
    //             ...selectedFundMenu,
    //             fund_id: parseInt(selectedFundMenu.fundId),
    //             ...JSON.parse(json_fund_infos)
    //         });
    //         setOpenFundFullCompositionModal(true);
    //     } else {
    //         dispatch(allActions.mainAlertActions.setMainAlert({
    //             show: true,
    //             type: "warning",
    //             message: "Falha ao obter informações locais do fundo."
    //         }))
    //     }

    //     //const response = await doGetOpenPortfolioFund(fund.cnpj);
    // }

    const handleTest = async () => {
        const response = await testEndpoint();
    }

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.tableSideGraph}>
                    <MyTable stickyHeader
                        size="small">
                        <colgroup>
                            <col width="33%" />
                            <col width="25%" />
                            <col width="35%" />
                            <col width="7%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Fundo</TableCell>
                                <TableCell align="center">CNPJ</TableCell>
                                <TableCell className={classes.sectionTableCell}
                                    align="center">
                                    <div>{'Rentabilidade'}</div>
                                    <div className={classes.multipleHorizontalTableCell}>
                                        <div>{'Mês (%)'}</div>
                                        <div>{'Ano (%)'}</div>
                                        <div>{'12m (%)'}</div>
                                        <div>{'24m (%)'}</div>
                                        <div>{'Início (%)'}</div>
                                    </div>
                                </TableCell>
                                <TableCell align="center">Ações</TableCell>
                            </TableRow>
                        </TableHead>

                        {
                            clientOn && clientOn.client ?
                                <TableBody>
                                    {fundsFormatteds && fundsFormatteds.filter(el => !settledFundsIds.includes(el.fundId)).map((row, index) => (

                                        <TableRow
                                            key={'client-fund-' + index}
                                        // onClick={() => currentUser?.user?.isViewer ? undefined : bladeFund(row)}
                                        >
                                            <TableCell align="left">{row.fund_name}</TableCell>
                                            <TableCell align="center">{row.cnpj}</TableCell>
                                            <TableCell align="center"
                                                className={classes.sectionTableCellData}>
                                                <div className={classes.multipleHorizontalTableCell}>
                                                    <div>{
                                                        row.rentMonth ? applyMaskAmount(row.rentMonth, null, true) + "%" : '-'
                                                    }</div>
                                                    <div>{
                                                        row.rentYear ? applyMaskAmount(row.rentYear, null, true) + "%" : '-'
                                                    }</div>
                                                    <div>{
                                                        row.rent12m ?
                                                            applyMaskAmount(row.rent12m, null, true) + "%"
                                                            :
                                                            <LightTooltip title={'O fundo ainda não possui 12 meses na carteira'} placement="right" arrow>
                                                                <InfoIcon className={classes.infoIcon} />
                                                            </LightTooltip>
                                                    }</div>
                                                    <div>{row.rent24m != null ?
                                                        applyMaskAmount(row.rent24m, null, true) + "%"
                                                        :
                                                        <LightTooltip title={'O fundo ainda não possui 24 meses na carteira'} placement="right" arrow>
                                                            <InfoIcon className={classes.infoIcon} />
                                                        </LightTooltip>
                                                    }</div>

                                                    <div>{row.rentHistoric != null ? applyMaskAmount(row.rentHistoric, null, true) + "%" : '-'}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" className="withIcons">{
                                                <IconButton onClick={(e) => openOptionsFundMenu(e, row)}>
                                                    <MoreIcon />
                                                </IconButton>
                                            }</TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                                : null
                        }
                    </MyTable>

                    <Popper
                        open={selectedFundMenu}
                        anchorEl={anchorEl}
                        transition
                        disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseOptionsMenuFund}>
                                        {selectedFundMenu ?


                                            <MenuList autoFocusItem={selectedFundMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                <MenuItem
                                                    key={'menu-options-fund-blade'}
                                                    onClick={() => handleBladeFund(selectedFundMenu)}>
                                                    <Typography >{"Lâmina do fundo"}</Typography>

                                                </MenuItem>
                                                <MenuItem
                                                    key={'menu-options-fund-quotas-historic'}
                                                    onClick={() => handleHistoricQuotasFund(selectedFundMenu)}>
                                                    <Typography>{"Histórico de cotas"}</Typography>

                                                </MenuItem>
                                                {/* <MenuItem
                                                    key={'menu-test'}
                                                    onClick={() => handleTest()}>
                                                    <Typography>{"Teste"}</Typography>

                                                </MenuItem> */}
                                                {
                                                    getConsultingId(currentUser) === 1 ?
                                                        <MenuItem
                                                            key={'menu-options-fund-open-portfolio'}
                                                            onClick={() => handleInfosFund(selectedFundMenu)}>
                                                            <Typography>{"Carteira do fundo"}</Typography>

                                                        </MenuItem>
                                                        : null
                                                }

                                                {
                                                    getConsultingId(currentUser) === 1 ?
                                                        <MenuItem
                                                            //disabled
                                                            key={'menu-options-fund-open-portfolio-2'}
                                                            onClick={() => handleInfosFund(selectedFundMenu, true)}>
                                                            <Typography>{"Abrir carteira"}</Typography>

                                                        </MenuItem>
                                                        : null
                                                }
                                            </MenuList>
                                            : <div></div>
                                        }
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </div>

            </div>

            <InfosFundModal
                isFull={openFundFullCompositionModal ? true : false}
                open={openFundInfos || openFundFullCompositionModal}
                fund={fundInfos ? fundInfos : null}
                handleCloseInfosFundModal={() => {
                    setOpenFundInfos(false);
                    setOpenFundFullCompositionModal(false);
                }}>

            </InfosFundModal>

        </div>
    );
}



