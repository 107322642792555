import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//redux

import { useDispatch } from "react-redux";

//API

//Controllers

import UnoButton from '../utils/UnoButton';


import { myLabelInput, unoTheme } from '../../assets/styles/unoTheme';

import BBLogo from '../../assets/imgs/leitorPdf/bb_min_logo.png';
import CaixaLogo from '../../assets/imgs/leitorPdf/caixa_min_logo.png';
import BNBLogo from '../../assets/imgs/leitorPdf/bnb_min_logo.png';
import BradescoLogo from '../../assets/imgs/leitorPdf/bradesco_min_logo.png';
import SantanderLogo from '../../assets/imgs/leitorPdf/santander_min_logo.png';
import ItauLogo from '../../assets/imgs/leitorPdf/itau_min_logo.png';
import BanestesLogo from '../../assets/imgs/leitorPdf/banestes_min_logo.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    instituitions: {

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",



        "& .instituitionToSelect": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            padding: "8px 2px",
            flexDirection: "column",

            border: "solid 2px white",
            transition: 'all .3s ease',
            borderRadius: "5px",
            width: "100px",
            height: "100px",

            cursor: "pointer",

            '&:hover': {
                backgroundColor: unoTheme.secondaryTuplaTable,
            },
            '&.selected': {
                border: `solid 3px ${unoTheme.mainColor}`,
            },
        }
    },

}));

export const INSTITUITIONS = {
    BB: 1,
    BNB: 2,
    ITAU: 3,
    CAIXA: 4,
    BRADESCO: 5,
    SANTANDER: 6,
    BANESTES: 7,
}
export const INSTITUITIONS_IDS = {
    [INSTITUITIONS.BB]: 1,
    [INSTITUITIONS.CAIXA]: 31,
    [INSTITUITIONS.BNB]: 4,
    [INSTITUITIONS.BRADESCO]: 76,
    [INSTITUITIONS.SANTANDER]: 19,
    [INSTITUITIONS.ITAU]: 114,
    [INSTITUITIONS.BANESTES]: 8,
}
export const INSTITUITIONS_NAMES = {
    [INSTITUITIONS.BB]: "BANCO DO BRASIL",
    [INSTITUITIONS.CAIXA]: "CAIXA ECONÔMICA FEDERAL",
    [INSTITUITIONS.BNB]: "BANCO DO NORDESTE",
    [INSTITUITIONS.BRADESCO]: "BANCO BRADESCO",
    [INSTITUITIONS.SANTANDER]: "BANCO SANTANDER",
    [INSTITUITIONS.ITAU]: "BANCO ITAÚ",
    [INSTITUITIONS.BANESTES]: "BANCO DO ESTADO DO ESPIRITO SANTO S.A",
}

export default function SelectInstituitionModal(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { open,
        handleSelectedInstituition, handleClose } = props;

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    const [selectedInstituition, setSelectedInstituition] = useState(undefined);


    useEffect(() => {

        if (open) {
            setSelectedInstituition(undefined);
        }


    }, [open])

    return (
        <Dialog
            // fullWidth={true}
            // maxWidth={'sm'}
            open={open}
            onClose={() => handleClose()}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Selecione a instituição"}
            </DialogTitle>

            <DialogContent>

                <div className={classes.instituitions}>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.BB ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.BB)}>

                        <img className={[classes.imgInstituition].join(" ")} src={BBLogo} width={50} height={50} />
                        <Typography>BB</Typography>
                    </div>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.CAIXA ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.CAIXA)}>

                        <img className={[classes.imgInstituition].join(" ")} src={CaixaLogo} width={50} height={50} />
                        <Typography>CAIXA</Typography>
                    </div>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.BNB ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.BNB)}>

                        <img className={[classes.imgInstituition].join(" ")} src={BNBLogo} width={50} height={50} />
                        <Typography>BNB</Typography>
                    </div>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.BRADESCO ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.BRADESCO)}>

                        <img className={[classes.imgInstituition].join(" ")} src={BradescoLogo} width={50} height={50} />
                        <Typography>BRADESCO</Typography>
                    </div>
                </div>
                <div className={classes.instituitions}>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.SANTANDER ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.SANTANDER)}>

                        <img className={[classes.imgInstituition].join(" ")} src={SantanderLogo} width={50} height={50} />
                        <Typography>SANTANDER</Typography>
                    </div>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.ITAU ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.ITAU)}>

                        <img className={[classes.imgInstituition].join(" ")} src={ItauLogo} width={50} height={50} />
                        <Typography>ITAÚ</Typography>
                    </div>
                    <div className={`instituitionToSelect ${selectedInstituition === INSTITUITIONS.BANESTES ? "selected" : ""}`}
                        onClick={() => setSelectedInstituition(INSTITUITIONS.BANESTES)}>

                        <img className={[classes.imgInstituition].join(" ")} src={BanestesLogo} width={50} height={50} />
                        <Typography>BANESTES</Typography>
                    </div>
                </div>


            </DialogContent>

            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() =>
                    handleClose()} color="secondary">
                    {'Cancelar'}
                </UnoButton>

                <UnoButton
                    disabled={!selectedInstituition}
                    className={classes.spaceRight2}
                    onClick={() => handleSelectedInstituition(selectedInstituition)}
                    color="primary">
                    {"Iniciar leitura"}
                </UnoButton>

            </DialogActions>

        </Dialog>
    );
}