import API from './config'

export const AssetsAPI = {

    async getAllAssetsClient(clientId, fundId, regimeId, accountId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/assets/getAllAssetsClient?client_id=${clientId}&fund_id=${fundId}&regime_id=${regimeId}&account_id=${accountId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },
}