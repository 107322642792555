import { IconButton, makeStyles, TableCell } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import React from 'react';
import { createNewObjectByVariables, getFilteredObject, getVariableName } from './utils';

const useStyles = makeStyles({
  green: {
    color: 'green'
  },
  red: {
    color: 'red',
  },
  iconButton: {
    padding: '5px',
  },
});

export default function SituationCell(props) {
  const { align, data, updateStatus } = props;
  const selectedProps = [align, data, updateStatus];
  const selectedPropsKeys = [getVariableName(align), getVariableName(data), getVariableName(updateStatus)];
  const formatedSelectedProps = createNewObjectByVariables(selectedPropsKeys, selectedProps);
  const availableProps = getFilteredObject(props, formatedSelectedProps);

  async function handleStatus(e, id, active) {
    e.stopPropagation();

    return await updateStatus(e, id, active);
  }

  const classes = useStyles();
  return (
    <TableCell align={align}>
      <IconButton
        size={'small'}
        className={classes.iconButton}
        onClick={(e) => handleStatus(e, data.id, !data.active)}
        {...availableProps}
      >
        {
          data.active ?
            <CheckCircleOutlineRoundedIcon className={classes.green} />
            :
            <HighlightOffRoundedIcon className={classes.red} />
        }
      </IconButton>
    </TableCell>
  )
}