import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { BarChart } from './Charts';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

//table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//Charts
import { PieChart } from "../utils/Charts";

import html2canvas from 'html2canvas'
import jsPdf from 'jspdf'

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    dialogContent: {
        display: 'flex',
        background: "#eeeeee",
        alignItems: 'center',
        flexDirection: 'column',
    },
    page: {
        width: '877px',
        height: '620px',
        backgroundColor: 'white',
    }
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function ModalToPdf({ open, handleModal }) {

    const classes = useStyles();

    const [scroll, setScroll] = React.useState('body');

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {

        console.log("OPEN PARAM: ", open);
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const [elevationPages, setElevationPages] = React.useState(3);

    function handleelevationPages(e) {
        setElevationPages(e)
    }

    function printPDF() {
        const domElement = document.getElementById('page')
        html2canvas(domElement, {
            onclone: (document) => {
                //document.getElementById('print-button').style.visibility = 'hidden'
            }
        }).then((canvas) => {
            const img = canvas.toDataURL('image/png')
            //const pdf = new jsPdf('landscape', 'pt', 'a4')
            //const pdf = new jsPdf('l', 'mm', [438.5, 310]);
            const pdf = new jsPdf("l", "mm", "a4");
            var width = pdf.internal.pageSize.width;
            var height = pdf.internal.pageSize.height;
            pdf.addImage(img, 'JPEG', 0, 0, width, height);
            pdf.save('relatorio.pdf')

            handleModal(false)
        })

    }

    function renderPages(pages) {

        let pagesToPrint = [];
        for (let i = 0; i < pages; i++) {
            pagesToPrint.push(
                <>
                    <Paper id={'page'} className={classes.page} elevation={0}>

                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <TableContainer>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Dessert (100g serving)</TableCell>
                                                <TableCell align="right">Calories</TableCell>
                                                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                                <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right">{row.calories}</TableCell>
                                                    <TableCell align="right">{row.fat}</TableCell>
                                                    <TableCell align="right">{row.carbs}</TableCell>
                                                    <TableCell align="right">{row.protein}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={classes.paper}
                                    elevation={0}>

                                    <PieChart legendPosition={'right'} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            )
        }

        return pagesToPrint;

    }

    return (
        <Dialog
            open={open}
            scroll={scroll}
            fullWidth={true}
            maxWidth={'xl'}
            //TransitionComponent={Transition}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">Relatório</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.contentModal}>
                    {renderPages(1)}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleModal(false)} color="primary">
                    Cancelar
          </Button>
                <Button color="primary"
                    onClick={() => printPDF()}>
                    Imprimir
          </Button>
            </DialogActions>
        </Dialog>
    );
}