import React, { useEffect, useState, useCallback, createRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import _, { set } from 'lodash';

//API
import { getClientAccountsBalancesValidationsByYear } from '../../../API/client'
import { listClientAccounts, getClientAccountsByRegimeAndDate } from '../../../API/account'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'
import { getInstituitionAbreviation, applyMaskAccount } from "../../utils/utils";
import { Typography } from "@material-ui/core";

//Tables
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import PublishIcon from '@material-ui/icons/Publish';

import { formatBalancesAccountsValidationToShow, getFormattedAgencyAccountToValidations } from '../../../controllers/AccountController'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    greenIcon: {
        color: unoTheme.greenMoney,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }

    },
    pendent: {
        color: unoTheme.warningColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '& td:first-child': {
            paddingLeft: '16px !important',
        },
        '&:hover': {
            background: unoTheme.hoverTable,
        }

    },
    tabs: {
        height: '64px',
        minHeight: '64px',
        width: '100%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',

        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: unoTheme.mainColor,
        },

        '& .MuiTabs-indicator': {
            backgroundColor: unoTheme.mainColor,
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    headerYear: {
        background: unoTheme.mainColor,
        color: 'white',
    },
    table: {

        '& .MuiTableRow-head': {
            borderRadius: '10px',
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 16px 16px 16px',
                background: 'white',
            },
        },

        '& .MuiTableCell-root': {

            padding: '4px',
            maxWidth: '0px',

            '& .spanAsset': {
                color: 'blue'
            }

        }

    },
    labelCell: {
        fontSize: '0.8em',
    },
    labelCellTop: {
        fontSize: '0.8em',
        fontWeight: 'bold'
    },
    labelCellBottom: {
        fontSize: '0.8em',
    },
    validInput: {
        cursor: 'pointer',
        '& input': {
            cursor: 'pointer',
        }
    },
    customDisable: {
        '& .MuiOutlinedInput-root': {
            background: "#e7ffe5 !important",
        },
    },
    smallText: {
        fontSize: '.9em'
    },

    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: '42px',
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    }

}));

export default function ValidationAccountsScreen(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const [tabValue, setTabValue] = React.useState(0);

    const clientOn = useSelector(state => state.clientOn)
    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [accountsClient, setAccountsClient] = React.useState(undefined);

    const [regimeOnScreen, setRegimeOnScreen] = useState(undefined);

    const [assetsClient, setAssetsClient] = React.useState(undefined);
    const [appearFab, setAppearFab] = useState(true);

    const [dataToTables, setDataToTables] = React.useState(undefined);

    const rightContent = useSelector(state => state.rightContent)

    async function doGetClientBalancesValidations(selectedYear, clientId, regimeId) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...'
        }));

        const responseAccs = await getClientAccountsByRegimeAndDate(clientId, regimeId);
        if (responseAccs.success) {

            console.log('ACCOUNTS CLIENT: ', responseAccs.body.rows)
            setAccountsClient(responseAccs.body.rows)

            // console.log('ACCOUNTS CLIENT: ', responseAccs.body)
            // setAccountsClient(responseAccs.body)

        } else {
            console.log("error: ", responseAccs.error)
        }

        const responseBv = await getClientAccountsBalancesValidationsByYear(selectedYear, clientId);

        if (responseBv.success) {

            console.log("RESPONSE: ", responseBv)
            setDataToTables(formatBalancesAccountsValidationToShow(selectedYear,
                responseAccs.body.rows,
                responseBv.body.rows))

        } else {
            console.log("error: ", responseBv.error)
        }

        if (responseAccs && responseAccs.body && responseAccs.body.roows && responseAccs.body.rows.length == 0) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: 'Este cliente ainda não possui ativos cadastrados'
            }));
        } else {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }



    }

    async function updateBalancesTable(clientId, accounts, selectedYear) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...'
        }));

        const responseBv = await getClientAccountsBalancesValidationsByYear(selectedYear, clientId);

        if (responseBv.success) {

            console.log("RESPONSE: ", responseBv)
            setDataToTables(formatBalancesAccountsValidationToShow(selectedYear,
                accounts,
                responseBv.body.rows));

        } else {
            console.log("error: ", responseBv.error)
        }

        if (accounts && accounts.length == 0) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: 'Este cliente ainda não possui ativos cadastrados'
            }));
        } else {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }

    }

    function renderItems(mode, obj, selectedYear) {

        let monthsItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

        return _.map(monthsItems, (m) => {

            let element = {}
            let key = {}

            if (obj.number_account == '6149') {
                console.log('obj account: ', obj);
            }

            const mainCod = obj.description + "=" + obj.number_account + getFormattedAgencyAccountToValidations(obj.agency);
            if (mode == 'accounts') {
                element =
                    dataToTables
                    ['accounts']
                    [mainCod]
                    [m];
                // init = dataToTables
                // ['accounts']
                // [obj.description + "=" + obj.number_account].init
                key = mainCod;

            } else {
                element =
                    dataToTables
                    ['assets'] //accounts ou assets
                    [obj.fund_name + "=" + obj.number_account]
                    [m];

                // init = dataToTables
                // ['assets']
                // [obj.fund_name + "=" + obj.number_account].init
                key = obj.fund_name + "=" + obj.number_account
            }


            if (element) {

                if (element.balance_status && element.balance) {

                    if (m >= obj.main.init) {
                        return (


                            <TableCell
                                key={key + "-" + m}
                                align="center"
                                className={[classes.validInput, classes.green].join(' ')}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'detailBalanceValidation',
                                    data: element
                                }))}>
                                <Typography className={[classes.smallText, classes.greenIcon].join(' ')}>
                                    <CheckCircleIcon />
                                </Typography>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell
                                key={key + "-" + m}
                                align="center" >
                                <Typography className={classes.smallText}>
                                    {'-'}
                                </Typography>
                            </TableCell>
                        )
                    }
                } else {

                    // console.log("M: ", m)
                    // console.log("INIT: ", init)

                    if (m >= obj.main.init) {
                        return (

                            <TableCell
                                key={key + "-" + m}
                                align="center"
                                className={[classes.validInput, classes.green].join(' ')}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'detailBalanceValidation',
                                    data: element
                                }))}>
                                <Typography className={[classes.smallText, classes.pendent].join(' ')}>
                                    <ReportProblemIcon />
                                </Typography>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell
                                key={key + "-" + m}
                                align="center" >
                                <Typography className={classes.smallText}>
                                    {'-'}
                                </Typography>
                            </TableCell>
                        )
                    }


                }

                // }

            }

        })
    }

    function renderLodashAccounts(accounts, selectedYear) {

        console.log("LODASH ACCOUNTS: ", accounts)

        return _.map(accounts, (row, key) => {

            const mainCod = row.description + "=" + row.number_account + getFormattedAgencyAccountToValidations(row.agency);

            row.selectedYear = selectedYear;
            row.main =
                dataToTables
                ['accounts']
                [mainCod]

            if (row && row.main && row.main.init != 0) {
                return (

                    <TableRow key={key + '-balance-account'}
                        className={classes.selectableRow}
                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                            path: 'detailBalanceValidation',
                            data: row,
                        }))}>
                        <TableCell align="left" >
                            <Typography className={classes.labelCellTop} noWrap>{applyMaskAccount(row.number_account)}</Typography>
                            <Typography className={classes.labelCellBottom} noWrap>{getInstituitionAbreviation(row.description)}</Typography>
                        </TableCell>

                        {renderItems('accounts', row, selectedYear)}

                    </TableRow>
                )
            }

        })
    }

    const { regime } = props

    useEffect(() => {

        console.log("User effect Validation Accounts")
        if (clientOn
            && clientOn.client
            && (
                !clientOnScreen
                ||
                (clientOnScreen.id != clientOn.client.id)
                || regime != regimeOnScreen
            )
        ) {

            console.log("PROCESSO 1");
            setClientOnScreen(_.cloneDeep(clientOn.client));
            setRegimeOnScreen(regime);
            doGetClientBalancesValidations(clientOn.client.selectedValidationYear, clientOn.client.id, regime);

        } else if (clientOn

            && clientOn.client
            && clientOnScreen
            && clientOn.client.selectedValidationYear != clientOnScreen.selectedValidationYear) {

            console.log("NOVO ANO: ", clientOn.client.selectedValidationYear)
            setClientOnScreen(_.cloneDeep(clientOn.client));
            updateBalancesTable(clientOn.client.id, accountsClient, clientOn.client.selectedValidationYear);

        }

        //Quando salva-se o que foi feito na tela de rightcontent e precisa-se atualizar os dados 
        //da tela principal
        if (rightContent && rightContent.action == "update") {

            console.log('2')
            dispatch(allActions.rightContentActions.setRightContent(null))
            updateBalancesTable(clientOn.client.id, accountsClient, clientOn.client.selectedValidationYear);

        }

    }, [clientOn, rightContent, regime])

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>

                {
                    dataToTables
                        && dataToTables
                        && dataToTables['accounts'] && accountsClient ?

                        <MyTable
                            onScroll={(value) => setAppearFab(value)}
                            stickyHeader
                            size="small"
                            selectable="true"
                            customclass={classes.table}>

                            <colgroup>
                                <col width="22%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTh} align="left">Conta corrente</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jan</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Fev</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Mar</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Abr</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Mai</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jun</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jul</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Ago</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Set</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Out</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Nov</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Dez</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {accountsClient && accountsClient.length > 0
                                    && clientOn && clientOn.client ? renderLodashAccounts(accountsClient, clientOn.client.selectedValidationYear) : null}
                            </TableBody>
                        </MyTable>

                        : null
                }
            </div>

            {/* <Zoom
                in={appearFab}
                unmountOnExit>
                
                <Fab className={classes.fabAdd}
                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                        path: 'newAccount',
                        data: {

                        }
                    }))}
                    color="primary" aria-label="add">
                    <PublishIcon />
                </Fab>
            </Zoom> */}

        </div>
    );
}



