import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { unoTheme } from '../../assets/styles/unoTheme'

import UnoButton from '../utils/UnoButton';


const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },

    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },

    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function CustomReportModal({ open, handleCustomReportModal }) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        dashboard: false,
        portfolio: false,
        distribuition: false,
        articles: false,
        targets: false,
        myFunds: false,
    });

    function initState() {
        setState({
            dashboard: false,
            portfolio: false,
            distribuition: false,
            articles: false,
            targets: false,
            myFunds: false,
        })
    }

    function handleCloseModal() {

        initState();
        handleCustomReportModal(false);

    }

    function getSelectedReports(state) {
        let selecteds = [];
        Object.entries(state).map(([key, element]) => {

            if (state[key]) {
                selecteds.push(key)
            }

        })

        initState();
        return selecteds;
    }

    const {
        dashboard,
        portfolio,
        distribuition,
        articles,
        targets,
        myFunds,
    } = state;

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <Dialog
            open={open}
            //keepMounted
            onClose={() => handleCustomReportModal(false)}>
            <DialogTitle>{"Selecione relatórios desejados"}</DialogTitle>
            <DialogContent>

                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={dashboard} onChange={handleChange} name='dashboard' />}
                        label="Dashboard"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={portfolio} onChange={handleChange} name='portfolio' />}
                        label="Carteira"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={distribuition} onChange={handleChange} name='distribuition' />}
                        label="Distribuição"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={articles} onChange={handleChange} name='articles' />}
                        label="Enquadramentos"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={targets} onChange={handleChange} name='targets' />}
                        label="Metas"
                    />
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={myFunds} onChange={handleChange} name='myFunds' />}
                        label="Fundos"
                    />
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <DialogActions className={classes.actionsFlex}>

                    <UnoButton onClick={() =>
                        handleCloseModal()} color="secondary">
                        {'Cancelar'}
                    </UnoButton>
                    <UnoButton
                        className={classes.spaceRight2}
                        onClick={() => handleCustomReportModal(getSelectedReports(state))}
                        color="primary">
                        {'Gerar'}
                    </UnoButton>
                </DialogActions>
            </DialogActions>
        </Dialog>
    );
}