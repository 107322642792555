import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField'
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MySwitch from '../../utils/MySwitch'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

//Tables
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//API
import {
    getTitleTypes,
    createTitle,
    updateTitle,
    updateTitleType,
    createTitleType,
    deleteTitle,
    saveCoupons,
    getCouponsByTitleIdAndYear
} from '../../../API/title'

//API
import {
    loadAllQuotasTitleById
} from '../../../API/title_local_quotas'

//API
import {
    getTitlesInfos
} from '../../../API/comdinheiro-api'

//Controllers
import {
    formatTitleLocalQuotasMonthYear, doSaveTitleLocalQuotas
} from '../../../controllers/TitleLocalQuotasController'
import {
    doSaveCoupons
} from '../../../controllers/TitleController'

import {
    listLegislationSegments,
    listLegislationArticlesBySegment
} from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import { unoTheme } from "../../../assets/styles/unoTheme";
import MySelect from '../../utils/MySelect';
import MyDatePicker from '../../utils/MyDatePicker'
import UnoButton from '../../utils/UnoButton';

import {
    applyMaskAmount, parseFloatByPtCurrency,
    getMonthName,
    formatAnbimaDateToPt,
    applyMaskAmountFreeDecimals,
    getTitleCod, getArrayMonthsWithNames, getYearsByDateUntilNow, stringPtDateToMoment, stringPtDateToJsDate, getDayMonthYearByStringDate
} from '../../utils/utils';
import {
    getOptionsMarkingTitle,
    getOptionsSectorTitle,
    getOptionsIndexersTitle
} from '../../utils/paramsToTitles';

import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmActionRightContent from './ConfirmActionRightContent'

import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 0.5px #f4f4f4',

        background: unoTheme.hoverTable,

        '& .closeIcon': {
            padding: theme.spacing(3),
        }
    },

    main: {
        //height: 'calc(100% - 96px)',
        overflowY: 'scroll',
        height: 'calc(100vh - 72px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        },

        '& .topQuotas': {
            display: 'flex',
            alignItems: 'center',
            '& div': {
                display: 'flex',
                alignItems: 'center',
            }
        }

    },

    mainCoupons: {

        height: 'calc(100vh - 72px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        textAlign: 'left',

        '& .topQuotas': {
            display: 'flex',
            padding: '10px 16px',
            borderBottom: 'solid 1px #dddddd',
            justifyContent: 'space-between',
            alignItems: 'center'
        },

        '& .bottom-coupons': {
            padding: '10px 16px',
        }

    },

    mainQuotas: {
        padding: theme.spacing(0),
        overflowY: 'unset !important',

        height: 'calc(100vh - 95px) !important',

        '& .MuiPaper-root': {
            height: 'calc(100vh - 192px) !important',
        },

        '& .topQuotas': {
            padding: '10px 16px',
            borderBottom: 'solid 1px #dddddd',
            justifyContent: 'space-between'
        },

        '& .bottom-quotas': {
            height: '38px',
            padding: '12px 24px',
            borderTop: 'solid 1px #dddddd'
        },

        '& .edit-quota': {
            height: 'calc(100vh - 72px)',
            padding: theme.spacing(3),
            position: 'absolute',
            //height: '100%',
            width: '100%',
            zIndex: '2',
            background: "rgba(255, 255, 255, 0.95)",
            overflowY: 'scroll',

            '& .buttons-edit': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',

                '& .main-actions': {
                    display: 'flex',
                    alignItems: 'center',
                }
            },

            '& .edit-quota-inputs': {
                marginTop: '-90px',

                '& .MuiTextField-root': {
                    marginTop: '16px',
                    marginBottom: '16px',
                },




            }
        }

    },

    mainTitleTypes: {
        overflowY: 'scroll',
        height: 'calc(100vh - 72px)',
        display: 'flex',
        flexDirection: 'column',
        //overflowY: 'scroll',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: '100%',
        },

        '& .bottom-title-types': {
            height: '54px',
            padding: '12px 24px',
            borderTop: 'solid 1px #dddddd',

            '& .MuiButtonBase-root': {
                float: 'right',
            }
        },

        '& .bottom-title-types-new': {
            padding: '24px 0px',
            //borderTop: 'solid 1px #dddddd',

        },

        '& .newTitleType': {
            padding: '24px 40px',
        },

        '& .topQuotas': {
            display: 'flex',
            alignItems: 'center',
            '& div': {
                display: 'flex',
                alignItems: 'center',
            }
        }

    },

    myDatePicker: {
        marginBottom: '16px',
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    tabsAsset: {
        background: unoTheme.hoverTable,
        '& .MuiButtonBase-root': {
            height: '71px',
        },

        '& .MuiTabs-flexContainer': {
            '& .MuiTab-root': {
                minWidth: '130px !important',
            }
        }
    },

    rightDivBackdrop: {
        zIndex: 3,
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

}));

export default function NewFund(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");

    const [segments, setSegments] = useState([]);
    const [segmentsV2, setSegmentsV2] = useState([]);
    const [articles, setArticles] = useState([]);
    const [articlesV2, setArticlesV2] = useState([]);

    //Formulário
    const [inputNameTitle, setInputNameTitle] = useState("");
    const [inputLiquidityTitle, setInputLiquidityTitle] = useState("");
    const [inputTypeTitle, setInputTypeTitle] = useState(0);
    const [inputSectorTypeTitle, setInputSectorTypeTitle] = useState(0);
    const [inputIndexerTitle, setInputIndexerTitle] = useState(null);

    const [inputIssueDateTitle, setInputIssueDateTitle] = useState(null);
    const [inputSaleOffDateTitle, setInputSaleOffDateTitle] = useState(null);
    const [inputDueDateTitle, setInputDueDateTitle] = useState(null);
    const [inputPurchasedTax, setInputPurchasedTax] = useState("");

    const [inputDiscountTaxTitle, setInputDiscountTaxTitle] = useState("");

    const [inputPayCouponTitle, setInputPayCouponTitle] = useState(false);
    const changePayCouponTitle = (event) => {
        setInputPayCouponTitle(event.target.checked);
    };
    const [inputLegislationSegmentTitle, setInputLegislationSegmentTitle] = useState(undefined);
    const [inputLegislationSegmentTitleV2, setInputLegislationSegmentTitleV2] = useState(undefined);
    const [inputArticleTitle, setInputArticleTitle] = useState(undefined);
    const [inputArticleTitleV2, setInputArticleTitleV2] = useState(undefined);
    const [disableArticle, setDisableArticle] = useState(true);
    const [disableArticleV2, setDisableArticleV2] = useState(true);


    const [inputNewTitleType, setInputNewTitleType] = useState("");
    const [createOrEdittingTitleType, setCreateOrEdittingTitleType] = useState(null);

    //Cotas locais
    const [titleLocalQuotas, setTitleLocalQuotas] = useState(null);
    const [monthSelected, setMonthSelected] = useState(0);
    const [yearSelected, setYearSelected] = useState(0);

    const [monthsInYearQuotas, setMonthsInYearQuotas] = useState([]);
    const [yearsWithMonths, setYearsWithMonths] = useState([]);
    const [yearsQuotas, setYearsQuotas] = useState([]);

    const [confirmUpdateAllQuotas, setConfirmUpdateAllQuotas] = useState(false);

    //Cupons predefinidos
    const [yearsCoupons, setYearsCoupons] = useState(null);
    const [yearCouponSelected, setYearCouponSelected] = useState(0);
    const [months, setMonths] = useState(getArrayMonthsWithNames())

    const initInputsCoupons = [
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
    ];
    const [inputs, setInputs] = useState(_.cloneDeep(initInputsCoupons));

    function handleChangeMonthQuotas(month) {

        console.log("MONTH SELECTED: ", month);
        setMonthSelected(month);
    }

    function handleChangeYearQuotas(year) {
        console.log("YEAR SELECTED: ", year);
        setYearSelected(year);
        setMonthsInYearQuotas(yearsWithMonths[yearsQuotas[year]]);
        setMonthSelected(0);

    }

    function handleLegislationSegmentTitle(event) {

        setDisableArticle(true);
        setInputLegislationSegmentTitle(event.target.value);
        loadArticles(segments[event.target.value].id, 1);

    }
    function handleLegislationSegmentTitleV2(event) {

        setDisableArticleV2(true);
        setInputLegislationSegmentTitleV2(event.target.value);
        loadArticles(segmentsV2[event.target.value].id, 2);

    }

    async function loadArticles(segmentId, resolutionVersion) {

        console.log("Listando: ", segmentId);

        let responseArticles = await listLegislationArticlesBySegment(segmentId);

        console.log("Aticles: ", responseArticles);

        if (responseArticles.success) {

            if (resolutionVersion == 1) {
                setArticles(responseArticles.body.rows);
                setDisableArticle(false);
            } else {
                setArticlesV2(responseArticles.body.rows);
                setDisableArticleV2(false);
            }

            //setOpenBackdrop(false);
            return responseArticles.body.rows;

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao listar artigos de resolução"
            }))
            //setOpenBackdrop(false);
            return [];
        }



    }

    const [titleTypes, setTitleTypes] = useState([]);
    const [optionsSectorTitle,] = useState(getOptionsSectorTitle());
    const [optionsMarkings,] = useState(getOptionsMarkingTitle());
    const [optionsIndexersTitle,] = useState(getOptionsIndexersTitle());

    const [confirmingAction, setConfirmingAction] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    //Tab de saldos do ano ou descrição do ativo
    const [tabValue, setTabValue] = useState(0); //detail
    function handleChangeTabValue(event, value) {

        setSubmitted(false)

        if (value == 0) {
            setCreateOrEdittingTitleType(null);
        }
        setTabValue(value)
    }

    function initInfos(data, articlesV1, articlesV2, titleTypes, segmentsOnV1, segmentsOnV2) {

        console.log("INIT INFOS: ", data);
        console.log("titleTypes: ", titleTypes);
        setInputNameTitle(data.title_name);
        setInputLiquidityTitle(data.liquidity);
        const typeTitleIndex = titleTypes.findIndex(el => el.id === data.title_type_id);
        setInputTypeTitle(typeTitleIndex != -1 ? typeTitleIndex : null);
        // const markingIndex = optionsMarkings.findIndex(el => el === data.marking);
        // setInputMarkingTitle(markingIndex != -1 ? markingIndex : 0);
        const indexerIndex = optionsIndexersTitle.findIndex(el => el === data.indexer);
        setInputIndexerTitle(indexerIndex != -1 ? indexerIndex : null);
        //setInputPriceTitle(applyMaskAmount(data.price_title));
        setInputPayCouponTitle(data.pay_coupon);

        setInputIssueDateTitle(data.issue_date ? moment.utc(data.issue_date) : null);
        setInputSaleOffDateTitle(data.sale_off_date ? moment.utc(data.sale_off_date) : null);
        setInputDueDateTitle(data.due_date ? moment.utc(data.due_date) : null);

        setInputPurchasedTax(data.purchased_tax ? applyMaskAmount(data.purchased_tax) : "");
        setInputDiscountTaxTitle(data.discount_tax ? applyMaskAmount(data.discount_tax) : "");
        //setInputPreTaxTitle(data.pre_tax ? applyMaskAmount(data.pre_tax) : "");


        const indexSegmentV1 = segmentsOnV1.findIndex(el => el.id == data.legislation_segment_id);
        setInputLegislationSegmentTitle(indexSegmentV1 != -1 ? indexSegmentV1 : null);

        const indexSegmentV2 = segmentsOnV2.findIndex(el => el.id == data.legislation_segment_id_v2);
        setInputLegislationSegmentTitleV2(indexSegmentV2 != -1 ? indexSegmentV2 : null);

        const indexArticleV1 = articlesV1.findIndex(el => el.id == data.legislation_article_id);
        setInputArticleTitle(indexArticleV1 != -1 ? indexArticleV1 : null);
        const indexArticleV2 = articlesV2.findIndex(el => el.id == data.legislation_article_id_v2);
        setInputArticleTitleV2(indexArticleV2 != -1 ? indexArticleV2 : null);
    }


    async function loadInfos(data, update) {

        //Obter titleTypes
        let responseSegments = await listLegislationSegments();
        if (responseSegments.success) {

            const segmentsOnV1 = responseSegments.body.rows.filter(el => el.resolution_version_id == 1)
            const segmentsOnV2 = responseSegments.body.rows.filter(el => el.resolution_version_id == 2)
            setSegments(segmentsOnV1);
            setSegmentsV2(segmentsOnV2);

            let responseTitleTypes = await getTitleTypes();

            if (responseTitleTypes.success) {
                setTitleTypes(responseTitleTypes.body.rows);

                if (update) {

                    let localArticles = await loadArticles(data.legislation_segment_id, 1);
                    let localArticlesV2 = await loadArticles(data.legislation_segment_id_v2 ? data.legislation_segment_id_v2 : segmentsOnV2[0].id, 2);
                    initInfos(data, localArticles, localArticlesV2, responseTitleTypes.body.rows, segmentsOnV1, segmentsOnV2);

                } else {

                    let localArticles = await loadArticles(segmentsOnV1[0].id, 1);
                    let localArticlesV2 = await loadArticles(segmentsOnV2[0].id, 2);

                }

            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao listar segmentos de legislação"
            }))

            setOpenBackdrop(false);
        }

        setOpenBackdrop(false);

    }

    async function loadLocalQuotas(title) {

        setOpenBackdrop(true);

        //Load cotas locais do título 
        console.log("TITLE TO LOAD COTAS: ", title);
        const responseAllQuotasTitle = await loadAllQuotasTitleById(title.id);
        console.log("TITLE TO LOAD COTAS: ", responseAllQuotasTitle);
        if (responseAllQuotasTitle.success) {
            let formatted = formatTitleLocalQuotasMonthYear(responseAllQuotasTitle.body.rows);

            console.log("QUOTAS: ", formatted);

            setTitleLocalQuotas(formatted.data);

            if (yearsQuotas.length == 0 && formatted.years.length > 0) {
                setYearsQuotas(formatted.years);
                setYearsWithMonths(formatted.yearsWithMonths);
                setMonthsInYearQuotas(formatted.yearsWithMonths[formatted.years[0]]);
                console.log("SETANDO");
                console.log(monthSelected);
                console.log(yearSelected);

                setYearSelected(yearSelected);
                setMonthSelected(monthSelected);
            }
        }

        setOpenBackdrop(false);

    }

    async function doSaveTitle(event) {

        //event.preventDefault();
        setSubmitted(true);

        setOpenBackdrop(true);

        //Realizar save title
        if (inputNameTitle != ""
            && inputLegislationSegmentTitle != null
            && inputLegislationSegmentTitleV2 != null
            && inputArticleTitle != null
            && inputArticleTitleV2 != null
            && articles[inputArticleTitle] != null
            && articlesV2[inputArticleTitleV2] != null) {

            if (titleTypes[inputTypeTitle].sector == 'PUBLICO'
                && (
                    !moment(inputIssueDateTitle).isValid() || !moment(inputSaleOffDateTitle).isValid() || !moment(inputDueDateTitle).isValid()
                )
            ) {

                setOpenBackdrop(false);
                return;
            }

            //let inputTaxOk = false;
            let inputTaxOk = true;

            if (inputTaxOk) {

                const titleToSave = {
                    title_name: inputNameTitle,
                    title_type_id: titleTypes[inputTypeTitle].id,
                    //marking: optionsMarkings[inputMarkingTitle],
                    indexer: optionsIndexersTitle[inputIndexerTitle],
                    legislation_article_id: articles[inputArticleTitle].id,
                    legislation_article_id_v2: articlesV2[inputArticleTitleV2].id,
                    issue_date: moment(inputIssueDateTitle).isValid() ? moment.utc(inputIssueDateTitle).format("DD/MM/YYYY") : null,
                    sale_off_date: moment(inputSaleOffDateTitle).isValid() ? moment.utc(inputSaleOffDateTitle).format("DD/MM/YYYY") : null,
                    due_date: moment(inputDueDateTitle).isValid() ? moment.utc(inputDueDateTitle).format("DD/MM/YYYY") : null,
                    //price_title: parseFloatByPtCurrency(inputPriceTitle),
                    pay_coupon: inputPayCouponTitle,
                    liquidity: inputLiquidityTitle,
                    //purchased_tax: inputPurchasedTax && inputPurchasedTax != "" ? parseFloatByPtCurrency(inputPurchasedTax) : null,
                    //discount_tax: inputDiscountTaxTitle && inputDiscountTaxTitle != "" ? parseFloatByPtCurrency(inputDiscountTaxTitle) : null,
                    //pre_tax: inputPreTaxTitle && inputPreTaxTitle != "" ? parseFloatByPtCurrency(inputPreTaxTitle) : null,
                }

                setSubmitted(false);

                console.log("TITLE TO SAVE: ", titleToSave);

                if (content.update) {

                    titleToSave.id = content.data.id;

                    let response = await updateTitle(titleToSave);

                    if (response.success) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            message: "Título atualizado com sucesso!",
                            type: "success"
                        }))


                        dispatch(allActions.rightContentActions.update())

                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao atualizar o título: " + response.body.message

                        }));
                    }

                } else {

                    let response = await createTitle(titleToSave);

                    if (response.success) {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            message: "Título cadastrado com sucesso!",
                            type: "success"
                        }))

                        dispatch(allActions.rightContentActions.update())

                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao cadastrar o título: " + response.body.message

                        }));
                    }
                }
            }

        }

        setOpenBackdrop(false);
    }

    async function doSaveTitleType(titleType) {

        setSubmitted(true);

        setOpenBackdrop(true);

        //Realizar save title
        if (inputNewTitleType != "") {


            let newTitleTypeToSave = {
                description: inputNewTitleType,
                sector: optionsSectorTitle[inputSectorTypeTitle],
            }

            setInputNewTitleType("");

            setSubmitted(false);

            if (titleType && titleType.id) {

                newTitleTypeToSave.id = titleType.id;

                let response = await updateTitleType(newTitleTypeToSave);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: "Tipo de Título atualizado com sucesso!",
                        type: "success"
                    }))

                    loadInfos(content.data, content.update);
                    //dispatch(allActions.rightContentActions.update())

                } else {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }))

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao atualizar o tipo de título: " + response.body.message

                    }));
                }

            } else {

                let response = await createTitleType(newTitleTypeToSave);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: "Tipo de Título cadastrado com sucesso!",
                        type: "success"
                    }))

                    loadInfos(content.data, content.update);
                    //dispatch(allActions.rightContentActions.update())

                } else {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }))

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao cadastrar o tipo de título: " + response.body.message

                    }));
                }
            }

            setCreateOrEdittingTitleType(null);

        }

        setOpenBackdrop(false);
    }

    function loadToupdateTitleType(titleType) {

        setCreateOrEdittingTitleType(titleType);
        setInputNewTitleType(titleType.description);
        const indexSectorTitle = optionsSectorTitle.findIndex(el => el === titleType.sector);
        setInputSectorTypeTitle(indexSectorTitle != -1 ? indexSectorTitle : 0);

    }

    function cancelNewTitleType() {
        setInputNewTitleType("");
        setCreateOrEdittingTitleType(null);
    }

    async function doDeleteTitle() {

        setOpenBackdrop(true);

        //console.log("CONTENT DATA: ", content.data.assetId);
        let response = await deleteTitle(content.data.id);
        if (response.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Ativo excluído com sucesso!'
            }));
        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: response.body ? response.body.message : "Erro"
            }));
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update())
    }

    //deleção de título
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteTitle();
    }

    function cancelConfirmUpdateAllQuotas() {
        setConfirmUpdateAllQuotas(false);
        setOpenBackdrop(false);
    }

    async function updateAllTitleQuotas(month, year, title) {

        setOpenBackdrop(true);
        setMessageBackdrop('Atualizando cotas...');

        let startDate = moment(new Date(year, parseInt(month) - 1, 1)).format("DD/MM/YYYY");
        let endDate = moment(new Date(year, parseInt(month), 0)).format("DD/MM/YYYY");

        console.log("UPDATE");
        console.log(startDate);
        console.log(endDate);

        let responseGetCotas = await getTitlesInfos([title], startDate, endDate);

        if (responseGetCotas.success) {

            console.log('responseGetCotas: ', responseGetCotas)
            //Obteve as cotas             
            const cotas = responseGetCotas.body.mapTitleCotas[getTitleCod(title)];
            console.log("COTAS TO SAVE: ", cotas);
            let reponseUpdateCotas = await doSaveTitleLocalQuotas({
                title_id: title.id,
                cotas: cotas,
            }, startDate, month, year, true);

            if (reponseUpdateCotas.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Cotas atualizadas com sucesso!"
                }))

                loadLocalQuotas(content.data);

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao atualizar cotas!"
                }))
            }

        } else {

            //Falha ao obter as cotas
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter as cotas da API!"
            }))

        }

        setOpenBackdrop(false);

    }

    function doConfirmUpdateAllQuotas() {
        setConfirmUpdateAllQuotas(false);

        updateAllTitleQuotas(
            parseInt(monthsInYearQuotas[monthSelected]),
            parseInt(yearsQuotas[yearSelected]),
            content.data
        );
    }

    //Predefinicoes de cupons
    const [submittedCoupons, setSubmittedCoupons] = useState(false);
    async function loadCouponInYear(titleId, year) {

        setOpenBackdrop(true)
        const response = await getCouponsByTitleIdAndYear(titleId, year);
        if (response.success) {
            //initInputs with infos

            const newInputs = [...inputs];
            newInputs.forEach(element => {
                element.date = '';
                element.value = '';
            });

            setInputs(newInputs);

            response.body.rows.forEach(element => {

                const indexToInput = moment.utc(element.date_coupon).month();
                console.log("MONTH INPUT: ", indexToInput);
                const inputLoop = newInputs[indexToInput];
                console.log("MONTH INPUT: ", inputLoop);

                inputLoop.date = moment.utc(element.date_coupon).format('DD/MM/YYYY');
                inputLoop.value = applyMaskAmountFreeDecimals(element.value_coupon);

            });

            setInputs(newInputs);
        }

        setOpenBackdrop(false)
    }
    async function loadInfosToCoupons(title) {

        if (title.id) {

            const years = getYearsByDateUntilNow(title.issue_date);
            console.log('years coupons: ', years);
            setYearsCoupons(years);

            const lastYear = years[0];
            loadCouponInYear(title.id, lastYear);

        }

    }

    function handleChangeYearCoupon(value) {

        setYearCouponSelected(value);
        loadCouponInYear(content.data.id, yearsCoupons[value]);

    }

    function handleChangeDateInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].date = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    function handleChangeValueInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].value = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    async function doSaveTitleCoupons(title) {

        setOpenBackdrop(true);
        setSubmittedCoupons(true);

        const yearToSave = yearsCoupons[yearCouponSelected];

        console.log("INPUTS: ", inputs);
        const currentInputs = [...inputs];
        const couponsToSave = [];
        currentInputs.forEach((element, index) => {
            couponsToSave.push({
                title_id: title.id,
                date_coupon: element.date,
                value_coupon: element.value ? parseFloatByPtCurrency(element.value) : null,
                month: index + 1,
                year: yearToSave
            })
        });

        //Validar dados a serem salvos, as datas devem ser válidas e o ano deve ser igual ao selecionado no MySelect
        let allSuccess = true;
        console.log("COUPONS TO SAVE: ", couponsToSave);
        let haveItemsToSave = false;
        couponsToSave.forEach(element => {

            const inputLoop = currentInputs[element.month - 1];
            console.log('INPUT LOOP: ', inputLoop);
            delete inputLoop.dateHelperText
            delete inputLoop.valueHelperText

            if (element.date_coupon || element.value_coupon) {
                haveItemsToSave = true;
                if (!element.date_coupon) {
                    inputLoop.dateHelperText = 'Insira uma data';
                    allSuccess = false;
                }
                if (!element.value_coupon) {
                    inputLoop.valueHelperText = 'Insira um valor';
                    allSuccess = false;
                }

                if (element.date_coupon) {
                    //const momentDate = stringPtDateToMoment(element.date_coupon);
                    const jsDate = stringPtDateToJsDate(element.date_coupon);
                    const isValidDate = moment(jsDate, 'DD/MM/YYYY', true).isValid();
                    if (!isValidDate) {
                        inputLoop.dateHelperText = 'Data inválida';
                        allSuccess = false;
                    }
                    console.log("MOMENT IS VALID: ", isValidDate);
                    console.log("JS DATE: ", jsDate);
                    console.log("MONTH: ", moment(jsDate).month());
                    console.log("MONTH element: ", element.month);

                    if (isValidDate && (moment(jsDate).month() + 1) != element.month) {
                        inputLoop.dateHelperText = 'Mês inválido';
                        allSuccess = false;
                    }

                    if (isValidDate && moment(jsDate).year() != yearToSave) {
                        inputLoop.dateHelperText = 'Ano inválido';
                        allSuccess = false;
                    }
                }
            }


        });

        if (haveItemsToSave) {
            if (allSuccess) {

                console.log("ALL SUCCESS: ", couponsToSave);
                const responseSaveCoupons = await doSaveCoupons(couponsToSave.filter(el => el.date_coupon && el.value_coupon));
                if (responseSaveCoupons) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: 'Cupons salvos com sucesso!',
                        type: 'success',
                    }))
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: 'Falha ao salvar cupons',
                        type: 'error',
                    }))
                }

            } else {
                console.log("CURRENT INPUTS: ", currentInputs);
                setInputs(currentInputs);
            }
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'Nenhum campo foi preenchido!',
                type: 'warning',
            }))
        }

        setOpenBackdrop(false);


    }

    const [firstEffect, setFirstEffect] = useState(true);

    useEffect(() => {

        if (firstEffect) {

            setOpenBackdrop(true);

            setFirstEffect(false);
            console.log("CONTENT: ", content);

            setTimeout(() => {
                loadInfos(content.data, content.update);
            }, 500)
        }



    }, [])

    return (
        <div className={classes.root}>

            {
                !confirmingAction
                    && !confirmUpdateAllQuotas ?

                    <>
                        <Paper style={{ height: '72px' }}>
                            <div className={classes.header}>


                                <Paper square className={classes.tabsAsset} elevation={0}>
                                    <Tabs
                                        value={tabValue}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChangeTabValue}>

                                        <Tab label={'Detalhes'} />

                                        <Tab label={"PUs ANBIMA"} disabled={!content
                                            || !content.data
                                            || !content.data.id} onClick={() => !titleLocalQuotas ? loadLocalQuotas(content.data) : null} />



                                        <Tab disabled={!content
                                            || !content.data
                                            || !content.data.id} label={'Cupons'} onClick={() => loadInfosToCoupons(content.data)} />
                                        {
                                            content && content.data && content.data.id ?
                                                null
                                                : <Tab label={'Tipos'} />
                                        }

                                    </Tabs>
                                </Paper>

                                <IconButton className="closeIcon" onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                    <CloseIcon />
                                </IconButton>

                            </div>
                        </Paper>
                        {
                            tabValue == 0 ?
                                <div className={classes.main}>

                                    <Grid container spacing={2}>

                                        {/* <Grid item lg={9} md={9} sm={12} xs={12}> */}
                                        <Grid item lg={9} md={9} sm={12} xs={12}>

                                            <MyTextField
                                                labelclassname={classes.noMarginTop}
                                                value={inputNameTitle}
                                                onChange={(value) => setInputNameTitle(value)}
                                                variant="outlined"
                                                margin="dense"
                                                outerlabel={'Nome do Título'}
                                                type="text"
                                                validator={inputNameTitle == "" && submitted}
                                                placeholder="Insira o nome do título"
                                                helperText="O nome do título deve ser preenchido"
                                                fullWidth />
                                        </Grid>

                                        <Grid item lg={3} md={3} sm={12} xs={12}>
                                            <MySelect
                                                outerlabel={'Tipo do título'}
                                                value={inputTypeTitle}
                                                onChange={(event) => setInputTypeTitle(event.target.value)}
                                                options={titleTypes.map((row, index) => (
                                                    <option key={'type-title-select-' + index} value={index}>{row.description}</option>
                                                ))}
                                                helperText={"Insira o tipo"}
                                                validator={inputTypeTitle == undefined && submitted ? true : undefined}
                                            />
                                        </Grid>

                                        {/* Versão 1 */}

                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <MySelect
                                                initblank
                                                outerlabel={'Seg. da legislação (3.922/2010)'}
                                                value={inputLegislationSegmentTitle}
                                                onChange={handleLegislationSegmentTitle}
                                                options={segments.map((row, index) => (
                                                    <option key={'segment-select-' + index} value={index}>{row.description}</option>
                                                ))}
                                                helperText={"Insira o segmento (3.922/2010)"}
                                                validator={inputLegislationSegmentTitle == undefined && submitted ? true : undefined}
                                            />
                                        </Grid>

                                        <Grid item lg={7} md={7} sm={12} xs={12}>
                                            <MySelect
                                                initblank
                                                outerlabel={'Art. de resolução (3.922/2010)'}
                                                value={inputArticleTitle}
                                                disabled={inputLegislationSegmentTitle == undefined || disableArticle}
                                                onChange={(event) => setInputArticleTitle(event.target.value)}
                                                options={articles.map((row, index) => (
                                                    <option key={'article-select-' + index} value={index}>{row.device}</option>
                                                ))}
                                                helperText={"Insira o artigo (3.922/2010)"}
                                                validator={inputArticleTitle == undefined && submitted ? true : undefined}
                                            />
                                        </Grid>

                                        {/* Versão 2 */}

                                        <Grid item lg={5} md={5} sm={12} xs={12}>
                                            <MySelect
                                                initblank
                                                outerlabel={'Seg. da legislação (4.963/2021)'}
                                                value={inputLegislationSegmentTitleV2}
                                                onChange={handleLegislationSegmentTitleV2}
                                                options={segmentsV2.map((row, index) => (
                                                    <option key={'segment-select-v2' + index} value={index}>{row.description}</option>
                                                ))}
                                                helperText={"Insira o segmento (4.963/2021)"}
                                                validator={inputLegislationSegmentTitleV2 == undefined && submitted ? true : undefined}
                                            />
                                        </Grid>

                                        <Grid item lg={7} md={7} sm={12} xs={12}>
                                            <MySelect
                                                initblank
                                                outerlabel={'Art. de resolução (4.963/2021)'}
                                                value={inputArticleTitleV2}
                                                disabled={inputLegislationSegmentTitleV2 == undefined || disableArticleV2}
                                                onChange={(event) => setInputArticleTitleV2(event.target.value)}
                                                options={articlesV2.map((row, index) => (
                                                    <option key={'article-select-v2' + index} value={index}>{row.device}</option>
                                                ))}
                                                helperText={"Insira o artigo (4.963/2021)"}
                                                validator={inputArticleTitleV2 == undefined && submitted ? true : undefined}
                                            />
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyDatePicker
                                                defaultValue={null}
                                                className={classes.myDatePicker}
                                                margin="dense"
                                                outerlabel="Emissão"
                                                format="DD/MM/YYYY"
                                                value={inputIssueDateTitle}
                                                onChange={(value) => setInputIssueDateTitle(value)}
                                                validator={!moment(inputIssueDateTitle).isValid() && submitted}
                                                helperText={"Insira uma data válida"}
                                                KeyboardButtonProps={{ 'aria-label': 'change date', }} />


                                        </Grid>



                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyDatePicker
                                                defaultValue={null}
                                                className={classes.myDatePicker}
                                                margin="dense"
                                                outerlabel="Liquidação"
                                                format="DD/MM/YYYY"
                                                value={inputSaleOffDateTitle}
                                                onChange={(value) => setInputSaleOffDateTitle(value)}
                                                validator={!moment(inputSaleOffDateTitle).isValid() && submitted}
                                                helperText={"Insira uma data válida"}
                                                KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                        </Grid>


                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyDatePicker
                                                defaultValue={null}
                                                className={classes.myDatePicker}
                                                margin="dense"
                                                outerlabel="Vencimento"
                                                format="DD/MM/YYYY"
                                                value={inputDueDateTitle}
                                                onChange={(value) => setInputDueDateTitle(value)}
                                                validator={!moment(inputDueDateTitle).isValid() && submitted}
                                                helperText={"Insira uma data válida"}
                                                KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                        </Grid>

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MySelect
                                                outerlabel={'Indexador'}
                                                initblank
                                                value={inputIndexerTitle}
                                                onChange={(event) => setInputIndexerTitle(event.target.value)}
                                                options={optionsIndexersTitle.map((row, index) => (
                                                    <option key={'indexer-title-' + index} value={index}>{row}</option>
                                                ))}
                                            />
                                        </Grid>

                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <MyTextField
                                                labelclassname={classes.noMarginTop}
                                                value={inputLiquidityTitle}
                                                onChange={(value) => setInputLiquidityTitle(value)}
                                                outerlabel={'Liquidez'}
                                                placeholder="Insira a liquidez"
                                                fullWidth />
                                        </Grid>

                                    </Grid>

                                    <br />
                                    <div className={classes.bottom}>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <UnoButton
                                                className={
                                                    !content.update ? classes.hiddenIcon : null}
                                                onClick={() => setConfirmingAction(true)}
                                                type='errorClean'>
                                                {"Excluir título"}
                                            </UnoButton>
                                        </div>

                                        <UnoButton
                                            type={
                                                content && content.update ? 'success' : 'primary'
                                            }
                                            float={'right'}
                                            onClick={(e) => doSaveTitle(e)}>
                                            {
                                                content && content.update ? 'Atualizar título' : 'Salvar título'
                                            }
                                        </UnoButton>

                                    </div>


                                </div>

                                :

                                <>

                                    {

                                        tabValue == 3 ?

                                            <div className={classes.mainTitleTypes}>

                                                {
                                                    createOrEdittingTitleType == null ?
                                                        <>
                                                            <MyTable
                                                                stickyHeader
                                                                selectable="true"
                                                                size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left">Identificador do tipo</TableCell>
                                                                        <TableCell align="center">Setor</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {titleTypes.map((row, index) => (
                                                                        <>
                                                                            {
                                                                                <TableRow
                                                                                    onClick={() => loadToupdateTitleType(row)}
                                                                                    key={'title-type-' + index}>
                                                                                    <TableCell align="left">{row.description}</TableCell>
                                                                                    <TableCell align="center">{row.sector}</TableCell>
                                                                                </TableRow>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </TableBody>
                                                            </MyTable>

                                                            <div class="bottom-title-types">

                                                                <UnoButton
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => setCreateOrEdittingTitleType({})}
                                                                    type='success'>
                                                                    {"Adicionar tipo"}
                                                                </UnoButton>
                                                            </div>

                                                        </>

                                                        :

                                                        <div className="newTitleType">

                                                            <Grid container spacing={2}>

                                                                <Grid item lg={8} md={8} sm={12} xs={12}>

                                                                    <MyTextField
                                                                        outerlabel="Tipo de título"
                                                                        placeholder="Insira o tipo de título"
                                                                        value={inputNewTitleType}
                                                                        onChange={(value) => setInputNewTitleType(value)}
                                                                        validator={inputNewTitleType == "" && submitted}
                                                                        helperText="Informe o nome do tipo" />
                                                                </Grid>

                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    <MySelect
                                                                        outerlabel={'Setor'}
                                                                        value={inputSectorTypeTitle}
                                                                        onChange={(event) => setInputSectorTypeTitle(event.target.value)}
                                                                        options={optionsSectorTitle.map((row, index) => (
                                                                            <option key={'sector-title-select-' + index} value={index}>{row}</option>
                                                                        ))}
                                                                    />
                                                                </Grid>
                                                            </Grid>


                                                            <div class="bottom-title-types-new">


                                                                <UnoButton
                                                                    onClick={() => cancelNewTitleType()}
                                                                    type='errorClean'>
                                                                    {"Cancelar"}
                                                                </UnoButton>

                                                                {

                                                                    createOrEdittingTitleType.id ?
                                                                        <UnoButton
                                                                            style={{ float: 'right' }}
                                                                            onClick={() => doSaveTitleType(createOrEdittingTitleType)}
                                                                            type='success'>
                                                                            {"Atualizar tipo"}
                                                                        </UnoButton>
                                                                        :
                                                                        <UnoButton
                                                                            style={{ float: 'right' }}
                                                                            onClick={() => doSaveTitleType()}
                                                                            type='primary'>
                                                                            {"Salvar tipo"}
                                                                        </UnoButton>
                                                                }




                                                            </div>


                                                        </div>
                                                }

                                            </div>


                                            :
                                            <>

                                                {tabValue == 1 ?
                                                    <div className={[classes.main, classes.mainQuotas].join(' ')}>

                                                        {
                                                            titleLocalQuotas && yearsQuotas && yearsQuotas.length > 0 && monthsInYearQuotas ?
                                                                <div className="topQuotas">

                                                                    <Typography variant={'body1'}>
                                                                        {'Selecione o mês e o ano >'}
                                                                    </Typography>

                                                                    <div>
                                                                        <MySelect
                                                                            style={{ marginLeft: '16px', marginRight: '16px' }}
                                                                            value={monthSelected}
                                                                            onChange={(e) => handleChangeMonthQuotas(e.target.value)}
                                                                            options={monthsInYearQuotas.map((row, index) => (
                                                                                <option key={'month-local-title-quota-' + index} value={index}>{getMonthName(row)}</option>
                                                                            ))}
                                                                        />
                                                                        <MySelect
                                                                            value={yearSelected}
                                                                            onChange={(e) => handleChangeYearQuotas(e.target.value)}
                                                                            options={yearsQuotas.map((row, index) => (
                                                                                <option key={'year-local-title-quota-' + index} value={index}>{row}</option>
                                                                            ))}
                                                                        />
                                                                    </div>
                                                                </div> : null
                                                        }

                                                        {
                                                            titleLocalQuotas ?

                                                                <MyTable
                                                                    stickyHeader
                                                                    size="small"
                                                                    selectable="true">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="left">Data</TableCell>
                                                                            <TableCell align="right">PU Anbima</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {titleLocalQuotas
                                                                            && titleLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]]
                                                                            ? titleLocalQuotas[monthsInYearQuotas[monthSelected] + "/" + yearsQuotas[yearSelected]].map((row, index) => (

                                                                                <TableRow key={"row-title-quota-local-index-" + index}>
                                                                                    <TableCell align="left">{row['date_quota']}</TableCell>
                                                                                    <TableCell align="right" style={{ color: unoTheme.greenMoney }}>{applyMaskAmountFreeDecimals(row['pu_anb'], true, 10)}</TableCell>
                                                                                </TableRow>

                                                                            )) : null}
                                                                    </TableBody>

                                                                </MyTable>

                                                                :

                                                                <Typography variant={'body1'} style={{ padding: '32px', textAlign: 'center' }}>
                                                                    {'Nenhuma cota local foi salva para esse fundo até agora'}
                                                                </Typography>
                                                        }



                                                        <div class="bottom-quotas">

                                                            {
                                                                titleLocalQuotas && yearsQuotas && yearsQuotas.length > 0 && monthsInYearQuotas ?
                                                                    <UnoButton
                                                                        size='small'
                                                                        type={'success'}
                                                                        float={'right'}
                                                                        onClick={() => setConfirmUpdateAllQuotas(true)}>{'Atualizar todas'}
                                                                    </UnoButton>
                                                                    : null
                                                            }



                                                        </div>

                                                    </div>

                                                    :

                                                    <div className={classes.mainCoupons}>
                                                        <div className="topQuotas">

                                                            <Typography variant={'body1'}>
                                                                {'Selecione o ano >'}
                                                            </Typography>

                                                            <div>
                                                                <MySelect
                                                                    value={yearCouponSelected}
                                                                    onChange={(e) => handleChangeYearCoupon(e.target.value)}
                                                                    options={yearsCoupons && yearsCoupons.map((row, index) => (
                                                                        <option key={'year-coupons-' + index} value={index}>{row}</option>
                                                                    ))}
                                                                />
                                                            </div>
                                                        </div>
                                                        <TableContainer
                                                            style={{ overflow: 'unset' }}
                                                            component={Paper}
                                                            elevation={0}>
                                                            <Table className={classes.table}
                                                                stickyHeader
                                                                size="small">

                                                                <colgroup>
                                                                    <col width="40%" />
                                                                    <col width="30%" />
                                                                    <col width="30%" />
                                                                </colgroup>

                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.leftPadding24} align="left">MÊS</TableCell>
                                                                        <TableCell align="center">Data</TableCell>
                                                                        <TableCell align="center">Valor</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody className={classes.table}>
                                                                    {
                                                                        months.map((row) => (
                                                                            <>

                                                                                <TableRow className={classes.detailRow}
                                                                                    key={'detail-month' + row.id}>

                                                                                    <TableCell align="left">
                                                                                        {row.name}
                                                                                    </TableCell>

                                                                                    <TableCell align="left">

                                                                                        <MyTextField
                                                                                            align="center"
                                                                                            onChange={(value) => handleChangeDateInput(value, parseInt(row.id) - 1)}
                                                                                            value={inputs[parseInt(row.id) - 1].date}
                                                                                            verysmall
                                                                                            placeholder="DD/MM/YYYY"
                                                                                            validator={inputs[parseInt(row.id) - 1].dateHelperText && submittedCoupons ? true : null}
                                                                                            helperText={inputs[parseInt(row.id) - 1].dateHelperText}
                                                                                            size={'small'} />

                                                                                    </TableCell>


                                                                                    <TableCell align="left">
                                                                                        <MyTextField
                                                                                            align="right"
                                                                                            onChange={(value) => handleChangeValueInput(value, parseInt(row.id) - 1)}
                                                                                            value={inputs[parseInt(row.id) - 1].value}
                                                                                            verysmall
                                                                                            placeholder="0.00"
                                                                                            validator={inputs[parseInt(row.id) - 1].valueHelperText && submittedCoupons ? true : null}
                                                                                            helperText={inputs[parseInt(row.id) - 1].valueHelperText}
                                                                                            size={'small'} />

                                                                                    </TableCell>


                                                                                </TableRow >
                                                                            </>
                                                                        ))

                                                                    }
                                                                </TableBody>

                                                            </Table>
                                                        </TableContainer>

                                                        <div class="bottom-coupons">

                                                            <UnoButton
                                                                size='small'
                                                                type={'primary'}
                                                                float={'right'}
                                                                onClick={() => doSaveTitleCoupons(content.data)}>{'Salvar cupons'}
                                                            </UnoButton>


                                                        </div>
                                                    </div>


                                                }
                                            </>

                                    }


                                </>



                        }

                    </>

                    :
                    <>

                        {
                            confirmingAction ?
                                <ConfirmActionRightContent
                                    message={`Tem certeza que quer excluir esse ativo?`}
                                    handleCancel={handleCancel}
                                    handleConfirm={handleConfirm}
                                /> : null
                        }

                        {
                            confirmUpdateAllQuotas ?

                                <ConfirmActionRightContent
                                    message={`Atualizar todas as cotas de ${getMonthName(monthsInYearQuotas[monthSelected]) + "/" + yearsQuotas[yearSelected]}?`}
                                    handleCancel={() => cancelConfirmUpdateAllQuotas()}
                                    handleConfirm={() => doConfirmUpdateAllQuotas()}
                                /> : null

                        }
                    </>

            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'} style={{ marginTop: '16px' }}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }
        </div >
    );
}