/**
 * 
 * @param {import('../reducers/scrollDialog').ScrollDialog} payload 
 * @returns {import('../reducers/scrollDialog').ScrollDialogReducer}
 */
const setScrollDialog = (payload) => {
    return {
        type: "SET_SCROLL_DIALOG",
        payload
    }
}

export default {
    setScrollDialog
}