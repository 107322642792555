import React from 'react';
import Form from './Form';
import { Wrapper } from './styles';

export const clientViewerPath = 'newClientViewer';

/**
 * @typedef {1 | 2} RightDrawerActionEnum Estado para controlar criação (`1`) ou atualização (`2`) de gaveta
 * @typedef {{
 *  action: RightDrawerActionEnum;
 *  clientOnScreen: {
 *      label_name: string;
 * };
 *  path: string;
 * }} Content 
 * @param {{
 *  content: Content}} props 
 * @returns 
 */

/**
 * @typedef {1 | 2} RightDrawerActionEnum Estado para controlar criação (`1`) ou atualização (`2`) de gaveta
 * @typedef {{
*  action: RightDrawerActionEnum;
*  clientOnScreen: {
*      id: number;
*      label_name: string;
* };
*  path: string;
*  data?: {
*    id: number;
*    name: string;
* }
* }} Content 
* @param {{
*  content: Content}} props 
* @returns 
*/
export default function ClientViewer(props) {
    const { content } = props;

    return (
        <Wrapper>
            <Form viewer={content.data} action={content.action} clientOnScreen={content.clientOnScreen} />
        </Wrapper>
    );
};



