import API, { defaultConfig } from '../API/config';
const resource = 'pluggy';

export default {
     /**
     * 
     * @param {{
     *  clientId: number;
     *  itemId: string;
     * }} data
     * @returns {Promise<void>}
      */
     storeItem(data) {
         return API.post(`${resource}/storeItem`, data, defaultConfig);
     },
    /**
     * 
     * @param {number} clientId Current RPPS client logged id.
     * @returns {Promise<{
    *  data: {
    *  clientPluggyItemId: string;
    * }
     * }>}
     */
    fetchClientItem(clientId) {
        return API.get(`${resource}/fetchClientItem/${clientId}`, defaultConfig);
    },
    /**
     * 
     * @param {number} clientId Current RPPS client logged id.
     * @returns {Promise<{
     *  data: {
    *  accessToken: string;
    * }
     * }>}
     */
    auth(clientId) {
        return API.post(`${resource}/auth`, {
            clientId
        }, defaultConfig);
    },
    /**
     * 
     * @param {number} itemId  Item is the representation of a connection with a specific Connector of an Institution, and serves as the entry point to access the set of products recovered from the user that gave his consent to collect his/her data.
     * @returns 
     */
    getTransactions(itemId) {
        return API.get(`${resource}/getTransactions/${itemId}`, defaultConfig);
    }
};