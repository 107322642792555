import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getArrayYears, applyMaskAmount, applyMaskAccount, getMonthName } from '../utils/utils';
import UnoButton from '../utils/UnoButton';

//Table
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function AmortizationsToCreateModal(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const { handleModal, open, data } = props;

    useEffect(() => {

        console.log("DATA: ", data);
    }, [data])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => handleModal(false)}>
            <DialogTitle
                className={classes.dialogTitleApr}>
                {
                    <><InfoIcon className={[classes.spacingRight2, classes.blue].join(' ')} />Amortizações</>
                }


            </DialogTitle>


            <DialogContent
                className={classes.dialogContent}>

                {data && data.length > 0 ?
                    <>
                        <Typography className={classes.afirmativeText}>
                            {'Existem amortizações pré-cadastradas a serem criadas'}
                        </Typography>
                        <TableContainer component={Paper} elevation={0}>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.noPaddingLeft}>Título</TableCell>
                                        <TableCell align="center">Compra</TableCell>
                                        <TableCell align="center">Data Cupom</TableCell>
                                        <TableCell align="right">Valor cupom</TableCell>
                                        <TableCell align="right" className={classes.noPaddingRight}>Amortização</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    data ?
                                        <TableBody>
                                            {data.map((row, index) => (

                                                <TableRow
                                                    className={classes.assetsToCreateRow}
                                                    key={'amortization-to-create-' + index}>
                                                    <TableCell align="left" className={[classes.noPaddingLeft, classes.blue].join(' ')}>{row.title}</TableCell>
                                                    <TableCell align="center">{moment.utc(row.purchaseDate).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell align="center">{moment.utc(row.dateCoupon).format('DD/MM/YYYY')}</TableCell>
                                                    <TableCell align="right">{applyMaskAmount(row.valueCoupon, true)}</TableCell>
                                                    <TableCell align="right" className={[classes.noPaddingRight, classes.green].join(' ')}>{applyMaskAmount(row.valueAmortization, true)}</TableCell>
                                                </TableRow>

                                            ))}
                                        </TableBody> : null
                                }

                            </Table>
                        </TableContainer>
                    </> : null

                }

            </DialogContent>




            <DialogActions className={classes.actionsFlexRight}>

                <UnoButton
                    onClick={() => handleModal(false)} color="secondary">
                    {'Fechar'}
                </UnoButton>

                <UnoButton
                    onClick={() => handleModal(true)}
                    color="primary">
                    {'Validar e Criar'}
                </UnoButton>
            </DialogActions>




        </Dialog >
    );
}