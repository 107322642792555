export const MARKING_CURVE = 'CURVA';
export const MARKING_MARKET = 'MERCADO';

/**
* Retorna as opções de título público ou privado
* @return {Array} 
*/
export function getOptionsSectorTitle() {

    return [
        "PUBLICO",
        "PRIVADO"
    ]
}

/**
* Retorna os tipos de marcação de um título CURVA ou MERCADO
* @return {Array} Array com os tipos de marcação do título
*/
export function getOptionsMarkingTitle() {

    return [
        MARKING_CURVE,
        MARKING_MARKET
    ]
}

/**
* Retorna as opções de indexadores
* @return {Array} 
*/
export function getOptionsIndexersTitle() {

    return [
        "CDI",
        "IPCA",
        "IGPM"
    ]
}