import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo } from 'react';


//redux

import UnoButton from '../utils/UnoButton';

//Table
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import { myLabelInput, unoTheme } from '../../assets/styles/unoTheme';

import WarningIcon from '@material-ui/icons/Warning';
import { applyMaskAccount, applyMaskAmount } from '../utils/utils';


// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function InconsistentTitlesModal({ data, open, handleAction }) {

    const classes = useStyles();

    const { message, titles } = data;

    function formatTitlesToShow(titles) {
        console.log('formatTitlesToShow: ', titles);
        let formatted = "";
        titles.forEach(element => {
            formatted += element.title_name + "[" + element.titleCod + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    const titlesToShow = useMemo(() => {

        if (titles) {
            return formatTitlesToShow(titles);
        }
    }, [titles])

    useEffect(() => {

        console.log('titles in modal: ', titles)
    }, [titles])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => handleAction()}>
            <DialogTitle
                className={classes.dialogTitleApr}
                id="dialog-title-apr">
                {
                    <><WarningIcon className={[classes.spacingRight2, classes.warningColor].join(' ')} />Aviso</>
                }


            </DialogTitle>


            <DialogContent
                className={classes.dialogContent}>


                <Typography className={classes.afirmativeText}>
                    {message}
                </Typography>

                <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                    <Table className={classes.table} size="small" >
                        <colgroup>
                            <col width="50%" />
                            <col width="25%" />
                            <col width="25%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.noPaddingLeft}>Título</TableCell>
                                <TableCell align="right">Saldo anterior</TableCell>
                                <TableCell align="right" className={classes.noPaddingRight}>Saldo final</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {titles && titles.map((rowTitle, index) => (
                                <TableRow key={'divergente-balance-title-' + index}>
                                    <TableCell align={'left'} className={[classes.blue, classes.noPaddingLeft].join(' ')}>
                                        {rowTitle.title_name + ' [' + rowTitle.titleCod + ']'}
                                    </TableCell>
                                    <TableCell align="right" className={[classes.green].join(' ')}>
                                        {applyMaskAmount(rowTitle.balance_before)}
                                    </TableCell>
                                    <TableCell align="right" className={[classes.green, classes.noPaddingRight].join(' ')}>
                                        {applyMaskAmount(rowTitle.balance_now)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
                {/* 
                <Typography className={[classes.blue].join(' ')}>
                    {titlesToShow}
                </Typography> */}

            </DialogContent>

            <DialogActions className={classes.actionsFlexRight}>

                <UnoButton
                    float="right"
                    onClick={() => handleAction(true)} color="primary">
                    {'Validar e lançar'}
                </UnoButton>
                <UnoButton
                    float="right"
                    onClick={() => handleAction(false)} color="secondary">
                    {'Cancelar'}
                </UnoButton>
            </DialogActions>




        </Dialog >
    );
}