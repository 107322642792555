import { Typography } from '@material-ui/core';
import React from 'react';

export default function CPF_AND_NAME_TEXT({ searchColumnTitle }) {
  return (
    <Typography align="left">
      O campo de pesquisa detecta automaticamente o tipo entrada que você está inserindo.
      <Typography>
        A busca pode ser realizada por <strong>Nome</strong> ou por <strong>CPF</strong>, baseando-se na coluna <em>{searchColumnTitle.toUpperCase()}</em> na tabela abaixo.
      </Typography>
    </Typography>
  );
};