import React, { useEffect, createRef, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";

/*
* Mask options
* [onlyNumbers] - apenas números podem ser inseridos no campo
*/

const useStyles = makeStyles((theme) => ({
    defaultStyle: myInput,
    disabled: {
        background: '#eeeeee',
        '& .MuiOutlinedInput-root': {
            background: "#eeeeee !important",
        },
    },

    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    outerLabelRight: {
        textAlign: 'right',
    },
    labelError: {
        color: 'red',
    },
    noMarginTop: {
        marginTop: '5px'
    },
    verySmall: {
        width: "100%",
        '& .MuiInputBase-input': {
            borderRadius: '6px !important',
            padding: '6px 10px',
            fontSize: '12px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '6px !important',
            background: unoTheme.defaultInputBackground,
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0px',
        }
    }


}));

export default function MyTextFieldRef(props) {

    const {
        className,
        validator,
        helperText,
        onChange,
        onBlur,
        mask,
        endadornment,
        startadornment,
        withRightBtn,
        outerlabel,
        labelClassName,
        align,
        disabled,
        outerlabelright,
        size,
        verysmall,
    } = props

    const classes = useStyles();

    function applyMask(mask, value) {

        if (mask) {
            if (mask.includes("onlyNumbers")) {

                value = value.replace(/[^0-9]+/g, "");
                console.log("ONLY NUMBERS: ", value)

                return value;

            } else if (mask.includes("cep")) {

                value = value.replace(/[^0-9]+/g, "");

                if (value.length > 8) {
                    value = value.substring(0, 8);
                }

                let resultValue = ""

                if (value.length <= 5) {

                    resultValue = value;

                } else if (value.length >= 6) {
                    resultValue += value.substring(0, 5);
                    resultValue += "-";
                    resultValue += value.substring(5);
                }

                return resultValue;

            } else if (mask.includes("cpf")) {

                value = value.replace(/[^0-9]+/g, "");
                if (value.length > 11) {
                    value = value.substring(0, 11);
                }

                let resultValue = ""

                if (value.length <= 2) {
                    resultValue = value;
                } else if (value.length == 3) {
                    resultValue = value;
                } else if (value.length == 4) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 4);
                } else if (value.length == 5) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 5);
                } else if (value.length == 6) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 6);
                } else if (value.length == 7 || value.length == 8) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 6);
                    resultValue += ".";
                    resultValue += value.substring(6);
                } else if (value.length == 9) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 6);
                    resultValue += "."
                    resultValue += value.substring(6, 9);
                } else if (value.length > 9) {
                    resultValue = value.substring(0, 3);
                    resultValue += ".";
                    resultValue += value.substring(3, 6);
                    resultValue += ".";
                    resultValue += value.substring(6, 9);
                    resultValue += "-";
                    resultValue += value.substring(9, 11);
                }

                return resultValue;

            } else if (mask.includes("cnpj")) {

                //97.022.030/0001-30
                value = value.replace(/[^0-9]+/g, "");

                let resultValue = "";

                if (value.length <= 2) {
                    resultValue = value;
                } else if (value.length <= 5) {
                    resultValue = value.substring(0, 2);
                    resultValue += ".";
                    resultValue += value.substring(2);
                } else if (value.length <= 8) {
                    resultValue = value.substring(0, 2);
                    resultValue += ".";
                    resultValue += value.substring(2, 5);
                    resultValue += ".";
                    resultValue += value.substring(5);
                } else if (value.length <= 12) {
                    resultValue = value.substring(0, 2);
                    resultValue += ".";
                    resultValue += value.substring(2, 5);
                    resultValue += ".";
                    resultValue += value.substring(5, 8);
                    resultValue += "/";
                    resultValue += value.substring(8);
                } else if (value.length <= 14) {
                    resultValue = value.substring(0, 2);
                    resultValue += ".";
                    resultValue += value.substring(2, 5);
                    resultValue += ".";
                    resultValue += value.substring(5, 8);
                    resultValue += "/";
                    resultValue += value.substring(8, 12);
                    resultValue += "-";
                    resultValue += value.substring(12);
                } else if (value.length > 14) {
                    resultValue = value.substring(0, 2);
                    resultValue += ".";
                    resultValue += value.substring(2, 5);
                    resultValue += ".";
                    resultValue += value.substring(5, 8);
                    resultValue += "/";
                    resultValue += value.substring(8, 12);
                    resultValue += "-";
                    resultValue += value.substring(12, 14);
                }

                return resultValue;

            } else if (mask.includes("phone")) {

                // if (value.length > 11) {
                //     value = value.substring(0, 11);
                // }

                value = value.replace(/[^0-9]+/g, "");

                let resultValue = ""

                //(85) 3229-0242
                //(85) 9 3229-0242

                if (value.length <= 2) {

                    resultValue += "(";
                    resultValue += value;

                } else if (value.length <= 6) {

                    resultValue += "(";
                    resultValue += value.substring(0, 2);
                    resultValue += ") " + value.substring(2);

                } else if (value.length <= 10) {

                    resultValue += "(";
                    resultValue += value.substring(0, 2);
                    resultValue += ") " + value.substring(2, 6);
                    resultValue += "-" + value.substring(6);

                } else if (value.length == 11) {
                    resultValue += "(";
                    resultValue += value.substring(0, 2);
                    resultValue += ") " + value.substring(2, 3) + " ";
                    resultValue += value.substring(3, 7);
                    resultValue += "-" + value.substring(7);
                } else if (value.length > 11) {
                    resultValue += "(";
                    resultValue += value.substring(0, 2);
                    resultValue += ") " + value.substring(2, 3) + " ";
                    resultValue += value.substring(3, 7);
                    resultValue += "-" + value.substring(7, 11);
                }

                return resultValue;
            } else if (mask.includes("currency")) {

                value = value.replace(/[^0-9]+/g, "");
                console.log(VMasker.toMoney(value))

                return VMasker.toMoney(value);
            }
        }

        return value;

    }

    const [value, setValue] = React.useState("");
    const valueRef = useRef();

    const doOnBlur = e => {
        setValue(valueRef.current.value);
        onBlur();
    };

    return (
        <>
            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={[
                            classes.label,
                            labelClassName,
                            validator ? classes.labelError : "",
                            outerlabelright == 'true' ? classes.outerLabelRight : ""
                        ].join(' ')}>
                        {outerlabel}
                    </Typography> : null
            }

            <TextField
                //focused={validator}
                {...props}
                className={[
                    classes.defaultStyle,
                    disabled ? classes.disabled : "",
                    withRightBtn ? classes.withRightBtn : "",
                    align ? classes[align] : classes.left,
                    outerlabel ? classes.noMarginTop : "",
                    verysmall ? classes['verySmall'] : classes['small'],
                    className,
                ].join(' ')}
                ref={valueRef}
                error={validator}
                helperText={validator ? helperText : ""}
                InputProps={{
                    endAdornment: endadornment,
                    startAdornment: startadornment,
                }}
                onBlur={(e) => onBlur ? doOnBlur() : null}
                onChange={(e) => onChange ? mask ? onChange(applyMask(mask, e.target.value)) : onChange(e.target.value) : null}
            />
        </>
    )
}