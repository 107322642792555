import moment from 'moment';
import {
    saveFundComposition, getFundCompositionByFundId, getFundOpenedPortfolioByFundId, getFundFullCompositionByFundId, saveFullFundComposition
} from "../API/funds_compositions"
import {
    formatPtStringDate,
    formatPtStringDateToAnbimaDate,
    getMonthAgo,
    getDayMonthsAgo,
    formatAnbimaDateToPt, getRentMonthAsset,
    getDayMonthYearByDate
} from '../components/utils/utils'

import {
    doGetFundCompositionByCnpj, doGetFundFullCompositionByCnpj
} from '../controllers/ComdinheiroController'

import _ from 'lodash'

export async function doSaveFundComposition(fund, composition, isFull) {

    const { month, year } = getDayMonthYearByDate(new Date());

    const fundComposition = {
        fund_id: fund.fund_id,
        json_composition: JSON.stringify(composition),
        month: month,
        year: year,
        is_full: isFull ?? false
    }

    console.log("LENGTH COMPOSITION: ", fundComposition.json_composition.length);

    if (isFull) {
        saveFullFundComposition(fundComposition)
    } else {
        saveFundComposition(fundComposition);
    }
}


export async function doGetFundComposition(fund, isFull) {

    const response = isFull ?
        await getFundFullCompositionByFundId(fund.fund_id, isFull)
        :
        await getFundCompositionByFundId(fund.fund_id, isFull);


    //Caso em que existe a informação localmente
    if (response.success && response.body && response.body.rows.length == 1) {

        return response.body.rows[0];

    } else if (response.success && response.body && response.body.rows.length == 0) {

        //Obter informação da API 
        // const composition = isFull ?
        //     await doGetFundFullCompositionByCnpj(fund.cnpj)
        //     :
        //     await doGetFundCompositionByCnpj(fund.cnpj)

        // if (composition) {
        //     doSaveFundComposition(fund, composition, isFull);
        // }

        // return composition;

        return null;

    }
}


