import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PrintIcon from '@material-ui/icons/Print';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useEffect, useRef, useState } from "react";
import MyTable from '../../utils/MyTable';
import { MyTab, MyTabs } from "../../utils/MyTabs";

//Import excel
import { getJsDateFromExcel } from 'excel-date-to-js';
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../assets/styles/unoTheme';

import moment from 'moment';
import UnoButton from "../../utils/UnoButton";
import { applyMaskAccount, applyMaskAmount, getConsultingId, getMonthName, isFileForClientOn } from "../../utils/utils";


import { getClientAprData } from "../../../API/client";
import { checkDuplicateSingleTransaction, createSingleTransaction, getSingleAprsByDateRangeAndRegime } from "../../../API/transaction";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { getFundByCnpj } from "../../../API/fund";
import DataImportModal from "../../modals/DataImportModal";
import UploadTransactionsModal from "../../modals/UploadTransactionsModal";
import { codsInnerLoad } from "../../utils/MyInnerLoad";
import { doClientDataBaseByYear } from '../../../controllers/ReportController';
import { checkUserIsRole, roles } from '../../utils/user';


//Exibição de ícone de ordenação das colunas
function showColumnOrderIcon(column, order) {
    if (column != order.column) {
        return
    }
    let style = { verticalAlign: 'bottom' };
    if (order.direction == 'ASC') {
        return <ArrowDropDown style={style} />
    } else {
        return <ArrowDropUp style={style} />
    }

}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    },

    tabs: tabs,

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '16px',
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    ok: {
        color: unoTheme.greenMoney,

        fontSize: '1.2em',

    },
    pendent: {
        color: unoTheme.warningColor,

        fontSize: '1.2em',

    },

});

export default function ToolsScreen(props) {

    const { selectedYearSingleAPRS } = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)
    const currentUser = useSelector(state => state.currentUser);

    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [accountRegimeId, setAccountRegimeId] = useState(undefined);
    const [regimeOnScreen, setRegimeOnScreen] = useState(undefined);
    const [yearOnScreen, setYearOnScreen] = useState(undefined);
    const [resultAmount, setResultAmount] = useState(0);
    const [transactionsToShow, setTransactionsToShow] = React.useState(undefined);
    const [clientAprData, setClientAprData] = React.useState(undefined);

    //Import Excel
    const inputFile = useRef(null);
    const [file, setFile] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [dataListFormatted, setDataListFormatted] = useState(undefined);
    const [dataToInsert, setDataToInsert] = useState(undefined);
    const [showImportModal, setShowImportModal] = useState(false);

    const [transactionsToUpload, setTransactionsToUpload] = useState(false);
    const [openUploadTransactionsModal, setOpenUploadTransactionsModal] = useState(false);

    function resetImportVariables() {

        console.log("Reset")
        setFile(undefined);
        setData(undefined);
        setDataListFormatted(undefined);

    }

    function handleModalImport(doImport) {

        setShowImportModal(false)
        resetImportVariables();
        if (doImport) {
            doInserts(dataToInsert);
        }
    }

    function handleChangeFile(e) {

        console.log("Change file")
        const files = e.target.files;
        console.log("FILES: ", files[0]);
        if (files && files[0]) {
            setFile(files[0])
        }

        e.target.value = null;

    };

    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);
        if (newValue === 1) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }

    };

    const [orderTable, setOrderTable] = React.useState({
        column: 'transaction_date',
        direction: 'ASC',
    });

    async function getSingleTransactionsByDateRange(clientId, startDate, endDate, regimeId, order) {

        //let response = await getClientAllTransactionByDateRange(accounts, startDate, endDate);
        console.log("CONSULTA CLIENTE: ", clientId);
        console.log("CONSULTA START DATE: ", startDate);
        console.log("CONSULTA END DATE: ", endDate);
        //let response = await getClientAllTransactionByDateRange(clientId, startDate, endDate);
        let response = await getSingleAprsByDateRangeAndRegime(clientId, startDate, endDate, regimeId, order);

        if (response.success) {

            console.log("APRS LENGTH: ", response.body.rows.length);
            console.log('response.body.rows: ', response.body.rows);

            setResultAmount(sumResultAmount(response.body.rows));

            setTransactionsToShow(response.body.rows);

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter transações"
            }))
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: response.success && !response.body.rows.length ? true : false,
            emptyMessage: response.success && !response.body.rows.length ? 'Nenhuma movimentação para o período selecionado' : null
        }));

    }

    async function loadData(clientId, year, regimeId, order) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Consultando aprs...',
        }));

        const response = await getClientAprData(clientId);
        console.log("RESPONSE APR DATA: ", response);

        //console.log("MONTH: ", month);
        console.log("year: ", year);

        if (response.success) {

            //Caso não haja rows ainda não foi salvo dados para a apr desse cliente
            if (response.body.rows.length == 1) {
                setClientAprData(response.body.rows[0])
            } else {
                setClientAprData(undefined)
            }

            const firstDay = new Date(year, 0, 1); //pegar o ano todo desde janeiro
            const lastDay = new Date(year, 12, 0); //pegar o ano todo até dezembro

            //Verificando se existem contaas
            if (firstDay != null
                && lastDay != undefined) {

                getSingleTransactionsByDateRange(
                    clientId,
                    moment(firstDay).format("DD/MM/YYYY"),
                    moment(lastDay).format("DD/MM/YYYY"), regimeId, order)
            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao obter dados da APR"
            }))

        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));


    }

    function sumResultAmount(transactions) {

        let resultAmount = 0.0;
        transactions.forEach(element => {
            if (element.operation_id == 1) {
                resultAmount += parseFloat(element.amount);
            } else if (element.operation_id == 2) {
                resultAmount -= parseFloat(element.amount);
            }

        });

        return resultAmount;
    }

    function handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);

            console.log("FILE CONVERTIDO");

            /* Update state */
            //setData([])
            //console.log("DATA: ", data);
            setData(data);
            setFile(undefined);

        };

        if (rABS) {
            //reader.readAsBinaryString(this.state.file);
            reader.readAsBinaryString(file);
        } else {
            //reader.readAsArrayBuffer(this.state.file);
            reader.readAsArrayBuffer(file);
        };
    }

    function doClickinputFile() {
        inputFile.current.click()
    }

    function verifyColumnsExcel(transactionLoop) {

        console.log("TESTE DE COLUNAS: ", transactionLoop);
        let invalidColumns = [];
        if (!transactionLoop['CC']) {
            invalidColumns.push('CC');
        }
        if (!transactionLoop['CNPJ']) {
            invalidColumns.push('CNPJ');
        }
        if (!transactionLoop['DATA']) {
            invalidColumns.push('DATA');
        }
        if (!transactionLoop['TIPO']) {
            invalidColumns.push('TIPO');
        }
        if (!transactionLoop['VALOR']) {
            invalidColumns.push('VALOR');
        }
        //Removido devido o caso em que existem resgates automáticos
        //A quantidade de cotas não é informada
        //Caso encontrado no cliente João Pessoa

        // if (!transactionLoop['QUANTIDADE']) {
        //     invalidColumns.push('QUANTIDADE');
        // }

        return invalidColumns;
    }

    async function doPreInserts(transactions, invalidColumns, invalidDates) {

        console.log("Transactions: ", transactions);

        const mapFunds = {};
        const newTransactionsToInsert = [];
        const duplicates = [];
        for (let index = 0; index < transactions.length; index++) {

            const transactionLoop = transactions[index];
            const cnpj = transactionLoop['CNPJ'];

            console.log('mapFunds: ', mapFunds);

            if (!mapFunds[cnpj]) {

                console.log("BUSCANDO CNPJ: ", cnpj);
                const responseFund = await getFundByCnpj(cnpj);
                console.log('responseFund: ', responseFund);
                if (responseFund && responseFund.body && responseFund.body.rows.length) {
                    mapFunds[cnpj] = responseFund.body.rows[0];
                }
            }

            const transactionFormatted = {

                client_id: clientOn.client.id,
                fund_id: mapFunds[cnpj].id,
                segregation: transactionLoop['SEGREGACAO'],
                fund_name: mapFunds[cnpj].fund_name,
                number_account: transactionLoop['CC'],
                operation_id: transactionLoop['TIPO'],
                operation: transactionLoop['TIPO'] == 1 ? 'APLICAÇÃO' : (transactionLoop['TIPO'] == 2 ? 'RESGATE' : 'AMORTIZAÇÃO'),
                amount: transactionLoop['VALOR'],
                description: "",
                transaction_date: transactionLoop['dataFormatada']

            };

            const responseCheckDuplicateTransaction = await checkDuplicateSingleTransaction(transactionFormatted);

            if (!responseCheckDuplicateTransaction) {
                newTransactionsToInsert.push(transactionFormatted);
            } else {
                duplicates.push(transactionFormatted);
            }


        };

        console.log('duplicates: ', duplicates);
        setTransactionsToUpload(newTransactionsToInsert);
        setOpenUploadTransactionsModal(true);
        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
            message: ""
        }))

    }

    async function doInserts(data) {

        let allSuccess = true;

        const transactionsToInsert = data

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Cadastrando Movimentações..."
        }))

        console.log("TRANSACTIONS TO INSERT: ", transactionsToInsert);

        for (let i = 0; i < transactionsToInsert.length; i++) {

            const transactionLoop = transactionsToInsert[i];

            //console.log("Incluindo: ", transactionLoop)
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando movimentações... " + parseFloat((i + 1) / transactionsToInsert.length * 100).toFixed(2) + "%"
            }))

            let responseInsertTransaction = await createSingleTransaction(transactionLoop);

            if (!responseInsertTransaction.success) {
                allSuccess = false;
            }
            console.log("Sucesso: ", responseInsertTransaction.success)

        }

        if (allSuccess) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Movimentações cadastradas com sucesso!"
            }))
        } else {
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Erro ao cadastrar uma ou mais movimentações"
            }))
        }

        setData(undefined)
        setDataListFormatted(undefined)

        //console.log(getAssetInfos());
        //getClientAccountsAndTransactions(clientOn.client.id, clientOn.client.selectedMonth, clientOn.client.selectedYear);
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...',
            cod: codsInnerLoad.toolsScreen
        }));

        loadData(clientOn.client.id,
            clientOn.client.selectedAdministrativeMonth,
            clientOn.client.selectedAdministrativeYear, accountRegimeId);
    }

    function handleAction(action) {

        setOpenUploadTransactionsModal(false);

        if (action) {
            doInserts(transactionsToUpload);

        }

    }

    function doReportBatchAprs() {

        let allSave = true;
        if (transactionsToShow) {
            for (let i = 0; i < transactionsToShow.length; i++) {

                const transactionRow = transactionsToShow[i];
                if (!(transactionRow.apr_description_id || (transactionRow.apr_description != "" && transactionRow.apr_description != null))) {
                    allSave = false;
                    break;
                }

            }

            //Pode-se realizar a geração de aprs em lote
            if (allSave) {

                dispatch(allActions.reportLoadActions.setReportLoad({
                    show: true,
                    reportType: "batchAprs",
                    order: orderTable,
                    yearToSinglesAprs: selectedYearSingleAPRS,
                    message: 'Gerando lote de APRS...'
                }))

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    message: 'Todas as APRS devem ser preenchidas e salvas para a geração em lote',
                    type: 'warning',
                    show: true,
                }))
            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                message: 'Não existem APRS a serem geradas',
                type: 'warning',
                show: true,
            }))

        }


        return;


    }

    async function doClientDataBaseReport(clientId, clientLabelName, year) {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: 'Gerando o base de dados...'
        }))

        await doClientDataBaseByYear(clientId, clientLabelName, year);

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))

    }

    useEffect(() => {

        if (tabValue === 0) {

            console.log("UseEffect: ");

            if (clientOn && clientOn.client
                && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

                console.log("ENTRANDO AQUI 1");

                setTransactionsToShow(undefined)

                setClientOnScreen(Object.assign({}, clientOn.client));
                setRegimeOnScreen(accountRegimeId);
                setYearOnScreen(selectedYearSingleAPRS);

                if (selectedYearSingleAPRS) {

                    // loadData(clientOn.client.id,
                    //     clientOn.client.selectedAdministrativeMonth,
                    //     clientOn.client.selectedAdministrativeYear, accountRegimeId);
                    loadData(clientOn.client.id,
                        selectedYearSingleAPRS, accountRegimeId);

                } else {

                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: false,
                    }));

                }


                dispatch(allActions.rightContentActions.setRightContent(null))

            } else if (rightContent && rightContent.action
                || accountRegimeId != regimeOnScreen) {


                console.log("ENTRANDO AQUI 2");

                //setTransactions(undefined)
                //setListAssetsWithTransactions(undefined);
                setTransactionsToShow(undefined)
                setRegimeOnScreen(accountRegimeId);
                setYearOnScreen(selectedYearSingleAPRS);

                loadData(clientOn.client.id,
                    selectedYearSingleAPRS, accountRegimeId);

                dispatch(allActions.rightContentActions.setRightContent(null))
            } else if (clientOn && clientOn.client && clientOnScreen
                && clientOn.client.id == clientOnScreen.id
                && (
                    selectedYearSingleAPRS != yearOnScreen
                )
            ) {


                console.log("ENTRANDO AQUI 3");

                //setTransactions(undefined)
                //setListAssetsWithTransactions(undefined);
                setTransactionsToShow(undefined)
                setRegimeOnScreen(accountRegimeId);
                setYearOnScreen(selectedYearSingleAPRS);

                setClientOnScreen(Object.assign({}, clientOn.client));

                loadData(clientOn.client.id,
                    selectedYearSingleAPRS, accountRegimeId);

            }

            //Inicio import Excel
            if (file) {

                console.log("HANDLE FILE")
                if (clientOn && clientOn.client) {

                    const responseValidFile = isFileForClientOn(file, clientOn.client, 'MOVS')
                    console.log('responseValidFile: ', responseValidFile);
                    if (responseValidFile.success) {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: true,
                            message: "Validando planilha..."
                        }))

                        handleFile();

                    } else {

                        setDataToInsert({

                            client: clientOn.client,
                            currentFileName: file.name,
                            rightFileName: responseValidFile.rightName + '.xlsm',


                        });

                        setShowImportModal(true);
                    }
                }

            }

            //if (data && data.length > 0 && !dataListFormatted && importYear) {
            if (data && data.length > 0 && !dataListFormatted) {

                console.log("DATA: ", data);

                let dataLFormatted = [];
                let invalidColumns = [];
                let invalidDates = [];
                for (let i = 0; i < data.length; i++) {

                    //console.log("Convertendo: ", data[i]);

                    //Verificando se há alguma coluna invalida
                    if (i === 0) {
                        invalidColumns = verifyColumnsExcel(data[i]);
                        if (invalidColumns.length > 0) {
                            break;
                        }
                    }

                    if (data[i]['DATA'] != undefined && data[i]['DATA'] != null && data[i]['DATA'] != "") {
                        //console.log("data[i]['DATA']: ", data[i]['DATA']);
                        if (Number.isInteger(data[i]['DATA'])) {
                            data[i].dataFormatada = moment.utc(getJsDateFromExcel(data[i]['DATA'])).format('DD/MM/YYYY');

                            dataLFormatted.push(data[i]);
                        } else {

                            invalidDates.push(data[i]['DATA']);
                            console.log("INVALID DATES: ", invalidDates);
                            //data[i].dataFormatada = data[i]['DATA'];
                        }


                    }
                    //data[i].dataFormatada = moment.utc(getJsDateFromExcel(data[i].DataOperacao)).add(1, 'days').format('DD/MM/YYYY');
                    //delete data[i]['DATA'],                

                }

                //console.log(dataMonthFormatted);
                console.log("MOVIMENTAÇÕES VÁLIDAS: ", dataLFormatted);

                setDataListFormatted({
                    invalidDates: invalidDates,
                    data: dataLFormatted,
                    invalidColumns: invalidColumns
                });

            } else if (data && data.length == 0) {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                    message: ""
                }))

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: "A planilha não possui dados"
                }))

            }

            if (dataListFormatted
                && clientOnScreen.id == clientOn.client.id) {

                //doInsertsTransactions(dataListFormatted);
                doPreInserts(dataListFormatted.data, dataListFormatted.invalidColumns, dataListFormatted.invalidDates);
            }
            //Fim import excel
        }


    }, [clientOn,
        file,
        dataListFormatted,
        rightContent, selectedYearSingleAPRS]);


    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={classes.tabs}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        <MyTab label="APRs" />
                        <MyTab label="Base de dados" disabled={
                            getConsultingId(currentUser) != 1
                            ||
                            !checkUserIsRole(currentUser, [roles.superadmin, roles.admin])
                        } />
                        <MyTab label="-" disabled />

                    </MyTabs>

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>

                <div className={classes.rightHeader}>

                    {
                        //Exibido apenas no menu de APRS
                        tabValue == 0 ?
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>

                                {transactionsToShow && transactionsToShow.length ? <UnoButton
                                    style={{ marginRight: '16px' }}
                                    size={'small'}
                                    onClick={() => doReportBatchAprs()}
                                    type='primary'
                                    startIcon={<PrintIcon />}>
                                    {"Gerar lote"}
                                </UnoButton> : null}


                                {!currentUser?.user?.isViewer && <UnoButton
                                    size={'small'}
                                    onClick={() => doClickinputFile()}
                                    type='success'
                                    startIcon={<PublishIcon />}>
                                    {"Importar Movimentações"}
                                </UnoButton>}

                            </div> : null
                    }

                </div>


            </div>

            {/* APRS */}
            {
                tabValue === 0 &&
                <div class={'defaultScrollWithHeaderContent'}>
                    <MyTable
                        stickyHeader
                        selectable="true"
                        size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="30%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="15%" />
                            <col width="10%" />
                            {/* <col width="5%" /> */}
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'
                                //onClick={() => handleOrderTable({ column: 'transaction_date', direction: 'ASC' })}
                                >Data
                                    {/* {showColumnOrderIcon('transaction_date', orderTable)} */}
                                </TableCell>
                                <TableCell align='left'>Fundo</TableCell>
                                <TableCell align='center'>Operação</TableCell>
                                <TableCell align="center">Conta</TableCell>
                                <TableCell align='center'
                                //onClick={() => handleOrderTable({ column: 'number_apr', direction: 'ASC' })}
                                >Num.
                                    {/* {showColumnOrderIcon('number_apr', orderTable)} */}
                                </TableCell>
                                <TableCell align='right'>Valor</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        {transactionsToShow ?
                            <TableBody>
                                {transactionsToShow.map((transactionRow, index) => (
                                    <TableRow
                                        className={classes.tableRow}
                                        key={'apr-transaction-' + index}
                                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                            path: 'newApr',
                                            data: {
                                                clientAprData: clientAprData,
                                                isSingleTransaction: true,
                                                transactionSelected: transactionRow,
                                            },
                                        }))}>
                                        <TableCell align="center">
                                            {moment.utc(transactionRow.transaction_date).format("DD/MM/YYYY")}
                                        </TableCell>
                                        <TableCell align="left">{transactionRow.fund_name}</TableCell>
                                        <TableCell align="center" className={
                                            transactionRow.operation == 'Aplicação' ? classes.green : (
                                                transactionRow.operation == 'Resgate' ? classes.red : classes.blue
                                            )
                                        }>{transactionRow.operation}</TableCell>
                                        <TableCell align="center">{applyMaskAccount(transactionRow.number_account)}</TableCell>
                                        <TableCell align="center" style={{ color: 'blue' }}>{transactionRow.number_apr ? transactionRow.number_apr : '-'}</TableCell>
                                        <TableCell align="right" className={
                                            transactionRow.operation == 'Aplicação' ? classes.green : (
                                                transactionRow.operation == 'Resgate' ? classes.red : classes.blue
                                            )
                                        }>{applyMaskAmount(transactionRow.amount, true)}</TableCell>
                                        <TableCell
                                            align="center">
                                            {
                                                transactionRow.apr_description_id || (transactionRow.apr_description != "" && transactionRow.apr_description != null) ?

                                                    <CheckCircleIcon className={classes.ok} style={{ verticalAlign: 'middle' }} />
                                                    :
                                                    <ReportProblemIcon className={classes.pendent} style={{ verticalAlign: 'middle' }} />
                                            }

                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                            :
                            null
                        }


                    </MyTable>

                </div>
            }

            {/* BASE DE DADOS */}
            {
                tabValue === 1 &&
                <div class={'defaultScrollWithHeaderContent'}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "16px" }}>

                        <Typography>{`${clientOn?.client?.label_name ?? "<Selecione o cliente>"} [${selectedYearSingleAPRS}]`}</Typography>
                        <UnoButton
                            size={'small'}
                            onClick={() => doClientDataBaseReport(clientOn?.client?.id, clientOn?.client?.label_name, selectedYearSingleAPRS)}
                            type='success'
                            startIcon={<PrintIcon />}>
                            {`Exportar base dados`}
                        </UnoButton>
                    </div>
                </div>
            }


            <div>
                <input
                    ref={inputFile}
                    style={{ display: 'none' }}
                    id="file"
                    multiple
                    accept={SheetJSFT}
                    type="file"
                    onClick={() => resetImportVariables()}
                    onChange={handleChangeFile}
                />
            </div>

            {
                openUploadTransactionsModal && clientOnScreen ?
                    <UploadTransactionsModal
                        open={openUploadTransactionsModal}
                        transactionsToUpload={transactionsToUpload}
                        clientOnScreen={clientOnScreen}
                        handleAction={handleAction}>

                    </UploadTransactionsModal>
                    : null
            }

            <DataImportModal
                dataToInsert={dataToInsert}
                handleModalImport={(doImport) => handleModalImport(doImport)}
                open={showImportModal} />
        </div>
    );
}



