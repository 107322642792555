import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Paper, Tooltip, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme, toogleButton } from "../../../assets/styles/unoTheme";

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

//Redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import {
    getArrayMonthsWithNames, applyMaskAmount,
    applyMaskAccount, parseFloatByPtCurrency,
    applyCurrencyMask,
    isDateAfterClosedMonth,
    isDateBeforeInit,
    formatAnbimaDateToPt
} from "../../utils/utils";

//API
import { updateTransaction, createTransaction, deleteTransaction } from "../../../API/transaction";
import { getFundClientAccountList } from "../../../API/fund";
import { getTitleClientAccountList } from "../../../API/title";

import InputAdornment from '@material-ui/core/InputAdornment';

//RadioGroup
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//MyInputs
import MyTextField from "../../utils/MyTextField";
import MySelect from '../../utils/MySelect'
import MyRadioGroup from '../../utils/MyRadioGroup'
import MyDatePicker from '../../utils/MyDatePicker'

import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import _ from 'lodash';
import moment from 'moment'
import MyInfo from "../../utils/MyInfo";

import ConfirmActionRightContent from './ConfirmActionRightContent'

import {
    doCheckHasCotaDayFund
} from "../../../controllers/FundLocalQuotasController";
import { checkUserIsRole, roles } from "../../utils/user";


const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        background: unoTheme.hoverTable
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px',
            },
        },
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '12px',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    warning: {
        color: unoTheme.warningColor
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    toogleButton: toogleButton,


}));

export default function NewTransaction(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    //const [edittingIndex, setEdittingIndex] = useState(undefined);
    const [confirmingAction, setConfirmingAction] = useState(false);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [fundClientAccounts, setFundClientAccounts] = useState([]);


    //form
    const [inputFundNewTransaction, setInputFundNewTransaction] = React.useState(0);
    const [inputAccountNewTransaction, setInputAccountNewTransaction] = React.useState(undefined);
    const [inputOperationNewTransaction, setInputOperationNewTransaction] = React.useState(1);
    const [inputDateNewTransaction, setInputDateNewTransaction] = useState(new Date());
    const [inputAmountNewTransaction, setInputAmountNewTransaction] = useState("");
    const [inputQuotasNewTransaction, setInputQuotasNewTransaction] = useState("");

    //Title
    const [inputTitleNewTransaction, setInputTitleNewTransaction] = React.useState(0);
    const [inputAssetTitleNewTransaction, setInputAssetTitleNewTransaction] = React.useState(0);
    const [titleClientAccounts, setTitleClientAccounts] = useState([]);

    const [option, setOption] = useState('fund');
    const handleSelect = (event, newOption) => {

        if (newOption && newOption.length) {

            console.log("NEW OPTION: ", newOption);

            if (newOption === 'fund') {

                setInputFundNewTransaction(0);
                setInputOperationNewTransaction(1);
                loadFundAccounts(content.data.funds[0].id,
                    clientOn.client.id, undefined);

            } else {

                setInputTitleNewTransaction(0);
                setInputOperationNewTransaction(3);

            }

            setOption(newOption);
        }


    };

    async function doCreateFundTransaction() {

        setValidationMessage('')
        setOpenBackdrop(true);

        console.log("INPUT: ", inputAccountNewTransaction);

        if (inputAccountNewTransaction == undefined) {

            setOpenBackdrop(false);
            setValidationMessage("Uma conta deve ser selecionada")

            //Verificando se não está tentando criar uma movimentação para um mês já fechado  
        } else {
            // console.log("Fund: ", content.data.funds);
            // console.log("Input: ", inputFundNewTransaction)
            // console.log("fundClientAccounts: ", fundClientAccounts)
            // console.log("inputAccountNewTransaction: ", inputAccountNewTransaction)

            let filteredAssets = content.data.assets.filter(el =>
                el.fund_id == content.data.funds[inputFundNewTransaction].id
                && el.account_id == fundClientAccounts[inputAccountNewTransaction].id)

            let transactionToCreate = {}

            if (filteredAssets && filteredAssets.length > 0) {
                transactionToCreate = {
                    asset_id: filteredAssets[0].id,
                    operation_id: inputOperationNewTransaction,
                    amount: parseFloatByPtCurrency(inputAmountNewTransaction),
                    //transaction_date: inputDateNewTransaction,
                    transaction_date: moment.utc(inputDateNewTransaction).format("DD/MM/YYYY"),
                }
            }

            console.log("TRANSACTION TO CREATE: ", transactionToCreate)

            console.log(transactionToCreate.transaction_date.substring(3));
            console.log(clientOn.client.portfolio_init.substring(3));

            let portfolioInit = moment.utc(clientOn.client.portfolio_init).format("DD/MM/YYYY");

            if (transactionToCreate.transaction_date == 'Invalid date') {

                setOpenBackdrop(false);
                setValidationMessage('Data inválida!');

            } else if (!isDateAfterClosedMonth(
                transactionToCreate.transaction_date,
                clientOn.client.portfolio_closed)) {

                setOpenBackdrop(false);
                setValidationMessage('A carteira já foi fechada para a data em que se deseja realizar a movimentação!');

            } else if (isDateBeforeInit(transactionToCreate.transaction_date,
                clientOn.client.portfolio_init)) {

                setOpenBackdrop(false);
                setValidationMessage('A data da movimentação deve ser a superior ao último dia do mês de ínicio da carteira do cliente ( ' + portfolioInit + ' )!');

            } else {

                //Verificando se existe cota para esse dia de transação 
                if (transactionToCreate.operation_id !== 3
                    && !filteredAssets[0].is_poupanca
                    && !checkUserIsRole(currentUser, [roles.superadmin, roles.admin])) { //retirando checagem caso seja amortização //Caso seja poupança também não necessita de teste
                    const hasCota = await doCheckHasCotaDayFund(content.data.funds[inputFundNewTransaction].id, transactionToCreate.transaction_date);
                    if (!hasCota) {
                        setOpenBackdrop(false);
                        setValidationMessage('Não existe cota para esse fundo no dia [ ' + transactionToCreate.transaction_date + ' ]');
                        return;
                    }
                }

                let response = await createTransaction(transactionToCreate);
                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Movimentação criada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao criar movimentação!"
                    }))

                }

                setOpenBackdrop(false);
                dispatch(allActions.rightContentActions.update())

            }
        }

    }

    async function doCreateTitleTransaction() {

        setValidationMessage('')
        setOpenBackdrop(true);

        console.log("INPUT: ", inputAccountNewTransaction);

        // console.log("Funtitled: ", content.data.funds);
        // console.log("Input: ", inputFundNewTransaction)
        // console.log("fundClientAccounts: ", fundClientAccounts)
        // console.log("inputAccountNewTransaction: ", inputAccountNewTransaction)

        console.log('content.data.titleAssets: ', content.data.titleAssets);
        console.log('inputAssetTitleNewTransaction: ', inputAssetTitleNewTransaction);
        console.log('inputAccountNewTransaction: ', inputAccountNewTransaction);
        console.log('titleClientAccounts: ', titleClientAccounts);
        console.log('titleClientAccounts[inputAccountNewTransaction]: ', titleClientAccounts[inputAccountNewTransaction]);

        let transactionToCreate = {}

        if (inputAssetTitleNewTransaction != null
            && content.data.titleAssets[inputAssetTitleNewTransaction].id) {

            transactionToCreate = {
                title_asset_id: content.data.titleAssets[inputAssetTitleNewTransaction].id,
                operation_id: inputOperationNewTransaction,
                amount: parseFloatByPtCurrency(inputAmountNewTransaction),
                //transaction_date: inputDateNewTransaction,

                transaction_date: moment.utc(inputDateNewTransaction).format("DD/MM/YYYY"),
            }

            //caso o título possua liquidez e seja uma operação de resgate
            if (needQuotasTransaction()) {//resgate
                transactionToCreate.quota_value = parseFloatByPtCurrency(inputQuotasNewTransaction);
            }

            // console.log("TRANSACTION TO CREATE: ", transactionToCreate)

            // console.log(transactionToCreate.transaction_date.substring(3));
            // console.log(clientOn.client.portfolio_init.substring(3));

            let portfolioInit = moment.utc(clientOn.client.portfolio_init).format("DD/MM/YYYY");

            if (transactionToCreate.transaction_date == 'Invalid date') {

                setOpenBackdrop(false);
                setValidationMessage('Data inválida!');

            } else if (!isDateAfterClosedMonth(
                transactionToCreate.transaction_date,
                clientOn.client.portfolio_closed)) {

                setOpenBackdrop(false);
                setValidationMessage('A carteira já foi fechada para a data em que se deseja realizar a movimentação!');

            } else if (isDateBeforeInit(transactionToCreate.transaction_date,
                clientOn.client.portfolio_init)) {

                setOpenBackdrop(false);
                setValidationMessage('A data da movimentação deve ser a superior ao último dia do mês de ínicio da carteira do cliente ( ' + portfolioInit + ' )!');

            } else {

                //Verificando se existe cota para esse dia de transação 
                let response = await createTransaction(transactionToCreate);
                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Movimentação criada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao criar movimentação!"
                    }))

                }

                setOpenBackdrop(false);
                dispatch(allActions.rightContentActions.update())

            }
        } else {
            setOpenBackdrop(false);
            setValidationMessage('Não existem títulos para esse título ID e essa conta ID');
        }


    }

    async function doUpdateTransaction() {

        console.log("Option: ", option);

        setOpenBackdrop(true);
        //const oldDateTransaction = moment.utc(transactionToUpdate.transaction_date).format('DD/MM/YYYY');

        let transactionToUpdate = {
            id: content.transactionToUpdate.id,
            asset_id: option == 'fund' ? fundClientAccounts[inputAccountNewTransaction].asset_id : null,
            title_asset_id: option == 'title' ? content.data.titleAssets[inputAssetTitleNewTransaction].id : null,
            operation_id: inputOperationNewTransaction,
            amount: parseFloatByPtCurrency(inputAmountNewTransaction),
            transaction_date: moment.utc(inputDateNewTransaction).format("DD/MM/YYYY"),

            //Caso em que deve-se atualizar a data de compra do título
            // first_application_transaction: option == 'title' ?
            //     moment.utc(content.data.titleAssets[inputAssetTitleNewTransaction].purchase_date).format("DD/MM/YYYY") == oldDateTransaction
            //     : null,
            //transaction_date: inputDateNewTransaction,
        }

        //caso o título possua liquidez e seja uma operação de resgate
        if (needQuotasTransaction()) {//resgate
            transactionToUpdate.quota_value = parseFloatByPtCurrency(inputQuotasNewTransaction);
        }

        console.log("TRANSACTION TO UPDATE: ", transactionToUpdate)

        let response = await updateTransaction(transactionToUpdate);
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Movimentação atualizada com sucesso!"
            }))


        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao atualizar movimentação!"
            }))

        }

        setOpenBackdrop(false);
        dispatch(allActions.rightContentActions.update())

    }

    async function doDeleteTransaction() {

        setOpenBackdrop(true);

        let response = await deleteTransaction(content.transactionToUpdate.id);
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Movimentação excluída com sucesso!"
            }))


        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir movimentação!"
            }))

        }

        setOpenBackdrop(false);
        dispatch(allActions.rightContentActions.update())

    }

    async function loadFundAccounts(fundId, clientId, nowAccountId) {

        let response = await getFundClientAccountList(fundId, clientId);
        if (response.success) {


            console.log("Setando contas: ", response.body.rows)
            let accs = response.body.rows;
            setFundClientAccounts(accs);

            //No caso de ser um update se coloca o select na conta selecionada, 
            //no caso de não ser colaca-se para a primeira conta obtida
            if (nowAccountId) {

                for (let i = 0; i < response.body.rows.length; i++) {

                    let accountLoop = response.body.rows[i];
                    if (accountLoop.id == nowAccountId) {
                        setInputAccountNewTransaction(i);
                        break;
                    }

                }

            } else {
                console.log("SETANDO: ", response.body.rows.length)
                if (response.body.rows.length == 1) {

                    setInputAccountNewTransaction(0)

                } else {

                    setInputAccountNewTransaction(undefined)
                }

            }


            setOpenBackdrop(false);

        } else {
            console.log("ERRO AO TRAZER CONTAS DO ASSET")
        }



    }

    function doChangeFund(index) {

        setInputFundNewTransaction(index)
        loadFundAccounts(content.data.funds[index].id,
            clientOn.client.id, undefined);

    }

    //A movimentação não pode ser alterada caso a carteira já tenha sido fechada
    function unableToChange() {

        return !isDateAfterClosedMonth(moment.utc(new Date(clientOn.client.selectedYear, parseInt(clientOn.client.selectedMonth) - 1, 1)).format("DD/MM/YYYY"),
            clientOn.client.portfolio_closed,
            false)
    }

    function needQuotasTransaction() {

        if (content && content.data && content.data.titleAssets && content.data.titleAssets[inputAssetTitleNewTransaction]) {

            console.log('content?.data?.titleAssets[inputAssetTitleNewTransaction]: ', content?.data?.titleAssets[inputAssetTitleNewTransaction]);
        }

        // console.log('LALALA: ', {
        //     inputAssetTitleNewTransaction: inputAssetTitleNewTransaction,
        //     titleAssets: content.data.titleAssets,
        // })

        return option == 'title'
            && content
            && content.data
            && content.data.titleAssets
            && content.data.titleAssets[inputAssetTitleNewTransaction]
            && (!content.data.titleAssets[inputAssetTitleNewTransaction].with_liquidity)
            && (
                inputOperationNewTransaction == 2
                ||
                (inputOperationNewTransaction == 1 && content.data.titleAssets[inputAssetTitleNewTransaction].marking == "MERCADO")
            )
    }

    useEffect(() => {

        console.log("DATA NEW TRANSACTION: ", content)

        if (content.update) {

            setOpenBackdrop(true);

            //incicializando inputs
            setInputOperationNewTransaction(content.transactionToUpdate.operation_id)
            setInputDateNewTransaction(moment.utc(content.transactionToUpdate.transaction_date))
            setInputAmountNewTransaction(applyMaskAmount(content.transactionToUpdate.amount));
            setInputQuotasNewTransaction(content.transactionToUpdate.quota_value_transaction ? applyMaskAmount(content.transactionToUpdate.quota_value_transaction) : '');

            if (content && content.data && content.data.titleAssets) {
                const indexAssetTitleSelected = content.data.titleAssets.findIndex(el => el.id === content.transactionToUpdate.title_asset_id);
                if (indexAssetTitleSelected >= 0) {
                    setInputAssetTitleNewTransaction(indexAssetTitleSelected);
                }
            }

            if (content.transactionToUpdate.title_id) {
                setOption('title')
            }

            loadFundAccounts(content.transactionToUpdate.fund_id,
                clientOn.client.id,
                content.transactionToUpdate.account_id);

        } else {

            if (clientOn.client.portfolio_closed) {
                let splittedDate = clientOn.client.portfolio_closed.split('/');
                setInputDateNewTransaction(new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]), 1, 0, 0, 0, 0))
            } else {
                setInputDateNewTransaction(moment.utc(clientOn.client.portfolio_init))
            }

            //console.log("LOAD ACCOUNTS: ", content.data.funds[0].id)
            loadFundAccounts(content.data.funds[0].id,
                clientOn.client.id, undefined);

        }

    }, [])

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?

                    <>
                        <Paper square className={classes.header}>
                            <IconButton
                                className={!content.update || !content.data.path ? classes.hiddenIcon : ""}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(
                                    content.data,
                                ))}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>

                                    {content.update ? "Editar movimentação" : "Cadastrar Movimentação"}

                                </Typography>

                                {!content.update ?
                                    <ToggleButtonGroup
                                        style={{ marginTop: '8px' }}
                                        className={classes.toogleButton}
                                        exclusive value={option} onChange={handleSelect}>
                                        <ToggleButton value={'fund'}>
                                            {'Fundo'}
                                        </ToggleButton>
                                        <ToggleButton value={'title'}>
                                            {'Título Público'}
                                        </ToggleButton>

                                    </ToggleButtonGroup>
                                    : null
                                }


                                {/* <Typography
                        align={'left'}
                        className={classes.labelCellBottom}>
                        {content && content.data ? content.data.fund_name : ""}
                    </Typography> */}
                            </div>


                            <IconButton className={content.update && content.data.path ? classes.hiddenIcon : ""}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </Paper>


                        <div className={classes.main}>

                            <Grid container spacing={2}>
                                {
                                    option == 'fund' ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                                content.update ?

                                                    <MyInfo
                                                        outerlabel={'Fundo'}
                                                        label={content.transactionToUpdate.fund_name}>
                                                    </MyInfo>

                                                    :
                                                    <>
                                                        {
                                                            content.data && content.data.funds ?
                                                                <MySelect
                                                                    outerlabel={'Fundo'}
                                                                    value={inputFundNewTransaction}
                                                                    onChange={(e) => doChangeFund(e.target.value)}
                                                                    options={content.data.funds.map((row, index) => (
                                                                        <option key={'client-fund-' + row.id} value={index}>{row.fund_name}</option>
                                                                    ))}
                                                                /> : null
                                                        }
                                                    </>

                                            }
                                        </Grid>
                                        :
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                                content.update ?

                                                    <MyInfo
                                                        outerlabel={'Título'}
                                                        label={content.transactionToUpdate.title_name + " ( Aplicação: " + moment.utc(content.transactionToUpdate.purchase_date).format("DD/MM/YYYY") + ")"}>
                                                    </MyInfo>

                                                    :
                                                    <>
                                                        {
                                                            content.data && content.data.titleAssets ?
                                                                <MySelect
                                                                    outerlabel={'Título'}
                                                                    value={inputAssetTitleNewTransaction}
                                                                    onChange={(e) => setInputAssetTitleNewTransaction(e.target.value)}
                                                                    options={content.data.titleAssets.map((row, index) => (
                                                                        <option key={'client-title-asset-' + row.id} value={index}>{row.title_name + (row.trading_note ? ' [' + row.trading_note + ']' : "") + ' ( Aplicação: ' + moment.utc(row.purchase_date).format('DD/MM/YYYY') + " )"}</option>
                                                                    ))}
                                                                /> : null
                                                        }
                                                    </>

                                            }
                                        </Grid>



                                }

                                {
                                    option === 'fund' ?
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                                fundClientAccounts && fundClientAccounts.length > 1 ?

                                                    <MySelect
                                                        outerlabel={'Conta'}
                                                        value={inputAccountNewTransaction}
                                                        onChange={(e) => setInputAccountNewTransaction(e.target.value)}
                                                        initblank={inputAccountNewTransaction == undefined}
                                                        options={
                                                            fundClientAccounts.map((row, index) => (
                                                                <option key={'account-new-transaction-' + row.id} value={index}>
                                                                    {
                                                                        row.description + ' [ ' + applyMaskAccount(row.number_account) + ' ] [ ' + (row.agency ? row.agency : '-') + ' ]'
                                                                    }
                                                                </option>
                                                            ))
                                                        }
                                                    />

                                                    :
                                                    <>
                                                        {
                                                            fundClientAccounts && fundClientAccounts.length == 1 ?
                                                                <MyInfo
                                                                    outerlabel={'Conta'}
                                                                    label={`
                                                                ${fundClientAccounts[0].description} [ 
                                                                ${applyMaskAccount(fundClientAccounts[0].number_account)} ] 
                                                                ${fundClientAccounts[0].agency != null ? '[' + fundClientAccounts[0].agency + ']' : ''}`
                                                                    }>
                                                                </MyInfo>
                                                                : null
                                                        }
                                                    </>

                                            }
                                        </Grid>
                                        : null

                                }



                                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.alignLeft}>
                                    <MyRadioGroup
                                        outerlabel={'Operação'}
                                        row
                                        aria-label="operação" name="operation"
                                        value={inputOperationNewTransaction}
                                        onChange={(e) => setInputOperationNewTransaction(parseInt(e.target.value))}
                                        options={content.data.operations.map((row, index) => (
                                            <FormControlLabel
                                                disabled={option == 'title'
                                                    && inputAssetTitleNewTransaction
                                                    && inputAssetTitleNewTransaction.sector == 'PUBLICO' && (index == 0 || index == 1)}
                                                key={'operation-new-transaction-' + row.id}
                                                value={row.id}
                                                control={<Radio />}
                                                label={
                                                    <span className={classes.itemRegime}>{row.type_operation}</span>
                                                } />
                                        ))}
                                    />

                                </Grid>

                                <Grid item lg={needQuotasTransaction() ? 4 : 6} md={needQuotasTransaction() ? 4 : 6} sm={12} xs={12} className={classes.alignLeft}>
                                    <MyDatePicker
                                        className={classes.myDatePicker}
                                        margin="dense"
                                        outerlabel="Data (Cotização)"
                                        format="DD/MM/YYYY"
                                        value={inputDateNewTransaction}
                                        onChange={(value) => setInputDateNewTransaction(value)}
                                        KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                </Grid>


                                <Grid item lg={needQuotasTransaction() ? 4 : 6}
                                    md={needQuotasTransaction() ? 4 : 6} sm={12} xs={12} className={classes.alignLeft}>
                                    <MyTextField
                                        align={'right'}
                                        startadornment={<InputAdornment position="start">R$</InputAdornment>}
                                        size={'small'}
                                        outerlabel="Valor"
                                        outerlabelright={'true'}
                                        value={inputAmountNewTransaction}
                                        placeholder="0,00"
                                        variant="outlined"
                                        helperText={"Insira o valor da movimentação"}
                                        onChange={(value) => setInputAmountNewTransaction(value)}
                                        mask={["currency"]} />

                                </Grid>

                                {
                                    needQuotasTransaction() ?

                                        <Grid item lg={4}
                                            md={4} sm={12} xs={12} className={classes.alignLeft}>
                                            <MyTextField
                                                align={'right'}
                                                size={'small'}
                                                outerlabel="Qtd. Cotas"
                                                outerlabelright={'true'}
                                                value={inputQuotasNewTransaction}
                                                placeholder="0,00"
                                                variant="outlined"
                                                helperText={"Insira o valor de qtd de cotas"}
                                                onChange={(value) => setInputQuotasNewTransaction(value)} />

                                        </Grid> : null
                                }




                            </Grid>

                            <br />

                            {
                                !unableToChange() ?
                                    <div className={classes.bottom}>

                                        {
                                            validationMessage != '' ?
                                                <Typography className={classes.validationMessage}>
                                                    {validationMessage}
                                                </Typography>
                                                : null

                                        }

                                        {content.update ?

                                            <div className={'bottomButtons'}>


                                                <UnoButton
                                                    className={
                                                        !content.update ? classes.hiddenIcon : null}
                                                    onClick={() => setConfirmingAction(true)}
                                                    type='errorClean'>
                                                    {"Excluir movimentação"}
                                                </UnoButton>
                                                <UnoButton
                                                    onClick={() => doUpdateTransaction()}
                                                    type='success'>
                                                    {"Atualizar movimentação"}
                                                </UnoButton>
                                            </div>

                                            :
                                            <UnoButton
                                                onClick={() => option == 'fund' ? doCreateFundTransaction() : doCreateTitleTransaction()}
                                                type='primary'>
                                                {"Salvar movimentação"}
                                            </UnoButton>
                                        }


                                    </div>
                                    : null
                            }

                            {/* //em caso de transação inválida permitir excluir */}

                            {
                                (content
                                    && content.transactionToUpdate
                                    && content.transactionToUpdate.invalidTransaction
                                    && checkUserIsRole(currentUser, [roles.admin, roles.superadmin])
                                ) ?

                                    <UnoButton
                                        className={
                                            !content.update ? classes.hiddenIcon : null}
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir movimentação"}
                                    </UnoButton> : null
                            }


                        </div>


                    </>
                    :
                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir essa movimentação?`}
                        handleCancel={() => setConfirmingAction(false)}
                        handleConfirm={() => doDeleteTransaction()}
                    />
            }

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }


        </div>
    );
}



