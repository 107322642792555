// Styles
import { IconButton, makeStyles, Typography, Paper } from '@material-ui/core';
import { unoTheme } from '../../../assets/styles/unoTheme';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

import { formatPortfolioClosed } from '../../utils/utils';

// Data
import React, { useEffect, useState } from 'react';
import { getAllClientsFromFund } from '../../../API/fund';
import { useDispatch } from 'react-redux';
import allActions from '../../../actions';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: unoTheme.hoverTable,
  },
  leftHeader: {

    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
  },
  fundLabel: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#1C2228'
  },
  accountLabel: {
    fontSize: '14px',
    color: unoTheme.secondaryColor,
  },
  spacingLeft2: {
    marginLeft: theme.spacing(2)
  },

  main: {
    height: 'calc(100vh - 99px)',
    //overflowY: 'scroll'
  }
}));


function ClientsFromTable({ content }) {
  const [clients, setClients] = useState([]);
  const { data } = content;
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    console.log("DATA: ", data);
  }, []);

  return (
    <div className={classes.root}>

      <Paper style={{ marginBottom: '4px' }}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div>
              <Typography
                align={'left'}
                className={classes.fundLabel}>
                {data.label ? formatPortfolioClosed(data.label) : '-'}
              </Typography>
              <Typography
                align={'left'}
                className={classes.accountLabel}>
                {/* <span>CNPJ: {data.fund_cnpj}</span> */}
                {/* <span className={classes.spacingLeft2}>QTD CLIENTES: {data.qtd}</span> */}
                <span>QTD CLIENTES: {data.qtd}</span>

              </Typography></div>

          </div>

          <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
            <CloseIcon />
          </IconButton>

        </div>
      </Paper>

      <div className={classes.main}>
        <MyTable
          stickyHeader
          nofixed={true}
          size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Município</TableCell>
              <TableCell align="left">Nome do RPPS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.clients.map((client, index) => (
              <TableRow key={'clients-from-table-' + index}>
                <TableCell align="left">{client.label_name}</TableCell>
                <TableCell align="left">{client.rpps_name.toUpperCase()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MyTable>
      </div>
    </div >

  )
}

export default ClientsFromTable;
