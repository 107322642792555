import moment from 'moment';
import {
    saveIpcaDpMonth, getIpcaDpMonth, getIpcaDpMonthAndAgoMonth
} from "../API/ipcadp_local"
import { getMonthAndYearBefore } from '../components/utils/utils';

export async function doGetIpcaDpMonthAndAgoMonth(date) {

    let splittedDate = date.split('/');

    const month = parseInt(splittedDate[1]);
    const year = parseInt(splittedDate[2]);

    const { monthBefore, yearBefore } = getMonthAndYearBefore(date);

    let response = await getIpcaDpMonthAndAgoMonth(month, year);

    if (response.success && response.body && response.body.rows) {

        let ipcaDpActualMonth = response.body.rows.find(el => el.month == month && el.year == year)
        let ipcaDpAgoMonth = response.body.rows.find(el => el.month == monthBefore && el.year == yearBefore)

        console.log("RESPONSE SAVE LOCAL IPCADP: ", response.body);

        const jsonIpcaDpActualMonth = ipcaDpActualMonth ? JSON.parse(ipcaDpActualMonth.json_ipcadp) : null;
        const jsonIpcaDpAgoMonth = ipcaDpAgoMonth ? JSON.parse(ipcaDpAgoMonth.json_ipcadp) : null;

        if (!jsonIpcaDpActualMonth || (jsonIpcaDpActualMonth && jsonIpcaDpActualMonth.some(el => el.ipca_dp == null))) {
            ipcaDpActualMonth = null;
        }

        if (!jsonIpcaDpAgoMonth || (jsonIpcaDpAgoMonth && jsonIpcaDpAgoMonth.some(el => el.ipca_dp == null))) {
            ipcaDpAgoMonth = null;
        }

        return {
            ipcaDpActualMonth, ipcaDpAgoMonth
        }
    } else {
        return null;
    }
};


export async function doGetIpcaDpMonth(date) {

    let splittedDate = date.split('/');

    const month = parseInt(splittedDate[1]);
    const year = parseInt(splittedDate[2]);

    let response = await getIpcaDpMonth(month, year);

    if (response.success && response.body && response.body.rows.length === 1) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
            body: response.body.rows[0]
        }
    } else {
        return {
            success: false,
        }
    }
};

/**
 * @param {obj} serie contem a lista de ipcadp do mês
 * @returns {obj} objFormatado
 */
export async function doSaveIpcaDpMonth(serie, date) {

    let splittedDate = date.split('/');

    const month = parseInt(splittedDate[1]);
    const year = parseInt(splittedDate[2]);

    const ipcaDpMonth = {
        json_ipcadp: JSON.stringify(serie),
        month: month,
        year: year,
        date_ipcadp: date,
    }

    let response = await saveIpcaDpMonth(ipcaDpMonth);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
};
