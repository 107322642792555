import { isEmpty, isNumber } from 'lodash';
import React, { useState } from 'react';
import MyTextField from './MyTextField';
import { makeStyles } from '@material-ui/styles';

export default function SearchBar({ mask, setSearchQuery, label, size, variant, name, align, spacingRight }) {

  const useStyles = makeStyles((theme) => {
    const styles = { inputSearch: {} };

    styles.inputSearch.marginTop = '0px';
    styles.inputSearch.marginBottom = '0px';

    if (size === 'large') {
      styles.inputSearch.width = "300px !important";
      styles.inputSearch.minWidth = "300px !important";
      size = 'small';
    };

    if (spacingRight) {
      styles.inputSearch.marginRight = '16px';
    }

    return styles;
  });

  const classes = useStyles();

  const [searchTimeout, setSearchTimeout] = useState(null);
  const [currentMask, setCurrentMask] = useState([false]);
  const [inputValue, setInputValue] = useState('');

  const delayedChange = (searchInputValue) => {
    return setSearchTimeout(setTimeout(() => {
      setSearchQuery(searchInputValue);
    }, 800));
  };

  function handleInputFormat(value) {
    setInputValue(value);

    let newMask = false;

    const isMaskText = {
      cnpj: mask === 'text_cnpj',
      cpf: mask === 'text_cpf',
    };

    const isFirstCharacter = value.length === 1;
    if (isFirstCharacter) {
      if (mask) {
        if (isMaskText.cnpj || isMaskText.cpf) {
          let searchInputValueNumber = Number(value);
          if (isNumber(searchInputValueNumber) && !isNaN(searchInputValueNumber)) {
            if (!isEmpty(value)) {
              if (isMaskText.cnpj) newMask = 'cnpj';
              if (isMaskText.cpf) newMask = 'cpf';
            };
          };
        };
      };
      setCurrentMask([newMask]);
    } else if (isEmpty(value)) {
      setCurrentMask([false]);
    };
  }

  function handleChange(searchInputValue) {
    handleInputFormat(searchInputValue);
    clearTimeout(searchTimeout);
    delayedChange(searchInputValue);
  }

  return (
    <MyTextField
      value={inputValue}
      onChange={value => handleChange(value)}
      mask={currentMask}
      className={classes.inputSearch}
      name={name}
      size={size}
      align={align}
      variant={variant}
      label={label}
    />
  )
};