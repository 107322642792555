import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import MyTable from '../../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../../actions";

//Controllers
import { formatTargetsToShow, loadClientDiaryByRange } from '../../../../../controllers/ClientController'

import { unoTheme, headerSearch, tabs } from '../../../../../assets/styles/unoTheme'

import moment from 'moment'
import { applyMaskAmount, applyMaskAmountComdinheiro, applyMaskAmountComdinheiroFreeDecimals, applyMaskAmountFreeDecimals, getInMonthRentFundInPortfolio, greenOrRed, justNumbers } from "../../../../utils/utils";
import UnoButton from "../../../../utils/UnoButton";
import { getFilesByDir } from "../../../../../API/server_files";

import { MyTabs, MyTab } from "../../../../utils/MyTabs";
import { getFundsDemonstrative } from "../../../../../controllers/FundController";
import { fundsTest } from "../../fileTest";
import { Paper, TableContainer, TextField } from "@material-ui/core";
import MyInfo from "../../../../utils/MyInfo";
import { codsInnerLoad } from "../../../../utils/MyInnerLoad";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    dirName: {
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
            color: 'blue'
        }
    },
    file: {
        cursor: 'pointer',
        '&:hover': {
            color: 'blue',
            '& svg': {
                color: 'blue'
            }
        }

    },
    tabs: tabs,

    demonstrativeContainer: {
        overflowY: 'scroll',
        width: '100%',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',

        '& .demonstrativeFund': {

            marginBottom: '32px',

            '& .fundName': {
                color: unoTheme.defaultTextColor,
                paddingBottom: '16px',
                textAlign: 'left',
                fontWeight: 'bold',
                fontSize: '18px',

            },

            '& .demonstrativeTopInfos': {
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '16px',

                '& .demonstrativeTopInfosLeft': {
                    paddingRight: '16px',
                },
                '& .demonstrativeTopInfosRight': {
                    borderLeft: 'solid 1px ' + unoTheme.mainColor,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }
            }
        }
    },
    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: unoTheme.mainColor,
                    color: 'white',

                    '& .MuiTableCell-root': {
                        color: 'white',
                    },
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },

            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: '#768191',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },


    },

    gridPaperTable: {
        borderRadius: '10px',
    }

});


export default function DemonstrativeTab(props) {

    const { searchText } = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const [funds, setFunds] = React.useState(null);
    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

    };

    async function doGetFundsDemonstrative(clientId, month, year) {

        //setFunds(fundsTest);
        const responseDemonstrative = await getFundsDemonstrative(clientId, month, year);
        console.log('responseDemonstrative: ', responseDemonstrative);

        if (responseDemonstrative.success) {
            setFunds(responseDemonstrative.funds);
        }



        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    function doReport() {
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'demonstrative',
            message: 'Gerando demonstrativo...'
        }))
    }

    useEffect(() => {

        if (clientOn && clientOn.client && clientOn.client.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

            setFunds(null)
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando demonstrativo de fundos...',
                cod: codsInnerLoad.demonstrativeScreen
            }));
            doGetFundsDemonstrative(clientOn.client.id, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear);

        } else {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Os retornos se dão a partir do lançamento de pelo menos um mês de carteira"
            }));
        }

    }, [clientOn]);

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>


                {
                    tabValue == 0 ?
                        <div className={classes.demonstrativeContainer}>
                            {
                                funds && funds.filter(el => searchText == '' || (searchText != '' &&
                                    (
                                        el.fund_name.toUpperCase().includes(searchText.toUpperCase())
                                        ||
                                        (justNumbers(searchText) != '' && justNumbers(el.cnpj).includes(justNumbers(searchText)))
                                    ))).map((row, index) => (

                                        <div className={'demonstrativeFund'}>
                                            <div className={'fundName'}>{row.fund_name} - {row.cnpj}</div>
                                            <div className={'demonstrativeTopInfos'}>
                                                <div className={'demonstrativeTopInfosLeft'}>
                                                    <MyInfo
                                                        outerlabel={'Total aplicações:'}
                                                        direction={'horizontal'}
                                                        colorInfo={unoTheme.greenMoney}
                                                        label={applyMaskAmount(row.totalApplication, true)}>
                                                    </MyInfo>
                                                    <MyInfo
                                                        outerlabel={'Total resgates:'}
                                                        direction={'horizontal'}
                                                        colorInfo={unoTheme.redMoney}
                                                        label={applyMaskAmount(row.totalRescue, true)}>
                                                    </MyInfo>
                                                    <MyInfo
                                                        outerlabel={'Participação:'}
                                                        direction={'horizontal'}
                                                        label={applyMaskAmount(row.participation) + "%"}>
                                                    </MyInfo>
                                                </div>
                                                <div className={'demonstrativeTopInfosRight'}>
                                                    <MyInfo
                                                        outerlabel={'Rent. na carteira:'}
                                                        direction={'horizontal'}
                                                        colorInfo={greenOrRed(row.rentMoneyInMonth)}
                                                        label={applyMaskAmount(row.rentMoneyInMonth, true) + " (" + applyMaskAmount(row.rentPercentInMonth, null, true) + '%)'}>
                                                    </MyInfo>
                                                    <MyInfo
                                                        outerlabel={'Saldo final:'}
                                                        direction={'horizontal'}
                                                        colorInfo={greenOrRed(row.balance_now)}
                                                        label={applyMaskAmount(row.balance_now, true)}>
                                                    </MyInfo>
                                                    <MyInfo
                                                        outerlabel={'Cotas investidas:'}
                                                        direction={'horizontal'}
                                                        label={applyMaskAmount(row.quota_amount_now)}>
                                                    </MyInfo>
                                                </div>
                                            </div>
                                            <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}>

                                                <TableContainer>
                                                    <Table className={classes.tableBlade} size={'small'}>
                                                        <colgroup>
                                                            <col width="15%" />
                                                            <col width="7.44%" />
                                                            <col width="9.44%" />
                                                            <col width="8%" />
                                                            <col width="9.44%" />
                                                            <col width="7.44%" />
                                                            <col width="7.44%" />
                                                            <col width="9.44%" />
                                                            <col width="8.44%" />
                                                            <col width="8.44%" />
                                                            <col width="9.44%" />
                                                            {/* <col width="8.1%" />
                                                    <col width="8.1%" /> */}
                                                        </colgroup>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">Risco x Retorno (12m)</TableCell>
                                                                <TableCell align="center">Cota</TableCell>
                                                                <TableCell align="center">PL</TableCell>
                                                                <TableCell align="center">Cotistas</TableCell>
                                                                <TableCell align="center">Volatilidade</TableCell>
                                                                <TableCell align="center">Perda Máx.</TableCell>
                                                                <TableCell align="center">VaR</TableCell>
                                                                <TableCell align="center">Índice Sharpe</TableCell>
                                                                <TableCell align="center">Retorno Máx.</TableCell>
                                                                <TableCell align="center">Retorno Mín.</TableCell>
                                                                <TableCell align="center">Retorno Médio</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow key={'blade-fund-risco-retorno-fund'}>
                                                                <TableCell align="left">{"Fundo"}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiroFreeDecimals(row.risco_retorno.cota)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.dados_cadastrais.pl_anbima)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.dados_cadastrais.num_cotistas)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.volatilidade)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.perda_maxima)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.var)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.indice_sharpe)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.retorno_maximo)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.retorno_minimo)}</TableCell>
                                                                <TableCell align="center">{applyMaskAmountComdinheiro(row.risco_retorno.retorno_medio)}</TableCell>
                                                            </TableRow>
                                                            <TableRow key={'blade-fund-risco-retorno-benchmark'}>
                                                                <TableCell align="left">{row.benchmark ? row.benchmark : "-"}</TableCell>
                                                                <TableCell align="center">{'-'}</TableCell>
                                                                <TableCell align="center">{'-'}</TableCell>
                                                                <TableCell align="center">{'-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.volatilidade ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.volatilidade) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.perda_maxima ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.perda_maxima) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.var ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.var) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.indice_sharpe ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.indice_sharpe) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.retorno_maximo ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.retorno_maximo) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.retorno_minimo ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.retorno_minimo) : '-'}</TableCell>
                                                                <TableCell align="center">{row.risco_retorno_benchmark && row.risco_retorno_benchmark.retorno_medio ? applyMaskAmountComdinheiro(row.risco_retorno_benchmark.retorno_medio) : '-'}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>

                                    ))
                            }
                        </div>
                        : null
                }
            </div>
        </div >
    );
}



