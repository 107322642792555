import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from 'react-router-dom';

//Table
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../../utils/MyTable';

import { useDispatch, useSelector } from "react-redux";



import Tooltip from '@material-ui/core/Tooltip';
import allActions from "../../../../actions";
import { headerSearch, unoTheme } from "../../../../assets/styles/unoTheme";
import { codsInnerLoad } from "../../../utils/MyInnerLoad";
import { getFirstBeforeBusinessDay, getLastPtDayMonth } from "../../../utils/utils";

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from '../../../../controllers/ExcelController';
import { RiskController } from '../../../../controllers/RiskController';

const rightDivWidth = 450;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        //boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.common.black,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    headerSearch: headerSearch,

    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableContainer: {
        padding: '0px 18px 16px 18px',
        borderRadius: '10px 0px 0px 10px',
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    clickableHead: {
        cursor: 'pointer',
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },

    leftChart: {
        padding: theme.spacing(2),
        width: '50%',
    },

    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .3s ease',
    },

    sectionTableCellData: {
        width: '30%',
        display: 'table-cell',
        padding: '0px !important',
        fontSize: '12px',
    },

    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
        fontSize: '12px',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '50%',
        }
    },

}));

const RiskMarketScreen = forwardRef((props, ref) => {

    const { data } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [fundsRisk, setFundsRisk] = useState(undefined);

    const [validationIbovCdiMonth, setValidationIbovCdiMonth] = useState(undefined);
    const [validationRentsMonth, setValidationRentsMonth] = useState(undefined);

    const [validationIbovCdi12m, setValidationIbovCdi12m] = useState(undefined);
    const [validationRents12m, setValidationRents12m] = useState(undefined);



    async function loadAssets(clientId, month, year) {

        console.log("LOAD ASSETS: ", clientId, month, year);

        const referenceDate = getFirstBeforeBusinessDay(getLastPtDayMonth(month, year));

        const marketData = await RiskController.getMarketData(clientId, referenceDate);

        setFundsRisk(marketData);

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    function doExcelValidation(fileName, ibovCdi, allRents) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: fileName,
            Author: "UNO",
        }

        const sheetName = fileName;
        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push(['DATA', 'CARTEIRA(%)', 'IBOVESPA(%)', 'CDI(%)'])

        allRents.forEach((rentLoop, index) => {

            if (ibovCdi[index].date == rentLoop.date) {
                ws_data.push([
                    rentLoop.date,
                    rentLoop.rent,
                    ibovCdi[index].valueIBOV,
                    ibovCdi[index].valueCDI,
                ])
            }

        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    useImperativeHandle(ref, () => ({
        excelValidation() {
            if (validationIbovCdi12m && validationRents12m) {

                doExcelValidation('Validação_Risco_' + clientOn.client.label_name, validationIbovCdi12m, validationRents12m)
            }
        }
    }));

    useEffect(() => {
        console.log("marketData: ", data);
    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.mainRoot}>
                <div class={'defaultScrollWithHeaderContent'}>
                    <div>
                        <MyTable
                            stickyHeader>
                            <colgroup>
                                <col width="20%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Fundo</TableCell>
                                    <TableCell align="left" className={'highlighted'}>Benchmark</TableCell>
                                    <TableCell className={[classes.sectionTableCell, 'highlighted'].join(' ')} align="center">
                                        <div>{'Rent. (%)'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'12m'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.sectionTableCell} align="center">
                                        <div>{'VaR (%)'}<span style={{ fontSize: '8px' }}>(252 d.u)</span></div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'Ano'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.sectionTableCell} align="center">
                                        <div>{'Vol (%)'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'12m'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.sectionTableCell} align="center">
                                        <div>{'Treynor'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'12m'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.sectionTableCell} align="center">
                                        <div>{'Drawdown'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'12m'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.sectionTableCell} align="center">
                                        <div>{'Sharpe'}</div>
                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div>{'Mês'}</div>
                                            <div>{'12m'}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">Res. 4963</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((row, index) => (
                                    <TableRow key={'diarys-' + index}>

                                        <TableCell align="left">{row.fund_name}</TableCell>
                                        <TableCell align="center" className={'highlighted'}>{row.benchmark}</TableCell>
                                        <TableCell align="center" className={[classes.sectionTableCellData, 'highlighted'].join(' ')}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.rent_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.rent_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.var_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.var_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.vol_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.vol_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.traynor_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.traynor_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.drawdown_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.drawdown_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                            <div className={classes.multipleHorizontalTableCell}>
                                                <div>{row.infosRisk?.sharpe_mes ?? "-"}</div>
                                                <div>{row.infosRisk?.sharpe_12m ?? "-"}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.sectionTableCellData}>{row.device_abbreviation}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </MyTable>
                    </div>

                </div>


            </div>

        </div>
    );
});

export default RiskMarketScreen;



