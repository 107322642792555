import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../utils/MyTable';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';

//API
import { getClientInflationRates } from '../../../API/inflation_rate'
import { getClientBalancesRentByPeriod } from '../../../API/fund'

//Controllers
import { loadClientDiaryByRange } from '../../../controllers/ClientController'


import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import moment, { calendarFormat } from 'moment'

import { getMonthName, applyMaskAmount, getMonthsByArray, getLastDayMonth, formatTargetsAndRentsToShow, timeStampToPtDate, formatPortfolioClosed, portfolioClosedToDate } from '../../utils/utils';
import IconButton from '@material-ui/core/IconButton';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';

//UnoButton
import UnoButton from '../../utils/UnoButton'
import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import PrintIcon from '@material-ui/icons/Print';

import _ from 'lodash';
import { getClientAllAmortizations, getClientAllTransactionByDateRangeAndRegime, getClientAllTransactions } from "../../../API/transaction";

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from "../../../controllers/ExcelController";
import { reportTypes } from "../../utils/ReportTypes";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    tableContainer: {
        padding: '0px 18px',
        paddingBottom: '16px',
        borderRadius: '10px 0px 0px 10px',
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    }
    ,
    blue: {
        color: 'blue',
    },
    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'unset',
            color: 'white',
            background: unoTheme.mainColor,
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },
}));


export default function TargetsScreen() {

    const classes = useStyles();

    const [inflationTargetsToShow, setInflationTargetsToShow] = useState(undefined);

    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)
    const rightContent = useSelector(state => state.rightContent)

    const [clientOnScreen, setClientOnScreen] = useState(null);
    const [appearFab, setAppearFab] = useState(true);

    async function doGetClientInflationRates(clientId) {

        const response = await getClientInflationRates(clientId);
        if (response.success) {

            const isClientOnPortfolioClosed = clientOn.client.portfolio_closed;

            if (isClientOnPortfolioClosed) {

                const splittedPortfolioClosed = isClientOnPortfolioClosed.toString().trim().split('/');
                const monthClosed = parseInt(splittedPortfolioClosed[0]);
                const yearClosed = parseInt(splittedPortfolioClosed[1]);

                //Novo método balance
                let diarys = await loadClientDiaryByRange(clientId,
                    monthClosed,
                    yearClosed, null); //null para trazer tudo desde o inicio

                // const responeClientAmortizations = await getClientAllAmortizations(clientId);
                // let clientAmortizations = null;
                // if (responeClientAmortizations.success) {
                //     clientAmortizations = responeClientAmortizations.body.rows;
                // }

                const responseClientTransactions = await getClientAllTransactionByDateRangeAndRegime(
                    clientId,
                    timeStampToPtDate(clientOn.client.portfolio_init),
                    timeStampToPtDate(portfolioClosedToDate(clientOn.client.portfolio_closed))
                );

                let clientTransactions = null;
                if (responseClientTransactions.success) {
                    clientTransactions = responseClientTransactions.body.rows;
                }

                console.log("MEGA DIARYS: ", diarys);

                const rentMonths = diarys.rentMonths;
                const clientInflationRates = response.body.rows;

                //const formatedClientInflationRates = formatInflationRatesToShow(clientInflationRates, rentMonths);
                const formatedClientInflationRates = formatTargetsAndRentsToShow(clientInflationRates, rentMonths,
                    diarys.mapAccountsBalances,
                    diarys.mapAssetsBalances,
                    clientTransactions);

                setInflationTargetsToShow(formatedClientInflationRates);

                console.log("INFLATION RATES: ", response.body.rows)
                if (response.body.rows && response.body.rows.length > 0) {
                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: false,
                    }));
                } else {
                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: true,
                        emptyMessage: 'Este cliente ainda não possui nenhum mês com ativos fechados',
                    }));
                }

            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: 'Este cliente ainda não possui nenhum mês com ativos fechados',
                }));
            }




        } else {
            console.log("RESPONSE ERROR: ", response.error)
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }




    }

    // const [searchText, setSearchText] = useState("");

    // const delayedChange = (text) => {
    //     setTimeout(() => {
    //         setSearchText(text)
    //     }, 800)
    // }

    // function changeSearch(e) {

    //     console.log("SearchText: ", searchText)
    //     delayedChange(e.target.value.toUpperCase())
    //     clearTimeout(delayedChange);
    // }

    const useRowStyles = makeStyles({
        root: {
            cursor: 'pointer',
            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
            },
            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },

            '&:hover': {
                background: unoTheme.hoverTable + ' !important',
            }


        },
        oddRow: {
            backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            '& .MuiTableRow-root.maybeOdd': {
                background: `${unoTheme.secondaryTuplaTable}`,
            },
        },
        noRadiusBottom: {
            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px !important',
                borderBottomLeftRadius: '0px !important',
                //backgroundColor: `${unoTheme.secondaryTuplaTable}`,
            },
            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px !important',
                borderBottomRightRadius: '0px !important',
                //backgroundColor: `${unoTheme.secondaryTuplaTable}`,
            },
        },
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
        },
        ok: {
            color: unoTheme.greenMoney,
            cursor: 'pointer',

            fontSize: '1.2em',

        },
        pendent: {
            color: unoTheme.warningColor,
            cursor: 'pointer',
            fontSize: '1.2em',

        },
        hiddenIcon: {
            visibility: 'hidden'
        },
        arrowTableIcon: {
            background: unoTheme.mainColor,
            color: 'white',
            borderRadius: '4px',
            height: '22px',
            width: '22px',

            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainButtonColor
            }
        },
        tableFixed: {
            tableLayout: 'fixed',
        },
        borderOnOpen: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        // lastSubAssetBorderBottom: {
        //     '& > *': {
        //         borderBottom: 'unset !important',
        //     },
        // }

        innerTableRow: {

            '& .MuiTableBody-root tr': {

                cursor: 'default !important',
                pointerEvents: 'none',
            }
        }

    });

    const [opensAccordions, setOpensAccordions] = useState([])

    function handleOpen(e, open, index) {

        console.log('HANDLE OPEN: ', open);
        opensAccordions[index] = open;

        let newOpensAccordions = [...opensAccordions]
        newOpensAccordions[index] = open
        setOpensAccordions(newOpensAccordions);
        e.stopPropagation();

    }

    function detail(e, row) {
        e.stopPropagation();
        dispatch(allActions.rightContentActions.setRightContent({
            path: 'newClientInflationRate',
            update: true,
            data: row,
        }))
    }

    const classesRow = useRowStyles();

    function Rows(rows) {
        //const { row, index } = props;

        //console.log("ROWS TO SHOW: ", rows);
        let rowsToShow = [];

        rows.forEach((row, index) => {

            //a linha que guarda os últimos acumulados do ano
            //let totalRow = row.finalTargetsToShow[0];
            let totalRow = row.finalTargetsToShow[row.finalTargetsToShow.length - 1];


            // console.log("ROW: ", row);
            // console.log("TOTAL ROW: ", totalRow);
            rowsToShow.push(
                <TableRow key={'inflation-row-' + index}
                    className={[
                        index % 2 != 1 ? classesRow.oddRow : "",
                        classesRow.root,
                        opensAccordions[index] && index % 2 != 1 ? classesRow.noRadiusBottom : ""
                    ].join(' ')}
                    onClick={(e) => detail(e, row)}>
                    <TableCell align="center">

                        {
                            row.finalTargetsToShow && row.finalTargetsToShow.length > 0 ?
                                <IconButton aria-label="expand row" size="small"
                                    className={[!row.inflateMonths || (row.inflateMonths && row.inflateMonths.length) == 0 ? classesRow.hiddenIcon : "", classesRow.arrowTableIcon].join(' ')}
                                    onClick={(e) => handleOpen(e, !opensAccordions[index], index)}>
                                    {opensAccordions[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton> : null
                        }


                    </TableCell>
                    <TableCell align="left">{row.year}</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="right" className={classesRow.blue}>{
                        row.use_ipca ?
                            'IPCA + ' + applyMaskAmount(row.tax) + '% a.a.'
                            :
                            'INPC + ' + applyMaskAmount(row.tax) + '% a.a.'}
                    </TableCell>
                    <TableCell align="right">
                        -

                    </TableCell>
                    <TableCell align="right">
                        -

                    </TableCell>
                    <TableCell align="right">
                        -

                    </TableCell>


                </TableRow>)
            rowsToShow.push(
                <TableRow
                    key={'month-inflation-row-' + index}
                    className={[classesRow.innerTableRow, index % 2 == 0 ? classesRow.oddRow : "", opensAccordions[index] ? "" : classesRow.borderOnOpen].join(' ')}>
                    <TableCell style={{ padding: 0 }} colSpan={8}>
                        <Collapse in={opensAccordions[index]} timeout="auto">
                            <Box>
                                {

                                    row && row.finalTargetsToShow && row.finalTargetsToShow.length > 0 ?
                                        <Table size="small"
                                            className={classesRow.tableFixed}>

                                            <colgroup>
                                                <col width="5%" />
                                                <col width="10%" />
                                                <col width="17.5%" />
                                                <col width="17.5%" />
                                                <col width="15%" />
                                                <col width="15%" />
                                                <col width="10%" />
                                                <col width="10%" />
                                            </colgroup>

                                            <TableBody>
                                                {row.finalTargetsToShow.map((inflateMonth, index) => (
                                                    // {row.inflateMonths.map((inflateMonth, index) => (
                                                    <TableRow key={'inflate-month-' + index}
                                                        className={[classesRow.root].join(' ')}>

                                                        <TableCell align="center">
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {getMonthName(inflateMonth.month)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {applyMaskAmount(inflateMonth.balance_before, true)}
                                                            {/* <div style={{ color: 'red' }}>{applyMaskAmount(inflateMonth.balance_before_investments, true)}</div>
                                                            <div style={{ color: 'blue' }}>{applyMaskAmount(inflateMonth.account_before, true)}</div> */}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {applyMaskAmount(inflateMonth.balance_now, true)}
                                                            {/* <div style={{ color: 'red' }}>{applyMaskAmount(inflateMonth.balance_now_investments, true)}</div>
                                                            <div style={{ color: 'blue' }}>{applyMaskAmount(inflateMonth.account_now, true)}</div> */}
                                                        </TableCell>

                                                        {/* Meta */}

                                                        {/* ACUMULADA */}
                                                        {/* <TableCell align="left">{
                                                            inflateMonth.use_ipca ?
                                                                <span className={classesRow.blue}>{applyMaskAmount(parseFloat(inflateMonth.taxAcumIpca))}%</span>
                                                                :
                                                                <span className={classesRow.blue}>{applyMaskAmount(parseFloat(inflateMonth.taxAcumInpc))}%</span>}
                                                        </TableCell> */}

                                                        {/* MÊS */}
                                                        <TableCell align="right">{
                                                            inflateMonth.use_ipca ?
                                                                <span className={classesRow.blue}>{applyMaskAmount(parseFloat(inflateMonth.taxMonthIpca))}%</span>
                                                                :
                                                                <span className={classesRow.blue}>{applyMaskAmount(parseFloat(inflateMonth.taxMonthInpc))}%</span>}
                                                        </TableCell>

                                                        {/* RENTABILIDADE */}
                                                        {/* ACUMULADA */}
                                                        {/* <TableCell align="right"><span style={inflateMonth.rentAcum >= 0 ? { color: unoTheme.greenMoney } : { color: unoTheme.redMoney }}>{applyMaskAmount(inflateMonth.rentAcum)}%</span></TableCell> */}
                                                        {/* MENSAL R$*/}
                                                        <TableCell align="right">
                                                            <span style={inflateMonth.rentMonthMoney >= 0 ? { color: unoTheme.greenMoney } : { color: unoTheme.redMoney }}>{applyMaskAmount(inflateMonth.rentMonthMoney, true)}</span>
                                                            {/* <div style={{ color: 'blue' }}>{applyMaskAmount(inflateMonth.rentMonthMoney - inflateMonth.account_now, true)}</div> */}
                                                        </TableCell>
                                                        {/* MENSAL %*/}
                                                        <TableCell align="right"><span style={inflateMonth.rentPercent >= 0 ? { color: unoTheme.greenMoney } : { color: unoTheme.redMoney }}>{applyMaskAmount(inflateMonth.rentPercent)}%</span></TableCell>

                                                        {/* GAP MENSAL */}
                                                        <TableCell align="right">
                                                            {
                                                                inflateMonth.use_ipca ?

                                                                    <span style={inflateMonth.rentPercent - inflateMonth.taxMonthIpca >= 0 ? { color: unoTheme.greenMoney } : { color: unoTheme.redMoney }}>{
                                                                        parseFloat(inflateMonth.rentPercent - inflateMonth.taxMonthIpca).toFixed(2)
                                                                    }p.p.</span>

                                                                    :

                                                                    <span style={inflateMonth.rentPercent - inflateMonth.taxMonthInpc >= 0 ? { color: unoTheme.greenMoney } : { color: unoTheme.redMoney }}>{
                                                                        parseFloat(inflateMonth.rentPercent - inflateMonth.taxMonthInpc).toFixed(2)
                                                                    }p.p.</span>

                                                            }
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>

                                            <TableFooter className={classes.myTableFooter}>
                                                <TableRow key={'footer-t-targets-' + index}>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">Total</TableCell>
                                                    {/* O primeiro balance before encontra-se no ultimo item do array */}
                                                    <TableCell align="right" className="spanTotal">{applyMaskAmount(row.finalTargetsToShow[row.finalTargetsToShow.length - 1].balance_before, true)}</TableCell>
                                                    {/* O último balance now encontra-se no primeiro item do array */}
                                                    {/* <TableCell align="right" className="spanTotal">{applyMaskAmount(row.finalTargetsToShow[0].balance_now, true)}</TableCell> */}
                                                    <TableCell align="right" className="spanTotal">{applyMaskAmount(row.finalTargetsToShow[row.finalTargetsToShow.length - 1].balance_now, true)}</TableCell>
                                                    <TableCell align="right" className="spanTotal">{
                                                        totalRow.use_ipca ?
                                                            // <span>{parseFloat(totalRow.taxAcumIpca)}%</span>
                                                            <span>{parseFloat(totalRow.taxAcumIpcaYear)}%</span>
                                                            :
                                                            // <span>{parseFloat(totalRow.taxAcumInpc)}%</span>
                                                            <span>{parseFloat(totalRow.taxAcumInpcYear)}%</span>
                                                    }
                                                    </TableCell>

                                                    {/* <TableCell align="right" ><span className="spanTotal">{applyMaskAmount(totalRow.rentAcum)}%</span></TableCell> */}
                                                    <TableCell align="right"><span className="spanTotal">{applyMaskAmount(totalRow.rentMoneyAcumYear, true)}</span></TableCell>
                                                    <TableCell align="right"><span className="spanTotal">{applyMaskAmount(totalRow.rentAcumYear)}%</span></TableCell>

                                                    <TableCell align="right" className="spanTotal" style={{ paddingRight: '24px' }}>
                                                        {
                                                            totalRow.use_ipca ?

                                                                <span>{
                                                                    // parseFloat(totalRow.rentAcumYear - totalRow.taxAcumIpca).toFixed(2)
                                                                    parseFloat(totalRow.rentAcumYear - totalRow.taxAcumIpcaYear).toFixed(2)
                                                                }p.p.</span>

                                                                :

                                                                <span>{
                                                                    parseFloat(totalRow.rentAcumYear - totalRow.taxAcumInpcYear).toFixed(2)
                                                                }p.p.</span>

                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>

                                        </Table>
                                        : null
                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )
        });


        return rowsToShow;
    }

    async function doReport() {

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'targets',
            message: 'Gerando o relatório...'
        }))

    }

    function doExcelBalancesAndTransactions(fileName, items) {


        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        console.log("items to excel: ", items);

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: fileName,
            Author: "UNO",
        }

        const sheetName = fileName;
        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push(['PERÍODO', 'SALDO ANTERIOR', 'APLICAÇÕES', 'RESGATES', 'AMORTIZAÇÕES', 'RETORNO', 'VARIAÇÃO DISPONIBILIDADE', 'SALDO FINAL'])

        items.forEach(yearTarget => {

            yearTarget.finalTargetsToShow.reverse().forEach(monthTarget => {
                if (monthTarget && monthTarget.month) {
                    console.log('monthTarget: ', monthTarget);
                    ws_data.push([
                        monthTarget.month + '/' + monthTarget.year,
                        applyMaskAmount(monthTarget.balance_before, true),
                        applyMaskAmount(monthTarget.applications_amount, true),
                        applyMaskAmount(monthTarget.rescues_amount, true),
                        applyMaskAmount(monthTarget.amortization_amount, true),
                        applyMaskAmount(monthTarget.rentMonthMoney, true),
                        applyMaskAmount(monthTarget.account_now - monthTarget.account_before, true),
                        applyMaskAmount(monthTarget.balance_now, true),
                    ])
                }
            });

        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    const [scrollTop, setScrollTop] = useState(0);

    function onScrollTable(e) {

        if (e.target.scrollTop < scrollTop) {
            setAppearFab(true);
        } else {
            setAppearFab(false);
        }

        setScrollTop(e.target.scrollTop);

    }

    useEffect(() => {

        console.log("PASSANDO TARGET SREEN");

        if (clientOn
            && clientOn.client
            && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

            setClientOnScreen(_.cloneDeep(clientOn.client))
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando informações...'
            }));
            doGetClientInflationRates(clientOn.client.id);

        }

        if ((rightContent && rightContent.action)) {

            console.log("Action right content: ", rightContent.action);

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando informações...'
            }));
            doGetClientInflationRates(clientOn.client.id);
            dispatch(allActions.rightContentActions.setRightContent(null))
        }

    }, [clientOn,
        rightContent]);

    return (
        <div className={classes.root}>


            <div className={classes.headerSearch}>

                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Metas
                    </Typography>
                </div>
                <div className={classes.rightHeader}>

                    {
                        //Exibido apenas no menu de APRS
                        inflationTargetsToShow && inflationTargetsToShow.length ?
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                                <UnoButton
                                    size={'small'}
                                    onClick={() => doExcelBalancesAndTransactions('Saldos e Movimentações', inflationTargetsToShow)}
                                    type='success'
                                    startIcon={<PrintIcon />}>
                                    {"Saldos e movimentações"}
                                </UnoButton>

                            </div> : null
                    }

                    {
                        inflationTargetsToShow && inflationTargetsToShow.length ?
                            <UnoButton
                                size={'small'}
                                onClick={() => doReport()}
                                type='primary'
                                startIcon={<PrintIcon />}>
                                {"Gerar relatório"}
                            </UnoButton>
                            : null
                    }

                    {
                        clientOn && clientOn.client && (currentUser && currentUser.user
                            && (currentUser.user.role == "ROLE_ADMIN"
                                || currentUser.user.role == "ROLE_SUPER_ADMIN"
                                || currentUser.user.role == "ROLE_ADVISOR")) ?
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                                <UnoButton
                                    size={'small'}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newClientInflationRate',
                                        data: {

                                        }
                                    }))}
                                    type='primary'
                                    startIcon={<AddIcon />}>
                                    {"Nova meta"}
                                </UnoButton>

                            </div> : null
                    }

                </div>

            </div>
            <div class={'defaultScrollWithHeaderContent'}>
                <TableContainer component={Paper} className={classes.tableContainer} elevation={0}
                    onScroll={(e) => onScrollTable(e)}>
                    <Table stickyHeader className={classes.table} size="small" >
                        <colgroup>
                            <col width="5%" />
                            <col width="10%" />
                            <col width="17.5%" />
                            <col width="17.5%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="10%" />
                            <col width="10%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell /> */}
                                <TableCell align="center"></TableCell>
                                <TableCell align="left">Período</TableCell>
                                <TableCell align="right">Saldo anterior</TableCell>
                                <TableCell align="right">Saldo final</TableCell>
                                <TableCell align="right">Meta</TableCell>
                                <TableCell align="right">Rent(R$)</TableCell>
                                <TableCell align="right">Rent(%)</TableCell>
                                <TableCell align="right">Gap</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {/* {inflationTargetsToShow ? inflationTargetsToShow.map((row, index) => (
                                <Row key={'target-formatted-' + index} row={row} index={index} />
                            )) : null} */}
                            {
                                inflationTargetsToShow ? Rows(inflationTargetsToShow) : null
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>
    );
}


