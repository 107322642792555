import moment from 'moment';
import { getAllUsersClientByRole } from '../API/client';
import { sendGeneralEmail } from '../API/mail';
import { unoTheme } from '../assets/styles/unoTheme';
import { roles } from '../components/utils/user';
import { getMonthName } from '../components/utils/utils';

const HTML_TO_LAUNCH_PORTFOLIO_MAIL = (month, year) => {
    return `
    
        <div style="width: 100%; height: 100%; border-radius: 10px;">
            <h3>Olha que notícia boa!</h3>
            <br/>
            <p>Sua carteira foi atualizada e já está disponível no <b style="color: ${unoTheme.mainColor}">UNO</b>.</p>
            <p>Agora, você poderá gerar um relatório mensal completo e ter acesso às principais informações dos investimentos referentes ao mês de <b>${getMonthName(month) + '/' + year}</b> :)</p>
            <br/>
            <a href='https://unoapp.com.br/' target='_blank' style="text-decoration: none; background-color: ${unoTheme.mainColor}; color: white; padding: 8px 12px; border-radius: 5px">Acesse o UNO<a/>
            <br/>
            <br/>
            <p><b>#vamosjuntos</b></p>
            <br/>
        </div>            
        
    `
}

//Enviando email para os gestores das carteiras
export async function sendLaunchPortofolioEmail(clientId, month, year) {

    const managersEmails = [];
    //Obtendo emails dos gestores do cliente selecionado
    const responseManagers = await getAllUsersClientByRole(clientId, roles.manager.id);
    if (responseManagers.success) {

        responseManagers.body.rows.forEach(manager => {
            managersEmails.push(manager.email);
        });

    }

    console.log("EMAILS: ", managersEmails);
    managersEmails.forEach(email => {

        sendGeneralEmail('[LEMA] RPPS: Sua carteira foi atualizada!', email, HTML_TO_LAUNCH_PORTFOLIO_MAIL(month, year));
    });

}