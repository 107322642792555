import moment from 'moment';
import {
    saveCDIMonth, getCDIMonth
} from "../API/cdi_local"
import { getMonthAndYearBefore, getDayMonthYearByStringDate } from '../components/utils/utils';

export async function doGetCDIMonth(month, year) {

    let response = await getCDIMonth(month, year);

    if (response.success && response.body && response.body.rows.length === 1) {
        console.log("RESPONSE GET CDI: ", response.body);
        return {
            success: true,
            body: JSON.parse(response.body.rows[0].json_cdi)
        }
    } else {
        return {
            success: false,
        }
    }
};

/**
 * @param {obj} serie contem a lista de CDI do mês
 * @returns {obj} objFormatado
 */
export async function doSaveCDIMonth(serie, date) {

    const { month, year } = getDayMonthYearByStringDate(date);

    const cdiMonth = {
        json_cdi: JSON.stringify(serie),
        month: month,
        year: year,
        date_cdi: date,
    }

    let response = await saveCDIMonth(cdiMonth);

    if (response.success) {
        //console.log("RESPONSE SAVE LOCAL: ", response.body);
        return {
            success: true,
        }
    } else {
        return {
            success: false,
        }
    }
};
