import API from './config'

export const PDFReaderAPI = {
    async getProcessedExtract(instituition, file) {

        try {

            console.log("FILE: ", file);
            if (file) {

                const formData = new FormData();
                formData.append('file', file);

                const response = await API.post('/pdf_reader/processExtract/' + instituition, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': localStorage.getItem('token'),
                    },
                })

                console.log("Response: ", response);
                if (response.data?.message === "INVALID_PDF") {
                    return false;
                } else {
                    return response.data;
                }



            }

        } catch (error) {

            console.log("error: ", error);

            // return {
            //     success: false,
            //     body: error.response.data
            // };
        }
    },
}