import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    getCapaReport,
    getPortfolioReport,
    getDistribuitionReport,
    saveChartTmpImage,
    getAprReport,
    getArticlesReport,
    getArticlesV2Report,
    getDistribuitionReportPart,
    getDistribuitionReportPartGrouped,
    getTargetsReport,
    getMyFundsReport,
    getMyTitlesReport,
    getMyReturnsReport,
    getDashboardReport,
    getAprBatchReport,
    getFullReport,
    getFundsRankingReport,
    getSingleAprReport,
    getDemonstrativeReport,
    getFullDemonstrativeReport,
    getCompareFundsReport,
    getRiskMarketReport,
    getRiskArticlesReport,
    getRiskBenchmarksReport,
    getRiskPolicyReport,
    getFullRiskReport,
    getProvisionalReport,
    deprecatedSaveChartTmpImage,
    getIPC14ReturnsReport,
} from '../../API/report'

import { FAILURE, REPORTS_URL } from '../../API/config'

//Charts
import { Pie } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { unoTheme } from '../../assets/styles/unoTheme';
import { getMonthName, applyMaskAmount, roundRect, getDayMonthYearByStringDate, getShortMonthName, getFirstPtDayMonth, getLastPtDayMonth, formatMillionNumber, getMonthAgo, getMonthAndYearBefore } from '../utils/utils';

//Controllers
import {
    getDataToArticleReport,
    getDataToDistribuitionReport,
    getDataToPortfolioReport,
    getDataToTargetsReport,
    getDataToMyFundsReport,
    getDataToMyTitlesReport,
    getDataToDashboardReport,
    getDataToAprReport,
    getDataToBatchAprsReport,
    getDataToMyClientReturns,
    getDataToFundsRankingReport,
    getDataToSingleAprReport,
    getDataToDemonstrativeReport,
    getDataToProvisonalReport,
} from '../../controllers/ReportController';
import { getConsultingInfosById } from '../../API/consulting';
import { fundsTest } from '../main/administrative/fileTest';
import { reportTypes } from '../utils/ReportTypes';
import { RiskController } from '../../controllers/RiskController';

const NO_DATA = 'no_data';

const legendPieChartOpts = {
    display: false,
    fullWidth: true,
    reverse: false,
    labels: {
        padding: 20
    }
};

const modalWidths = {
    big: 'big',
    medium: 'medium',
    sm: 'sm'
};

//Plugin para deixar o background branco, melhora na resolução do pdf
const chartPlugins = [{
    id: 'custom_canvas_background_color',
    beforeDraw: (chart) => {
        const ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}];

let optionsEvolutionChartDefault = {
    legend: {
        display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
        enabled: false
    },
    animation: {

        duration: 500,
        onComplete: function () {
            var chartInstance = this.chart,
                ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            //ctx.font = '9.5px sans-serif'
            ctx.font = '10px sans-serif'
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];

                    ctx.fillStyle = "rgba(255, 255, 255, 1)";
                    ctx.strokeStyle = "rgb(0, 0, 0)";

                    let text = applyMaskAmount(data.y, true);

                    let widthOfText = ctx.measureText(text).width + 10;

                    roundRect(ctx, bar._model.x - (widthOfText / 2), (chartInstance.chart.height / 2) - 20,
                        widthOfText, //width
                        25, //height
                        5, true, true);

                    ctx.fillStyle = "rgba(0, 0, 0, 1)";
                    ctx.fillText(text, bar._model.x, (chartInstance.chart.height / 2));
                    //ctx.fillText(applyMaskAmount(data.y, true), bar._model.x, bar._model.y - 1);
                });
            });
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                //beginAtZero: true,

                max: 1000000000
            },

            display: false,
            gridLines: {
                display: true
            }
        }],
        xAxes: [{
            barPercentage: 0.5
        }]
    },

}

const optionsCompareChart = {
    legend: {
        display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
        enabled: false
    },
    animation: {
        duration: 500,
        onComplete: function () {
            var chartInstance = this.chart,
                ctx = chartInstance.ctx;
            console.log("CTX: ", ctx);
            //ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';


            var mapPositions = [];
            var mapValuesLine = [];
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);


                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    //console.log("DATA COMPARE: ", data);

                    if (data && data.y != null) {

                        //ctx.fillText(applyMaskAmount(data.y) + "%", bar._model.x, bar._model.y + 20);
                        //ctx.fillRect(0, 0, bar._model.x, bar._model.y + 20);

                        //mapPositions.push(bar._model.y + 20);
                        console.log("INCLUINDO: ", data.y)
                        mapValuesLine.push(applyMaskAmount(data.y) + "%");
                    }
                    if (!data || data.y == null) {

                        //console.log("Bar model: ", bar._model.y);
                        ctx.fillStyle = "rgba(255, 255, 255, 1)";
                        ctx.strokeStyle = "rgb(0, 0, 0)";

                        const separator = '|';

                        const dataToShow = data ? applyMaskAmount(data) + "%" : '0,00%';

                        //let widthOfText = ctx.measureText(applyMaskAmount(data) + "%").width + 20;
                        //let widthOfText = ctx.measureText(applyMaskAmount(data) + "%" + separator + mapValuesLine[index]).width + 10;
                        let widthOfText = ctx.measureText(dataToShow + separator + mapValuesLine[index]).width + 12;

                        roundRect(ctx, bar._model.x - (widthOfText / 2), (chartInstance.chart.height / 2) - 20,
                            widthOfText, //width
                            25, //height
                            5, true, true);

                        //ctx.fillRect(bar._model.x - 45, bar._model.y + (115 - bar._model.y) - 18, 90, 20)

                        //ctx.fillRect(bar._model.x - 45, (chartInstance.chart.height / 2) - 20, widthOfText + 5, 30)

                        //ctx.fillStyle = "rgba(0, 0, 0, 1)";
                        ctx.fillStyle = "#000000";
                        //ctx.fillText(mapValuesLine[index] + " - " + applyMaskAmount(data) + "%", bar._model.x, (chartInstance.chart.height / 2));

                        ctx.textAlign = 'left';
                        ctx.fillStyle = unoTheme.chartColors[0];
                        ctx.fillText(dataToShow, bar._model.x + 2.5, (chartInstance.chart.height / 2));
                        ctx.textAlign = 'center';
                        ctx.fillStyle = "#000000";
                        ctx.fillText(separator, bar._model.x, (chartInstance.chart.height / 2));
                        ctx.textAlign = 'right';
                        ctx.fillStyle = unoTheme.chartColors[1];
                        ctx.fillText(mapValuesLine[index], bar._model.x - 2.5, (chartInstance.chart.height / 2));

                        //ctx.fillRect(bar._model.x - 10, bar._model.x + 10, bar._model.y + (115 - bar._model.y) - 10, bar._model.y + (115 - bar._model.y) + 10);


                    }

                });

            });

            console.log("Map position: ", mapPositions);
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            },
            display: false,
            gridLines: {
                display: true
            }
        }],
        xAxes: [{
            barPercentage: 0.5
        }]
    }
};

const optionsChartRentsMonthCompareFunds = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    //return applyMaskAmount(value, true);
                    // return formatMillionNumber(value);
                    return value + '%';
                }
            }
        }],
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel) + '%';
            }
        }
    },

}

const optionsChartPlEvolutionCompareFunds = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    //return applyMaskAmount(value, true);
                    // return formatMillionNumber(value);
                    return formatMillionNumber(value);
                }
            }
        }],
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel, true);
            }
        }
    },

}

const DEFAULT_MODAL_WIDTH = modalWidths.medium;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
    },

    progressAndMessage: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),

        '& .MuiCircularProgress-root': {
            width: '20px !important',
            height: '20px !important',
            color: unoTheme.mainColor,
            marginRight: theme.spacing(2),
        }
    },

    displayChart: {

    },

    displayChartCompareFunds: {
        height: '300px',
    }
}));

export default function ReportLoadModal(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)
    const reportLoad = useSelector(state => state.reportLoad)

    //Distribuition
    const [dataToDistribuitionChart, setDataToDistribuitionChart] = useState(undefined);

    const chartDistribuitionRef = useRef(null);

    //Dashboard
    const [dataToEvolutionPlChart, setDataToEvolutionPlChart] = useState(undefined);
    const [optionsEvolutionChart, setOptionsEvolutionChart] = useState(undefined);
    const [dataToCompareChart, setDataToCompareChart] = useState(undefined);

    const [dataToRentsMonthCompareFundsChart, setDataToRentsMonthCompareFundsChart] = useState(undefined);
    const [dataToPlEvolutionCompareFundsChart, setDataToPlEvolutionCompareFundsChart] = useState(undefined);

    const chartEvolutionPlRef = useRef(null);
    const chartCompareRef = useRef(null);
    const chartRentsMonthCompareFundsRef = useRef(null);
    const chartPlEvolutionCompareFundsRef = useRef(null);

    const [modalWidth, setModalWidth] = useState(DEFAULT_MODAL_WIDTH);
    const [innerReportLoadMessage, setInnerReportLoadMessage] = useState();

    function getMonthsPeriod(startDate, endDate) {
        const responseStart = getDayMonthYearByStringDate(startDate);
        const responseEnd = getDayMonthYearByStringDate(endDate);

        const monthsPeriod = [];

        if (responseEnd.month - responseStart.month == 2) { //Quarter
            for (let index = responseStart.month; index <= responseEnd.month; index++) {
                monthsPeriod.push(getShortMonthName(index));
            }
        } else if (responseEnd.month - responseStart.month == 5) { //Semester
            if (responseEnd.month == 6) {
                monthsPeriod.push('1º Tri.')
                monthsPeriod.push('2º Tri.')
                monthsPeriod.push('1º Semestre')
            } else {
                monthsPeriod.push('3º Trim.')
                monthsPeriod.push('4º Tri.')
                monthsPeriod.push('2º Semestre')
            }
        }

        return monthsPeriod;
    }

    function getPeriodStringReport(startDate, endDate) {


        console.log('getPeriodStringReport: ', startDate);
        console.log('getPeriodStringReport: ', endDate);
        const responseStartDateMonthYear = getDayMonthYearByStringDate(startDate);
        const { month, year } = getDayMonthYearByStringDate(endDate);

        if (responseStartDateMonthYear.month == month && responseStartDateMonthYear.year == year) {
            //isMonthly
            return getMonthName(month) + "/" + year;
        } else {
            return getMonthName(responseStartDateMonthYear.month) + " a " + getMonthName(month) + " de " + year;
        }

    }

    /**
   * @param {import('../../API/report').DataToReportAPI} data Dados do gráfico formatados para a biblioteca Chart.js.
   * @param {import('../../API/report').ReportTypeAPI} reportType Tipo de relatório.
   * @param {string} fileName
   * @param {number} count Contagem para nomeação de imagens do gráfico.
   * @param {import('chart.js').ChartLegendOptions} legend
   */
    async function saveChartImageToReport(
        data,
        reportType,
        fileName,
        count,
        legend
    ) {
        return saveChartTmpImage(
            clientOn.client.id,
            data,
            count,
            fileName,
            reportType,
            legend
        );
    }

    async function deprecatedSaveChartImageToReport(chartRef, index, fileName) {
        const base64Image = chartRef.current.chartInstance.toBase64Image();

        let response = await deprecatedSaveChartTmpImage(
            clientOn.client.id,
            base64Image,
            index,
            fileName
        );

        if (response.success) {
            return {
                success: true,
            };
        } else {
            console.error("Falha ao salvar a imagem do report");
        }
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //Necessário pra dar uma margem no gráfico a ser desenhado, possibilitando escrever valor por cima da coluna
    function getOptionsToEvolutionChart(maxValue, numLabels) {

        console.log("MAX VALUE: ", maxValue)
        if (maxValue < 1000000) {

            maxValue += 200000;

        } else if (maxValue >= 10000000 && maxValue < 100000000) {

            maxValue += 5000000;

        } else if (maxValue >= 100000000) {

            maxValue += 10000000;

        }

        optionsEvolutionChartDefault.scales.yAxes[0].ticks.max = maxValue;

        console.log("NUM LABELS: ", numLabels);
        console.log("NUM LABELS: ", optionsEvolutionChartDefault.scales.yAxes[0].ticks);
        if (numLabels <= 6) {
            optionsEvolutionChartDefault.scales.yAxes[0].ticks.beginAtZero = true;
        }

        return optionsEvolutionChartDefault;

    }

    // async function prepareChartsAndGenerate() {

    //     const finalPath = await getFullReportPath(null, null, reportLoad.reportTypeList);

    //     openReport(finalPath);

    // }

    /**
  * @typedef {import ("../../actions/reportLoadActions").ReportLoadObj & {
  *    startDate: string;
    *    endDate: string;
    * }} ConfigReport
    * @param {ConfigReport} configReport
    */
    async function prepareChartsAndGenerate(configReport) {

        let { startDate, endDate, periodReport, reportTypeList } = configReport;

        //Obtendo informações da consultoria
        const consultingInfos = await getConsultingInfosById(
            currentUser.user.consulting_id
        );
        console.log("consultingInfos: ", consultingInfos);
        clientOn.client.consultingName = consultingInfos
            ? consultingInfos.short_name
            : "LEMA";

        //Chamadas de report nas telas, deve executar como report mensal para o mês selecionado
        if (!startDate && !endDate) {
            startDate = getFirstPtDayMonth(clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear);
            endDate = getLastPtDayMonth(clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear);

            periodReport = 'mensal';
        }

        console.log("startDate: ", startDate);
        console.log("endDate: ", endDate);

        setModalWidth(DEFAULT_MODAL_WIDTH);

        //Variáveis utilizadas no caso do full report
        let dataDistribuition = null;
        let dataDashboard = null;

        const isReportTypeFullReport = reportLoad.reportType == 'fullReport';
        const isReportTypeDistribution = reportLoad.reportType == 'distribuition';
        const isReportTypeSummarised = reportLoad.reportType == 'dashboard';
        const isReportTypeCustomWithDistribuition = reportTypeList && reportTypeList.includes('distribuition');
        const isReportTypeCustomWithDashboard = reportTypeList && reportTypeList.includes('dashboard');
        const isReportTypeCompareFunds = reportLoad.reportType == 'compareFunds';
        //const periodReport = reportLoad.periodReport;

        if (isReportTypeFullReport || isReportTypeDistribution) {
            setInnerReportLoadMessage("Gerando relatório de distribuição...");
        }

        if (
            isReportTypeDistribution ||
            isReportTypeFullReport ||
            isReportTypeCustomWithDistribuition
        ) {
            //Relatório de distribuição necessita de um gráfico que é previamente redenrizado,
            //fotografado e enviado para o servidor para ser usado no relatório

            let responseDataToReport = await getDataToDistribuitionReport(
                clientOn.client.id,
                startDate,
                endDate
            );

            /**
             * @type {import('../../controllers/ReportController').DataToReport['dataToTable']}
             */
            let dataToTables = [];
            for (
                let i = 0;
                i < responseDataToReport.dataToDistributionReport.length;
                i++
            ) {
                const count = i + 1;
                let dataLoop = responseDataToReport.dataToDistributionReport[i];

                dataToTables.push(dataLoop["dataToTable"]);

                /**
                 * @type {import('../../API/report').DataToReportAPI}
                 */
                const dataToChart = {
                    chart: dataLoop.dataToChart,
                };
                await saveChartImageToReport(
                    dataToChart,
                    'distribuition',
                    'chartDistribution',
                    count,
                    legendPieChartOpts
                );
            }
            dataDistribuition = dataToTables;
        }

        if (isReportTypeFullReport) {
            setInnerReportLoadMessage("Gerando relatório de evolução do patrimônio...");
        }

        //Gerando gráficos para dashboard report
        if (isReportTypeSummarised || isReportTypeFullReport || isReportTypeCustomWithDashboard) {
            let responseDataToReport = await getDataToDashboardReport(clientOn.client.id,
                startDate, endDate, periodReport);

            if (responseDataToReport.success) {
                /**
                 * @type {import('../../API/report').DataToReportAPI}
                 */
                const dataToChartEvolution = {
                    chart: responseDataToReport.dataToChartEvolution,
                    maxValue: responseDataToReport.maxValue,
                };

                await saveChartImageToReport(
                    dataToChartEvolution,
                    "plEvolution",
                    "chartEvolutionPl"
                );

                if (isReportTypeFullReport) {
                    setInnerReportLoadMessage("Gerando relatório comparativo...");
                }

                /** @type {import('../../API/report').DataToReportAPI} */
                const dataChartComparative = {
                    chart: responseDataToReport.dataToChartCompare,
                };
                await saveChartImageToReport(
                    dataChartComparative,
                    "comparative",
                    "chartCompare"
                );

                dataDashboard = responseDataToReport.dataToReport;
            }
        }

        //Comparador de Fundos
        if (isReportTypeCompareFunds) {
            setModalWidth(modalWidths.big);

            const { chartRentsMonthData, chartPlEvolutionData, tableData } =
                reportLoad;
            setDataToRentsMonthCompareFundsChart(chartRentsMonthData);

            //Tempo necessário para o gráfico ser renderizado na tela e ser possível fotografá-lo pela referência
            await timeout(800);
            const nameResponseSaveImg1 = "chartRentsMonthCompareFunds";
            const responseSaveImg1 = await deprecatedSaveChartImageToReport(
                chartRentsMonthCompareFundsRef,
                null,
                nameResponseSaveImg1
            );
            setDataToRentsMonthCompareFundsChart(null);

            await timeout(200);
            setDataToPlEvolutionCompareFundsChart(chartPlEvolutionData);
            await timeout(800);
            const nameResponseSaveImg2 = "chartPlEvolutionCompareFunds";
            const responseSaveImg2 = await deprecatedSaveChartImageToReport(chartPlEvolutionCompareFundsRef, null, nameResponseSaveImg2);

            await timeout(200);
            setDataToPlEvolutionCompareFundsChart(null);
        }

        setModalWidth(DEFAULT_MODAL_WIDTH);

        setInnerReportLoadMessage("Gerando relatório...");

        //Comparador de Fundos
        if (isReportTypeCompareFunds) {
            let pathToReport = await getCompareFundsReportPath(reportLoad.fundsInCompare, reportLoad.benchamrksInCompare, reportLoad.tableData);
            console.log('pathToReport: ', pathToReport);
            if (pathToReport != null) {
                openReport(pathToReport);
            }
        } else {
            let pathToReport = await getPathToReport(dataDashboard, dataDistribuition, startDate, endDate, periodReport, reportTypeList, reportLoad.reportData);
            if (pathToReport != null) {
                openReport(pathToReport);
            }
        }

        setInnerReportLoadMessage();
    }

    async function getPathToReport(...data) {


        const [
            dataDashboard,
            dataDistribuition,
            startDate,
            endDate,
            periodReport,
            reportTypeList,
            reportData //ipc14
        ] = data;

        console.log("data getPathToReport: ", data);
        console.log("START DATE getPathToReport: ", startDate);
        console.log("END DATE getPathToReport: ", endDate);
        console.log("reportLoad.reportType: ", reportLoad.reportType);

        let finalPath = null;

        switch (reportLoad.reportType) {
            case 'portfolio':
                finalPath = await getPortfolioReportPath(reportLoad.accountRegimeId, startDate, endDate, periodReport); //Adicionando parâmetro de accountRegimeId
                break;
            case 'assetsAndAccounts':
                finalPath = await getPortfolioReportPath(reportLoad.accountRegimeId, startDate, endDate, periodReport, {
                    isAssetAndAccountsReport: true,
                });
                break;
            case 'transactionsInPeriod':
                finalPath = await getPortfolioReportPath(reportLoad.accountRegimeId, startDate, endDate, periodReport, {
                    isAccumulatedTrasactionsReport: true,
                });
                break;
            case 'provisional':
                finalPath = await getProvisionalReportPath(startDate, endDate, periodReport); //Adicionando parâmetro de accountRegimeId
                break;
            case 'dashboard':
                finalPath = await getDashboardReportPath(dataDashboard, startDate, endDate);
                break;
            case 'distribuition':
                finalPath = await getDistribuitionReportPath(dataDistribuition, startDate, endDate);
                break;
            case 'articles':
                finalPath = await getArticlesReportPath(startDate, endDate);
                break;
            case 'targets':
                finalPath = await getTargetsReportPath(startDate, endDate, periodReport);
                break;
            case 'myFunds':
                finalPath = await getMyFundsReportPath(startDate, endDate, periodReport);
                break;
            case 'myTitles':
                finalPath = await getMyTitlesReportPath(startDate, endDate);
                break;
            case 'myReturns':
                finalPath = await getMyReturnsReportPath(startDate, endDate);
                break;
            case 'custom':
            case 'fullReport':
                finalPath = await getFullReportPath(dataDashboard,
                    dataDistribuition,
                    reportTypeList,
                    startDate,
                    endDate,
                    periodReport);
                break;
            case 'apr':
                finalPath = await getAprReportPath();
                break;
            case 'singleApr':
                finalPath = await getSingleAprReportPath();
                break;
            case 'batchAprs':
                finalPath = await getBatchAprsReportPath();
                break;
            case 'fundsRanking':
                finalPath = await getFundsRankingReportPath();
                break;
            case 'demonstrative':
                finalPath = await getDemonstrativeReportPath();
                break;
            case 'risk':
                finalPath = await getRiskReportPath(startDate, endDate);
                break;
            case 'ipc14_returns':
                finalPath = await getIPC14ReturnsReportPath(reportData);
                break;
        }
        return finalPath;
    }

    function openReport(pathToReport) {


        const href = REPORTS_URL + 'reports/'
            + clientOn.client.id + pathToReport;

        Object.assign(document.createElement('a'), {
            target: '_blank', href
        }
        ).click();

        dispatch(allActions.reportLoadActions.setReportLoad(null))

    }

    async function getPortfolioReportPath(accountRegimeId, startDate, endDate, periodReport,
        {
            isAssetAndAccountsReport = false,
            isAccumulatedTrasactionsReport = false
        } = {}) {

        console.log('periodReport in report modal: ', periodReport);

        let responseDataToReport = await getDataToPortfolioReport(clientOn.client.id,
            startDate,
            endDate,
            accountRegimeId, periodReport, {
            isAssetAndAccountsReport,
            isAccumulatedTrasactionsReport
        });

        const { monthBefore, yearBefore } = getMonthAndYearBefore(startDate);

        let responseReport = await
            getPortfolioReport(clientOn.client,
                (reportLoad.regimeLabel ? reportLoad.regimeLabel + ' - ' : '') + getPeriodStringReport(startDate, endDate),
                responseDataToReport.dataToPortfolioReport, {
                isAssetAndAccountsReport,
                isAccumulatedTrasactionsReport,
                startDate: getLastPtDayMonth(monthBefore, yearBefore),
                endDate,
            });

        if (responseReport.success) {

            return responseReport.body.data.pathToReport;

        } else {

            return false;

        }

    }

    async function getProvisionalReportPath(startDate, endDate, periodReport) {

        const responseDataToReport = await getDataToProvisonalReport(clientOn.client.id,
            startDate,
            endDate);

        const promises = [];
        //Adicionando capa ao relatório provisório
        promises.push(
            new Promise(async (resolve, reject) => {
                let path = await getCapaReportPath(startDate, endDate, "RELATÓRIO PROVISÓRIO");

                if (path) {

                    resolve({
                        path,
                        index: 0,
                    });

                } else {

                    resolve(false);

                }
            })
        )

        promises.push(
            new Promise(async (resolve, reject) => {
                const response = await
                    getProvisionalReport(clientOn.client, 'Cota referência: ' + responseDataToReport.referenceQuotaDate + ' - ' + getPeriodStringReport(startDate, endDate),
                        responseDataToReport.dataToReport, 1);

                if (response.success) {

                    resolve({
                        path: response.body.data.pathToReport,
                        index: 1,
                    });

                } else {

                    resolve(false);

                }
            })
        )

        const responseAll = await Promise.all(promises);
        let pathsToSend = [];

        console.log('responseAll: ', responseAll);

        if ((responseAll.filter(el => !el)).length == 0) {

            for (let i = 0; i < responseAll.length; i++) {

                if (responseAll[i] != NO_DATA) {//removendo os que não possuem dados, como no caso em que não há títulos

                    pathsToSend.push(responseAll[i].path);

                }

            }

            const responseProvisionalReport = await getFullReport(clientOn.client, pathsToSend, "RELATORIO_PROVISORIO");

            if (responseProvisionalReport.success) {
                return responseProvisionalReport.body.data.pathToReport
            } else {
                return false;
            }
        }

    }

    async function getCapaReportPath(startDate, endDate, subTitle) {

        const period = getPeriodStringReport(startDate, endDate);
        let responseCapaReport = await getCapaReport(
            clientOn.client,
            period, subTitle);

        if (responseCapaReport.success) {

            return responseCapaReport.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getDashboardReportPath(data, startDate, endDate) {

        const period = getPeriodStringReport(startDate, endDate);

        let responseDashboardReport = await getDashboardReport(
            clientOn.client,
            period,
            data);

        if (responseDashboardReport.success) {

            return responseDashboardReport.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getRiskReportPath(startDate, endDate) {

        const promises = [];
        const period = getPeriodStringReport(startDate, endDate);

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            updateMessage: true,
            message: 'Gerando relatório de risco... ( 1/4 )'
        }))

        //Market
        const dataMarket = await RiskController.getMarketData(clientOn.client.id, endDate);
        promises.push(new Promise(async (resolve, reject) => {
            const responsePart = await getRiskMarketReport(clientOn.client,
                period,
                dataMarket);

            if (responsePart.success) {

                resolve(responsePart.body.data.pathToReport);

            } else {

                console.log("ERRO AO TRAZER PATH");
                resolve(false);

            }
        }))

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            updateMessage: true,
            message: 'Gerando relatório de risco... ( 2/4 )'
        }))


        //Articles
        const dataArticles = await RiskController.getArticlesData(clientOn.client.id, endDate);
        promises.push(new Promise(async (resolve, reject) => {
            const responsePart = await getRiskArticlesReport(clientOn.client,
                period,
                dataArticles);

            if (responsePart.success) {

                resolve(responsePart.body.data.pathToReport);

            } else {

                console.log("ERRO AO TRAZER PATH");
                resolve(false);

            }
        }))

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            updateMessage: true,
            message: 'Gerando relatório de risco... ( 3/4 )'
        }))

        //Benchmarks
        const dataBenchmarks = await RiskController.getBenchmarksData(clientOn.client.id, endDate);
        promises.push(new Promise(async (resolve, reject) => {
            const responsePart = await getRiskBenchmarksReport(clientOn.client,
                period,
                dataBenchmarks);

            if (responsePart.success) {

                resolve(responsePart.body.data.pathToReport);

            } else {

                console.log("ERRO AO TRAZER PATH");
                resolve(false);

            }
        }))

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            updateMessage: true,
            message: 'Gerando relatório de risco... ( 4/4 )'
        }))

        //Policy
        const dataPolicy = await RiskController.getPolicyData(clientOn.client, endDate);
        promises.push(new Promise(async (resolve, reject) => {
            const responsePart = await getRiskPolicyReport(clientOn.client,
                period,
                dataPolicy);

            if (responsePart.success) {

                resolve(responsePart.body.data.pathToReport);

            } else {

                console.log("ERRO AO TRAZER PATH");
                resolve(false);

            }
        }))

        const responseParts = await Promise.all(promises)

        //return responseParts[0];

        const responseReportFull = await
            getFullRiskReport(clientOn.client, responseParts);

        if (responseReportFull.success) {

            return responseReportFull.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getIPC14ReturnsReportPath(data) {

        const response = await getIPC14ReturnsReport(clientOn.client, data);

        if (response.success) {

            return response.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getDistribuitionReportPath(data, startDate, endDate) {

        console.log("START DATE: ", startDate);
        console.log("END DATE: ", endDate);
        const period = getPeriodStringReport(startDate, endDate);

        //Temporário
        let pageIndex = 1;

        //Verificando se os dados das duas primeiras distribuições podem ser agrupados numa mesma página
        const limitLines = 8;
        let group12 = false;
        if (data[0].data.length <= limitLines
            && data[1].data.length <= limitLines) {
            group12 = true;
        }
        //Verificando se os dados da terceira e da quarta distribuição podem ser agrupados numa mesma página
        let group34 = false;
        if (data[2].data.length <= limitLines
            && data[3].data.length <= limitLines) {
            group34 = true;
        }

        let responseParts = null;

        console.log('group12: ', group12);
        console.log('group34: ', group34);
        if (!group12 && !group34) {
            responseParts = await Promise.all(

                data.map(async (part, index) => {
                    let responsePart = await getDistribuitionReportPart(clientOn.client,
                        period,
                        part, index + 1, pageIndex + index);

                    if (responsePart.success) {

                        return responsePart.body.data.pathToReport;

                    } else {

                        console.log("ERRO AO TRAZER PATH");
                        return false;

                    }

                })

            )
        } else if (group12 && !group34) { //agrupar os dois primeiros e não agrupar o 3 e o 4

            let promises = [];

            promises.push(new Promise(async (resolve, reject) => {
                let responsePart = await getDistribuitionReportPartGrouped(clientOn.client,
                    period,
                    data[0],
                    data[1],
                    1, 2, pageIndex);

                if (responsePart.success) {

                    resolve(responsePart.body.data.pathToReport);

                } else {

                    console.log("ERRO AO TRAZER PATH");
                    resolve(false);

                }
            }))

            pageIndex++;

            let newData = data.slice(2);
            newData.map(async (part, index) => {

                promises.push(
                    new Promise(async (resolve, reject) => {
                        let responsePart = await getDistribuitionReportPart(clientOn.client,
                            period,
                            part, index + 3, pageIndex + index);

                        if (responsePart.success) {

                            resolve(responsePart.body.data.pathToReport);

                        } else {
                            resolve(false);
                        }
                    })
                )

            })

            responseParts = await Promise.all(promises)

        } else if (group12 && group34) { //Agrupar os dois primeiros, e o terceiro com o quarto

            let promises = [];

            promises.push(new Promise(async (resolve, reject) => {
                let responsePart = await getDistribuitionReportPartGrouped(clientOn.client,
                    period,
                    data[0],
                    data[1],
                    1, 2, pageIndex);

                if (responsePart.success) {

                    console.log("Resolvido 12");

                    resolve(responsePart.body.data.pathToReport);

                } else {

                    console.log("ERRO AO TRAZER PATH");
                    resolve(false);

                }
            }))

            pageIndex++;

            promises.push(new Promise(async (resolve, reject) => {
                let responsePart = await getDistribuitionReportPartGrouped(clientOn.client,
                    period,
                    data[2],
                    data[3],
                    3, 4, pageIndex);

                if (responsePart.success) {

                    console.log("Resolvido 34");

                    resolve(responsePart.body.data.pathToReport);

                } else {

                    console.log("ERRO AO TRAZER PATH");
                    resolve(false);

                }
            }))

            pageIndex++;

            promises.push(
                new Promise(async (resolve, reject) => {
                    let responsePart = await getDistribuitionReportPart(clientOn.client,
                        period,
                        data[4], 5, pageIndex);

                    if (responsePart.success) {

                        console.log("Resolvido 5");
                        resolve(responsePart.body.data.pathToReport);

                    } else {
                        resolve(false);
                    }
                })
            )

            promises.push(
                new Promise(async (resolve, reject) => {
                    let responsePart = await getDistribuitionReportPart(clientOn.client,
                        period,
                        data[5], 6, pageIndex);

                    if (responsePart.success) {

                        console.log("Resolvido 5");
                        resolve(responsePart.body.data.pathToReport);

                    } else {
                        resolve(false);
                    }
                })
            )

            responseParts = await Promise.all(promises);
        } else if (!group12 && group34) { //não agrupar o 1 e o 2 e agrupar o 3 e o 4

            let promises = [];

            const newData = data.slice(0, 2);

            newData.map(async (part, index) => {

                promises.push(
                    new Promise(async (resolve, reject) => {
                        let responsePart = await getDistribuitionReportPart(clientOn.client,
                            period,
                            part, index + 1, pageIndex + index);

                        if (responsePart.success) {

                            resolve(responsePart.body.data.pathToReport);

                        } else {
                            resolve(false);
                        }
                    })
                )

            })

            pageIndex++;

            promises.push(new Promise(async (resolve, reject) => {
                let responsePart = await getDistribuitionReportPartGrouped(clientOn.client,
                    period,
                    data[2],
                    data[3],
                    3, 4, pageIndex);

                if (responsePart.success) {

                    console.log("Resolvido 34");

                    resolve(responsePart.body.data.pathToReport);

                } else {

                    console.log("ERRO AO TRAZER PATH");
                    resolve(false);

                }
            }))

            pageIndex++;

            promises.push(
                new Promise(async (resolve, reject) => {
                    let responsePart = await getDistribuitionReportPart(clientOn.client,
                        period,
                        data[4], 5, pageIndex);

                    if (responsePart.success) {

                        console.log("Resolvido 5");
                        resolve(responsePart.body.data.pathToReport);

                    } else {
                        resolve(false);
                    }
                })
            )

            promises.push(
                new Promise(async (resolve, reject) => {
                    let responsePart = await getDistribuitionReportPart(clientOn.client,
                        period,
                        data[5], 6, pageIndex);

                    if (responsePart.success) {

                        console.log("Resolvido 5");
                        resolve(responsePart.body.data.pathToReport);

                    } else {
                        resolve(false);
                    }
                })
            )

            responseParts = await Promise.all(promises);
        }

        console.log("Response partes: ", responseParts);

        let responseReportFull = await
            getDistribuitionReport(clientOn.client, responseParts);

        if (responseReportFull.success) {

            return responseReportFull.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getArticlesReportPath(startDate, endDate) {

        let responseDataToReport = await getDataToArticleReport(clientOn.client, startDate, endDate);

        const period = getPeriodStringReport(startDate, endDate);

        let responseArticles = null;
        //Verificação de versão de RESOLUÇÃO
        if (responseDataToReport.dataToArticlesReport[4]
            && responseDataToReport.dataToArticlesReport[5]
            && responseDataToReport.dataToArticlesReport[6]
            && responseDataToReport.dataToArticlesReport[7]
            && responseDataToReport.dataToArticlesReport[8]
            && responseDataToReport.dataToArticlesReport[9]) {


            //V2
            responseArticles = await getArticlesV2Report(
                clientOn.client,
                responseDataToReport.dataToArticlesReport,
                period,
            );
        } else {
            //V1
            responseArticles = await getArticlesReport(
                clientOn.client,
                responseDataToReport.dataToArticlesReport,
                period);
        }




        if (responseArticles.success) {

            return responseArticles.body.data.pathToReport;

        } else {

            return false;
        }

    }

    async function getTargetsReportPath(startDate, endDate, periodReport) {
        let responseDataToReport = await getDataToTargetsReport(clientOn.client,
            startDate, endDate, periodReport);

        const period = getPeriodStringReport(startDate, endDate);

        if (responseDataToReport.success) {
            let responseTargetsReport = await getTargetsReport(
                clientOn.client,
                period,
                responseDataToReport.dataToReport);

            if (responseTargetsReport.success) {
                return responseTargetsReport.body.data.pathToReport;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    async function getMyFundsReportPath(startDate, endDate, periodReport) {

        const segmentId = reportLoad.segmentId ? reportLoad.segmentId : 0;

        const period = getPeriodStringReport(startDate, endDate);

        //let responseDataToReport = await getDataToMyFundsReport(clientOn.client.id,
        let responseDataToReport = await getDataToMyFundsReport(
            clientOn.client,
            startDate,
            endDate,
            segmentId,
            periodReport
        );

        console.log("DATA MY FUNDS REPORT: ", responseDataToReport);

        if (responseDataToReport.success) {

            let responseTargetsReport = await getMyFundsReport(
                clientOn.client,
                period,
                responseDataToReport.dataToReport,
                periodReport, getMonthsPeriod(startDate, endDate));

            if (responseTargetsReport.success) {

                return responseTargetsReport.body.data.pathToReport;

            } else {

                return false;
            }


        } else {

            return false;

        }
    }

    async function getMyTitlesReportPath(startDate, endDate) {
        let responseDataToReport = await getDataToMyTitlesReport(clientOn.client.id,
            startDate, endDate);

        const period = getPeriodStringReport(startDate, endDate);

        console.log("DATA MY TITLES REPORT: ", responseDataToReport);
        if (responseDataToReport.success && responseDataToReport.dataToReport.length == 0) {
            return NO_DATA;
        }

        if (responseDataToReport.success) {

            const responseMyTitlesReport = await getMyTitlesReport(
                clientOn.client,
                period,
                responseDataToReport.dataToReport);

            if (responseMyTitlesReport.success) {

                return responseMyTitlesReport.body.data.pathToReport;

            } else {

                return false;
            }


        } else {

            return false;

        }
    }

    async function getFundsRankingReportPath(pageIndex = 1) {
        let responseFundsRankingReport = await getDataToFundsRankingReport();

        const isResponseDataToReportFailure = responseFundsRankingReport.isSuccessful === FAILURE;
        if (isResponseDataToReportFailure) return FAILURE;

        const dataToFundsRankingReport = responseFundsRankingReport.dataToFundsRankingReport;

        let dateNow = new Date();
        const period = getMonthName(dateNow.getMonth() + 1) + "/" + dateNow.getFullYear();
        const client = clientOn.client;

        let responseFundsRankingReportPath = await getFundsRankingReport(
            client,
            period,
            dataToFundsRankingReport,
            pageIndex);

        if (responseFundsRankingReportPath.success) {
            const pathToReport = responseFundsRankingReportPath.body.data.pathToReport;
            return pathToReport;
        }
        else {
            return FAILURE;
        }
    }

    async function getDemonstrativeReportPath() {

        const responseDataToReport = await getDataToDemonstrativeReport(clientOn.client, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear);
        // const responseDataToReport = {}
        // responseDataToReport.success = true;
        // //responseDataToReport.dataToReport = fundsTest;
        // responseDataToReport.dataToReport = fundsTest.slice(0, 3);
        // console.log("TO DEMONSTRATIVE REPORT: ", responseDataToReport);

        let index1 = 0;
        let index2 = 1;

        const countPages = responseDataToReport.dataToReport.length % 2 == 1 ? (responseDataToReport.dataToReport.length + 1) / 2 : responseDataToReport.dataToReport.length / 2;

        console.log('responseDataToReport: ', responseDataToReport.dataToReport);
        console.log('responseDataToReport.dataToReport: ', responseDataToReport.dataToReport.length);
        console.log('countPages: ', countPages);

        let finishedDemonstrativePages = 0;
        const demonstrativePromises = [];
        for (let index = 1; index <= countPages; index++) {

            const dataToReport = [
                responseDataToReport.dataToReport[index1],
                responseDataToReport.dataToReport[index2] ? responseDataToReport.dataToReport[index2] : null
            ]
            console.log('########');
            console.log('index1: ', index1);
            console.log('index2: ', index2);
            index1 += 2;
            index2 += 2;

            demonstrativePromises.push(
                await timeout(500)
            );
            demonstrativePromises.push(
                new Promise(async (resolve, reject) => {

                    const responseDemonstrativeReport = await getDemonstrativeReport(
                        clientOn.client,
                        getMonthName(clientOn.client.selectedPortfolioMonth) + '/' + clientOn.client.selectedPortfolioYear,
                        dataToReport);

                    finishedDemonstrativePages++;

                    dispatch(allActions.reportLoadActions.setReportLoad({
                        show: true,
                        updateMessage: true,
                        message: 'Gerando demonstrativo... ( ' + Number.parseInt(finishedDemonstrativePages / countPages * 100) + '% )'
                    }))

                    if (responseDemonstrativeReport.success) {

                        resolve(responseDemonstrativeReport.body.data.pathToReport)

                    } else {

                        console.error("ERRO AO GERAR APR");
                        console.log("responseApr: ", responseDemonstrativeReport);
                        console.error("--------------");

                        resolve(false)

                    }

                })
            )

        }

        const pathsDemonstrative = await Promise.all(demonstrativePromises)
        console.log('pathsDemonstrative: ', pathsDemonstrative);

        //Retirando timeouts
        const realPaths = pathsDemonstrative.filter(el => el !== undefined && el !== null && el !== '');
        console.log('realPaths: ', realPaths);
        if (realPaths && realPaths.length === countPages) {

            const responseFullDemonstrative = await getFullDemonstrativeReport(clientOn.client, realPaths);

            if (responseFullDemonstrative.success) {

                Object.assign(document.createElement('a'), {
                    target: '_blank', href: REPORTS_URL + 'reports/'
                        + clientOn.client.id + responseFullDemonstrative.body.data.pathToReport
                }).click();

                dispatch(allActions.reportLoadActions.setReportLoad(null))

            } else {

                console.log("Erro ao gerar report apr 1");
                dispatch(allActions.reportLoadActions.setReportLoad(null))

            }

        } else {

            dispatch(allActions.reportLoadActions.setReportLoad(null))
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'Falha ao gerar demonstrativo',
                type: 'warning',
            }))
        }

    }

    async function getMyReturnsReportPath(pageIndex = 1) {
        let responseDataToReport = await getDataToMyClientReturns(clientOn.client.id, clientOn.client.selectedPortfolioMonth, clientOn.client.selectedPortfolioYear, 1);

        if (responseDataToReport.isSuccessful) {
            const clientReturns = responseDataToReport.clientReturns;

            let period = getMonthName(clientOn.client.selectedPortfolioMonth) + "/" + clientOn.client.selectedPortfolioYear;

            let responseTargetsReport = await getMyReturnsReport(
                clientOn.client,
                period,
                clientReturns, pageIndex);

            if (responseTargetsReport.success) {
                return responseTargetsReport.body.data.pathToReport;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    async function getCompareFundsReportPath(funds, benchmarks, tablesData) {

        const response = await getCompareFundsReport(clientOn.client, funds, benchmarks, tablesData);
        console.log('response PATH: ', response);

        if (response.success) {
            return response.body.data.pathToReport;
        } else {
            return false;
        }

    }

    async function getAprReportPath() {

        let responseDataToReport = await getDataToAprReport(reportLoad.aprId,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear, reportLoad.hideCpf);

        if (responseDataToReport.success) {

            let responseApr = await getAprReport(
                clientOn.client,
                responseDataToReport.dataToReport);

            if (responseApr.success) {

                console.log("APR PATH: ", responseApr.body.data.pathAprReport);

                Object.assign(document.createElement('a'), {
                    target: '_blank', href: REPORTS_URL + 'reports/' + clientOn.client.id + '/aprs/' + responseApr.body.data.pathAprReport
                }).click();

                dispatch(allActions.reportLoadActions.setReportLoad(null))

            } else {

                console.log("Erro ao gerar report apr");
                dispatch(allActions.reportLoadActions.setReportLoad(null))

            }

        } else {

            console.log("Erro ao gerar report apr 2: ", responseDataToReport);
            dispatch(allActions.reportLoadActions.setReportLoad(null))
        }

    }

    async function getSingleAprReportPath() {

        let responseDataToReport = await getDataToSingleAprReport(reportLoad.aprId,
            clientOn.client.selectedAdministrativeMonth,
            clientOn.client.selectedAdministrativeYear, reportLoad.hideCpf);

        if (responseDataToReport.success) {

            let responseApr = await getSingleAprReport(
                clientOn.client,
                responseDataToReport.dataToReport);

            if (responseApr.success) {

                Object.assign(document.createElement('a'), {
                    target: '_blank', href: REPORTS_URL + 'reports/' + clientOn.client.id + '/aprs/' + responseApr.body.data.pathAprReport
                }).click();

                dispatch(allActions.reportLoadActions.setReportLoad(null))

            } else {

                console.log("Erro ao gerar report apr");
                dispatch(allActions.reportLoadActions.setReportLoad(null))

            }

        } else {

            console.log("Erro ao gerar report apr 2: ", responseDataToReport);
            dispatch(allActions.reportLoadActions.setReportLoad(null))
        }

    }

    async function getBatchAprsReportPath() {

        let responseDataToReport = await getDataToBatchAprsReport(
            clientOn.client.id,
            clientOn.client.selectedPortfolioMonth,
            clientOn.client.selectedPortfolioYear,
            reportLoad.accountRegimeId,
            reportLoad.order,
            reportLoad.yearToSinglesAprs, reportLoad.hideCpf); //yearToSinglesAprs caso não seja nulo é referente a geração de lote das aprs avulsas

        console.log('responseDataToReport: ', responseDataToReport);

        if (responseDataToReport.success) {

            const countAprsToGenerate = responseDataToReport.dataToReport.length;

            const aprsPromises = [];
            let finishedAprs = 0;
            for (let index = 0; index < responseDataToReport.dataToReport.length; index++) {

                const apr = responseDataToReport.dataToReport[index];
                aprsPromises.push(
                    await timeout(500)
                );
                aprsPromises.push(
                    new Promise(async (resolve, reject) => {

                        let responseApr = await getAprReport(
                            clientOn.client,
                            apr);

                        finishedAprs++;

                        setInnerReportLoadMessage('Gerando lote de APRS... ( ' + Number.parseInt(finishedAprs / countAprsToGenerate * 100) + '% )');
                        // dispatch(allActions.reportLoadActions.setReportLoad({
                        //     show: true,
                        //     updateMessage: true,
                        //     message: 'Gerando lote de APRS... ( ' + Number.parseInt(finishedAprs / countAprsToGenerate * 100) + '% )'
                        // }))

                        if (responseApr.success) {

                            resolve(responseApr.body.data.pathAprReport)

                        } else {

                            console.error("ERRO AO GERAR APR");
                            console.log("responseApr: ", responseApr);
                            console.log("apr: ", apr);
                            console.error("--------------");

                            resolve(false)

                        }

                    })
                )

            }

            const pathsAprs = await Promise.all(aprsPromises)

            console.log('pathsAprs: ', pathsAprs);

            //Verificando se houve problema com algum path
            // if (!pathsAprs.some((el, index) => index % 2 !== 0 && !el)) {

            //Retirando timeouts
            const realPaths = pathsAprs.filter(el => el !== undefined && el !== null && el !== '');
            console.log('countAprsToGenerate: ', countAprsToGenerate);
            console.log('realPaths: ', realPaths);
            if (realPaths && realPaths.length === countAprsToGenerate) {

                //let responseBatchApr = await getAprBatchReport(clientOn.client, pathsAprs);
                let responseBatchApr = await getAprBatchReport(clientOn.client, realPaths);

                if (responseBatchApr.success) {

                    console.log("RESPONSE BATCH: ", responseBatchApr);
                    Object.assign(document.createElement('a'), {
                        target: '_blank', href: REPORTS_URL + 'reports/'
                            + clientOn.client.id + responseBatchApr.body.data.pathToReport
                    }).click();

                    dispatch(allActions.reportLoadActions.setReportLoad(null))

                } else {

                    console.log("Erro ao gerar report apr 1");
                    dispatch(allActions.reportLoadActions.setReportLoad(null))

                }

            } else {

                dispatch(allActions.reportLoadActions.setReportLoad(null))
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Falha ao gerar lote de APRS',
                    type: 'warning',
                }))
            }


        } else {
            console.log("Erro ao gerar report apr 2");
            dispatch(allActions.reportLoadActions.setReportLoad(null))
        }

    }

    //Verifica se o report deve ou não ser incluido na geração
    function verifyIncludeReportType(reportTypeList, reportType, removeInFull) {

        if (reportTypeList == null) {
            if (removeInFull) {
                return false;
            } else {
                return true;
            }
        } else {
            return reportTypeList.includes(reportType);
        }

    }

    //Adicionando parametro reportTypeList, para caso de custom report
    async function getFullReportPath(dataDashboard,
        dataDistribuition,
        reportTypeList,
        startDate,
        endDate,
        periodReport) {

        let promises = [];

        const isCustomReport = reportTypeList != null && reportTypeList.length > 0;

        //Adicionando capa
        promises.push(
            new Promise(async (resolve, reject) => {
                let path = await getCapaReportPath(startDate, endDate);

                if (path) {

                    resolve({
                        path,
                        index: 0,
                    });

                } else {

                    resolve(false);

                }
            })
        )

        if (verifyIncludeReportType(reportTypeList, 'dashboard')) {
            //DASHBOARD
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getDashboardReportPath(dataDashboard, startDate, endDate);

                    if (path) {

                        resolve({
                            path,
                            index: 2,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }


        if (verifyIncludeReportType(reportTypeList, 'portfolio')) {
            //PORTFOLIO
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getPortfolioReportPath(null, startDate, endDate, periodReport);

                    if (path) {

                        resolve({
                            path,
                            index: 1,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'distribuition')) {
            //DISTRIBUITION
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getDistribuitionReportPath(dataDistribuition, startDate, endDate);

                    if (path) {

                        resolve({
                            path,
                            index: 3,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'articles')) {
            //ARTICLES
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getArticlesReportPath(startDate, endDate);

                    if (path) {

                        resolve({
                            path,
                            index: 4,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'targets')) {
            //TARGETS
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getTargetsReportPath(startDate, endDate, periodReport);

                    if (path) {

                        resolve({
                            path,
                            index: 5,
                        });

                    } else {
                        resolve(false);
                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'myFunds')) {
            //MYFUNDS
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getMyFundsReportPath(startDate, endDate, periodReport);

                    if (path) {
                        resolve({
                            path,
                            index: 6,
                        });
                    } else {
                        resolve(false);
                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'myTitles')) {
            //MYFUNDS
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getMyTitlesReportPath(startDate, endDate);

                    if (path == NO_DATA) {
                        resolve(NO_DATA);
                    }

                    if (path) {
                        resolve({
                            path,
                            index: 7,
                        });
                    } else {
                        resolve(false);
                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'assetsAndAccounts', true)) {
            //PORTFOLIO
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getPortfolioReportPath(null, startDate, endDate, periodReport, {
                        isAssetAndAccountsReport: true
                    });

                    if (path) {

                        resolve({
                            path,
                            index: 8,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }

        if (verifyIncludeReportType(reportTypeList, 'transactionsInPeriod', true)) {
            //PORTFOLIO
            promises.push(
                new Promise(async (resolve, reject) => {
                    let path = await getPortfolioReportPath(null, startDate, endDate, periodReport, {
                        isAccumulatedTrasactionsReport: true
                    });

                    if (path) {

                        resolve({
                            path,
                            index: 9,
                        });

                    } else {

                        resolve(false);

                    }
                })
            )
        }

        let responseAll = await Promise.all(promises);
        let pathsToSend = [];

        console.log("RESPONSE ALL: ", responseAll);

        if ((responseAll.filter(el => !el)).length == 0) {

            for (let i = 0; i < responseAll.length; i++) {

                if (responseAll[i] != NO_DATA) {//removendo os que não possuem dados, como no caso em que não há títulos

                    pathsToSend.push(responseAll[i].path);

                }

            }

            let responseFullReport = await getFullReport(clientOn.client, pathsToSend, isCustomReport);

            if (responseFullReport.success) {
                return responseFullReport.body.data.pathToReport
            } else {
                return false;
            }
        } else {
            console.error("EXISTE PELO MENOS UM PATH INVALIDO: ", responseAll);
            dispatch(allActions.reportLoadActions.setReportLoad(null))
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao gerar relatório",
                type: 'error',
            }))
        }
    }

    useEffect(() => {

        if (reportLoad) {
            //Em caso de apeans atualização de mensagem não se dispara o generate
            if (!reportLoad.updateMessage) {
                console.log('reportLoad: ', reportLoad);

                //testReport();
                prepareChartsAndGenerate(reportLoad);
            }
        }

    }, [reportLoad]);

    return (
        <Dialog
            open={reportLoad && reportLoad.show}
            fullWidth={true}
            maxWidth={modalWidth == 'big' ? false : 'sm'}>
            <DialogContent className={classes.root} style={
                modalWidth == 'big' ? {
                    width: '1180px',
                    textAlign: 'center',
                    margin: '0 auto',
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    pointerEvents: 'none',
                } : {}}>

                {
                    dataToDistribuitionChart ?
                        <div className={classes.displayChart}>

                            <Pie data={dataToDistribuitionChart}
                                legend={legendPieChartOpts}
                                options={{
                                    tooltips: {
                                        enabled: false
                                    },
                                    animation: {
                                        duration: 500
                                    },
                                    responsive: true,
                                    //maintainAspectRatio: false,
                                }}
                                plugins={chartPlugins}
                                ref={chartDistribuitionRef} />

                        </div > : null
                }

                {
                    dataToEvolutionPlChart ?
                        <div className={classes.displayChart}>

                            <Bar data={dataToEvolutionPlChart}
                                options={optionsEvolutionChart}
                                plugins={chartPlugins}
                                ref={chartEvolutionPlRef} />

                        </div> : null
                }

                {
                    dataToCompareChart ?
                        <div className={classes.displayChart}>

                            <Bar data={dataToCompareChart}
                                options={optionsCompareChart}
                                plugins={chartPlugins}
                                ref={chartCompareRef}
                            />

                        </div> : null
                }

                {
                    dataToRentsMonthCompareFundsChart ?
                        <div className={classes.displayChartCompareFunds}>

                            <Bar data={dataToRentsMonthCompareFundsChart}
                                options={optionsChartRentsMonthCompareFunds}
                                plugins={chartPlugins}
                                ref={chartRentsMonthCompareFundsRef}
                            />

                        </div> : null
                }

                {
                    dataToPlEvolutionCompareFundsChart ?
                        <div className={classes.displayChartCompareFunds}>

                            <Bar data={dataToPlEvolutionCompareFundsChart}
                                options={optionsChartPlEvolutionCompareFunds}
                                plugins={chartPlugins}
                                ref={chartPlEvolutionCompareFundsRef}
                            />

                        </div> : null
                }

                <div className={classes.progressAndMessage}>
                    <CircularProgress disableShrink />
                    <Typography variant={"h6"} align={"center"}>
                        {innerReportLoadMessage ? innerReportLoadMessage : (reportLoad && reportLoad.message)}
                    </Typography>
                </div>


            </DialogContent >
        </Dialog >
    );
}