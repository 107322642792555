import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
import MyTextField from '../../utils/MyTextField';

//UnoButton
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { unoTheme } from "../../../assets/styles/unoTheme";
import UnoButton from '../../utils/UnoButton';

//RadioGroup

//BoostrapInput

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Grid
import { Grid } from "@material-ui/core";

//API
import { createAccount, deleteAccount, updateAccount } from '../../../API/account';

import CircularProgress from '@material-ui/core/CircularProgress';

import ConfirmActionRightContent from './ConfirmActionRightContent';

import moment from 'moment';
import { POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS } from '../../../controllers/AccountController';
import { isValidCnpj } from '../../utils/utils';
import { DistributorsAPI } from '../../../API/distributors';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    bottom: {

        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

}));

export default function NewDistributor(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    //form            
    const [submitted, setSubmitted] = useState('');

    const [inputCnpj, setInputCnpj] = useState('');
    const [inputName, setInputName] = useState('');


    function loadInfos(data) {

        const { cnpj, name } = data;
        setInputCnpj(cnpj);
        setInputName(name);

    }

    async function doSave() {

        setValidationMessage('')
        setSubmitted(true);
        setOpenBackdrop(true)

        const { update: isUpdate } = content;


        if (isValidCnpj(inputCnpj)
            && inputCnpj != ""
            && inputName != "") {

            const distributor = {
                name: inputName,
                cnpj: inputCnpj,
            }

            const response = await DistributorsAPI.save({
                ...distributor,
                id: isUpdate ? content.data.id : null
            });

            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: `Distribuidor ${isUpdate ? "atualizado" : "criado"} com sucesso!`
                }))

                dispatch(allActions.rightContentActions.update())

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: `Erro ao ${isUpdate ? "atualizar" : "criar"} a distribuidor: ` + response.body.message
                }))


            }



        }

        setOpenBackdrop(false)




    }

    async function doDeleteDistributor() {

        setValidationMessage('')
        setOpenBackdrop(true)

        //content contem data que contem a conta que foi aberta no componente lateral
        const response = await DistributorsAPI.delete(content.data.id);

        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: response.body.message
            }))

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao exluir a distribuidor: " + response.body.message
            }))
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update());

    }


    //deleção da conta
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteDistributor();
    }

    useEffect(() => {

        console.log("DATA NEW DISTRIBUTOR: ", content)
        if (clientOn && clientOn.client) {

            if (content.data)
                loadInfos(content.data);

        }
    }, []);


    const rolesDisabled = useMemo(() => currentUser?.user?.isViewer, [currentUser]);

    return (
        <div className={classes.root}>

            {
                !confirmingAction ?
                    <>
                        <div className={classes.header}>
                            <IconButton
                                className={classes.hiddenIcon}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>

                                    {content.update ? "Editar distribuidor" : "Cadastrar distribuidor"}

                                </Typography>

                            </div>


                            <IconButton
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>
                            <Grid container spacing={2}>

                                <Grid item lg={12} md={12} sm={12} xs={12} >

                                    <MyTextField
                                        id="distributor-cnpj"
                                        outerlabel={'CNPJ'}
                                        value={inputCnpj}
                                        onChange={(value) => setInputCnpj(value)}
                                        placeholder={'Insira o CNPJ'}
                                        validator={!isValidCnpj(inputCnpj) && submitted}
                                        helperText="Informe um CNPJ válido"
                                        mask={["cnpj"]} />

                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <MyTextField
                                        id="distributor-name"
                                        outerlabel={'Nome do distribuidor'}
                                        value={inputName}
                                        onChange={(value) => setInputName(value)}
                                        validator={!inputName && submitted}
                                        helperText="Informe um Nome"
                                        placeholder={'Insira o Nome'} />

                                </Grid>

                            </Grid>

                            <br />
                            <div className={classes.bottom}>

                                {
                                    validationMessage != '' ?
                                        <Typography className={classes.validationMessage}>
                                            {validationMessage}
                                        </Typography>
                                        : null

                                }


                                <div className={'bottomButtons'}>

                                    <UnoButton
                                        className={
                                            !content.update ? classes.hiddenIcon : null}
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir"}
                                    </UnoButton>

                                    <UnoButton
                                        onClick={() => doSave()}
                                        type={content.update ? 'success' : 'primary'}>
                                        {content.update ? "Atualizar" : "Criar"}
                                    </UnoButton>


                                </div>



                            </div>

                        </div>
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                        este distribuidor?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }


        </div>
    );
}



