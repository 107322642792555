import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../utils/utils'
import { makeStyles } from '@material-ui/core/styles';
import { LegendOptions } from "../utils/Charts";
import { Paper } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

//contents
import DetailBalanceValidation from './rightContent/DetailBalanceValidation'
import DetailBalanceTitleValidation from './rightContent/DetailBalanceTitleValidation'
import DetailBalanceCollection from './rightContent/DetailBalanceCollection'
import DetailTitleBalanceCollection from './rightContent/DetailTitleBalanceCollection'
import DetailAdminLegislationArticle from './rightContent/DetailAdminLegislationArticle'
import DetailAdminLegislationSegment from './rightContent/DetailAdminLegislationSegment'
import NewTransaction from "./rightContent/NewTransaction";
import NewAsset from "./rightContent/NewAsset";
import NewAssetTitle from "./rightContent/NewAssetTitle";
import NewApr from "./rightContent/NewApr";
import NewAccount from "./rightContent/NewAccount";
import NewLocalBenchmark from "./rightContent/NewLocalBenchmark";
import NewInflationRate from "./rightContent/NewInflationRate";
import NewClientInflationRate from "./rightContent/NewClientInflationRate";
import NewClientManager from "./rightContent/NewClientManager";
import NewClientSubManager from "./rightContent/NewClientSubManager";
import NewFund from "./rightContent/NewFund";
import NewTitle from "./rightContent/NewTitle";
import InvalidQuotas from "./rightContent/InvalidQuotas";
import ClientsFromFund from "./rightContent/ClientsFromFund";
import ClientsFromTable from "./rightContent/ClientsFromTable";
import AssetsFromFormat from "./rightContent/AssetsFromFormat";


//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions'
import SelectPeriodReport from "./rightContent/SelectPeriodReport";
import FundsFromArticle from "./rightContent/FundsFromArticle";
import ClientViewer, { clientViewerPath } from "./rightContent/ClientViewer";
import DetailAdminArticleRule from "./rightContent/DetailAdminArticleRule";
import ClientUpdates from "./rightContent/ClientUpdates";
import FundLocalQuotas from "./rightContent/FundLocalQuotas";
import NewDistributor from "./rightContent/NewDistributor";
import IPC14 from "./rightContent/IPC14";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    paper: {
        padding: '24px'
    }
}));

export const RightDrawerActionEnum = {
    Cadastrar: 0,
    Editar: 1,
    AtualizarDados: 'update'
};

export const rightDrawerActionTitles = Object.keys(RightDrawerActionEnum);

export default function RightContent(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { content } = props

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            //Do whatever when esc is pressed
            //console.log("ESC DOWN");
            dispatch(allActions.rightContentActions.setRightContent(null))
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [])



    // função que abre o menu lateral que se sobrepõe a tela, saindo do lado direito
    // a função recebe um path a partir de um dispatch no rightContentActions
    // Ex: 
    // dispatch(allActions.rightContentActions.setRightContent({
    //     path: 'detailBalanceValidation',
    //     data: infos
    // }))}
    // o dispatch altera o componente chamado na UnoDashboard que chama a função RightContent 
    // De acordo com o path o componente correto é exibido
    // para o componente é passado o 'content'
    return (
        <div className={classes.root}>
            {
                content && content.path == 'detailBalanceValidation' ?
                    <DetailBalanceValidation content={content} />
                    : null
            }
            {
                content && content.path == 'detailBalanceTitleValidation' ?
                    <DetailBalanceTitleValidation content={content} />
                    : null
            }
            {
                content && content.path == 'detailBalanceCollection' ?
                    <DetailBalanceCollection content={content} />
                    : null
            }
            {
                content && content.path == 'detailTitleBalanceCollection' ?
                    <DetailTitleBalanceCollection content={content} />
                    : null
            }
            {
                content && content.path == 'newTransaction' ?
                    <NewTransaction content={content} />
                    : null
            }
            {
                content && content.path == 'newAsset' ?
                    <NewAsset content={content} />
                    : null
            }
            {
                content && content.path == 'newAssetTitle' ?
                    <NewAssetTitle content={content} />
                    : null
            }
            {
                content && content.path == 'newApr' ?
                    <NewApr content={content} />
                    : null
            }
            {
                content && content.path == 'newAccount' ?
                    <NewAccount content={content} />
                    : null
            }
            {
                content && content.path == 'newLocalBenchmark' ?
                    <NewLocalBenchmark content={content} />
                    : null
            }
            {
                content && content.path == 'detailAdminLegislationSegment' ?
                    <DetailAdminLegislationSegment content={content} />
                    : null
            }
            {
                content && content.path == 'detailAdminArticleRule' ?
                    <DetailAdminArticleRule content={content} />
                    : null
            }
            {
                content && content.path == 'detailAdminLegislationArticle' ?
                    <DetailAdminLegislationArticle content={content} />
                    : null
            }
            {
                content && content.path == 'newInflationRate' ?
                    <NewInflationRate content={content} />
                    : null
            }
            {
                content && content.path == 'newClientInflationRate' ?
                    <NewClientInflationRate content={content} />
                    : null
            }
            {
                content && content.path == 'newClientManager' ?
                    <NewClientManager content={content} />
                    : null
            }
            {
                content && content.path == 'newClientSubManager' ?
                    <NewClientSubManager content={content} />
                    : null
            }
            {
                content && content.path === clientViewerPath ?
                    <ClientViewer content={content} />
                    : null
            }
            {
                content && content.path == 'newFund' ?
                    <NewFund content={content} />
                    : null
            }
            {
                content && content.path == 'newTitle' ?
                    <NewTitle content={content} />
                    : null
            }
            {
                content && content.path == 'clientsFromFund' ?
                    <ClientsFromFund content={content} />
                    : null
            }
            {
                content && content.path == 'clientsFromTable' ?
                    <ClientsFromTable content={content} />
                    : null
            }
            {
                content && content.path == 'assetsFromFormat' ?
                    <AssetsFromFormat content={content} />
                    : null
            }
            {
                content && content.path == 'fundsFromArticle' ?
                    <FundsFromArticle content={content} />
                    : null
            }
            {
                content && content.path == 'invalidQuotas' ?
                    <InvalidQuotas content={content} />
                    : null
            }
            {
                content && content.path == 'configReport' ?
                    <SelectPeriodReport content={content} />
                    : null
            }
            {
                content && content.path == 'clientUpdates' ?
                    <ClientUpdates content={content} />
                    : null
            }
            {
                content && content.path == 'fundLocalQuotas' ?
                    <FundLocalQuotas content={content} />
                    : null
            }
            {
                content && content.path == 'newDistributor' ?
                    <NewDistributor content={content} />
                    : null
            }
            {
                content && content.path == 'returnsFundsIpc14' ?
                    <IPC14 content={content} />
                    : null
            }
        </div>
    );
}



