import Account from "../models/Account";
import moment from 'moment';
import {
    getAccountIdByNumberAccount, listClientAccounts,
    getAccountIdByNumberAccountAndInstituitionCod,
    getAccountIdByNumberAccountAndInstituitionCodAndCnpj,
    getAccountIdByNumberAccountAndCnpjAsset,
    listClientAccountsBalancesByPeriodAndRegime,
    getDisponilityByMonthAndYear,
    getAccountIdByNumberAccountAndAgencyAndInstituitionCod,
    getAccountIdByNumberAccountAndAgency
} from "../API/account"
import { getInstituitionIdByInstituitionName, getInstituitionIdByInstituitionCod } from "../API/instituition"
import { getClientAccountsBalancesValidationsByYear } from '../API/client'

export const COD_NO_VINC_ACCOUNTS = '000';
export const POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS = ['11111', '22222', '33333'];
export const NO_MORE_NO_VINC_ACCOUNTS = 'NO_MORE_NO_VINC_ACCOUNTS';

export function getNextNoVincAccountNumber(clientAccounts) {

    console.log("clientAccounts: ", clientAccounts);
    let nextAccountNoVinc = null;
    POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS.forEach(numberAccountNoVinc => {
        if (clientAccounts.some(el => el.number_account == numberAccountNoVinc)) {
            console.log("JA POSSUI CONTA: ", numberAccountNoVinc);
        } else if (!nextAccountNoVinc) {
            nextAccountNoVinc = numberAccountNoVinc;
        }
    });

    if (nextAccountNoVinc) {
        return nextAccountNoVinc
    } else {
        return NO_MORE_NO_VINC_ACCOUNTS;
    }

}

export function normalizeAccounts(data) {

    console.log("DATA ACCOUNTS: ", data);

    let accs = [];
    data.forEach(element => {

        accs.push(new Account(element))

    });

    return accs;
};

/**
 * Retorna o id da conta (id o banco) a partir do número da conta
 * @param {int} clientId
 * @param {String} accountNumber
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccount(clientId, numberAccount) {

    let response = await getAccountIdByNumberAccount(clientId, numberAccount);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        if (response.body.message == "CONTAS_DUPLICADAS") {

            return "CONTAS_DUPLICADAS";

        } else {

            return "NOT_FOUND"

        }
    }

}

/**
 * Retorna o id da conta (id o banco) a partir do número da conta e da agência
 * @param {int} clientId
 * @param {String} accountNumber
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccountAndAgency(clientId, numberAccount, agency) {

    let response = await getAccountIdByNumberAccountAndAgency(clientId, numberAccount, agency);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        if (response.body.message == "CONTAS_DUPLICADAS") {

            return "CONTAS_DUPLICADAS";

        } else {

            return "NOT_FOUND"

        }
    }

}

/**
 * Retorna o id da conta (id o banco) a partir do número da conta e do código da instituição
 * @param {int} clientId
 * @param {String} accountNumber
 * @param {String} instituitionCod
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccountAndInstituitionCod(clientId, numberAccount, instituitionCod) {

    let response = await getAccountIdByNumberAccountAndInstituitionCod(clientId, numberAccount, instituitionCod);

    if (response.success
        && response.body
        && response.body.id) {

        console.log("RESPONSE BODY: ", response.body);
        console.log("RESPONSE BODY ID: ", response.body.id);

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"
    }

}

/**
 * Retorna o id da conta (id do banco) a partir do número da conta, agência e do código da instituição
 * @param {int} clientId
 * @param {String} accountNumber
 * @param {String} instituitionCod
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccountAndAgencyAndInstituitionCod(clientId, numberAccount, agency, instituitionCod) {

    let response = await getAccountIdByNumberAccountAndAgencyAndInstituitionCod(clientId, numberAccount, agency, instituitionCod);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"
    }

}

/**
 * Retorna o id da conta (id o banco) a partir do número da conta, do código da instituição e o cnpj do fundo
 * @param {int} clientId
 * @param {String} accountNumber
 * @param {String} instituitionCod
 * @param {String} cnpjFund
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccountAndInstituitionCodAndCnpj(clientId, numberAccount, instituitionCod, cnpjFund) {

    let response = await getAccountIdByNumberAccountAndInstituitionCodAndCnpj(clientId, numberAccount, instituitionCod, cnpjFund);

    if (response.success
        && response.body
        && response.body.id) {

        console.log("RESPONSE BODY: ", response.body);
        console.log("RESPONSE BODY ID: ", response.body.id);

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"
    }

}

/**
 * Retorna o id da conta (id o banco) a partir do número da conta e do cnpj do ativo
 * @param {String} accountNumber
 * @param {String} cnpjAsset
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetAccountIdByNumberAccountAndCnpjAsset(numberAccount, cnpjAsset) {

    let response = await getAccountIdByNumberAccountAndCnpjAsset(numberAccount, cnpjAsset);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"
    }

}

/**
 * Retorna o id da instituição (id o banco) a partir do nome da instituição 
 * @param {String} instituitionName
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetInstituitionIdByInstituitionName(instituitionName) {

    let response = await getInstituitionIdByInstituitionName(instituitionName);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body;

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"

    }

}

/**
 * Retorna o id da instituição (id o banco) a partir do cod da instituição 
 * @param {String} instituitionName
 * @returns {int} retorna o id ou "NOT_FOUND"
 */
export async function doGetInstituitionIdByInstituitionCod(instituitionCod) {

    let response = await getInstituitionIdByInstituitionCod(instituitionCod);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body;

    } else {

        console.log("ERROR: ", response.body)
        return "NOT_FOUND"

    }

}

export function getFormattedAgencyAccountToValidations(ag) {

    if (ag) {
        return "=ag=" + ag;
    }

    return "";
}

/**
 * Formatando estruura para exibição da tabela de saldo de contas
 * @param {array} yearSelected
 * @param {array} accountsClient
 * @param {array} balancesAccountValidations
 * @returns {array} objetos formatados prontos para serem salvos no banco
 */
export function formatBalancesAccountsValidationToShow(yearSelected, accounts, balancesValidations) {

    console.log("ENTRANDO NO FORMAT: ", yearSelected)

    let formatted = {}

    formatted['accounts'] = {}

    accounts.forEach(element => {

        let initAccount = 1;
        let y = parseInt(moment.utc(element.account_init).format('YYYY'))

        if (y >= yearSelected) { //Se o ano de inicio da conta for maior ou igual ao ano selecionado

            if (y == yearSelected) {
                //Se o ano for igual inicia-se a partir do mês do inicio da conta
                initAccount = parseInt(moment.utc(element.account_init).format('MM'));
            } else {
                //Se o ano for maior a conta não é exibida
                initAccount = 0
            }

        }

        const mainCod = element.description + "=" + element.number_account + getFormattedAgencyAccountToValidations(element.agency);
        formatted['accounts']
        [mainCod] = {
            init: initAccount,
            account_regime_id: element.account_regime_id,
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {},
            7: {},
            8: {},
            9: {},
            10: {},
            11: {},
            12: {},
            infosAccounts: {
                instituition: element.description,
                number_account: element.number_account
            }
        }

    });

    balancesValidations.forEach(element => {

        const mainCod = element.instituition_account + "=" + element.number_account + getFormattedAgencyAccountToValidations(element.agency);

        if (!formatted
            .accounts[mainCod]) {
            formatted
                .accounts[mainCod] = {}
            formatted
                .accounts[mainCod][element.month_validation] = {}
        }

        formatted
            .accounts[mainCod][element.month_validation] = element

        if (!formatted
            .accounts[mainCod]['infosAccounts']) {
            formatted
                .accounts[mainCod]
            ['infosAccounts'] = {
                instituition: element.instituition_account,
                number_account: element.number_account
            }

        }

        let cod = element.year_validation
            + '-accounts-'
            + element.number_account
            + "-"
            + element.agency
            + "-"
            + element.month_validation;

        element.codInput = cod;

    });

    return formatted;
}


export async function doGetClientBalancesValidations(selectedYear, clientId) {


    const responseAccs = await listClientAccounts(clientId);
    if (responseAccs.success) {

        console.log('ACCOUNTS CLIENT: ', responseAccs.body.rows)
        const responseBv = await getClientAccountsBalancesValidationsByYear(selectedYear, clientId);

        if (responseBv.success) {

            console.log("RESPONSE: ", responseBv)
            return formatBalancesAccountsValidationToShow(selectedYear,
                responseAccs.body.rows,
                responseBv.body.rows);

        } else {
            console.log("error: ", responseBv.error);
            return {
                accounts: {},
            }
        }

    } else {
        console.log("error: ", responseAccs.error)
        return {
            accounts: {},
        }
    }



}

export async function doGetDisponibilityAccountsByPeriod(clientId, regimeId, startDate, endDate) {


    const responseBalancesAccs = await listClientAccountsBalancesByPeriodAndRegime(clientId,
        regimeId,
        startDate, endDate);

    if (responseBalancesAccs.success) {

        console.log('responseBalancesAccs: ', responseBalancesAccs);

        //Organizando por mês/ano
        let mapBalancesAccounts = {};
        if (responseBalancesAccs.body && responseBalancesAccs.body.rows) {

            responseBalancesAccs.body.rows.forEach(accBalance => {

                if (!mapBalancesAccounts[accBalance.month + '/' + accBalance.year]) {
                    mapBalancesAccounts[accBalance.month + '/' + accBalance.year] = 0;
                }

                mapBalancesAccounts[accBalance.month + '/' + accBalance.year] += parseFloat(accBalance.balance_now);

            });
        }

        console.log('mapBalancesAccounts: ', mapBalancesAccounts);
        return {
            success: true,
            body: mapBalancesAccounts,
        }


    } else {
        return {
            success: false,
        }
    }


}

export async function doGetDisponilityByMonthAndYear(clientId, month, year) {

    const responseDisponility = await getDisponilityByMonthAndYear(clientId, month, year);

    console.log('responseDisponility: ', responseDisponility);
    if (responseDisponility.success
        && responseDisponility.body.length === 1) {
        return parseFloat(responseDisponility.body[0].balance);
    }

    return null;

}
