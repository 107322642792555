import API from './config';

export async function createFund(fund) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/create', fund, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateFund(fund) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/funds/update', fund, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteFund(fundId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/funds/delete/' + fundId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllFunds() {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    };

    try {

        const response = await API.get('/funds/getAllFunds', config);

        return {
            success: true,
            body: response.data
        };
    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsBySearch(search) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {
        const response = await API.get('/funds/getFundsBySearch?search=' + search, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

};

export async function getClientFundsBySearch(search, clientId) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {
        const response = await API.get(`/funds/getClientFundsBySearch?search=${search}&clientId=${clientId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

};

export async function getFundsBySegmentId(segmentId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/getFundsBySegmentId/' + segmentId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsBySearchAndSegmentId(search, segmentId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/funds/getFundsBySearchAndSegmentId?segmentId=${segmentId}&search=${search}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}


export async function getFundByCnpj(cnpj) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {
        // ? Não deveria ser utilizado o método getFundBySearch?
        // TODO [REFACTOR]: Verificar as possibilidades de refatoração
        const response = await API.post('/funds/getFundByCnpj/', { cnpj: cnpj }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsByCnpjs(cnpjs) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {
        // ? Não deveria ser utilizado o método getFundBySearch?
        // TODO [REFACTOR]: Verificar as possibilidades de refatoração
        const response = await API.post('/funds/getFundsByCnpjs/', { cnpjs: cnpjs }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createAsset(asset) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/createAsset', asset, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            error: error.response.data
        };
    }

}

export async function updateAsset(asset) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/funds/updateAsset', asset, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            error: error.response.data
        };
    }

}

export async function deleteAsset(assetId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/funds/deleteAsset/' + assetId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            error: error.response.data
        };
    }

}

export async function getAllClientFunds(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/listAllClientFunds/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsClientByPeriodRent(clientId, startDate, endDate, search) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getFundsClientByPeriodRent', {
            client_id: clientId, start_date: startDate, end_date: endDate, search: search
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFullFundsClientByPeriodRent(clientId, month, year, segmentId, search) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getFullFundsClientByPeriodRent', {
            client_id: clientId, month: month, year: year, segment_id: segmentId, search: search
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientsFromFund(fundId, consultingId) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/getAllClientsFromFund?fund_id=' + fundId + '&consulting_id=' + consultingId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }
}

export async function getRankingFundsPerClient(consultingId) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/rankingFundsPerClient/' + consultingId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }
}

export async function getAllClientAssets(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/listAllClientAssets/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientAssetsByRegime(clientId, accountRegimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("ACCOUNT REGIME ID: ", accountRegimeId);

    try {

        const response = await API.post('/funds/listAllClientAssetsByRegime', {
            client_id: clientId,
            account_regime_id: accountRegimeId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundClientAccountList(fundId, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getFundClientAccountList', {
            fund_id: fundId,
            client_id: clientId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsByAccountId(accountId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/funds/getFundsByAccountId/' + accountId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAllAssetsByDate(clientId, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientAssetListByDate', {
            client_id: clientId,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAllAssetsByDateAndRegime(clientId, startDate, endDate, accountRegimeId, startDatePeriod) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientAssetListByDateAndRegime', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            account_regime_id: accountRegimeId,
            start_date_period: startDatePeriod,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAssetsInitPortfolio(clientId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientAssetsInitPortfolio', {
            client_id: clientId,
            month: month,
            year: year,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Não é necessário o id do cliente pois a conta já é do cliente
//É necessário o número da conta pois o accountId pode ser NOT_FOUND
export async function getClientAssetByCnpjAndAccountId(cnpj, accountId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientAssetByCnpjAndAccountId', {
            cnpj: cnpj,
            account_id: accountId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAssetByCnpjAndNumberAccount(cnpj, numberAccount) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientAssetByCnpjAndNumberAccount', {
            cnpj: cnpj,
            number_account: numberAccount,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientBalancesRentByPeriod(clientId, initDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientBalancesRentByPeriod', {
            client_id: clientId,
            init_date: initDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientFundsRentsByPeriod(clientId, startDate, endDate, search, segmentId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getClientFundsRentsByPeriod', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            search: search ? search : '',
            segment_id: segmentId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundsInfosByFundIds(arrayFundsIds) {

    console.log('arraFundsIds: ', arrayFundsIds);

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getFundsInfosByFundIds', { arrayFundsIds: arrayFundsIds }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getSettledFundsIdsByFundsIdsAndReferenceDate(arrayFundsIds, referenceDate) {

    console.log('arraFundsIds: ', arrayFundsIds);

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/getSettledFundsIdsByFundsIdsAndReferenceDate', { arrayFundsIds: arrayFundsIds, referenceDate: referenceDate }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateAllFundInfosComdinheiro(month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/updateAllFundInfosComdinheiro', {

            month: month,
            year: year,

        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateInfosCarteiraComdinheiroByCNPJ(cnpj) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/funds/updateInfosCarteiraComdinheiroByCNPJ', {
            cnpj: cnpj
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateFundArticles(fundId, fundArticles) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post(`/funds/updateFundArticles`,
            { fund_id: fundId, fund_articles: fundArticles, },
            config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getFundArticles(fundId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/funds/getArticles?fund_id=${fundId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

