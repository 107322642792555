import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getActionsHistoryLogs, getUserActionsHistoryLogs } from '../../../../API/log_action';

import { unoTheme, headerSearch } from '../../../../assets/styles/unoTheme'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import allActions from '../../../../actions';
import { isEmpty } from 'lodash';
import { formatStringToDisplay } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red',
  },
  headerSearch: headerSearch,
  inputSearch: {
    width: "300px",
    background: "white"
  },
  toStickyHeader: {
    display: 'flex',
    height: '100%',
  },
}));

export default function ActionsHistory({ selectedClient }) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [logActions, setLogActions] = useState([]);

  //PAGINATION
  const LIMIT = 30;
  const handleChange = (value) => {
    const newPage = page + value;
    setPage(page + value);
    doGetLogActions(newPage);
  };
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(logActions.length);


  async function doGetLogActions(page, selectedClient) {
    dispatch(allActions.innerLoadActions.setInnerLoad({
      show: true,
      loadMessage: 'Carregando acessos...'
    }));

    const offset = (page - 1) * LIMIT;

    const response = await getActionsHistoryLogs(offset, LIMIT, selectedClient ? selectedClient.id : null);

    console.log('getActionsHistoryLogs: ', response);

    if (response.success && response.body) {

      const logActionsData = response.body.rows;

      const hasLogActionsData = logActionsData.length > 0 ? true : false;
      if (hasLogActionsData) {
        setLogActions(logActionsData);
        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
      } else {
        setLogActions([]);
        dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem acessos' }));
      }

      setCount(response.body.totalRows);
    } else {
      console.error("RESPONSE ERROR: ", response.error);
      dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
    }
  }

  function getActionLabel(actionCod) {

    const isPortfolioReset = actionCod === 1;
    const isResetAssetsAndAccounts = actionCod === 2;
    const isResetTransactions = actionCod === 3;
    const isPortfolioLaunch = actionCod === 4;

    let action = "";
    if (isPortfolioReset) {

      action = "RESET DE CARTEIRA";

    } else if (isResetAssetsAndAccounts) {

      action = "RESET DE ATIVOS/CONTAS";

    } else if (isResetTransactions) {

      action = "RESET DE TRANSAÇÕES";

    } else if (isPortfolioLaunch) {

      action = "LANÇAMENTO";

    }

    return action;
  }

  function renderDateCell(row) {

    const typeActionId = row.type_action_id;
    if (typeActionId === 1) {
      return (
        <TableCell align="left">{
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: unoTheme.redMoney }}>{moment.utc(row.from_date_reset).format("MM/YYYY")}</span> <ArrowRightAltIcon /> <span style={{ color: unoTheme.mainColor }}>{
              row.to_date_reset == null ? "Início da carteira" : moment.utc(row.to_date_reset).format("MM/YYYY")
            }</span></span>}
        </TableCell>
      )
    } else if (typeActionId === 4) {
      return (
        <TableCell align="left">{'Carteira: ' + moment.utc(row.portfolio_date_launch).format("MM/YYYY")}
        </TableCell>
      )
    }
    else {
      return (
        <TableCell align="left">{
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowRightAltIcon /> <span style={{ color: unoTheme.mainColor }}>{
              row.to_date_reset == null ? "Início da carteira" : moment.utc(row.to_date_reset).format("MM/YYYY")
            }</span></span>}
        </TableCell>
      )
    }
  }

  useEffect(() => {
    doGetLogActions(1, selectedClient);
  }, [selectedClient]);


  return (
    <div className={classes.root}>
      <div class={'defaultScrollWithHeaderContent'}>
        <MyTable
          pagination={logActions && logActions.length > 0}
          itemspage={LIMIT}
          totalitems={count}
          handlePage={handleChange}
          stickyHeader
          nofixed={true}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Ação</TableCell>
              <TableCell align="left">Consultor</TableCell>
              <TableCell align="left">Cliente</TableCell>
              <TableCell align="left">De / Para</TableCell>
            </TableRow>
          </TableHead>
          {logActions.length > 0 ?
            <TableBody>
              {logActions.map((row, index) => (
                <TableRow key={'log-actions-' + index}>
                  <TableCell align="left" style={{ color: unoTheme.mainColor }}>{moment(row.created_date).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
                  <TableCell align="left">{getActionLabel(row.type_action_id)}</TableCell>
                  <TableCell align="left">{formatStringToDisplay(row.username.toUpperCase(), 2)}</TableCell>
                  <TableCell align="left">{row.client_label.toUpperCase()}</TableCell>
                  {renderDateCell(row)}
                </TableRow>
              ))}
            </TableBody>
            : <TableBody></TableBody>}
        </MyTable>
      </div>
    </div>
  );
}