import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import allActions from '../../actions'
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { unoTheme } from '../../assets/styles/unoTheme';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 999,
        color: unoTheme.mainColor,

        //width: '100%',
        //height: '100%',
        position: 'relative',
        //width: 'calc(100% - 144px)',

        marginTop: 'calc(100vh / 2)',
        borderRadius: '10px 10px 0px 0px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .small': {
            zIndex: 2,
            '& .MuiCircularProgress-root': {
                width: '25px !important',
                height: '25px !important',
            },

            '& h6': {
                fontSize: '14px !important',
            }
        },

        '& .background': {

            //height: 'calc(100% - 138px)',
            width: 'calc(100% - 144px)',
            height: 'calc(100vh - 91px)',
            position: 'absolute',
            background: 'white',
            opacity: 0.7,
            zIndex: 1,
            width: '100%',
            borderRadius: '10px 10px 0px 0px',


        },

        '& .background.extraMargin': {
            marginTop: "-46px",
        }

    },




}));

export const codsInnerLoad = {
    unoDashboard: 'unoDasboard',
    searchAccounts: 'searchAccounts',
    fundsScreen: 'fundsScreen',
    titlesScreen: 'titlesScreen',
    accessHistory: 'accessHistory',
    actionsHistory: 'actionsHistory',
    conciliationScreen: 'conciliationScreen',
    articleTableScreen: 'articleTableScreen',
    balancesCollectionScreen: 'balancesCollectionScreen',
    assetsScreen: 'assetsScreen',
    demonstrativeScreen: 'demonstrativeScreen',
    toolsScreen: 'toolsScreen',
}

/**
* Exibir inner load
* @param {String} show 
* @param {String} message 
* @returns {Object} inner load
*/
export default function MyInnerLoad(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [codOnScreen, setCodOnScreen] = useState(null);
    const [show, setShow] = useState(false);

    const { innerLoad,
        size } = props

    useEffect(() => {

        console.log("INNER LOAD: ", innerLoad);
        if (innerLoad) {

            if (innerLoad.show) {
                setShow(true);
            }

            if (!innerLoad.show && innerLoad.cod == codOnScreen) {
                setShow(false);
            }

            setCodOnScreen(innerLoad.cod);
        }


    }, [innerLoad])

    return (

        <div className={classes.root}>
            {
                //innerLoad && innerLoad.show ?
                show ?
                    <>
                        {
                            !innerLoad.emptyMessage || innerLoad.emptyMessage == "" ?
                                <div className={`background ${innerLoad.extraMargin ? 'extraMargin' : ''}`}>
                                </div> : null
                        }

                        <div className={[size, classes.center].join(' ')}>

                            {
                                innerLoad.loadMessage && innerLoad.loadMessage != '' ?
                                    <>
                                        <CircularProgress
                                            disableShrink color="inherit" />
                                        <Typography variant={"h6"}>
                                            {innerLoad.loadMessage}
                                        </Typography>
                                    </> : null
                            }

                            {
                                innerLoad.emptyMessage && innerLoad.emptyMessage != '' ?
                                    <>
                                        <Typography variant={"h6"}>
                                            {innerLoad.emptyMessage}
                                        </Typography>
                                    </> : null
                            }

                        </div>
                    </>
                    : null
            }


        </div >

    );
}