import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { unoTheme } from '../../assets/styles/unoTheme';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({

    root: {
        position: 'relative',
    },

    load: {
        color: unoTheme.mainColor,
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        right: '0',
        bottom: '0',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiCircularProgress-root': {
            height: '20px !important',
            width: '20px !important',
        }
    },
}));


/**
 * Exibir full load
 * @param {String} show 
 * @param {String} message 
 * @returns {Object} FullLoad
 */
export default function MyChartWrapper(props) {

    const classes = useStyles();

    const { height, loading, loadMessage } = props;

    useEffect(() => {

    }, [])

    return (
        <div className={classes.root} style={{ height: height }}>

            {
                !loading ?
                    props.children : null
            }

            {
                loading ?
                    <div className={classes.load}>
                        <div>
                            <CircularProgress disableShrink color="inherit" />
                            {
                                <Typography variant={"h6"} style={{ fontSize: '12px' }}>
                                    {loadMessage ? loadMessage : 'Carregando dados...'}
                                </Typography>
                            }
                        </div>
                    </div>
                    : null
            }


        </div>
    );
}