import { formatTextForValidDisplay } from "../utils";

export const AdminsDictionary = {

    dictionary: {
        "BANCO BNP PARIBAS BRASIL S/A": "BANCO BNP PARIBAS",
        "BANCO BRADESCO S.A.": "BANCO BRADESCO",
        "BANCO COOPERATIVO SICREDI S.A.": "BANCO COOPERATIVO SICREDI",
        "BANCO DAYCOVAL S.A.": "BANCO DAYCOVAL",
        "BANCO GENIAL S.A.": "BANCO GENIAL",
        "BANCO SANTANDER (BRASIL) S.A.": "BANCO SANTANDER",
        "BANCOOB DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA": "BANCOOB DTVM",
        "BANESTES DTVM SA": "BANESTES DTVM",
        "BB GESTAO DE RECURSOS DTVM S.A": "BB ASSET",
        "BEM - DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.": "BEM DTVM (BRADESCO)",
        "BFL ADMINISTRADORA DE RECURSOS": "BFL DTVM",
        "BNY MELLON SERVICOS FINANCEIROS DTVM S.A.": "BNY MELLON",
        "BRL TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A.": "BRL TRUST DTVM",
        "BTG PACTUAL SERVIÇOS FINANCEIROS S/A DTVM": "BTG PACTUAL DTVM",
        "CAIXA ECONOMICA FEDERAL": "CAIXA DTVM",
        "CM CAPITAL MARKETS DTVM LTDA": "CM CAPITAL MARKETS",
        "FINAXIS CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.": "FINAXIS DTVM",
        "GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIÁRIOS S.A.": "GENIAL INVESTIMENTOS",
        "INTRA INVESTIMENTOS DTVM LTDA": "INTRA INVESTIMENTOS",
        "INTRAG DTVM LTDA.": "INTRAG DTVM",
        "ITAU UNIBANCO S.A.": "ITAU UNIBANCO",
        "MDL TRUST SERVIÇOS FIDUCIÁRIOS LTDA.": "MDL TRUST",
        "PLANNER CORRETORA DE VALORES SA": "PLANNER CORRETORA",
        "RJI CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA": "RJI CORRETORA",
        "S3 CACEIS BRASIL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A": "S3 CACEIS (SANTANDER)",
        "SAFRA SERVIÇOS DE ADMINISTRAÇÃO FIDUCIÁRIA LTDA.": "SAFRA ADMINISTRAÇÃO",
        "SANTANDER DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.": "SANTANDER DISTRIBUIDORA",
        "SINGULARE CORRETORA DE TITULOS E VALORES MOBILIARIOS S.A.": "SINGULARE CORRETORA",
        "TRUSTEE DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.": "TRUSTEE DTVM",
        "VOTORANTIM ASSET MANAGEMENT LTDA.": "VOTORANTIM ASSET",
    },

    getMyAdminAnbima(adminAnbima) {

        const correspondence = this.dictionary[adminAnbima];
        return correspondence ? correspondence : formatTextForValidDisplay(adminAnbima, 2);

    }
}
