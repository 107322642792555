import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

//Table
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'
import { applyMaskAccount, applyMaskAmount } from "../../utils/utils";

//API
import { listClientAccountsByRegime, getClientAccountsByRegimeAndDate } from '../../../API/account'

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'unset',
            color: 'white',
            background: unoTheme.mainColor,
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },
}));

export default function AccountsScreen(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)

    const [clientAccounts, setClientAccounts] = React.useState([]);

    const [appearFab, setAppearFab] = useState(true);

    const [resultAmount, setResultAmount] = useState(0);

    function copyToClipboard(e, text) {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        dispatch(allActions.mainAlertActions.setMainAlert({
            type: 'success',
            message: 'Saldo da conta copiado para a área de transferência!',
            show: true,
        }));
    };

    async function getClientAccounts(clientId, regimeId, month, year) {

        console.log("motth: ", month)
        console.log("year: ", year)
        //const response = await listClientAccounts(clientId, regimeId);
        //const response = await listClientAccountsByRegime(clientId, regimeId);
        const response = await getClientAccountsByRegimeAndDate(clientId, regimeId, month, year);

        console.log("RESPONSE: ", response)

        if (response.success) {

            setClientAccounts(response.body.rows)
            setResultAmount(sumResultAmount(response.body.rows));

        } else {
            console.log("error: ", response.error)
        }

    }

    function sumResultAmount(accounts) {

        let resultAmount = 0.0;
        accounts.forEach(element => {

            resultAmount += element.balance_validation ? parseFloat(element.balance_validation) : 0;

        });

        console.log("Result amount: ", resultAmount)

        return resultAmount;
    }

    function loadInfos(client, regimeId) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Consultando contas...',
        }));

        if (!client.portfolio_closed) {

            let splittedClosedMonth = moment.utc(client.portfolio_init).format('MM/YYYY').split('/');

            getClientAccounts(client.id, regimeId, parseInt(splittedClosedMonth[0]), parseInt(splittedClosedMonth[1]))

        } else {

            getClientAccounts(client.id, regimeId, client.selectedPortfolioMonth, client.selectedPortfolioYear)

        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    const { accountRegimeId, flagDoNewAccount, resetFlag } = props

    useEffect(() => {



        // console.log("ClienteAccounts: ", clientAccounts);
        if (clientOn && clientOn.client) {


            loadInfos(clientOn.client, accountRegimeId)

        }

        if ((rightContent && rightContent.action)) {

            loadInfos(clientOn.client,
                accountRegimeId)
            dispatch(allActions.rightContentActions.setRightContent(null))

        }

        if (flagDoNewAccount) {
            dispatch(allActions.rightContentActions.setRightContent({
                path: 'newAccount',
                data: {

                },
                clientAccounts: clientAccounts,
            }))

            resetFlag();
        }

    }, [clientOn,
        rightContent,
        accountRegimeId, flagDoNewAccount])



    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    onScroll={(value) => setAppearFab(value)}
                    stickyHeader
                    size="small"
                    selectable="true">
                    <col width="40%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="15%" />
                    {/* <col width="15%" /> */}
                    <TableHead>
                        <TableRow>
                            {/* <TableCell /> */}
                            <TableCell className={classes.tableTh} align="left">Instituição</TableCell>
                            <TableCell className={classes.tableTh} align="center">Agência</TableCell>
                            <TableCell className={classes.tableTh} align="center">Número da conta</TableCell>
                            <TableCell className={classes.tableTh} align="center">Posição inicial</TableCell>
                            <TableCell className={classes.tableTh} align="right">Saldo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientAccounts.map((row) => (
                            <TableRow key={'account-' + row.id}
                                className={classes.selectableRow}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'newAccount',
                                    update: true,
                                    data: row,
                                }))}>
                                <TableCell component="th" scope="row">
                                    {row.description}
                                </TableCell>
                                <TableCell align="center">{row.agency ? row.agency : "-"}</TableCell>
                                <TableCell align="center">{applyMaskAccount(row.number_account)}</TableCell>
                                <TableCell align="center">{moment.utc(row.account_init).format('MM/YYYY')}</TableCell>
                                <TableCell align="right"
                                    onClick={(e) => copyToClipboard(e, row.balance_validation ? row.balance_validation : 0)}
                                    className={classes.green}>{
                                        applyMaskAmount(row.balance_validation ? row.balance_validation : 0, true)
                                    }</TableCell>
                                {/* <TableCell align="center" className={classes.cellIcon}>
                                    <IconButton>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center" className={classes.cellIcon}>
                                    <IconButton>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                    {
                        clientAccounts && clientAccounts.length > 0 ?
                            <TableFooter className={classes.myTableFooter}>
                                <TableRow key={'footer-t-accounts'}>
                                    <TableCell align="left">Total</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="right"><span className={'spanTotal'}>{applyMaskAmount(resultAmount, true)}</span></TableCell>
                                </TableRow>
                            </TableFooter> : null
                    }
                </MyTable>
            </div>

            {/* {
                clientOn && clientOn.client ?
                    <Zoom
                        in={appearFab}
                        unmountOnExit>

                        <Fab className={classes.fabAdd}
                            onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                path: 'newAccount',
                                data: {

                                },
                                clientAccounts: clientAccounts,
                            }))}
                            color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Zoom> : null
            } */}


        </div>
    );
}



