const rightContent = (state = null, action) => {
    switch (action.type) {
        case "SET_RIGHT_CONTENT":
            return action.payload
        case "UPDATE":
            return {
                action: 'update'
            }
        case "SET_ACTION":

            console.log("STATE: ", state)
            console.log("ACTION: ", state)
            return {
                ...state,
                action: action.payload
            }
        default:
            return state
    }
}

export default rightContent;