import { getFundsByCnpjs } from "../API/fund";
import { PDFReaderAPI } from "../API/pdf_reader";
import allActions from "../actions";
import { INSTITUITIONS_IDS, INSTITUITIONS_NAMES } from "../components/modals/SelectInstituitionModal";
import { customTrimCNPJ, getMonthNumberByMonthShortName, justNumbers } from "../components/utils/utils";

export const PdfReaderController = {

    async processExtractsPdfs(instituition, pdfsFiles, selectedValidationYear, dispatch) {
        // const responseExtract = await PDFReaderAPI.getProcessedExtract(instituition, pdfsFiles);
        // return;
        const processedExtracts = [];
        const invalidExtracts = [];
        for (let i = 0; i < pdfsFiles.length; i++) {
            const pdfFile = pdfsFiles[i];
            console.log("pdfFile in loop: ", pdfFile);
            const responseExtract = await PDFReaderAPI.getProcessedExtract(instituition, pdfFile);
            console.log("responseExtract: ", responseExtract);
            if (responseExtract) {

                processedExtracts.push({
                    ...responseExtract,
                    pdfFileName: pdfFile.name,
                });
            } else {
                invalidExtracts.push(pdfFile.name)
            }

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: `Realizando a leitura dos pdfs...(${i + 1}/${pdfsFiles.length})`,
            }))
        }

        //compilando informações
        const fullExtracts = {
            transactions: [],
            balances: [],
        };
        console.log("processedExtracts: ", processedExtracts);
        processedExtracts.forEach(element => {
            const { transactions, balances } = element;
            if (balances.some(el => el.cc == "NaN" || el.cc == "IPMT")) {
                invalidExtracts.push(element.pdfFileName);
            } else {
                fullExtracts.transactions = [...fullExtracts.transactions, ...transactions]
                fullExtracts.balances = [...fullExtracts.balances, ...balances]
            }

        });

        //normalizando cnpjs que possuem zeros a esquerda

        const currentYear = selectedValidationYear;
        let currentMonth = null;

        ///REALIZAR OTIMIZACAO NO SERVIO PYTHON
        fullExtracts.balances.forEach(el => {
            if (el.agencia) { //removendo possíveis 0 a esquerda
                el.agencia = parseInt(justNumbers(el.agencia.toString())).toString();
            }
            el.cnpj = customTrimCNPJ(el.cnpj);
            el.balanceMonth = el[el.mes + "_valor"];
            el.qtdQuotas = el[el.mes + "_cota"];

            if (currentMonth === null) {
                currentMonth = getMonthNumberByMonthShortName(el.mes.toUpperCase());
            }
            el.month = currentMonth + "/" + currentYear;

        })

        ///REALIZAR OTIMIZACAO NO SERVIO PYTHON
        fullExtracts.transactions = fullExtracts.transactions.map(el => ({
            ...el,
            "CNPJ": customTrimCNPJ(el['CNPJ']),
        }))

        // console.log("fullExtracts: ", fullExtracts);
        // console.log("invalidExtracts: ", invalidExtracts);

        //Agrupando ativos por contas

        return {
            extracts: fullExtracts,
            invalidExtracts: invalidExtracts,
        };
    },


    async normalizeAccountsAssetsAndTransactions(toInsertBalances, transactionsToInsert, transactionsInExtracts, instituitionPdfs) {

        console.log("normalizeAccountsAssetsAndTransactions ", toInsertBalances);
        console.log("normalizeAccountsAssetsAndTransactions ", transactionsToInsert);
        const { validations, assets: assetsToCreate } = toInsertBalances;
        const { transactions } = transactionsToInsert;

        toInsertBalances.transactionsInExtracts = transactionsInExtracts;

        if (!validations.length) return null;

        const cnpjsFunds = validations.map(el => el.cnpj);
        const responseFundsByCnpjs = await getFundsByCnpjs(cnpjsFunds);
        console.log("responseFundsByCnpjs: ", responseFundsByCnpjs);
        const mapFundInfosByCnpj = {}
        responseFundsByCnpjs.body.rows.forEach(element => {
            mapFundInfosByCnpj[element.cnpj] = element;
        });

        const accountsIds = [];
        const accounts = [];

        const mapFakeAgency = {};
        validations.forEach(element => {

            const isNewAccount = element.account_id === "NOT_FOUND";
            const accountCod = !isNewAccount ?
                element.account_id : "NEW_" + (element.agencia ? element.agencia + "_" : "") + element.cc;
            if (!accountsIds.includes(accountCod)) {

                accountsIds.push(accountCod);
                accounts.push({
                    accountId: accountCod,
                    instituitionName: INSTITUITIONS_NAMES[instituitionPdfs],
                    instituitionId: INSTITUITIONS_IDS[instituitionPdfs],
                    isNew: isNewAccount,
                    cc: element.cc,
                    agency: element.agencia,
                    assets: []
                })
            }

            const theAccount = accounts.find(el => (!el.isNew && el.accountId === element.account_id) || (el.isNew && el.accountId === accountCod));
            if (theAccount) {
                const fundInfos = mapFundInfosByCnpj[element.cnpj];

                const transactionsInAsset = theAccount.isNew ?
                    transactionsInExtracts.filter(el => theAccount.accountId === ("NEW_" + (element.agencia ? element.agencia + "_" : "") + el['CC']) && el['CC'] === element.cc && (element.agency ? el['AGENCIA'] === element.agency : true) && el['CNPJ'] === element.cnpj).map(
                        el => (
                            {
                                "account_id": theAccount.accountId,
                                "operation_id": el['TIPO'],
                                "amount": el['VALOR'],
                                "description": "",
                                "cnpj": el['CNPJ'].trim().padStart(18, "0"),
                                "transaction_date": el['dataFormatada'],
                                "qtd_quotas": el['QUANTIDADE_COTAS']
                            }
                        )
                    ) :
                    transactions.filter(el => el.account_id === element.account_id && el.cnpj === element.cnpj);
                theAccount.assets.push({
                    asset: {
                        ...element,
                        fundInfos: fundInfos,
                        isNew: element.account_id.toString().includes("NEW_") || (assetsToCreate.some(el => el.fund_id === fundInfos?.id && element.cc === el.number_account))
                    },
                    transactions: transactionsInAsset,
                })



                if (!theAccount.init)
                    theAccount.init = element.month;
            }

        });

        console.log("accountsaccountsaccounts: ", accounts);

        return accounts;

    }

}