import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React from 'react';
import { sortArrayOrderBy } from './utils';

/**
 * @returns Ordem da coluna.
 * @description Esse objeto possui apenas 3 colunas.
 */
export const order = {
  /**
   * @param {Number} orderBy 
   * Recebe {@link order.ASC ASC} ou {@link order.DESC DESC} de {@link order}.
   */
  getColumn: (orderBy, columnNumber) => {
    return `${columnNumber}${orderBy}`;
  },
  /**
   * @returns {Number} Ascendente
   */
  ASC: 1,
  /**
   * @returns {Number} Descendente
   */
  DESC: 0,
};

export const distributionOptionClassification = {
  classe: "classe",
  device: "device",
  manager_anbima: "manager_anbima",
  administrator_anbima: "administrator_anbima",
  //benchmark_anbima: "benchmark_anbima"
  benchmark_anbima: "benchmark",
  disponibilization: "disponibilization_distribuition"
};

/**
    * Show column order icon according params.
    * @param {String} column 
    * @param {String} currentColumn Column provided by useState
    */
export function showColumnOrderIcon(column, currentColumn) {

  if (column != currentColumn.substring(0, 1)) {
    return null;
  } else {
    let style = { verticalAlign: 'bottom' };

    if (currentColumn.substring(1) == 1) {
      return <ArrowDropDown style={style} />
    } else {
      return <ArrowDropUp style={style} />
    }
  }
}

export function sortTableRows(currentOrderColumn, arrayToSort) {
  let [selectedColumn, selectedColumnDirection] = currentOrderColumn;
  selectedColumn = Number(selectedColumn);
  selectedColumnDirection = Number(selectedColumnDirection);

  let propertyLabel = undefined;

  switch (selectedColumn) {
    case 1:
      propertyLabel = 'label';
      break;
    case 2:
      propertyLabel = 'percent';
      break;
    case 3:
      propertyLabel = 'amount';
      break;
    default:
      break;
  }

  arrayToSort = sortArrayOrderBy(arrayToSort, propertyLabel, selectedColumnDirection);

  return arrayToSort;
}

export function invertColumnOrder(columnOrder) {
  let column = columnOrder[0];
  let direction = columnOrder[1];
  columnOrder = column;

  if (direction == order.ASC) {
    columnOrder += order.DESC;
  } else {
    columnOrder += order.ASC;
  }

  return columnOrder;
}

/**
 * 
 * @param {String} selectedDistributionOptionLabel 
 * @returns 
 */
export function isOptionValidToJustTwoWords(selectedDistributionOptionLabel) {
  const [DO_NOT_FORMAT, FORMAT] = [false, true];

  return selectedDistributionOptionLabel === distributionOptionClassification.device || distributionOptionClassification.benchmark_anbima ? DO_NOT_FORMAT : FORMAT;
}

const REMOVE_PRE_TEXT = '[r_p_t]';
export function checkFinalLabelDistribuitionTable(formatedLabel) {

  if (formatedLabel.includes('[r_p_t]')) {
    return formatedLabel.substring(1 + REMOVE_PRE_TEXT.length);
  } else {
    return formatedLabel;
  }

}

export function getLabelLiquidityDistribuition(numberDays) {

  if (numberDays >= 0 && numberDays <= 30) {

    return 'a' + REMOVE_PRE_TEXT + '0 a 30 dias';

  } else if (numberDays >= 31 && numberDays <= 180) {

    return 'b' + REMOVE_PRE_TEXT + '31 a 180 dias';

  } else if (numberDays >= 181 && numberDays <= 365) {

    return 'c' + REMOVE_PRE_TEXT + '181 a 365 dias';

  } else if (numberDays >= 366 && numberDays <= 700) {

    return 'd' + REMOVE_PRE_TEXT + '1 a 2 anos';

  } else if (numberDays >= 701) {

    return 'e' + REMOVE_PRE_TEXT + 'Acima 2 anos';

  } else {

    if (numberDays < 0) {
      return 'g' + REMOVE_PRE_TEXT + "Títulos Vencidos"
    }
    return 'f' + REMOVE_PRE_TEXT + numberDays;

  }

}