import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import Grow from '@material-ui/core/Grow';
import allActions from '../../actions'
import { unoTheme } from '../../assets/styles/unoTheme';

function Alert(props) {
    return <MuiAlert elevation={3} {...props} />;
}

const useStyles = makeStyles((theme) => ({
    alert: {
        width: '100%',
        background: 'white',
        marginBottom: theme.spacing(2),
        borderBottom: '6px',
        zIndex: 999,
    },

    info: {
        borderBottom: `solid 6px ${unoTheme.mainColor}`
    },
    warning: {
        borderBottom: `solid 6px ${unoTheme.warningColor}`
    },
    error: {
        borderBottom: `solid 6px ${unoTheme.errorColor}`
    },
    success: {
        borderBottom: `solid 6px ${unoTheme.successColor}`
    }
}));

/**
 * My main alert
 * @param {string} show 
 * @param {string} type
 * @param {string} message
 * @returns {Object} Alert
 */
export default function MyAlert({ alert }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(allActions.mainAlertActions.setMainAlert({
            show: false,
            type: alert.type,
            message: alert.message,
        }))
    };

    return (

        <Snackbar
            style={{ zIndex: 999 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alert && alert.show ? alert.show : false}
            autoHideDuration={5000}
            onClose={handleClose} >
            <Alert className={[classes.alert, alert.type ? classes[alert.type] : classes['info']].join(' ')} severity={alert && alert.type ? alert.type : "info"}>{alert.message}</Alert>
        </Snackbar >
        // <Snackbar open={true} autoHideDuration={5000} onClose={handleClose}>
        //     <Alert className={[classes.alert, alert.type ? classes[alert.type] : classes['info']].join(' ')} severity={alert && alert.type ? alert.type : "info"}>Vivamus vestibulum metus augue, eu vulputate ex ornare vitae. Donec in mi augue</Alert>
        // </Snackbar>
    );
}