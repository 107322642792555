import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//redux
import MyDatePicker from '../utils/MyDatePicker'

//Utils
import { portfolioClosedToDate } from '../utils/utils'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio, resetAccounts, resetAssets } from '../../API/client'
import { getTransactionsByDateRangeWithAPR } from '../../API/transaction'

//Controllers
import { saveLogResetAssetsAndAccounts } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    }


}));

export default function ResetAssetsAccountsModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { open,
        initDate,
        portfolioClosed,
        clientOnScreen,
        // months,
        // years,
        handleActionResetAssetsAccounts } = props;

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(initDate);
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    //Resetando ativos do cliente
    async function doResetAssetsAccountsClient(clientId, toDate) {

        setLoader(true);
        setValidateMessage("");

        //formatando caso não seja null        
        if (toDate) {
            toDate = moment.utc(toDate).format('MM/YYYY');
            console.log("To date: ", toDate);
        }

        //Verificando se a data de reset é permitida
        //Ela tem que ser maior do que o inicio da carteira e tem que ser menor do que a data da ultima carteira fechada

        //Verificando se é menor do que a ultima carteira fechada

        let statusToSave = "NO";
        let doUpdateDatePortfolioClosed = true;
        if (toDate) {

            statusToSave = "OK"

            let splitted2 = toDate.split('/');
            let newMonthClosed = parseInt(splitted2[0]);
            let newYearClosed = parseInt(splitted2[1]);

            if (portfolioClosed) {
                let splitted1 = portfolioClosed.split('/');
                let monthClosed = parseInt(splitted1[0]);
                let yearClosed = parseInt(splitted1[1]);

                if (newYearClosed > yearClosed) {
                    doUpdateDatePortfolioClosed = false;
                } else if (newYearClosed == yearClosed) {

                    if (newMonthClosed < monthClosed) {
                        doUpdateDatePortfolioClosed = true;
                    } else {
                        doUpdateDatePortfolioClosed = false;
                    }

                }

                console.log('newMonthClosed: ', newMonthClosed);
                console.log('newYearClosed: ', newYearClosed);
                console.log('monthClosed: ', monthClosed);
                console.log('yearClosed: ', yearClosed);
            } else {
                doUpdateDatePortfolioClosed = false;
            }

            //Verificando se é igual ao portfolio_init
            //Caso seja manda-se null
            let formattedInitDate = moment.utc(initDate).format("MM/YYYY");
            let splitted3 = formattedInitDate.split('/');

            let initMonthClosed = parseInt(splitted3[0]);
            let initYearClosed = parseInt(splitted3[1]);

            if (initMonthClosed == newMonthClosed && initYearClosed == newYearClosed) {
                toDate = null;
            }

            if (statusToSave == "OK") {

                if (newYearClosed < initYearClosed) {
                    statusToSave = "BEFORE_DATE"
                } else if (newYearClosed > initYearClosed) {
                    statusToSave = "OK"
                } else if (newYearClosed == initYearClosed) {

                    if (newMonthClosed >= initMonthClosed) {
                        statusToSave = "OK";
                    } else {
                        statusToSave = "BEFORE_DATE";
                    }

                }

            }

        }

        console.log("STATUS TO SAVE: ", statusToSave);
        console.log("doUpdateDatePortfolioClosed: ", doUpdateDatePortfolioClosed);

        if (!toDate) {
            statusToSave = "OK"
        }

        if (statusToSave == "OK") {

            //Caso existe uma data específica para não são excluídos todos os ativos e as contas
            //Apenas os que nasceram após a data especificadas
            if (toDate) {

                //caso comece com 0 como 01/2020, deve-se enviar para o banco apenas 1/2020

                //Para o método resetPortfolio basta enviar a data como string
                let toDateToSave = toDate != null && toDate.startsWith('0') ? toDate.substring(1) : toDate;

                let dateTimestampToLog = null;
                const dateToPortfolioClosed = toDateToSave;

                if (toDateToSave) {

                    let splittedDateToSave = toDateToSave.split('/');
                    let month = splittedDateToSave[0];
                    let year = splittedDateToSave[1];

                    dateTimestampToLog = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');

                    month++;
                    if (month == 13) {
                        month = 1;
                        year++;
                    }

                    //firstDay
                    toDateToSave = moment.utc(new Date(year, parseInt(month) - 1, 1)).format('DD/MM/YYYY');
                }

                console.log("APAGANDO TUDO QUE SEJA DEPOIS DE: ", toDateToSave);

                //Verificar se existem APRs preenchidas para esse período
                //const responseDoneAprs = await getTransactionsByDateRangeWithAPR(clientId, toDateToSave, portfolioClosedToDate(portfolioClosed));
                //Alterando pois na verdade devem ser obtidas todas as possíveis movimentações do cliente
                const responseDoneAprs = await getTransactionsByDateRangeWithAPR(clientId, toDateToSave, null);

                if (responseDoneAprs.success && responseDoneAprs.body && responseDoneAprs.body.rows && responseDoneAprs.body.rows.length == 0) {

                    //Remover contas que nasceram após a data especificada
                    let responseResetAccounts = await resetAccounts(clientId, toDateToSave);
                    if (responseResetAccounts.success) {

                        let responseResetAssets = await resetAssets(clientId, toDateToSave);
                        if (responseResetAssets.success) {

                            //doUpdateDatePortfolioClosed deve ser enviado quando de fato deve ser atualizado a data de fechamento da carteira
                            //O único caso que isso ocorre é quando o dia de fechamento é depois do dia que foi indicado em toDateSave1
                            //Então a data deve voltar para o indicado em toDateSave1
                            let responseResetPortfolioClosed = await resetPortfolio(clientId, toDateToSave, dateToPortfolioClosed, doUpdateDatePortfolioClosed);
                            if (responseResetPortfolioClosed.success) {

                                if (clientOn.client.id == clientId && doUpdateDatePortfolioClosed) {
                                    clientOn.client.portfolio_closed = dateToPortfolioClosed;
                                    clientOn.client.updateInfosClient = true;
                                    let updatedClient = {
                                        ...clientOn.client,
                                    }

                                    console.log("Atualizando cliente: ", updatedClient);
                                    dispatch(allActions.clientActions.setClient(updatedClient))
                                }

                                dispatch(allActions.mainAlertActions.setMainAlert({
                                    show: true,
                                    type: 'success',
                                    message: "Ativos e contas resetadas com sucesso!"

                                }));

                                console.log("dateToPortfolioClosed: ", dateToPortfolioClosed);

                                //Registrando logAction

                                let responseLogAction = await saveLogResetAssetsAndAccounts(currentUser.user.id, clientId, dateTimestampToLog);

                                handleActionResetAssetsAccounts(false)
                                history.push('/administration/clients');

                            } else {

                                setLoader(false);
                                setValidateMessage("Falha ao resetar fechamento da carteira!");

                            }

                        } else {

                            setLoader(false);
                            setValidateMessage("Falha ao resetar ativos!");

                        }

                    } else {

                        setLoader(false);
                        setValidateMessage("Falha ao resetar contas!");

                    }
                } else {

                    setLoader(false);
                    if (responseDoneAprs.body && responseDoneAprs.body.rows) {

                        setValidateMessage("Não é possível resetar os ativos para " + dateToPortfolioClosed + " pois existem APRs preenchidas nesse período [ " + responseDoneAprs.body.rows.length + " ]");
                    } else {
                        setValidateMessage("Falha ao verificar existência de APRs preenchidas!");
                    }
                }

                //Remover ativos que nasceram após data especificada

                //Atualizar fechamento

            } else {

                //Caso não haja data específica exclui-se tudo
                //Verificar se existem APRs preenchidas para esse período
                const responseDoneAprs = await getTransactionsByDateRangeWithAPR(clientId, null, null);

                if (responseDoneAprs.success && responseDoneAprs.body && responseDoneAprs.body.rows && responseDoneAprs.body.rows.length == 0) {

                    //Os ativos são deletados automaticamente a partir do CASCADE DELETE das contas
                    let responseResetAccounts = await resetAccounts(clientId);
                    if (responseResetAccounts.success) {

                        //Resetando o fachamento da carteira do cliente
                        let responseResetPortfolioClosed = await resetPortfolio(clientId, null, null, true);
                        if (responseResetPortfolioClosed.success) {

                            if (clientOn.client.id == clientId) {
                                clientOn.client.portfolio_closed = null;
                                clientOn.client.updateInfosClient = true;
                                let updatedClient = {
                                    ...clientOn.client,
                                }

                                console.log("Atualizando cliente: ", updatedClient);
                                dispatch(allActions.clientActions.setClient(updatedClient))
                            }

                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'success',
                                message: "Ativos e contas resetadas com sucesso!"

                            }));

                            //Registrando logAction
                            let responseLogAction = await saveLogResetAssetsAndAccounts(currentUser.user.id, clientId);

                            handleActionResetAssetsAccounts(false)

                        } else {

                            setLoader(false);
                            setValidateMessage("Falha ao resetar fechamento da carteira!");

                        }



                    } else {

                        setLoader(false);
                        setValidateMessage("Falha ao resetar contas!");

                    }
                } else {
                    setLoader(false);
                    if (responseDoneAprs.body && responseDoneAprs.body.rows) {

                        setValidateMessage("Não é possível resetar os ativos pois existem APRs preenchidas nesse período [ " + responseDoneAprs.body.rows.length + " ]");
                    } else {
                        setValidateMessage("Falha ao verificar existência de APRs preenchidas!");
                    }
                }


            }

        } else {
            setLoader(false);
            setValidateMessage("A data deve estar entre o início da carteira e a última carteira fechada");
        }


    }

    useEffect(() => {

        // console.log("Months: ", months);
        // console.log("Years: ", years);


    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => handleActionResetAssetsAccounts(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Resetar Ativos e Contas"}
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {
                    loader ?
                        <div className={classes.progress}>
                            <CircularProgress
                                disableShrink color="inherit" />
                            <Typography variant={"h6"}>
                                {"Realizando operação..."}
                            </Typography>
                        </div>
                        :
                        <>
                            <Typography style={{ textAlign: 'center', marginBottom: '16px' }}>
                                {`Selecione o mês e o ano para onde deseja voltar na carteira`}
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'center', marginBottom: '16px' }}>
                                {`Atual mês de lançamento: ${portfolioClosed ? portfolioClosed : '-'}`}
                            </Typography>

                            <MyDatePicker
                                className={classes.myDatePicker}
                                views={["month", "year"]}
                                margin="dense"
                                id="date-picker-dialog"
                                format="MM/YYYY"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{ 'aria-label': 'change date', }} />
                        </>
                }

                {
                    validateMessage != "" ?
                        <Typography variant={"body2"} style={{ color: 'red', textAlign: 'center', marginTop: '16px', padding: '16px', backgroundColor: '#eeeeee', borderRadius: '5px' }}>
                            {validateMessage}
                        </Typography>
                        :
                        null
                }


            </DialogContent>


            {
                !loader ?
                    <DialogActions className={classes.actionsFlex}>

                        <UnoButton onClick={() =>
                            handleActionResetAssetsAccounts(false)} color="secondary">
                            {'Cancelar'}
                        </UnoButton>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <UnoButton
                                className={classes.spaceRight2}
                                onClick={() => doResetAssetsAccountsClient(clientOnScreen.id, null)}
                                type={'successClean'}>
                                {'Resetar tudo'}
                            </UnoButton>

                            <UnoButton
                                // disabled
                                className={classes.spaceRight2}
                                onClick={() => doResetAssetsAccountsClient(clientOnScreen.id, selectedDate)}
                                color="primary">
                                {'Resetar para [' + moment.utc(selectedDate).format('MM/YYYY') + "]"}
                            </UnoButton>
                        </div>

                    </DialogActions>
                    : null

            }




        </Dialog >
    );
}