import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getFundArticles } from '../../../API/fund';
import { getResolutionVersions, listAllLegislationArticles, listLegislationSegments } from '../../../API/legislation_article';
import { unoTheme } from '../../../assets/styles/unoTheme';
import MyDatePicker from '../../utils/MyDatePicker';
import MySelect from '../MySelect';
import MyDivider from '../MyDivider';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UnoButton from '../UnoButton';
import { IconButton, Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    root: {
        '& .articleNode': {

            //background: unoTheme.secondaryTuplaTable,
            border: "solid 2px #ccc",
            borderRadius: "10px",
            marginBottom: "8px",
            padding: "12px",
            position: "relative",

            '& .periodDates': {
                display: "flex",
                alignItems: "center",
                gap: "16px"
            },

            '& .articleInfos': {
                display: "flex",
                alignItems: "center",
                gap: "16px"
            },

            '& .confirmRemoveOverlay': {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",

                '& .theOverlay': {
                    background: "white",
                    opacity: 0.9,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                },

                '& .theActions': {
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    gap: "8px",
                    justifyContent: 'center',
                }


            },

            '& .Mui-error': {
                marginTop: '4px',
                marginLeft: '0px',
                fontSize: '0.75rem',
                marginTop: '3px',
                textAlign: 'left',
                fontWeight: '400',
                lineHeight: '1.66',
                color: '#f44336',
            },

            '& .articleNode.articleNodeError': {
                border: "solid 2px #f44336",
            }

        }
    },

    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: 'blue',
    },
}));

export default function FundArticlesWrapper(props) {

    const classes = useStyles();

    const {
        fundId,
        articlesNodesCallback,
        submitted,
        validatedMessages
    } = props

    console.log("validatedMessages xx: ", validatedMessages)

    const [loadedArticles, setLoadedArticles] = useState([]);

    const [allResolutions, setAllResolutions] = useState([]);
    const [allSegments, setAllSegments] = useState([]);
    const [allArticles, setAllArticles] = useState([]);

    const updateArticleNode = (index, key, value) => {

        const currentArticlesNodes = [...loadedArticles];
        currentArticlesNodes[index] = {
            ...currentArticlesNodes[index],
            [key]: value,
        }
        setLoadedArticles(currentArticlesNodes);
    }

    const getArticleNode = useCallback((index, article, notEnableDelete) => {

        console.log("validatedMessages: ", validatedMessages);

        const { fund_id, article_id,
            begin_period, end_period,
            resolution_id, segment_id, confirmDelete } = article;

        const selectResolutionsOptions = allResolutions.map(el => ({
            id: el.id, name: el.resolution_name
        }));
        const selectSegmentsOptions = allSegments.filter(el => el.resolution_version_id == resolution_id).map(el => ({
            id: el.id, name: el.description
        }));
        const selectArticlesOptions = allArticles.filter(el => el.legislation_segment_id == segment_id).map(el => ({
            id: el.id, name: el.device
        }));

        const isErrorMode = validatedMessages[index]?.length;

        return <div className={`articleNode ${isErrorMode ? 'articleNodeError' : ''}`}>
            <div className='periodDates'>

                <MyDatePicker
                    defaultValue={null}
                    margin="dense"
                    outerlabel="Início período"
                    format="DD/MM/YYYY"
                    value={begin_period}
                    onChange={(value) => updateArticleNode(index, 'begin_period', value)} />

                <MyDatePicker
                    defaultValue={null}
                    margin="dense"
                    outerlabel="Fim período"
                    format="DD/MM/YYYY"
                    value={end_period}
                    onChange={(value) => updateArticleNode(index, 'end_period', value)} />

                {
                    !notEnableDelete &&
                    <IconButton onClick={() => updateArticleNode(index, 'confirmDelete', true)}>
                        <DeleteIcon />
                    </IconButton>
                }
            </div>
            <div className='articleInfos'>

                <MySelect
                    customWidth="20%"
                    initblank
                    outerlabel={'Resolução'}
                    value={resolution_id}
                    onChange={(e) => updateArticleNode(index, 'resolution_id', e.target.value)}
                    options={selectResolutionsOptions.map((row, index) => (
                        <option key={'resolution-options' + row.id} value={row.id}>{row.name}</option>
                    ))}
                    helperText={"Resolução"}
                //validator={inputLegislationSegmentFundV1 == undefined && submitted ? true : undefined}
                />

                <MySelect
                    customWidth="35%"
                    initblank
                    outerlabel={'Seg. da legislação'}
                    disabled={!resolution_id}
                    value={segment_id}
                    onChange={(e) => updateArticleNode(index, 'segment_id', e.target.value)}
                    options={selectSegmentsOptions.map((row, index) => (
                        <option key={'segment-select-fund-article' + row.id} value={row.id}>{row.name}</option>
                    ))}
                    helperText={"Segmento"}
                //validator={inputLegislationSegmentFundV1 == undefined && submitted ? true : undefined}
                />

                <MySelect
                    customWidth="45%"
                    initblank
                    outerlabel={'Art. de resolução'}
                    value={article_id}
                    disabled={!segment_id}
                    onChange={(e) => updateArticleNode(index, 'article_id', e.target.value)}
                    options={selectArticlesOptions.map((row, index) => (
                        <option key={'article-select-fund-artcile' + row.id} value={row.id}>{row.name}</option>
                    ))}
                    helperText={"Artigo"}
                //validator={inputArticleFundV1 == undefined && submitted ? true : undefined}
                />

            </div>
            {
                confirmDelete &&
                <div className={"confirmRemoveOverlay"} style={{ display: "flex", alignItems: 'center' }}>
                    <div className='theOverlay'></div>
                    <div className='theActions'>
                        <Typography> Tem certeza que deseja remover o enquadramento?</Typography>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', gap: "8px" }}>
                            <UnoButton
                                size="verySmall"
                                type={'primary'}
                                onClick={() => removeNode(index)}>
                                {'Sim'}
                            </UnoButton>
                            <UnoButton
                                size="verySmall"
                                color="secondary"
                                onClick={() => updateArticleNode(index, 'confirmDelete', false)}>
                                {'Cancelar'}
                            </UnoButton>
                        </div>
                    </div>

                </div>
            }

            {
                submitted &&
                validatedMessages[index] &&
                <div>
                    {validatedMessages[index].map(el => (<div className='Mui-error'>{el}</div>))}
                </div>
            }

        </div>

    }, [
        loadedArticles,
        allResolutions,
        allSegments,
        allArticles,
        submitted,
        validatedMessages
    ]);

    const articles = useMemo(() => {
        const loadedArticlesSize = loadedArticles.length;
        return loadedArticles.map((el, index) => getArticleNode(index, el, loadedArticlesSize == 1 && index == 0));

    }, [loadedArticles,
        submitted,
        validatedMessages]);

    const loadFundArticles = async (fundId) => {

        if (!fundId) {
            setLoadedArticles([{
                fund_id: null,
                article_id: null,
                begin_period: null,
                end_period: null,
            }]);
        } else {

            //obtendo articles from fund
            const response = await getFundArticles(fundId);

            if (response.success) {

                const articles = [...response.body.rows];
                if (!articles.length) {
                    articles.unshift({
                        fund_id: fundId,
                        article_id: null,
                        begin_period: null,
                        end_period: null,
                    })
                }

                setLoadedArticles(articles);

            } else {
                setLoadedArticles([]);
            }
        }


    }

    const addNode = () => {
        const current = [...loadedArticles]
        current.push({
            fund_id: fundId,
            article_id: null,
            begin_period: null,
            end_period: null,
        });
        setLoadedArticles(current);
    }

    const removeNode = (index) => {
        const current = [...loadedArticles]
        current.splice(index, 1);
        console.log("CURRENT: ", current);
        setLoadedArticles(current);
    }

    useEffect(() => {
        if (!allResolutions?.length || !allSegments?.length || !allArticles?.length) return;
        console.log("allResolutions, allSegments, allArticles: ", allResolutions, allSegments, allArticles);
        loadFundArticles(fundId);
    }, [allResolutions, allSegments, allArticles])

    const preLoad = async () => {

        const reslutionsVersions = await getResolutionVersions()
        const legislationSegments = await listLegislationSegments();
        const legislationArticles = await listAllLegislationArticles();

        if (reslutionsVersions.success
            && legislationSegments.success
            && legislationArticles.success) {

            setAllResolutions(reslutionsVersions.body.rows);
            setAllSegments(legislationSegments.body.rows);
            setAllArticles(legislationArticles.body.rows);

        }
    }

    useEffect(() => {
        articlesNodesCallback(loadedArticles)
    }, [loadedArticles])

    useEffect(() => {
        console.log("fundId: ", fundId);
        preLoad();

    }, [fundId])

    return (

        <div className={classes.root}>
            <MyDivider title={"Enquadramentos por período"} />
            {articles}
            <UnoButton
                type={'primary'}
                startIcon={<AddIcon />}
                float={'right'}
                onClick={() => addNode()}>
                {'Enquadramento'}
            </UnoButton>
        </div>
    );
}