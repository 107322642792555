import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//Api
import { getAllTitles, getTitlesBySearch } from '../../../../API/title'

//redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";

import { headerSearch } from '../../../../assets/styles/unoTheme';

import MyInnerLoad from '../../../utils/MyInnerLoad';

//Utils
import { getTitleCod, removeEspecialChars } from '../../../utils/utils';

import moment from 'moment';
import { useTitlesStyles } from "./styles";
import FundsAndTitlesScreen from './index';
import { isEmpty } from "lodash";

/**
 * Componente para:
 * - Listagem de títulos do sistema;
 * @param {String} search Valor para ser consultado;
 * @returns {JSX.Element}
 */
export default function TitlesScreen() {

    const classes = useTitlesStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const search = '';

    const clientOn = useSelector(state => state.clientOn)
    const rightContent = useSelector(state => state.rightContent)

    const [titles, setTitles] = useState(undefined);
    const [itemsPage, setItemsPage] = useState(15);
    const [page, setPage] = useState(1);

    async function getTitles(search) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando títulos...'
        }));

        let response = {};

        if (isEmpty(search)) {
            response = await getAllTitles();
        } else {
            response = await getTitlesBySearch(search);
        };

        const titlesResponse = response.body.rows;

        if (response.success) {

            console.log('titlesResponse: ', titlesResponse);

            if (isEmpty(titlesResponse)) {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem títulos registrados' }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            };

            setTitles(titlesResponse);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Falha ao obter lista de títulos"
            }));
        };
    };

    useEffect(() => {


        if (!titles
            //|| search || isEmpty(search)
        ) {
            getTitles(search);
        };

        if (rightContent && rightContent.action) {

            getTitles();
            dispatch(allActions.rightContentActions.setRightContent(null))

        };
    }, [
        clientOn,
        rightContent,
        search
    ])

    return (
        <div className={classes.root}>
            <div class={'defaultScrollWithHeaderContent'}>
                {
                    titles ?

                        <MyTable
                            pagination={search == '' && titles && titles.length > 0}
                            itemspage={itemsPage}
                            totalitems={titles ? titles.length : 0}
                            handlePage={(value) => setPage(page + value)}
                            stickyHeader
                            selectable="true"
                            size="small">
                            <colgroup>
                                <col width="30%" />
                                <col width="25%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTh} align="left">Identificador</TableCell>
                                    <TableCell className={classes.tableTh} align="left">Nome</TableCell>
                                    <TableCell className={classes.tableTh} align="left">Emissão</TableCell>
                                    {/* <TableCell className={classes.tableTh} align="left">Compra</TableCell> */}
                                    <TableCell className={classes.tableTh} align="left">Liquidação</TableCell>
                                    <TableCell className={classes.tableTh} align="left">Vencimento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {titles.map((row, index) => (
                                    <>
                                        {
                                            <TableRow
                                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                                    path: 'newTitle',
                                                    update: true,
                                                    data: row
                                                }))}
                                                className={classes.quotaRow}
                                                key={'title' + index}>
                                                <TableCell align="left">{getTitleCod(row)}</TableCell>
                                                <TableCell align="left">{row.title_name}</TableCell>
                                                <TableCell align="left">{row.sector == 'PUBLICO' ? moment.utc(row.issue_date).format("DD/MM/YYYY") : '-'}</TableCell>
                                                <TableCell align="left">{row.sector == 'PUBLICO' ? moment.utc(row.sale_off_date).format("DD/MM/YYYY") : '-'}</TableCell>
                                                <TableCell align="left">{row.sector == 'PUBLICO' ? moment.utc(row.due_date).format("DD/MM/YYYY") : '-'}</TableCell>
                                            </TableRow>
                                        }
                                    </>
                                ))}
                            </TableBody>
                        </MyTable>

                        :
                        <>
                            <MyInnerLoad message={'Carregando fundos...'} />
                        </>
                }


            </div>

        </div >
    );
}



