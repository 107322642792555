import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField'
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

//Tables
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//API
import {
    createFund, updateFund, deleteFund
} from '../../../API/fund'

//Controllers
import {
    getQuotasByListFunds
} from '../../../controllers/FundController'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import UnoButton from '../../utils/UnoButton';

import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import { applyMaskAmount, applyMaskAmountFreeDecimals, formatAnbimaDateToPt } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 0.5px #f4f4f4',
    },

    main: {
        height: 'calc(100% - 96px)',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    bottom: {
        paddingTop: '16px',
        display: 'flex',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.85,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },

    hiddenIcon: {
        visibility: 'hidden',
    },

    invalidBlock: {

        '& .top-invalid-block': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            '& h4': {
                marginBottom: '0px',
            },

            '& .anbima-cod': {
                color: unoTheme.defaultTextColor,
                margin: '0px',
            },

            '& .situation': {
                color: unoTheme.redMoney,
            }
        },

        '& .middle-invalid-block': {

            paddingTop: '24px',

            '& .no-days': {

                color: 'blue',
            }
        },

        '& .actions': {
            marginTop: '16px',
        },

        paddingBottom: '16px',
        borderBottom: 'solid 1px #dddddd',

    },

    noPaddingTable: {
        paddingLeft: '0px',
        paddingRight: '0px',
    }

}));

export default function InvalidQuotas(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState("");

    const [invalidBlocksData, setInvalidBlocksData] = useState([]);


    async function loadInfos(data) {

        console.log("DATA: ", data);
        let invalidData = [];
        Object.entries(data.invalidQuotas).map(([key, row]) => {

            console.log("ROW INVELIDS: ", row);

            data.clientFunds.forEach(clientFund => {

                if (clientFund.id == key) {

                    let situation = null;

                    if (row.invalids && row.invalids.length > 0) {
                        situation = "Ausência de cotas diárias"
                    }

                    if (row.allQuotas == '404') {
                        situation = "Nenhuma cota encontrada"
                    }

                    invalidData.push({
                        ...clientFund,
                        ...row,
                        situation: situation,
                    });

                }

            });

        })

        console.log("INVALID DATA: ", invalidData);

        setInvalidBlocksData(invalidData);

    }

    function formatInvalidDaysToShow(invalidDays) {
        let formatted = "";
        invalidDays.forEach(element => {
            formatted += "[" + element + "], "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function renderInvalidQuotasBlocks(data) {

        let blocks = [];

        data.forEach(element => {

            blocks.push(
                <div className={classes.invalidBlock}>

                    <div class="top-invalid-block">

                        <div>
                            <h4>
                                {element.fund_name}
                            </h4>
                            {/* <h5 class="anbima-cod">
                                Código Anbima: {element.anbima_cod && element.anbima_cod != "" ? element.anbima_cod : "[[Código ANBIMA não informado]]"}
                            </h5> */}
                            {/* <h5 class="anbima-cod">
                                Código Anbima: {element.anbima_cod && element.anbima_cod != "" ? element.anbima_cod : "[[Código ANBIMA não informado]]"}
                            </h5> */}
                        </div>

                        <div class="situation">
                            {element.situation}
                        </div>

                    </div>
                    <div class='middle-invalid-block'>

                        <span>
                            <span>Dias inválidos: </span><span class="no-days">{formatInvalidDaysToShow(element.invalids)}</span>
                        </span>


                    </div>

                    {
                        element.newQuotasAnbima && element.newQuotasAnbima != "404" ?
                            <div class='new-anbima-quotas'>


                                <h4>Cotas atuais ANBIMA</h4>
                                <MyTable
                                    customclass={classes.noPaddingTable}
                                    style={{ marginTop: '8px' }}
                                    stickyHeader
                                    sizeheaders="verySmall"
                                    size="small">

                                    <TableBody>
                                        {
                                            element.newQuotasAnbima.map((row, index) => (


                                                <TableRow key={'new-anbima-quota-' + index}>

                                                    <TableCell align="left">
                                                        {formatAnbimaDateToPt(row.data_referencia)}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {applyMaskAmountFreeDecimals(row.valor_cota, true)}
                                                    </TableCell>

                                                </TableRow>



                                            ))

                                        }
                                    </TableBody>
                                </MyTable>


                            </div>

                            : null
                    }


                </div>
            )

        });

        return blocks;
    }

    async function verifyAnbimaQuotas(data) {


        var firstDay = new Date(content.data.year, parseInt(content.data.month) - 1, 1);
        var lastDay = new Date(content.data.year, parseInt(content.data.month), 0);

        let anbimaCodsAndFundIds = [];
        data.forEach(element => {

            //Caso em que não é trazida nenhuma cota do fundo
            if (element.allQuotas != "404") {
                anbimaCodsAndFundIds.push({
                    anbima_cod: element.anbima_cod,
                    fund_id: element.id,
                })
            }


        });

        let responseGetQuotas = await getQuotasByListFunds(anbimaCodsAndFundIds,
            moment(firstDay).format("DD/MM/YYYY"),
            moment(lastDay).format("DD/MM/YYYY"));

        if (responseGetQuotas) {

            console.log("QUOTAS: ", responseGetQuotas);

            let newInvalidBlocksData = [...invalidBlocksData];

            newInvalidBlocksData.forEach(invalidBlock => {

                invalidBlock.newQuotasAnbima = responseGetQuotas.filteredQuotas[invalidBlock.anbima_cod];

            });

            setInvalidBlocksData(newInvalidBlocksData);

        }

    }

    useEffect(() => {

        //setOpenBackdrop(true);
        console.log("COTAS INVALIDAS: ", content.data);
        loadInfos(content.data);

    }, [])

    return (
        <div className={classes.root}>


            <div className={classes.header}>
                <IconButton className={classes.hiddenIcon}>
                    <CloseIcon />
                </IconButton>

                <div>
                    <Typography
                        variant={'h6'}
                        align={'left'}>
                        {'Detalhamento das cotas inválidas'}

                    </Typography>
                </div>


                <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div>
            <div className={classes.main}>


                {invalidBlocksData && invalidBlocksData.length > 0 ?
                    renderInvalidQuotasBlocks(invalidBlocksData)
                    :
                    null
                }

                {/* <div className={classes.bottom}>

                    {invalidBlocksData && invalidBlocksData.length > 0 ?
                        <UnoButton
                            type={'success'}
                            float={'right'}
                            onClick={() => verifyAnbimaQuotas(invalidBlocksData)}>
                            {"Verificar Anbima"}
                        </UnoButton>
                        :
                        null
                    }



                </div> */}

            </div>

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                        {
                            messageBackdrop != "" ?
                                <Typography variant={'body1'}>
                                    {messageBackdrop}
                                </Typography>
                                : null
                        }

                    </div> : null
            }
        </div>
    );
}