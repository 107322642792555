import API from './config'

export async function createTitle(title) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/create', title, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateTitle(title) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/titles/update', title, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createTitleType(titleType) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/createTitleType', titleType, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateTitleType(titleType) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/titles/updateTitleType', titleType, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteTitle(titleId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/titles/delete/' + titleId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllTitles() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/titles/listAllTitles', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

};

export async function getTitlesBySearch(search) {
    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {
        const response = await API.get('/titles/getTitlesBySearch?search=' + search, config);

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }
};

export async function getTitleTypes() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/titles/getTitleTypes', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createAssetTitle(assetTitle) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/createAssetTitle', assetTitle, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateAssetTitle(assetTitle) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/titles/updateAssetTitle', assetTitle, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateTradingNoteAssetTitle(assetTitleId, newTradingNote) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.patch('/titles/updateTradingNoteAssetTitle/' + assetTitleId, { new_trading_note: newTradingNote }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteAssetTitle(assetTitleId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/titles/deleteAssetTitle/' + assetTitleId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientTitles(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/titles/getAllClientTitles/' + clientId,
            config
        )

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getTitlesByIds(titlesIds) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getTitlesByIds',
            { titles_ids: titlesIds },
            config
        )

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getTitleClientAccountList(titleId, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getTitleClientAccountList', {
            title_id: titleId,
            client_id: clientId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAssetsTitles(year, client_id, regime) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getClientAssetsTitles',
            { year, client_id, regime },
            config
        )

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAllAssetsTitleByDateAndRegime(clientId, startDate, endDate, accountRegimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getClientAllAssetsTitleByDateAndRegime', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            account_regime_id: accountRegimeId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAmortizationsByTitleAssetsIds(idsInClause, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getAmortizationsByTitleAssetsIds', {
            in_clause: idsInClause,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAllAssetsTitleByDateAndRegimeWithInits(clientId, startDate, endDate, accountRegimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getClientAllAssetsTitleByDateAndRegimeWithInits', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            account_regime_id: accountRegimeId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function saveCoupon(coupon) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/saveCoupon', coupon, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getBalancesByMonthAndYear(clientId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/titles/getBalancesByMonthAndYear?client_id=${clientId}&month=${month}&year=${year}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getBalancesByPeriod(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/titles/getBalancesByPeriod?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getCouponsByTitleIdAndYear(titleId, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/titles/getCouponsByTitleIdAndYear', {
            title_id: titleId, year: year,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

