export const suitabilityEnum = {
    conservador: "CONSERVADOR",
    moderado: "MODERADO",
    agressivo: "AGRESSIVO",
};

export function getSuitabilityIndex(suitability) {
    const index = getSuitabilityOptions().findIndex(el => el == suitability)
    return index >= 0 ? index : null;
}


export function getSuitabilityOptions() {

    return [
        suitabilityEnum.conservador,
        suitabilityEnum.moderado,
        suitabilityEnum.agressivo
    ]

}