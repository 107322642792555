import API from './config'

export async function login(cpf, password) {

    try {

        const response = await API.post('/users/login', {
            cpf: cpf,
            password: password
        })

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function me() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("CHAMANDO ME")
        const response = await API.get('/users/me', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error)

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function create(user) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/users', user, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function updateUser(userId, user) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/users/update', {
            id: userId,
            user: user
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function deleteUser(userId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/users/delete/' + userId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function listAllAdvisors() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/listAllAdvisors', config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllAdvisorsAndAdmins() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/listAllAdvisorsAndAdmins', config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllAdvisorsAndAdminsByConsulting(consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/listAllAdvisorsAndAdminsByConsulting/' + consultingId, config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAdvisorByNameOrCPF(searchInputValue) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getAdvisorByNameOrCPF/' + searchInputValue, config);

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAdvisorOrAdminByNameOrCPF(searchInputValue) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getAdvisorOrAdminByNameOrCPF/' + searchInputValue, config);

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAdvisorOrAdminByNameOrCPFByConsulting(searchInputValue, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getAdvisorOrAdminByNameOrCPFByConsulting?search=' + searchInputValue + "&consulting_id=" + consultingId, config);

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateStatus(userId, status) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/users/updateStatus', {
            id: userId,
            status: status,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getManagersByClient(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("CLIENT_ID: ", clientId)

    try {

        const response = await API.post('/users/getManagersByClient', {
            client_id: clientId
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getActiveAdvisors() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getActiveAdvisors', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getActiveAdvisorsAndAdmins() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getActiveAdvisorsAndAdmins', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getActiveAdvisorsAndAdminsByConsulting(consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/users/getActiveAdvisorsAndAdminsByConsulting/' + consultingId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function addClientToAdvisor(clientId, userId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("CLIENT_ID: ", clientId)

    try {

        const response = await API.post('/users/addClientToAdvisor', {
            client_id: clientId,
            user_id: userId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function removeClientToAdvisor(advisorClientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/users/removeClientToAdvisor/' + advisorClientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAdvisorClients(advisorId, orderBy) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/users/getAdvisorClients/', {
            advisor_id: advisorId,
            orderBy: orderBy
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getUserEmailByCpf(cpf) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("ENVIANDO CPF: ", cpf);
        const response = await API.get('/users/getUserEmailByCpf/' + cpf, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updatePassByHash(hash, newPass) {

    try {

        const response = await API.post('/users/updatePassByHash', { hash: hash, password: newPass })

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function checkHashResetPass(hash) {

    try {

        const response = await API.post('/users/checkHashResetPass', { hash })

        return {
            success: true,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

