import { makeStyles } from "@material-ui/core";
import { unoTheme, toogleButton, headerSearch } from "../../../../assets/styles/unoTheme";

/**
 * Estilos do Material-UI v4
 */
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconButton: {
    padding: '5px',
  },
  toogleButton: toogleButton,
  headerSearch: headerSearch,

  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    borderRadius: '10px 0px 0px 10px',
    backgroundColor: 'white',
  },

  fabAdd: {
    position: 'absolute',
    backgroundColor: unoTheme.mainColor,
    right: theme.spacing(4),
    bottom: unoTheme.bottomFab,
    '&:hover': {
      background: unoTheme.hoverMainButtonColor,
    }
  },
}));
