import React from 'react';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { unoTheme } from '../../assets/styles/unoTheme';

const useStyles = makeStyles((theme) => ({

    root: {
        position: 'relative',
    },


    innerLoadRoot: {
        zIndex: 1000,
        position: "absolute",
        color: unoTheme.mainColor,

        width: '100%',
        height: '100%',
        borderRadius: '10px 10px 0px 0px',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .loader': {
            zIndex: 1001,
        },

        '& .small': {
            '& .MuiCircularProgress-root': {
                width: '25px !important',
                height: '25px !important',
            },

            '& h6': {
                fontSize: '14px !important',
            }
        },

        '& .background': {

            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'white',
            opacity: 0.7,
            zIndex: 1000,
            width: '100%',
            borderRadius: '10px 10px 0px 0px',


        },

        '& .background.extraMargin': {
            marginTop: "-46px",
        }

    },

}));

/**
* 
* @param {import('react').PropsWithChildren<{
*   innerLoad: {
*       emptyMessage: string,
*       loadMessage: string,
*   }
* }>} props 
* @returns 
*/
export default function DefaultScrollWithHeaderContent(props) {

    const classes = useStyles();
    const { children, innerLoad } = props;

    return (
        <div className={["defaultScrollWithHeaderContent", classes.root].join(" ")}>

            {children}

            {
                innerLoad ?
                    <div className={classes.innerLoadRoot}>
                        {
                            !innerLoad.emptyMessage || innerLoad.emptyMessage == "" ?
                                <div className={`background ${innerLoad.extraMargin ? 'extraMargin' : ''}`}>
                                </div> : null
                        }

                        <div className={["small", "loader"].join(' ')}>

                            {
                                innerLoad.loadMessage && innerLoad.loadMessage != '' ?
                                    <>
                                        <CircularProgress
                                            disableShrink color="inherit" />
                                        <Typography variant={"h6"}>
                                            {innerLoad.loadMessage}
                                        </Typography>
                                    </> : null
                            }

                            {
                                innerLoad.emptyMessage && innerLoad.emptyMessage != '' ?
                                    <>
                                        <Typography variant={"h6"}>
                                            {innerLoad.emptyMessage}
                                        </Typography>
                                    </> : null
                            }

                        </div>
                    </div>
                    : null
            }

        </div>
    )

}