import React, { useCallback, useEffect, useState } from 'react';
import { Fab, Typography, Zoom, makeStyles } from '@material-ui/core';
import { roles } from '../../../../utils/user';
import _, { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../../actions';
import { getAllUsersClientByRole } from '../../../../../API/client';
import { unoThemeStyles } from '../../../../../assets/styles/unoTheme';
import { RightDrawerActionEnum } from '../../../RightContent';
import AddIcon from '@material-ui/icons/Add';
import PageWrapper from '../../../../utils/PageWrapper';
import { clientViewerPath } from '../../../rightContent/ClientViewer';
import { updateStatus } from '../../../../../API/user';
import MyTable from '../../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { applyMaskPhone } from '../../../../utils/utils';
import SituationCell from '../../../../utils/SituationCell';

export default function ClientViewersScreen(props) {
    const { clientOnScreen } = props;

    const rightContent = useSelector(state => state.rightContent)
    const dispatch = useDispatch();
    const unoThemeClasses = unoThemeStyles();

    const [appearFab, setAppearFab] = useState(true);
    const [viewers, setViewers] = useState();

    async function updateStatusViewer(e, usarId, status) {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Atualizando situação do visualizador...'
        }));

        const response = await updateStatus(usarId, status);
        if (response.success) {

            dispatch(allActions.rightContentActions.update());

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Situação do visualizador atualizada com sucesso"
            }));

            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    };

    const fetch = useCallback(async () => {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando visualizadores...'
        }));

        const response = await getAllUsersClientByRole(clientOnScreen.id, roles.viewer.id);
        const viewersFromResponse = response.body.rows;

        if (response.success) {
            const innerLoad = {
                show: false,
                emptyMessage: undefined
            };

            if (isEmpty(viewersFromResponse)) {
                innerLoad.show = true;
                innerLoad.emptyMessage = 'Não existem visualizadores registrados';
            }

            setViewers(viewersFromResponse);
            dispatch(allActions.innerLoadActions.setInnerLoad(innerLoad));
        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    }, [dispatch]);

    useEffect(() => {
        if (clientOnScreen) {
            fetch();
        };
    }, [fetch, clientOnScreen]);

    useEffect(() => {
        if (rightContent && rightContent.action === RightDrawerActionEnum.AtualizarDados) {
            fetch();
            dispatch(allActions.rightContentActions.setRightContent(null))
        };
    }, [fetch, rightContent]);


    return (
        <PageWrapper
            fab={{
                Wrapper: ({ children }) => (
                    <Zoom
                        in={appearFab}
                        unmountOnExit>
                        {children}
                    </Zoom>
                ),
                props: {
                    className: unoThemeClasses.fabAdd,
                    onClick: () => dispatch(allActions.rightContentActions.setRightContent({
                        action: RightDrawerActionEnum.Cadastrar,
                        path: 'newClientViewer',
                        clientOnScreen
                    }))
                },
                icon: <AddIcon />
            }}
        >
            <MyTable
                onScroll={(value) => setAppearFab(value)}
                stickyHeader
                size="small"
                selectable='true'>
                <colgroup>
                    <col width="55%" />
                    <col width="20%" />
                    <col width="15%" />
                    <col width="10%" />
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="center">Contato</TableCell>
                        <TableCell align="center">Situação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {viewers?.map((row, index) => (
                        <TableRow
                            key={'table-row-submanager-client-' + row.id}
                            onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                action: RightDrawerActionEnum.Editar,
                                path: clientViewerPath,
                                data: row,
                                clientOnScreen,
                            }))}>

                            <TableCell align="left" component="th" scope="row">{row.name}</TableCell>
                            <TableCell align="left" style={{
                                wordWrap: 'break-word'
                            }}>{row.email}</TableCell>
                            <TableCell align="center" >{applyMaskPhone(row.phone_number)}</TableCell>

                            <SituationCell align="center" data={row} updateStatus={updateStatusViewer} />
                        </TableRow>
                    ))}
                </TableBody>
            </MyTable>
        </PageWrapper>
    );
}