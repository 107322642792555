import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import allActions from '../../actions'
import { unoTheme } from '../../assets/styles/unoTheme';
import { applyMaskAmount, applyMaskAccount, applyMaskAmountFreeDecimals, checkIsSameBalance, checkIsSameQuotas } from './utils'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({

    alertBalance: {
        '& .MuiAlert-message': {
            fontSize: '12px',
            padding: '0px',
            '& span': {
                verticalAlign: 'middle',
            }
        },
        '& .MuiAlert-icon': {
            padding: '0px',
        },
        '& .alertMain': {
            marginTop: '5px',
            marginBottom: '5px',
        }
    },
    bold: {
        color: 'black',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: 'blue',
    },
}));

/**
 * My main alert
 * @param {string} show 
 * @param {string} type
 * @param {string} message
 * @returns {Object} Alert
 */
export default function MyAlertSpecialClientQuota(props) {

    const classes = useStyles();
    const difBalances = 0.15;
    //const difBalances = 500000;

    const {
        row,
        action,
        role
    } = props

    function renderAccount(row) {
        return (
            <span><span className={classes.bold}>Conta: </span><span className={classes.bold}>{applyMaskAccount(row.number_account)} {row.agency ? "/" + row.agency : ""}</span></span>
        )
    }

    return (

        <div className={classes.alertBalance}>


            <Alert className={'alertMain'}
                variant="outlined"
                severity="info"
                action={

                    (role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN" || role == 'ROLE_ADVISOR') ?

                        <Button color={"inherit"}
                            size="small"
                            onClick={() => action(row.balance_now_id)}>
                            {'ATUALIZAR COTA'}
                        </Button>

                        : null
                }>

                <span><span className={classes.bold}>Cota do cliente não informada</span></span>
                <br />
                <span>Considere atualizar cota específica do mês para esse ativo</span>

            </Alert>

        </div >
    );
}