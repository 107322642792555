import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../../utils/utils'
import { makeStyles } from '@material-ui/core/styles';
import { BarChart } from '../../utils/Charts';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExcelReader from "../../utils/ExcelReader";
import exportFromJSON from 'export-from-json'
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

export default function ImportExcel() {

    const classes = useStyles();

    useEffect(() => {

    })

    const data = [
        {
            foo: 'foo1',
            bar: 'bar1'
        },
        {
            foo: 'foo2',
            bar: 'bar2'
        }]
    const fileName = 'download'
    const exportType = 'xls'


    function exportToExcel() {
        exportFromJSON({ data, fileName, exportType })
    }


    return (
        <div className={classes.root}>
            <Typography variant="h4" gutterBottom align="left">
                Exportar dados para Excel
            </Typography>
            <Divider />
            <br />
            <Button
                onClick={() => exportToExcel()}
                variant="contained"
                color={'primary'}>
                Exportar para excel
            </Button>
            <br />
            <Typography variant="h4" gutterBottom align="left">
                Importar dados do Excel
            </Typography>
            <Divider />
            <br />
            <ExcelReader />

        </div>
    );
}



