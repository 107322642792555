import React, { useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";
import InputBase from '@material-ui/core/InputBase';

//RadioGroup
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'

    },
    disabled: {
        background: '#eeeeee'
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    noMarginTop: {
        marginTop: '5px'
    }


}));

export default function MyRadioGroup(props) {

    const {
        outerlabel,
        options
    } = props

    const classes = useStyles();

    return (
        <div className={classes.root}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={classes.label}>
                        {outerlabel}
                    </Typography> : null
            }

            <RadioGroup
                {...props}>
                {options}
            </RadioGroup>

        </div>


    )
}