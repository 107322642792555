import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getArrayYears, applyMaskAmount, applyMaskAccount, getMonthName } from '../utils/utils';
import UnoButton from '../utils/UnoButton';

//Table
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function InconsistentBalancesModal({ inconsistentBalancesValidations, open, closeModal }) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    function formatFundsToShow(invalidsFunds) {
        let formatted = "";
        invalidsFunds.forEach(element => {
            formatted += element.fund_name + "[" + element.cnpj + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    useEffect(() => {

        console.log("DATA: ", inconsistentBalancesValidations);
    }, [inconsistentBalancesValidations])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={closeModal}>
            <DialogTitle
                className={classes.dialogTitleApr}
                id="dialog-title-apr">
                {
                    <><WarningIcon className={[classes.spacingRight2, classes.warningColor].join(' ')} />Aviso</>
                }


            </DialogTitle>


            <DialogContent
                className={classes.dialogContent}>

                {
                    inconsistentBalancesValidations
                        && inconsistentBalancesValidations.length > 0 ?
                        <>
                            <Typography className={classes.afirmativeText} variant='body1'>
                                {'Os saldos de ativos que não começam junto do início da carteira devem ser 0 (zero) no seu mês de início'}
                            </Typography>
                            <br />
                            <TableContainer component={Paper} elevation={0}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.noPaddingLeft} align="left">Fundo</TableCell>
                                            <TableCell align="center">CC</TableCell>
                                            <TableCell align="center">Início</TableCell>
                                            <TableCell align="right">Saldo</TableCell>
                                            <TableCell className={classes.noPaddingRight} align="right">Qtd. Cotas</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inconsistentBalancesValidations.map((row, index) => (

                                            <TableRow
                                                className={classes.toCreateRow}
                                                key={'account-to-create-' + index}>
                                                <TableCell align="left" className={classes.noPaddingLeft}>{row.fund_name}</TableCell>
                                                <TableCell align="center">{row.number_account}</TableCell>
                                                <TableCell align="center">{row.asset_init ? moment.utc(row.asset_init).format("MM/YYYY") : "-"}</TableCell>
                                                <TableCell align="right" className={classes.green}>{applyMaskAmount(row.balance, true)}</TableCell>
                                                <TableCell align="right" className={[classes.blue, classes.noPaddingRight].join(' ')}>{applyMaskAmount(row.quota_amount, true)}</TableCell>
                                            </TableRow>

                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </> : null

                }

            </DialogContent>




            <DialogActions className={classes.actionsFlexRight}>

                <UnoButton
                    float="right"
                    onClick={closeModal} color="secondary">
                    {'Fechar'}
                </UnoButton>
            </DialogActions>




        </Dialog >
    );
}