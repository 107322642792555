import React, { useMemo, useState } from 'react';
import { Grid } from "@material-ui/core";
import { Footer, FooterButtonsWrapper, FormWrapper, Wrapper, useStyles } from "./styles";
import MyTextField from '../../../../utils/MyTextField';
import { RightDrawerActionEnum } from '../../../RightContent';
import { applyMaskPhone, getConsultingId, isValidCpf, isValidEmail, justNumbers } from '../../../../utils/utils';
import { Loading, SpaceFiller } from './Header/styles';
import { roles } from '../../../../utils/user';
import { create, deleteUser, updateUser } from '../../../../../API/user';
import allActions from '../../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import UnoButton from '../../../../utils/UnoButton';
import ConfirmActionRightContent from '../../ConfirmActionRightContent';
import Header from './Header';

/**
 * 
 * @param {Omit<import('./index').Content, 'path' | 'data'> & {
 *  viewer: import('./index').Content['data'];
 * }} props 
 * @returns 
 */
export default function Form(props) {
    const { action, clientOnScreen, viewer } = props;

    const isUpdate = useMemo(() => action === RightDrawerActionEnum.Editar, [action]);
    const isCreate = useMemo(() => action === RightDrawerActionEnum.Cadastrar, [action]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);

    const [confirmingAction, setConfirmingAction] = useState(false);

    function handleCancel() {
        setConfirmingAction(false);
    };

    async function deleteViewer() {
        setLoading(true)

        const responseAccount = await deleteUser(viewer.id);

        if (responseAccount.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Visualizador excluído com sucesso!'
            }));
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir visualizador: " + responseAccount.body.message
            }));
        }

        setLoading(false);
        dispatch(allActions.rightContentActions.update());

    };

    const [loading, setLoading] = useState(false);

    const [formState, setFormState] = useState({
        data: {
            name: viewer?.name ?? '',
            email: viewer?.email ?? '',
            phone: viewer?.phone_number ? applyMaskPhone(viewer?.phone_number) : '',
            cpf: viewer?.cpf ?? '',
            password: ''
        },
        isSubmitted: false
    });

    const username = useMemo(() => formState.data.name, [formState]);
    const usernameValidator = useMemo(() => username === "" && formState.isSubmitted ? true : undefined, [username, formState]);

    const email = useMemo(() => formState.data.email, [formState]);
    const emailValidator = useMemo(() => !isValidEmail(email) && formState.isSubmitted ? true : undefined, [email, formState]);

    const phone = useMemo(() => formState.data.phone, [formState]);

    const cpf = useMemo(() => formState.data.cpf, [formState]);
    const cpfValidator = useMemo(() => !isValidCpf(cpf) && formState.isSubmitted ? true : undefined, [cpf, formState]);

    const password = useMemo(() => formState.data.password, [formState]);
    const [passwordReseted, setPasswordReseted] = useState(false);
    const passwordDisabled = useMemo(() => isUpdate && !passwordReseted, [isUpdate, passwordReseted]);
    const passwordValidator = useMemo(() => password.length < 6
        && formState.isSubmitted ? true : undefined, [password, formState]);
    const passwordPlaceholder = useMemo(() => passwordDisabled ? "Clique para nova senha" : "Insira a senha", [passwordDisabled]);
    const passwordClassName = useMemo(() => passwordDisabled ? classes.selectable : '', [passwordDisabled, classes]);

    /**
     * 
     * @param {'name' | 'password' | 'phone' | 'email' | 'cpf'} fieldName 
     * @param {*} value 
     */
    const handleChange = (fieldName, value) => {
        setFormState({
            ...formState,
            data: {
                ...formState.data,
                [fieldName]: value
            },
        });
    };

    /**
     * 
     * @param {boolean} isSubmitted indica o estado de submissão do formulário.
     */
    const flagFormSubmission = (isSubmitted) => {
        setFormState({
            ...formState,
            isSubmitted
        })
    }

    async function handleFormSubmission(event) {

        event.preventDefault();
        flagFormSubmission(true);

        setLoading(true);

        const newPassword = isUpdate ? (passwordReseted ? password : null) : password;

        let user = {
            cpf,
            password: newPassword,
            name: username,
            email,
            phone_number: justNumbers(phone),
            role: roles.viewer.id,
            client_id: clientOnScreen.id,
            consulting_id: getConsultingId(currentUser)
        }

        if (isUpdate) {

            if (isValidCpf(justNumbers(user.cpf))
                &&
                (
                    (passwordReseted && user.password !== "" && user.password.length >= 6)
                    ||
                    (!passwordReseted)
                )
                && user.name !== ""
                && isValidEmail(user.email)) {

                flagFormSubmission(false);

                let response = await updateUser(viewer.id, user);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Visualizador atualizado com sucesso"

                    }));
                    dispatch(allActions.rightContentActions.update());
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: 'Erro ao atualizar visualizador'
                    }));
                }
            }

        } else {

            if (isValidCpf(justNumbers(user.cpf))
                && user.password != ""
                && user.password.length >= 6
                && user.name != ""
                && isValidEmail(user.email)) {

                flagFormSubmission(false);

                let response = await create(user);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Visualizador cadastrado com sucesso"

                    }));

                    dispatch(allActions.rightContentActions.update());

                } else {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }));

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: 'Erro ao cadastrar visualizador'
                    }));
                }

            }
        }

        setLoading(false);
    };

    return (
        <Wrapper>
            {!confirmingAction ?
                <>
                    <Header action={action} labelName={clientOnScreen.label_name} />
                    <FormWrapper>
                        <Grid item xs={12} sm={12} md={12}>
                            <MyTextField required
                                value={username}
                                outerlabel="Nome"
                                size={'small'}
                                validator={usernameValidator}
                                variant="outlined"
                                onChange={(value) => handleChange('name', value)}
                                helperText="Insira um nome"
                                placeholder='Insira o nome'
                            />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7}>
                            <MyTextField
                                value={email}
                                variant="outlined"
                                size='small'
                                onChange={(value) => handleChange('email', value)}
                                validator={emailValidator}
                                helperText="Insira um email válido"
                                placeholder='Insira o email'
                                outerlabel="Email" />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <MyTextField
                                value={phone}
                                size={'small'}
                                onChange={(value) => handleChange('phone', value)}
                                mask={['phone']}
                                variant="outlined"
                                placeholder='Insira o telefone'
                                outerlabel="Telefone"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <MyTextField
                                value={cpf}
                                onChange={(value) => handleChange('cpf', value)}
                                size={'small'}
                                variant="outlined"
                                validator={cpfValidator}
                                helperText="Insira um CPF válido"
                                mask={['cpf']}
                                placeholder='Insira o CPF'
                                outerlabel="CPF" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <MyTextField
                                value={password}
                                type="password"
                                className={passwordClassName}
                                outerlabel="Senha"
                                onChange={(value) => handleChange('password', value)}
                                size={'small'}
                                variant="outlined"
                                onClick={() => setPasswordReseted(true)}
                                placeholder={passwordPlaceholder}
                                validator={passwordValidator}
                                helperText="Insira uma senha de 6 dígitos"
                            />
                        </Grid>
                    </FormWrapper>
                    <Footer>
                        <FooterButtonsWrapper>
                            {
                                isCreate ? <SpaceFiller /> :
                                    <UnoButton
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        Excluir visualizador
                                    </UnoButton>
                            }


                            <UnoButton
                                onClick={(e) => handleFormSubmission(e)}
                                type={isUpdate ? 'success' : 'primary'}>
                                {isUpdate ? `Atualizar visualizador` : `Salvar visualizador`}
                            </UnoButton>
                        </FooterButtonsWrapper>
                    </Footer>
                </>
                :
                <ConfirmActionRightContent
                    message={`Tem certeza que quer excluir 
                o usuário ${viewer?.name ?? ''}?`}
                    handleCancel={handleCancel}
                    handleConfirm={deleteViewer}
                />}
            {loading && <Loading />}
        </Wrapper>
    );
}