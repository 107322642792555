import API from './config'

export async function saveLocalQuotas(obj) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/saveLocalQuotas', obj, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateLocalJsonQuotas(obj) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/updateLocalJsonQuotas', obj, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateInfosLocalQuotas(fundLocalQuotaId, infos) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/fund_local_quotas/updateInfosLocalQuotas', {
            fund_local_quota_id: fundLocalQuotaId,
            infos: infos,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateInfosLocalQuotasByFundId(fund_id, infos, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/fund_local_quotas/updateInfosLocalQuotasByFundId', {
            fund_id: fund_id,
            infos: infos,
            month: month,
            year: year,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalQuotaByFundId(fundId, date) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalQuotaByFundId', {
            fund_id: fundId,
            date: date,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalFundInfoById(fundLocalQuotaiId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/fund_local_quotas/getLocalFundInfoById/' + fundLocalQuotaiId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalFundInfoByCnpj(cnpj, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/fund_local_quotas/getLocalFundInfoByCnpj', {
            cnpj, month, year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllLocalQuotasByFundId(fundId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/fund_local_quotas/getAllLocalQuotasByFundId/' + fundId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllLocalQuotasByFundIdMonthAndYear(fundId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getAllLocalQuotasByFundIdMonthAndYear',
            {
                fund_id: fundId,
                month: month,
                year: year
            }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalQuotasByFundMonthYear(fundId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalQuotasByFundMonthYear', { fund_id: fundId, month: month, year: year }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalInfosByFundIdMonthYear(fundId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalInfosByFundIdMonthYear', { fund_id: fundId, month: month, year: year }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalInfosByArrayFundsIds(arrayFundsIds, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalInfosByArrayFundsIds', {
            array_funds_ids: arrayFundsIds,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Usando como referência o fundo 11.328.882/0001-35 BB IRF-M 1 TP FIC RF PREVID
export async function getLastDayQuotaInMonth(month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLastDayQuotaInMonth', { month: month, year: year }, config)
        console.log("RESPONSE: ", response);
        return {
            success: true,
            body: response.data.lastDay
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalQuotasByFundsIdsAndYear(arrayFundsIds, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalQuotasByFundsIdsAndYear', {
            funds_ids: arrayFundsIds,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getLocalQuotasByFundIdAndPeriods(fundId, periods) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/fund_local_quotas/getLocalQuotasByFundIdAndPeriods', {
            fund_id: fundId,
            periods: periods
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}
