import { IconButton, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from "react-redux";
import allActions from "../../../actions";

export default function MyTooltip({ className, iconSize, hasAlert = false, alertMessage, tooltipTitle }) {
  const dispatch = useDispatch();

  return (
    <Tooltip className={className} arrow title={tooltipTitle}>
      <IconButton onClick={() => {
        if (hasAlert) {
          dispatch(allActions.mainAlertActions.setMainAlert({
            show: true,
            type: 'info',
            message: alertMessage
          }));
        }
      }
      }>
        <HelpOutline fontSize={iconSize} />
      </IconButton>
    </Tooltip>
  )
};