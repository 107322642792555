import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//Tables
import MyTable from '../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//redux
import MyDatePicker from '../utils/MyDatePicker'

//Utils
import { portfolioClosedToDate, applyMaskAccount, applyMaskAmountFreeDecimals, applyMaskAmount, isDateBeforePortfolioClosed } from '../utils/utils'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio, resetAccounts, resetAssets, resetTransactions } from '../../API/client'
import { getTransactionsByDateRangeWithAPR, getClientAllTransactionByDateRange } from '../../API/transaction'

//Controllers
import { saveLogResetAssetsAndAccounts } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    labelTopModal: {
        float: 'right',
        fontSize: '14px',
        color: unoTheme.defaultTextColor
    }


}));

export default function UploadTransactionsModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const { open,
        transactionsToUpload, handleAction } = props;

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");


    useEffect(() => {

        console.log("transactionsToUpload: ", transactionsToUpload);

    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => handleAction(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Upload de Movimentações"}

            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {/* Movimentações a serem subidas */}

                {transactionsToUpload && transactionsToUpload.length ?

                    <MyTable
                        style={{ marginTop: '8px' }}
                        stickyHeader
                        sizeheaders="verySmall"
                        size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableTh} align="center">Data</TableCell>
                                <TableCell className={classes.tableTh} align="left">Fundo</TableCell>
                                <TableCell className={classes.tableTh} align="center">Operação</TableCell>
                                <TableCell className={classes.tableTh} align="center">Conta</TableCell>
                                <TableCell className={classes.tableTh} align="right">Valor</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                //content.data.transactions.map((row, index) => (
                                transactionsToUpload && transactionsToUpload.map((row, index) => (


                                    <TableRow className={classes.detailRow}
                                        key={'detail-single-transaction-' + index}>

                                        <TableCell align="center" className={classes.selectableInfo}>
                                            {row.transaction_date}
                                        </TableCell>

                                        <TableCell align="left" className={classes.selectableInfo}>
                                            {row.fund_name}
                                        </TableCell>

                                        <TableCell align="center" className={
                                            row.operation == 'APLICAÇÃO' ? classes.green : (
                                                row.operation == 'RESGATE' ? classes.red : classes.blue
                                            )
                                        }>
                                            {row.operation}
                                        </TableCell>

                                        <TableCell align="center" >
                                            {row.number_account ? applyMaskAccount(row.number_account) : '-'}
                                        </TableCell>

                                        <TableCell align="right" className={
                                            row.operation == 'APLICAÇÃO' ? classes.green : (
                                                row.operation == 'RESGATE' ? classes.red : classes.blue
                                            )
                                        }>
                                            {row && row.amount ? applyMaskAmount(row.amount, true) : null}
                                        </TableCell>

                                    </TableRow>



                                ))

                            }
                        </TableBody>

                    </MyTable> : <Typography>Não foram encontradas novas APRs a serem cadastradas</Typography>
                }






            </DialogContent>

            <DialogActions className={classes.actionsFlex}>

                <UnoButton
                    onClick={() => handleAction(false)}>
                    {'Cancelar'}
                </UnoButton>

                {transactionsToUpload && transactionsToUpload.length ?
                    <UnoButton
                        onClick={() => handleAction(true)}
                        className={classes.spaceRight2}
                        color="primary">
                        {'Confirmar'}
                    </UnoButton> : null}

            </DialogActions>




        </Dialog >
    );
}