import moment from 'moment';
import { BalancesValidationsAPI } from '../API/balances_validations';
import {
    getActuarysInfos,
    getAllActivesClientsIdsAndNamesByConsultingId,
    getAllMaxQuotasClientsByMonthAndYearAndConsultingId,
    getAllRentsClientsByPeriod,
    getBalancesPortfolioMonthByPeriodAndRegime,
    getBalancesRentsByRange, getClientDiaryPlsByRange, getClientLastQuota, getClientPortfolioRentsByLimit,
    getClientsAndLaunchs,
    getClientsPolicys,
    getClientsTargets,
    getStatusDairClientsByYear
} from '../API/client';
import { getLastDayQuotaInMonth } from '../API/fund_local_quotas';
import { allClientsSumTransactionsByPeriodAndConsultingId } from '../API/transaction';
import { unoTheme } from '../assets/styles/unoTheme';
import { INVALID_DATE_STRING, applyMaskAmount, formatAnbimaDateToPt, formatDataToPieChart, getDayMonthYearByPtDate, getDayMonthYearByStringDate, getFirstDateDayMonth, getFirstDayYear, getFirstPtDayMonth, getLastDayMonth, getLastPtDayMonth, getMonthAgo, getMonthAndYearPortfolioClosed, getTheLastDayCompleteMonthPt } from '../components/utils/utils';
import { doGetDisponibilityAccountsByPeriod } from '../controllers/AccountController';


//export const NO_RENT_TO_COMPARE = -9999999;
export const NO_RENT_TO_COMPARE = "NO_RENT_TO_COMPARE";

export function getColorSuitability(suitability) {
    switch (suitability) {
        case 'CONSERVADOR':
            return unoTheme.suitabilityColorConservador;
        case 'MODERADO':
            return unoTheme.suitabilityColorModerado;
        case 'AGRESSIVO':
            return unoTheme.suitabilityColorAgressivo;
    }
}

export const STATUS_DAIR = {
    launched: "launched",
    unlaunched: "unlaunched",
    pendent: "pendent",
    unneeded: "unneeded",
}

export const STATUS_DPIN = {
    sent: "sent",
    not_sent: "not_sent",
    unneeded: "",
}

/**
 * Retorna o próximo mês a ser lançado do cliente
 * @param {Object} client 
 * @returns {Object} 
 * Ex: '{
 *                          nextMonth: 1,
                            nextYear: 2020,
                            formatted: '1/2020'
 *                        }'
 */
export function getNextMonthToLaunch(client) {

    let splittedClosedMonth = null
    let nextMonth = 0
    let nextYear = 0

    if (client.portfolio_closed) {
        //No caso de já haver uma carteira fechada
        splittedClosedMonth = client.portfolio_closed.split("/")
    } else {

        //Ainda não havia carteira fechada
        splittedClosedMonth = moment.utc(client.portfolio_init).format("MM/YYYY").split("/")
    }

    nextMonth = parseInt(splittedClosedMonth[0]) + 1
    nextYear = parseInt(splittedClosedMonth[1])

    //No caso do mês ser 12
    if (nextMonth == 13) {
        nextMonth = 1;
        nextYear++;
    }

    return {
        nextMonth: nextMonth,
        nextYear: nextYear,
        formatted: nextMonth + '/' + nextYear
    };
};

/**
 * Retorna o id da conta (id o banco) a partir do número da conta
 * @param {Array} assets lista de ativos 
 * @param {int} clientId id do cliente 
 * @param {Date} date dia de referência 
 * @param {int} month mês de referência 
 * @param {int} year ano de referência 
 * @returns {Object} retorna o objeto clientRent a ser salvo em client_rents
 */
export function formatFirstRentToSave(clientId, assets, date, month, year) {

    let clientRent = {
        client_id: clientId,
        pl: 0,
        date_quota: date,
        quota_value: 1,
        month: month,
        result_app_rescues: 0,
        year: year,
    };

    console.log("ASSET FRIST: ", assets);
    assets.forEach(assetLoop => {

        //Somando os pls dos ativos        
        clientRent.result_app_rescues += assetLoop.balance_now ? parseFloat(assetLoop.balance_now) : 0;

    });

    clientRent.qtd_quotas = clientRent.result_app_rescues;
    clientRent.add_quotas = clientRent.result_app_rescues;
    clientRent.new_pl = clientRent.result_app_rescues;
    console.log("CLIENT RETN RETURN: ", clientRent);

    return clientRent;
}



/**
 * Retorna a lista de saldos em ativos dos clients por mês no período
 * @returns {Array}
 */
export async function doGetBalancesAssetsByPeriod(clientId, regimeId, startDate, endDate) {

    console.log("Start date: ", startDate);
    console.log("End date: ", endDate);

    let periodToRemove = null;
    if (startDate) {
        const { month, year } = getDayMonthYearByStringDate(startDate);
        startDate = getMonthAgo(month, year, 2);
        console.log("New Start date metas: ", startDate);
        //Removendo inicio de carteira do gráfico de patrimonio
        const splittedNewStartDate = startDate.split('/');
        periodToRemove = parseInt(splittedNewStartDate[1]) + '/' + splittedNewStartDate[2];
        console.log('periodToRemove: ', periodToRemove);
    }


    const responseBalances = await getBalancesPortfolioMonthByPeriodAndRegime(clientId, regimeId, startDate, endDate);
    console.log("RESPONSE BALANCES: ", responseBalances)

    const mapBalancesAssets = {};
    //somando saldos de fundos e títulos nos períodos
    if (responseBalances.success) {
        const { fundsBalances, titlesBalances } = responseBalances.body

        fundsBalances.forEach(element => {
            if (!mapBalancesAssets[element.period]) {
                mapBalancesAssets[element.period] = 0;
            }

            mapBalancesAssets[element.period] += element.balance;
        });

        titlesBalances.forEach(element => {
            if (!mapBalancesAssets[element.period]) {
                mapBalancesAssets[element.period] = 0;
            }

            mapBalancesAssets[element.period] += element.balance;
        });
    }

    return {
        mapBalancesAssets: mapBalancesAssets,
        periodToRemove: periodToRemove
    }
}

/**
 * Retorna a lista de pls diários do cliente 
 * @param {Number} clientId id do cliente 
 * @param {Date} month 
 * @param {Date} year 
 * @param {String} period [ano, 12, 24, 36, null] no caso de null traz todos os registros
 * @returns {Array}
 */
export async function loadClientDiaryByRange(clientId, month, year, period) {

    let startDate = null;
    console.log("PERIOD: ", period);

    if (period == 'ano') {

        startDate = getFirstDayYear(year);

    } else if (period != null) {

        startDate = getMonthAgo(
            month,
            year,
            period);

    } else {

        //Trazer pls diários desde o inciio
        startDate = null;
    }

    //Se a data de início for anterior a data de inicio do portfolio adapta-se para a data de inicio do portfolio 

    let endDate = getLastDayMonth(month,
        year);

    console.log('DIARYS TESTE');
    console.log('startDate diarys: ', startDate);
    console.log("moment.utc(endDate).format('DD/MM/YYYY'): ", moment.utc(endDate).format('DD/MM/YYYY'));
    let responseDiarys = await getClientDiaryPlsByRange(clientId, startDate, moment.utc(endDate).format('DD/MM/YYYY'));

    console.log("RESPONSE DIARYS: ", responseDiarys);


    let responseBalancesInAccounts = await doGetDisponibilityAccountsByPeriod(clientId, null, startDate, moment.utc(endDate).format('DD/MM/YYYY'));
    let responseBalancesInAssets = await doGetBalancesAssetsByPeriod(clientId, null, startDate, moment.utc(endDate).format('DD/MM/YYYY'));

    console.log('responseBalancesInAccounts: ', responseBalancesInAccounts);
    console.log('responseBalancesInAssets: ', responseBalancesInAssets);

    let monthAgo = month - 1;
    let yearAgo = year;

    if (monthAgo == 0) {

        monthAgo = 12;
        yearAgo--;

    }

    let diarys = responseDiarys.body;

    //Obtendo mês e ano da data inicial
    //No caso do exemplo startDate: 01/03/2019 e endDate 29/02/2020
    //Deve-se obter a última quota de 02/2019, para cálculo da rentabilidade acumulada do período    

    const initQuota = {
        quota_value: 1,
    };
    let lastQuota = null; //A última quota anterior ao período
    console.log('startDate LAST QUOTA: ', startDate);
    if (startDate) { //Caso seja null não entra no getLastQuota
        let spliitedStartDate = startDate.split('/');
        let lastQuotaMonth = spliitedStartDate[1];
        let lastQuotaYear = spliitedStartDate[2];

        //Obtendo a quota do último dia do mês anterior ao período requisitado    
        console.log("REQUISITANDO LAST QUOTA: " + lastQuotaMonth + "/" + lastQuotaYear);
        let responselastQuota = await getClientLastQuota(clientId, lastQuotaMonth, lastQuotaYear);
        console.log("LAST QUOTA: ", responselastQuota);

        if (responselastQuota.success) {

            if (responselastQuota.body && responselastQuota.body.message && responselastQuota.body.message == "HAS_COTAS") {
                //o caso em que se traz o mês inicial da carteira
                //portanto a cota inicial é 1
                lastQuota = initQuota;
            } else {
                lastQuota = responselastQuota.body[0];
            }


        } else {
            lastQuota = diarys[diarys.length - 1];
        }

    } else {

        //lastQuota = diarys[0]; //Caso a startDate seja null pega-se a primeira cota registrada que está no inicio do array de diarios
        lastQuota = initQuota;

    }

    console.log("LAST QUOTA: ", lastQuota);

    //Objeto que guardará as rentabilidades do período;
    let rentMonths = {};
    let lastDiaryBeforeMonth = lastQuota;

    diarys.forEach(diary => {

        const key = diary.month + "/" + diary.year;
        if (!rentMonths[key]) {

            rentMonths[key] = {
                lastDiaryBeforeMonth: lastDiaryBeforeMonth,
                diarys: [],
            }

        }

        diary.pl_before = lastDiaryBeforeMonth.new_pl;
        lastDiaryBeforeMonth = diary;
        rentMonths[key].diarys.push(diary);

    });

    console.log('rentMonths in loaddiary: ', rentMonths);

    //Calculando as rentabilidades    
    let arrayRents = [];
    Object.entries(rentMonths).map(([key, row], index) => {

        if (row.lastDiaryBeforeMonth
            && row.lastDiaryBeforeMonth.quota_value
            && lastQuota) {

            row.rent = ((row.diarys[row.diarys.length - 1].quota_value / row.lastDiaryBeforeMonth.quota_value) - 1) * 100;
            row.rentAcum = ((row.diarys[row.diarys.length - 1].quota_value / lastQuota.quota_value) - 1) * 100;

            const lastYear = parseInt(key.split('/')[1]) - 1;
            //console.log('lastYear: ', lastYear);
            //console.log('lastYear: ', lastYear);
            const lastRentBeforeYear = rentMonths['12/' + lastYear];
            // console.log('RENT ACUM YEAR: ', lastRentBeforeYear);
            // console.log('lastRentBeforeYear: ', lastRentBeforeYear);
            // console.log('lastQuota: ', lastQuota);
            const lastQuotaInBeforeYear = lastRentBeforeYear ? lastRentBeforeYear.diarys[lastRentBeforeYear.diarys.length - 1].quota_value : lastQuota.quota_value;
            // console.log('QUOTA NOW: ', row.diarys[row.diarys.length - 1]);
            // console.log('QUOTA NOW[' + row.diarys[row.diarys.length - 1].date_quota + ']: ', row.diarys[row.diarys.length - 1].quota_value);
            // console.log('lastQuotaInBeforeYear: ', lastQuotaInBeforeYear);
            row.rentAcumYear = ((row.diarys[row.diarys.length - 1].quota_value / parseFloat(lastQuotaInBeforeYear)) - 1) * 100;
            //console.log('row.rentAcumYear: ', row.rentAcumYear);

            arrayRents.push({
                ...row,
                monthYear: key,
            });
        }

    })


    const isClientDiaryDataLoadingSuccess = arrayRents && arrayRents.length >= 1 || false;
    const defaultReturns = {
        rentMonths,
        arrayRents,

    }

    if (isClientDiaryDataLoadingSuccess) {
        return {
            ...defaultReturns,
            //Adicionando saldos das disponibilidades
            mapAccountsBalances: responseBalancesInAccounts.success ? responseBalancesInAccounts.body : null,
            mapAssetsBalances: responseBalancesInAssets.mapBalancesAssets,
            periodToRemove: responseBalancesInAssets.periodToRemove,
            lastQuotaDate: moment.utc(lastQuota.date_quota),
            isSuccessful: true
        }
    }

    return {
        ...defaultReturns,
        isSuccessful: false,
        error: responseDiarys.body
    };
}


export async function doGetAllRentsClients(period, month, year) {

    let prevStartDate = null;

    if (period == 'ano') {

        prevStartDate = getFirstDayYear(year);

    } else if (period != null) {

        prevStartDate = getMonthAgo(
            month,
            year,
            period);

    } else {

        //Trazer pls diários desde o inciio
        prevStartDate = null;
    }

    //Se a data de início for anterior a data de inicio do portfolio adapta-se para a data de inicio do portfolio 
    const { month: monthPrevStartDate, year: yearPrevStartDate } = getDayMonthYearByPtDate(prevStartDate);
    const startMonth = getMonthAgo(monthPrevStartDate, yearPrevStartDate, 2);
    const { month: monthStartMonth, year: yearStartDate } = getDayMonthYearByPtDate(startMonth);

    //Data do inicio do período para cálculo da rentabilidade acumulada
    const startDate = moment.utc(getLastDayMonth(monthStartMonth, yearStartDate)).format('DD/MM/YYYY');
    //Data do fim do período para cálculo da rentabilidade acumulada e rentabildiade do mês atual
    const endDate = moment.utc(getLastDayMonth(month, year)).format('DD/MM/YYYY');
    //Data do mês anterior para cálculo da rantabilidade do mês atual
    const agoEndDate = getMonthAgo(month, year, 2);
    const { month: monthAgoEndDate, year: yearAgoEndDate } = getDayMonthYearByPtDate(agoEndDate);
    const monthBeforeDate = moment.utc(getLastDayMonth(monthAgoEndDate, yearAgoEndDate)).format('DD/MM/YYYY');

    // console.log('startDate: ', startDate);
    // console.log('endDate: ', endDate);
    // console.log('monthBeforeDate: ', monthBeforeDate);

    const responseStartDateLastDayQuota = await getLastDayQuotaInMonth(monthStartMonth, yearStartDate);
    const responseEndDateLastDayQuota = await getLastDayQuotaInMonth(month, year);
    const responseAgoEndDateLastDayQuota = await getLastDayQuotaInMonth(monthAgoEndDate, yearAgoEndDate);

    const startDateLastDayQuota = formatAnbimaDateToPt(responseStartDateLastDayQuota.body);
    const endDateLastDayQuota = formatAnbimaDateToPt(responseEndDateLastDayQuota.body);
    const agoEndDateLastDayQuota = formatAnbimaDateToPt(responseAgoEndDateLastDayQuota.body);

    // console.log("Real dates quotas: ");
    console.log(startDateLastDayQuota);
    console.log(endDateLastDayQuota);
    console.log(agoEndDateLastDayQuota);

    const response = await getAllRentsClientsByPeriod(startDateLastDayQuota, endDateLastDayQuota, agoEndDateLastDayQuota)

    if (response.success) {

        //formatando resposta, obtendo data inicial e data final
        const clients = {};
        response.body.forEach(clientRent => {

            if (clientRent.client_id == 224) {
                console.log("FORTALEZA");
                console.log("date_quota: ", clientRent);
                console.log("date_quota: ", clientRent.date_quota);
                console.log("date_quota: ", moment.utc(clientRent.date_quota).format('DD/MM/YYYY'));
                console.log("endDateLastDayQuota: ", endDateLastDayQuota);
            }

            const startDateRent = moment.utc(clientRent.date_quota).format('DD/MM/YYYY') == startDateLastDayQuota ? moment.utc(clientRent.date_quota).format('DD/MM/YYYY') : null
            const endDateRent = moment.utc(clientRent.date_quota).format('DD/MM/YYYY') == endDateLastDayQuota ? moment.utc(clientRent.date_quota).format('DD/MM/YYYY') : null
            const monthBeforeDateRent = moment.utc(clientRent.date_quota).format('DD/MM/YYYY') == agoEndDateLastDayQuota ? moment.utc(clientRent.date_quota).format('DD/MM/YYYY') : null

            if (!clients[clientRent.client_id]) {
                clients[clientRent.client_id] = {
                    id: clientRent.client_id,
                    label_name: clientRent.label_name,
                }
            }

            if (startDateRent) {
                clients[clientRent.client_id].startDate = startDateRent;
                clients[clientRent.client_id].startQuotaValue = clientRent.quota_value;
            }

            if (endDateRent) {
                clients[clientRent.client_id].endDate = endDateRent;
                clients[clientRent.client_id].endQuotaValue = clientRent.quota_value;
            }

            if (monthBeforeDateRent) {
                clients[clientRent.client_id].monthBeforeDate = monthBeforeDateRent;
                clients[clientRent.client_id].monthBeforeQuotaValue = clientRent.quota_value;
            }

        });

        const clientsArray = [];

        Object.entries(clients).map(([key, row]) => {
            if (row.startDate && row.endDate) {
                row.rentAcum = ((parseFloat(row.endQuotaValue) / parseFloat(row.startQuotaValue)) - 1) * 100;
            }

            if (row.monthBeforeDate && row.endDate) {
                row.rentMonth = ((parseFloat(row.endQuotaValue) / parseFloat(row.monthBeforeQuotaValue)) - 1) * 100;
            }

            if (!row.rentAcum) {
                row.rentAcum = NO_RENT_TO_COMPARE;
            }
            if (!row.rentMonth) {
                row.rentMonth = NO_RENT_TO_COMPARE;
            }
            clientsArray.push(row);
        });

        return {
            success: true,
            clients: clientsArray
        }
    } else {
        return {
            success: false,
        }
    }

}

export async function doGetAllRentsClients2(period, month, year, consultingId) {

    let startDate = null;

    if (period == 'ano') {

        startDate = getFirstDayYear(year);
        const { month: m, year: y } = getDayMonthYearByPtDate(startDate);
        startDate = getMonthAgo(
            m,
            y,
            2);

    } else if (period != null) {

        startDate = getMonthAgo(
            month,
            year,
            period);
    }

    const endDate = getLastPtDayMonth(month, year);

    let prevEndDateMonth = month - 1;
    let prevEndDateYear = year;
    if (prevEndDateMonth === 0) {
        prevEndDateMonth = 12;
        prevEndDateYear--;
    }

    const prevEndDate = getLastPtDayMonth(prevEndDateMonth, prevEndDateYear);
    console.log("startDate: ", startDate);
    console.log("prevEndDate: ", prevEndDate);
    console.log("endDate: ", endDate);

    const clientsMap = {};

    const responseAllClientsIds = await getAllActivesClientsIdsAndNamesByConsultingId(consultingId);

    if (responseAllClientsIds?.success) {
        console.log('responseAllClientsIds: ', responseAllClientsIds);

        const { month: startDateMonth, year: startDateYear } = getDayMonthYearByPtDate(startDate);
        const responseStartDateQuotas = await getAllMaxQuotasClientsByMonthAndYearAndConsultingId(startDateMonth, startDateYear, consultingId);

        console.log('responseStartDateQuotas: ', responseStartDateQuotas);

        const responsePrevEndDateQuotas = await getAllMaxQuotasClientsByMonthAndYearAndConsultingId(prevEndDateMonth, prevEndDateYear, consultingId);
        console.log('responsePrevEndDateQuotas: ', responsePrevEndDateQuotas);

        const { month: endDateMonth, year: endDateYear } = getDayMonthYearByPtDate(endDate);
        const responseEndDateQuotas = await getAllMaxQuotasClientsByMonthAndYearAndConsultingId(endDateMonth, endDateYear, consultingId);

        responseAllClientsIds.body.forEach((el) => {
            clientsMap[el.id] = {
                id: el.id,
                label_name: el.label_name,
                startQuota: null, //Cota do início do período Ex: 31/12/2022
                prevEndQuota: null, //Cota anterior ao final do período Ex: 30/11/2023, para cálculo da rentabilidade do mês,
                endQuota: null, //Cota do início do período Ex: 31/12/2023
            }
        })
        responseStartDateQuotas.body.forEach((el) => {
            if (clientsMap[el.client_id]) {

                clientsMap[el.client_id].startQuota = parseFloat(el.quota_value);
                clientsMap[el.client_id].Z1_DATE_START = moment.utc(el.date_quota).format("DD/MM/YYYY");
            }

        })
        responsePrevEndDateQuotas.body.forEach((el) => {
            if (clientsMap[el.client_id])
                clientsMap[el.client_id].prevEndQuota = parseFloat(el.quota_value);
            clientsMap[el.client_id].Z2_DATE_PREV_END = moment.utc(el.date_quota).format("DD/MM/YYYY");
        })
        responseEndDateQuotas.body.forEach((el) => {
            if (clientsMap[el.client_id]) {

                clientsMap[el.client_id].endQuota = parseFloat(el.quota_value);
                clientsMap[el.client_id].Z3_DATE_END = moment.utc(el.date_quota).format("DD/MM/YYYY");
            }
        })

        const clientsArray = [];
        Object.entries(clientsMap).map(([clientId, row]) => {
            // if (clientId === 76) //Cortês
            //     console.log("ROW: ", row);
            if (row.startQuota && row.endQuota) {
                row.rentAcum = ((parseFloat(row.endQuota) / parseFloat(row.startQuota)) - 1) * 100;
            }

            if (row.prevEndQuota && row.endQuota) {
                row.rentMonth = ((parseFloat(row.endQuota) / parseFloat(row.prevEndQuota)) - 1) * 100;
            }

            if (!row.rentAcum) {
                row.rentAcum = NO_RENT_TO_COMPARE;
            }
            if (!row.rentMonth) {
                row.rentMonth = NO_RENT_TO_COMPARE;
            }
            clientsArray.push(row);
        })

        console.log('clientsArray: ', clientsArray);

        return {
            success: true,
            clients: clientsArray
        }
    } else {
        return {
            success: false,

        }
    }



}

export async function getMoneyRentsClientsByPeriod(period, month, year, consultingId) {
    let prevStartDate = null;

    if (period == 'ano') {

        prevStartDate = getFirstDayYear(year);

    } else if (period != null) {

        prevStartDate = getMonthAgo(
            month,
            year,
            period);

    } else {

        //Trazer pls diários desde o inciio
        prevStartDate = null;
    }

    //Se a data de início for anterior a data de inicio do portfolio adapta-se para a data de inicio do portfolio 
    const { month: monthPrevStartDate, year: yearPrevStartDate } = getDayMonthYearByPtDate(prevStartDate);
    const startMonth = getMonthAgo(monthPrevStartDate, yearPrevStartDate, 2);
    const { month: monthStartMonth, year: yearStartDate } = getDayMonthYearByPtDate(startMonth);

    const endDate = moment.utc(getLastDayMonth(month, year)).format('DD/MM/YYYY');

    // console.log("START DATE TRANSACTIONS: ", startDate);
    // console.log("END DATE TRANSACTIONS: ", endDate);

    const responseSumTransactions = await allClientsSumTransactionsByPeriodAndConsultingId(prevStartDate, endDate, consultingId);

    const responseSumBalancesBeforeClients = await BalancesValidationsAPI.getAllClientsSumBalancesByMonthAndYearAndConsultingId(monthStartMonth, yearStartDate, consultingId);
    const responseSumBalancesNowClients = await BalancesValidationsAPI.getAllClientsSumBalancesByMonthAndYearAndConsultingId(month, year, consultingId);

    //TEST CLIENT
    const clientIdTest = 267;
    console.log('responseSumTransactions: ', responseSumTransactions.body[clientIdTest]);
    console.log('responseSumBalancesBeforeClients: ', responseSumBalancesBeforeClients.data[clientIdTest]);
    console.log('responseSumBalancesNowClients: ', responseSumBalancesNowClients.data[clientIdTest]);

    const periodRentMoneysByClient = {}
    if (responseSumTransactions.success && responseSumBalancesBeforeClients.success && responseSumBalancesNowClients.success) {
        Object.entries(responseSumBalancesNowClients.data).map(([clientId, sumBalancePeriod]) => {
            periodRentMoneysByClient[clientId] = {
                sumBalanceNowInPeriod: sumBalancePeriod,
                //incialziando trnasações do período
                sumTransactionsInPeriod: 0,
            }
        })

        Object.entries(responseSumBalancesBeforeClients.data).map(([clientId, sumBalancePeriod]) => {
            if (!periodRentMoneysByClient[clientId]) {
                periodRentMoneysByClient[clientId] = {
                    sumBalanceBeforeInPeriod: sumBalancePeriod,
                }
            } else {
                periodRentMoneysByClient[clientId].sumBalanceBeforeInPeriod = sumBalancePeriod;
            }
        })

        Object.entries(responseSumTransactions.body).map(([clientId, sumTransactionsInPeriod]) => {
            if (!periodRentMoneysByClient[clientId]) {
                periodRentMoneysByClient[clientId] = {
                    sumTransactionsInPeriod: sumTransactionsInPeriod,
                }
            } else {
                periodRentMoneysByClient[clientId].sumTransactionsInPeriod = sumTransactionsInPeriod;
            }
        })

    }


    const nullClients = [];
    Object.entries(periodRentMoneysByClient).map(([clientId, row]) => {

        if (clientId === 267)
            console.log("CLIENT RENT MONEYS: ", row)

        if (row.sumBalanceNowInPeriod != null && row.sumTransactionsInPeriod != null && row.sumBalanceBeforeInPeriod != null) {

            row.result = row.sumBalanceNowInPeriod - row.sumTransactionsInPeriod - row.sumBalanceBeforeInPeriod;
        } else {
            row.notFullPeriod = true;
        }

    });

    return periodRentMoneysByClient;


}


/**
 * Retorna a lista do cliente formata com as metas acumuladas 
 * @param {int} targets do cliente   
 * @returns {Array}
 */
export function formatTargetsToShow(targets, rentMonths) {

    let inflationRatesToShow = {};

    console.log("RENT MONTHS IN TARGET: ", rentMonths);
    console.log("targets IN FORMAT: ", targets);

    targets.forEach(ir => {

        //Testando se há rentabilidade para esse mês de inflação, pode haver o caso do cliente que começa carteira no meio do ano
        //ocasionando acúmulo parcial de metas
        if (rentMonths[ir.month + '/' + ir.year]) {

            if (!inflationRatesToShow[ir.year]) {

                inflationRatesToShow[ir.year] = {
                    id: ir.id,
                    year: ir.year,
                    tax: ir.tax,
                    taxMonth: (Math.pow((1 + (parseFloat(ir.tax) / 100)), 1 / 12) - 1) * 100,
                    expected_rent: ir.expected_rent,
                    use_ipca: ir.use_ipca,
                    use_inpc: ir.use_inpc,
                };
                inflationRatesToShow[ir.year].inflateMonths = [];
            }

            inflationRatesToShow[ir.year].inflateMonths.push(ir);
        }

    });

    let arrayToShow = [];

    let ipcaAcum = 1;
    let inpcAcum = 1;
    let taxAcumIpca = 1;
    let taxAcumInpc = 1;

    // Para cada indice mensal de inflação, fazendo os valores acumulados
    // e atribuindo a rentabilidade e o gap
    Object.entries(inflationRatesToShow).map(([key, row], index) => {

        let finalTargetsToShow = [];

        row.inflateMonths.reverse();
        row.inflateMonths.forEach((ir) => {


            //Só deve ser incluida na lista as metas que já possuem lançamento
            //Calcular o ipcaAcumulado
            ipcaAcum *= ir.ipca ? (1 + (parseFloat(ir.ipca) / 100)) : 1;
            inpcAcum *= ir.inpc ? (1 + (parseFloat(ir.inpc) / 100)) : 1;

            let fatorIpca = (1 + (parseFloat(ir.ipca) / 100))
            let fatorInpc = (1 + (parseFloat(ir.inpc) / 100))
            let fatorTax = (1 + (parseFloat(row.taxMonth) / 100))

            ir.taxMonth = row.taxMonth;
            ir.taxMonthIpca = row.taxMonth + parseFloat(ir.ipca);
            ir.taxMonthInpc = row.taxMonth + parseFloat(ir.inpc);

            //taxAcum = ((fatorIpca * fatorTax) - 1) * 100;
            taxAcumIpca = (taxAcumIpca * fatorIpca * fatorTax);
            taxAcumInpc = (taxAcumInpc * fatorInpc * fatorTax);
            //console.log(getMonthName(ir.month) + "/" + row.year + ": [(" + (1 + (taxAcum / 100)) + " * " + fatorIpca + " * " + fatorTax + ") - 1] * 100 = " + taxAcum);

            ir.ipcaAcum = parseFloat(parseFloat((ipcaAcum - 1) * 100).toFixed(2));
            ir.inpcAcum = parseFloat(parseFloat((inpcAcum - 1) * 100).toFixed(2));
            ir.taxAcumIpca = parseFloat(parseFloat((taxAcumIpca - 1) * 100).toFixed(2));
            ir.taxAcumInpc = parseFloat(parseFloat((taxAcumInpc - 1) * 100).toFixed(2));

            finalTargetsToShow.push(ir);

        })

        row.inflateMonths.reverse();
        finalTargetsToShow.reverse();
        row.finalTargetsToShow = finalTargetsToShow;

        arrayToShow.push(row);

    })

    arrayToShow.reverse();

    let returnArray = [];
    arrayToShow.forEach(yearTargets => {
        yearTargets.finalTargetsToShow.forEach(target => {

            returnArray.push(target);

        });
    });

    console.log("RETURN ARRAY: ", returnArray);


    return returnArray;

}

/**
 * Retorna a lista do cliente formatada com as metas acumuladas 
 * @param {int} targets do cliente   
 * @returns {Array}
 */
export function formatTargetsSimple(clientTarget, inflationRates, month, year) {

    // console.log("targets IN FORMAT: ", clientTarget);
    // console.log("inflationRates: ", inflationRates);

    const mainTarget = clientTarget;

    const formattedMainTarget = {
        id: mainTarget.id,
        year: mainTarget.year,
        tax: mainTarget.tax,
        taxMonth: (Math.pow((1 + (parseFloat(mainTarget.tax) / 100)), 1 / 12) - 1) * 100,
        expected_rent: mainTarget.expected_rent,
        use_ipca: mainTarget.use_ipca,
        use_inpc: mainTarget.use_inpc,
        inflateMonths: inflationRates,
    };

    let ipcaAcum = 1;
    let inpcAcum = 1;
    let taxAcumIpca = 1;
    let taxAcumInpc = 1;

    let targetActualMonth = null;

    formattedMainTarget.inflateMonths.forEach((ir) => {


        //Só deve ser incluida na lista as metas que já possuem lançamento
        //Calcular o ipcaAcumulado
        ipcaAcum *= ir.ipca ? (1 + (parseFloat(ir.ipca) / 100)) : 1;
        inpcAcum *= ir.inpc ? (1 + (parseFloat(ir.inpc) / 100)) : 1;

        let fatorIpca = (1 + (parseFloat(ir.ipca) / 100))
        let fatorInpc = (1 + (parseFloat(ir.inpc) / 100))
        let fatorTax = (1 + (parseFloat(formattedMainTarget.taxMonth) / 100))

        ir.taxMonth = formattedMainTarget.taxMonth;
        ir.taxMonthIpca = formattedMainTarget.taxMonth + parseFloat(ir.ipca);
        ir.taxMonthInpc = formattedMainTarget.taxMonth + parseFloat(ir.inpc);

        //taxAcum = ((fatorIpca * fatorTax) - 1) * 100;
        taxAcumIpca = (taxAcumIpca * fatorIpca * fatorTax);
        taxAcumInpc = (taxAcumInpc * fatorInpc * fatorTax);
        //console.log(getMonthName(ir.month) + "/" + formattedMainTarget.year + ": [(" + (1 + (taxAcum / 100)) + " * " + fatorIpca + " * " + fatorTax + ") - 1] * 100 = " + taxAcum);

        ir.ipcaAcum = parseFloat(parseFloat((ipcaAcum - 1) * 100).toFixed(2));
        ir.inpcAcum = parseFloat(parseFloat((inpcAcum - 1) * 100).toFixed(2));
        ir.taxAcumIpca = parseFloat(parseFloat((taxAcumIpca - 1) * 100).toFixed(2));
        ir.taxAcumInpc = parseFloat(parseFloat((taxAcumInpc - 1) * 100).toFixed(2));

        if (month == ir.month
            && year == ir.year) {

            targetActualMonth = ir;

        }


    })


    //console.log("formattedMainTarget: ", formattedMainTarget);

    return {
        periodTarget: formattedMainTarget,
        targetActualMonth: targetActualMonth,
    };

}

export function formatTargetsInClientsRents(
    clients,
    targetsClients,
    inflationRates,
    month,
    year) {

    if (inflationRates) {
        //normalize inflate rates
        inflationRates.forEach(element => {
            element.ipca = element.ipca ? parseFloat(element.ipca) : null;
            element.inpc = element.inpc ? parseFloat(element.inpc) : null;
        });

        clients.forEach(clientLoop => {
            const clientLoopTargets = targetsClients.find(el => el.client_id == clientLoop.id);
            //console.log('clientLoopTargets: ', clientLoopTargets);
            if (clientLoopTargets) {
                const { periodTarget, targetActualMonth } = formatTargetsSimple(clientLoopTargets, inflationRates, month, year);

                clientLoop.targets = {
                    periodTarget, targetActualMonth

                }
                //console.log('targetActualMonth: ', targetActualMonth);
                clientLoop.targetMonth = targetActualMonth ? (periodTarget.use_ipca ? targetActualMonth.taxMonthIpca : targetActualMonth.taxMonthInpc) : null;
                clientLoop.targetAccumulated = targetActualMonth ? (periodTarget.use_ipca ? targetActualMonth.taxAcumIpca : targetActualMonth.taxAcumInpc) : null;

                clientLoop.gapMonth = clientLoop.rentMonth !== NO_RENT_TO_COMPARE ? clientLoop.rentMonth - clientLoop.targetMonth : null;
                clientLoop.gapAccumulated = clientLoop.rentAcum !== NO_RENT_TO_COMPARE ? clientLoop.rentAcum - clientLoop.targetAccumulated : null;


            }


        });
    }


}

export async function formatBalancesRentsByRange(funds, clientId, period) {

    let startDate = null;
    let today = moment(new Date()).format('DD/MM/YYYY')
    let splittedToday = today.split('/');
    let month = parseInt(splittedToday[1]);
    let year = parseInt(splittedToday[2]);

    if (period == 'ano') {

        startDate = getFirstDayYear(year);

    } else if (period != null) {

        startDate = getMonthAgo(
            month,
            year,
            period);

    } else {

        //Trazer pls diários desde o inciio
        startDate = null;
    }

    let endDate = getTheLastDayCompleteMonthPt();

    console.log("START DATE X: ", startDate);
    console.log("END DATE X: ", endDate);

    let responseBalancesRent = await getBalancesRentsByRange(clientId, startDate, endDate);

    if (responseBalancesRent.success) {

        console.log("BALANCES RENT: ", responseBalancesRent);

        //Agrupar resposta por fundo / mes / ano
        let mapByFund = {};
        let indexMap = 0;
        responseBalancesRent.body.rows.forEach(balanceRent => {

            if (mapByFund[balanceRent.fund_id] == null) {
                mapByFund[balanceRent.fund_id] = {};
                mapByFund[balanceRent.fund_id].keys = [];
                indexMap = 1;
            }

            let key = indexMap + "-" + balanceRent.month_validation + "/" + balanceRent.year_validation;
            let keyMapTest = balanceRent.month_validation + "/" + balanceRent.year_validation;

            if (!mapByFund[balanceRent.fund_id].keys.includes(keyMapTest)) {

                if (balanceRent.rent_month) { //caso seja nulo é o saldo referente ao inicio da carteira, ainda não havia cotização
                    let obj = {
                        month: balanceRent.month_validation,
                        year: balanceRent.year_validation,
                        monthYear: keyMapTest,
                        rentMonth: balanceRent.rent_month
                    }
                    mapByFund[balanceRent.fund_id][key] = obj
                    mapByFund[balanceRent.fund_id].keys.push(keyMapTest);

                    //Separando os rendimentos do ano
                    if (obj.year == year) {

                        if (!mapByFund[balanceRent.fund_id].rentsYear) {
                            mapByFund[balanceRent.fund_id].rentsYear = [];
                        }

                        mapByFund[balanceRent.fund_id].rentsYear.push(obj);
                    }

                    //Separando rendimentos 12m
                    if (!mapByFund[balanceRent.fund_id].rents12m) {
                        mapByFund[balanceRent.fund_id].rents12m = [];
                    }

                    if (mapByFund[balanceRent.fund_id].rents12m.length < 12) {
                        mapByFund[balanceRent.fund_id].rents12m.push(obj)
                    }

                    //Separando rendimentos 24m
                    if (!mapByFund[balanceRent.fund_id].rents24m) {
                        mapByFund[balanceRent.fund_id].rents24m = [];
                    }

                    if (mapByFund[balanceRent.fund_id].rents24m.length < 24) {
                        mapByFund[balanceRent.fund_id].rents24m.push(obj)
                    }

                    indexMap++;
                }

            }

        });

        console.log("MAP FUND: ", mapByFund);


        //Realizar capitalização dos grupos de meses ANO / 12 MESES / 24 MESES
        Object.entries(mapByFund).map(([key, row]) => {

            let rentAcumYear = 1;
            if (row.rentsYear) {

                row.rentsYear.forEach(element => {

                    rentAcumYear *= (1 + (parseFloat(element.rentMonth) / 100))

                });

                rentAcumYear = parseFloat(parseFloat((rentAcumYear - 1) * 100).toFixed(2));

                row.rentAcumYear = rentAcumYear;
            }

            let rentAcum12m = 1;
            if (row.rents12m) {

                row.rents12m.forEach(element => {

                    rentAcum12m *= (1 + (parseFloat(element.rentMonth) / 100))

                });

                rentAcum12m = parseFloat(parseFloat((rentAcum12m - 1) * 100).toFixed(2));

                row.rentAcum12m = rentAcum12m;
            }

            let rentAcum24m = 1;
            if (row.rents24m) {

                row.rents24m.forEach(element => {

                    rentAcum24m *= (1 + (parseFloat(element.rentMonth) / 100))

                });

                rentAcum24m = parseFloat(parseFloat((rentAcum24m - 1) * 100).toFixed(2));

                row.rentAcum24m = rentAcum24m;
            }

        })

        console.log("MAP RENTS: ", mapByFund);

        //atualizando variável rentAcumYear nos fundos
        funds.forEach(element => {

            if (mapByFund[element.id]) {
                element.rentAcumYear = mapByFund[element.id].rentAcumYear;
                element.rentAcum12m = mapByFund[element.id].rentAcum12m;
                element.rentAcum24m = mapByFund[element.id].rentAcum24m;
            }

        });

    } else {

        console.log("FALHA AO TRAZER BALANCES RENTS POR PERIODO");

    }


}

export async function loadClientDiaryPlsByLimit(clientId, month, year, limit) {

    const responseRents = await getClientPortfolioRentsByLimit(clientId, month, year, limit);
    if (responseRents.success && responseRents.body.length > 0) {

        return responseRents.body;

    } else {

        return null;

    }

}

export async function formatClientsPorfolioLaunchs(orderDirection, advisorId, consultingId) {

    const responseAllClientsLaunch = await getClientsAndLaunchs(orderDirection, advisorId, consultingId);

    if (responseAllClientsLaunch.success && responseAllClientsLaunch.body.rows.length > 0) {

        const clients = responseAllClientsLaunch.body.rows;
        console.log("PORTFOLIO LAUNCHS: ", clients);

        const labelNoLaunch = "Sem lançamento";

        const clientsNoLaunch = clients.filter(el => !el.portfolio_closed);
        const mapLaunch = {
            [labelNoLaunch]: clients.filter(el => !el.portfolio_closed),
        };


        const clientsWithLaunchs = clients.filter(el => el.portfolio_closed);
        let lastLabel = clientsWithLaunchs[0].portfolio_closed.toString().trim();
        let orderMap = 1;

        clientsWithLaunchs.forEach(element => {

            const portfolioClosed = element.portfolio_closed.toString().trim();
            if (portfolioClosed != lastLabel) {
                lastLabel = portfolioClosed;
                orderMap++;
            }

            const key = orderMap + "_" + portfolioClosed;
            if (!mapLaunch[key]) {
                mapLaunch[key] = [];
            }

            mapLaunch[key].push(element);


        });

        console.log('mapLaunch: ', mapLaunch);

        //to array
        const arrayDates = [];
        const totalClients = clientsWithLaunchs.length + clientsNoLaunch.length;
        Object.entries(mapLaunch).map(([key, row]) => {
            if (key != labelNoLaunch) {
                arrayDates.push({
                    launch: key.substring(key.indexOf('_') + 1),
                    qtd: row.length,
                    percent: applyMaskAmount(row.length / totalClients * 100) + '%',
                    clients: row,
                })
            }

        });

        arrayDates.push({
            launch: labelNoLaunch,
            qtd: mapLaunch[labelNoLaunch].length,
            percent: applyMaskAmount(mapLaunch[labelNoLaunch].length / totalClients * 100) + '%',
            clients: mapLaunch[labelNoLaunch],
        })

        console.log('arrayDates: ', arrayDates);

        //Array to pie chartjs
        const arrayToChart = [];
        arrayDates.forEach(element => {
            arrayToChart.push({
                label: element.launch,
                value: element.qtd,
            })
        });


        return { table: arrayDates, chart: formatDataToPieChart(arrayToChart), totalClients: totalClients };
    } else {
        return null;
    }




}

//Ordenando clientes por ordem alfabética
function compareSortLabelName(a, b) {
    if (a.label_name < b.label_name)
        return -1;
    if (a.label_name > b.label_name)
        return 1;
    return 0;
}



export async function formatClientsTargets(orderDirection, advisorId, consultingId) {

    const responseAllClientsLaunch = await getClientsAndLaunchs(orderDirection, advisorId, consultingId);

    if (responseAllClientsLaunch.success) {

        const responseAllClientsTargets = await getClientsTargets(orderDirection, advisorId, consultingId);

        console.log('responseAllClientsTargets: ', responseAllClientsTargets);

        if (responseAllClientsTargets.success) {

            //inicio de demanda de meta mais antigo
            let moreInitTarget = null;

            const allClients = responseAllClientsLaunch.body.rows;
            const allTargets = responseAllClientsTargets.body.rows;

            const mapTargetsByClient = {};
            allTargets.forEach(target => {

                if (!mapTargetsByClient[target.client_id]) {
                    mapTargetsByClient[target.client_id] = [];
                }

                mapTargetsByClient[target.client_id].push(target.target_year);

            });

            console.log('mapTargetsByClient: ', mapTargetsByClient);

            allClients.forEach(client => {

                client.registredTargets = mapTargetsByClient[client.id];

                const momentInitPortfolio = moment.utc(client.portfolio_init);
                client.initYearTarget = momentInitPortfolio.month() + 1 != 12 ? momentInitPortfolio.year() : momentInitPortfolio.year() + 1;
                client.endYearTarget = client.portfolio_closed ? parseInt(client.portfolio_closed.toString().trim().split('/')[1]) : null;

                if (!moreInitTarget || client.initYearTarget < moreInitTarget) {
                    moreInitTarget = client.initYearTarget;
                }

            });

            console.log('allClients: ', allClients);
            const currentYear = new Date().getFullYear();
            const yearsToTable = [];
            for (let i = moreInitTarget; i <= currentYear; i++) {
                yearsToTable.push(i);
            }

            const arrayToTable = [];

            yearsToTable.forEach(year => {

                const demandTargetsClients = allClients.filter(el =>
                    year >= el.initYearTarget
                    && year <= el.endYearTarget
                );

                const registredTargetsClients = allClients.filter(el =>
                    year >= el.initYearTarget
                    && year <= el.endYearTarget
                    && (el.registredTargets && el.registredTargets.includes(year))
                )

                arrayToTable.push({
                    year: year,
                    demandInYear: demandTargetsClients,
                    registredInYear: registredTargetsClients,
                })

            });

            console.log('arrayToTable: ', arrayToTable.reverse());

            allClients.sort(compareSortLabelName);

            return {
                allClients: allClients,
                yearsToTable: yearsToTable,
                table: arrayToTable,
                //chart: formatDataToPieChart(arrayToChart)
            };
        }

    } else {
        return null;
    }

}

export async function formatClientsPolicys(orderDirection, advisorId, consultingId) {



    const responseAllClientsLaunch = await getClientsAndLaunchs(orderDirection, advisorId, consultingId);

    if (responseAllClientsLaunch.success) {

        const responseAllClientsPolicys = await getClientsPolicys(orderDirection, advisorId, consultingId);

        console.log('responseAllClientsPolicys: ', responseAllClientsPolicys);

        if (responseAllClientsPolicys.success) {

            //inicio de demanda de meta mais antigo
            let moreInitTarget = null;

            const allClients = responseAllClientsLaunch.body.rows;
            const allTargets = responseAllClientsPolicys.body.rows;

            const mapTargetsByClient = {};
            allTargets.forEach(target => {

                if (!mapTargetsByClient[target.client_id]) {
                    mapTargetsByClient[target.client_id] = [];
                }

                mapTargetsByClient[target.client_id].push(target.policy_year);

            });

            console.log('mapTargetsByClient: ', mapTargetsByClient);

            allClients.forEach(client => {

                client.registredTargets = mapTargetsByClient[client.id];

                const momentInitPortfolio = moment.utc(client.portfolio_init);
                client.initYearTarget = momentInitPortfolio.month() + 1 != 12 ? momentInitPortfolio.year() : momentInitPortfolio.year() + 1;
                client.endYearTarget = client.portfolio_closed ? parseInt(client.portfolio_closed.toString().trim().split('/')[1]) : null;

                if (!moreInitTarget || client.initYearTarget < moreInitTarget) {
                    moreInitTarget = client.initYearTarget;
                }

            });

            console.log('allClients: ', allClients);
            const currentYear = new Date().getFullYear();
            const yearsToTable = [];
            for (let i = moreInitTarget; i <= currentYear; i++) {
                yearsToTable.push(i);
            }

            const arrayToTable = [];

            yearsToTable.forEach(year => {

                const demandTargetsClients = allClients.filter(el =>
                    year >= el.initYearTarget
                    && year <= el.endYearTarget
                );

                const registredTargetsClients = allClients.filter(el =>
                    year >= el.initYearTarget
                    && year <= el.endYearTarget
                    && (el.registredTargets && el.registredTargets.includes(year))
                )

                arrayToTable.push({
                    year: year,
                    demandInYear: demandTargetsClients,
                    registredInYear: registredTargetsClients,
                })

            });

            console.log('arrayToTable: ', arrayToTable.reverse());

            allClients.sort(compareSortLabelName);

            return {
                allClients: allClients,
                yearsToTable: yearsToTable,
                table: arrayToTable,
                //chart: formatDataToPieChart(arrayToChart)
            };
        }

    } else {
        return null;
    }

}

export function getStatusDPIN(selectedYear, statusDpin) {

    if (selectedYear <= 2016) {
        return STATUS_DPIN.unneeded;
    } else {

        return statusDpin ? statusDpin : STATUS_DPIN.not_sent;

    }
}

export function isLaunchedByMonthsAndYears(
    testDate,
    initDate,
    closedDate,
    ableQuotasDate) {

    if (testDate.isSameOrAfter(initDate) && testDate.isSameOrBefore(closedDate)) {
        return STATUS_DAIR.launched;
    } else if (testDate.isSameOrAfter(initDate) && testDate.isAfter(closedDate)) {
        if (testDate.isSameOrBefore(ableQuotasDate)) {

            //se após agosto de 2022 entrar como EXTRATO PENDENTE
            const startDateStatusClient = moment.utc('2022-08-01');
            if (testDate.isSameOrAfter(startDateStatusClient)) {
                return STATUS_DAIR.pendent;
            } else {
                return STATUS_DAIR.unlaunched;
            }
        } else {
            return STATUS_DAIR.unneeded;
        }
    } else if (testDate.isBefore(initDate)) {
        return STATUS_DAIR.unneeded;
    }

}

export async function doGetStatusClientsByYear(offset, limit, selectedYear, advisorId,
    consultingId, search,
    dairStatusIdFilter, monthFilterDair,
    snapshotDate //Data para análise de status
) {

    //const responseDpin = await getStatusDpinClientsByYear(offset, limit, selectedYear, advisorId, consultingId, search);
    const responseDair = await getStatusDairClientsByYear(offset, limit, selectedYear,
        advisorId, consultingId, search,
        dairStatusIdFilter,
        monthFilterDair, snapshotDate);
    console.log("responseDair: ", responseDair);
    //console.log("responseDpin: ", responseDpin);

    //if (responseDpin.success && responseDair.success) {
    if (responseDair.success) {

        // const DPINS = responseDpin.body.rows;
        // const DPINSMap = {};
        // DPINS.forEach(element => {
        //     DPINSMap[element.client_id] = element;
        // });

        // console.log('DPINSMap: ', DPINSMap);

        const now = new Date();
        const lastMonthAbleToQuotas = {
            month: now.getMonth() + 1,
            year: now.getFullYear()
        };
        const ableQuotasDate = getFirstDateDayMonth(lastMonthAbleToQuotas.month, lastMonthAbleToQuotas.year);
        const statusClientsMap = {};
        //Foramtando resultado para ser exibido na tabela 
        responseDair.body.rows.forEach(statusClient => {

            const clientLabelNameLoop = statusClient.label_name;
            if (!statusClientsMap[clientLabelNameLoop]) {
                statusClientsMap[clientLabelNameLoop] = {
                    id: statusClient.client_id,
                    label_name: clientLabelNameLoop,
                    dpin: null,
                    1: null,
                    2: null,
                    3: null,
                    4: null,
                    5: null,
                    6: null,
                    7: null,
                    8: null,
                    9: null,
                    10: null,
                    11: null,
                    12: null,
                };
            }

            if (!statusClientsMap[clientLabelNameLoop].initDate) {
                const infoClosed = statusClient.portfolio_closed ? getMonthAndYearPortfolioClosed(statusClient.portfolio_closed.trim()) : null;
                const monthClosed = infoClosed && infoClosed.month;
                const yearClosed = infoClosed && infoClosed.year;
                const infoInit = moment.utc(statusClient.portfolio_init);
                const monthInit = infoInit.month() + 1;
                const yearInit = infoInit.year();

                const initDate = getFirstDateDayMonth(monthInit, yearInit);
                const prevClosedDate = getFirstDateDayMonth(monthClosed, yearClosed);
                const closedDate = prevClosedDate == INVALID_DATE_STRING ? initDate : prevClosedDate;

                statusClientsMap[clientLabelNameLoop].initDate = initDate;
                statusClientsMap[clientLabelNameLoop].closedDate = closedDate;
            }

            if (statusClient.dair_month) {
                statusClientsMap[clientLabelNameLoop][statusClient.dair_month] = statusClient;
            }

            //Atribuindo DPIN
            if (statusClient
                && statusClient.status_dpin_id
                && !statusClientsMap[clientLabelNameLoop]['dpin']) {
                statusClientsMap[clientLabelNameLoop]['dpin'] = {
                    status_dpin_id: statusClient.status_dpin_id,
                    dpin_name: statusClient.dpin_name,
                    dpin_short_name: statusClient.dpin_short_name,
                    dpin_color: statusClient.dpin_color,
                }
            }

            //atualizando status do DPIN 
            //realizar lógica para trazer o status do DPIN do ano do cliente
            //monthsFilled['dpin'] = getStatusDPIN(selectedYear, client.status_dpin);

            //verificando unneeded
            for (let monthLoop = 1; monthLoop <= 12; monthLoop++) {

                //Caso em que não há status
                if (!statusClientsMap[clientLabelNameLoop][monthLoop]) {

                    const testDate = getFirstDateDayMonth(monthLoop, selectedYear);
                    //mes antes do init ou mês depos do último mês possível de lançamento = unneeded
                    const SIMULATOR_STATUS_DAIR = isLaunchedByMonthsAndYears(testDate,
                        statusClientsMap[clientLabelNameLoop].initDate,
                        statusClientsMap[clientLabelNameLoop].closedDate,
                        ableQuotasDate);
                    if (SIMULATOR_STATUS_DAIR == STATUS_DAIR.unneeded) {

                        statusClientsMap[clientLabelNameLoop][monthLoop] = STATUS_DAIR.unneeded
                    } else if (SIMULATOR_STATUS_DAIR == STATUS_DAIR.pendent) {

                        statusClientsMap[clientLabelNameLoop][monthLoop] = STATUS_DAIR.pendent
                    }
                }
            }




        });



        const statusClientsArray = [];
        Object.entries(statusClientsMap).map(([key, row]) => {
            //console.log('key: ', key);
            statusClientsArray.push(row);
        })

        console.log('statusClientsArray: ', statusClientsArray);

        //Caso ainda haja algum mês com o valor null é corrigido para PENDENT (Caso em que há filtro de data)
        if (selectedYear == new Date().getFullYear()) {
            statusClientsArray.forEach(element => {
                for (let monthLoop = 1; monthLoop <= 12; monthLoop++) {

                    //Caso em que não há status
                    if (!element[monthLoop]) {
                        element[monthLoop] = STATUS_DAIR.pendent;
                    }
                }
            });
        }


        console.log('statusClientsArray: ', statusClientsArray);

        return {
            success: true,
            body: statusClientsArray,
            totalItems: responseDair.body.totalRowsCount,
        }
    } else {
        return {
            success: false,
        }
    }
}

export async function doGetActuarysInfos(year, advisorId, consultingId, search) {

    const response = await getActuarysInfos(year, advisorId, consultingId, search);
    console.log('getActuarysInfos: ', response);

    if (response.success) {
        return {
            success: true,
            body: response.body.rows
        }
    } else {
        return { success: false }
    }
}
