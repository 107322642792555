import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import allActions from '../../actions'
import { unoTheme } from '../../assets/styles/unoTheme';
import { applyMaskAmount, applyMaskAccount, applyMaskAmountFreeDecimals, checkIsSameBalance, checkIsSameQuotas } from './utils'

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { isPrivateCdiTitle } from '../../controllers/TitleController';

const useStyles = makeStyles((theme) => ({

    alertBalance: {
        '& .MuiAlert-message': {
            fontSize: '12px',
            padding: '0px',
            '& span': {
                verticalAlign: 'middle',
            }
        },
        '& .MuiAlert-icon': {
            padding: '0px',
        },
        '& .alertMain': {
            marginTop: '5px',
            marginBottom: '5px',
        }
    },
    bold: {
        color: 'black',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: 'blue',
    },
}));

/**
 * My main alert
 * @param {string} show 
 * @param {string} type
 * @param {string} message
 * @returns {Object} Alert
 */
export default function MyAlertBalance(props) {

    const classes = useStyles();
    const difBalances = 0.15;
    //const difBalances = 500000;

    const {
        row,
        action,
        role
    } = props

    function renderAccount(row) {
        return (
            <span><span className={classes.bold}>Conta: </span><span className={classes.bold}>{applyMaskAccount(row.number_account)} {row.agency ? "/" + row.agency : ""}</span></span>
        )
    }

    return (

        <div className={classes.alertBalance}>

            {
                //row.balance_now == row.balance_before + row.resultAmount

                //Caso o saldo validado seja igual ao atual pl não exibe nada
                ((row.balance_now == row.pl
                    || checkIsSameBalance(row.pl, row.balance_now)) && (row.quota_amount_now == row.qtd_quotas_sensibilized_final || checkIsSameQuotas(row.quota_amount_now, row.qtd_quotas_sensibilized_final)))
                    ?

                    null
                    :
                    <>
                        {

                            //Caso o saldo esteja conciliado não exibe nada
                            row.balance_now_conc ?

                                null
                                :

                                //Para aparecer o botão de conciliar, o ssaldo tem que ter sido informado na tela de Ativos e Saldos
                                //e a diferença entre saldos não pode ser maior que difBalances

                                //Ou

                                //Pode ser um fundo especial que não possui cotas na CVM/ANBIMA
                                <Alert className={'alertMain'}
                                    variant="outlined"
                                    severity="warning"
                                    action={

                                        (row.balance_now != undefined
                                            && (
                                                row.pl - row.balance_now >= 0 ?
                                                    (row.pl - row.balance_now <= difBalances || (role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN"))
                                                    :
                                                    (((row.pl - row.balance_now) * (-1)) <= difBalances || (role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN"))
                                            )
                                            && (
                                                row.qtd_quotas_sensibilized_final - row.quota_amount_now >= 0 ?
                                                    (row.qtd_quotas_sensibilized_final - row.quota_amount_now <= difBalances || (role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN"))
                                                    :
                                                    (((row.qtd_quotas_sensibilized_final - row.quota_amount_now) * (-1)) <= difBalances || (role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN"))
                                            )
                                        )
                                            || (row.special && ((role == "ROLE_ADMIN" || role == "ROLE_SUPER_ADMIN"))
                                            ) ?

                                            <Button color={"inherit"}
                                                size="small"
                                                onClick={() => action(row.balance_now_id)}>
                                                {'Conciliar'}
                                            </Button>

                                            : null
                                    }>

                                    {renderAccount(row)}
                                    <br />

                                    {
                                        //Testando se o fundo não é especial
                                        // !row.special ?
                                        //     <>

                                        //         {

                                        //Caso não tenha sido informado o saldo anterior exibe-se a mensagem de que o saldo precisa ser informado na tela de Ativos e contas
                                        row.balance_before == undefined
                                            || row.balance_now == undefined
                                            || row.quota_amount_before == undefined
                                            || row.quota_amount_now == undefined ?

                                            <span>É necessário que o SALDO e a QTD DE COTAS (Anterior e atual) sejam informados na tela de Ativos e Contas</span>

                                            :

                                            <>
                                                {/* Testando se o saldo sensibilizado de cotas é igual ao saldo atual informado na validação de saldos */}

                                                {
                                                    row.quota_amount_now == row.qtd_quotas_sensibilized_final || checkIsSameQuotas(row.quota_amount_now, row.qtd_quotas_sensibilized_final) ?

                                                        <>

                                                            {
                                                                //Caso os saldos estejam dentro dos limites de diferença
                                                                (row.pl - row.balance_now > 0 ?
                                                                    row.pl - row.balance_now <= difBalances
                                                                    : ((row.pl - row.balance_now) * (-1)) <= difBalances) ?

                                                                    <span>
                                                                        O saldo calculado {'>'} <span className={classes.green}>{
                                                                            row.balance_before != null ? applyMaskAmountFreeDecimals(row.pl, true) : applyMaskAmountFreeDecimals(row.resultAmount, true)
                                                                        }</span> está diferente do saldo do extrato {'>'} <span className={classes.green}>{applyMaskAmount(row.balance_now, true)}</span>.
                                                                        <span> ( Dif: </span><span className={classes.red}>{applyMaskAmountFreeDecimals(row.pl - row.balance_now, false, 5)}</span> )
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        O saldo calculado {'>'} <span className={classes.green}>{
                                                                            row.balance_before != null ? applyMaskAmountFreeDecimals(row.pl, true) : applyMaskAmountFreeDecimals(row.resultAmount, true)
                                                                        }</span> está diferente do saldo do extrato {'>'} <span className={classes.green}>{applyMaskAmount(row.balance_now, true)} </span>além do limite máximo de <span style={{ color: 'blue' }}>{applyMaskAmount(difBalances, true)}. </span><span>Consulte o admnistrador para liberação</span>
                                                                    </span>
                                                            }

                                                        </>

                                                        :

                                                        // <span>A quantidade de cotas informada na validação de saldos ( <span className={classes.green}>{applyMaskAmountFreeDecimals(row.quota_amount_now, false, 5)}</span> ) para o mês atual está diferente da quantidade de cotas obtidas na sensibilzação ( <span className={classes.green}>{applyMaskAmountFreeDecimals(row.qtd_quotas_sensibilized_final, false, 5)}</span> )</span>
                                                        <div>


                                                            <span>A quantidade de cotas informada na validação de saldos para o mês atual está diferente da quantidade de cotas obtidas na sensibilzação </span>
                                                            {
                                                                Number.isNaN(row.quota_amount_before) ? <div><span className={classes.blue}>(Saldo de cotas do mês anterior não informado)</span></div>
                                                                    :
                                                                    <>
                                                                        <div>(Validação) Cotas: <span className={classes.green}>{applyMaskAmountFreeDecimals(row.quota_amount_now, false, 5)}</span> / Valor: <span className={classes.green}> {applyMaskAmountFreeDecimals(row.balance_now, true)} </span></div>
                                                                        <div>(Sensib.) Cotas: <span className={classes.green}>{applyMaskAmountFreeDecimals(row.qtd_quotas_sensibilized_final, false, 5)}</span> / Valor: <span className={classes.green}>{applyMaskAmountFreeDecimals(row.pl, true)}</span></div>
                                                                        <div>Dif. Cotas:<span className={classes.red}>{applyMaskAmountFreeDecimals(row.quota_amount_now - row.qtd_quotas_sensibilized_final, false, 5)}</span> / Dif. Valor: <span className={classes.red}>{applyMaskAmountFreeDecimals(row.pl - row.balance_now, true)}</span></div>
                                                                        {
                                                                            row && isPrivateCdiTitle(row) && row.qtd_quota && row.cdb_quota_value ?
                                                                                <div style={{ color: unoTheme.mainColor }}>CDB: {row.qtd_quota + " x " + row.cdb_quota_value + ' = ' + applyMaskAmountFreeDecimals(row.qtd_quota * row.cdb_quota_value)}</div>
                                                                                : null
                                                                        }
                                                                    </>
                                                            }

                                                        </div>
                                                }


                                            </>


                                        //     }
                                        // </>
                                        // :

                                        // <>
                                        //     <span>Fundo especial, esse fundo não possui cotas cadastradas e deve ser conciliado</span>
                                        // </>
                                    }

                                </Alert>
                        }
                    </>

            }
        </div >
    );
}