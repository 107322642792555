import React, { useEffect } from "react";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { unoTheme, myInput, myLabelInput, toogleButton } from "../../assets/styles/unoTheme";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
    toogleButton: toogleButton,
}));

export default function MyToggleSegregationButton(props) {

    const classes = useStyles();

    const { value, handleValue, disabled, segregated, marginRight } = props;

    return (
        <ToggleButtonGroup
            style={
                { marginRight: marginRight ? '16px' : '0px' }
            }
            className={classes.toogleButton}
            exclusive
            value={value}
            onChange={handleValue} >
            <ToggleButton
                disabled={disabled}
                value={null} aria-label="Consolidada">
                {'Consolidada'}
            </ToggleButton>
            <ToggleButton
                disabled={disabled}
                value={1}
                aria-label="Previdenciária">
                {'Previdenciária'}
            </ToggleButton>
            {
                segregated ?
                    <ToggleButton
                        disabled={disabled}
                        value={2}
                        aria-label="Financeiro">
                        {'Financeiro'}
                    </ToggleButton>
                    : null
            }
            <ToggleButton
                value={3}
                disabled={disabled}
                aria-label="Taxa Administrativa">
                {'Taxa Administrativa'}
            </ToggleButton>
        </ToggleButtonGroup>
    )
}