import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import MyTable from '../../utils/MyTable';
import MyTextField from '../../utils/MyTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmActionRightContent from './ConfirmActionRightContent'

//API
import {
    listAllLegislationArticlesByResolutionId,
    updateLegislationSegment
} from '../../../API/legislation_article';

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { unoTheme } from "../../../assets/styles/unoTheme";
import UnoButton from '../../utils/UnoButton';

import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import MyAutoComplete from '../../utils/MyAutoComplete';
import { ArticleRulesAPI } from '../../../API/article_rules';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        textAlign: 'left',
        overflowY: 'scroll',
        height: 'calc(100% - 96px)',


    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));


export default function DetailAdminArticleRule(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    const [articles, setArticles] = useState([]);
    const [articlesRuleList, setArticlesRuleList] = useState([]);

    const [inputName, setInputName] = useState("");
    const [inputDescription, setInputDescription] = useState("");
    const [inputLimitSegment, setInputLimitSegment] = useState("");

    //Pro gestão
    const [inputPGI, setInputPGI] = useState("");
    const [inputPGII, setInputPGII] = useState("");
    const [inputPGIII, setInputPGIII] = useState("");
    const [inputPGIV, setInputPGIV] = useState("");

    const [submitted, setSubmitted] = useState(false);

    //deleção da regra
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteArticleRule();
    }

    async function doSaveArticleRule(isUpdate) {

        setOpenBackdrop(true);
        setSubmitted(true);

        if (articlesRuleList.length > 0) {

            if (inputName != "") {
                const newArticleRule = {
                    user_id: currentUser.user.id,
                    name: inputName,
                    description: inputDescription,
                    limit_rule: parseFloat(inputLimitSegment),
                    limit_pg1: inputPGI ? inputPGI : null,
                    limit_pg2: inputPGII ? inputPGII : null,
                    limit_pg3: inputPGIII ? inputPGIII : null,
                    limit_pg4: inputPGIV ? inputPGIV : null,
                    articlesIds: articlesRuleList.map(el => el.id),
                }

                if (isUpdate) {

                    const responseUpdate = await ArticleRulesAPI.update({ ...newArticleRule, id: content?.data.rule.id });
                    if (responseUpdate.success) {
                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: responseUpdate.body.message,
                        }))
                    }
                    dispatch(allActions.rightContentActions.update())
                } else {
                    const responseCreate = await ArticleRulesAPI.create(newArticleRule);
                    if (responseCreate.success) {
                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: responseCreate.body.message,
                        }))
                    }
                    dispatch(allActions.rightContentActions.update())
                }



            }

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'Selecione pelo menos 1 artigo de resolução para compor a regra'
            }))
        }

        setOpenBackdrop(false);



    }

    async function doDeleteArticleRule() {
        const responseDelete = await ArticleRulesAPI.delete(content?.data.rule.id);
        if (responseDelete.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: responseDelete.body.message,
            }))
        }
        dispatch(allActions.rightContentActions.update())

    }

    async function loadInfos(data) {

        setOpenBackdrop(true);

        const { resolutionId, rule } = data;

        const responseAllArticlesInResolution = await listAllLegislationArticlesByResolutionId(resolutionId);
        if (responseAllArticlesInResolution.success) {
            console.log('allArticlesInResolution: ', responseAllArticlesInResolution.body.rows);
            setArticles(responseAllArticlesInResolution.body.rows);

            if (rule) {
                setInputName(rule.name);
                setInputDescription(rule.description);
                setInputLimitSegment(rule.limit_rule);
                setInputPGI(rule.limit_pg1);
                setInputPGII(rule.limit_pg2);
                setInputPGIII(rule.limit_pg3);
                setInputPGIV(rule.limit_pg4);


                setArticlesRuleList(rule.articles);
            }

            setOpenBackdrop(false);
        }


    }

    const addArticleRuleList = (article) => {

        const newArticlesRuleList = [...articlesRuleList];
        newArticlesRuleList.push(article);
        setArticlesRuleList(newArticlesRuleList);

    }

    const removeArticleFromList = (index) => {

        const newArticlesRuleList = [...articlesRuleList];
        newArticlesRuleList.splice(index, 1);
        setArticlesRuleList(newArticlesRuleList);

    }

    const getLabelAutocomplete = (option) => {

        if (option.device_abbreviation) {
            return option.device_abbreviation + ' / ' + option.asset
        }
        return option.asset
    }


    useEffect(() => {

        if (content && content.data) {

            console.log("DATA: ", content.data);
            loadInfos(content.data);
        }

    }, [])

    return (



        <div className={classes.root}>

            {
                !confirmingAction ?
                    <>

                        <div className={classes.header}>
                            <IconButton className={classes.hiddenIcon}>
                                <CloseIcon />
                            </IconButton>

                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>
                                    {content?.update ? 'Editar regra de enquadramento' : 'Nova regra de enquadramento'}

                                </Typography>
                            </div>


                            <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>
                        <div className={classes.main}>

                            <Grid container spacing={2}>

                                <Grid item xs={12} lg={12} md={12}>

                                    <MyAutoComplete
                                        onChange={(event, newValue) => {
                                            if (newValue)
                                                addArticleRuleList(newValue);
                                        }}
                                        options={articles}
                                        autoComplete
                                        label={'Selecione o artigo a ser adicionado na regra'}
                                        getOptionLabel={(option) => getLabelAutocomplete(option)}
                                        renderOption={(option) => (
                                            <React.Fragment key={'autocomplete-article-' + option.id}>
                                                <span className={classes.textAutoComplete}>{getLabelAutocomplete(option)}</span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                                    />
                                </Grid>

                                {
                                    articlesRuleList?.length ?
                                        <Grid item xs={12} lg={12} md={12} style={{ padding: '0px' }}>
                                            <MyTable
                                                size="small"
                                                heightAuto>

                                                <colgroup>
                                                    <col width="70%" />
                                                    <col width="20%" />
                                                    <col width="10%" />
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            {'Dispositivo'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {'Art.'}
                                                        </TableCell>
                                                        <TableCell align="center">

                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {articlesRuleList.map((row, index) => (
                                                        <TableRow key={'articlesRuleList-' + row.id}>
                                                            <TableCell align="left">
                                                                {row.asset}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row.device_abbreviation}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <IconButton onClick={() => removeArticleFromList(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </MyTable>
                                        </Grid> : null
                                }



                                <Grid item xs={12} lg={12} md={12}>

                                    <MyTextField
                                        value={inputName}
                                        onChange={(value) => setInputName(value)}
                                        outerlabel={'Nome da regra'}
                                        validator={inputName == "" && submitted ? true : false}
                                        placeholder="Insira o nome da regra"
                                        helperText="O nome da Regra é obrigatório"
                                        fullWidth />
                                </Grid>


                                <Grid item lg={4} md={4} sm={12} xs={12}>

                                    <MyTextField
                                        labelclassname={classes.noMarginTop}
                                        value={inputLimitSegment}
                                        onChange={(value) => setInputLimitSegment(value)}
                                        variant="outlined"
                                        margin="dense"
                                        align='right'
                                        outerlabel={'Limite Res.'}
                                        validator={inputLimitSegment == "" && submitted ? true : false}
                                        helperText="O limite deve ser preenchido"
                                        type="text"
                                        endadornment={<InputAdornment position="end">%</InputAdornment>}
                                        mask={['onlyNumbers']}
                                        fullWidth />
                                </Grid>

                                {/* PRE-GESTAO */}
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        labelclassname={classes.noMarginTop}
                                        onChange={setInputPGI}
                                        value={inputPGI}
                                        variant="outlined"
                                        margin="dense"
                                        outerlabel={'Lim. PG I'}
                                        type="text"
                                        placeholder={'0'}
                                        endadornment={<InputAdornment position="end">%</InputAdornment>}
                                        mask={['onlyNumbers']}
                                        fullWidth />
                                </Grid>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        labelclassname={classes.noMarginTop}
                                        onChange={setInputPGII}
                                        value={inputPGII}
                                        variant="outlined"
                                        margin="dense"
                                        outerlabel={'Lim. PG II'}
                                        type="text"
                                        placeholder={'0'}
                                        endadornment={<InputAdornment position="end">%</InputAdornment>}
                                        mask={['onlyNumbers']}
                                        fullWidth />
                                </Grid>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        labelclassname={classes.noMarginTop}
                                        onChange={setInputPGIII}
                                        value={inputPGIII}
                                        variant="outlined"
                                        margin="dense"
                                        outerlabel={'Lim. PG III'}
                                        type="text"
                                        placeholder={'0'}
                                        endadornment={<InputAdornment position="end">%</InputAdornment>}
                                        mask={['onlyNumbers']}
                                        fullWidth />
                                </Grid>
                                <Grid item lg={2} md={2} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        labelclassname={classes.noMarginTop}
                                        onChange={setInputPGIV}
                                        value={inputPGIV}
                                        variant="outlined"
                                        margin="dense"
                                        outerlabel={'Lim. PG IV'}
                                        type="text"
                                        placeholder={'0'}
                                        endadornment={<InputAdornment position="end">%</InputAdornment>}
                                        mask={['onlyNumbers']}
                                        fullWidth />
                                </Grid>

                                <Grid item xs={12} lg={12} md={12}>

                                    <MyTextField
                                        outerlabel={"Descrição"}
                                        value={inputDescription}
                                        multiline={true}
                                        rows={5}
                                        rowsMax={5}
                                        onChange={(value) => setInputDescription(value)}
                                    />

                                </Grid>

                            </Grid>

                            <br />
                            <div className={classes.bottom}>


                                {
                                    validationMessage != '' ?
                                        <Typography className={classes.validationMessage}>
                                            {validationMessage}
                                        </Typography>
                                        : null

                                }

                                <div className={'bottomButtons'}>

                                    {
                                        content?.update ?
                                            <UnoButton
                                                onClick={() => setConfirmingAction(true)}
                                                type='errorClean'>
                                                {"Excluir regra"}
                                            </UnoButton>
                                            : <div></div>
                                    }

                                    {
                                        content?.update ?
                                            <UnoButton
                                                type={'success'}
                                                float={'right'}
                                                onClick={() => doSaveArticleRule(true)}>
                                                {'Atualizar'}
                                            </UnoButton>
                                            :
                                            <UnoButton
                                                type={'primary'}
                                                float={'right'}
                                                onClick={() => doSaveArticleRule()}>
                                                {'Salvar'}
                                            </UnoButton>

                                    }
                                </div>

                            </div>


                        </div>
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                        essa regra?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }
            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }
        </div>
    );
}