import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';


import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//API
import { updateClientClosedMonth } from '../../../API/client'

import AddIcon from '@material-ui/icons/Add';

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

//UnoButton
import UnoButton from '../../utils/UnoButton'
import clsx from 'clsx';
import { unoTheme, tabs, headerSearch } from '../../../assets/styles/unoTheme'

//Import excel
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';
import PublishIcon from '@material-ui/icons/Publish';
import PrintIcon from '@material-ui/icons/Print';

import BalancesValidationScreen from "./BalancesValidationScreen";
import BalancesCollectScreen from "./BalancesCollectScreen";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment'
import { getMonthName } from "../../utils/utils";

import { MyTabs, MyTab } from "../../utils/MyTabs";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: '14px',
        padding: '4px 8px',
    },
    arrow: {
        color: theme.palette.common.white,
        webkitFilter: 'drop-shadow(0px 2px 2px rgba(130,130,130,1))',
        filter: 'drop-shadow(0px 2px 2px rgba(130, 130, 130, 1))',
        msFilter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')",
        filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=2, Color='#444')",
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

        '&:hover': {
            color: 'white',
            background: unoTheme.mainColor,
            '& > *': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                }

            }
        }

    },
    tabs: tabs,
    bottomTabs: {
        height: '24px',
        //minHeight: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    contentTabs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rightTabs: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2),
    },

    speedDial: {
        position: 'absolute',
        bottom: '90px',
        right: theme.spacing(4),
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column',

        '& .MuiFab-primary': {
            backgroundColor: unoTheme.mainColor,
            '&:hover': {
                background: unoTheme.hoverMainButtonColor,
            }
        },

        '& .MuiSpeedDialAction-staticTooltipLabel': {
            padding: '4px 8px',
            whiteSpace: 'nowrap',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '14px'
        }

    },

}));

export default function TransactionsScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const clientOn = useSelector(state => state.clientOn)

    const [tabValue, setTabValue] = React.useState(0);

    const inputFile = useRef(null);
    const [resetImportVariables, setResetImportVariables] = React.useState(false);
    const [fileToImport, setFileToImport] = React.useState(null);

    //To new transaction
    const [allFunds, setAllFunds] = useState(undefined)
    const [clientFunds, setClientFunds] = useState(undefined)
    const [clientAccounts, setClientAccounts] = useState(undefined)
    const [clientAssets, setClientAssets] = useState(undefined)
    const [clientOperations, setClientOperations] = useState(undefined)
    const [assetsFormatted, setAssetsFormatted] = useState(undefined)

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push('/transactions/balancesCollect')
        } else if (newValue == 1) {
            history.push('/transactions/balancesValidation')
        }

    };

    function handleChangeFile(e) {

        console.log("Change file")
        const files = e.target.files;
        if (files && files[0]) {
            setFileToImport(files[0])
        }

        e.target.value = null;

    };

    function resetFileToImport() {
        setFileToImport(null);
    }

    function cleanResetImportVariables() {
        setResetImportVariables(false);
    }

    async function launchWallet() {

        //Checar se o último mês validado é mesmo o 
        //anterior a esse que está tentando-se fechar portfolio_closed

        console.log("CLOSED MONTH: ", clientOn.client.portfolio_closed)
        let splittedClosedMonth = null
        let nextMonth = 0
        let nextYear = 0

        if (clientOn.client.portfolio_closed) {
            splittedClosedMonth = clientOn.client.portfolio_closed.split("/")
        } else {
            splittedClosedMonth = moment.utc(clientOn.client.portfolio_init).format("MM/YYYY").split("/")
        }

        nextMonth = parseInt(splittedClosedMonth[0]) + 1
        nextYear = parseInt(splittedClosedMonth[1])

        //No caso do mês ser 12
        if (nextMonth == 13) {
            nextMonth = 1;
            nextYear++;
        }

        if (clientOn.client.selectedMonth != nextMonth
            || clientOn.client.selectedYear != nextYear) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'A próxima carteira a ser fechada deve ser a do mês ' + getMonthName(nextMonth) + "/" + nextYear
            }))

        } else {

            let formattedMonth = clientOn.client.selectedMonth + "/" + clientOn.client.selectedYear

            //Verificar se os ativos do mês possuem seus saldos validados e/ou conciliados            
            let allConc = true;
            assetsFormatted.forEach(element => {
                console.log("ELEMENTE ASSET: ", element);

                //Caso tenha elementos segregados deve verificar os saldos de cada elemento
                if (element.subAssets) {
                    element.subAssets.forEach(elementSegregated => {

                        if (elementSegregated.balance_before + elementSegregated.resultAmount != elementSegregated.balance_now) {
                            // console.log("///////////////////")
                            // console.log(elementSegregated);
                            // console.log("balance_before+resultAmount: ", elementSegregated.balance_before + elementSegregated.resultAmount)
                            // console.log("é diferente do: ", elementSegregated.balance_now);
                            if (!elementSegregated.balance_now_conc) {
                                //console.log("E não tem balance_conc: ", elementSegregated.balance_now_conc);
                                allConc = false;
                            }

                        }

                    });

                } else {
                    if (element.balance_before + element.resultAmount != element.balance_now) {
                        // console.log("///////////////////")
                        // console.log(element);
                        // console.log("balance_before+resultAmount: ", element.balance_before + element.resultAmount)
                        // console.log("é diferente do: ", element.balance_now);
                        if (!element.balance_now_conc) {
                            //console.log("E não tem balance_conc: ", element.balance_now_conc);
                            allConc = false;
                        }

                    }
                }

                console.log("ALL CONC: ", allConc)
            });

            if (allConc) {

                //Verificar se os saldos validados são iguais ao saldo sensibilizado
                console.log("FECHAR: ", formattedMonth)
                let response = await updateClientClosedMonth(clientOn.client.id,
                    formattedMonth)
                if (response.success) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: 'Carteira de [ ' + getMonthName(formattedMonth) + '/' + clientOn.client.selectedYear + ' ] lançada com sucesso!'
                    }))

                    clientOn.client.portfolio_closed = formattedMonth;
                    //Atualizar datas dos selects de data da Dashboard
                    clientOn.client.updateInfosPortfolio = true;

                    dispatch(allActions.clientActions.setClient(clientOn.client))

                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: 'Erro ao fechar carteira de ' + formattedMonth
                    }))
                }
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: 'Os saldos de todos os ativos devem estar validados e/ou conciliados'
                }))
            }

        }



    }

    //SpeedDial
    const [direction, setDirection] = React.useState('up');
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);

    const handleDirectionChange = (event) => {
        setDirection(event.target.value);
    };

    const handleHiddenChange = (event) => {
        setHidden(event.target.checked);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={classes.root}>

            <div className={classes.tabs}>

                <div className={classes.contentTabs}>
                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        <MyTab label="Coleta de Saldos" />
                        <MyTab label="Validação de Saldos" />

                    </MyTabs>

                    {

                        clientOn && clientOn.client &&
                            location.pathname == '/transactions/balancesCollect' ?
                            <div className={classes.rightTabs}>
                                <UnoButton
                                    className={classes.spacingLeft1}
                                    onClick={() => launchWallet()}
                                    type='success'
                                    startIcon={<AccountBalanceWalletIcon />}>
                                    {"Lançar Carteira"}
                                </UnoButton>
                            </div>
                            : null
                    }

                </div>

                {/* <div className={classes.bottomTabs}></div> */}

            </div>


            <Switch className={classes.switch}>
                <Route path="/transactions/balancesCollect">
                    <BalancesCollectScreen
                        cleanResetImportVariables={cleanResetImportVariables}
                        doResetImportVariables={resetImportVariables}
                        fileToImport={fileToImport}
                        resetFileToImport={resetFileToImport}
                        fillAllFunds={(funds) => setAllFunds(funds)}
                        fillClientFunds={(funds) => setClientFunds(funds)}
                        fillClientAccounts={(accounts) => setClientAccounts(accounts)}
                        fillClientAssets={(assets) => setClientAssets(assets)}
                        fillClientOperations={(operations) => setClientOperations(operations)}
                        fillAssetsFormatted={(assetsFormatted) => setAssetsFormatted(assetsFormatted)} />
                </Route>
                <Route path="/transactions/balancesValidation">
                    <BalancesValidationScreen />
                </Route>
            </Switch>

            {

                clientOn && clientOn.client && tabValue == 0 ?
                    <>
                        <div className={classes.speedDial}>
                            <SpeedDial
                                ariaLabel="SpeedDial example"
                                hidden={hidden}
                                icon={<SpeedDialIcon />}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                open={open}
                                direction={direction}>

                                <SpeedDialAction
                                    htmlFor="file"
                                    key={'import-fab'}
                                    icon={<PublishIcon />}
                                    tooltipOpen
                                    tooltipTitle={'Importar movimentações'}
                                    onClick={() => inputFile.current.click()}
                                />

                                <SpeedDialAction
                                    key={'print-fab'}
                                    icon={<PrintIcon />}
                                    tooltipOpen
                                    tooltipTitle={'Imprimir relatório'}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newAsset',
                                        data: {
                                            allFunds: allFunds,
                                            accounts: clientAccounts,
                                        }
                                    }))}
                                />

                                <SpeedDialAction
                                    key={'asset-create-fab'}
                                    icon={<AddIcon />}
                                    tooltipOpen
                                    tooltipTitle={'Cadastrar ativo'}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newAsset',
                                        data: {
                                            allFunds: allFunds,
                                            accounts: clientAccounts,
                                        }
                                    }))}
                                />

                                <SpeedDialAction
                                    key={'transaction-create-fab'}
                                    icon={<AddIcon />}
                                    tooltipOpen
                                    tooltipTitle={'Cadastrar movimentação'}
                                    onClick={() => {

                                        if (clientFunds && clientFunds.length > 0) {
                                            dispatch(allActions.rightContentActions.setRightContent({
                                                path: 'newTransaction',
                                                data: {
                                                    accounts: clientAccounts,
                                                    funds: clientFunds,
                                                    assets: clientAssets,
                                                    operations: clientOperations,
                                                }
                                            }))
                                        } else {
                                            dispatch(allActions.mainAlertActions.setMainAlert({
                                                show: true,
                                                type: 'warning',
                                                message: 'Para criar uma movimentação você precisa primeiro criar um ATIVO',
                                            }))
                                        }

                                    }}
                                />

                            </SpeedDial>



                            {/* <div>
                                <input
                                    ref={inputFile}
                                    className={classes.input}
                                    id="file"
                                    multiple
                                    accept={SheetJSFT}
                                    type="file"
                                    onClick={() => setResetImportVariables(true)}
                                    onChange={handleChangeFile}
                                />
                                <label htmlFor="file">
                                    <Zoom
                                        style={{ marginTop: '16px' }}
                                        in={true}
                                        unmountOnExit>
                                        <CustomTooltip
                                            title={"Importar movimentações"}
                                            placement="left">
                                            <Fab className={[classes.fabAdd].join(' ')}
                                                color="primary" aria-label="add"
                                                onClick={() => inputFile.current.click()}>
                                                <PublishIcon />
                                            </Fab>
                                        </CustomTooltip>
                                    </Zoom>
                                </label>
                            </div> */}

                        </div>
                    </> : null

            }

            <div>
                <input
                    ref={inputFile}
                    className={classes.input}
                    id="file"
                    multiple
                    accept={SheetJSFT}
                    type="file"
                    onClick={() => setResetImportVariables(true)}
                    onChange={handleChangeFile}
                />
            </div>

        </div>
    );
}



