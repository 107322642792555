export const roles = {
  admin: {
    id: 1,
    title: 'ROLE_ADMIN',
  },
  advisor: {
    id: 2,
    title: 'ROLE_ADVISOR'
  },
  manager: {
    id: 3,
    title: 'ROLE_MANAGER'
  },
  submanager: {
    id: 4,
    title: 'ROLE_SUBMANAGER'
  },
  viewer: {
    id: 5,
    title: 'ROLE_VIEWER'
  },
  superadmin: {
    id: 999,
    title: 'ROLE_SUPER_ADMIN'
  }
};

export const rolesSortById = Object.values(roles);

/**
 * 
 * @param {{
 *   user: {
 *     role: string;
 * }
 * }} currentUser 
 * @param {string[]} roles 
 * @returns 
 */
export function checkUserIsRole(currentUser, roles) {

  if (currentUser && currentUser.user && roles) {
    return roles.some(el => el.title == currentUser.user.role)
  } else { return false }

}