/**
* Retorna os tipos de regime de conta do sistema
* @param {Boolea} segregated parâmetro que indica se há ou não segregação de carteira
* @return {Array} Array com os tipos de regime
*/
export function getRegimeTypes(segregated) {

    const regimeType1 = 'Consolidado';
    const regimeType2 = 'Previdenciário';
    const regimeType3 = 'Financeiro';
    const regimeType4 = 'Taxa Adm.';

    if (segregated) {
        return [
            regimeType1,
            regimeType2,
            regimeType3,
            regimeType4
        ]
    } else {

        //No caso de não segregado não é enviado o type3
        return [
            regimeType1,
            regimeType2,
            regimeType4,
        ]
    }
}