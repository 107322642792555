import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
//UnoButton
import UnoButton from '../../utils/UnoButton'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(6),
    },

    buttons: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    }

}));

export default function ConfirmActionRightContent(props) {

    const classes = useStyles();

    const { message,
        handleCancel,
        handleConfirm } = props;

    useEffect(() => {

    }, [])

    return (
        <div className={classes.root}>

            <Typography variant={'h6'}>
                {message}
            </Typography>

            <div className={classes.buttons}>
                <UnoButton
                    onClick={handleCancel}
                    type='errorClean'>
                    {'Cancelar'}
                </UnoButton>


                <UnoButton
                    onClick={handleConfirm}
                    type='primary'>
                    {'Confirmar'}
                </UnoButton>
            </div>

        </div>
    );
}



