import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme } from "../../../assets/styles/unoTheme";

//AutoComplete
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import {
    applyMaskAmount,
    parseFloatByPtCurrency,
    isDateAfterClosedMonth,
    applyMaskAccount
} from "../../utils/utils";

import InputAdornment from '@material-ui/core/InputAdornment';

//Api
import {
    createAsset,
    updateAsset,
    deleteAsset,
} from '../../../API/fund'

import {
    getAccountById,
    listClientAccountsByRegime
} from '../../../API/account'

//MyInputs
import MyTextField from "../../utils/MyTextField";
import MySelect from '../../utils/MySelect'
import MyAutoComplete from '../../utils/MyAutoComplete'
import MyDatePicker from '../../utils/MyDatePicker'

import { checkAssetIsLaunched } from '../../../controllers/AssetsController'

import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import moment from 'moment'
import MyInfo from "../../utils/MyInfo";

import ConfirmActionRightContent from './ConfirmActionRightContent'

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        height: 'calc(100vh - 149px)',
        overflowY: 'scroll',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px',
            },
        },
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '12px',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    warning: {
        color: unoTheme.warningColor
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));

export default function NewAsset(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { content } = props

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);

    //cadastrar ativo    
    const [inputAssetToCreate, setInputAssetToCreate] = useState(undefined);
    const [inputAccountNewAsset, setInputAccountNewAsset] = React.useState(undefined);
    const [inputInitDateAssetToCreate, setInputInitDateAssetToCreate] = React.useState(new Date());
    const [inputBalanceAssetToCreate, setInputBalanceAssetToCreate] = useState("");
    const [disableBalance, setDisableBalance] = useState(false);

    //atualizar ativos
    const [clientAccounts, setClientAccounts] = useState([]);
    //Para indicar se o ativo já foi lançado alguma vez na carteira do cliente
    //Caso já tenha sido lançado o ativo não pode ser editado
    const [alreadyLaunched, setAlreadyLaunched] = useState(true);
    const [accountToShow, setAccountToShow] = useState(true);

    function handleInitDateAsset(value) {

        setInputInitDateAssetToCreate(value)

        console.log("Value: ", value);
        if (moment.utc(value).format('MM/YYYY')
            == moment.utc(clientOn.client.portfolio_init).format('MM/YYYY')) {
            setDisableBalance(false);
        } else {
            setDisableBalance(true);
        }
    }

    async function doSaveClientAsset() {

        setValidationMessage('')
        setOpenBackdrop(true)

        if (inputAccountNewAsset == undefined) {

            setValidationMessage('Uma conta deve ser selecionada')
            setOpenBackdrop(false)

        }

        let dateFormatted = moment.utc(inputInitDateAssetToCreate).format("DD/MM/YYYY");
        if (isDateAfterClosedMonth(clientOn.client.portfolio_init,
            dateFormatted)) {

            setOpenBackdrop(false);
            setValidationMessage('O ativo não pode ser criado para uma data anterior ao início da carteira (' + moment.utc(clientOn.client.portfolio_init).format('MM/YYYY') + ')');

        } else if (
            !isDateAfterClosedMonth(dateFormatted,
                clientOn.client.portfolio_closed, true)
            && (currentUser && currentUser.user // ou o usuário seja ADMIN
                && (currentUser.user.role != "ROLE_ADMIN"))
        ) {

            setOpenBackdrop(false);
            setValidationMessage('A carteira já foi fechada para a data em que se deseja criar o ativo, entre em contato com o suporte!');

        } else {

            let asset = {
                account_id: clientAccounts[inputAccountNewAsset].id,
                fund_id: inputAssetToCreate.id,
                asset_init: dateFormatted,
                balance: parseFloatByPtCurrency(inputBalanceAssetToCreate),
                quota_amount: 0,

                //Caso não tenha alterado a conta carteira o asset pode passar direto sem precisar testar se o ativo já existe no banco
                same_account: clientAccounts[inputAccountNewAsset].id == content.data.selectedAccountId,

                //Propriedade que indica que deve ser criado um saldo anterior para esse ativo com o valor informado no balance;
                //Caso seja um ativo criado num mês diferente do início da carteira
                isTransaction: disableBalance,
                balanceSameInit: disableBalance, //Flag utilizada para indicar que o ativo deve ser criado com saldo zero pra própria data informada sem retrair um mês 
                //como no caso de um asset criado a partir da importação de uma movimentação que surge no meio do ano sem a criação prévia do ativo
            }

            console.log("ASSET TO CREATE: ", asset);

            if (content.data.update) {

                //Adicionando ID do asset para ser atualizado
                asset.id = content.data.assetId;

                let response = await updateAsset(asset);
                if (response.success) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: 'Ativo atualizado com sucesso!'
                    }));
                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: response.error.message
                    }));
                }

            } else {

                let response = await createAsset(asset);
                if (response.success) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: 'Ativo cadastrado com sucesso!'
                    }));
                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: response.error.message
                    }));
                }
            }


            setOpenBackdrop(false)
            dispatch(allActions.rightContentActions.update())
        }

    }

    async function doDeleteClientAsset() {

        setValidationMessage('')
        setOpenBackdrop(true)

        //console.log("CONTENT DATA: ", content.data.assetId);
        let response = await deleteAsset(content.data.assetId);
        if (response.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Ativo excluído com sucesso!'
            }));
        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: response.error.message
            }));
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update())
    }

    async function loadInfosUpdate(data, clientId, portfolioClosed) {

        setInputAssetToCreate({ id: data.fundId });

        console.log("ASSET INIT TO UPDATE: ", data.assetInit);
        console.log("ASSET INIT TO UPDATE: ", moment.utc(data.assetInit).format("DD/MM/YYYY"));
        handleInitDateAsset(data.assetInit);
        //setInputInitDateAssetToCreate(data.assetInit);
        setInputBalanceAssetToCreate(applyMaskAmount(data.balanceInit));

        let alreadyLaunchedLocal = checkAssetIsLaunched(data.assetInit, portfolioClosed);
        setAlreadyLaunched(alreadyLaunchedLocal);

        console.log("ALREADY LAUNCHED: ", alreadyLaunchedLocal);
        if (!alreadyLaunchedLocal // só é permitida a edição de conta caso ainda não tenha sido lançada a carteira 
            || (currentUser && currentUser.user // ou o usuário seja ADMIN
                && (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_SUPER_ADMIN"))) {

            const response = await listClientAccountsByRegime(clientId, null);

            console.log("RESPONSE: ", response)

            if (response.success) {

                setClientAccounts(response.body.rows)
                for (let i = 0; i < response.body.rows.length; i++) {

                    if (response.body.rows[i].id == data.selectedAccountId) {
                        setInputAccountNewAsset(i);
                        break;
                    }

                }

            } else {

                console.log("error: ", response.error)

            }

            //Caso seja um usuário manager ele pode acessar apenas a conta por extenso 
            if ((currentUser && currentUser.user
                && (currentUser.user.role == "ROLE_MANAGER" && currentUser.user.role == "ROLE_SUBMANAGER"))) {
                const response = await getAccountById(data.selectedAccountId);
                if (response.success) {
                    console.log("BODY ACCOUNT: ", response.body)
                    setAccountToShow(response.body)

                } else {
                    console.log("error: ", response.error)
                }
            }

        } else {
            const response = await getAccountById(data.selectedAccountId);
            if (response.success) {
                console.log("BODY ACCOUNT: ", response.body)
                //setAccountToShow(response.body.description + ' - Ag: ' + response.body.agency + ' - cc: ' + response.body.number_account)
                setAccountToShow(response.body)

            } else {
                console.log("error: ", response.error)
            }

        }



    }

    //deleção da conta
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteClientAsset();
    }

    useEffect(() => {

        console.log("DATA NEW ASSET: ", content)

        //Caso de update de Asset (Asset só pode ser alterado caso ainda não tenha movimentações cadastrdas para ele)
        if (content
            && content.data
            && content.data.update
            && clientOn && clientOn.client) {

            //Iniciar seletor de conta com a atual conta selecionada [content.data.selectedAccountId]  
            loadInfosUpdate(content.data, clientOn.client.id, clientOn.client.portfolio_closed);

        } else {

            setInputAssetToCreate(content.data.allFunds[0]);
            setClientAccounts(content.data.accounts);

            if (clientOn.client.portfolio_closed) {

                let splittedDate = clientOn.client.portfolio_closed.split('/');
                //setInputInitDateAssetToCreate(new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]) - 1, 1, 0, 0, 0, 0))

                //Criando a data pro mês de lançamento
                handleInitDateAsset(new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]) - 1, 1, 0, 0, 0, 0))
                //setInputInitDateAssetToCreate(new Date(parseInt(splittedDate[1]), parseInt(splittedDate[0]), 1, 0, 0, 0, 0))

            } else {

                handleInitDateAsset(moment.utc(clientOn.client.portfolio_init))
                //setInputInitDateAssetToCreate(moment.utc(clientOn.client.portfolio_init))

            }
        }

    }, [])

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?
                    <>
                        {content && content.data && content.data.update ?

                            <div style={{ height: '24px' }}></div>

                            :

                            <div className={classes.header}>

                                <IconButton className={content && content.data && content.data.update ? "" : classes.hiddenIcon}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent(content.data.dataToBack))}>
                                    <ArrowBackIcon />
                                </IconButton>

                                <div>
                                    <Typography
                                        variant={'h6'}
                                        align={'left'}>
                                        {content && content.data && content.data.update ? "Editar Ativo" : "Cadastrar Ativo"}

                                    </Typography>
                                    {/* <Typography
                            align={'left'}
                            className={classes.labelCellBottom}>
                            {content && content.data ? content.data.fund_name : ""}
                        </Typography> */}
                                </div>


                                <IconButton
                                    className={content && content.data && content.data.update ? classes.hiddenIcon : ""}
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                    <CloseIcon />
                                </IconButton>

                            </div>
                        }

                        <div className={classes.main}>

                            {/* {!creatingAsset ?
                    <Typography variant={'body1'}>
                        {'Você ainda não possui nenhum Ativo criado, crie o ativo selecionando-o abaixo e em seguida clicando no botão "Salvar Ativo". Você pode procurar o fundo pelo CNPJ e pelo nome.'}
                    </Typography>
                    : null
                } */}

                            <Grid container spacing={2}>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    {
                                        (content.data && content.data.allFunds && content.data.allFunds.length > 0) ?


                                            <MyAutoComplete
                                                outerlabel={'FUNDO'}
                                                onChange={(event, newValue) => {
                                                    console.log("New value: ", newValue)
                                                    setInputAssetToCreate(newValue);
                                                }}
                                                //normallayout={'true'}
                                                options={content.data.allFunds}
                                                autoComplete
                                                value={inputAssetToCreate ? inputAssetToCreate : null}
                                                //includeInputInList
                                                getOptionLabel={(option) => option.fund_name + " - " + option.cnpj}
                                                renderOption={(option) => (
                                                    <React.Fragment key={'autocomplete-' + option.id}>
                                                        <span className={classes.textAutoComplete}>{option.cnpj}<span>{" - "}</span><span className={classes.bold}>{option.fund_name}</span></span>
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                                            />
                                            :
                                            <MyInfo
                                                outerlabel={'FUNDO'}
                                                label={content && content.data ? content.data.fundName : ''}>

                                            </MyInfo>

                                    }
                                </Grid>


                                {
                                    content
                                        && content.data
                                        && content.data.update
                                        && alreadyLaunched
                                        && (currentUser && currentUser.user
                                            && (currentUser.user.role != "ROLE_ADMIN"))
                                        || (currentUser && currentUser.user
                                            && (currentUser.user.role == "ROLE_MANAGER" && currentUser.user.role == "ROLE_SUBMANAGER")) ?

                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <MyInfo
                                                        outerlabel={'Instituição (CC)'}
                                                        label={accountToShow.description}>

                                                    </MyInfo>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <MyInfo
                                                        align={'right'}
                                                        outerlabel={'Agência'}
                                                        label={accountToShow.agency ? accountToShow.agency : "-"}>

                                                    </MyInfo>
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                                    <MyInfo
                                                        align={'right'}
                                                        outerlabel={'CC'}
                                                        label={accountToShow.number_account ? applyMaskAccount(accountToShow.number_account) : null}>

                                                    </MyInfo>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        :

                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <MySelect
                                                outerlabel={'Conta'}
                                                value={inputAccountNewAsset}
                                                initblank={'true'}
                                                onChange={(e) => setInputAccountNewAsset(e.target.value)}
                                                options={clientAccounts.map((row, index) => (
                                                    <option key={'account-new-transaction-' + row.id} value={index}>
                                                        {row.description + (row.agency ? ' - Ag: ' + row.agency : "") + ' - CC: ' + row.number_account}
                                                    </option>
                                                ))}

                                            />
                                        </Grid>
                                }



                                <Grid item lg={6} md={6} sm={12} xs={12} className={classes.alignLeft}>
                                    <MyDatePicker
                                        disabled={content
                                            && content.data
                                            && content.data.update
                                            && alreadyLaunched
                                            || (currentUser && currentUser.user
                                                && (currentUser.user.role == "ROLE_MANAGER" && currentUser.user.role == "ROLE_SUBMANAGER"))}
                                        className={classes.myDatePicker}
                                        margin="dense"
                                        views={["month", "year"]}
                                        outerlabel="Início do ativo"
                                        format="MM/YYYY"
                                        value={inputInitDateAssetToCreate}
                                        onChange={(value) => handleInitDateAsset(value)}
                                        KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                                </Grid>

                                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <MyTextField
                                        align={'right'}
                                        startadornment={<InputAdornment position="start">R$</InputAdornment>}
                                        size={'small'}
                                        outerlabel="Saldo (primeiro cadastro)"
                                        outerlabelright={'true'}
                                        placeholder="0,00"
                                        disabled={(content
                                            && content.data
                                            && content.data.update
                                            && alreadyLaunched) || disableBalance || (currentUser && currentUser.user
                                                && currentUser.user.role == "ROLE_MANAGER")} // disableBalance no caso de a data de inicio do ativo ser diferente da data de inicio da carteira
                                        // a data sendo igual a do inicio da carteira é possível criar o saldo incial
                                        value={inputBalanceAssetToCreate}
                                        variant="outlined"
                                        onChange={(value) => setInputBalanceAssetToCreate(value)}
                                        mask={["currency"]} />

                                </Grid> */}

                                {content.data && content.data.update ?
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography style={{ color: unoTheme.defaultTextColor }} variant={'body1'}>
                                            {'A edição do ATIVO só é permitida no caso em que ainda não foi fechada nenhuma carteira que o contenha, assim como nenhuma movimentação relacionada a ele'}
                                        </Typography>
                                    </Grid>
                                    : null
                                }
                            </Grid>

                            <br />

                            {
                                currentUser && currentUser.user
                                    && (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_SUPER_ADMIN" || currentUser.user.role == "ROLE_ADVISOR") ?
                                    <div className={classes.bottom}>

                                        {
                                            validationMessage != '' ?
                                                <Typography className={classes.validationMessage}>
                                                    {validationMessage}
                                                </Typography>
                                                : null

                                        }

                                        {

                                            content.data && content.data.update ?
                                                <>
                                                    {
                                                        content
                                                            && content.data
                                                            && content.data.update
                                                            && (
                                                                !alreadyLaunched
                                                                ||
                                                                (
                                                                    alreadyLaunched
                                                                    &&
                                                                    currentUser && currentUser.user
                                                                    &&
                                                                    (currentUser.user.role == "ROLE_ADMIN" || currentUser.user.role == "ROLE_SUPER_ADMIN")
                                                                )
                                                            ) ?

                                                            <div className={'bottomButtons'}>

                                                                {
                                                                    !alreadyLaunched ?
                                                                        <UnoButton
                                                                            onClick={() => setConfirmingAction(true)}
                                                                            type='errorClean'>
                                                                            {"Excluir ativo"}
                                                                        </UnoButton>
                                                                        : null
                                                                }

                                                                <UnoButton
                                                                    onClick={() => doSaveClientAsset()}
                                                                    type='success'>
                                                                    {"Atualizar Ativo"}
                                                                </UnoButton>
                                                            </div>
                                                            : null

                                                    }

                                                </>
                                                :

                                                <UnoButton
                                                    onClick={() => doSaveClientAsset()}
                                                    type='primary'>
                                                    {"Salvar Ativo"}
                                                </UnoButton>
                                        }


                                    </div>
                                    : null
                            }


                        </div>

                        {
                            openBackdrop ?
                                <div className={classes.rightDivBackdrop}>
                                    <CircularProgress className={'progress'} />
                                </div> : null
                        }
                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                        esse ativo?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />
            }

        </div >
    );
}



