export const MathUtils = {

    variance(arr) {
        if (!arr.length) {
            return 0;
        };
        let outs = 0; //Para ser desconsiderado quando o valor do indicador for 0
        const sum = arr.reduce((acc, val) => acc + val);
        const { length: num } = arr;
        const median = sum / num;
        let variance = 0;
        arr.forEach(num => {
            if (num) {
                variance += ((num - median) * (num - median));
            } else {
                outs++;
            }

        });
        variance /= (num - outs);
        return variance;
    },

    mean(arr, n) {

        let sum = 0;
        let outs = 0; //Para ser desconsiderado quando o valor do indicador for 0
        for (let i = 0; i < n; i++) {

            sum = sum + arr[i];
            if (arr[i] == 0) outs++
        }

        console.log("Outs mean: ", outs);

        return sum / (n - outs);

    },

    covariance(arr1, arr2, n) {
        console.log('arr1: ', arr1);
        console.log('arr2: ', arr2);
        console.log('n: ', n);
        let sum = 0;
        const mean_arr1 = this.mean(arr1, n);
        console.log('mean_arr1: ', mean_arr1)
        const mean_arr2 = this.mean(arr2, n);
        console.log('mean_arr2: ', mean_arr2)

        let outs = 0; //Para ser desconsiderado quando o valor do indicador for 0
        for (let i = 0; i < n; i++) {

            if (arr2[i]) {
                sum = sum + (arr1[i] - mean_arr1) *
                    (arr2[i] - mean_arr2);
            } else {
                outs++;
            }
        }

        console.log("Outs: ", outs);

        return sum / (n - outs);
    }
}