import API from './config'

export async function saveLogAction(logAction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/log_actions/saveLogAction', logAction, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getActionsHistoryLogs(offset, limit, selectedClient) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/log_actions/actionsHistoryLogs?' +
            'offset=' + offset
            + '&limit=' + limit
            //+ '&search=' + (!search ? "" : search)
            + (selectedClient ? '&client_id=' + selectedClient : '')
            , config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getUserActionsHistoryLogs(searchInputValue) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/log_actions/userActionsHistoryLogs/' + searchInputValue, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccessHistoryLogs() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/log_actions/accessHistoryLogs', config);

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getUserAccessHistoryLogs(searchInputValue) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/log_actions/userAccessHistoryLogs/' + searchInputValue, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}