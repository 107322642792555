import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';

import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


//Redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import allActions from "../../../actions";

import moment from 'moment'

// Utils
import _, { isEmpty } from 'lodash';
import { applyMaskAmount, formatPortfolioClosed, getConsultingId, getShortMonthName } from "../../utils/utils";
import { ClickAwayListener, Grow, Menu, MenuItem, MenuList, Paper, Popper, TableFooter, Tooltip, Typography } from "@material-ui/core";
import { headerSearch, unoTheme } from "../../../assets/styles/unoTheme";

import {
    doGetStatusClientsByYear, STATUS_DPIN, STATUS_DAIR, doGetActuarysInfos
} from "../../../controllers/ClientController";

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchBar from "../../utils/SearchBar";
import { changeStatusDair, changeStatusDpin, getStatusesDair, getStatusesDpin } from "../../../API/status_dpin_dair";
import { formatStatusesByShortName } from "../../../controllers/StatusDpinDairController";
import MyAutoComplete from "../../utils/MyAutoComplete";
import { getActiveAdvisorsAndAdminsByConsulting } from "../../../API/user";
import { checkUserIsRole, roles } from "../../utils/user";
import { path } from "../../utils/routesPaths";
import { getClientById } from "../../../API/client";
import UnoButton from "../../utils/UnoButton";

import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    headerSearch: headerSearch,

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'white',
                background: unoTheme.mainColor,
            },
        },

        '& .disponibilityLine': {
            '& .MuiTableCell-footer': {
                color: 'black',
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    noHover: {
        pointerEvents: 'all !important',
        '&:hover': {
            backgroundColor: 'none !important',
        }
    },

    dpinColumn: {
        background: '#fdffe0 !important',
        borderBottom: 'solid 0.2px #efefef !important',
        '&.MuiTableCell-body': {
            '&:hover': {
                background: '#fbffc7 !important',
            }
        }

    }

}));

export default function ActuarysClientsScreen(props) {

    const { selectedYearClosure } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(state => state.currentUser)

    const [activeAdvisors, setActiveAdvisors] = useState(null);
    async function doGetActiveAdvisorsAndAdmins() {
        //const response = await getActiveAdvisorsAndAdmins();
        const response = await getActiveAdvisorsAndAdminsByConsulting(getConsultingId(currentUser));
        if (response.success) {
            //response.body.rows.unshift(TODOS_ADVISOR)
            setActiveAdvisors(response.body.rows);
        }
    }

    const [selectedAdvisor, setSelectedAdvisor] = useState(currentUser.user);
    const handleAdvisorSelectedClient = (event, newValue) => {

        setSelectedAdvisor(newValue);
    }


    const [search, setSearch] = useState("");

    const [actuarysInfos, setActuarysInfos] = useState(null);

    async function loadActuarysInfos(year, advisor, consultingId, search) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando dados atuariais...'
        }));

        setActuarysInfos(null);
        const advisorToFilter = advisor && advisor.id ? advisor.id : null;

        const response = await doGetActuarysInfos(
            year,
            advisorToFilter,
            consultingId,
            search);

        if (response.success) {

            if (response.body && response.body.length) {

                setActuarysInfos(response.body);
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                }));

            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Nenhum cliente encontrado",
                }));
            }



        } else {
            setActuarysInfos(null);

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Nenhum cliente encontrado",
            }));
        }

    }

    useEffect(() => {
        loadActuarysInfos(
            selectedYearClosure,
            selectedAdvisor,
            getConsultingId(currentUser),
            search);

    }, [selectedYearClosure, selectedAdvisor, search]);

    useEffect(() => {

        doGetActiveAdvisorsAndAdmins();
        loadActuarysInfos(
            selectedYearClosure,
            selectedAdvisor,
            getConsultingId(currentUser),
            search);

    }, []);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Informações Atuariais
                </Typography>

                <div className={classes.rightHeader}>

                    {
                        activeAdvisors ?
                            <MyAutoComplete
                                disabled={!checkUserIsRole(currentUser, [roles.superadmin])}
                                options={activeAdvisors}
                                autoComplete
                                customWidth={'420px'}
                                value={
                                    selectedAdvisor ? selectedAdvisor : null
                                }
                                onChange={(event, newValue) => {
                                    console.log("New value: ", newValue)
                                    handleAdvisorSelectedClient(event, newValue)
                                }}
                                label={'Consultor'}
                                placeholder={"Selecione o consultor"}
                                getOptionLabel={(option) => option.name.toUpperCase()}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderOption={(option) => (
                                    <React.Fragment key={'autocomplete-' + option.id}>
                                        <span>{option.name.toUpperCase()}</span>
                                    </React.Fragment>
                                )}
                            /> : <Typography variant='body1' style={{ whiteSpace: 'nowrap', marginRight: '16px' }}>{selectedAdvisor && selectedAdvisor.name.toUpperCase()}</Typography>
                    }

                </div>

            </div>
            {/* <Divider /> */}
            <div class={'defaultScrollWithHeaderContent'}>

                <MyTable
                    stickyHeader
                    size="small">
                    <colgroup>
                        <col width="20%" />
                        <col width="30%" />
                        <col width="50%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"
                                style={{ paddingLeft: '0px' }}>
                                <SearchBar
                                    name='search_query'
                                    mask='text_cnpj'
                                    size='small'
                                    spacingRight
                                    variant='outlined'
                                    label='Cliente'
                                    setSearchQuery={setSearch}
                                /></TableCell>
                            <TableCell align="left">Duration</TableCell>
                            <TableCell align="left">Atuário</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {actuarysInfos && actuarysInfos.map((row, index) => (
                            <TableRow
                                key={'actuarys-client-' + index}>
                                <TableCell
                                    align="left">{row.label_name}</TableCell>
                                <TableCell
                                    align="left">{row.liability_duration ? applyMaskAmount(row.liability_duration) : '-'}</TableCell>
                                <TableCell
                                    align="left">{row.actuary ? row.actuary : '-'}</TableCell>
                            </TableRow>
                        ))}

                    </TableBody>


                </MyTable>
            </div>

        </div >

    );
}



