import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MyTable from '../../utils/MyTable';

import IconButton from '@material-ui/core/IconButton';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import { listAllInstituitions, updateInstituition } from '../../../API/instituition';

import { headerSearch, tabs } from '../../../assets/styles/unoTheme';
import { MyTab, MyTabs } from '../../utils/MyTabs';
import UnoButton from '../../utils/UnoButton';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';
import { DistributorsAPI } from '../../../API/distributors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },
    tabs: tabs,
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginLeft: theme.spacing(2)
        },
        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
}));

export default function InstituitionsScreen() {

    const classes = useStyles();
    const rightContent = useSelector(state => state.rightContent)

    //Instituitions
    const [listInstituitions, setListInstituitions] = useState([]);
    const [itemsPageInstituitions, setItemsPageInstituitions] = useState(15);
    const [pageInstituitions, setPageInstituitions] = useState(1);

    //Distributors
    const [listDistributors, setListDistributors] = useState([]);

    const [tabValue, setTabValue] = useState(0);

    const dispatch = useDispatch();

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    async function getAllInstituitions() {

        const response = await listAllInstituitions();
        if (response.success) {
            console.log('INSTITUITIONS: ', JSON.stringify(response.body.rows));
            setListInstituitions(response.body.rows);
        } else {
            console.log("RESPONSE ERROR: ", response.error)
        }
    }

    async function updateStatusInstituition(instId, status) {

        const response = await updateInstituition(instId, status);
        if (response.success) {
            //setListInstituitions(response.body.rows);
            console.log(response.body)
            callback();
        } else {
            console.log("RESPONSE ERROR: ", response.error)
        }

    }

    const callback = useCallback(() => {
        getAllInstituitions();
    }, [])

    const getAllDistributors = async () => {

        const response = await DistributorsAPI.getAll();
        console.log("response: ", response);
        if (response.success) {
            const { rows } = response.body;
            setListDistributors(rows);
        }

    }

    useEffect(() => {

        if (tabValue === 0) {
            getAllInstituitions();
        } else if (tabValue === 1) {
            getAllDistributors();
        }

    }, [tabValue]);

    useEffect(() => {
        if (rightContent && rightContent.action) {
            dispatch(allActions.rightContentActions.setRightContent(null))
            if (tabValue === 0) {
                getAllInstituitions();
            } else if (tabValue === 1) {
                getAllDistributors();
            }
        }
    }, [rightContent])

    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(e.target.value.toUpperCase())
        clearTimeout(delayedChange);
    }

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={classes.tabs}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                    >

                        <MyTab label={"Instituições financeiras"} value={0} />
                        <MyTab label={"Distribuidores"} value={1} />

                    </MyTabs>

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>
                <div className={classes.rightHeader}>
                    <div className={classes.headerSearch}>

                        {
                            tabValue === 0 ?
                                <TextField id="standard-basic"
                                    onChange={(event) => changeSearch(event)}
                                    className={classes.inputSearch}
                                    label="Buscar..."
                                    variant="outlined"
                                    size="small" />
                                :
                                <UnoButton
                                    variant="contained"
                                    type='primary'
                                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newDistributor',
                                    }))}
                                    startIcon={<AddIcon />}>
                                    {"NOVO DISTRIBUIDOR"}
                                </UnoButton>
                        }

                    </div>
                </div>
            </div>


            <div class={'defaultScrollWithHeaderContent'}>


                {/* Instituitions */}
                {
                    tabValue === 0 &&
                    <MyTable
                        pagination={searchText == '' && listInstituitions && listInstituitions.length > 0}
                        itemspage={itemsPageInstituitions}
                        totalitems={listInstituitions ? listInstituitions.length : 0}
                        handlePage={(value) => setPageInstituitions(pageInstituitions + value)}
                        stickyHeader
                        size="small">
                        <colgroup>
                            <col width="15%" />
                            <col width="70%" />
                            <col width="15%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableTh} align="center">Cod</TableCell>
                                <TableCell className={classes.tableTh} align="left">Descrição</TableCell>
                                <TableCell className={classes.tableTh} align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(searchText == '' ? listInstituitions.slice(
                                (pageInstituitions * itemsPageInstituitions) - (itemsPageInstituitions),
                                itemsPageInstituitions + (itemsPageInstituitions * (pageInstituitions - 1))
                            ) : listInstituitions).map((row) => (
                                <>
                                    {
                                        searchText == "" || (
                                            row.cod.includes(searchText)
                                            || row.description.includes(searchText)) ?
                                            <TableRow key={row.id}>
                                                <TableCell align="center">{row.cod}</TableCell>
                                                <TableCell align="left" component="th" scope="row">{row.description}</TableCell>
                                                <TableCell align="center">

                                                    {
                                                        row.cod != '000' ?
                                                            <IconButton className={classes.iconButton}
                                                                size={'small'}
                                                                onClick={() => updateStatusInstituition(row.id, !row.active)} color="inherit">
                                                                {row.active ?
                                                                    <CheckCircleOutlineRoundedIcon className={classes.green} />
                                                                    :
                                                                    <HighlightOffRoundedIcon className={classes.red} />
                                                                }
                                                            </IconButton> : null
                                                    }


                                                </TableCell>
                                            </TableRow> : null
                                    }
                                </>
                            ))}
                        </TableBody>
                    </MyTable>
                }

                {
                    tabValue === 1 &&
                    <MyTable
                        selectable="true"
                        stickyHeader
                        size="small">
                        <colgroup>
                            <col width="10%" />
                            <col width="30%" />
                            <col width="60%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableTh} align="center">Id</TableCell>
                                <TableCell className={classes.tableTh} align="center">CNPJ</TableCell>
                                <TableCell className={classes.tableTh} align="left">Nome</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listDistributors.map((row) => (

                                <TableRow key={"distributor-" + row.id} onClick={
                                    () => dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newDistributor',
                                        update: true,
                                        data: { ...row }
                                    }))
                                }>
                                    <TableCell align="center">{row.id}</TableCell>
                                    <TableCell align="center">{row.cnpj}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </MyTable>
                }


            </div>
        </div>
    );
}



