import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { login, updatePassByHash, checkHashResetPass } from '../../API/user'
import { useHistory, useLocation } from "react-router-dom";

import Banner from '../../assets/imgs/banner_uno.png';

import MyTextField from '../utils/MyTextField';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import { unoTheme } from '../../assets/styles/unoTheme';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LogoUno from '../../assets/imgs/logo_uno_full_azul.svg'
import UnoButton from '../utils/UnoButton';
import { useDispatch } from 'react-redux';
import allActions from '../../actions';
import MyInnerLoad from '../utils/MyContentLoadModal';

const useStyles = makeStyles((theme) => ({

    image: {
        backgroundImage: 'url(' + Banner + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    inputGrid: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '200px',
        marginBottom: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        width: '100%'
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    forgotPassword: {
        color: unoTheme.mainColor,
        textAlign: 'right',
        fontSize: '0.9em',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },

    resetDiv: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: unoTheme.defaultInputBackground,

        '& .formReset': {
            '& .paperForm': {
                padding: '32px'
            }
        }

    }



}));

export default function ResetPassScreen() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    let location = useLocation();

    const [theHash, setTheHash] = useState('');

    const [inputNewPass, setInputNewPass] = useState('');
    const [typeInputNewPass, setTypeInputNewPass] = useState('password');

    const [inputConfirmNewPass, setInputConfirmNewPass] = useState('');
    const [typeInputConfirmNewPass, setTypeInputConfirmNewPass] = useState('password');

    const [submmited, setSubmmited] = useState(false);

    const [resetMessage, setResetMessage] = useState('');

    const [processing, setProcessing] = useState(false);

    async function doResetPass() {

        setProcessing(true);

        setSubmmited(true);
        console.log("THE HASH: ", theHash)

        if (inputNewPass != "" && inputNewPass.length >= 6
            && inputNewPass == inputConfirmNewPass) {

            const response = await updatePassByHash(theHash, inputNewPass);
            console.log("RSPONSE updatePassByHash: ", response);
            if (response.success) {

                setResetMessage('Senha atualizada com sucesso!')
            } else {
                setResetMessage('Falha ao atualizar senha!')
            }

        }

        setProcessing(false);

    }

    async function checkHash() {

        const hash = location.pathname.substring('/resetPass/'.length);
        const responseCheck = await checkHashResetPass(hash);
        if (!responseCheck.success && responseCheck.body) {
            setResetMessage(responseCheck.body.message)
        } else {
            setTheHash(hash);
        }

    }

    useEffect(() => {

        checkHash();

    }, []);

    return (
        <div className={classes.resetDiv}>
            <Grid container className={'formReset'} square>
                <Grid item xs={0} sm={3} md={4}>
                </Grid>
                <Grid item xs={12} sm={6} md={4}
                    className={'paperForm'}
                    component={Paper} elevation={6}>
                    <img className={classes.logo}
                        src={LogoUno}></img>
                    <br />

                    {
                        resetMessage == '' ?
                            <>
                                <Typography>Para redefinir a senha de acesso ao UNO, insira uma nova senha e depois a confirme. A senha deve ter no mínimo <span style={{ fontWeight: 'bold' }}>6 dígitos</span></Typography>
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={inputNewPass}
                                    onChange={(value) => setInputNewPass(value)}
                                    autoFocus={true}
                                    type={typeInputNewPass}
                                    endadornment={<InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                                        if (typeInputNewPass == 'password') {
                                            setTypeInputNewPass('text')
                                        } else {
                                            setTypeInputNewPass('password')
                                        }
                                    }} position="end">{typeInputNewPass == 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />}</InputAdornment>}
                                    label="Nova senha"
                                    helperText={'A senha deve ter pelo menos 6 dígitos'}
                                    validator={inputNewPass.length < 6 && submmited ? true : false} />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={inputConfirmNewPass}
                                    onChange={(value) => setInputConfirmNewPass(value)}
                                    autoFocus={true}
                                    type={typeInputConfirmNewPass}
                                    endadornment={<InputAdornment style={{ cursor: 'pointer' }} onClick={() => {
                                        if (typeInputConfirmNewPass == 'password') {
                                            setTypeInputConfirmNewPass('text')
                                        } else {
                                            setTypeInputConfirmNewPass('password')
                                        }
                                    }} position="end">{typeInputConfirmNewPass == 'password' ? <VisibilityOffIcon /> : <VisibilityIcon />}</InputAdornment>}
                                    label="Confirme a nova senha"
                                    helperText={'As senhas devem ser iguais'}
                                    validator={(inputNewPass != inputConfirmNewPass) && submmited ? true : false} />
                                <br />
                                <br />
                                <br />
                                <UnoButton
                                    disabled={processing}
                                    onClick={() => doResetPass()}
                                    fullWidth={true}
                                    type='primary'>
                                    {!processing ? "REDEFINIR" : <CircularProgress size={24}
                                        disableShrink color="inherit" />}
                                </UnoButton>
                            </>
                            :
                            <>
                                <br />
                                <Typography>{resetMessage}</Typography>
                                <br />
                                <br />
                                <UnoButton

                                    onClick={() => history.push('/login')}
                                    fullWidth={true}
                                    type='primary'>
                                    {"Retornar a tela de login"}
                                </UnoButton>
                            </>
                    }

                </Grid>
                <Grid item xs={0} sm={3} md={4}>
                </Grid>

            </Grid>
        </div>

    );
}