import { IconButton, Paper, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import allActions from '../../../actions';
import { unoTheme, useColors } from '../../../assets/styles/unoTheme';
import { organizeAssetsAndTransactions } from '../../../controllers/AssetsController';
import MyTable from '../../utils/MyTable';
import { checkFinalLabelDistribuitionTable } from '../../utils/distribution';
import { applyMaskAmount, getTitleCod } from '../../utils/utils';
import InfoIcon from '@material-ui/icons/Info';
import { ArticleParams } from '../../utils/ArticleParams';

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #E6E8ED",
        color: "#4A4A4A",
        fontSize: '16px',
    }
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',

        '& .MuiTableContainer-root': {
            height: 'calc(100vh - 103px) !important',
        }
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        backgroundColor: unoTheme.hoverTable,
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
    },
    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228'
    },
    accountLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    green: {
        color: unoTheme.greenMoney,
    },

    infoIcon: {
        display: 'flex',
        fontSize: '14px',
        color: unoTheme.mainColor,
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    }
}));


export default function FundsFromArticle({ content }) {
    const dispatch = useDispatch();

    const {
        data: { assets, info, assetsOut }
    } = content;

    const classes = useStyles();
    const colors = useColors();

    const [listToShow, setListToShow] = useState();

    function groupByFund(assets, assetsOut) {
        const groupedByFund = organizeAssetsAndTransactions([...assets.filter(el => !el.title_id)], []);
        const titles = [...assets.filter(el => el.title_id)];
        groupedByFund.forEach(fund => {

            if (fund.subAssets) {

                fund.participation = 0;
                fund.subAssets.forEach(subAsset => {
                    fund.participation += parseFloat(subAsset.participation);
                });

            }

            if (assetsOut
                && assetsOut.art18
                && assetsOut.art18.length
                && assetsOut.art18.some(el => el.fund_id == fund.fund_id)) {
                fund.outArt18 = true;
            }

            if (assetsOut
                && assetsOut.art19
                && assetsOut.art19.length
                && assetsOut.art19.some(el => el.fund_id == fund.fund_id)) {
                fund.outArt19 = true;
            }

        });

        setListToShow([
            ...groupedByFund,
            ...titles,
        ]);
    }

    useEffect(() => {
        console.log("Content AssetsFromFormat: ", content);
        console.log("AssetsFromFormat: ", assets);
        if (!listToShow) {
            groupByFund(assets, assetsOut)
        }

    })

    return (
        <div className={classes.root}>

            <Paper style={{ marginBottom: '4px' }}>
                <div className={classes.header}>
                    <div className={classes.leftHeader}>
                        <div>
                            <Typography
                                align={'left'}
                                className={classes.fundLabel}>
                                {checkFinalLabelDistribuitionTable(info.label)}
                            </Typography>
                            <Typography
                                align={'left'}
                                className={classes.accountLabel}>
                                <span>PARTICIPAÇÃO: <span style={{ color: unoTheme.mainColor }}>{info.percent}</span></span>
                                <span className={classes.spacingLeft2}>
                                    VALOR:
                                    <Typography component="strong" className={colors.green}> {info.formatedAmount} </Typography>
                                </span>

                            </Typography>
                        </div>
                    </div>

                    <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                        <CloseIcon />
                    </IconButton>

                </div>
            </Paper>
            <MyTable
                stickyHeader
                nofixed={true}
                size="small">

                <TableHead>
                    <TableRow>
                        <TableCell align="left">Fundo / Título</TableCell>
                        <TableCell align="center">
                            <LightTooltip button title={"As aplicações em cotas de um mesmo fundo de investimento, fundo de investimento em cotas de fundos de investimento ou fundo de índice não podem, direta ou indiretamente, exceder a 20% (vinte por cento) das aplicações dos recursos do regime próprio de previdência social."}
                                enterDelay={100}
                                leaveDelay={100}
                                placement="bottom" arrow>
                                <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '4px' }}>Art. 18 <InfoIcon className={classes.infoIcon} /></span>
                            </LightTooltip>
                        </TableCell>
                        <TableCell align="center">Part. Art. 18</TableCell>
                        <TableCell align="center">
                            <LightTooltip button title={<span>O total das aplicações dos recursos do regime próprio de previdência social em um mesmo fundo de investimento deverá representar, no máximo, 15% (quinze por cento) do patrimônio líquido do fundo, observado o disposto no art. 16. <br /><span style={{ fontWeight: "bold" }}>§ 1º O limite de que trata o caput será de até 5% (cinco por cento) do patrimônio líquido dos fundos de investimento de que trata o inciso V do art. 7º.</span></span>}
                                enterDelay={100}
                                leaveDelay={100}
                                placement="bottom" arrow>
                                <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '4px' }}>Art. 19 <InfoIcon className={classes.infoIcon} /></span>
                            </LightTooltip>
                        </TableCell>
                        <TableCell align="center">
                            Part. Art. 19
                        </TableCell>
                        <TableCell align="right">Saldo</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listToShow && listToShow.map((fund, index) => (
                        <TableRow key={'funds-from-article-' + index}>
                            <TableCell align="left">{fund.fund_id ? fund.fund_name : getTitleCod(fund)}</TableCell>
                            <TableCell align="center">
                                {fund.title_id
                                    || ArticleParams
                                        .limitsByResolution
                                        .resolution_4963
                                        .offLimitArt18And19Ids.includes(info?.articleId) ? "-" :
                                    <>
                                        {
                                            fund.outArt18 ?
                                                <CloseIcon style={{ color: unoTheme.redMoney }} />
                                                :
                                                <CheckIcon style={{ color: unoTheme.greenMoney }} />

                                        }
                                    </>
                                }
                            </TableCell>
                            <TableCell align="center">{
                                fund.title_id
                                    || ArticleParams
                                        .limitsByResolution
                                        .resolution_4963
                                        .offLimitArt18And19Ids.includes(info?.articleId) ? "-"
                                    : <span style={{ color: fund.outArt18 ? unoTheme.redMoney : unoTheme.mainColor }}>{applyMaskAmount(fund.participation) + '%'}</span>
                            }</TableCell>
                            <TableCell align="center">
                                {
                                    fund.outArt19 ?
                                        <CloseIcon style={{ color: unoTheme.redMoney }} />
                                        :
                                        <>{fund.title_id
                                            || ArticleParams
                                                .limitsByResolution
                                                .resolution_4963
                                                .offLimitArt18And19Ids.includes(info?.articleId) ? "-" : <CheckIcon style={{ color: unoTheme.greenMoney }} />}</>

                                }
                            </TableCell>
                            <TableCell align="center">{
                                <span style={{ color: fund.outArt19 ? unoTheme.redMoney : unoTheme.mainColor }}>{fund.participationAssetInFundPl
                                    && !ArticleParams
                                        .limitsByResolution
                                        .resolution_4963
                                        .offLimitArt18And19Ids.includes(info?.articleId) ? applyMaskAmount(fund.participationAssetInFundPl) + '%' : "-"}</span>
                            }</TableCell>
                            <TableCell align="right" className={classes.green}>{
                                fund.balance_now ? applyMaskAmount(fund.balance_now, true) : "-"}</TableCell>
                        </TableRow>))}

                </TableBody>
            </MyTable>
        </div >
    )
}
