import { normalizeAccounts } from '../controllers/AccountController';
import API from './config'

export async function getConsultingInfosById(consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/consultings/getConsultingInfosById/' + consultingId, config)

        return response.data;

    } catch (error) {

        console.log("ERROR: ", error.response)

        return null;
    }

}

