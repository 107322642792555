import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { unoTheme } from '../../assets/styles/unoTheme';
import { applyMaskAccount } from './utils';

import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({

    alertBalance: {
        '& .MuiAlert-message': {
            fontSize: '12px',
            padding: '0px',
            '& span': {
                verticalAlign: 'middle',
            }
        },
        '& .MuiAlert-icon': {
            padding: '0px',
        },
        '& .alertMain': {
            marginTop: '5px',
            marginBottom: '5px',
        }
    },
    bold: {
        color: 'black',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: 'blue',
    },
}));

/**
 * My main alert
 * @param {string} show 
 * @param {string} type
 * @param {string} message
 * @returns {Object} Alert
 */
export default function MyAlertEndFund(props) {

    const classes = useStyles();

    const {
        row,
        endDayMonthDetail,
        firstDayMonthDetail
    } = props

    function renderAccount(row) {
        return (
            <span><span className={classes.bold}>Conta: </span><span className={classes.bold}>{applyMaskAccount(row.number_account)} {row.agency ? "/" + row.agency : ""}</span></span>
        )
    }

    const { uno_date_quota_end } = row;

    return (

        <>
            {

                uno_date_quota_end != null
                && moment.utc(uno_date_quota_end).isBetween(firstDayMonthDetail, endDayMonthDetail)
                && (row.balance_now > 0 || row.quota_amount_now > 0) &&

                <div className={classes.alertBalance}>


                    <Alert className={'alertMain'}
                        variant="outlined"
                        severity="info">

                        <span><span className={classes.bold}>Inconsistência de liquidação do fundo</span></span>
                        <br />
                        <span><span>{`O fundo foi liquidado no dia `}</span><span style={{ fontWeight: "bold" }}>{moment.utc(row.uno_date_quota_end).format("DD/MM/YYYY")}</span><span>{` e portanto deveria ser validado com saldo final `}<span style={{ fontWeight: "bold" }}>0 (zero)</span>{` e quantidades de cotas `}<span style={{ fontWeight: "bold" }}>0 (zero)</span></span></span>

                    </Alert>

                </div >
            }
        </>

    );
}