import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import MyTable from '../../utils/MyTable';
import MyTextField from '../../utils/MyTextField';


import _ from 'lodash';


//Api
import { createUpdateClientPolicy } from '../../../API/client';
import {
    listLegislationArticles,
    listLegislationSegmentsByType
} from '../../../API/legislation_article';

//redux
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions';

//Modals
import NewLegislationArticleModal from "../../modals/NewLegislationArticleModal";
import UpdatePolicyModal from "../../modals/UpdatePolicyModal";

//Controllers
import { getSimpleNormalizedAssets } from '../../../controllers/AssetsController';

import { headerSearch, tabs, unoTheme } from '../../../assets/styles/unoTheme';

import { applyMaskAmount, groupArticlePolicys, markArticlesInPortfolio } from "../../utils/utils";

import { Tooltip } from "@material-ui/core";

import WarningIcon from '@material-ui/icons/Warning';

import { ArticleRulesAPI } from "../../../API/article_rules";

const LightTooltip = withStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid #E6E8ED",
        color: "#4A4A4A",
        fontSize: '16px',
    }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.redMoney,
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
    toogleButton: {
        maxHeight: '40px',
    },

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },

    iconExtraPolicys: {

        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            verticalAlign: 'middle',
        }

    },

    tabs: tabs,

    sectionTableCellData: {
        width: '30%',
        display: 'table-cell',
        padding: '0px !important',
    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'unset',
            color: 'white',
            background: unoTheme.mainColor,
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    pendent: {
        color: unoTheme.warningColor,
        cursor: 'pointer',
        fontSize: '1.2em',
        verticalAlign: 'middle',
    },


}));

export default function ArticlesTableScreen(props) {

    const { formats, editTargets, handleFab, segment, nextYearPolicySelected } = props

    const nextYear = new Date().getFullYear() + 1;

    const classes = useStyles();
    const location = useLocation();
    const clientOn = useSelector(state => state.clientOn)

    const dispatch = useDispatch();

    const [legislationSegments, setListLegislationSegments] = useState([]);
    const [legislationArticles, setListLegislationArticles] = useState([]);

    //Selecionado para ao modal
    const [legislationArticleSelected, setLegislationArticleSelected] = useState(undefined);
    const [showNewLegislationArticle, setShowNewLegislationArticle] = useState(false);

    //Modal update policy
    const [policysToUpdate, setPolicysToUpdate] = useState(true);
    const [showModalUpdatePolicy, setShowModalUpdatePolicy] = useState(false);

    const [limitPortfolioMode, setLimitPortfolioMode] = useState('porcent');
    const [presentationNameMode, setPresentationNameMode] = useState('name');

    //Total dos saldos da carteira
    const [totalPortfolioBalance, setTotalPortfolioBalance] = useState(null);
    //Total dos saldos dos artigos do segmento específico (Ex: fundos de renda fixa)
    const [totalArticlesBalance, setTotalArticlesBalance] = useState(null);

    const [filter, setFilter] = useState('carteira');

    //Input de targets
    const [inputs, setInputs] = useState({});
    const [editTargetsScreen, setEditTargetsScreen] = useState(false);
    const [segmentOnScreen, setSegmentOnScreen] = useState(0);
    const [clientOnScreen, setClientOnScreen] = useState("");
    const [nextYearPolicySelectedOnScreen, setNextYearPolicySelectedOnScreen] = useState(false);

    //AssetsByRule
    const [assetsByRule, setAssetsByRule] = useState();

    function initInputs(articles, selectedPortfolioMonth) {

        let newInputs = {}

        //Criando o objeto de input com os artigos que será referenciado 
        //no futuro para ser salvo no banco
        articles.forEach(a => {

            let returnInput = {
                ...a,
                changed: false,
                //Caso já exista o registro de política para o cliente nesse artigo, 
                //esse id será usado para fazer um update em vez de criar um novo registro
                client_policy_id: a.client_policy_id ? a.client_policy_id : null,

                //Targets trazidos de políticas que já existem
                lower_target: a.lower_target ? a.lower_target : "",
                target: a.target ? a.target : "",
                top_target: a.top_target ? a.top_target : "",

                //Targets trazidos de políticas que já existem
                old_lower_target: a.lower_target ? a.lower_target : "",
                old_target: a.target ? a.target : "",
                old_top_target: a.top_target ? a.top_target : "",

                old_month: a.month_policy

            };

            //Existe mudança de política no meio do ano;
            if (a.extrasPolicys && a.extrasPolicys[selectedPortfolioMonth]) {

                let extraPolicy = a.extrasPolicys[selectedPortfolioMonth];

                //Caso seja o mês da extra política altera-se o id do objeto principal 
                //Pra ser usado na lógica que vai comparar ou criar uma nova política
                returnInput.client_policy_id = extraPolicy.client_policy_id ? extraPolicy.client_policy_id : null;

                //Targets trazidos de políticas que já existem
                returnInput.lower_target = extraPolicy.lower_target ? extraPolicy.lower_target : "";
                returnInput.target = extraPolicy.target ? extraPolicy.target : "";
                returnInput.top_target = extraPolicy.top_target ? extraPolicy.top_target : "";

                //Targets trazidos de políticas que já existem
                returnInput.old_lower_target = extraPolicy.lower_target ? extraPolicy.lower_target : "";
                returnInput.old_target = extraPolicy.target ? extraPolicy.target : "";
                returnInput.old_top_target = extraPolicy.top_target ? extraPolicy.top_target : "";

                returnInput.old_month = extraPolicy.month_policy;

            }

            newInputs[a.id] = returnInput;

        });

        //console.log("NEW INPUTS: ", newInputs)
        setInputs(newInputs);

    }

    function onChangeTarget(field, id, value) {
        //initInputs2(legislationArticles);

        let newInputs = Object.assign({}, inputs);

        newInputs[id].changed = true;
        newInputs[id][field] = value;

        //setInputs({})
        setInputs(newInputs);

    }

    function getYearToSelect(nextYearPolicySelected, selectedPortfolioYear) {
        return nextYearPolicySelected ? nextYear : selectedPortfolioYear;
    }

    function getMonthToSelect(nextYearPolicySelected, selectedPortfolioMonth) {
        return nextYearPolicySelected ? 1 : selectedPortfolioMonth;
    }

    async function getLegislationByType(type, yearToSelect) {

        console.log("getLegislationByType: ", clientOn.client);

        let responseLegislationSegments = await listLegislationSegmentsByType(type);

        if (responseLegislationSegments.success) {

            setListLegislationSegments(responseLegislationSegments.body.rows);

            //Como o filtro é feito apenas pelo ano os registros de artigo podem vir duplicados pois
            //podem existir mais de uma politica para cada artigo
            //a diferença está no mês        
            let responseLegislationArticles = await listLegislationArticles(type,
                clientOn.client.id,
                yearToSelect);

            console.log("ARTICLES COM POLITICAS: ", responseLegislationArticles.body.rows);

            let formattedArticles = groupArticlePolicys(responseLegislationArticles.body.rows);

            console.log("ARTICLES GROUPED: ", formattedArticles);


            if (!responseLegislationArticles.success) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao listar os enquadramentos de resolução'
                }))
            }

            if (clientOn && clientOn.client && yearToSelect && clientOn.client.selectedPortfolioMonth) {

                console.log("SELECTED YEAR: ", yearToSelect);
                console.log("SELECTED MONTH: ", clientOn.client.selectedPortfolioMonth);

                //let responseAssets = await getClientAllAssetsByDate(clientOn.client.id, moment.utc(lastDay).format("DD/MM/YYYY"));
                let responseAssets = await getSimpleNormalizedAssets(clientOn.client.id,
                    clientOn.client.selectedPortfolioMonth,
                    yearToSelect,
                    null)

                if (responseAssets.success) {

                    const responseArticlesRules = await ArticleRulesAPI.listAll();
                    if (responseArticlesRules.success) {
                        const articlesRules = responseArticlesRules.body.rows;

                        //console.log("ASSETS: ", responseAssets.assets);
                        const responseGrup = markArticlesInPortfolio(formattedArticles,
                            responseAssets.assets, null, articlesRules, clientOn.client.promanagement_code);
                        setTotalPortfolioBalance(responseGrup.totalPortfolioValue);
                        setTotalArticlesBalance(responseGrup.totalArticlesValue);

                        setAssetsByRule(responseGrup.assetsByRule);

                        console.log('responseGrup.assetsByRule: ', responseGrup.assetsByRule);

                        //inicializando inputs que serão utilizados na edição
                        initInputs(responseGrup.articles, clientOn.client.selectedPortfolioMonth);

                        if (responseGrup.articles && responseGrup.articles.length > 0 && responseGrup.articles[0].asset == 'Desenquadrado') {
                            responseGrup.articles.push(responseGrup.articles.shift());
                        }

                        //console.log('responseGrup.articles: ', responseGrup.articles);
                        console.log('RULE PROCESSED: ', responseGrup.assetsByRule);
                        setListLegislationArticles(responseGrup.articles);

                        if (responseGrup.totalArticlesValue == 0 && formats == 'carteira') {
                            dispatch(allActions.innerLoadActions.setInnerLoad({
                                show: true,
                                emptyMessage: "Nenhum investimento na carteira para esse segmento"
                            }));

                        } else {
                            dispatch(allActions.innerLoadActions.setInnerLoad({
                                show: false,
                            }));
                        }
                    } else {
                        dispatch(allActions.innerLoadActions.setInnerLoad({
                            show: true,
                            emptyMessage: "falha ao obter regras de enquadramento"
                        }));
                    }

                } else {

                    console.log("ERROR AO TRAZER ASSETS")
                    dispatch(allActions.innerLoadActions.setInnerLoad({
                        show: true,
                        emptyMessage: "Ainda não existe carteira lançada para esse cliente"
                    }));

                }
            } else if (!yearToSelect || !clientOn.client.selectedPortfolioMonth) {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Ainda não existe carteira lançada para esse cliente"
                }));
            }


        } else {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os enquadramentos de resolução'
            }))

        }



    }

    async function doUpdateSaveClientPolicy(policy, type) {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Atualizando política de investimentos..."
        }))

        //verificando se existem registros a serem atualizados em um mês diferente pro mesmo ano
        //caso existam devem ser listados num modal
        //deve ser explicado que se está alterando uma política já existente para o mesmo ano
        //dando a possibilidade de apenas corrigir ou de criar nova política
        let toUpdateSameYear = []

        const monthToSelect = getMonthToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioMonth);
        for (let i = 0; i < policy.length; i++) {

            let element = policy[i];
            console.log("OLD MONTH: ", element.old_month);
            console.log("PORT MONTH: ", monthToSelect);
            if (//se já existe id para esse objeto já existe registro no banco
                element.client_policy_id
                && element.client_policy_id != ""

                //e se o mês que está sendo salvo é diferente do mês do último registro de política
                //salvo do ano
                && element.old_month != monthToSelect) {

                //politica a ser atualizada
                toUpdateSameYear.push(element);
            }

        }

        if (toUpdateSameYear.length == 0) {

            let allSuccess = true;
            for (let i = 0; i < policy.length; i++) {

                let element = policy[i];
                let responsePolicy = await createUpdateClientPolicy(element);

                if (!responsePolicy.success) {
                    allSuccess = false;
                    break;
                }
            }

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
                message: "Atualizando política de investimentos..."
            }))

            if (allSuccess) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: "success",
                    message: "Política de investimentos atualizada com sucesso!"
                }))

                getLegislationByType(segmentOnScreen,
                    getYearToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioYear)
                );

            } else {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: "error",
                    message: "Falha ao atualizar política de investimento"
                }))
            }
        } else {
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
                message: "Atualizando política de investimentos..."
            }))

            //Setando políticas na variável que será enviada ao modal
            setPolicysToUpdate(toUpdateSameYear);
            setShowModalUpdatePolicy(true);
        }

    }

    useEffect(() => {

        console.log("Chamando NEXT YEAR POLICY");

        //Deve fazer a recarga apenas no caso de ter mudado o client, o ano selecionado ou a tab                
        if (segment && segment.id && clientOn && clientOn.client
            &&
            (
                (
                    clientOn.client.id != clientOnScreen.id
                    ||
                    clientOn.client.selectedPortfolioMonth != clientOnScreen.selectedPortfolioMonth
                    ||
                    clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear
                )
                || segment.id != segmentOnScreen
                || nextYearPolicySelected != nextYearPolicySelectedOnScreen
            )
        ) {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando informações...',
            }));

            setClientOnScreen(_.cloneDeep(clientOn.client))

            setSegmentOnScreen(segment.id);
            setNextYearPolicySelectedOnScreen(nextYearPolicySelected);
            getLegislationByType(segment.id, getYearToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioYear));
        }


        setFilter(formats);
        if (formats == 'todos') {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }

        console.log("Edit target: ", editTargets)
        //Situação em que estava ocorrendo a edição de targets
        //Deseja-se então salvá-los
        //O gatilho é disparado no Fab button na ArticleScreen.js
        if (!editTargets && editTargetsScreen) {

            //salvar registros de política de investimento
            let toSave = [];
            let monthToSave = getMonthToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioMonth);
            let yearToSave = getYearToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioYear);

            Object.entries(inputs).map(([key, element]) => {

                if (element.changed) { //Caso algum input desse artigo tenha sido alterado ele entra pra atualização

                    toSave.push({
                        legislation_article_id: key,
                        client_id: clientOn.client.id,
                        ...element, // contém o client_policy_id, lower_target, target e top_target. Obtidos nos inputs

                        month: monthToSave, //Setando o mês a ser salvo
                        year: yearToSave, //Setando o ano a ser salvo
                    });


                }
            })

            console.log("POLITICA TO SAVE: ", toSave);

            //Caso exista algum artigo que foi alterado
            if (toSave.length > 0) {
                doUpdateSaveClientPolicy(toSave, segmentOnScreen);
            }

        }

        setEditTargetsScreen(editTargets);

    }, [
        clientOn,
        legislationArticleSelected,
        segment,
        formats,
        editTargets,
        nextYearPolicySelected
    ]);

    function handleModal(update) {
        setLegislationArticleSelected(undefined)
        setShowNewLegislationArticle(false);
        getLegislationByType(1, getYearToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioYear));
    }

    function handleModalPolicy(open, update) {
        setShowModalUpdatePolicy(open);
        if (update) {
            getLegislationByType(segmentOnScreen, getYearToSelect(nextYearPolicySelected, clientOn.client.selectedPortfolioYear));
        }

    }

    function openArticleModalToUpdate(article) {

        setLegislationArticleSelected(article)
    }

    function getWarningMessageArticleRules(assetsInRule) {

        const nodes = [];
        Object.entries(assetsInRule).map(([key, row]) => {
            if (row.rule) {
                nodes.push(<p style={{ fontSize: '14px' }}>Os saldos dos ativos referentes a regra <span style={{ color: unoTheme.mainColor, fontWeight: 'bold' }}>{row.rule.name}</span> encontram-se desenquadrados</p>);
            }
        });

        return nodes;

    }

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.tableSideGraph}>
                    {

                        legislationSegments.map((segment, index) => (

                            <>
                                {filter == 'carteira' &&
                                    legislationArticles.filter(el =>
                                        el.legislation_segment_id == segment.id
                                        && el.isInPortfolio
                                    ).length > 0

                                    || filter == 'todos' ?
                                    <MyTable stickyHeader
                                        nofixed={true}
                                        onScroll={(value) => handleFab(value)}
                                        size="small">
                                        {/* selectable="true"> */}
                                        {/* <colgroup>
                                            <col width="25%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                            <col width="15%" />
                                            <col width="5%" />
                                            <col width="15%" />
                                        </colgroup> */}
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={[classes.clickAble].join(' ')} align="left"
                                                    onClick={() => setPresentationNameMode(presentationNameMode == 'name' ? 'class' : 'name')}>
                                                    {'Tipo de ativo'}
                                                </TableCell>
                                                {/*art13*/}
                                                <TableCell align="center"></TableCell>
                                                {/*art14*/}
                                                <TableCell align="center"></TableCell>
                                                {/*art18 fund em relação a carteira*/}
                                                <TableCell align="center"></TableCell>
                                                {/*art19 fund em relação ao pl anbima*/}
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center" style={{ display: "flex", alignItems: 'center', gap: '8px' }}>
                                                    Resolução (%) {clientOn.client?.promanagement_code ? '[PG ' + clientOn.client?.promanagement_code + ']' : ''}
                                                    {
                                                        assetsByRule?.outRule &&
                                                        <LightTooltip button title={<div>{getWarningMessageArticleRules(assetsByRule)}</div>}
                                                            className={classes.pendent}
                                                            enterDelay={100}
                                                            leaveDelay={100}
                                                            placement="bottom" arrow>
                                                            <WarningIcon />
                                                        </LightTooltip>
                                                    }

                                                </TableCell>
                                                <TableCell
                                                    className={[classes.clickAble].join(' ')} align="center"
                                                    onClick={() => setLimitPortfolioMode(limitPortfolioMode == 'value' ? 'porcent' : 'value')}>Carteira {limitPortfolioMode == 'value' ? '(R$)' : '(%)'}</TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center">
                                                </TableCell>
                                                <TableCell className={classes.sectionTableCell}
                                                    align="center">
                                                    <div>{'Estratégia de alocação'}</div>
                                                    <div className={classes.multipleHorizontalTableCell}>
                                                        <div>{'Inferior (%)'}</div>
                                                        <div>{'Alvo (%)'}</div>
                                                        <div>{'Superior (%)'}</div>
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell align="center">
                                                    {'Lim. Inferior (%)'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {'Alvo (%)'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {'Lim. Superior (%)'}
                                                </TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {legislationArticles
                                                .filter(el =>
                                                    filter == 'carteira'
                                                    && el.legislation_segment_id == segment.id
                                                    && el.isInPortfolio
                                                    ||
                                                    filter == 'todos'
                                                    && el.legislation_segment_id == segment.id
                                                )
                                                .map((row) => (

                                                    <TableRow key={'article-' + row.id}
                                                        className={classes.selectableRow}
                                                        onClick={() => {

                                                            if (!editTargetsScreen && row.assets && row.assets.length) {
                                                                dispatch(allActions.rightContentActions.setRightContent({
                                                                    path: 'fundsFromArticle',
                                                                    data: {
                                                                        info: {
                                                                            articleId: row.id,
                                                                            label: row.device,
                                                                            percent: applyMaskAmount(parseFloat(row.totalArticleValue * 100 / totalPortfolioBalance)) + '%',
                                                                            formatedAmount: applyMaskAmount(row.totalArticleValue)
                                                                        },
                                                                        assets: row.assets,
                                                                        assetsOut: {
                                                                            art18: row.assetsOutArt18,
                                                                            art19: row.assetsOutArt19
                                                                        }
                                                                    },
                                                                }))
                                                            }

                                                        }}>
                                                        <LightTooltip button title={row.description} enterDelay={100} leaveDelay={100} placement="bottom" arrow>
                                                            <TableCell align="left">
                                                                {presentationNameMode == 'name' ? row.asset : row.device}
                                                            </TableCell>
                                                        </LightTooltip>

                                                        {/* art 13 */}
                                                        <TableCell align="center">
                                                            {row.assetsOutArt13 && row.assetsOutArt13.length > 0 ?
                                                                <LightTooltip button title={"DESENQUADRADO NO ARTIGO 13"}
                                                                    className={classes.pendent}
                                                                    enterDelay={100}
                                                                    leaveDelay={100}
                                                                    placement="bottom" arrow>
                                                                    <WarningIcon />
                                                                </LightTooltip> : null}
                                                        </TableCell>

                                                        {/* art 14 */}
                                                        <TableCell align="center">
                                                            {row.assetsOutArt14 && row.assetsOutArt14.length > 0 ?

                                                                <LightTooltip
                                                                    button
                                                                    className={classes.pendent}
                                                                    title={"DESENQUADRADO NO ARTIGO 14"}
                                                                    enterDelay={100}
                                                                    leaveDelay={100}
                                                                    placement="bottom"
                                                                    arrow>
                                                                    <WarningIcon />
                                                                </LightTooltip> : null}
                                                        </TableCell>

                                                        {/* art 18 */}
                                                        <TableCell align="center">
                                                            {row.assetsOutArt18 && row.assetsOutArt18.length > 0 ?
                                                                <LightTooltip button title={"Existem fundos desenquadrados no Art. 18. \n As aplicações em cotas de um mesmo fundo de investimento, fundo de investimento em cotas de fundos de investimento ou fundo de índice não podem, direta ou indiretamente, exceder a 20% (vinte por cento) das aplicações dos recursos do regime próprio de previdência social."}
                                                                    className={classes.pendent}
                                                                    enterDelay={100}
                                                                    leaveDelay={100}
                                                                    placement="bottom" arrow>
                                                                    <WarningIcon style={{ color: unoTheme.redMoney }} />
                                                                </LightTooltip> : null}
                                                        </TableCell>

                                                        {/* art 19 */}
                                                        <TableCell align="center">
                                                            {row.assetsOutArt19 && row.assetsOutArt19.length > 0 ?
                                                                <LightTooltip button title={"Existem fundos desenquadrados no Art. 19. \n O total das aplicações dos recursos do regime próprio de previdência social em um mesmo fundo de investimento deverá representar, no máximo, 15% (quinze por cento) do patrimônio líquido do fundo, observado o disposto no art. 16."}
                                                                    className={classes.pendent}
                                                                    enterDelay={100}
                                                                    leaveDelay={100}
                                                                    placement="bottom" arrow>
                                                                    <WarningIcon style={{ color: unoTheme.redMoney }} />
                                                                </LightTooltip> : null}
                                                        </TableCell>

                                                        <TableCell align="center">{row.limit_resolution}%</TableCell>
                                                        {
                                                            limitPortfolioMode == 'porcent' ?

                                                                //No caso de ser o modelo de porcentagem                                                                                                                                
                                                                <TableCell align="center">
                                                                    {
                                                                        (row.totalArticleValue * 100
                                                                            / totalPortfolioBalance) > row.limit_resolution ?
                                                                            // Caso o valor seja superior ao limite da resolução ele aparece vermelho com o ícone de alerta
                                                                            <div className={classes.errorArticle}>
                                                                                {<WarningIcon />} {parseFloat(row.totalArticleValue * 100 / totalPortfolioBalance).toFixed(2)}%
                                                                            </div>
                                                                            :
                                                                            <div className={totalPortfolioBalance != 0 && parseFloat(row.totalArticleValue * 100 / totalPortfolioBalance).toFixed(2) != '0.00' ? classes.green : null}>
                                                                                {totalPortfolioBalance != 0 ? parseFloat(row.totalArticleValue * 100 / totalPortfolioBalance).toFixed(2) : "0.00"}%
                                                                            </div>

                                                                    }



                                                                </TableCell>
                                                                :
                                                                <TableCell align="center">
                                                                    {

                                                                        row.totalArticleValue * 100 /
                                                                            totalPortfolioBalance > row.limit_resolution ?
                                                                            // Caso o valor seja superior ao limite da resolução ele aparece vermelho com o ícone de alerta
                                                                            <div className={classes.errorArticle}>
                                                                                {<WarningIcon />} {applyMaskAmount(row.totalArticleValue, true)}
                                                                            </div>

                                                                            :
                                                                            <div>
                                                                                {applyMaskAmount(row.totalArticleValue, true)}
                                                                            </div>

                                                                    }



                                                                </TableCell>
                                                        }
                                                        <TableCell align="center">
                                                            {
                                                                //Caso esteja abaixo do lower
                                                                row.outLowerTarget ||
                                                                    //Caso esteja acima do top
                                                                    row.outTopTarget ?
                                                                    <LightTooltip button title={

                                                                        row.outLowerTarget ?
                                                                            "Abaixo do alvo inferior da extratégia de alocação"
                                                                            :
                                                                            "Acima do alvo superior da estratégia de alocação"
                                                                    }
                                                                        className={classes.pendent}
                                                                        enterDelay={100}
                                                                        leaveDelay={100}
                                                                        placement="bottom" arrow>
                                                                        <WarningIcon />
                                                                    </LightTooltip> : null
                                                            }
                                                        </TableCell>
                                                        <TableCell align="center" className={classes.iconExtraPolicys}>
                                                            {clientOn.client?.selectedPortfolioMonth && row.extrasPolicys
                                                                && row.extrasPolicys[clientOn.client.selectedPortfolioMonth] ?

                                                                <LightTooltip button title={"Alteração de política realizada para o mesmo ano de " + clientOn.client.selectedPortfolioYear} enterDelay={100} leaveDelay={100} placement="bottom" arrow>
                                                                    <WarningIcon />
                                                                </LightTooltip> : null}
                                                        </TableCell>


                                                        <TableCell align="center" className={classes.sectionTableCellData}>
                                                            {

                                                                //No momento que se clica em editar são exibidos os inputs referentes aos 3 campos (lower_target, target e top_target)
                                                                //Os campos não precisam ser salvos no exato momento, apenas ao fim do processo se faz a atualização geral da lista

                                                                //Não deve ser editável o desenquadrado
                                                                editTargets && row.asset != "Desenquadrado" ?
                                                                    <div className={classes.multipleHorizontalTableCell}>

                                                                        <div>
                                                                            <MyTextField
                                                                                align="right"
                                                                                value={inputs[parseInt(row.id)] && inputs[parseInt(row.id)].lower_target}
                                                                                onChange={(value) => onChangeTarget('lower_target', parseInt(row.id), value)}
                                                                                verysmall
                                                                                placeholder="0.00"
                                                                                mask={['percent']}
                                                                                size={'small'}
                                                                                variant="outlined" />
                                                                        </div>
                                                                        <div>
                                                                            <MyTextField
                                                                                align="right"
                                                                                value={inputs[parseInt(row.id)] && inputs[parseInt(row.id)].target}
                                                                                onChange={(value) => onChangeTarget('target', parseInt(row.id), value)}
                                                                                verysmall
                                                                                placeholder="0.00"
                                                                                mask={['percent']}
                                                                                size={'small'}
                                                                                variant="outlined" />
                                                                        </div>
                                                                        <div>
                                                                            <MyTextField
                                                                                align="right"
                                                                                value={inputs[parseInt(row.id)] && inputs[parseInt(row.id)].top_target}
                                                                                onChange={(value) => onChangeTarget('top_target', parseInt(row.id), value)}
                                                                                verysmall
                                                                                placeholder="0.00"
                                                                                mask={['percent']}
                                                                                size={'small'}
                                                                                variant="outlined" />
                                                                        </div>

                                                                    </div>

                                                                    :
                                                                    <>
                                                                        {/* Caso haja multipla política e a política do mês selecionado seja uma política extra */}
                                                                        {
                                                                            clientOn.client?.selectedPortfolioMonth && row.extrasPolicys && row.extrasPolicys[clientOn.client.selectedPortfolioMonth] ?


                                                                                <div className={classes.multipleHorizontalTableCell}>
                                                                                    <div>{row.extrasPolicys[clientOn.client.selectedPortfolioMonth].lower_target ? row.extrasPolicys[clientOn.client.selectedPortfolioMonth].lower_target + '%' : '-'}</div>
                                                                                    <div>{row.extrasPolicys[clientOn.client.selectedPortfolioMonth].target ? row.extrasPolicys[clientOn.client.selectedPortfolioMonth].target + '%' : '-'}</div>
                                                                                    <div>{row.extrasPolicys[clientOn.client.selectedPortfolioMonth].top_target ? row.extrasPolicys[clientOn.client.selectedPortfolioMonth].top_target + '%' : '-'}</div>
                                                                                </div>

                                                                                :

                                                                                <div className={classes.multipleHorizontalTableCell}>
                                                                                    <div>{row.lower_target ? row.lower_target + '%' : '-'}</div>
                                                                                    <div>{row.target ? row.target + '%' : '-'}</div>
                                                                                    <div>{row.top_target ? row.top_target + '%' : '-'}</div>
                                                                                </div>


                                                                        }
                                                                    </>



                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                        <TableFooter className={classes.myTableFooter}>
                                            <TableRow key={'footer-t-assets'}>
                                                <TableCell align="left">Total</TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="center">
                                                    <span className={'spanTotal'}>{limitPortfolioMode == 'porcent' ?
                                                        applyMaskAmount((totalArticlesBalance / totalPortfolioBalance * 100)) + "%"
                                                        :
                                                        applyMaskAmount(totalArticlesBalance, true)}</span>
                                                </TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </MyTable>

                                    : null

                                }
                            </>
                        ))
                    }

                </div>

            </div>

            <NewLegislationArticleModal
                open={showNewLegislationArticle}
                handleModal={(show) => handleModal(show)}
                legislationArticle={legislationArticleSelected}
                legislationSegments={legislationSegments} />

            <UpdatePolicyModal
                open={showModalUpdatePolicy}
                policys={policysToUpdate}
                handleModalPolicy={handleModalPolicy} />

        </div >
    );
}



