import React, { useEffect, useMemo, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { isDevENV } from '../API/config';
import PluggyController from '../controllers/PluggyController';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../actions';
import { PluggyConnect } from 'react-pluggy-connect';

export const useDisplayErrorMessageOnPluggyError = () => {
    const dispatch = useDispatch();

    return {
        triggerErrorAlert: (error = 'empty') => {
            console.log(`Pluggy Console Error: ${error}`);

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Houve um problema ao integrar com Pluggy!"
            }))
        }
    }
};

export default function PluggyAlert({
    setPluggyAlertVisibility,
    connectToken
}) {
    const { clientOn, currentUser, scrollDialog } = useSelector(state => state);

    const isCurrentUserSuperAdmin = useMemo(() => currentUser?.user && currentUser?.user.isSuperAdmin, [currentUser]);

    const dispatch = useDispatch();

    const [isConnectVisible, setIsConnectVisible] = useState(false);
    const [success, setSuccess] = useState(false);

    const { triggerErrorAlert } = useDisplayErrorMessageOnPluggyError();

    const openConnectPopup = () => {
        setIsConnectVisible(true);
    };

    const onClosePopup = () => {
        setIsConnectVisible(false);
    };

    useEffect(() => {
        if (success && !isConnectVisible) {
            setPluggyAlertVisibility(true);
            dispatch(allActions.notificationActions.setNotifications({
                notificationsToRemove: [{
                    type: 'pluggy_integration'
                }]
            }));
        }
    }, [success, isConnectVisible]);

    return (
        <>
            <Alert severity='info'>
                <span
                    style={{
                        textDecoration: 'underline',
                        cursor: 'pointer'
                    }}
                    onClick={() => openConnectPopup(true)}
                >Clique aqui</span> para integrar com a pluggy.
            </Alert>
            {isConnectVisible && (
                <PluggyConnect
                    connectToken={connectToken}
                    includeSandbox={isDevENV || isCurrentUserSuperAdmin}
                    onClose={onClosePopup}
                    onSuccess={async ({ item }) => {
                        try {
                            await PluggyController.storeItem({
                                itemId: item.id,
                                clientId: clientOn?.client?.id
                            });

                            setSuccess(true);
                            dispatch(allActions.scrollDialogActions.setScrollDialog({
                                ...scrollDialog,
                                update: true
                            }));
                        } catch (error) {
                            console.error("ERROR PLUGGY CONNECT: ", error);
                            triggerErrorAlert(error);
                        };
                    }}
                    onError={(err) => {
                        console.log("ERROR PLUGGY CONNECT: ", err);
                        triggerErrorAlert();
                    }}
                />
            )}
        </>
    );
}