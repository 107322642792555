import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { unoTheme } from '../../assets/styles/unoTheme';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        marginBottom: "8px",
        '& .dividerLine': {
            background: "#aaa",
            height: "2px",
            borderRadius: "50%",
            width: "100%",
            margin: "12px 0px",
        },
        '& .dividerTitle': {
            position: "absolute",
            left: 24,
            top: 0,
            background: "white",
            fontWeight: "bold",
            padding: "0px 8px",
            color: unoTheme.mainColor
        }
    },

    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    blue: {
        color: 'blue',
    },
}));

export default function MyDivider(props) {

    const classes = useStyles();
    const { title } = props;

    return (

        <div className={classes.root}>
            {title && <Typography className='dividerTitle'>{title}</Typography>}
            <div className='dividerLine'></div>
        </div>
    );
}