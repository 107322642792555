import { NOTIFICATION_TYPE } from "../reducers/notifications";

/**
 * @typedef {'pluggy_integration'} NotificationType
 * @typedef {{
 *  type: NotificationType;
 * }} Notification
 * @typedef {Notification[]} NewNotifications 
 * @typedef {Notification[]} NotificationsToRemove 
 * @typedef {{
 *  newNotifications: NewNotifications
 *  notificationsToRemove: NotificationsToRemove
 * }} NotificationPayload
 * @param {NotificationPayload} payload
 * @returns 
 */
const setNotifications = (payload) => {
    return {
        type: NOTIFICATION_TYPE,
        payload
    }
}

export default {
    setNotifications
}