import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';

//Router
import {
    Route,
    Switch
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

//Controllers

import { headerSearch, tabs, unoTheme } from '../../../../assets/styles/unoTheme';

import { TextField } from '@material-ui/core';
import { listClientAccountsByRegime } from '../../../../API/account';
import allActions from '../../../../actions';
import MySelect from '../../../utils/MySelect';
import { MyTab, MyTabs } from "../../../utils/MyTabs";
import { path } from "../../../utils/routesPaths";
import { removeAccents, verifyClientPortfolioIsSgregated } from '../../../utils/utils';
import ReturnsIPC14Tab from './tabs/ReturnsIPC14Tab';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '100px !important',
            width: 'auto',
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: '16px',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    flexCenterDiv: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    rightHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: "12px",
        '& .ipc14-components': {
            display: 'flex',
            alignItems: 'center',
            gap: "12px",
        }
    },
});

export default function AccountingScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const [tabValue, setTabValue] = useState(null);

    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [selectedRegime, setSelectedRegime] = useState(0);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        if (newValue == 0) {
            history.push(path.administrative.accounting.returnsIPC14)
        }

    };

    //IPCA14
    const [selectedAccount, setSelectedAccount] = useState(undefined);
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(undefined);
    const [clientAccounts, setClientAccounts] = useState([]);

    const loadAccounts = async (clientId, segregation) => {

        console.log("segregation: ", segregation);
        const response = await listClientAccountsByRegime(clientId, segregation == 0 ? null : segregation);

        console.log("RESPONSE: ", response)

        if (response.success) {

            const accs = response.body.rows;
            accs.unshift({
                clientId: clientId,
                id: 0,
            })
            setClientAccounts(accs)
            setSelectedAccountIndex(0);
            setSelectedAccount(accs[0]);

        } else {

            console.log("error: ", response.error)

        }
    }

    useEffect(() => {
        if (tabValue === 0) {
            loadAccounts(clientOn.client.id, selectedRegime); //segregação consolidada
        }
    }, [selectedRegime])

    useEffect(() => {
        console.log("selectedAccountIndex: ", selectedAccountIndex);
        if (clientAccounts.length) {
            const account = clientAccounts[selectedAccountIndex];
            console.log("account: ", account);
            setSelectedAccount(account);
        }
    }, [selectedAccountIndex])


    //FIM IPC14

    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(removeAccents(e.target.value.toUpperCase()))
        clearTimeout(delayedChange);
    }

    function doReport() {
        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: 'demonstrative',
            message: 'Gerando demonstrativo...'
        }))
    }

    const regimeOptions = useMemo(() => {

        const defaultOptions = [
            {
                name: "Consolidado",
                value: 0,
            },
            {
                name: "Previdenciária",
                value: 1,
            },
            {
                name: "Financeiro",
                value: 2,
            },
            {
                name: "Taxa Administrativa",
                value: 3,
            },
        ];

        if (!clientOn?.client) return defaultOptions;

        const isSegregated = verifyClientPortfolioIsSgregated(clientOn);

        return defaultOptions.filter(el => isSegregated || (!isSegregated && el.value !== 2));

    }, [clientOn])

    useEffect(() => {
        if (!clientOn?.client) return;
        if (!tabValue) {
            handleChangeTabValue(null, 0)
        }
        if (tabValue === 0) {
            loadAccounts(clientOn.client.id, null); //segregação consolidada
        }
    }, [tabValue])

    useEffect(() => {

        console.log("clientOn?.client?.id: ", clientOn?.client?.id);
        if (!clientOn?.client?.id) return;
        handleChangeTabValue(null, 0)
        loadAccounts(clientOn.client.id, null); //segregação consolidada

    }, [clientOn?.client?.id])


    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>

                <div className={[classes.tabs, classes.shortWidthTab].join(' ')} style={{ width: "auto" }}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Retornos" />
                        <MyTab label="Provisão de perdas" disabled />
                        <MyTab label="Ajustes Patrimoniais" disabled />
                        <MyTab label="Impairment" disabled />

                    </MyTabs>

                </div>


                <div className={classes.rightHeader}>

                    {/* <TextField
                        style={{ width: "170px", minWidth: "180px" }}
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Buscar fundo..."
                        variant="outlined"
                        size="small" /> */}

                    {
                        tabValue === 0 && <div className='ipc14-components'>

                            <MySelect
                                value={selectedRegime}
                                onChange={(e) => setSelectedRegime(e.target.value)}
                                options={regimeOptions.map((row, index) => (
                                    <option key={'regimes-accounting-filter-' + index} value={row.value}>
                                        {row.name}
                                    </option>
                                ))}

                            />

                            <MySelect
                                customWidth={'200px'}
                                value={selectedAccountIndex}
                                // initblank={'true'}
                                onChange={(e) => setSelectedAccountIndex(e.target.value)}
                                options={clientAccounts.map((row, index) => (
                                    <option key={'account-ipc14-' + row.id} value={index}>
                                        {
                                            row.id === 0 ?
                                                "Todas as contas"
                                                :
                                                (row.agency ? 'Ag: ' + row.agency + " - " : "") + 'CC: ' + row.number_account + ' / ' + row.description
                                        }
                                    </option>
                                ))}

                            />
                            {/* <MyToggleSegregationButton
                                marginRight
                                value={segregation}
                                handleValue={handleSegregation}
                                // disabled={innerLoad && !innerLoad.emptyMessage}
                                segregated={verifyClientPortfolioIsSgregated(clientOn)}
                            /> */}
                        </div>

                    }

                    {/* {
                        tabValue === 0 &&

                        <UnoButton
                            style={{ marginRight: '16px' }}
                            size={'small'}
                            onClick={() => doReport()}
                            type='primary'
                            startIcon={<PrintIcon />}>
                            {"Gerar relatório"}
                        </UnoButton>
                    } */}
                </div>


            </div>
            <Switch className={classes.switch}>
                <Route path={path.administrative.accounting.returnsIPC14}>
                    <ReturnsIPC14Tab
                        selectedRegime={selectedRegime}
                        searchText={searchText}
                        selectedAccount={selectedAccount} />
                </Route>
                {/* <Route path={path.administrative.demonstratives.funds}>
                    <DemonstrativeTab searchText={searchText} />
                </Route> */}

            </Switch>



        </div>
    );
}



