import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getArrayYears, applyMaskAmount, applyMaskAccount, getMonthName } from '../utils/utils';
import UnoButton from '../utils/UnoButton';

//Table
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import moment from 'moment'
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function DataImportModal({ open, handleModalImport, dataToInsert }) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    function formatInvalidDatesToShow(invalidDates) {
        let formatted = "";
        invalidDates.forEach(element => {
            formatted += "[ " + element + " ], "
        });
        formatted = formatted.slice(0, -3); //retirando último espaço e vírgula
        return formatted;
    }
    function formatInvalidAccountsToShow(invalidAccounts) {
        let formatted = "";
        invalidAccounts.forEach(element => {
            formatted += applyMaskAccount(element) + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatInvalidMonthsToShow(invalidMonths) {
        let formatted = "";
        invalidMonths.forEach(element => {
            formatted += '[' + getMonthName(element) + ']' + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatInvalidColumnsToShow(invalidColumns) {
        let formatted = "";
        invalidColumns.forEach(element => {
            formatted += element + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatMonthsToShow(months) {
        let formatted = "";
        months.forEach(element => {
            formatted += getMonthName(element) + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatInvalidYearsToShow(invalidYears) {
        let formatted = "";
        invalidYears.forEach(element => {
            formatted += element + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function groupAssets(assets) {

        let cloneAssets = _.cloneDeep(assets);
        let mapGroupAssets = [];
        let groupAssets = [];
        cloneAssets.forEach(ass => {

            let key = ass.fundInfos.cnpj;
            if (!mapGroupAssets.includes(key)) {

                mapGroupAssets.push(key);

                ass.subAssets = [];
                ass.subAssets.push(ass);
                groupAssets.push(ass);

            } else {

                let assetFiltered = groupAssets.filter(el => el.fundInfos.cnpj == ass.fundInfos.cnpj)

                if (assetFiltered && assetFiltered.length == 1) {

                    assetFiltered[0].subAssets.push(ass);
                }

            }
        });

        return groupAssets;
    }

    const useRowStyles = makeStyles({
        root: {
            '& .MuiTableCell-root': {
                fontSize: '12px !important',
            },
            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
            },
            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },


        },
        oddRow: {
            // backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            // '& .MuiTableRow-root.maybeOdd': {
            //     background: `${unoTheme.secondaryTuplaTable}`,
            // },
        },
        // noRadiusBottom: {
        //     '& .MuiTableCell-root:first-child': {
        //         borderTopLeftRadius: '10px !important',
        //         borderBottomLeftRadius: '0px !important',
        //         backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
        //     },
        //     '& .MuiTableCell-root:last-child': {
        //         borderTopRightRadius: '10px !important',
        //         borderBottomRightRadius: '0px !important',
        //         backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
        //     },
        // },
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
        },
        ok: {
            color: unoTheme.greenMoney,
            cursor: 'pointer',

            fontSize: '1.2em',

        },
        pendent: {
            color: unoTheme.warningColor,
            cursor: 'pointer',
            fontSize: '1.2em',

        },
        hiddenIcon: {
            visibility: 'hidden'
        },
        arrowTableIcon: {
            background: unoTheme.mainColor,
            color: 'white',
            borderRadius: '4px',
            height: '22px',
            width: '22px',

            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainButtonColor
            }
        },
        noPaddingCell: {
            padding: '0px !important',
            height: '1px',
        },
        ramification1: {
            display: 'flex',
            height: '100% !important',
            //width: '100% !important',
            alignItems: 'center',

            '& .rLeft': {
                height: '100%',
                width: '5px',
                marginLeft: '7px',
                '& .lTop': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },

                '& .lBottom': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },
            },

            '& .rRight': {
                height: '100%',
                width: '25px',
                '& .tTop': {
                    height: '50%',
                    width: '100%',
                    borderBottom: `solid 1px #dddee0`,
                },

                '& .tBottom': {
                    height: '50%',
                    width: '100%',
                },
            },

            '& .littleBall': {
                height: '7px',
                width: '8px',
                borderRadius: '50%',
                background: unoTheme.mainColor,
                marginTop: '-1.5px',
            }

        },
        tableFixed: {
            tableLayout: 'fixed',
        },
        borderOnOpen: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        noPaddingLeft: {
            paddingLeft: '0px',
        },
        noPaddingRight: {
            paddingRight: '0px !important',
        },
        // lastSubAssetBorderBottom: {
        //     '& > *': {
        //         borderBottom: 'unset !important',
        //     },
        // }

    });

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = React.useState(true);
        const classes = useRowStyles();

        return (
            <React.Fragment>
                <TableRow key={'asset-' + index}
                    className={[
                        index % 2 != 1 ? classes.oddRow : "",
                        classes.root,
                        open && index % 2 != 1 ? classes.noRadiusBottom : ""
                    ].join(' ')}>
                    <TableCell align={'left'} className={classes.noPaddingLeft}>
                        {row.fundInfos.fund_name} - {row.fundInfos.cnpj}
                    </TableCell>
                    {/* <TableCell align={'center'}>
                        {row.fundInfos.cnpj}
                    </TableCell> */}
                    <TableCell align="center">
                        {'Início'}
                    </TableCell>
                    <TableCell align="right" className={[classes.noPaddingRight].join(' ')} >
                        {'Saldo'}
                    </TableCell>

                </TableRow>
                <TableRow
                    className={[classes.innerTableRow, index % 2 == 0 ? classes.oddRow : "", open ? "" : classes.borderOnOpen].join(' ')}>
                    <TableCell style={{ padding: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box>
                                <Table size="small"
                                    className={classes.tableFixed}>
                                    <colgroup>
                                        <col width="60%" />
                                        <col width="20%" />
                                        <col width="20%" />
                                    </colgroup>

                                    {

                                        row && row.subAssets ?
                                            <TableBody>
                                                {row.subAssets.map((subAsset, index) => (
                                                    <TableRow key={'sub-asset-to-insert-' + index}
                                                        className={[classes.root].join(' ')}>
                                                        <TableCell align="left"
                                                            style={{ display: 'flex', alignItems: 'center', height: '30px' }}
                                                            className={classes.noPaddingCell}>

                                                            <div className={classes.ramification1}>

                                                                <div className={'rLeft'}>
                                                                    <div className={'lTop'}>

                                                                    </div>
                                                                    {
                                                                        index == row.subAssets.length - 1 ?
                                                                            null
                                                                            :
                                                                            <div className={'lBottom'}>

                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className={'rRight'}>
                                                                    <div className={'tTop'}>

                                                                    </div>

                                                                    <div className={'tBottom'}>

                                                                    </div>

                                                                </div>
                                                                <div className={'littleBall'}></div>
                                                            </div>

                                                            {/* <IconButton aria-label="expand row" size="small"
                                                                className={[classes.arrowTableIcon].join(' ')}>
                                                                <KeyboardArrowUpIcon />
                                                            </IconButton> */}
                                                            <span style={{ paddingLeft: '5px' }}>{'CC: ' + applyMaskAccount(subAsset.number_account)} {subAsset.agency ? ' / ' + subAsset.agency : ''}</span>

                                                        </TableCell>
                                                        <TableCell align={'center'} style={{ color: unoTheme.mainColor }}>
                                                            {row.transaction_date.substring(3)}
                                                        </TableCell>

                                                        <TableCell align="right" className={[classes.green, classes.noPaddingRight].join(' ')}>
                                                            {
                                                                subAsset.balance != 'Não informado' ?
                                                                    applyMaskAmount(subAsset.balance, true)
                                                                    : 'Não informado'
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody> : null
                                    }

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    function isOkToImport() {


        return dataToInsert &&
            (dataToInsert.rightFileName == null) &&
            dataToInsert.invalidDates.length == 0 &&
            dataToInsert.invalidNoCnpjs == 0 &&
            dataToInsert.invalidColumns.length == 0 &&
            dataToInsert.newMonthsToInsert.length > 0 &&
            dataToInsert.invalidsAccounts.length == 0 &&
            dataToInsert.invalidsFunds.length == 0 &&
            dataToInsert.transactions.length > 0 &&
            dataToInsert.accountsWhite == 0 &&
            dataToInsert.yearsToInsert.length == 1
            && dataToInsert.invalidMonths.length == 0
            //Agora não cria mais assets
            && dataToInsert.assets.length == 0



        //!dataToInsert.alreadyImported 

    }

    useEffect(() => {

        console.log("DATA TO INSERT: ", dataToInsert);
    }, [dataToInsert])

    return (
        <Dialog
            fullWidth={true}
            // maxWidth={dataToInsert &&
            //     dataToInsert.invalidColumns.length == 0 &&
            //     dataToInsert.newMonthsToInsert.length > 0 &&
            //     dataToInsert.invalidsAccounts.length == 0 &&
            //     dataToInsert.invalidsFunds.length == 0 &&
            //     dataToInsert.accountsWhite == 0 &&
            //     dataToInsert.yearsToInsert.length <= 1
            //     ? 'md' : 'sm'}
            maxWidth={'md'}
            open={open}
            onClose={() => handleModalImport(false)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description">
            <DialogTitle
                className={classes.dialogTitleApr}
                id="dialog-title-apr">
                {

                    isOkToImport() ?
                        <><CheckCircleIcon className={[classes.spacingRight2, classes.green].join(' ')} />Planilha válida</>
                        :
                        <><WarningIcon className={[classes.spacingRight2, classes.warningColor].join(' ')} />Planilha inválida</>
                }


            </DialogTitle>
            {

                //Se não há nenhuma coluna inválida
                //Se não há nenhuma conta inválida
                //e existe pelo menos um novo mês a ser inserido
                //nenhum fundo inválido
                //nenhuma conta em branco
                //e existe apenas um ano pra ser inserido
                //LIBERA
                isOkToImport() ?

                    //Validação OK
                    <DialogContent
                        className={classes.dialogContent}>
                        <Typography className={classes.afirmativeText}>
                            CLIENTE
                        </Typography>
                        <Typography className={classes.label}>
                            {dataToInsert && dataToInsert.client ?
                                dataToInsert.client.label_name
                                : null
                            }
                        </Typography>
                        <br />
                        <Typography className={classes.afirmativeText}>
                            {'ANO'}
                        </Typography>
                        <Typography className={classes.label}>
                            {dataToInsert && dataToInsert.transactions && dataToInsert.transactions[0] ?
                                dataToInsert.transactions[0].transaction_date.substring(6)
                                : null
                            }
                        </Typography>

                        <br />
                        <Typography className={classes.afirmativeText}>

                            {dataToInsert && dataToInsert.newMonthsToInsert
                                && dataToInsert.newMonthsToInsert.length == 1 ? 'MÊS A SER CADASTRADO' : 'MESES A SEREM CADASTRADOS'}

                        </Typography>
                        <Typography className={classes.label}>

                            {dataToInsert && dataToInsert.newMonthsToInsert && dataToInsert.newMonthsToInsert.length == 1 ?
                                getMonthName(dataToInsert.newMonthsToInsert[0]) : formatMonthsToShow(dataToInsert.newMonthsToInsert)
                            }

                        </Typography>

                        <br />
                        {/* {
                            dataToInsert
                                && dataToInsert.assets
                                && dataToInsert.assets.length > 0 ?
                                <>                                    
                                    <Typography className={classes.afirmativeText}>
                                        {'ATIVOS A SEREM CRIADOS'}
                                    </Typography>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table size="small" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.noPaddingLeft} align="left">Fundo</TableCell>
                                                    <TableCell align="center">CNPJ</TableCell>
                                                    <TableCell align="center">Conta</TableCell>
                                                    <TableCell align="center">Início</TableCell>
                                                    <TableCell className={classes.noPaddingRight} align="right">Saldo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                dataToInsert ?
                                                    <TableBody>
                                                        {groupAssets(dataToInsert.assets).map((row, index) => (

                                                            <TableRow
                                                                className={classes.assetsToCreateRow}
                                                                key={'asset-to-create-' + index}>
                                                                <TableCell align="left" className={classes.noPaddingLeft} component="th" scope="row">{row.fundInfos.fund_name}</TableCell>
                                                                <TableCell align="center">{row.fundInfos.cnpj}</TableCell>
                                                                <TableCell align="center">{applyMaskAccount(row.number_account)}</TableCell>
                                                                <TableCell align="center">{row.transaction_date.substring(3)}</TableCell>
                                                                <TableCell align="right" className={[classes.green, classes.noPaddingRight].join(' ')}>{applyMaskAmount(row.balance, true)}</TableCell>
                                                            </TableRow>

                                                        ))}
                                                    </TableBody> : null
                                            }

                                        </Table>
                                    </TableContainer>
                                    <br />
                                </> : null
                        } */}

                        {/* {
                            dataToInsert
                                && dataToInsert.assets
                                && dataToInsert.assets.length > 0 ?
                                <>

                                    <Typography className={classes.afirmativeText}>
                                        {'ATIVOS A SEREM CRIADOS'}
                                    </Typography>
                                    <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                        <Table className={classes.table} size="small" >
                                            <colgroup>
                                                <col width="60%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                            </colgroup>                                        
                                            <TableBody>
                                                {groupAssets(dataToInsert.assets).map((row, index) => (
                                                    <Row key={'asset-to-insert-' + index} row={row} index={index} />
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                    <br />
                                </> : null
                        } */}
                        <Typography className={classes.afirmativeText}>
                            QTD DE MOVIMENTAÇÕES
                        </Typography>
                        <Typography className={classes.label}>
                            {dataToInsert && dataToInsert.transactions ?
                                `${dataToInsert.transactions.length} movimentações encontradas`
                                : null
                            }
                        </Typography>

                    </DialogContent>
                    :
                    //INVALIDA
                    <DialogContent
                        className={classes.dialogContent}>

                        {/* Planilha inválida */}
                        <Typography className={classes.afirmativeText}>
                            CLIENTE
                        </Typography>
                        <Typography className={classes.label}>
                            {dataToInsert && dataToInsert.client ?
                                dataToInsert.client.label_name
                                : null
                            }
                        </Typography>

                        {
                            dataToInsert && dataToInsert.rightFileName ?
                                <>
                                    <br />
                                    <Typography className={classes.afirmativeText}>
                                        {'O título da planilha não corresponde ao cliente selecionado'}
                                    </Typography>

                                    <br />

                                    <Typography variant='body1'>
                                        <span>Título informado: <span className={classes.red}>{dataToInsert.currentFileName}</span></span>
                                    </Typography>

                                    <Typography variant='body1'>
                                        <span>Título correto: <span className={classes.green}>{dataToInsert.rightFileName}</span></span>
                                    </Typography>
                                </>
                                :
                                <>
                                    <br />
                                    <Typography className={classes.afirmativeText}>
                                        {'ANO'}
                                    </Typography>
                                    <Typography className={classes.label}>
                                        {dataToInsert &&
                                            dataToInsert.yearsToInsert &&
                                            dataToInsert.yearsToInsert.length > 0 ?
                                            dataToInsert.yearsToInsert[0]
                                            : null
                                        }
                                    </Typography>

                                    {/* Existem colunas do padrão que não foram informadas na planilha */}
                                    {
                                        dataToInsert
                                            && dataToInsert.invalidColumns.length > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Algumas colunas obrigatórias não foram encontradas na planilha. Verifique se a primeira linha após o cabeçalho [linha 2] encontra-se corretamente preenchida'}
                                                </Typography>
                                                <Typography className={[classes.blue].join(' ')}>
                                                    {dataToInsert && dataToInsert.invalidColumns ?
                                                        formatInvalidColumnsToShow(dataToInsert.invalidColumns)
                                                        : null
                                                    }
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert &&
                                            dataToInsert.newMonthsToInsert.length == 0 &&
                                            dataToInsert.invalidColumns.length == 0 &&
                                            dataToInsert.invalidsAccounts.length == 0 &&
                                            dataToInsert.invalidsFunds.length == 0 &&
                                            dataToInsert.accountsWhite == 0
                                            && dataToInsert.yearsToInsert.length == 1 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'INFORMAÇÃO'}
                                                </Typography>
                                                <Typography className={classes.label}>
                                                    {'Todos os meses instanciados na planilha já foram importados'}
                                                </Typography>
                                            </> : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.invalidDates.length > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Algumas datas estão com formato inválido na planilha'}
                                                </Typography>
                                                <Typography className={[classes.blue].join(' ')}>
                                                    {dataToInsert && dataToInsert.invalidDates ?
                                                        formatInvalidDatesToShow(dataToInsert.invalidDates)
                                                        : null
                                                    }
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.transactions.length == 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Não foram encontradas movimentações válidas, verificar se existem movimentações de anos diferentes na mesma planilha.'}
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.invalidsAccounts.length > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Algumas contas precisam ser cadastradas'}
                                                </Typography>
                                                <Typography className={[classes.blue].join(' ')}>
                                                    {dataToInsert && dataToInsert.invalidsAccounts ?
                                                        formatInvalidAccountsToShow(dataToInsert.invalidsAccounts)
                                                        : null
                                                    }
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.invalidMonths.length > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Existem movimentações para datas anteriores ao ínicio da carteira, verifique se a data de início de carteira foi corretamente cadastrada. O início da carteira deve ser o mês anterior ao início das movimentações'}
                                                </Typography>
                                                <Typography className={[classes.blue].join(' ')}>
                                                    {dataToInsert && dataToInsert.invalidMonths ?
                                                        formatInvalidMonthsToShow(dataToInsert.invalidMonths)
                                                        : null
                                                    }
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.yearsToInsert.length > 1 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'A planilha contém movimentações para dois ou mais anos diferentes'}
                                                </Typography>
                                                <Typography className={[classes.blue].join(' ')}>
                                                    {dataToInsert && dataToInsert.yearsToInsert ?
                                                        formatInvalidYearsToShow(dataToInsert.yearsToInsert)
                                                        : null
                                                    }
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.invalidNoCnpjs > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {`Existem [ ${dataToInsert.invalidNoCnpjs} ] linhas na planilha que não possuem CNPJ infomado`}
                                                </Typography>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.invalidsFunds.length > 0 && dataToInsert.invalidNoCnpjs == 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Fundos não cadastrados na base local'}
                                                </Typography>
                                                <TableContainer component={Paper} elevation={0}>
                                                    <Table size="small" aria-label="simple table">
                                                        {
                                                            dataToInsert ?
                                                                <TableBody>
                                                                    {dataToInsert.invalidsFunds.map((row, index) => (

                                                                        <TableRow
                                                                            className={classes.assetsToCreateRow}
                                                                            key={'fund-to-local-create-' + index}>
                                                                            <TableCell align="left" className={classes.noPaddingLeft} component="th" scope="row">{row.cnpj}</TableCell>
                                                                            {/* <TableCell align="right" className={classes.noPaddingRight}>{row.cnpj}</TableCell> */}
                                                                        </TableRow>

                                                                    ))}
                                                                </TableBody> : null
                                                        }

                                                    </Table>
                                                </TableContainer>
                                            </>
                                            : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.assets
                                            && dataToInsert.assets.length > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'ATIVOS NÃO CADASTRADOS'}
                                                </Typography>
                                                <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                                    <Table className={classes.table} size="small" >
                                                        <colgroup>
                                                            <col width="60%" />
                                                            <col width="20%" />
                                                            <col width="20%" />
                                                        </colgroup>
                                                        <TableBody>
                                                            {groupAssets(dataToInsert.assets).map((row, index) => (
                                                                <Row key={'asset-to-insert-' + index} row={row} index={index} />
                                                            ))}
                                                        </TableBody>

                                                    </Table>
                                                </TableContainer>
                                                <br />
                                            </> : null
                                    }

                                    {
                                        dataToInsert
                                            && dataToInsert.accountsWhite > 0 ?
                                            <>
                                                <br />
                                                <Typography className={classes.afirmativeText}>
                                                    {'Existem na planilha registros com a coluna de conta em branco'}
                                                </Typography>
                                            </> : null
                                    }
                                </>
                        }



                    </DialogContent>
            }


            {

                isOkToImport() ?

                    <DialogActions className={classes.actionsFlex}>

                        <UnoButton onClick={() =>
                            handleModalImport(false)} color="secondary">
                            {'Cancelar'}
                        </UnoButton>
                        <UnoButton
                            className={classes.spaceRight2}
                            onClick={() => handleModalImport(true)}
                            color="primary">
                            {'Iniciar importação'}
                        </UnoButton>
                    </DialogActions>

                    :

                    <DialogActions className={classes.actionsFlexRight}>


                        <UnoButton
                            onClick={() =>
                                handleModalImport(false)}
                            type="default">
                            {'Fechar'}
                        </UnoButton>

                    </DialogActions>

            }


        </Dialog >
    );
}