/**
* Retorna os sufixos possíveis da comdinheiro
* @return {Array} Array com os sufixos possíveis
*/

const suffixes = [
    'SEM SUFIXO',
    'senior1',
    'subordinada1'
];

export function getSuffixesComdinheiro() {

    return suffixes;
}

export function getIndexSuffixComdinheiro(suffix) {

    if (!suffix) return 0;

    for (let index = 0; index < suffixes.length; index++) {
        const element = suffixes[index];

        if (element === suffix) {
            return index;
        }

    }

    return 0;
}