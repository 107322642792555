import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { getArrayYears, applyMaskAmount, applyMaskAccount } from '../utils/utils';
import MySelect from '../utils/MySelect';

import UnoButton from '../utils/UnoButton';

//Table
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import moment from 'moment'

import _ from 'lodash';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    // dialogContent: {
    //     display: 'flex',
    //     background: "#eeeeee",
    //     alignItems: 'center',
    //     flexDirection: 'column',
    // },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    toCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function DataImportModalValidationBalances({ open, handleModalImport, dataToInsert, initImport }) {

    const classes = useStyles();

    const [dupplicatedAccounts, setDupplicatedAccounts] = useState(null);
    const [dupplicatedAccountValidateMessage, setDupplicatedAccountValidateMessage] = useState('');

    function formatInvalidColumnsToShow(invalidColumns) {
        let formatted = "";
        invalidColumns.forEach(element => {
            formatted += element + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatFundsToShow(invalidsFunds) {
        let formatted = "";
        invalidsFunds.forEach(element => {
            formatted += element.fund_name + "[" + element.cnpj + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatInstituitionsToShow(invalidsInstituitions) {
        let formatted = "";
        invalidsInstituitions.forEach(element => {
            formatted += "[" + element + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatAssetsToShow(invalidsAssets) {
        let formatted = "";
        invalidsAssets.forEach(element => {
            formatted += "CC: " + element.account + " [" + element.fund + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function formatBlankAssetsToShow(blankAssets) {
        let formatted = "";
        blankAssets.forEach(element => {
            formatted += "CC: " + (element.number_account ? applyMaskAccount(element.number_account) : '-') + " [" + (element.fundInfos ? element.fundInfos.cnpj : '-') + "]" + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function changeInstiuitionDupplicatedAccount(indexAccount, value) {
        let newDupplicatedAccounts = [...dupplicatedAccounts];

        newDupplicatedAccounts[indexAccount].instituitionSelected = value;
        setDupplicatedAccounts(newDupplicatedAccounts);
    }

    function formatInvalidCnpjsToShow(invalidCnpjs) {
        let formatted = "";
        invalidCnpjs.forEach(element => {
            formatted += element + ", "
        });
        formatted = formatted.slice(0, -2); //retirando último espaço e vírgula
        return formatted;
    }

    function checkOkToImport() {

        let elementsNoOk = [];

        if (dataToInsert.validationsNotOK && dataToInsert.validationsNotOK.length > 0) {
            elementsNoOk.push('VALIDATIONS_NOT_OK');
        }

        if (dataToInsert.invalidColumns && dataToInsert.invalidColumns.length > 0) {
            elementsNoOk.push('COLUMNS');
        }

        if (dataToInsert.invalidsFunds && dataToInsert.invalidsFunds.length > 0) {
            elementsNoOk.push('FUNDS');
        }

        if (dataToInsert.invalidsInstituitions && dataToInsert.invalidsInstituitions.length > 0) {
            elementsNoOk.push('INSTITUITIONS');
        }

        if (dataToInsert.accountsWhite && dataToInsert.accountsWhite.length > 0) {
            elementsNoOk.push('ACCOUNTS_WHITE');
        }

        if (dataToInsert.assetsInvalidAccount && dataToInsert.assetsInvalidAccount.length > 0) {
            elementsNoOk.push('ASSETS_INVALID_ACCOUNT');
        }

        if (dataToInsert.validations && dataToInsert.validations.length == 0) {
            elementsNoOk.push('NO_VALIDATIONS');
        }

        if (dataToInsert.duplicatedAccounts && dataToInsert.duplicatedAccounts.length > 0) { //Indica que há duplicação de contas
            elementsNoOk.push('DUPLICATION_ACCOUNTS');
        }

        if (dataToInsert.invalidInitAssets && dataToInsert.invalidInitAssets.length > 0) { //Indica que há ativos iniciados em datas que já foram fechadas
            elementsNoOk.push('INVALID_INIT_ASSETS');
        }

        if (dataToInsert.blankAssets && dataToInsert.blankAssets.length > 0) { //Ativos sem informação na planilha
            elementsNoOk.push('BLANK_ASSETS');
        }

        if (dataToInsert.rightFileName) { //Indica que o arquivo importado não pertence ao cliente selecionado
            elementsNoOk.push('INVALID_FILE_NAME');
        }

        if (dataToInsert.invalidCnpjs && dataToInsert.invalidCnpjs.length > 0) { //Indica que o arquivo importado não pertence ao cliente selecionado
            elementsNoOk.push('INVALID_CNPJS');
        }

        if (dataToInsert.fundsWithClasses && dataToInsert.fundsWithClasses.length > 0) { //Indica que o arquivo importado não pertence ao cliente selecionado
            elementsNoOk.push('CLASS_B_FUNDS');
        }

        return elementsNoOk;
    }

    function groupAssets(assets) {

        let cloneAssets = _.cloneDeep(assets);
        let mapGroupAssets = [];
        let groupAssets = [];
        cloneAssets.forEach(ass => {

            let key = ass.fundInfos.cnpj;
            if (!mapGroupAssets.includes(key)) {

                mapGroupAssets.push(key);

                ass.subAssets = [];
                ass.subAssets.push(ass);
                groupAssets.push(ass);

            } else {

                let assetFiltered = groupAssets.filter(el => el.fundInfos.cnpj == ass.fundInfos.cnpj)

                if (assetFiltered && assetFiltered.length == 1) {

                    assetFiltered[0].subAssets.push(ass);
                }

            }
        });

        return groupAssets;
    }

    const useRowStyles = makeStyles({
        root: {
            '& .MuiTableCell-root': {
                fontSize: '12px !important',
            },
            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
            },
            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },


        },
        oddRow: {
            // backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            // '& .MuiTableRow-root.maybeOdd': {
            //     background: `${unoTheme.secondaryTuplaTable}`,
            // },
        },
        // noRadiusBottom: {
        //     '& .MuiTableCell-root:first-child': {
        //         borderTopLeftRadius: '10px !important',
        //         borderBottomLeftRadius: '0px !important',
        //         backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
        //     },
        //     '& .MuiTableCell-root:last-child': {
        //         borderTopRightRadius: '10px !important',
        //         borderBottomRightRadius: '0px !important',
        //         backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
        //     },
        // },
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
        },
        ok: {
            color: unoTheme.greenMoney,
            cursor: 'pointer',

            fontSize: '1.2em',

        },
        pendent: {
            color: unoTheme.warningColor,
            cursor: 'pointer',
            fontSize: '1.2em',

        },
        hiddenIcon: {
            visibility: 'hidden'
        },
        arrowTableIcon: {
            background: unoTheme.mainColor,
            color: 'white',
            borderRadius: '4px',
            height: '22px',
            width: '22px',

            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainButtonColor
            }
        },
        noPaddingCell: {
            padding: '0px !important',
            height: '1px',
        },
        ramification1: {
            display: 'flex',
            height: '100% !important',
            //width: '100% !important',
            alignItems: 'center',

            '& .rLeft': {
                height: '100%',
                width: '5px',
                marginLeft: '7px',
                '& .lTop': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },

                '& .lBottom': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },
            },

            '& .rRight': {
                height: '100%',
                width: '25px',
                '& .tTop': {
                    height: '50%',
                    width: '100%',
                    borderBottom: `solid 1px #dddee0`,
                },

                '& .tBottom': {
                    height: '50%',
                    width: '100%',
                },
            },

            '& .littleBall': {
                height: '7px',
                width: '8px',
                borderRadius: '50%',
                background: unoTheme.mainColor,
                marginTop: '-1.5px',
            }

        },
        tableFixed: {
            tableLayout: 'fixed',
        },
        borderOnOpen: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        noPaddingLeft: {
            paddingLeft: '0px',
        },
        noPaddingRight: {
            paddingRight: '0px !important',
        },
        // lastSubAssetBorderBottom: {
        //     '& > *': {
        //         borderBottom: 'unset !important',
        //     },
        // }

    });

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = React.useState(true);
        const classes = useRowStyles();

        return (
            <React.Fragment>
                <TableRow key={'asset-' + index}
                    className={[
                        index % 2 != 1 ? classes.oddRow : "",
                        classes.root,
                        open && index % 2 != 1 ? classes.noRadiusBottom : ""
                    ].join(' ')}>
                    <TableCell align={'left'} className={classes.noPaddingLeft}>
                        {row.fundInfos.fund_name} - {row.fundInfos.cnpj}
                    </TableCell>
                    {/* <TableCell align={'center'}>
                        {row.fundInfos.cnpj}
                    </TableCell> */}
                    <TableCell align="center">
                        {'Início'}
                    </TableCell>
                    <TableCell align="right" className={[classes.noPaddingRight].join(' ')} >
                        {'Saldo'}
                    </TableCell>

                </TableRow>
                <TableRow
                    className={[classes.innerTableRow, index % 2 == 0 ? classes.oddRow : "", open ? "" : classes.borderOnOpen].join(' ')}>
                    <TableCell style={{ padding: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box>
                                <Table size="small"
                                    className={classes.tableFixed}>
                                    <colgroup>
                                        <col width="60%" />
                                        <col width="20%" />
                                        <col width="20%" />
                                    </colgroup>

                                    {

                                        row && row.subAssets ?
                                            <TableBody>
                                                {row.subAssets.map((subAsset, index) => (
                                                    <TableRow key={'sub-asset-to-insert-' + index}
                                                        className={[classes.root].join(' ')}>
                                                        <TableCell align="left"
                                                            style={{ display: 'flex', alignItems: 'center', height: '30px' }}
                                                            className={classes.noPaddingCell}>

                                                            <div className={classes.ramification1}>

                                                                <div className={'rLeft'}>
                                                                    <div className={'lTop'}>

                                                                    </div>
                                                                    {
                                                                        index == row.subAssets.length - 1 ?
                                                                            null
                                                                            :
                                                                            <div className={'lBottom'}>

                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className={'rRight'}>
                                                                    <div className={'tTop'}>

                                                                    </div>

                                                                    <div className={'tBottom'}>

                                                                    </div>

                                                                </div>
                                                                <div className={'littleBall'}></div>
                                                            </div>

                                                            {/* <IconButton aria-label="expand row" size="small"
                                                                className={[classes.arrowTableIcon].join(' ')}>
                                                                <KeyboardArrowUpIcon />
                                                            </IconButton> */}
                                                            <span style={{ paddingLeft: '5px' }}>{'CC: ' + applyMaskAccount(subAsset.number_account)} {subAsset.agency ? ' / ' + subAsset.agency : ''}</span>

                                                        </TableCell>
                                                        <TableCell align={'center'} style={{ color: unoTheme.mainColor }}>
                                                            {subAsset.asset_init.substring(3)}
                                                        </TableCell>

                                                        <TableCell align="right" className={[classes.green, classes.noPaddingRight].join(' ')}>
                                                            {
                                                                subAsset.balance != 'Não informado' ?
                                                                    applyMaskAmount(subAsset.balance, true)
                                                                    : 'Não informado'
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody> : null
                                    }

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    function validDupplicatedAccounts(dataToInsert) {

        console.log('dupplicatedAccounts: ', dupplicatedAccounts);
        setDupplicatedAccountValidateMessage('');

        if (dupplicatedAccounts.some(el => el.instituitionSelected == null)) {

            setDupplicatedAccountValidateMessage('Selecione uma instituição para cada ativo apresentado');

        } else {

            // let isUnique = true;
            // let uniqueInstituitions = [];
            // dupplicatedAccounts.forEach(element => {

            //     if (uniqueInstituitions.includes(element.instituitionSelected)) {
            //         isUnique = false;
            //     }

            //     uniqueInstituitions.push(element.instituitionSelected);

            // });


            if (dupplicatedAccounts.length > 1) {
                //Verificar se todas são iguais, pois não podem ser iguais
                const instituitionsSelected = [];
                dupplicatedAccounts.forEach(element => {

                    instituitionsSelected.push(element.instituitionSelected);

                });

                if (instituitionsSelected.every((val, i, arr) => val === arr[0])) {
                    console.log('instituitionsSelected: ', instituitionsSelected);
                    setDupplicatedAccountValidateMessage('As instituições não podem ser todas iguais');
                } else {
                    initImport({ dupplicatedAccounts: dupplicatedAccounts })
                }
            } else {
                initImport({ dupplicatedAccounts: dupplicatedAccounts })
            }


        }



    }


    useEffect(() => {

        console.log("DATA TO INSERT MODAL: ", dataToInsert);
        setDupplicatedAccountValidateMessage('')
        if (dataToInsert && dataToInsert.duplicatedAccounts) {
            console.log("SETANDO AQUI");
            setDupplicatedAccounts(_.cloneDeep(dataToInsert.duplicatedAccounts));
        }

    }, [dataToInsert])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={dataToInsert
                && (checkOkToImport().length == 0 && (dataToInsert.accountsToCreate.length > 0 || dataToInsert.assets.length > 0))
                || (dataToInsert && dataToInsert.invalidInitAssets.length > 0)
                ? 'md' : 'sm'}
            open={open}
            onClose={() => handleModalImport(false)}>
            <DialogTitle
                className={classes.dialogTitleApr}>
                {
                    dataToInsert
                        && checkOkToImport().length == 0 ?
                        <><CheckCircleIcon className={[classes.spacingRight2, classes.green].join(' ')} />Planilha válida</>
                        :
                        <><WarningIcon className={[classes.spacingRight2, classes.warningColor].join(' ')} />Planilha inválida</>
                }


            </DialogTitle>

            <DialogContent
                className={classes.dialogContent}>

                {
                    dataToInsert &&
                        checkOkToImport().includes('INVALID_FILE_NAME') ?

                        <>
                            <Typography className={classes.afirmativeText}>
                                {'O título da planilha não corresponde ao cliente selecionado'}
                            </Typography>

                            <br />

                            <Typography variant='body1'>
                                <span>Título informado: <span className={classes.red}>{dataToInsert.currentFileName}</span></span>
                            </Typography>

                            <Typography variant='body1'>
                                <span>Título correto: <span className={classes.green}>{dataToInsert.rightFileName}</span></span>
                            </Typography>
                        </>

                        :

                        null

                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('CLASS_B_FUNDS') ?


                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Existem ATIVOS a serem criados que possuem múltiplas classes, os ativos nesse caso devem ser criados previamente de forma manual'}
                            </Typography>
                            <br />
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.fundsWithClasses ?
                                    formatFundsToShow(dataToInsert.fundsWithClasses)
                                    : null
                                }
                            </Typography>
                        </>
                        : null

                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('VALIDATIONS_NOT_OK') ?

                        <Typography className={classes.afirmativeText}>
                            {'Verifique se faz a importação da planilha do ano correto [ '
                                + dataToInsert.selectedValidationYear
                                + ' ]'}
                        </Typography>

                        :

                        <>

                            {
                                dataToInsert &&
                                    checkOkToImport().length == 0 ?
                                    <>
                                        <Typography className={classes.afirmativeText}>
                                            {'ANO'}
                                        </Typography>
                                        <Typography className={classes.label}>
                                            {dataToInsert && dataToInsert.selectedValidationYear ?
                                                dataToInsert.selectedValidationYear
                                                : null
                                            }
                                        </Typography>
                                    </> : null
                            }

                        </>

                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('COLUMNS') ?

                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Algumas colunas obrigatórias não foram encontradas na planilha. A planilha de saldos de ' + dataToInsert.selectedValidationYear + ' é identificada pelas colunas:'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.invalidColumns ?
                                    formatInvalidColumnsToShow(dataToInsert.invalidColumns)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert && checkOkToImport().length == 1 &&
                        checkOkToImport().includes('DUPLICATION_ACCOUNTS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Foi verificado que alguns números de conta encontram-se duplicados. Isso pode ocorrer por haver duas contas com o mesmo número mas de instituições diferentes. Nesse caso, selecione o código de instituição manualmente para que posso prosseguir com a operação: '}
                            </Typography>
                            <br />
                            <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                <Table className={classes.table} size="small" >
                                    <colgroup>
                                        <col width="50%" />
                                        <col width="20%" />
                                        <col width="30%" />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className={classes.noPaddingLeft}>CNPJ</TableCell>
                                            <TableCell align="center">CC</TableCell>
                                            <TableCell align="right" className={classes.noPaddingRight}>Cód. Instituição</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dupplicatedAccounts && dupplicatedAccounts.map((rowAccount, index) => (
                                            <TableRow key={'dupliccated-account-' + index}>
                                                <TableCell align={'left'} className={classes.noPaddingLeft}>
                                                    {rowAccount.cnpj}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {applyMaskAccount(rowAccount.cc)}
                                                </TableCell>
                                                <TableCell align="right" className={[classes.noPaddingRight].join(' ')} >
                                                    <MySelect
                                                        value={rowAccount.instituitionSelected && parseInt(rowAccount.instituitionSelected)}
                                                        verysmall
                                                        initblank
                                                        onChange={(e) => changeInstiuitionDupplicatedAccount(index, e.target.value)}
                                                        options={dataToInsert.instituitions.map((row, index) => (

                                                            <option key={'instituition-account-' + index} value={index}>{row}</option>

                                                        ))}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {/* {dataToInsert && dataToInsert.duplicatedAccounts && dupplicatedAccounts ?
                                            formatInvalidAccountsToShow(dupplicatedAccounts)
                                            : null
                                        } */}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            {dupplicatedAccountValidateMessage !== '' ?
                                <>
                                    <Typography variant={"body2"} style={{ color: 'red', textAlign: 'center', marginTop: '16px', padding: '16px', backgroundColor: '#eeeeee', borderRadius: '5px' }}>
                                        {dupplicatedAccountValidateMessage}
                                    </Typography>
                                </>
                                : null
                            }
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('INVALID_CNPJS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Alguns dados informados na coluna de CNPJ estão inconsistentes. Verifique se os valores estão corretos. Ex: CNPJ com algum caractere extra'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.invalidCnpjs ?
                                    formatInvalidCnpjsToShow(dataToInsert.invalidCnpjs)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('FUNDS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Alguns fundos não foram encontrados na base de dados'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.invalidsFunds ?
                                    formatFundsToShow(dataToInsert.invalidsFunds)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('INSTITUITIONS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Algumas instituilções não foram encontrados na base de dados'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.invalidsInstituitions ?
                                    formatInstituitionsToShow(dataToInsert.invalidsInstituitions)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('ASSETS_INVALID_ACCOUNT') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Alguns ativos possuem contas inválidas'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.assetsInvalidAccount ?
                                    formatAssetsToShow(dataToInsert.assetsInvalidAccount)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('BLANK_ASSETS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Alguns ativos estão sem informação'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {dataToInsert && dataToInsert.blankAssets ?
                                    formatBlankAssetsToShow(dataToInsert.blankAssets)
                                    : null
                                }
                            </Typography>
                        </>
                        : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('INVALID_INIT_ASSETS') ?


                        <>

                            <br />
                            <Typography className={classes.afirmativeText}>
                                {`Alguns ativos estão sendo importados com início inválido, a data de início deve ser maior ou igual a data atual do fechamento da carteira do cliente [ ${dataToInsert.clientPortfolioClosed} ]`}
                            </Typography>
                            <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                <Table className={classes.table} size="small" >
                                    <colgroup>
                                        <col width="60%" />
                                        <col width="20%" />
                                        <col width="20%" />
                                    </colgroup>
                                    {/* <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.noPaddingLeft}>Fundo</TableCell>                                                    
                                        <TableCell align="center">Início</TableCell>
                                        <TableCell align="right" className={classes.noPaddingRight}>Saldo</TableCell>
                                    </TableRow>
                                </TableHead> */}
                                    <TableBody>
                                        {groupAssets(dataToInsert.invalidInitAssets).map((row, index) => (
                                            <Row key={'invalid-asset-to-insert-' + index} row={row} index={index} />
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            <br />
                        </> : null
                }

                {
                    dataToInsert &&
                        checkOkToImport().includes('NO_VALIDATIONS') ?
                        <>
                            <br />
                            <Typography className={classes.afirmativeText}>
                                {'Nenhum dado encontrado'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {'- Verifique o formato e as colunas da planilha'}
                            </Typography>
                            <Typography className={[classes.blue].join(' ')}>
                                {'- Verifique se os cabeçalhos estão em formato "GERAL"'}
                            </Typography>
                        </>
                        : null
                }

                {dataToInsert
                    && checkOkToImport().length == 0 ?
                    <>
                        {
                            dataToInsert && dataToInsert.accountsToCreate.length > 0 ?
                                <>
                                    {/* AccountsToCreate */}
                                    <br />
                                    <Typography className={classes.afirmativeText}>
                                        {'CONTAS A SEREM CRIADAS'}
                                    </Typography>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.noPaddingLeft} align="left">Instituição</TableCell>
                                                    <TableCell align="center">Agência</TableCell>
                                                    <TableCell align="center">CC</TableCell>
                                                    <TableCell align="center">Início</TableCell>
                                                    <TableCell className={classes.noPaddingRight} align="right">Saldo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataToInsert.accountsToCreate.map((row, index) => (

                                                    <TableRow
                                                        className={classes.toCreateRow}
                                                        key={'account-to-create-' + index}>
                                                        <TableCell align="left" className={classes.noPaddingLeft}>{row.instituition_name}</TableCell>
                                                        <TableCell align="center">{row.agency ? row.agency : '-'}</TableCell>
                                                        <TableCell align="center">{row.number_account}</TableCell>
                                                        <TableCell align="center">{row.account_init ? row.account_init.substring(3) : "-"}</TableCell>
                                                        <TableCell align="right" className={[classes.green, classes.noPaddingRight].join(' ')}>{applyMaskAmount(row.balance, true)}</TableCell>
                                                    </TableRow>

                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </> : null
                        }

                        {
                            dataToInsert
                                && dataToInsert.assets
                                && dataToInsert.assets.length > 0 ?
                                <>

                                    <br />
                                    <Typography className={classes.afirmativeText}>
                                        {'ATIVOS A SEREM CRIADOS'}
                                    </Typography>
                                    <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                        <Table className={classes.table} size="small" >
                                            <colgroup>
                                                <col width="60%" />
                                                <col width="20%" />
                                                <col width="20%" />
                                            </colgroup>
                                            {/* <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.noPaddingLeft}>Fundo</TableCell>                                                    
                                                    <TableCell align="center">Início</TableCell>
                                                    <TableCell align="right" className={classes.noPaddingRight}>Saldo</TableCell>
                                                </TableRow>
                                            </TableHead> */}
                                            <TableBody>
                                                {groupAssets(dataToInsert.assets).map((row, index) => (
                                                    <Row key={'asset-to-insert-' + index} row={row} index={index} />
                                                ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                    <br />
                                </> : null
                        }

                    </> : null
                }


            </DialogContent>


            {
                dataToInsert && checkOkToImport().length == 0 ?

                    <DialogActions className={classes.actionsFlex}>

                        <UnoButton onClick={() =>
                            handleModalImport(false)} color="secondary">
                            {'Cancelar'}
                        </UnoButton>
                        <UnoButton
                            className={classes.spaceRight2}
                            onClick={() => initImport()}
                            color="primary">
                            {'Iniciar importação'}
                        </UnoButton>
                    </DialogActions>
                    :
                    <>

                        {
                            dataToInsert && checkOkToImport().length == 1
                                && checkOkToImport().includes('DUPLICATION_ACCOUNTS') ?
                                <DialogActions className={classes.actionsFlexRight}>

                                    <UnoButton onClick={() =>
                                        handleModalImport(false)} color="secondary">
                                        {'Cancelar'}
                                    </UnoButton>

                                    <UnoButton
                                        className={classes.spaceRight2}
                                        onClick={() => validDupplicatedAccounts(dataToInsert)}
                                        color="primary">
                                        {'Validar e Importar'}
                                    </UnoButton>

                                </DialogActions>
                                :
                                <DialogActions className={classes.actionsFlexRight}>

                                    <UnoButton onClick={() =>
                                        handleModalImport(false)} color="secondary">
                                        {'Fechar'}
                                    </UnoButton>

                                </DialogActions>
                        }
                    </>
            }



        </Dialog >
    );
}