import API from './config'

export async function sendContactEmail(name, email, phone, office, city, message) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/lema/sendContactEmail', {
            name: name,
            email: email,
            phone: phone,
            office: office,
            city: city,
            message: message
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}