import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import _, { isEmpty } from 'lodash';

//Api
import { getFundsBySearchAndSegmentId, getFundsBySegmentId } from '../../../../API/fund';

//redux
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../../actions';

import { unoTheme, tabs, headerSearch } from '../../../../assets/styles/unoTheme';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.redMoney,
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            background: unoTheme.hoverTable,
        }
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
    toogleButton: {
        maxHeight: '40px',
    },
    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },
    iconExtraPolicys: {

        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            verticalAlign: 'middle',
        }

    },
    tabs: tabs,

    sectionTableCellData: {
        display: 'table-cell',
        padding: '0px !important',
    },
});

export default function FundsAllScreen({ segmentId, search }) {

    const classes = useStyles();
    const history = useHistory();

    const currentUser = useSelector(state => state.currentUser);
    const clientOn = useSelector(state => state.clientOn)

    const dispatch = useDispatch();

    const [funds, setFunds] = useState([]);
    const [itemsPage,] = useState(15);
    const [page, setPage] = useState(1);

    async function loadInfos(segmentId) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            emptyMessage: 'Carregando fundos...',
            cod: 'my_funds_screen'
        }));

        console.log('ALL FUNDS segmentId: ', segmentId);

        let responseFunds = {};
        if (isEmpty(search)) {
            responseFunds = await getFundsBySegmentId(segmentId)
        } else {
            responseFunds = await getFundsBySearchAndSegmentId(search, segmentId);
        };
        if (responseFunds.success) {
            const responseFundsData = responseFunds.body.rows;
            console.log('responseFundsData: ', responseFundsData);
            if (isEmpty(responseFundsData)) {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: 'Não existem fundos',
                    cod: 'my_funds_screen'
                }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false, cod: 'my_funds_screen' }));
            };

            setFunds(responseFundsData);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Falha ao trazer fundos",
                type: 'error',
            }));
        }
    }

    function bladeFund(row) {
        history.push(
            {
                pathname: "/blade/all",
                state: { fund: row }
            }
        )
    }

    useEffect(() => {
        if (segmentId == null) return;
        loadInfos(segmentId);
    }, [clientOn,
        segmentId,
        search]);

    const selectable = useMemo(() => currentUser?.user?.isViewer ? undefined : "true", [currentUser]);

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.tableSideGraph}>
                    <MyTable
                        pagination={isEmpty(search) && funds && funds.length > 0}
                        itemspage={itemsPage}
                        totalitems={funds ? funds.length : 0}
                        handlePage={(value) => setPage(page + value)}
                        stickyHeader
                        size="small"
                        selectable={selectable}>
                        <colgroup>
                            <col width="45%" />
                            <col width="55%" />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Fundo</TableCell>
                                <TableCell align="center">CNPJ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (isEmpty(search) ? funds.slice(
                                    (page * itemsPage) - (itemsPage),
                                    itemsPage + (itemsPage * (page - 1))
                                ) : funds).map((row) => (

                                    <TableRow
                                        key={'all-fund-' + row.id}
                                        onClick={() => currentUser?.user?.isViewer ? undefined : bladeFund(row)}
                                    >
                                        <TableCell align="left">{row.fund_name}</TableCell>
                                        <TableCell align="center">{row.cnpj}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </MyTable>
                </div>

            </div>
        </div >
    );
}