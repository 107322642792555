import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Box } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%'
    }
}));

export const Wrapper = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.wrapper}>{children}</Box>
};