import { normalizeAccounts } from '../controllers/AccountController';
import API from './config'

export async function createAccount(account) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/create', account, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateAccount(account) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/update', account, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteAccount(accountId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/account/delete/' + accountId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listClientAccounts(client_id) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/accounts', {
            client_id: client_id
        }, config)

        return {
            success: true,
            body: response.data
        };
        // return {
        //     success: true,
        //     body: normalizeAccounts(response.data.rows),
        // };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listClientAccountsByRegime(clientId, regimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/accountsByRegime', {
            client_id: clientId,
            regime_id: regimeId,
        }, config)

        return {
            success: true,
            body: response.data
        };
        // return {
        //     success: true,
        //     body: normalizeAccounts(response.data.rows),
        // };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listClientAccountsWithBalancesByDateAndRegime(clientId, regimeId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/accountsWithBalancesByDateAndRegime', {
            client_id: clientId,
            regime_id: regimeId,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };
        // return {
        //     success: true,
        //     body: normalizeAccounts(response.data.rows),
        // };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listClientAccountsBalancesByPeriodAndRegime(clientId, regimeId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/listClientAccountsBalancesByPeriodAndRegime', {
            client_id: clientId,
            regime_id: regimeId,
            start_date: startDate,
            end_date: endDate
        }, config)

        return {
            success: true,
            body: response.data
        };


    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAccountsByRegimeAndDate(clientId, regimeId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getClientAccountsByRegimeAndDate', {
            client_id: clientId,
            regime_id: regimeId,
            month: month,
            year: year,
        }, config)

        return {
            success: true,
            body: response.data
        };
        // return {
        //     success: true,
        //     body: normalizeAccounts(response.data.rows),
        // };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountById(accountId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/account/getAccountById/' + accountId, config)

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientByAccountNumber(accountNumber) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/getClientByAccountNumber/' + accountNumber, config)

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }
}

export async function getAccountIdByNumberAccount(clientId, numberAccount) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/getAccountIdByNumberAccount', {
            client_id: clientId,
            number_account: numberAccount
        }, config)

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountIdByNumberAccountAndAgency(clientId, numberAccount, agency) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/getAccountIdByNumberAccountAndAgency', {
            client_id: clientId,
            number_account: numberAccount,
            agency: agency
        }, config)

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountIdByNumberAccountAndInstituitionCod(clientId, numberAccount, instituitionCod) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("clientId: ", clientId);
    console.log("numberAccount: ", numberAccount);
    console.log("instituitionCod: ", instituitionCod);

    try {

        const response = await API.post('/account/getAccountIdByNumberAccountAndInstituitionCod', {
            client_id: clientId,
            number_account: numberAccount,
            instituition_cod: instituitionCod
        }, config)

        console.log("RESPONSE DATA: ", response.data);

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountIdByNumberAccountAndAgencyAndInstituitionCod(clientId, numberAccount, agency, instituitionCod) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("clientId: ", clientId);
    console.log("numberAccount: ", numberAccount);
    console.log("instituitionCod: ", instituitionCod);

    try {

        const response = await API.post('/account/getAccountIdByNumberAccountAndAgencyAndInstituitionCod', {
            client_id: clientId,
            number_account: numberAccount,
            agency: agency,
            instituition_cod: instituitionCod
        }, config)

        console.log("RESPONSE DATA: ", response.data);

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountIdByNumberAccountAndInstituitionCodAndCnpj(clientId, numberAccount, instituitionCod, cnpjFund) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("clientId: ", clientId);
    console.log("numberAccount: ", numberAccount);
    console.log("instituitionCod: ", instituitionCod);

    try {

        const response = await API.post('/account/getAccountIdByNumberAccountAndInstituitionCodAndCnpj', {
            client_id: clientId,
            number_account: numberAccount,
            instituition_cod: instituitionCod,
            cnpj: cnpjFund
        }, config)

        console.log("RESPONSE DATA: ", response.data);

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAccountIdByNumberAccountAndCnpjAsset(numberAccount, cnpjAsset) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/getAccountIdByNumberAccountAndCnpjAsset', {
            number_account: numberAccount,
            cnpj_asset: cnpjAsset
        }, config)

        return {
            success: true,
            body: response.data,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAccountsWithBalances(client_id) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/getClientAccountsWithBalances', {
            client_id: client_id
        }, config)

        // return {
        //     success: true,
        //     body: response.data
        // };

        return {
            success: true,
            body: normalizeAccounts(response.data.rows),
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDisponilityByMonthAndYear(clientId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/account/getDisponilityByMonthAndYear', {
            client_id: clientId,
            month: month,
            year: year,
        }, config)

        return {
            success: true,
            body: response.data.rows,
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

