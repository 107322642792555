import { makeStyles } from "@material-ui/core";
import { unoTheme } from "../../../../../assets/styles/unoTheme";

export const useStyles = makeStyles({
  divTable: {
    width: '60%',
    maxWidth: '60%',
    height: '100%',

    '& table': {
      '& thead': {
        '& tr': {
          cursor: 'pointer',
        }
      }
    },

    '& .MuiTableRow-root .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '12px',
    }
  },
  table: {
    tableLayout: 'fixed',
    '& .MuiTableRow-head': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        color: unoTheme.defaultTextColor,
        fontWeight: '500',
        textTransform: 'uppercase',
        fontSize: '12px',
        padding: '16px 24px 16px 16px',
      },
    },
  },
  legendTable: {
    width: '90%',
    height: '20px',
    borderRadius: '3px',
  },
  myTableFooter: {
    cursor: "pointer",
    borderBottom: 'unset',
    '& > *': {
      borderBottom: 'unset',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      color: 'white',
      background: unoTheme.mainColor,
    },

    '& .MuiTableCell-root:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },

    '& .MuiTableCell-root:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },

    '& .spanTotal': {
      //color: unoTheme.greenMoney,
      fontSize: '14px',
    }

  },
});