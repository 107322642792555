import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//redux
import MyDatePicker from '../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio } from '../../API/client'

//Controllers
import { saveLogResetPortfolio } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
import { formatPortfolioClosed } from '../utils/utils';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    }


}));

export default function SelectDateModal(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { open,
        handleAction } = props;

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(new Date(2020, 12, 1));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    useEffect(() => {

        // console.log("Months: ", months);
        // console.log("Years: ", years);


    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => handleAction(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {"Selecione a data de referência"}
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                <MyDatePicker
                    className={classes.myDatePicker}
                    views={["month", "year"]}
                    margin="dense"
                    id="date-picker-dialog"
                    format="MM/YYYY"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{ 'aria-label': 'change date', }} />

                {
                    validateMessage != "" ?
                        <Typography variant={"body2"} style={{ color: 'red' }}>
                            {validateMessage}
                        </Typography>
                        :
                        null
                }


            </DialogContent>

            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() =>
                    handleAction(false)} color="secondary">
                    {'Cancelar'}
                </UnoButton>

                <UnoButton
                    className={classes.spaceRight2}
                    onClick={() => handleAction(selectedDate)}
                    color="primary">
                    {"Confirmar"}
                </UnoButton>

            </DialogActions>

        </Dialog>
    );
}