import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { isAuthenticated, justNumbers, getArrayYears } from '../../utils/utils'
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';

//Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

//Tables
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//Utils
import { isValidCnpj, applyMaskAccount } from '../../utils/utils'

import NativeSelect from '@material-ui/core/NativeSelect';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { lemaTheme } from '../../../assets/styles/lemaTheme'

import { listActivesInstituitions } from '../../../API/instituition'
import { createTransaction, getClientAllTransactions, getClientAllTransactionByDateRange } from '../../../API/transaction'
import { createAccount, listClientAccounts } from '../../../API/account'

import { getQuotas, getToken } from '../../../API/quotas-api'
import { getRegimes } from '../../../API/account_regime'

//Teste field 
import MyTextField from '../../utils/MyTextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

//RadioGroup
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import PublishIcon from '@material-ui/icons/Publish';

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

//Import excel
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';
import { getArrayMonthsWithNames, getMonthNumberByMonthName } from '../../utils/utils';
import { getJsDateFromExcel } from 'excel-date-to-js'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: headerSearch,
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: lemaTheme.mainColor,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    createData('343 Banco do Brasil', '3237', '23423-4'),
    createData('123 Bradesco', '3237', '3234-0'),
    createData('454 Satander ', '1232', '22324-2'),
];

export default function CollectionOfBalancesScreeen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [submitted, setSubmitted] = useState(false);

    const clientOn = useSelector(state => state.clientOn)

    //CONTA CORRENTE
    const [instituitionSelected, setInstitutionSelected] = React.useState(0);

    const handleChangeInstitutionSelected = (event) => {
        setInstitutionSelected(event.target.value);
    };

    const [activesInstituitions, setActiveInstituitions] = React.useState([]);
    const [regimes, setRegimes] = React.useState([]);
    const [accountRegime, setAccountRegime] = React.useState(1);
    const [clientAccounts, setClientAccounts] = React.useState([]);

    //INPUTS CRIAÇÃO DE ACCOUNT
    const [inputAgency, setInputAgency] = useState("");

    const onChangeAgency = value => {
        setInputAgency(value);
    };

    const [inputNumberAccount, setInputNumberAccount] = useState("");

    const onChangeNumberAccount = value => {
        setInputNumberAccount(value);
    };

    const handleChangeAccountRegime = (event) => {
        console.log("event.target.value: ", event.target.value)
        setAccountRegime(parseInt(event.target.value));
    };

    async function getActiveInstituitions() {

        const response = await listActivesInstituitions();

        if (response.success) {

            setActiveInstituitions(response.body.rows)

        } else {
            console.log("error: ", response.error)
        }


        const reponseRegimes = await getRegimes();
        if (reponseRegimes.success) {

            setRegimes(reponseRegimes.body.rows)

        } else {
            console.log("error: ", reponseRegimes.error)
        }
    }


    async function getClientAccounts() {

        console.log("ClientOn: ", clientOn)
        const response = await listClientAccounts(clientOn.client.id);

        console.log("RESPONSE: ", response)

        if (response.success) {

            setClientAccounts(response.body.rows)

        } else {
            console.log("error: ", response.error)
        }

    }

    useEffect(() => {


        console.log("USE EFFECT:")

        if (activesInstituitions.length == 0) {
            getActiveInstituitions();
        }

        console.log("ClienteAccounts: ", clientAccounts);
        if (clientOn && clientOn.client) {
            getClientAccounts();
        }

    }, [clientOn])

    function cleanFormAccount() {
        setInstitutionSelected(0)
        setInputNumberAccount("");
        setInputAgency("");
        setSubmitted(false);
    }

    async function doSaveAccount() {

        setSubmitted(true);

        let acc = {
            instituition_id: activesInstituitions[instituitionSelected].id,
            client_id: clientOn.client.id,
            account_regime_id: accountRegime,
            agency: inputAgency,
            number_account: inputNumberAccount,
        }

        console.log("ACC: ", acc)
        const responseAccount = await createAccount(acc);

        if (responseAccount.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Conta cadastrada com sucesso!"
            }))

            //Atualizar lista de contas
            getClientAccounts();
            cleanFormAccount();

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao cadastrar a conta: " + responseAccount.body.message
            }))
        }



    }

    const [listQuotas, setListQuotas] = useState([]);

    const [inputNewApplicationId, setInputNewApplicationId] = useState("");
    function changeApplicationId(value) {
        setInputNewApplicationId(value)
    }

    const [infosCnpj, setInfosCnpj] = useState("");
    const [inputNewApplicationCnpj, setInputNewApplicationCnpj] = useState("");

    function getQuotaByCnpj(quotas, cnpj) {

        let q = undefined;
        for (let i = 0; i < quotas.length; i++) {
            console.log("Comparando1: ", quotas[i].cnpj_fundo)
            console.log("Comparando2: ", cnpj)
            if (quotas[i].cnpj_fundo == cnpj) q = quotas[i]
        }

        console.log("RETORNANDO FUNDO: ", q)

        return q;
    }

    async function changeNewApplicationCnpj(value) {

        setInputNewApplicationCnpj(value)
        if (value.length == 18 && isValidCnpj(value)) {

            setInfosCnpj("searching");

            //Cota a ser obtida
            let q = {};

            if (listQuotas.length == 0) {

                const response = await getQuotas();

                console.log("RESPONSE QUOTAS: ", response)

                if (response.success) {

                    setListQuotas(response.body)

                    q = getQuotaByCnpj(response.body, justNumbers(value));

                    setInfosCnpj(q);
                    if (q) {
                        setInputNewApplicationName(q.nome_fantasia)
                    }

                } else {
                    console.log("RESPONSE ERROR: ", response.error)
                    if (response.body == "RENOVAR TOKEN") {
                        const tryNewToken = await getToken();

                        if (tryNewToken.success) {

                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'success',
                                message: 'Sucesso ao obter novo token'
                            }))

                            localStorage.setItem("anbimaToken", tryNewToken.body.access_token)

                            const responseNewQuotas = await getQuotas();

                            setListQuotas(responseNewQuotas.body)

                            q = getQuotaByCnpj(responseNewQuotas.body, justNumbers(value));

                            setInfosCnpj(q);
                            if (q) {
                                setInputNewApplicationName(q.nome_fantasia)
                            }


                        } else {
                            dispatch(allActions.mainAlertActions.setMainAlert({
                                show: true,
                                type: 'error',
                                message: 'Falha ao obter token'
                            }))
                        }
                    }
                }
            } else {

                q = getQuotaByCnpj(listQuotas, justNumbers(value));
                setInfosCnpj(q);
                if (q) {
                    setInputNewApplicationName(q.nome_fantasia)
                }
            }

            console.log("FUNDO: ", q)

        }

    }

    const [inputNewApplicationName, setInputNewApplicationName] = useState("");
    function changeNewApplicationName(value) {
        setInputNewApplicationName(value)
    }

    function doInsertNewApplication() {

    }

    return (
        <div className={classes.root}>
            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Coleta de saldos
                </Typography>
            </div>
            <Divider />
            <br />
            <div className={classes.accordionAccount}>
                <Accordion className={classes.accordionSummary}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography className={classes.heading}>Conta corrente</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.formControl}
                                            focused={true}>
                                            <InputLabel id="demo-simple-select-label">Instituição</InputLabel>
                                            <NativeSelect id="demo-customized-select-native"
                                                value={instituitionSelected}
                                                onChange={handleChangeInstitutionSelected}
                                                input={<BootstrapInput />}>
                                                {activesInstituitions.map((row, index) => (
                                                    <option value={index}>{row.description}</option>
                                                ))}
                                                {/* <option aria-label="None" value="" /> */}
                                                {/* <option value={10}>104 Caixa Econômica Federal</option>
                                                <option value={20}>237 Banco Bradesco S.A.</option>
                                                <option value={30}>232 Algum Nome de Banco</option> */}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} className={classes.alignLeft}>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                component="legend">Regime da conta</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="regime" name="account_regime"
                                                value={accountRegime}
                                                onChange={handleChangeAccountRegime}>
                                                {regimes.map((row, index) => (
                                                    <FormControlLabel

                                                        value={row.id}
                                                        control={<Radio />}
                                                        label={
                                                            <span className={classes.itemRegime}>{row.description}</span>
                                                        } />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Regime da conta</InputLabel>
                                            <NativeSelect id="demo-customized-select-native"
                                                value={instituitionSelected}
                                                onChange={handleChangeInstitutionSelected}
                                                input={<BootstrapInput />}>
                                                {regimes.map((row, index) => (
                                                    <option value={index}>{row.description}</option>
                                                ))}
                                                
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={6}>
                                        <MyTextField
                                            id="agency"
                                            className={classes.inputsAccount}
                                            value={inputAgency}
                                            onChange={onChangeAgency}
                                            validator={inputAgency == "" && submitted}
                                            helperText={"Insira o número da agência"}
                                            mask={["onlyNumbers"]}
                                            label="Agência" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField
                                            id="accountNumber"
                                            className={classes.inputsAccount}
                                            value={inputNumberAccount}
                                            onChange={onChangeNumberAccount}
                                            validator={inputNumberAccount == "" && submitted}
                                            helperText={"Insira o número da conta"}
                                            mask={["onlyNumbers"]}
                                            label="N° da conta" />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Button variant="contained"
                                            color="primary"
                                            onClick={() => doSaveAccount()}>

                                            {'Salvar conta'}
                                        </Button>
                                    </Grid>

                                </Grid>


                            </Grid>
                            <Grid item xs={7}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="customized table">
                                        <TableBody>
                                            {clientAccounts.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.description}
                                                    </TableCell>
                                                    <TableCell align="center">{row.agency}</TableCell>
                                                    <TableCell align="center">{row.number_account}</TableCell>
                                                    <TableCell align="center" className={classes.cellIcon}>
                                                        <IconButton>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.cellIcon}>
                                                        <IconButton>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                // <StyledTableRow key={row.name}>
                                                //     <StyledTableCell component="th" scope="row">
                                                //         {row.name}
                                                //     </StyledTableCell>

                                                //     <StyledTableCell align="center">{row.calories}</StyledTableCell>
                                                //     <StyledTableCell align="center">{row.fat}</StyledTableCell>
                                                //     <StyledTableCell align="center" className={classes.cellIcon}>
                                                //         <IconButton>
                                                //             <EditIcon />
                                                //         </IconButton>
                                                //     </StyledTableCell>
                                                //     <StyledTableCell align="center" className={classes.cellIcon}>
                                                //         <IconButton>
                                                //             <DeleteIcon />
                                                //         </IconButton>
                                                //     </StyledTableCell>
                                                // </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordionSummary}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>Novas aplicações</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <form className={classes.formNewApplication}>
                            <Grid container spacing={2}>
                                <Grid item lg={1} md={1} sm={1} xs={12}>
                                    <MyTextField
                                        id="new_application_id"
                                        disabled={true}
                                        value={inputNewApplicationId}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={changeApplicationId}
                                        label="ID" />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    <MyTextField
                                        align="center"
                                        id="new_application_cnpj"
                                        value={inputNewApplicationCnpj}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={changeNewApplicationCnpj}
                                        validator={!isValidCnpj(inputNewApplicationCnpj) && inputNewApplicationCnpj.length == 18}
                                        helperText={!isValidCnpj(inputNewApplicationCnpj) ? "CNPJ inválido" : "CNPJ não encontrado"}
                                        mask={["cnpj"]}
                                        label="CNPJ"
                                        endAdornment={
                                            <>
                                                {
                                                    infosCnpj == "searching" ?
                                                        <InputAdornment position="end">
                                                            <CircularProgress className={classes.progressInput} />
                                                        </InputAdornment> : null

                                                }
                                            </>

                                        } />
                                </Grid>
                                <Grid item lg={5} md={5} sm={5} xs={12}>
                                    <MyTextField
                                        id="new_application_name"
                                        disabled={true}
                                        value={inputNewApplicationName}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={changeNewApplicationName}
                                        label="Nome do produto" />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => doInsertNewApplication()}>
                                        {"Inserir novo"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}



