import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme } from "../../../assets/styles/unoTheme";

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

//Redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//API
import { create, updateUser, deleteUser } from "../../../API/user";

//MyInputs
import MyTextField from "../../utils/MyTextField";

import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ConfirmActionRightContent from './ConfirmActionRightContent'

import _ from 'lodash';

import { isValidEmail, isValidCpf, justNumbers, applyMaskPhone, pascalCase, getConsultingId } from "../../utils/utils";
import { roles } from '../../utils/user.js';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(5)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px',
            },
        },
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '12px',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    green: {
        color: unoTheme.greenMoney
    },
    red: {
        color: unoTheme.errorColor,
    },
    warning: {
        color: unoTheme.warningColor
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    }


}));

export default function NewClientSubManager({ content }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const SUBMANAGER_TITLE = 'subgestor';

    const currentUser = useSelector(state => state.currentUser)

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [confirmingAction, setConfirmingAction] = useState(false);

    //form    
    const [submitted, setSubmitted] = useState(false);

    const [passwordReseted, setPasswordReseted] = useState(false);

    const [clientOnScreen, setClientOnScreen] = useState({});

    const [inputNameManager, setInputNameManager] = useState("");
    const [inputEmailManager, setInputEmailManager] = useState("");
    const [inputCpfManager, setInputCpfManager] = useState("");
    const [inputPhoneManager, setInputPhoneManager] = useState("");
    const [inputPasswordManager, setInputPasswordManager] = useState("");

    async function doSaveManager(event) {

        event.preventDefault();
        setSubmitted(true);

        setOpenBackdrop(true);

        let user = {
            cpf: inputCpfManager,
            password: content.update ? (passwordReseted ? inputPasswordManager : null) : inputPasswordManager,
            name: inputNameManager,
            email: inputEmailManager,
            phone_number: justNumbers(inputPhoneManager),
            role: roles.submanager.id,
            client_id: clientOnScreen.id, //managers sempre possuem o cliente
            consulting_id: getConsultingId(currentUser)
        }

        if (content.update) {

            if (isValidCpf(justNumbers(user.cpf))
                &&
                (
                    (passwordReseted && user.password != "" && user.password.length >= 6)
                    ||
                    (!passwordReseted)
                )
                && user.name != ""
                && isValidEmail(user.email)) {

                setSubmitted(false);

                let response = await updateUser(content.data.id, user);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: pascalCase(SUBMANAGER_TITLE) + " atualizado com sucesso"

                    }));
                    dispatch(allActions.rightContentActions.update());
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: `Erro ao atualizar o ${SUBMANAGER_TITLE.toLowerCase()}: ${response.body.message}`
                    }));
                }
            }

        } else {

            if (isValidCpf(justNumbers(user.cpf))
                && user.password != ""
                && user.password.length >= 6
                && user.name != ""
                && isValidEmail(user.email)) {

                setSubmitted(false);

                let response = await create(user);

                if (response.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: pascalCase(SUBMANAGER_TITLE) + " cadastrado com sucesso"

                    }));

                    dispatch(allActions.rightContentActions.update())

                } else {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }))

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: `Erro ao cadastrar o ${SUBMANAGER_TITLE.toLowerCase()}: ${response.body.message}`
                    }));
                }

            }
        }

        setOpenBackdrop(false);
    }

    function initForm(manager) {

        setInputNameManager(manager.name);
        setInputEmailManager(manager.email);
        setInputCpfManager(manager.cpf);
        setInputPhoneManager(applyMaskPhone(manager.phone_number));

    }

    async function doDeleteManager() {

        setOpenBackdrop(true)

        //content contem data que contem a conta que foi aberta no componente lateral
        const responseAccount = await deleteUser(content.data.id);

        if (responseAccount.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Gestor excluído com sucesso!'
            }))

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir gestor: " + responseAccount.body.message
            }))
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update());

    }

    //deleção da conta
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleConfirm() {
        doDeleteManager();
    }

    useEffect(() => {

        //Caso seja a edição de um manager
        if (content) {

            //Setando o cliente que está sendo utilizado, selecionado
            setClientOnScreen(content.clientOnScreen);
            if (content && content.update) {
                initForm(content.data);
            }
        }


    }, []);

    const passwordDisabled = useMemo(() => content.update && !passwordReseted, [content, passwordReseted]);

    return (
        <div className={classes.root}>


            {
                !confirmingAction ?

                    <>

                        <div className={classes.header}>
                            <IconButton
                                className={!content.update ? classes.hiddenIcon : ""}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'center'}>

                                    {content.update ? `Editar ${pascalCase(SUBMANAGER_TITLE)}` : `Cadastrar ${pascalCase(SUBMANAGER_TITLE)}`}

                                </Typography>

                                <Typography
                                    variant={'body1'}
                                    align={'center'}
                                    style={{ color: unoTheme.defaultTextColor }}>

                                    {clientOnScreen && (clientOnScreen.city_name + " - " + clientOnScreen.uf)}

                                </Typography>

                            </div>


                            <IconButton className={content.update ? classes.hiddenIcon : ""}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <MyTextField required
                                        outerlabel="Nome"
                                        size={'small'}
                                        validator={inputNameManager == "" && submitted ? true : undefined}
                                        variant="outlined"
                                        onChange={(value) => setInputNameManager(value)}
                                        helperText={"Insira um nome"}
                                        placeholder={'Insira o nome'}
                                        value={inputNameManager} />
                                </Grid>

                                <Grid item xs={12} sm={7} md={7}>
                                    <MyTextField
                                        value={inputEmailManager}
                                        variant="outlined"
                                        size={'small'}
                                        onChange={(value) => setInputEmailManager(value)}
                                        validator={!isValidEmail(inputEmailManager) && submitted ? true : undefined}
                                        helperText={"Insira um email válido"}
                                        placeholder={'Insira o email'}
                                        outerlabel="Email" />
                                </Grid>

                                <Grid item xs={12} sm={5} md={5}>
                                    <MyTextField
                                        value={inputPhoneManager}
                                        size={'small'}
                                        onChange={(value) => setInputPhoneManager(value)}
                                        mask={['phone']}
                                        variant="outlined"
                                        placeholder={'Insira o telefone'}
                                        outerlabel="Telefone" />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <MyTextField
                                        value={inputCpfManager}
                                        onChange={(value) => setInputCpfManager(value)}
                                        size={'small'}
                                        variant="outlined"
                                        validator={!isValidCpf(inputCpfManager) && submitted ? true : undefined}
                                        helperText={"Insira um CPF válido"}
                                        mask={['cpf']}
                                        placeholder={'Insira o CPF'}
                                        outerlabel="CPF" />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>

                                    <MyTextField
                                        type="password"                                        // disabled={!passwordReseted && content.update}
                                        className={passwordDisabled ? classes.selectable : ''}
                                        outerlabel="Senha"
                                        value={inputPasswordManager}
                                        onChange={(value) => setInputPasswordManager(value)}
                                        size={'small'}
                                        variant="outlined"
                                        onClick={() => setPasswordReseted(true)}
                                        placeholder={!passwordReseted && content.update ? "Clique para nova senha" : "Insira a senha"}
                                        validator={inputPasswordManager.length < 6
                                            && submitted ? true : undefined}
                                        helperText={"Insira uma senha de 6 dígitos"}
                                    />

                                </Grid>

                            </Grid>

                            <br />
                            <br />
                            <div className={classes.bottom}>

                                <div className={'bottomButtons'}>

                                    <UnoButton
                                        className={
                                            !content.update ? classes.hiddenIcon : null}
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir SubGestor"}
                                    </UnoButton>


                                    <UnoButton
                                        onClick={(e) => doSaveManager(e)}
                                        type={content.update ? 'success' : 'primary'}>
                                        {content.update ? `Atualizar ${pascalCase(SUBMANAGER_TITLE)}` : `Salvar ${pascalCase(SUBMANAGER_TITLE)}`}
                                    </UnoButton>
                                </div>

                            </div>
                        </div>
                    </>
                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir 
                            o usuário ${content && content.data ? content.data.name : ''}?`}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                    />

            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }
        </div>
    );
}



