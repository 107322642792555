import { ClientRiskDataAPI } from "../API/client_risk_data";
import { getAnalisysRiskMarket } from "../API/comdinheiro-api";
import { getClientTargetByYear } from "../API/inflation_rate";
import { listLegislationArticles, listLegislationArticlesByDate, listLegislationSegmentsByVersionId } from "../API/legislation_article";
import { ArticleParams } from "../components/utils/ArticleParams";
import { AdminsDictionary } from "../components/utils/Dictionarys/AdminsDictionary";
import { ManagersDictionary } from "../components/utils/Dictionarys/ManagersDictionary";
import { MathUtils } from "../components/utils/MathUtils";
import { applyMaskAmount, applyMaskAmountFreeDecimals, formatStringToDisplay, formatTextForValidDisplay, getDayMonthYearByPtDate, getFirstBeforeBusinessDay, getLastPtDayMonth, getMonthAgo, groupArticlePolicys, markArticlesInPortfolio, var5 } from "../components/utils/utils";
import { getAssetInfos, getSimpleNormalizedAssets } from "./AssetsController";
import { loadClientDiaryByRange, loadClientDiaryPlsByLimit } from "./ClientController";
import { CLIENT_RISK_TYPES, ClientRiskDataController } from "./ClientRiskDataController";
import { doGetIBOVAndCDI, doGetRankingAdmins, doGetRankingManagers, doGetRentsMes3m6m12m } from "./ComdinheiroController";
import { formatMyFundsToShow } from "./FundController";
import { rentsByPeriod } from "./ReportController";

const DESENQUADRADO = 'Desenquadrado'

export const RiskController = {

    formatToArticles4963Risk(assets, rankingManagers, rankingAdmins) {

        console.log("formatToArticles4963Risk: ", assets)
        console.log("rankingManagers: ", rankingManagers)
        console.log("rankingAdmins: ", rankingAdmins)

        const totalBalanceNow = assets.reduce(
            (previous, current) => previous + parseFloat(current.balance_now),
            0
        )

        console.log('totalBalanceNow: ', totalBalanceNow)

        const formattedAssets = [];
        assets.forEach(ass => {

            //Art 18
            const participationAsset = ass.balance_now / totalBalanceNow * 100;
            const art18 = participationAsset;
            console.log('fund: ', ass.fund_name);
            console.log('article_id: ', ass.article_id);
            const outArt18 = ass.fund_id
                && participationAsset >= ArticleParams.limitsByResolution.resolution_4963.limiteArt18
                && !ArticleParams.limitsByResolution.resolution_4963.offLimitArt18Ids.includes(ass.article_id);
            console.log('outArt18: ', outArt18);

            //Art 19
            let art19 = null;
            let outArt19 = false;

            if (ass.fund_id && ass.dados_cadastrais && ass.dados_cadastrais.pl_anbima) {
                const participationAssetInFundPl = ass.balance_now / ass.dados_cadastrais.pl_anbima * 100;
                art19 = participationAssetInFundPl;

                //Resoluções de excessão do artigo 19
                if (ArticleParams.limitsByResolution.resolution_4963.offLimitArt19Ids.includes[ass.article_id]) {
                    outArt19 = participationAssetInFundPl >= ArticleParams.limitsByResolution.resolution_4963.extraLimiteArt19;
                } else {
                    outArt19 = participationAssetInFundPl >= ArticleParams.limitsByResolution.resolution_4963.limiteArt19;
                }
            }

            //Art 20
            let art20 = null;
            let outArt20 = false;

            if (ass.fund_id && ass.manager_anbima && rankingManagers[ass.manager_anbima]) {

                const participationAssetInAdminPl = ass.balance_now / rankingManagers[ass.manager_anbima] * 100;
                console.log('participationAssetInAdminPl: ', participationAssetInAdminPl);
                art20 = participationAssetInAdminPl;

                outArt20 = participationAssetInAdminPl >= ArticleParams.limitsByResolution.resolution_4963.limiteArt20;

            }

            //Art21
            let art21 = null;
            let outArt21 = false;

            if (ass.fund_id && ass.administrator_anbima && rankingAdmins[ass.administrator_anbima]) {

                const participationAssetInManagerPl = ass.balance_now / rankingAdmins[ass.administrator_anbima] * 100;
                console.log('participationAssetInManagerPl: ', participationAssetInManagerPl);
                art21 = participationAssetInManagerPl;

                outArt21 = participationAssetInManagerPl >= ArticleParams.limitsByResolution.resolution_4963.limiteArt20;

            }

            formattedAssets.push({
                fund_name: formatStringToDisplay(ass.fund_name, 6, '...'),
                full_fund_name: ass.fund_name,
                balance_now: applyMaskAmount(ass.balance_now, true),
                art18: applyMaskAmount(art18) + '%',
                outArt18: outArt18,
                art19: art19 ? applyMaskAmount(art19) + '%' : '-',
                outArt19: outArt19,
                manager_name: ass.dados_cadastrais ? ManagersDictionary.getMyManagerAnbima(ass.dados_cadastrais.manager_name) : '-',
                art20: art20 ? applyMaskAmountFreeDecimals(art20) + '%' : '-',
                outArt20: outArt20,
                administrator_name: ass.dados_cadastrais ? AdminsDictionary.getMyAdminAnbima(ass.dados_cadastrais.administrator_name) : '-',
                art21: art21 ? applyMaskAmountFreeDecimals(art21) + '%' : '-',
                outArt21: outArt21,
                device_abbreviation: ass.device_abbreviation,
            })

        });

        return formattedAssets;
    },

    formatToBenchmarksRisk(funds, mapRentsBenchmarks) {
        const formattedAssets = [];

        funds.forEach(fd => {

            if (fd.benchmark_comdinheiro) {

                const benchmarkComdinheiroKey = fd.benchmark_comdinheiro.toString().toLowerCase();
                const benchmarkVsFund = {
                    rentMonth: '-',
                    rent3m: '-',
                    rent6m: '-',
                    rent12m: '-',
                }

                console.log('fd.rentMonth: ', fd.rentMonth);
                console.log('mapRentsBenchmarks[benchmarkComdinheiroKey]: ', mapRentsBenchmarks[benchmarkComdinheiroKey]);

                if (fd.rentMonth && mapRentsBenchmarks[benchmarkComdinheiroKey] && mapRentsBenchmarks[benchmarkComdinheiroKey].rentMonth) {
                    benchmarkVsFund.rentMonth = applyMaskAmount(fd.rentMonth / mapRentsBenchmarks[benchmarkComdinheiroKey].rentMonth * 100) + '%';
                }
                if (fd.rent3m && mapRentsBenchmarks[benchmarkComdinheiroKey] && mapRentsBenchmarks[benchmarkComdinheiroKey].rent3m) {
                    benchmarkVsFund.rent3m = applyMaskAmount(fd.rent3m / mapRentsBenchmarks[benchmarkComdinheiroKey].rent3m * 100) + '%';
                }
                if (fd.rent6m && mapRentsBenchmarks[benchmarkComdinheiroKey] && mapRentsBenchmarks[benchmarkComdinheiroKey].rent6m) {
                    benchmarkVsFund.rent6m = applyMaskAmount(fd.rent6m / mapRentsBenchmarks[benchmarkComdinheiroKey].rent6m * 100) + '%';
                }
                if (fd.rent12m && mapRentsBenchmarks[benchmarkComdinheiroKey] && mapRentsBenchmarks[benchmarkComdinheiroKey].rent12m) {
                    benchmarkVsFund.rent12m = applyMaskAmount(fd.rent12m / mapRentsBenchmarks[benchmarkComdinheiroKey].rent12m * 100) + '%';
                }

                formattedAssets.push({
                    //fund_name: formatStringToDisplay(fd.fund_name, 5, '...'),
                    fund_name: fd.fund_name,
                    benchmark: fd.benchmark,
                    balance_now: applyMaskAmount(fd.balance_now, true),
                    rentMonth: fd.rentMonth ? applyMaskAmount(fd.rentMonth) + '%' : '-',
                    rent3m: fd.rent3m ? applyMaskAmount(fd.rent3m) + '%' : '-',
                    rent6m: fd.rent6m ? applyMaskAmount(fd.rent6m) + '%' : '-',
                    rent12m: fd.rent12m ? applyMaskAmount(fd.rent12m) + '%' : '-',

                    benchmarkVsFund: {
                        ...benchmarkVsFund,
                    },
                })

            }

        });

        console.log('formatToBenchmarksRisk: ', formattedAssets);

        return formattedAssets;
    },

    //Picos e médias de rent mes e 12m
    rentsPeaksAndAverages(diarysCurrentMonth, arrayRents12m) {

        console.log('diarysCurrentMonth: ', diarysCurrentMonth);
        console.log('arrayRents12m: ', arrayRents12m);

        let peakMonth = null;
        let minimumMonth = null;
        let averageMonth = 0;
        let countRents = 0;
        diarysCurrentMonth.forEach(elementDiary => {

            const floatedDiaryRent = parseFloat(elementDiary.rent_day);
            if (!peakMonth) {
                peakMonth = floatedDiaryRent;
            } else {

                if (floatedDiaryRent > peakMonth) {
                    peakMonth = floatedDiaryRent;
                }
            }

            if (!minimumMonth) {
                minimumMonth = floatedDiaryRent;
            } else {

                if (floatedDiaryRent < minimumMonth) {
                    minimumMonth = floatedDiaryRent;
                }
            }

            averageMonth += floatedDiaryRent;
            countRents++;

        });
        //Calculando variancia para calcular desvio padrão 
        averageMonth = averageMonth / countRents;
        let totalVariancesMonth = 0;
        diarysCurrentMonth.forEach(elementDiary => {
            const floatedDiaryRent = parseFloat(elementDiary.rent_day);
            totalVariancesMonth += (floatedDiaryRent - averageMonth) * (floatedDiaryRent - averageMonth);
        });
        const standardDeviationMonth = Math.sqrt(totalVariancesMonth / countRents);

        let peak12m = null;
        let minimum12m = null;
        let average12m = 0;
        let countRents12m = 0;
        arrayRents12m.forEach(element => {
            element.diarys.forEach(elementDiary => {

                const floatedDiaryRent = parseFloat(elementDiary.rent_day);
                if (!peak12m) {
                    peak12m = floatedDiaryRent;
                } else {

                    if (floatedDiaryRent > peak12m) {
                        peak12m = floatedDiaryRent;
                    }
                }

                if (!minimum12m) {
                    minimum12m = floatedDiaryRent;
                } else {

                    if (floatedDiaryRent < minimum12m) {
                        minimum12m = floatedDiaryRent;
                    }
                }

                average12m += floatedDiaryRent;
                countRents12m++;

            });
        });
        average12m = average12m / countRents12m;
        let totalVariances12m = 0;
        arrayRents12m.forEach(element => {
            element.diarys.forEach(elementDiary => {
                const floatedDiaryRent = parseFloat(elementDiary.rent_day);
                totalVariances12m += (floatedDiaryRent - average12m) * (floatedDiaryRent - average12m);
            })
        });
        const standardDeviation12m = Math.sqrt(totalVariances12m / countRents12m);


        return {

            peakMonth: peakMonth,
            minimumMonth: minimumMonth,

            peak12m: peak12m,
            minimum12m: minimum12m,

            averageMonth: averageMonth,
            average12m: average12m,

            standardDeviationMonth: standardDeviationMonth,
            standardDeviation12m: standardDeviation12m,

        };
    },

    getTreynorAndSharpe(arrayRents, arrayIbov, arrayCdi) {
        console.log('rents: ', arrayRents);
        console.log('ibov: ', arrayIbov);
        console.log('cdi: ', arrayCdi);

        // const slicedRents = arrayRents.slice(0, 2);
        // const slicedIbov = arrayIbov.slice(0, 2);

        const N = arrayRents.length;
        const covarianceRentsIbov = MathUtils.covariance(arrayRents, arrayIbov, N);
        //const covarianceRentsIbov = MathUtils.covariance(slicedRents, slicedIbov, 2);
        const varianceIbov = MathUtils.variance(arrayIbov, N);
        console.log('covarianceRentsIbov: ', covarianceRentsIbov);
        console.log('varianceIbov: ', varianceIbov);

        const beta = covarianceRentsIbov / varianceIbov;
        console.log('beta: ', beta);

        const safeReturn = MathUtils.mean(arrayCdi, N) / 100;
        console.log('safeReturn: ', safeReturn);
        const activeReturn = MathUtils.mean(arrayRents, N) / 100;
        console.log('activeReturn: ', activeReturn);

        //Treynor
        const treynorFactor1 = beta;
        console.log('treynorFactor1: ', treynorFactor1);
        const treynorFactor2 = Math.pow(1 + safeReturn, 252) - 1;
        console.log('treynorFactor2: ', treynorFactor2);
        const treynorFactor3 = Math.pow(1 + activeReturn, 252) - 1;
        console.log('treynorFactor3: ', treynorFactor3);

        const treynor = (treynorFactor3 - treynorFactor2) / treynorFactor1;
        console.log('treynor: ', treynor);

        //Sharpe
        const standardDeviation = Math.sqrt(MathUtils.variance(arrayRents)) / 100;
        console.log('standardDeviation: ', standardDeviation);
        const sharpeFactor1 = standardDeviation * Math.sqrt(252);
        console.log('sharpeFactor1: ', sharpeFactor1);
        const sharpeFactor2 = treynorFactor2;
        console.log('sharpeFactor2: ', sharpeFactor2);
        const sharpeFactor3 = treynorFactor3;
        console.log('sharpeFactor3: ', sharpeFactor3);

        const sharpe = (sharpeFactor3 - sharpeFactor2) / sharpeFactor1;
        console.log('sharpe: ', sharpe);

        return {
            treynor: treynor,
            sharpe: sharpe
        }
    },

    async getMarketData(clientId, referenceDate) {

        const { month, year } = getDayMonthYearByPtDate(referenceDate);

        //Verificar se já existe a informação na base local
        const responseLocalData = await ClientRiskDataController.getLocalData(clientId, month, year, CLIENT_RISK_TYPES.MARKET);

        if (responseLocalData) {
            return responseLocalData;
        } else {
            const response = await getSimpleNormalizedAssets(clientId, month, year, null);

            const currentPeriod = month + '/' + year;

            const startDate = getMonthAgo(
                month,
                year,
                12);

            const responseRentsByPeriod = await rentsByPeriod(clientId, startDate, referenceDate, null, 0);

            const responseDiarys = await loadClientDiaryByRange(clientId, month, year, 12);

            console.log('responseRentsByPeriod: ', responseRentsByPeriod);
            console.log('responseDiarys: ', responseDiarys);

            const { simpleFunds } = response;
            console.log('simpleFunds: ', simpleFunds);
            const justCnpjs = [];
            const justFunds = simpleFunds.filter(el => el.id);
            justFunds.forEach(element => {
                justCnpjs.push(element.cnpj);
            });

            const responseRiskMarket = await getAnalisysRiskMarket(getFirstBeforeBusinessDay(referenceDate), justCnpjs);
            const reponseIbovCdi = await doGetIBOVAndCDI(startDate, referenceDate);

            const responseTargets = await getClientTargetByYear(clientId, year);

            const rentsToVar_1_252 = await loadClientDiaryPlsByLimit(clientId, month,
                year, 252);
            console.log('responseTargets: ', responseTargets);

            if (responseRiskMarket.success && responseRentsByPeriod && responseRentsByPeriod.resultFunds && responseDiarys.isSuccessful) {

                const { peakMonth, peak12m,
                    minimumMonth, minimum12m,
                    averageMonth, average12m,
                    standardDeviationMonth, standardDeviation12m
                } = this.rentsPeaksAndAverages(responseDiarys.rentMonths[currentPeriod].diarys, responseDiarys.arrayRents);

                const allRentsMonth = [];
                const allRents12m = [];
                responseDiarys.rentMonths[currentPeriod].diarys.forEach(elementDiary => {
                    allRentsMonth.push(
                        parseFloat(elementDiary.rent_day),
                    )
                });

                responseDiarys.arrayRents.forEach(element => {
                    element.diarys.forEach(elementDiary => {
                        allRents12m.push(parseFloat(elementDiary.rent_day))
                    });
                });

                const arrayibovCdiMonth = reponseIbovCdi.filter(el => el.date.includes(currentPeriod.padStart(5, '0')));
                const arrayIbovMonth = [];
                const arrayCdiMonth = [];
                const arrayIbov12m = [];
                const arrayCdi12m = [];
                arrayibovCdiMonth.forEach(elementIbovCdi => {
                    arrayIbovMonth.push(elementIbovCdi.valueIBOV ? elementIbovCdi.valueIBOV : 0);
                    arrayCdiMonth.push(elementIbovCdi.valueCDI ? elementIbovCdi.valueCDI : 0);
                });
                reponseIbovCdi.forEach(elementIbovCdi => {
                    arrayIbov12m.push(elementIbovCdi.valueIBOV ? elementIbovCdi.valueIBOV : 0);
                    arrayCdi12m.push(elementIbovCdi.valueCDI ? elementIbovCdi.valueCDI : 0);
                });

                const { treynor: treynorMonth, sharpe: sharpeMonth } = this.getTreynorAndSharpe(allRentsMonth, arrayIbovMonth, arrayCdiMonth)
                const { treynor: treynor12m, sharpe: sharpe12m } = this.getTreynorAndSharpe(allRents12m, arrayIbov12m, arrayCdi12m)

                console.log(peakMonth, peak12m, minimumMonth, minimum12m);

                const drawdownMonth = (peakMonth - minimumMonth) / peakMonth;
                const drawdown12m = (peak12m - minimum12m) / peak12m;


                const rentCurrentMonth = responseDiarys.rentMonths[currentPeriod];

                // console.log('standardDeviationMonth: ', standardDeviationMonth);
                // console.log('standardDeviation12m: ', standardDeviation12m);
                // console.log('Math.sqrt(21): ', Math.sqrt(21));
                // console.log('Math.sqrt(252): ', Math.sqrt(252));

                const varMonth = var5(allRentsMonth, 21, true)
                const var12m = var5(allRents12m, 252, true)
                const var5_1_252 = rentsToVar_1_252 ? var5(rentsToVar_1_252) : null;
                console.log("var5_1_252: ", var5_1_252);

                const currentFundsRisk = [
                    {
                        cnpj: '-',
                        device_abbreviation: '-',
                        benchmark: '=meta', //currentTarget(responseTargets),
                        fund_name: 'CARTEIRA',
                        var5_1_252: applyMaskAmount(var5_1_252) + "%",
                        infosRisk: {
                            rent_mes: applyMaskAmount(rentCurrentMonth.rent) + '%',
                            rent_12m: applyMaskAmount(rentCurrentMonth.rentAcum) + '%',
                            cota: null,
                            patrimonio: null,
                            var_mes: applyMaskAmount(varMonth) + '%',
                            var_12m: applyMaskAmount(var12m) + "%",
                            vol_mes: applyMaskAmount(standardDeviationMonth * Math.sqrt(21)) + "%",
                            vol_12m: applyMaskAmount(standardDeviation12m * Math.sqrt(252)) + "%",
                            traynor_mes: applyMaskAmount(treynorMonth),
                            traynor_12m: applyMaskAmount(treynor12m),
                            sharpe_mes: applyMaskAmount(sharpeMonth),
                            sharpe_12m: applyMaskAmount(sharpe12m),
                            drawdown_mes: applyMaskAmount(drawdownMonth) + "%",
                            drawdown_12m: applyMaskAmount(drawdown12m) + "%",
                        }
                    },
                    ...justFunds
                ];
                console.log('currentFundsRisk: ', currentFundsRisk);
                currentFundsRisk.forEach((element, index) => {

                    if (index !== 0) {

                        // console.log("responseRiskMarket: ", responseRiskMarket);
                        // console.log("element.cnpj: ", element.cnpj);
                        // console.log('responseRiskMarket.body[element.cnpj]: ', responseRiskMarket.body[element.cnpj]);
                        element.infosRisk = responseRiskMarket.body[element.cnpj];
                        element.fund_name = formatStringToDisplay(element.fund_name, 6, '...');

                        if (element.infosRisk && responseRentsByPeriod.resultFunds[element.id]) {


                            element.infosRisk.rent_mes = applyMaskAmount(responseRentsByPeriod.integralFundsBalancesMap[element.id].rentMonth);
                            element.infosRisk.rent_12m = applyMaskAmount(responseRentsByPeriod.integralFundsBalancesMap[element.id].rentAcum);

                            element.infosRisk.var_mes = element.infosRisk.var_mes ? element.infosRisk.var_mes + '%' : '-';
                            element.infosRisk.var_12m = element.infosRisk.var_12m ? element.infosRisk.var_12m + '%' : '-';
                            element.infosRisk.vol_mes = element.infosRisk.vol_mes ? element.infosRisk.vol_mes + '%' : '-';
                            element.infosRisk.vol_12m = element.infosRisk.vol_12m ? element.infosRisk.vol_12m + '%' : '-';
                            // element.infosRisk.traynor_mes = element.infosRisk.traynor_mes + '%';
                            // element.infosRisk.traynor_12m = element.infosRisk.traynor_12m + '%';
                            // element.infosRisk.sharpe_mes = element.infosRisk.sharpe_mes + '%';
                            // element.infosRisk.sharpe_12m = element.infosRisk.sharpe_12m + '%';
                            // element.infosRisk.drawdown_mes = element.infosRisk.drawdown_mes + '%';
                            // element.infosRisk.drawdown_12m = element.infosRisk.drawdown_12m + '%';


                        }
                    }
                });

                console.log('currentFundsRisk: ', currentFundsRisk);

                //salvar localmente as informações de market_data
                const responseSaveLocal = await ClientRiskDataController.saveData(clientId, month, year, CLIENT_RISK_TYPES.MARKET, currentFundsRisk);

                return currentFundsRisk;
            }
        }


    },

    isPolicyAccording(participation, lower, top) {

        if (lower == null && top == null) {
            return true;
        }

        if (
            participation != null && lower != null && top != null
            && (participation >= lower && participation <= top)) {

            return true;
        }

        return false;

    },


    async getPolicyData(client, referenceDate) {

        const { id: clientId } = client;
        const { month, year } = getDayMonthYearByPtDate(referenceDate);

        // //Verificar se já existe a informação na base local
        // const responseLocalData = await ClientRiskDataController.getLocalData(clientId, month, year, CLIENT_RISK_TYPES.POLICY);

        // if (responseLocalData) {

        //     return responseLocalData;

        // } else {

        const responseLegislationArticles = await listLegislationArticlesByDate(
            null,
            clientId,
            year, referenceDate, true);

        console.log('responseLegislationArticles: ', responseLegislationArticles);

        const formattedArticles = groupArticlePolicys(responseLegislationArticles.body.rows);

        const responseAssets = await getSimpleNormalizedAssets(clientId,
            month,
            year,
            null)

        const responseGrup = markArticlesInPortfolio(formattedArticles,
            responseAssets.assets, null, [], client.promanagement_code);

        console.log('formattedArticles: ', formattedArticles);
        console.log('responseGrup: ', responseGrup);

        //format to show
        const formattedToShow = [];
        let currentSegmentIdLoop = null;
        let currentDesenquadrado = null;
        responseGrup.articles.forEach(articleLoop => {

            if (!currentSegmentIdLoop || articleLoop.legislation_segment_id != currentSegmentIdLoop) {
                if (currentDesenquadrado) {
                    if (currentDesenquadrado.balanceFloated > 0) {
                        formattedToShow.push(currentDesenquadrado)
                    }
                    currentDesenquadrado = null;
                }
                //Adicionar linha de segment
                formattedToShow.push({
                    segmentName: articleLoop.segment_name,
                    asset: '',
                    balance: '',
                    participation: '',
                    lowerTarget: '',
                    target: '',
                    topTarget: '',
                    limitResolution: '',
                })
                currentSegmentIdLoop = articleLoop.legislation_segment_id;
            }

            const articleToAdd = {
                segmentName: null,
                asset: articleLoop.asset,
                balanceFloated: articleLoop.totalArticleValue,
                balance: applyMaskAmount(articleLoop.totalArticleValue, true),
                participation: applyMaskAmount(articleLoop.percentInPortfolio) + "%",
                lowerTarget: articleLoop.lower_target != null ? applyMaskAmount(articleLoop.lower_target) + '%' : '-',
                target: articleLoop.target != null ? applyMaskAmount(articleLoop.target) + '%' : '-',
                topTarget: articleLoop.top_target != null ? applyMaskAmount(articleLoop.top_target) + '%' : '-',
                limitResolution: articleLoop.limit_resolution ? applyMaskAmount(articleLoop.limit_resolution) + '%' : '-',
                outLowerTarget: articleLoop.outLowerTarget,
                outTopTarget: articleLoop.outTopTarget,
                isPolicyAccording: this.isPolicyAccording(articleLoop.percentInPortfolio, articleLoop.lower_target, articleLoop.top_target),


            }

            if (articleLoop.asset === DESENQUADRADO) {
                currentDesenquadrado = articleToAdd;
            } else {
                formattedToShow.push(articleToAdd)
            }


        });

        console.log('formattedToShow: ', formattedToShow);

        //salvar localmente as informações de articles_data
        //const responseSaveLocal = await ClientRiskDataController.saveData(clientId, month, year, CLIENT_RISK_TYPES.POLICY, formattedToShow);

        return formattedToShow;
        //}

    },


    async getArticlesData(clientId, referenceDate) {

        const { month, year } = getDayMonthYearByPtDate(referenceDate);

        //Verificar se já existe a informação na base local
        const responseLocalData = await ClientRiskDataController.getLocalData(clientId, month, year, CLIENT_RISK_TYPES.ARTICLES);

        if (responseLocalData) {

            return responseLocalData;

        } else {
            const responseInfosAssets = await getAssetInfos(clientId, month, year,
                null, false);

            const { assetsFormatted } = responseInfosAssets;

            const responseRankingManagers = await doGetRankingManagers(getFirstBeforeBusinessDay(referenceDate));
            const responseRankingAdmins = await doGetRankingAdmins(getFirstBeforeBusinessDay(referenceDate));

            if (responseRankingManagers && responseRankingAdmins) {

                const formatted = this.formatToArticles4963Risk(assetsFormatted, responseRankingManagers, responseRankingAdmins);
                console.log('Formatted to articles risk: ', formatted);

                //salvar localmente as informações de articles_data
                const responseSaveLocal = await ClientRiskDataController.saveData(clientId, month, year, CLIENT_RISK_TYPES.ARTICLES, formatted);

                return formatted;

            }

            return null;
        }



    },

    async getBenchmarksData(clientId, referenceDate) {

        const { month, year } = getDayMonthYearByPtDate(referenceDate);

        //Verificar se já existe a informação na base local
        const responseLocalData = await ClientRiskDataController.getLocalData(clientId, month, year, CLIENT_RISK_TYPES.BENCHMARKS);

        if (responseLocalData) {

            return responseLocalData;

        } else {
            const currentPeriod = month + '/' + year;
            const responseInfosAssets = await getAssetInfos(clientId, month, year,
                null, false);

            console.log('responseInfosAssets: ', responseInfosAssets);

            const { assetsFormatted } = responseInfosAssets;
            console.log('assetsFormatted: ', assetsFormatted);

            const startDate = getMonthAgo(
                month,
                year,
                12);

            const bechmarksAssets = [];
            const mapInfoFunds = {};
            assetsFormatted.forEach(element => {
                if (element.fund_id) {
                    if (!bechmarksAssets.includes(element.benchmark_comdinheiro)) {
                        bechmarksAssets.push(element.benchmark_comdinheiro);
                    }
                    mapInfoFunds[element.fund_id] = {
                        cnpj: element.cnpj,
                        fund_name: element.fund_name,
                        benchmark_comdinheiro: element.benchmark_comdinheiro,
                        benchmark: element.benchmark,
                    }
                }

            });

            const endDate = getFirstBeforeBusinessDay(getLastPtDayMonth(month, year));

            const responseRents = await rentsByPeriod(clientId, startDate, endDate, null, 0);

            const responseRentsBenchmarks = await doGetRentsMes3m6m12m(null, bechmarksAssets, endDate);
            if (responseRentsBenchmarks) {

                const formattedRentsFunds = formatMyFundsToShow(responseRents.integralFundsBalancesMap, currentPeriod, mapInfoFunds, '12');

                const { mapRentsBenchmarks } = responseRentsBenchmarks;

                const formattedData = this.formatToBenchmarksRisk(formattedRentsFunds, mapRentsBenchmarks);

                //salvar localmente as informações de articles_data
                const responseSaveLocal = await ClientRiskDataController.saveData(clientId, month, year, CLIENT_RISK_TYPES.BENCHMARKS, formattedData);

                return formattedData;

            }

            return null;
        }


    }

}