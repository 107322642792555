import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { getInflationRates } from '../../../API/inflation_rate'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import MySelect from '../../utils/MySelect'

import moment, { calendarFormat } from 'moment'

import { formatDataToPieChart, getMonthName, getShortMonths, getYearsByYearUntilNow } from '../../utils/utils';

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

import _ from 'lodash';

//Charts
import { Line } from 'react-chartjs-2';
//import { Bar } from 'react-chartjs-2';

const color = unoTheme.chartColors;

const optionsChart2 = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    }
    ,
    blue: {
        color: 'blue',
    },

    main: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        width: '100%',
        borderRadius: '10px 0px 0px 10px',
        backgroundColor: 'white',
    },

    divTable: {
        width: '40%',
        maxWidth: '40%',
    },

    divChart: {
        width: '60%',
        maxWidth: '60%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '& .chartHeader': {
            minHeight: '56px',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            paddingTop: theme.spacing(2),
            paddingBottom: '12px',
            paddingRight: '13px',
            '& .MuiTypography-root': {
                marginRight: theme.spacing(1),
            }
        },

        '& .chartWrapper': {
            height: '450px',
            width: '100%',
        }


        // '& canvas': {
        //     width: '600px !important',
        //     [theme.breakpoints.down(800)]: {
        //         width: '400px !important',
        //     }
        // }
    }


}));


export default function InflationRatesScreen() {

    const classes = useStyles();

    const [inflationRates, setInflationRates] = useState([]);

    const dispatch = useDispatch();
    const history = useHistory();

    const rightContent = useSelector(state => state.rightContent)

    //const [appearFab, setAppearFab] = useState(true);
    const [years, setYears] = useState([]);
    const [dataToShow, setDataToShow] = useState([]);
    const [dataByYears, setDataByYears] = useState(null);
    const [dataChart, setDataChart] = useState(null);
    const [yearChartSelected, setYearChartSelected] = useState(null);
    const [typesChart, setTypesChart] = useState(['Valores mensais', 'Valores acumulados']);
    const [typeChartSelected, setTypeChartSelected] = useState(null);

    async function doGetInflationRates() {

        const response = await getInflationRates();
        if (response.success) {

            setInflationRates(response.body.rows);
            let years = getYearsByYearUntilNow(2015);
            let yearsReverse = _.cloneDeep(years);
            yearsReverse.reverse();
            setYears(yearsReverse);
            formatDataToShow(years, response.body.rows);
            console.log("INFLATION RATES: ", response.body.rows)

        } else {
            console.log("RESPONSE ERROR: ", response.error)
        }
    }

    // const [searchText, setSearchText] = useState("");

    // const delayedChange = (text) => {
    //     setTimeout(() => {
    //         setSearchText(text)
    //     }, 800)
    // }

    // function changeSearch(e) {

    //     console.log("SearchText: ", searchText)
    //     delayedChange(e.target.value.toUpperCase())
    //     clearTimeout(delayedChange);
    // }
    const handleChangeTypeChartYear = (value) => {

        //console.log("YEAR: ", yearChartSelected);
        setTypeChartSelected(value);
        formatDataToChart(years[yearChartSelected], dataByYears, value);

    };
    const handleChangeChartYear = (value) => {

        setYearChartSelected(value);
        formatDataToChart(years[value], dataByYears, typeChartSelected);

    };

    function getDataChart(d1, d2) {

        let dataChartInflationRates = {
            labels: getShortMonths(),
            type: 'line',
            datasets: [
                {
                    label: 'IPCA',
                    backgroundColor: "transparent",
                    borderColor: color[1],
                    pointBackgroundColor: color[1],
                    pointBorderColor: color[1],
                    pointHoverBackgroundColor: color[1],
                    pointHoverBorderColor: color[1],
                    data: d1
                },
                {
                    label: 'INPC',
                    backgroundColor: "transparent",
                    borderColor: color[2],
                    pointBackgroundColor: color[2],
                    pointBorderColor: color[2],
                    pointHoverBackgroundColor: color[2],
                    pointHoverBorderColor: color[2],
                    data: d2
                },

            ]
        };

        return dataChartInflationRates;
    }

    function formatDataToChart(year, localDataByYears, type) {


        //Inicializando select de chart year        

        let dataChartIPCA = [];
        let dataChartINPC = [];

        //type = 'acum';
        if (type == 1) {
            for (let m = 1; m <= 12; m++) {

                dataChartIPCA.push({
                    y: localDataByYears[year].inflationRates[m] && localDataByYears[year].inflationRates[m].ipcaAcum
                        ? parseFloat(localDataByYears[year].inflationRates[m].ipcaAcum) : 0,
                    id: 'IPCA-' + year + '-' + m,
                });

                dataChartINPC.push({
                    y: localDataByYears[year].inflationRates[m] && localDataByYears[year].inflationRates[m].inpcAcum
                        ? parseFloat(localDataByYears[year].inflationRates[m].inpcAcum) : 0,
                    id: 'INPC-' + year + '-' + m,
                });

            }
        } else {
            for (let m = 1; m <= 12; m++) {

                dataChartIPCA.push({
                    y: localDataByYears[year].inflationRates[m] && localDataByYears[year].inflationRates[m].ipca
                        ? parseFloat(localDataByYears[year].inflationRates[m].ipca) : 0,
                    id: 'IPCA-' + year + '-' + m,
                });

                dataChartINPC.push({
                    y: localDataByYears[year].inflationRates[m] && localDataByYears[year].inflationRates[m].inpc
                        ? parseFloat(localDataByYears[year].inflationRates[m].inpc) : 0,
                    id: 'INPC-' + year + '-' + m,
                });

            }
        }


        setDataChart(getDataChart(dataChartIPCA, dataChartINPC));

    }

    function formatDataToShow(years, inflationRates) {

        let localDataByYears = {};

        let dataToShow = [];
        let monthsInflationRates = {}

        years.forEach(element => {

            let inflationRatesYear = inflationRates.filter(el => element == el.year);

            console.log('inflationRatesYear: ', inflationRatesYear);

            let ipcaAcum = 1;
            let inpcAcum = 1;
            inflationRatesYear.forEach(ir => {

                //Calcular o ipcaAcumulado
                ipcaAcum *= ir.ipca ? (1 + (parseFloat(ir.ipca) / 100)) : 1;
                inpcAcum *= ir.inpc ? (1 + (parseFloat(ir.inpc) / 100)) : 1;

                ir.ipcaAcum = parseFloat(parseFloat((ipcaAcum - 1) * 100).toFixed(2));
                console.log("IPCA ACUMULADO[" + ir.month + "]: ", ir.ipcaAcum);
                ir.inpcAcum = parseFloat(parseFloat((inpcAcum - 1) * 100).toFixed(2));

                //Construindo objeto que tem key como o mês para ser usado na tela de detalhe
                monthsInflationRates[ir.month] = ir;

            });

            let inflationRateToPush = {
                year: element,

                //Aplicando última fase do cálculo do IPCA/INPC acumulado

                ipcaAcum: parseFloat((ipcaAcum - 1) * 100).toFixed(2),
                //ipcaAcum: parseFloat((ipcaAcum - 1) * 100),
                inpcAcum: parseFloat((inpcAcum - 1) * 100).toFixed(2),
                //inpcAcum: parseFloat((inpcAcum - 1) * 100),

                inflationRates: monthsInflationRates,
            };

            dataToShow.push(inflationRateToPush);

            monthsInflationRates = {}

            //Organizando em um objeto por ano para ser usado no gráfico
            localDataByYears[element] = inflationRateToPush;
        });

        dataToShow.reverse();

        console.log("DATA TO SHOW: ", dataToShow);

        setDataToShow(dataToShow);

        setDataByYears(localDataByYears);

        //Formatar data para gráfico
        let lastYear = years[years.length - 1];
        setYearChartSelected(0);
        formatDataToChart(lastYear, localDataByYears, 0);

    }

    useEffect(() => {

        if (dataToShow && dataToShow.length == 0) {

            doGetInflationRates();
        }

        if ((rightContent && rightContent.action)) {

            doGetInflationRates();
            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [rightContent]);

    return (
        <div className={classes.root}>


            <div className={classes.headerSearch}>

                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Indices de Inflação
                    </Typography>
                </div>
                <div className={classes.rightHeader}>

                    {/* <TextField id="standard-basic"
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Buscar..."
                        variant="outlined"
                        size="small" /> */}
                </div>

            </div>
            {/* <Divider /> */}
            {/* <br />
            <br /> */}
            <div class={'defaultScrollWithHeaderContent'}>
                <div className={classes.main}>
                    <div className={classes.divTable}>
                        <MyTable
                            //onScroll={(value) => setAppearFab(value)}
                            stickyHeader
                            size="small"
                            selectable='true'>
                            <colgroup>
                                <col width="40%" />
                                <col width="30%" />
                                <col width="30%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell /> */}
                                    {/* <TableCell align="left">Mês</TableCell> */}
                                    <TableCell align="left">Ano</TableCell>
                                    <TableCell align="right">IPCA</TableCell>
                                    <TableCell align="right">INPC</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataToShow.map((row) => (
                                    <TableRow
                                        key={'inflation-rate-' + row.year}
                                        onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                            path: 'newInflationRate',
                                            data: row,
                                        }))}>
                                        <TableCell align="left">{row.year}</TableCell>
                                        <TableCell align="right" className={classes.blue}>{row.ipcaAcum ? row.ipcaAcum + "%" : '-'}</TableCell>
                                        <TableCell align="right" className={classes.blue}>{row.inpcAcum ? row.inpcAcum + "%" : '-'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {/* <TableBody>
                        {inflationRates.map((row) => (
                            <TableRow onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                path: 'newInflationRate',
                                update: true,
                                data: row,
                            }))}>                                
                                <TableCell align="center">{row.year}</TableCell>
                                <TableCell align="right" className={classes.blue}>{row.ipca}%</TableCell>
                                <TableCell align="right" className={classes.blue}>{row.inpc}%</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        ))}
                    </TableBody> */}
                        </MyTable>
                    </div >
                    {
                        dataChart ?
                            <div className={classes.divChart}>
                                <div className={'chartHeader'}>

                                    <Typography variant={'body1'}>IPCA x INPC</Typography>
                                    <MySelect
                                        style={{ marginRight: '8px' }}
                                        value={typeChartSelected}
                                        verysmall
                                        onChange={(e) => handleChangeTypeChartYear(e.target.value)}
                                        options={typesChart.map((row, index) => (

                                            <option key={'type-chart-' + index} value={index}>{row}</option>

                                        ))}
                                    />
                                    <MySelect
                                        value={yearChartSelected}
                                        verysmall
                                        onChange={(e) => handleChangeChartYear(e.target.value)}
                                        options={years.map((row, index) => (

                                            <option key={'chart-year-' + index} value={index}>{row}</option>

                                        ))}
                                    />
                                </div>


                                <div className={'chartWrapper'}>
                                    <Line data={dataChart} options={optionsChart2} />
                                </div>

                            </div>

                            : null
                    }
                </div>

            </div>

        </div>

    );
}


