import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Divider, Paper, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import UnoButton from "../../utils/UnoButton";
import { unoTheme } from "../../../assets/styles/unoTheme";

//Tables
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTextField from "../../utils/MyTextField";

import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import Switch from '@material-ui/core/Switch';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    getArrayMonthsWithNames,
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    applyMaskAccount,
    parseFloatByPtCurrency,
    isDateAfterClosedMonth
} from "../../utils/utils";
import { createBalanceValidation } from "../../../API/client";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import _ from 'lodash';

import NewAsset from "./NewAsset";

import moment from 'moment'

const GreenSwitch = withStyles({
    switchBase: {
        //color: unoTheme.greenMoney,
        '&$checked': {
            color: unoTheme.greenMoney,
        },
        '&$checked + $track': {
            backgroundColor: unoTheme.greenMoney,
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        backgroundColor: unoTheme.hoverTable,
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiTableContainer-root': {
            height: 'calc(100% - 218px)',
        },

        '& .MuiTableHead-root': {
            '& .MuiTableCell-head': {

                //background: `${unoTheme.hoverTable} !important`,
            }
        }
    },

    leftPadding24: {
        paddingLeft: theme.spacing(3),
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },

    table: {
        height: 'calc(100% - 1px)',


        // '& .MuiTableRow-root': {

        //     borderRadius: '10px',

        //     '&:nth-of-type(odd)': {
        //         backgroundColor: unoTheme.secondaryTuplaTable,
        //         //backgroundColor: 'red',
        //     }
        // },

        '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
                background: 'white',
            }
        }
    },

    detailRow: {
        cursor: "pointer",
        '& > *': {
            padding: '3px 16px 3px 16px !important',
            textTransform: 'uppercase',
            color: unoTheme.defaultTextColor,
            fontWeight: 400,
            fontSize: '14px',
        },

        '& .MuiInputBase-input': {
            height: '20px !important',
        },

        '& .MuiTableCell-root:first-child': {
            paddingLeft: '24px !important',
        }
        // '&:nth-of-type(odd)': {
        //     backgroundColor: unoTheme.secondaryTuplaTable,
        // },

    },

    flexCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100%',
        paddingLeft: '0px !important',

        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    statusOff: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusOk: {
        color: unoTheme.greenMoney,
        fill: unoTheme.greenMoney,
    },
    statusPendent: {
        color: unoTheme.warningColor,
        fill: unoTheme.warningColor,
    },
    marginLeft: {
        marginLeft: '5px',
    },
    leftHeader: {

        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    disableMonth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },

    headerDate: {
        display: 'flex',
        alignItems: 'center',
        color: unoTheme.secondaryColor,
        fontSize: '12px',
        '& svg': {
            marginRight: '5px',
        },
        '& > *': {
            fontSize: '14px',
        }
    },

    accountLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228'
    },

    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    tabsAsset: {
        background: '#F3F5F8',
    }


}));

export default function DetailBalanceValidation(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)

    const { content } = props;

    const [disableAll, setDisableAll] = useState(false);

    const [openBackdrop, setOpenBackdrop] = useState(false);

    //Tab de saldos do ano ou descrição do ativo
    const [tabValue, setTabValue] = useState(0); //detail
    function handleChangeTabValue(event, value) {
        setTabValue(value)
    }

    const [months, setMonths] = useState(getArrayMonthsWithNames())

    const [inputs, setInputs] = useState([
        {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
    ]);

    //const [initInputs, setInitInputs] = useState(undefined);

    console.log("DATA SELECIONADA: ", content)

    function initInputs(data) {


        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        let newInputs = []

        months.forEach(m => {

            let dataLoop = data.main[m];

            newInputs.push({
                balance_validation_id: dataLoop.id,
                month: m,
                //Atributo que indica se esse mês de carteira já fechada
                isAlreadyLaunched: !isDateAfterClosedMonth(
                    moment.utc(new Date(dataLoop.year_validation, dataLoop.month_validation - 1, 1)).format("DD/MM/YYYY"),
                    clientOn.client.portfolio_closed,
                    false
                ),
                balance: dataLoop.balance != null ? applyMaskAmount(dataLoop.balance) : '',
                quota_amount: dataLoop.quota_amount != null ? applyMaskAmountFreeDecimals(dataLoop.quota_amount, null, 10) : '',
                status: dataLoop.balance != null ? true : false,
            })

        });

        console.log("NEW INPUTS: ", newInputs)
        console.log("MONTHS: ", months)
        setInputs(newInputs);

        const hasDisabledRoles = currentUser?.user?.isManager || currentUser?.user?.isSubManager || currentUser?.user?.isViewer;

        if (hasDisabledRoles) {
            setDisableAll(true)
        }

    }

    function handleChangeBalanceInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].balance = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    function handleChangeQuotaInput(value, index) {

        let newInputs = [...inputs]
        newInputs[index].quota_amount = value
        newInputs[index].statusChange = true
        setInputs(newInputs);
    }

    function changeStatusBalance(e, index) {

        console.log("STATUS: ", e.target.checked)

        let value = e.target.checked;

        let newInputs = [...inputs]

        newInputs[index].status = value
        if (!value && !newInputs[index].statusChange) {
            newInputs[index].statusChange = false
        }

        console.log("NEW INPUTS: ", newInputs)
        setInputs(newInputs);
    }

    async function doSaveBalancesValidations(ipts, year) {

        setOpenBackdrop(true)

        //let filterStatusOk = ipts.filter(el => el.status || el.statusChange)
        console.log("TO SAVE BALANCES: ", ipts);
        //São filtrados os objetos que sofreram alteração e que estão com status de OK
        let filterStatusOk = ipts.filter(el => el.statusChange)
        console.log("TO SAVE BALANCES: ", filterStatusOk);

        let allSuccess = true;
        for (let i = 0; i < filterStatusOk.length; i++) {

            let balanceToSave = {
                //Caso seja um update de um balanço já criado
                balance_validation_id: filterStatusOk[i].balance_validation_id,
                //Caso seja um balanço de conta
                account_id: content.data.account_init ? content.data.id : null,
                //Caso seja um balanço de ativo
                asset_id: content.data.asset_init ? content.data.id : null,
                //O mês do balanço
                month_validation: filterStatusOk[i].month,
                //O ano do balanço
                year_validation: year,
                //Valor do balanço
                balance: parseFloatByPtCurrency(filterStatusOk[i].balance),

                quota_amount: parseFloatByPtCurrency(filterStatusOk[i].quota_amount),
            }

            console.log("BALANCE TO SAVE: ", balanceToSave);

            let response = await createBalanceValidation(balanceToSave)

            if (!response.success) {
                allSuccess = false
            }
        }

        setOpenBackdrop(false)
        dispatch(allActions.rightContentActions.update())

        if (allSuccess) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Saldos cadastrados com sucesso!'
            }))

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao cadastrar saldos!'
            }))
        }

    }



    useEffect(() => {
        console.log("USE EFFECT")
        if (content && content.data) {

            //console.log("DATA: ", content)
            console.log("DATA: ", content.data)
            initInputs(content.data);

        }
    }, [])

    return (

        <div className={classes.root}>


            <Paper style={{ marginBottom: '4px' }}>
                <div className={classes.header}>

                    <div className={classes.leftHeader}>
                        {/* <div className={classes.headerDate}>
                        <EventIcon />
                        <Typography
                            align={'left'}

                            className={classes.labelMonth}>
                            {content && content.data && content.data ? content.data.selectedYear : ""}
                        </Typography>
                    </div> */}
                        {
                            content && content.data
                                && content.data.account_init ? //caso seja uma conta

                                //No caso de ser uma conta corrent

                                <div>

                                    <Typography
                                        align={'left'}
                                        className={classes.fundLabel}>
                                        {content && content.data && content.data ? content.data.description : ""}

                                    </Typography>
                                    {content && content.data && content.data ?
                                        <Typography
                                            align={'left'}
                                            className={classes.accountLabel}>
                                            <span>{"Ag: " + (content.data.agency ? content.data.agency : '-')}</span>
                                            <span className={classes.spacingLeft2}>{"CC: " + applyMaskAccount(content.data.number_account)}</span>

                                        </Typography>
                                        : null
                                    }

                                </div>

                                :

                                <div>

                                    <Typography
                                        align={'left'}
                                        className={classes.fundLabel}>
                                        {content && content.data && content.data ? content.data.fund_name : ""}
                                    </Typography>
                                    {content && content.data && content.data ?
                                        <Typography
                                            align={'left'}
                                            className={classes.accountLabel}>
                                            <span>{"Ag: " + (content.data.agency ? content.data.agency : '-')}</span>
                                            <span className={classes.spacingLeft2}>{"CC: " + applyMaskAccount(content.data.number_account)}</span>

                                        </Typography>
                                        : null
                                    }
                                </div>
                        }
                    </div>

                    <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                        <CloseIcon />
                    </IconButton>

                </div>


                <Paper square className={classes.tabsAsset}>
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>
                        <Tab label={<span style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}><EventIcon /> <span style={{ marginLeft: '5px' }}>{" Saldos " + (content && content.data && content.data ? content.data.selectedYear : "")}</span></span>} />

                        {content && content.data
                            && !content.data.account_init ?
                            <Tab label="Detalhe do ativo" />
                            : null
                        }

                    </Tabs>
                </Paper>



            </Paper>
            {tabValue == 0 ?
                <div className={classes.main}>


                    <TableContainer component={Paper} elevation={0}>
                        <Table className={classes.table}
                            stickyHeader
                            size="small"
                            aria-label="simple table">

                            {
                                content.data.account_init ?
                                    <colgroup>
                                        <col width="25%" />
                                        <col width="60%" />
                                        <col width="15%" />
                                    </colgroup>
                                    :
                                    <colgroup>
                                        <col width="25%" />
                                        <col width="30%" />
                                        <col width="30%" />
                                        <col width="15%" />
                                    </colgroup>
                            }

                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.leftPadding24} align="left">MÊS</TableCell>
                                    <TableCell align="center">SALDO</TableCell>
                                    {
                                        content.data.account_init ?
                                            null :
                                            <TableCell align="center">Q.COTAS</TableCell>
                                    }
                                    {
                                        disableAll ? //Caso seja um usuário manager não há a necessidade de exivir a coluna de status
                                            null :
                                            <TableCell align="center">STATUS</TableCell>
                                    }

                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.table}>
                                {
                                    months.map((row) => (
                                        <>

                                            <TableRow className={classes.detailRow}
                                                key={'detail-month' + row.id}>

                                                <TableCell align="left">
                                                    {row.name}
                                                </TableCell>


                                                {/*VALOR DO SALDO*/}
                                                <TableCell align="left">
                                                    {

                                                        content && content.data && parseInt(row.id) >= content.data.main.init ?
                                                            <MyTextField
                                                                disabled={inputs[parseInt(row.id) - 1].status || disableAll}
                                                                align="right"
                                                                onChange={(value) => handleChangeBalanceInput(value, parseInt(row.id) - 1)}
                                                                value={inputs[parseInt(row.id) - 1].balance}
                                                                small
                                                                placeholder="0.00"
                                                                //mask={["currency"]}
                                                                variant="outlined" />
                                                            :
                                                            <Typography
                                                                align="center"
                                                                className={classes.smallText}>
                                                                {'-'}
                                                            </Typography>
                                                    }

                                                </TableCell>

                                                {/*VALOR QUANTIDADE DE QUOTAS*/}
                                                {
                                                    content.data.account_init ?
                                                        null
                                                        :
                                                        <TableCell align="left">
                                                            {

                                                                content && content.data && parseInt(row.id) >= content.data.main.init ?
                                                                    <MyTextField
                                                                        disabled={inputs[parseInt(row.id) - 1].status || disableAll}
                                                                        align="right"
                                                                        onChange={(value) => handleChangeQuotaInput(value, parseInt(row.id) - 1)}
                                                                        value={inputs[parseInt(row.id) - 1].quota_amount}
                                                                        placeholder="0.00"
                                                                        //verysmall
                                                                        size={'small'}
                                                                        variant="outlined" />
                                                                    :
                                                                    <Typography
                                                                        align="center"
                                                                        className={classes.smallText}>
                                                                        {'-'}
                                                                    </Typography>
                                                            }
                                                        </TableCell>

                                                }


                                                {

                                                    !disableAll ?
                                                        <>
                                                            {
                                                                //verificando se existe saldo pra esse mês
                                                                //Caso o row.id seja menor que o init (representa o mês do ano que iniciou o ativo)
                                                                //o switch deve ser disable

                                                                //Caso esse seja um mês em que a carteira já foi fechada exibe-se o disable
                                                                parseInt(row.id) >= content.data.main.init
                                                                    && !inputs[parseInt(row.id) - 1].isAlreadyLaunched ?

                                                                    <TableCell align="left" className={classes.flexCell}>
                                                                        <GreenSwitch
                                                                            checked={(inputs[parseInt(row.id) - 1].status
                                                                                || inputs[parseInt(row.id) - 1].statusChange)
                                                                                || (inputs[parseInt(row.id) - 1].status == undefined && inputs[parseInt(row.id) - 1].statusChange == undefined)}
                                                                            onChange={(e) => changeStatusBalance(e, parseInt(row.id) - 1)} />

                                                                    </TableCell>
                                                                    :

                                                                    //invalid
                                                                    <TableCell align="left" className={classes.flexCell}>
                                                                        <GreenSwitch
                                                                            disabled />
                                                                    </TableCell>
                                                            }
                                                        </> : null

                                                }



                                            </TableRow >
                                        </>
                                    ))

                                }
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <Divider />

                    {
                        !disableAll &&
                            (
                                currentUser && currentUser.user
                                && (
                                    currentUser.user.role == "ROLE_ADMIN"
                                    || currentUser.user.role == "ROLE_SUPER_ADMIN"
                                    || currentUser.user.role == "ROLE_ADVISOR")
                            ) ?

                            <div className={classes.bottom}>


                                <UnoButton
                                    onClick={() => doSaveBalancesValidations(inputs, content.data.selectedYear)}
                                    className={classes.spacingRight2}
                                    type='primary'
                                    size={'small'}>
                                    {"Salvar saldos"}
                                </UnoButton>
                            </div>
                            : null
                    }


                </div>


                :

                <NewAsset content={{
                    data: {
                        update: true,
                        assetId: content.data.id,
                        fundId: content.data.fund_id,
                        fundName: content.data.fund_name,
                        assetInit: content.data.asset_init,
                        balanceInit: content.data.balance,
                        selectedAccountId: content.data.account_id,
                        selectedMonth: clientOn.client.selectedMonth,
                        selectedYear: clientOn.client.selectedYear,
                    }
                }}
                />

            }

            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }

        </div >
    );
}



