import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';

//Text field 
import MyTextField from '../../../utils/MyTextField'
import MySwitch from '../../../utils/MySwitch'

//UnoButton
import UnoButton from '../../../utils/UnoButton'
import { unoTheme } from "../../../../assets/styles/unoTheme";

//Grid
import { Divider, Grid } from "@material-ui/core";

//API
import { updateClientCadprevInfos } from '../../../../API/client'

//Redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import allActions from "../../../../actions";

import moment from 'moment'
import { headerSearch } from "../../../../assets/styles/unoTheme";

// Utils
import _, { isEmpty } from 'lodash';
import { isValidCpf } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    main: {
        padding: theme.spacing(2),
    },

    headerSearch: headerSearch,
    switchs: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',

        '& .MuiTypography-root': {
            marginTop: '-2px',
        }

    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },

    buttonsHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    maginRight2: {
        marginRight: theme.spacing(2),
    },
    input: {
        display: 'none',
    },

    imageSelected: {
        width: '100px',
        borderRadius: '10px',
        float: 'left',
        cursor: 'pointer',
        //border: 'solid 1px ' + unoTheme.mainColor
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    }

}));

export default function ClientCadprevScreen({
    clientOnScreen }) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [cpfPresidentCadprev, setCpfPresidentCadprev] = useState('')
    const [passwordPresidentCadprev, setPasswordPresidentCadprev] = useState('')
    const [cpfManagerCadprev, setCpfManagerCadprev] = useState('')
    const [passwordManagerCadprev, setPasswordManagerCadprev] = useState('')
    const [cpfLemaCadprev, setCpfLemaCadprev] = useState('')
    const [passwordLemaCadprev, setPasswordLemaCadprev] = useState('')

    const [namePresidentCadprev, setNamePresidentCadprev] = useState('')
    const [nameManagerCadprev, setNameManagerCadprev] = useState('')
    const [nameLiquidantCadprev, setNameLiquidantCadprev] = useState('')

    const [loginPresidentActive, setLoginPresidentActive] = useState(false)
    const [loginManagerActive, setLoginManagerActive] = useState(false)
    const [loginLemaActive, setLoginLemaActive] = useState(false)

    function handleActiveLoginCadprev(checked, loginType) {

        console.log("CHECKED: ", checked);

        if (checked) {
            if (loginType == 'president') {
                setLoginManagerActive(false);
                setLoginLemaActive(false);
                setLoginPresidentActive(true);
            } else if (loginType == 'manager') {
                setLoginPresidentActive(false);
                setLoginLemaActive(false);
                setLoginManagerActive(true);
            } else {
                setLoginPresidentActive(false);
                setLoginManagerActive(false);
                setLoginLemaActive(true);
            }
        }

    }

    const [passwordReseted1, setPasswordReseted1] = useState(false);
    const [passwordReseted2, setPasswordReseted2] = useState(false);

    //form    
    const [submitted, setSubmitted] = useState(false);

    async function doSaveInfosCadprevClient(event) {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: 'Atualizando informações CADPREV'
        }))

        event.preventDefault();

        setSubmitted(true);

        if (!loginPresidentActive && !loginManagerActive && !loginLemaActive) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: 'Selecione o login ativo do CADPREV'
            }))

            setSubmitted(false);
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))
            return;

        }

        //validação prévia
        // if (
        //     (!cpfPresidentCadprev || (cpfPresidentCadprev != "" && isValidCpf(cpfPresidentCadprev)))
        //     && (!cpfManagerCadprev || (cpfManagerCadprev != "" && isValidCpf(cpfManagerCadprev)))
        // ) {

        setSubmitted(false);

        const cadprevInfosClient = {

            cpf_president_cadprev: cpfPresidentCadprev,
            password_president_cadprev: passwordPresidentCadprev,
            cpf_manager_cadprev: cpfManagerCadprev,
            password_manager_cadprev: passwordManagerCadprev,
            cpf_lema_cadprev: cpfLemaCadprev,
            password_lema_cadprev: passwordLemaCadprev,

            name_president_cadprev: namePresidentCadprev,
            name_manager_cadprev: nameManagerCadprev,
            name_liquidant_cadprev: nameLiquidantCadprev,

            login_cadprev_active: loginPresidentActive ? 'president' : (loginManagerActive ? 'manager' : 'lema')

        }

        const responseCadprevUpdateInfos = await updateClientCadprevInfos(clientOnScreen.id, cadprevInfosClient);

        if (responseCadprevUpdateInfos.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Informações do CADPREV atualizadas com sucesso'
            }))

            //updateActiveClients();

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            history.push('/administration/clients');


        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao atualizar informações do CADPREV'
            }))
        }


        // } else {
        //     console.log("Não passou validação");
        //     console.log((cpfPresidentCadprev == "" || (cpfPresidentCadprev != "" && isValidCpf(cpfPresidentCadprev))));
        //     console.log((cpfManagerCadprev == "" || (cpfManagerCadprev != "" && isValidCpf(cpfManagerCadprev))));
        // }

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))


    }

    function initForm(client) {

        setCpfPresidentCadprev(client.cpf_president_cadprev);
        setPasswordPresidentCadprev(client.password_president_cadprev)
        setNamePresidentCadprev(client.name_president_cadprev)
        // if (!client.password_president_cadprev) {
        //     setPasswordReseted1(true);
        // }
        setCpfManagerCadprev(client.cpf_manager_cadprev);
        setPasswordManagerCadprev(client.password_manager_cadprev)
        setNameManagerCadprev(client.name_manager_cadprev)

        setCpfLemaCadprev(client.cpf_lema_cadprev);
        setPasswordLemaCadprev(client.password_lema_cadprev)

        setNameLiquidantCadprev(client.name_liquidant_cadprev)


        setLoginPresidentActive(client.login_cadprev_active == 'president' ? true : false)
        setLoginManagerActive(client.login_cadprev_active == 'manager' ? true : false)
        setLoginLemaActive(client.login_cadprev_active == 'lema' ? true : false)

        // if (!client.password_manager_cadprev) {
        //     setPasswordReseted2(true);
        // }


    };

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        if (clientOnScreen) {

            initForm(clientOnScreen)

        }

    }, [clientOnScreen]);

    return (
        <div className={classes.root}>

            <div class='defaultScrollWithHeaderContent'>
                <div className={classes.main}>
                    <form className={classes.rootForm} noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField required
                                    outerlabel="Nome Presidente"
                                    size={'small'}
                                    variant="outlined"
                                    value={namePresidentCadprev}
                                    placeholder={'Insira o Nome'}
                                    onChange={(value) => setNamePresidentCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField required
                                    outerlabel="CPF Presidente"
                                    size={'small'}
                                    variant="outlined"
                                    value={cpfPresidentCadprev}
                                    mask={['cpf']}
                                    placeholder={'Insira o CPF'}
                                    onChange={(value) => setCpfPresidentCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="Senha presidente"
                                    value={passwordPresidentCadprev}
                                    onChange={(value) => setPasswordPresidentCadprev(value)}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={"Insira a senha"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <MySwitch
                                    outerlabel="LOGIN PRESIDENTE"
                                    checked={loginPresidentActive}
                                    onChange={(e) => handleActiveLoginCadprev(e.target.checked, 'president')}
                                    color="primary" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField required
                                    outerlabel="Nome Gestor"
                                    size={'small'}
                                    variant="outlined"
                                    value={nameManagerCadprev}
                                    placeholder={'Insira o Nome'}
                                    onChange={(value) => setNameManagerCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="CPF GESTOR"
                                    size={'small'}
                                    variant="outlined"
                                    value={cpfManagerCadprev}
                                    mask={['cpf']}
                                    placeholder={'Insira o CPF'}
                                    onChange={(value) => setCpfManagerCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="Senha gestor"
                                    value={passwordManagerCadprev}
                                    onChange={(value) => setPasswordManagerCadprev(value)}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={"Insira a senha"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <MySwitch
                                    outerlabel="LOGIN GESTOR"
                                    checked={loginManagerActive}
                                    onChange={(e) => handleActiveLoginCadprev(e.target.checked, 'manager')}
                                    color="primary" />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <MyTextField required
                                    outerlabel="Nome Liquidante"
                                    size={'small'}
                                    variant="outlined"
                                    value={nameLiquidantCadprev}
                                    placeholder={'Insira o Nome'}
                                    onChange={(value) => setNameLiquidantCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="CPF LEMA"
                                    size={'small'}
                                    variant="outlined"
                                    value={cpfLemaCadprev}
                                    mask={['cpf']}
                                    placeholder={'Insira o CPF'}
                                    onChange={(value) => setCpfLemaCadprev(value)} />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <MyTextField
                                    outerlabel="Senha LEMA"
                                    value={passwordLemaCadprev}
                                    onChange={(value) => setPasswordLemaCadprev(value)}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={"Insira a senha"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <MySwitch
                                    outerlabel="LOGIN UNO"
                                    checked={loginLemaActive}
                                    onChange={(e) => handleActiveLoginCadprev(e.target.checked, 'lema')}
                                    color="primary" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <UnoButton
                                    size={'small'}
                                    variant="contained"
                                    type="primary"
                                    onClick={(e) => doSaveInfosCadprevClient(e)}>
                                    {"Salvar informações"}
                                </UnoButton>
                            </Grid>

                        </Grid>

                    </form>
                </div>
            </div>


        </div>
    );
}
