const setRightContent = (content) => {
    return {
        type: "SET_RIGHT_CONTENT",
        payload: content
    }
}

const update = () => {
    return {
        type: "UPDATE",
    }
}


//content:{
//    task: 
//    data:
//}
const setAction = (content) => {
    return {
        type: "SET_ACTION",
        payload: content
    }
}

export default {
    setRightContent,
    update,
    setAction,
}