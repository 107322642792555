import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

//Table
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from '@material-ui/core';

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions';
import { calendarFormat } from 'moment';
import UnoButton from '../utils/UnoButton';
import MyInfo from '../utils/MyInfo';
import { getMonthName } from '../utils/utils';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { unoTheme } from '../../assets/styles/unoTheme';

import { createUpdateClientPolicy } from '../../API/client'

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    spaceRight2: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    noMarginTop: {
        marginTop: "0px",
    },
    afirmativeText: {
        color: 'black'
    },
    row: {
        '& .MuiTableCell-root': {
            //fontSize: '12px !important',
        }
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },
    iconChange: {
        verticalAlign: 'middle',
        marginTop: '-3px',
    },
    gree: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    rowTarget: {

        '& .green': {
            color: unoTheme.greenMoney,
        },

        '& .red': {
            color: unoTheme.redMoney,
        },
    },
    dialogContent: {
        overflow: 'hidden',
        '& .green': {
            color: unoTheme.greenMoney,
        },

        '& .red': {
            color: unoTheme.redMoney,
        },
    }

}));

export default function NewLegislationArticleModal(props) {

    const {
        open,
        policys,
        handleModalPolicy
    } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)

    const [registerNewArticle, setRegisterNewArticle] = useState("");
    const [oldMonthPolicy, setOldMonthPolicy] = useState(null);
    const [newMonthPolicy, setNewMonthPolicy] = useState(null);
    const [yearPolicy, setYearPolicy] = useState(null);

    useEffect(() => {

        console.log("ON MODAL POLICYS: ", policys);
        if (policys && policys.length > 0) {

            let p = policys[0];

            setOldMonthPolicy(p.old_month)
            setNewMonthPolicy(clientOn.client.selectedPortfolioMonth)
            setYearPolicy(clientOn.client.selectedPortfolioYear);

        }

    }, [policys])

    async function updatePolicys(policysToUpdate) {

        let allSuccess = true;
        for (let i = 0; i < policysToUpdate.length; i++) {

            let p = policysToUpdate[i];

            //deletando o id do registro de política para que seja criado um novo com o novo mês
            delete p.client_policy_id;

            let response = await createUpdateClientPolicy(p);

            if (!response.success) {
                allSuccess = false;
            }

        }

        handleModalPolicy(false, true);

        if (allSuccess) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "success",
                message: "Política de investimentos atualizada com sucesso!"
            }))
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "error",
                message: "Falha ao atualizar política de investimento"
            }))
        }

    }

    function loadInfos(article) {

    }

    function cleanForm() {
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}>
            <DialogTitle
                className={classes.dialogTitleApr}
                id="dialog-title-apr">
                {"Atualização de política detectada"}
            </DialogTitle>
            <DialogContent
                className={classes.dialogContent}>

                <Grid container spacing={3}>

                    <Grid item md={3}>

                        <MyInfo
                            outerlabel={'Ano'}
                            label={yearPolicy}>
                        </MyInfo>
                    </Grid>

                    <Grid item md={9}>


                        {
                            newMonthPolicy && oldMonthPolicy &&
                                newMonthPolicy != oldMonthPolicy ?
                                <MyInfo outerlabel={"Mês"}
                                    label={
                                        <span className={'red'}>{getMonthName(oldMonthPolicy)}<span className={'green'}>
                                            <ArrowRightAltIcon className={classes.iconChange} />
                                            {getMonthName(newMonthPolicy)}
                                        </span></span>}>

                                </MyInfo>

                                : null
                        }
                    </Grid>

                    <Grid item md={12}>

                        {
                            policys
                                && policys.length > 0 ?
                                <TableContainer component={Paper} elevation={0}>
                                    <Table size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.noPaddingLeft} align="left">{'Ativo'}</TableCell>
                                                <TableCell align="left">{'Dispositivo'}</TableCell>

                                                <TableCell align="right">{'Alvo Inferior'}</TableCell>
                                                <TableCell align="right">{'Alvo'}</TableCell>
                                                <TableCell className={classes.noPaddingRight} align="right">{'Alvo Superior'}</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {policys.map((row, index) => (

                                                <TableRow
                                                    className={classes.row}
                                                    key={'fund-to-local-create-' + index}>
                                                    <TableCell align="left" className={classes.noPaddingLeft} component="th" scope="row">{row.asset}</TableCell>
                                                    <TableCell align="left">{row.device}</TableCell>
                                                    <TableCell align="right" className={classes.rowTarget}>

                                                        <span className={row.lower_target != row.old_lower_target ? 'red' : ""}>
                                                            {row.old_lower_target ? row.old_lower_target + "%" : "-"}
                                                        </span>

                                                        {
                                                            row.lower_target != row.old_lower_target ?
                                                                <span className={'green'}>
                                                                    <ArrowRightAltIcon className={classes.iconChange} />
                                                                    {row.lower_target ? row.lower_target + "%" : "-"}
                                                                </span>
                                                                : null
                                                        }

                                                    </TableCell>
                                                    <TableCell align="right" className={classes.rowTarget}>

                                                        <span className={row.target != row.old_target ? 'red' : ""}>
                                                            {row.old_target ? row.old_target + "%" : "-"}
                                                        </span>

                                                        {
                                                            row.target != row.old_target ?
                                                                <span className={'green'}>
                                                                    <ArrowRightAltIcon className={classes.iconChange} />
                                                                    {row.target ? row.target + '%' : "-"}
                                                                </span>
                                                                : null
                                                        }

                                                    </TableCell>
                                                    <TableCell className={classes.noPaddingRight} align="right" className={classes.rowTarget}>

                                                        <span className={row.top_target != row.old_top_target ? 'red' : ""}>
                                                            {row.old_top_target ? row.old_top_target + "%" : "-"}
                                                        </span>

                                                        {
                                                            row.top_target != row.old_top_target ?
                                                                <span className={'green'}>
                                                                    <ArrowRightAltIcon className={classes.iconChange} />
                                                                    {row.top_target ? row.top_target + "%" : "-"}
                                                                </span>
                                                                : null
                                                        }

                                                    </TableCell>
                                                </TableRow>

                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                                : null
                        }
                    </Grid>

                </Grid>




            </DialogContent>
            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() =>
                    handleModalPolicy(false, false)} color="secondary">
                    {'Fechar'}
                </UnoButton>
                <div>
                    <UnoButton
                        className={classes.spaceRight2}
                        type={'successClean'}
                        onClick={() => updatePolicys(policys)}>
                        {'Atualizar'}
                    </UnoButton>
                </div>
            </DialogActions>

        </Dialog >
    );
}