import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

//Table
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../../utils/MyTable';

import { useDispatch, useSelector } from "react-redux";



import allActions from "../../../../actions";
import { headerSearch, unoTheme } from "../../../../assets/styles/unoTheme";
import { RiskController } from "../../../../controllers/RiskController";
import { codsInnerLoad } from "../../../utils/MyInnerLoad";
import { getFirstBeforeBusinessDay, getLastPtDayMonth, greenOrRed } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    headerSearch: headerSearch,

    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableContainer: {
        padding: '0px 18px 16px 18px',
        borderRadius: '10px 0px 0px 10px',
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    clickableHead: {
        cursor: 'pointer',
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },

    leftChart: {
        padding: theme.spacing(2),
        width: '50%',
    },

    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .3s ease',
    },

    sectionTableCellData: {
        width: '30%',
        display: 'table-cell',
        padding: '0px !important',
        fontSize: '12px',
    },

    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
        fontSize: '12px',
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '50%',
        }
    },

}));

export default function RiskArticlesScreen(props) {

    const { data } = props;

    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(() => {

    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.mainRoot}>
                <div class={'defaultScrollWithHeaderContent'}>
                    <div>
                        <MyTable
                            stickyHeader>
                            <colgroup>
                                <col width="25%" />
                                <col width="15%" />
                                <col width="7%" />
                                <col width="7%" />
                                <col />
                                <col width="10%" />
                                <col />
                                <col width="10%" />
                                <col width="7%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>

                                    <TableCell align="left">Fundo</TableCell>
                                    <TableCell align="right">Saldo</TableCell>
                                    <TableCell align="center">Art. 18</TableCell>
                                    <TableCell align="center">Art. 19</TableCell>
                                    <TableCell align="left">Gestor</TableCell>
                                    <TableCell align="center">Art. 20</TableCell>
                                    <TableCell align="left">Administrador</TableCell>
                                    <TableCell align="center">Art. 21</TableCell>
                                    <TableCell align="center"></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((row, index) => (
                                    <TableRow key={'diarys-' + index}>


                                        <TableCell align="left">{row.fund_name}</TableCell>
                                        <TableCell align="right" style={{ color: greenOrRed(row.balance_now) }}>{row.balance_now}</TableCell>
                                        <TableCell align="center" style={{ color: row.outArt18 ? unoTheme.redMoney : unoTheme.greenMoney }}>{row.art18}</TableCell>
                                        <TableCell align="center" style={{ color: row.outArt19 ? unoTheme.redMoney : unoTheme.greenMoney }}>{row.art19}</TableCell>
                                        <TableCell align="left">{row.manager_name}</TableCell>
                                        <TableCell align="center" style={{ color: row.outArt20 ? unoTheme.redMoney : unoTheme.greenMoney }}>{row.art20}</TableCell>
                                        <TableCell align="left">{row.administrator_name}</TableCell>
                                        <TableCell align="center" style={{ color: row.outArt20 ? unoTheme.redMoney : unoTheme.greenMoney }}>{row.art21}</TableCell>
                                        <TableCell align="center" >{row.device_abbreviation}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </MyTable>
                    </div>

                </div>


            </div>

        </div>
    );
}



