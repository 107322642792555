import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//redux
import MyDatePicker from '../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio } from '../../API/client'

//Controllers
import { saveLogResetPortfolio } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Grid, Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
import { applyMaskAmount, formatAnbimaDateToPt, formatPortfolioClosed, formatPtStringDateToAnbimaDate, getMonthName, parseFloatByPtCurrency, stringPtDateToMoment } from '../utils/utils';
import MyTextField from '../utils/MyTextField';
import { getLastDayQuotaInMonth } from '../../API/fund_local_quotas';
import { doGetAllLocalQuotasByFundId, doGetAllLocalQuotasByFundIdMonthAndYear, doSaveOrUpdateLocalQuotas } from '../../controllers/FundLocalQuotasController';

import AddIcon from '@material-ui/icons/Add';
import MyAutoComplete from '../utils/MyAutoComplete';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    }


}));

export default function NewSpecialClientQuotaModal(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { open,
        handleAction,
        asset,
        month,
        year,
        lastDayQuotaInMonth } = props;

    const [inputDateNewQuota, setInputDateNewQuota] = useState(null);
    const [inputValueNewQuota, setInputValueNewQuota] = useState("");

    //Cotas
    const [fundLocalQuotas, setFundLocalQuotas] = useState(null);

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    const [submmitedEditQuota, setSubmittedEditQuota] = useState(false);

    function loadValueQuotaDate(localQuotas, dateSelected) {

        // if (localQuotas) {
        //     const ptDate = moment.utc(dateSelected).format('DD/MM/YYYY');
        //     const matchedQuota = localQuotas.find(el => el.data_referencia == formatPtStringDateToAnbimaDate(ptDate));
        //     if (matchedQuota) {
        //         const clientQuota = matchedQuota['valor_cota_' + clientOn.client.id];
        //         if (clientQuota) {
        //             setInputValueNewQuota(applyMaskAmount(clientQuota))
        //         }
        //     }
        // }
    }

    async function loadLocalQuotas(fundId, month, year) {

        console.log("LOAD LOCAL QUOTAS: ", fundId, month, year);
        let responseLocalQuotas = await doGetAllLocalQuotasByFundIdMonthAndYear(fundId, month, year);

        if (responseLocalQuotas.success && responseLocalQuotas.body && responseLocalQuotas.body.length > 0) {

            const formatted = JSON.parse(responseLocalQuotas.body[0].json_quotas);
            console.log("CURRENT QUOTAS FORMATTED: ", formatted);
            setFundLocalQuotas(formatted);

            return formatted;

        }

        return null;


    }

    async function saveNewQuota(fundId, finalToSave, month, year) {

        //modelo requsitado pelo método doSaveOrUpdateLocalQuotas        

        const date = new Date(year, month - 1, 1);

        console.log("DATE QUOTAS: ", date);

        let response = await doSaveOrUpdateLocalQuotas(fundId,
            finalToSave,
            date,
            month,
            year,
            true)

        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: 'Cotas locais atualizadas com sucesso',
                type: 'success'
            }))


        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: response.body.message ? response.body.message : 'Falha ao atualizar cotas locais',
                type: 'error'
            }))
        }

    }

    function formatJsonQuotasToSave(newQuota, jsonQuotas) {

        console.log("New cota: ", newQuota);
        console.log("json: ", jsonQuotas);

        //verificar se já existe cota salva para essa data
        let alreadyQuota = false;
        //saber se a cota já foi adicionada
        let alreadyAdded = false;

        let newJsonQuotas = [];
        jsonQuotas.forEach(quota => {

            let dayQuota = quota.data_referencia.split('-')[2];
            let dayNewQuota = newQuota.data_referencia.split('-')[2];

            if (dayQuota != dayNewQuota && !alreadyAdded) {

                if (dayNewQuota < dayQuota) {
                    alreadyAdded = true;
                    newJsonQuotas.push(newQuota);
                }

            }

            if (quota.data_referencia == newQuota.data_referencia) {
                //existe cota
                alreadyQuota = true;

            }

            newJsonQuotas.push(quota);
        });



        //Caso entre aqui é porque a quota é em um dia depois do último dia que já existia localmente
        //Adiciona-se ao fim da lista para que seja salva
        if (!alreadyAdded) {
            newJsonQuotas.push(newQuota);
        }

        console.log("newJsonQuotas: ", newJsonQuotas);
        return newJsonQuotas;

    }

    async function doSaveNewSpecialClientQuota() {
        setLoader(true);
        setValidateMessage("");
        setSubmittedEditQuota(true);

        if (inputValueNewQuota != ""
            && inputDateNewQuota != null
            && moment.utc(inputDateNewQuota).isValid()) {

            const momentDate = moment.utc(inputDateNewQuota);

            if (momentDate.month() + 1 == month
                && momentDate.year() == year) {

                const date = inputDateNewQuota;
                const value = inputValueNewQuota;

                let formattedDate = moment.utc(date).format('DD/MM/YYYY');

                if (value != null && value != "" && fundLocalQuotas) {

                    const anbimaDate = formatPtStringDateToAnbimaDate(formattedDate);

                    const matchedQuota = fundLocalQuotas.find(el => el.data_referencia == anbimaDate);
                    console.log('matchedQuota: ', matchedQuota);

                    if (matchedQuota) {

                        matchedQuota['valor_cota_' + clientOn.client.id] = parseFloatByPtCurrency(value);
                        matchedQuota.usuario_id = currentUser.user.id;

                        console.log("JSON TO SAVE: ", fundLocalQuotas);
                        if (fundLocalQuotas) {

                            //Salvar json
                            await saveNewQuota(asset.fund_id, fundLocalQuotas, month, year);
                        }

                    } else {

                        console.log("CRIAR NOVA COTA");
                        const preJsonNewQuotaToSave = {
                            data_referencia: anbimaDate,
                            valor_cota: 1,
                            patrimonio_liquido: 0,
                            usuario_id: currentUser.user.id,
                        }
                        preJsonNewQuotaToSave['valor_cota_' + clientOn.client.id] = parseFloatByPtCurrency(value);

                        if (fundLocalQuotas.length == 0) {

                            await saveNewQuota(asset.fund_id, [preJsonNewQuotaToSave], month, year);

                        } else {
                            //adicionar
                            const formattedJsonQuotas = formatJsonQuotasToSave(preJsonNewQuotaToSave, fundLocalQuotas)
                            await saveNewQuota(asset.fund_id, formattedJsonQuotas, month, year);

                        }


                    }

                }
            } else {
                setValidateMessage("Deve ser selecionada alguma data do mês [ " + getMonthName(month) + '/' + year + " ]");
            }

        }

        setLoader(false);
        handleAction(false);
        dispatch(allActions.rightContentActions.update())

    }

    async function loadLastDayQuota(preLoadQuotaInMonth) {

        const responseLocalQuotas = await loadLocalQuotas(asset.fund_id, month, year);
        if (responseLocalQuotas) {
            if (!preLoadQuotaInMonth) {
                const responseLastDay = await getLastDayQuotaInMonth(clientOn.client.selectedMonth, clientOn.client.selectedYear);
                if (responseLastDay.success && responseLastDay.body) {
                    const ptDate = formatAnbimaDateToPt(responseLastDay.body);
                    const finalDate = stringPtDateToMoment(ptDate);
                    setInputDateNewQuota(finalDate);
                    loadValueQuotaDate(responseLocalQuotas, finalDate)
                }
            } else {
                const ptDate = formatAnbimaDateToPt(preLoadQuotaInMonth);
                const finalDate = stringPtDateToMoment(ptDate);
                setInputDateNewQuota(finalDate);
                loadValueQuotaDate(responseLocalQuotas, finalDate)
            }

        } else {
            handleAction(false);
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Falha ao carregar cotas do fundo"
            }));

        }



        setLoader(false);
    }

    useEffect(() => {

        if (open) {

            setLoader(true);
            loadLastDayQuota(lastDayQuotaInMonth);
        }

        // console.log("Months: ", months);
        // console.log("Years: ", years);


    }, [open])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => handleAction(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                <AddIcon style={{ color: 'blue', marginRight: '8px' }} />{"Cota especial " + " [ " + getMonthName(month) + '/' + year + " ]"}
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {
                    loader ?
                        <div className={classes.progress}>
                            <CircularProgress
                                disableShrink color="inherit" />
                            <Typography variant={"h6"}>
                                {"Carregando..."}
                            </Typography>
                        </div>
                        :
                        <>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={4} sm={4}>
                                    <MyAutoComplete
                                        id={'newSpecialClientQuota'}
                                        options={[clientOn.client]}
                                        autoComplete
                                        customPadding={'6px 16px'}
                                        customHeight={'25px'}
                                        value={
                                            clientOn.client
                                        }
                                        disabled={true}
                                        outerlabel={'Cliente'}
                                        getOptionLabel={(option) => option.label_name}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(option) => (
                                            <React.Fragment key={'autocomplete-new-special-quota-' + option.id}>
                                                <span>{option.label_name}</span>
                                            </React.Fragment>
                                        )}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <MyDatePicker
                                        className={classes.myDatePicker}
                                        margin="dense"
                                        outerlabel="Data*"
                                        format="DD/MM/YYYY"
                                        value={inputDateNewQuota}
                                        onChange={(value) => {
                                            setInputDateNewQuota(value)
                                            //loadValueQuotaDate(fundLocalQuotas, value);
                                        }}
                                        validator={inputDateNewQuota == "" || inputDateNewQuota == null ||
                                            (inputDateNewQuota != null && !moment.utc(inputDateNewQuota).isValid()) && submmitedEditQuota ? true : undefined}
                                        helperText={"Insira uma data válida"} />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <MyTextField
                                        value={inputValueNewQuota}
                                        onChange={(value) => setInputValueNewQuota(value)}
                                        variant="outlined"
                                        margin="dense"
                                        align="right"
                                        outerlabel={'Valor da cota*'}
                                        placeholder="Ex: 2,345343"
                                        validator={inputValueNewQuota == "" && submmitedEditQuota ? true : undefined}
                                        helperText={"Insira um valor da cota"}
                                        type="text"
                                        fullWidth />
                                </Grid>
                            </Grid>
                        </>
                }



                {
                    validateMessage != "" ?
                        <Typography variant={"body2"} style={{ color: 'red', marginTop: '16px' }}>
                            {validateMessage}
                        </Typography>
                        :
                        null
                }


            </DialogContent>

            <DialogActions className={classes.actionsFlex}>

                <UnoButton
                    disabled={loader}
                    onClick={() =>
                        handleAction(false)} color="secondary">
                    {'Cancelar'}
                </UnoButton>

                <UnoButton
                    disabled={loader || (inputDateNewQuota == null)}
                    className={classes.spaceRight2}
                    onClick={() => doSaveNewSpecialClientQuota()}
                    color="primary">
                    {"Salvar cota"}
                </UnoButton>

            </DialogActions>

        </Dialog>
    );
}