import { makeStyles } from '@material-ui/core';
import React, { useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { applyMaskAmount } from '../../../../utils/utils.js';

const optionsChart = {
  legend: {
    display: false,
  },

  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return " " + data.labels[tooltipItem.index] + ": " + applyMaskAmount(data.datasets[0].data[tooltipItem.index], true);
      }
    }
  },
};

const useStyles = makeStyles({
  divChart: {
    width: '40%',
    maxWidth: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

export default function MyDistributionChart({ dataToChart }) {
  const chartRefToReport = useRef(null);
  const classes = useStyles();

  return (
    <div className={classes.divChart}>
      <Pie data={dataToChart}
        options={optionsChart}
        redraw={true}
        ref={chartRefToReport} />
    </div>
  )
}