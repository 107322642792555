import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import allActions from '../../actions'
import Typography from '@material-ui/core/Typography';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

/**
 * Exibir full load
 * @param {String} show 
 * @param {String} message 
 * @returns {Object} FullLoad
 */
export default function MyFullLoad({ fullLoad }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false
        }))
    };

    useEffect(() => {

    }, [fullLoad])

    return (
        // <Snackbar open={alert && alert.show ? alert.show : false} autoHideDuration={5000} onClose={handleClose}>
        <Backdrop className={classes.backdrop} open={fullLoad.show}>
            {/* onClick={handleClose} */}
            <div>
                <CircularProgress disableShrink color="inherit" />
                {
                    <Typography variant={"h6"}>
                        {fullLoad.message}
                    </Typography>
                }
            </div>


        </Backdrop>
    );
}