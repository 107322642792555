import { Box, Fab, makeStyles } from '@material-ui/core';
import { SpeedDialAction } from '@material-ui/lab';
import SpeedDial from '@material-ui/lab/SpeedDial';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

/**
 * 
 * @param {import('react').PropsWithChildren<{
 *  speedDial: {
 *      actions: (import('@material-ui/lab').SpeedDialActionProps & Record<'key', string>)[];
 *      props: import('@material-ui/lab').SpeedDialProps
 * }
 *  fab: {
 *  Wrapper: JSX.Element;
 *  props: import('@material-ui/core').FabProps;
 *  icon: React.ReactNode;
 * }
 * }>} props 
 * @returns 
 */
export default function PageWrapper(props) {
    const classes = useStyles();
    const { children, speedDial, fab, ...other } = props;

    return <Box className={classes.root}>
        <Box class={'defaultScrollWithHeaderContent'}>
            {children}
            {
                speedDial && !fab && <SpeedDial
                    {...speedDial.props}>
                    {speedDial.actions.map((action) => (
                        <SpeedDialAction
                            {...action}
                        />
                    ))}
                </SpeedDial>
            }
            {
                fab && !speedDial ?
                    fab.Wrapper ?
                        <fab.Wrapper>
                            <Fab {...fab.props}>
                                {fab.icon}
                            </Fab>
                        </fab.Wrapper>
                        :
                        <Fab {...fab.props}>
                            {fab.icon}
                        </Fab>
                    :
                    null
            }
        </Box>
    </Box>;
}