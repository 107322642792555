import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//Text field 
import MyTextField from '../../utils/MyTextField'
import { getConsultingId, isValidCpf, isValidEmail, justNumbers } from '../../utils/utils'

//UnoButton
import UnoButton from '../../utils/UnoButton'

//Grid
import { Divider, Grid, IconButton } from "@material-ui/core";

//API
import { create, updateUser, deleteUser, getAdvisorClients, addClientToAdvisor, removeClientToAdvisor } from '../../../API/user'

//Redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import { unoTheme, headerSearch, tabs } from "../../../assets/styles/unoTheme";

import ConfirmActionModal from "../../modals/ConfirmActionModal";

import _ from 'lodash';

//tabs
import { MyTabs, MyTab } from "../../utils/MyTabs";

//my table
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTooltip from "../../utils/MyHelpers/MyTooltip";
import { getColorSuitability } from "../../../controllers/ClientController";

import moment from "moment";

import AddIcon from '@material-ui/icons/Add';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import MyAutoComplete from "../../utils/MyAutoComplete";
import SituationCell from "../../utils/SituationCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { checkUserIsRole, roles } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    },

    main: {
        width: '100%',
        padding: theme.spacing(2),
    },

    headerSearch: headerSearch,

    labelHeader: {
        fontSize: '16px',
        color: unoTheme.defaultTextColor,
        fontWeight: 600,
    },
    switchs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    },
    buttonsHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
}));

export default function NewAdvisorScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const activeClients = useSelector(state => state.activeClients)
    const currentUser = useSelector(state => state.currentUser)
    const innerLoad = useSelector(state => state.innerLoad)

    const location = useLocation();
    const history = useHistory();

    const [selectedOrderByPostfolioClosed, setSelectedOrderByPostfolioClosed] = useState(null);
    const [iconOrder, setIconOrder] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const handleChangeClient = (event, newValue) => {
        setSelectedClient(newValue)
    };

    //Confirm action modal    
    const [openConfirmActionModal, setOpenConfirmActionModal] = useState(false);

    //form new client
    const [submitted, setSubmitted] = useState(false);

    const [advisor, setAdvisor] = useState(undefined)
    const [initAdvisor, setInitAdvisor] = useState(undefined);

    const [passwordReseted, setPasswordReseted] = useState(false);
    function handlePasswordReseted() {
        console.log("Password reseted");
        setPasswordReseted(true)
    }

    const [inputNameAdvisor, setInputNameAdvisor] = useState("");
    function changeNameAdvisor(value) {
        setInputNameAdvisor(value)
    }

    const [inputEmailAdvisor, setInputEmailAdvisor] = useState("");
    function changeEmailAdvisor(value) {
        setInputEmailAdvisor(value)
    }

    const [inputCpfAdvisor, setInputCpfAdvisor] = useState("");
    function changeCpfAdvisor(value) {
        setInputCpfAdvisor(value)
    }

    const [inputPhoneAdvisor, setInputPhoneAdvisor] = useState("");
    function changePhoneAdvisor(value) {
        setInputPhoneAdvisor(value)
    }

    const [inputPasswordAdvisor, setInputPasswordAdvisor] = useState("");
    function changePasswordAdvisor(value) {
        setInputPasswordAdvisor(value)
    }

    const [inputRoleAdvisor, setInputRoleAdvisor] = useState(null);

    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

    };

    const [advisorClients, setAdvisorClients] = useState(null);

    async function doSaveAdvisor(event) {

        event.preventDefault();

        setSubmitted(true);

        let user = {
            cpf: inputCpfAdvisor,
            password: inputPasswordAdvisor,
            name: inputNameAdvisor,
            email: inputEmailAdvisor,
            phone_number: justNumbers(inputPhoneAdvisor),
            role: 2,
            consulting_id: getConsultingId(currentUser)
        }

        //validação prévia
        if (isValidCpf(justNumbers(user.cpf))
            && user.password != "" && user.password.length >= 6
            && user.name != ""
            && isValidEmail(user.email)) {

            setSubmitted(false);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando consultor..."
            }))

            let response = await create(user);

            if (response.success) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Consultor cadastrado com sucesso"

                }));

                clearForm();

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                history.goBack();

            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Erro ao cadastrar o consultor: " + response.body.message

                }));
            }
        }


    }

    async function doUpdateAdvisor() {

        if (!inputRoleAdvisor) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Permissão do usuário inválida"

            }));
        } else {
            setSubmitted(true);

            console.log("inputRoleAdvisor: ", inputRoleAdvisor)

            let newAdvisor = {
                cpf: inputCpfAdvisor,
                password: passwordReseted ? inputPasswordAdvisor : null,
                name: inputNameAdvisor,
                email: inputEmailAdvisor,
                phone_number: justNumbers(inputPhoneAdvisor),
                role: inputRoleAdvisor,
                consulting_id: getConsultingId(currentUser)
            }

            //validação prévia
            if (isValidCpf(newAdvisor.cpf)
                &&
                (
                    (passwordReseted && newAdvisor.password != "" && newAdvisor.password.length >= 6)
                    ||
                    (!passwordReseted)
                )
                && newAdvisor.name != ""
                && isValidEmail(newAdvisor.email)) {

                setSubmitted(false);

                let passwordInputed = initAdvisor.password;
                if (!passwordReseted) {
                    initAdvisor.password = null;
                }

                if (!_.isEqual(initAdvisor, newAdvisor)) {

                    console.log("INIT ADVISOR: ", initAdvisor)
                    console.log("new advisor: ", newAdvisor)

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: true,
                        message: "Atualizando consultor..."
                    }))

                    let response = await updateUser(advisor.id, newAdvisor);

                    if (response.success) {
                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'success',
                            message: "Consultor atualizado com sucesso"

                        }));

                        //console.log("Response advisor: ", response.body);
                        setInitAdvisor({
                            cpf: justNumbers(response.body.cpf),
                            password: response.body.password,
                            name: response.body.name,
                            email: response.body.email,
                            phone_number: justNumbers(response.body.phone_number),
                            role: 2,
                        })

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        history.goBack();

                    } else {

                        dispatch(allActions.fullLoadActions.setFullLoad({
                            show: false,
                        }))

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'error',
                            message: "Erro ao atualizar o consultor: " + response.body.message

                        }));
                    }
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'warning',
                        message: "Nenhuma alteração foi realizada"

                    }));
                }

                initAdvisor.password = passwordInputed;
            }
        }




    }

    function clearForm() {

        setInputNameAdvisor("");
        setInputEmailAdvisor("");
        setInputCpfAdvisor("");
        setInputPhoneAdvisor("");
        setInputPasswordAdvisor("");
    }

    function initForm(advisor) {

        console.log("ADVISOR: ", advisor)
        setInputNameAdvisor(advisor.name);
        setInputEmailAdvisor(advisor.email);
        setInputCpfAdvisor(advisor.cpf);
        setInputPhoneAdvisor(advisor.phone_number);
        setInputRoleAdvisor(advisor.role_id)
        //setInputPasswordAdvisor(advisor.password);

        setInitAdvisor({
            cpf: advisor.cpf,
            //password: advisor.password,
            name: advisor.name,
            email: advisor.email,
            phone_number: justNumbers(advisor.phone_number),
            role: advisor.role_id ? advisor.role_id : 2,
        })
    }

    async function doDeleteAdvisor(advisor) {


        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Excluindo consultor..."
        }))

        //content contem data que contem a conta que foi aberta no componente lateral
        const response = await deleteUser(advisor.id);

        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'Consultor excluído com sucesso!'
            }))

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir Consultor: " + response.body.message
            }))
        }

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))

        history.goBack();



    }

    function handleAction(action) {

        setOpenConfirmActionModal(false);
        if (action) {
            doDeleteAdvisor(advisor);
        }
    }

    async function doGetAdvisorClients(orderBy) {

        const response = await getAdvisorClients(advisor.id, orderBy);
        console.log("response getAdvisorClients: ", response);
        if (response.success) {
            setAdvisorClients(response.body.rows);
        }

    }

    async function doAddClientToAdvisor() {

        if (selectedClient != null && selectedClient.id) {
            console.log('selectedClient: ', selectedClient);
            console.log('advisor: ', advisor);
            const response = await addClientToAdvisor(selectedClient.id, advisor.id);
            console.log("response addClientToAdvisor: ", response);
            if (response.success) {
                doGetAdvisorClients(selectedOrderByPostfolioClosed);
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Cliente adicionado com sucesso!"
                }))
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: response.body.message
                }))
            }
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Selecione um cliente"
            }))
        }


    }

    async function doRemoveClientToAdvisor(advisorClientId) {

        const response = await removeClientToAdvisor(advisorClientId);
        if (response.success) {
            doGetAdvisorClients(selectedOrderByPostfolioClosed);
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Cliente removido com sucesso!"
            }))
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao remover cliente!'
            }))
        }

    }

    function orderByPortfolioClosed(orderBy) {

        // if (search === '' || search === undefined) {
        let style = { verticalAlign: 'bottom' };
        if (orderBy === 'DESC' || orderBy === null) {
            orderBy = 'ASC';
            setIconOrder(<ArrowDropUp style={style} />);
        } else {
            orderBy = 'DESC';
            setIconOrder(<ArrowDropDown style={style} />);
        }

        setSelectedOrderByPostfolioClosed(orderBy);

        doGetAdvisorClients(orderBy);
        // }


    }

    function RowClient(props) {
        const { row, updateStatusClient, goToDetailClient } = props;
        const [open, setOpen] = React.useState(false);

        return (

            <TableRow
                key={'table-row-client-advisor' + row.id}>
                <TableCell align="center" >{row.id}</TableCell>
                <TableCell align="left" component="th" scope="row">{

                    row.label_name
                }</TableCell>

                <TableCell align="center" style={{ color: getColorSuitability(row.suitability) }}>{row.suitability ? row.suitability : '-'}</TableCell>

                <TableCell align="center">{moment.utc(row.portfolio_init).format('MM/YYYY')}</TableCell>
                <TableCell align="center">{row.portfolio_closed ? row.portfolio_closed : '-'}</TableCell>
                <TableCell align="center">

                    {
                        row.active ?
                            <CheckCircleOutlineRoundedIcon className={classes.green} />
                            :
                            <HighlightOffRoundedIcon className={classes.red} />
                    }

                </TableCell>
                <TableCell align="center">
                    <IconButton
                        size={'small'}
                        className={classes.iconButton}
                        onClick={(e) => doRemoveClientToAdvisor(row.advisor_client_id)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

        );
    }

    useEffect(() => {

        if (!advisor) {
            if (location && location.state) {
                setAdvisor(location.state.advisor)
            }
        } else {
            initForm(advisor);
        }

    }, [advisor]);

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={[classes.tabs,
                    //classes.shortWidthTab
                ].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label={'Consultor'} />
                        <MyTab label={"Clientes"} onClick={() => doGetAdvisorClients(selectedOrderByPostfolioClosed)} />


                    </MyTabs>


                </div>

                {
                    tabValue === 0 ?
                        <div className={classes.rightHeader}>

                            <div className={classes.headerSearch}>

                                {
                                    checkUserIsRole(currentUser, [roles.admin, roles.superadmin])
                                        ? //pois pode ser admin
                                        <>

                                            {
                                                !location
                                                    || (location && !location.state)
                                                    || (location && location.state && !location.state.advisor) ?
                                                    <UnoButton
                                                        float={'right'}
                                                        variant="contained"
                                                        type="primary"
                                                        onClick={(e) => doSaveAdvisor(e)}>
                                                        {"Salvar consultor"}
                                                    </UnoButton>
                                                    :

                                                    <div className={classes.buttonsHeader}>
                                                        <UnoButton
                                                            margin={'mRight'}
                                                            float={'right'}
                                                            variant="contained"
                                                            type="error"
                                                            onClick={() => setOpenConfirmActionModal(true)}>
                                                            {"Excluir consultor"}
                                                        </UnoButton>

                                                        <UnoButton
                                                            float={'right'}
                                                            variant="contained"
                                                            type="success"
                                                            onClick={(e) => doUpdateAdvisor(e)}>
                                                            {"Atualizar consultor"}
                                                        </UnoButton>
                                                    </div>

                                            }
                                        </> : null

                                }

                            </div>

                        </div>
                        :
                        <div className={classes.rightHeader}>

                            <div className={classes.headerSearch}>

                                {
                                    location && location.state && location.state.advisor ?

                                        <div className={classes.buttonsHeader}>
                                            {
                                                activeClients && activeClients.length > 1 ?

                                                    <MyAutoComplete
                                                        disabled={innerLoad.show && innerLoad.loadMessage != "" && innerLoad.loadMessage != null}
                                                        options={activeClients}
                                                        autoComplete
                                                        customWidth={'320px'}
                                                        className={classes.spacingLeft2}
                                                        value={
                                                            selectedClient ? selectedClient : null
                                                        }
                                                        onChange={(event, newValue) => {
                                                            console.log("New value: ", newValue)
                                                            handleChangeClient(event, newValue)
                                                        }}
                                                        placeholder={"Selecione o cliente"}
                                                        getOptionLabel={(option) => option.label_name}
                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                        renderOption={(option) => (
                                                            <React.Fragment key={'autocomplete-' + option.id}>
                                                                <span>{option.label_name}</span>
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                    :
                                                    <>
                                                        {
                                                            activeClients && activeClients.length == 1 ?
                                                                <Typography className={classes.spacingLeft2}>{
                                                                    activeClients[0].city_name
                                                                    +
                                                                    " - "
                                                                    +
                                                                    activeClients[0].uf
                                                                }</Typography> : null
                                                        }

                                                    </>

                                            }


                                            <UnoButton
                                                style={{ marginLeft: '16px' }}
                                                float={'right'}
                                                variant="contained"
                                                type="success"
                                                startIcon={<AddIcon />}
                                                onClick={(e) => doAddClientToAdvisor()}>
                                                {"Adicionar cliente"}
                                            </UnoButton>
                                        </div>
                                        : null
                                }

                            </div>

                        </div>
                }



            </div>

            <div class='defaultScrollWithHeaderContent'>
                {
                    tabValue == 0 ?
                        <div className={classes.main}>
                            <form noValidate autoComplete="off">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={7} md={7}>
                                        <MyTextField required
                                            id="advisor_name"
                                            outerlabel="Nome"
                                            size={'small'}
                                            validator={inputNameAdvisor == "" && submitted ? true : undefined}
                                            variant="outlined"
                                            onChange={changeNameAdvisor}
                                            helperText={"Insira um nome"}
                                            placeholder={'Insira o nome'}
                                            value={inputNameAdvisor} />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5}>
                                        <MyTextField
                                            id="advisor_email"
                                            value={inputEmailAdvisor}
                                            variant="outlined"
                                            size={'small'}
                                            onChange={changeEmailAdvisor}
                                            validator={!isValidEmail(inputEmailAdvisor) && submitted ? true : undefined}
                                            helperText={"Insira um email válido"}
                                            placeholder={'Insira o email'}
                                            outerlabel="Email" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <MyTextField
                                            id="advisor_cpf"
                                            value={inputCpfAdvisor}
                                            onChange={changeCpfAdvisor}
                                            size={'small'}
                                            variant="outlined"
                                            validator={!isValidCpf(inputCpfAdvisor) && submitted ? true : undefined}
                                            helperText={"Insira um CPF válido"}
                                            mask={['cpf']}
                                            placeholder={'Insira o CPF'}
                                            outerlabel="CPF" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <MyTextField
                                            id="advisor_phone"
                                            value={inputPhoneAdvisor}
                                            size={'small'}
                                            onChange={changePhoneAdvisor}
                                            mask={['phone']}
                                            variant="outlined"
                                            placeholder={'Insira o telefone'}
                                            outerlabel="Telefone" />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={4} md={4}>
                                <MyTextField
                                    id="advisor_password"
                                    value={inputPasswordAdvisor}
                                    onChange={changePasswordAdvisor}
                                    size={'small'}
                                    variant="outlined"
                                    validator={inputPasswordAdvisor == ""
                                        && inputPasswordAdvisor.length < 6
                                        && submitted ? true : undefined}
                                    helperText={"Insira uma senha de 6 dígitos"}
                                    outerlabel="Senha"
                                />
                            </Grid> */}


                                    <Grid item xs={12} sm={4} md={4}>

                                        <MyTextField
                                            className={!passwordReseted && location && location.state && location.state.advisor ? classes.selectable : ''}
                                            outerlabel="Senha"
                                            id="advisor_password"
                                            value={inputPasswordAdvisor}
                                            onChange={changePasswordAdvisor}
                                            size={'small'}
                                            disabled={!passwordReseted
                                                && location && location.state && location.state.advisor
                                                && !checkUserIsRole(currentUser, [roles.admin, roles.superadmin])
                                            }
                                            variant="outlined"
                                            onClick={() => handlePasswordReseted()}
                                            placeholder={!passwordReseted && location && location.state && location.state.advisor ? "Clique para nova senha" : "Insira a senha"}
                                            validator={inputPasswordAdvisor.length < 6
                                                && submitted ? true : undefined}
                                            helperText={"Insira uma senha de 6 dígitos"}
                                        />

                                    </Grid>

                                </Grid>
                            </form>
                        </div>
                        :
                        <div className={classes.main}>
                            <MyTable
                                //pagination={isEmpty(search) && clients && clients.length > 0}
                                //itemspage={LIMIT}
                                //totalitems={count}
                                //handlePage={handleChange}
                                //onScroll={(value) => setAppearFab(value)}
                                stickyHeader
                                size="small"
                                selectable='true'>
                                <colgroup>
                                    <col width="5%" />
                                    <col width="30%" />
                                    {/* <col width="7%" /> */}
                                    <col width="20%" />
                                    <col width="15%" />
                                    <col width="15%" />
                                    <col width="8%" />
                                    <col width="7%" />
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ID</TableCell>
                                        <TableCell align="left">RPPS</TableCell>
                                        {/* <TableCell align="center">UF</TableCell> */}
                                        {/* <TableCell align="left">CNPJ</TableCell> */}
                                        <TableCell align="center">SUITABILITY</TableCell>
                                        <TableCell align="center">Início carteira</TableCell>
                                        <TableCell align="center" onClick={() => orderByPortfolioClosed(selectedOrderByPostfolioClosed)}>
                                            Fim carteira {iconOrder}
                                        </TableCell>
                                        <TableCell align="center">
                                            {/* Situação */}
                                            <MyTooltip
                                                className={classes.myTooltip}
                                                iconSize='small'
                                                tooltipTitle='Indica se o cliente está ativo ou não.'
                                            />
                                        </TableCell>
                                        <TableCell align="center">

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {advisorClients && advisorClients.map((row) => (
                                        <RowClient
                                            key={'row-client-advisor-' + row.id}
                                            row={row}
                                        // goToDetailClient={(row) => history.push(
                                        //     {
                                        //         pathname: path.administration.client.details.newClient,
                                        //         state: { client: row }
                                        //     }
                                        // )}
                                        // updateStatusClient={(e, id, active) => updateStatusClient(e, id, active)} 
                                        />
                                    ))}
                                </TableBody>
                            </MyTable>
                        </div>
                }

            </div>

            <ConfirmActionModal
                open={openConfirmActionModal}
                title={'Excluir consultor'}
                message={'Tem certeza de que deseja excluir esse consultor?'}
                handleAction={handleAction}>
            </ConfirmActionModal>

        </div >
    );
}
