import React, { useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        //flexDirection: 'column',
        height: '42px',
        '& .MuiInputBase-root': {
            lineHeight: '24px !important',
        },
        '& .MuiNativeSelect-root': {
            height: '30px',
        }
    },
    disabled: {
        background: '#eeeeee'
    },
    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    marginTop: {
        marginTop: '5px'
    }


}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            marginRight: '8px',
            color: unoTheme.mainColor,
        },
    },

    input: {
        borderRadius: 5,
        position: 'relative',
        backgroundColor: 'white',
        border: 'solid 1px #E4E7EB',
        fontSize: 16,
        borderRadius: '10px',
        padding: '6px 42px 6px 16px !important',
        //transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },

}))(InputBase);

const BootstrapVerySmallInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        //width: '70px',
        borderRadius: 6,
        position: 'relative',
        backgroundColor: 'white',
        border: 'solid 1px #E4E7EB',
        fontSize: 12,
        padding: '0px 26px 0px 12px',
        lineHeight: '18px',
        height: '26.250px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function MySelect2(props) {

    const {
        outerlabel,
        options,
        verysmall,
    } = props

    const classes = useStyles();

    return (
        <div className={classes.root}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={classes.label}>
                        {outerlabel}
                    </Typography> : null
            }

            <NativeSelect
                className={classes.marginTop}
                {...props}
                input={verysmall ? <BootstrapVerySmallInput /> : <BootstrapInput />}>
                {options}
            </NativeSelect>

        </div>


    )
}