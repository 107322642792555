import moment from "moment";
import { getFundByCnpj } from "../API/fund";
import allActions from "../actions";
import { isDateAfterClosedMonth, justNumbers } from "../components/utils/utils";
import { doGetAccountIdByNumberAccountAndCnpjAsset } from "./AccountController";
import { checkAlreadyImportedMonth } from "../API/transaction";

export const SystemValidationsController = {

    async doPreInsertsTransactions(
        transactions,
        invalidColumns,
        invalidDates,
        clientAssets,
        clientAccounts,
        clientOn,
        isFromPdfs,
        dispatch) {

        console.log("DO PRE INSERTS: ")
        function verifyColumnsExcel(transactionLoop) {

            console.log("TESTE DE COLUNAS: ", transactionLoop);
            let invalidColumns = [];
            if (!transactionLoop['CC']) {
                invalidColumns.push('CC');
            }
            if (!transactionLoop['CNPJ']) {
                invalidColumns.push('CNPJ');
            }
            if (!transactionLoop['DATA']) {
                invalidColumns.push('DATA');
            }
            if (!transactionLoop['TIPO']) {
                invalidColumns.push('TIPO');
            }
            if (!transactionLoop['VALOR']) {
                invalidColumns.push('VALOR');
            }
            //Removido devido o caso em que existem resgates automáticos
            //A quantidade de cotas não é informada
            //Caso encontrado no cliente João Pessoa

            // if (!transactionLoop['QUANTIDADE']) {
            //     invalidColumns.push('QUANTIDADE');
            // }

            return invalidColumns;
        }
        async function getAccountIdByAccountNumberAndFundCnpj(accountNumber, cnpj) {

            //console.log("PROCURANDO ACC: ", accountNumber)
            //let accounts = clientAccounts.filter(el => el.number_account == accountNumber && el.cod == instituitionCod);
            const accounts = clientAccounts.filter(el => el.number_account == accountNumber);

            if (accounts.length == 1) {

                return accounts[0].id

            } else if (accounts.length > 1) {

                const accountAsset = await doGetAccountIdByNumberAccountAndCnpjAsset(accountNumber, cnpj);
                return accountAsset;

            } else {

                return "NOT_FOUND";

            }

        }
        //Faz a verificação da data formatada da linha da tabela do excel
        function verifyingDate(dateToVerify, monthsToInsert, yearsToInsert, initYear, initMonth, invalidMonths) {
            //Guardando o mês pra saber se é uma planilha anual ou mensal
            let testMonth = dateToVerify.substring(3, 5);
            //Teste para saber se existe algum erro na data de transação,
            //algum registro apontando pra alguma data de anos diferentes
            let testYear = dateToVerify.substring(6);

            //Verificando se existem movimeentações para antes do início da carteira                    
            if (initYear == testYear && parseInt(testMonth) <= initMonth) {

                if (!invalidMonths.includes(parseInt(testMonth))) {
                    invalidMonths.push(parseInt(testMonth));
                }

            }

            if (!monthsToInsert.includes(testMonth)) {

                monthsToInsert.push(testMonth);

            }

            console.log("YEAR TEST: ", testYear)
            if (!yearsToInsert.includes(testYear)) {
                yearsToInsert.push(testYear)
            }
        }

        function checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, cnpj, accountId) {

            // console.log("CNPJ: ", cnpj)
            // console.log("ACCOUNT: ", accountId)
            // console.log("TO COMPARE: ", listLocalClientAssets);
            let assets = listLocalClientAssets.filter(el => el.cnpj == cnpj && el.account_id == accountId)
            if (assets && assets.length == 1) {
                //console.log("ASSET JA EXISTENTE OK!")
                return assets[0].id;
            } else {
                return null;
            }
        }

        let initYear = moment.utc(clientOn.client.portfolio_init).format('YYYY');
        let initMonth = parseInt(moment.utc(clientOn.client.portfolio_init).format('MM'));
        //Obtendo mês da carteira fechada para fazer validação de segurança impedindo que sejam importadas movimentações
        //de meses que já foram fechados e validados
        //Ex clientOn.client.portfolio_closed: 8/2021
        let monthClosed = clientOn.client.portfolio_closed == null ? null : parseInt(clientOn.client.portfolio_closed.split('/')[0]);
        let yearClosed = clientOn.client.portfolio_closed == null ? null : parseInt(clientOn.client.portfolio_closed.split('/')[1]);
        const portfolioClosed = clientOn.client.portfolio_closed;

        let listLocalClientAssets = clientAssets ? clientAssets : [];
        let invalidsAccounts = [];
        let accountsWhite = 0;
        let invalidsFunds = [];
        let invalidMonths = [];
        let invalidNoCnpjs = 0; //linhas sem informação de cnpj

        let assetsToCreate = [];

        let mapFunds = [];

        let transactionToInsert = [];

        //Utilizado para verificar a validade da planilha, com movimentações apenas do mesmo ano
        let yearsToInsert = [];

        //Se houver apenas 1 mês inserido na lista indica que a planilha é uma planilha mensal
        let monthsToInsert = [];

        //Verificar inconsistência na sequência, caso hajam criações de ativos de mês anterior eles devem vir todos no topo da lista       

        for (let i = 0; i < transactions.length; i++) {

            let transactionLoop = transactions[i];

            //Normalizando CNPJ, retirando possíveis espaços
            if (transactionLoop['CNPJ'] != null) {

                transactionLoop['CNPJ'] = transactionLoop['CNPJ'].toString().trim();

            } else {

                invalidNoCnpjs++;

            }


            //Realizar a verificação das colunas da planilha
            if (i === 0) {

                //Caso retorne false uma ou mais colunas necessárias não foram informadas
                invalidColumns = verifyColumnsExcel(transactionLoop);

            }

            // let accountLoop = getAccountIdByAccountNumber(justNumbers(transactionLoop['CC']));
            //console.log("Account: ", transactionLoop['CC']);

            if (transactionLoop['CC']) {

                transactionLoop['CC'] = parseInt(justNumbers(transactionLoop['CC']))
                transactionLoop['CC'] = transactionLoop['CC'].toString().trim()
                //transactionLoop.account_id = getAccountIdByAccountNumber(transactionLoop['CC']);
                transactionLoop.account_id = await getAccountIdByAccountNumberAndFundCnpj(transactionLoop['CC'], transactionLoop['CNPJ']);

            } else {

                transactionLoop.account_id = 'NOT_FOUND';
                accountsWhite++;

            }
            if (transactionLoop.account_id == 'NOT_FOUND') {

                if (transactionLoop['CC']) {
                    if (!invalidsAccounts.includes(justNumbers(transactionLoop['CC']))) {
                        invalidsAccounts.push(justNumbers(transactionLoop['CC']));
                    }
                }

            } else {

                let fundId = undefined;

                if (mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']]) { //Fundo já mapeado

                    fundId = mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']];
                    //let assetId = checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, transactionLoop['CNPJ'], transactionLoop.account_id)
                    const transactionFormatted = {

                        //asset_id: assetId,
                        account_id: transactionLoop.account_id,
                        operation_id: transactionLoop['TIPO'],
                        amount: transactionLoop['VALOR'],
                        description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                        cnpj: transactionLoop['CNPJ'],
                        transaction_date: transactionLoop['dataFormatada'],
                        qtd_quotas: transactionLoop['QUANTIDADE_COTAS'],
                    };

                    //console.log("Transaction formatted: ", transactionFormatted);

                    verifyingDate(transactionLoop['dataFormatada'],
                        monthsToInsert,
                        yearsToInsert,
                        initYear,
                        initMonth,
                        invalidMonths);

                    //if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                    if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                        transactionToInsert.push(transactionFormatted);
                    }

                    //console.log("INCLUINDO " + i + ": ", transactionFormatted);

                } else {

                    console.log("Tentando incluir 1: ", transactionLoop);

                    let responseFundByCnpj = await getFundByCnpj(transactionLoop['CNPJ']);

                    let testMonth = transactionLoop['dataFormatada'].substring(3, 5);
                    //Teste para saber se existe algum erro na data de transação,
                    //algum registro apontando pra alguma data de anos diferentes
                    let testYear = transactionLoop['dataFormatada'].substring(6);

                    //Verificando se existem movimeentações para antes do início da carteira
                    if (initYear == testYear && parseInt(testMonth) <= initMonth) {

                        if (!invalidMonths.includes(parseInt(testMonth))) {
                            invalidMonths.push(parseInt(testMonth));
                        }

                    }

                    verifyingDate(transactionLoop['dataFormatada'],
                        monthsToInsert,
                        yearsToInsert,
                        initYear,
                        initMonth,
                        invalidMonths);

                    console.log("responseFundByCnpj: ", responseFundByCnpj);

                    //Existe o fundo cadastrado na base de dados
                    if (responseFundByCnpj.success && responseFundByCnpj.body.rows[0] != undefined) {

                        fundId = responseFundByCnpj.body.rows[0].id;

                        //Checando se o cliente já possui esse Ativo
                        // console.log("LOCAL ASSETS: ", listLocalClientAssets);
                        // console.log("transactionLoop['CNPJ']: ", transactionLoop['CNPJ']);
                        // console.log("transactionLoop.account_id: ", transactionLoop.account_id);
                        let assetId = checkIsClientAssetByCnpjAndAccount(listLocalClientAssets, transactionLoop['CNPJ'].toString().trim(), transactionLoop.account_id)

                        console.log("ASSSET ID: ", assetId);

                        // console.log("COMPARE: ");
                        // console.log(parseInt(transactionLoop['dataFormatada'].substring(6)))
                        // console.log(parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')))
                        // console.log("Result: ", parseInt(transactionLoop['dataFormatada'].substring(6))
                        //     > parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')));


                        if (!assetId &&
                            (assetsToCreate
                                .filter(el => el.fund_id == fundId && el.account_id == transactionLoop.account_id)).length == 0) {
                            //Checando se já não é um fundo a ser criado
                            //Caso não possua o ativo desse ser criado                        
                            const asset = {
                                account_id: transactionLoop.account_id,
                                number_account: transactionLoop['CC'],
                                fund_id: fundId,
                                balance: transactionLoop['VALOR'],
                                fundInfos: responseFundByCnpj.body.rows[0],
                                asset_init: transactionLoop['dataFormatada'],
                                quota_amount: transactionLoop['QUANTIDADE'],
                                transaction_date: transactionLoop['dataFormatada'],
                                qtd_quotas: transactionLoop['QUANTIDADE_COTAS'],
                            }

                            //console.log("ACCOUNT NO ASSET: ", asset.account_id)

                            //Se data de movimentação for uma data antes do início da carteira o
                            //asset é criado e não é considerado como movimentação
                            //senão entra no IF abaixo e é criado o asset mas ele também uma movimentação

                            if (parseInt(transactionLoop['dataFormatada'].substring(6)) //Compara o ano da linha do excel 
                                > parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY'))) { //Com o ano de inicio da carteira

                                console.log("COMPARANDO: " + "[" + parseInt(transactionLoop['dataFormatada'].substring(6)) + "]" +
                                    ">" + "[" + parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY')) + "]")

                                const transactionFormatted = {

                                    //asset_id: assetId,
                                    account_id: asset.account_id,
                                    operation_id: transactionLoop['TIPO'],
                                    amount: transactionLoop['VALOR'],
                                    description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                                    cnpj: transactionLoop['CNPJ'],
                                    transaction_date: transactionLoop['dataFormatada'],
                                    qtd_quotas: transactionLoop['QUANTIDADE_COTAS'],

                                };

                                //console.log("TRANSACTION TAMBEM ASSET: ", transactionFormatted)

                                //Teste para saber se existe algum erro na data de transação,
                                //algum registro apontando pra alguma data de anos diferentes
                                let testYear = transactionLoop['dataFormatada'].substring(6);
                                //console.log("YEAR TEST: ", testYear)
                                if (!yearsToInsert.includes(testYear)) {
                                    yearsToInsert.push(testYear)
                                }

                                // if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                                if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                                    transactionToInsert.push(transactionFormatted);
                                }

                                //console.log("INCLUINDO " + i + ": ", transactionFormatted);

                                //No caso em que o asset é uma movimentação o saldo de criação é 0
                                asset.balance = 0.0;
                                asset.isTransaction = true; //utilizado pra controlar a criação do balance_validation
                                //quando é uma movimentação cria-se apenas um blance_validation aberto para o mês da movimentação
                            }

                            //console.log("ASSET TO CREATE: ", asset);
                            assetsToCreate.push(asset);

                        } else if (parseInt(transactionLoop['dataFormatada'].substring(6)) //Compara o ano da linha do excel, o ano deve ser maior ou igual ao ano de início da carteira 
                            >= parseInt(initYear)) {
                            //> parseInt(moment.utc(clientOn.client.portfolio_init).format('YYYY'))) {

                            console.log("PASSANDO XXX: ", assetId);

                            const transactionFormatted = {
                                asset_id: assetId,
                                account_id: transactionLoop.account_id,
                                operation_id: transactionLoop['TIPO'],
                                amount: transactionLoop['VALOR'],
                                cnpj: transactionLoop['CNPJ'],
                                description: transactionLoop['DESCRICAO'] ? transactionLoop['DESCRICAO'].substring(0, 1000) : "",
                                transaction_date: transactionLoop['dataFormatada'],
                                qtd_quotas: transactionLoop['QUANTIDADE_COTAS'],
                            };

                            // if (verifyTransactionAfterPorfolioClosed(monthClosed, yearClosed, transactionFormatted.transaction_date)) {
                            if (isDateAfterClosedMonth(transactionFormatted.transaction_date, portfolioClosed)) {
                                transactionToInsert.push(transactionFormatted);
                            }

                        }

                        mapFunds[transactionLoop['CNPJ'] + "=" + transactionLoop['CC']] = fundId;

                    } else {

                        console.log("Tentando incluir 2: ", transactionLoop['CNPJ']);
                        console.log("ERROR: ", responseFundByCnpj.body)

                        let filtered = invalidsFunds.filter(el => el.cnpj == transactionLoop['CNPJ']);
                        if (filtered && filtered.length == 0) {
                            invalidsFunds.push({
                                fund_name: transactionLoop['FUNDO'],
                                cnpj: transactionLoop['CNPJ']
                            });
                        }

                    }

                }

            }


        }

        if (invalidColumns.length > 0
            || invalidsAccounts.length > 0
            || invalidsFunds.length > 0
            || accountsWhite > 0
            || yearsToInsert.length > 1
            || invalidMonths.length > 0
            || invalidDates.length > 0
            || invalidNoCnpjs > 0
        ) {
            console.log("TRANSACTIONS");
            console.log("Não será possível realizar as inserções")
            console.log("Colunas não encontradas: ", invalidColumns)
            console.log("Contas: ", invalidsAccounts)
            console.log("Contas em branco: ", accountsWhite)
            console.log("Fundos: ", invalidsFunds)
            console.log("Anos: ", yearsToInsert)
            console.log("invalidMonths: ", invalidMonths)
            console.log("invalidDates: ", invalidDates)
            console.log("invalidNoCnpjs: ", invalidNoCnpjs)
        }

        //checando se o ano já foi importado caso a planilha seja válida
        let alreadyImported = false;

        //lista dos meses que ainda não foram importados
        let newMonthsToInsert = [];
        //lista de meses a serem removidos da lista de inserção
        let monthsToRemoveFromInsert = [];

        //Meses que precisarão ser limpos pois surgiram novas transações
        const needCleanMonth = [];
        if (monthsToInsert.length > 0 && yearsToInsert.length == 1) {
            for (let i = 0; i < monthsToInsert.length; i++) {

                if (isFromPdfs) {
                    newMonthsToInsert.push(monthsToInsert[i]);
                } else {

                    let responseAlreadyImported = await checkAlreadyImportedMonth(monthsToInsert[i].toString(),
                        yearsToInsert[0],
                        clientOn.client.id);

                    console.log("responseAlreadyImported:", responseAlreadyImported);
                    if (responseAlreadyImported.success) {

                        const transactionsInMonth = transactionToInsert.filter(el => el.transaction_date.substring(3, 5) == monthsToInsert[i].toString())

                        if (responseAlreadyImported.body
                            && responseAlreadyImported.body.rowCount == 0) {
                            //&& responseAlreadyImported.body.rowCount != transactionsInMonth.length) {

                            // if (responseAlreadyImported.body.rowCount != 0 && transactionsInMonth != 0) {
                            //     needCleanMonth.push(parseInt(monthsToInsert[i]));
                            // }

                            console.log("ADICIONANDO: ", monthsToInsert[i]);
                            newMonthsToInsert.push(monthsToInsert[i]);

                        } else {
                            monthsToRemoveFromInsert.push(monthsToInsert[i]);
                        }
                    }
                }


            }

        }

        console.log("TRANSACTIONS TO INSERT: ", transactionToInsert);
        console.log("needCleanMonth: ", needCleanMonth);

        //Caso existam meses a serem cadastrados

        //##################################################################
        //##################################################################
        //TROCAR TESTE DE JA INSERÇÃO DE MESES POR TESTE DE INSERÇÃO DE TRANSAÇÃO
        //let newTransactionsToInsert = [];
        let newTransactionsToInsert = transactionToInsert;
        if (!isFromPdfs) {
            if (newMonthsToInsert) {

                if (monthsToRemoveFromInsert.length > 0) {

                    //Filtrando pelos meses que devem ser retirados, o filtro retorna tudos os itens que não se encontram na lista de meses a serem retirados
                    newTransactionsToInsert = transactionToInsert.filter((el) => !monthsToRemoveFromInsert.includes(el.transaction_date.substring(3, 5)));

                } else {
                    newTransactionsToInsert = transactionToInsert;
                }
            }
        }
        //##################################################################
        //##################################################################

        //Guardando os dados a serem inseridos e exibindo modal de confirmação
        return {

            client: clientOn.client,

            assets: assetsToCreate,
            //transactions: transactionToInsert,
            needCleanMonth: needCleanMonth,
            transactions: newTransactionsToInsert,

            invalidColumns: invalidColumns,
            invalidsAccounts: invalidsAccounts,
            invalidsFunds: invalidsFunds,
            invalidMonths: invalidMonths,
            accountsWhite: accountsWhite,
            yearsToInsert: yearsToInsert,
            monthsToInsert: monthsToInsert,
            newMonthsToInsert: newMonthsToInsert,
            invalidDates: invalidDates,
            invalidNoCnpjs: invalidNoCnpjs,

            alreadyImported: alreadyImported,

        };

        // }
    }
}