import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../utils/MyTable';

//redux

//Utils
import {
    applyMaskAmount,
    applyMaskAmountFreeDecimals,
    formatDataToPieChart, greenOrRed
} from '../utils/utils';

import ComdinheiroInfosFund from '../utils/ComdinheiroInfosFund';

import MyContentLoadModal from '../utils/MyContentLoadModal';

import { useDispatch, useSelector } from "react-redux";

//Controllers
import { doGetFundComposition } from '../../controllers/FundsCompositionsController';
import { doGetArticlesByCnpjList } from '../../controllers/LegislationArticleController';

import UnoButton from '../utils/UnoButton';

import { Grid, Typography } from '@material-ui/core';

import { myLabelInput, unoTheme } from '../../assets/styles/unoTheme';


import { Pie } from 'react-chartjs-2';
import allActions from '../../actions';
import moment from 'moment';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paperScrollPaper': {

            height: '100% !important',
        }
    },
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),
        //position: 'absolute',
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    },

    labelTopModal: {
        float: 'right',
        fontSize: '14px',
        color: unoTheme.defaultTextColor
    },

    infoRentHistoric: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        fontWeight: 'bold',
        fontSize: '16px',
        '& div': {
            marginLeft: '16px',
            background: '#eeeeee',
            padding: '4px 12px',
            borderRadius: '8px',
        },
        '& span': {
            fontSize: '12px',
            marginRight: '8px',
        }
    },
    topInfos: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '24px',
        backgroundColor: unoTheme.hoverTable,
        borderRadius: '10px',
    },
    divDetailTable: {
        textAlign: 'left',
        width: '50%',
        maxWidth: '50%',
        display: 'flex',
        //alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    divChart: {
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '24px',
        backgroundColor: unoTheme.hoverTable,
        borderRadius: '10px',
        height: '400px',
        maxHeight: '400px',
    },

    ellipsis: {
        whiteSpace: "nowrap", /* Impede a quebra de linha */
        overflow: "hidden", /* Garante que o texto extra seja ocultado */
        textOverflow: "ellipsis",
    },

    customHeightCell: {
        '& .MuiTableCell-root': {
            height: '50px',
        },
    }




}));

const defaultOptionsChart = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'right',
        fullWidth: true,
    },
};

function compareSortRentValue(a, b) {

    if (!a.valor_final)
        return 1
    if (!b.valor_final)
        return -1
    if (a.valor_final > b.valor_final)
        return -1;
    if (a.valor_final < b.valor_final)
        return 1;
    return 0;
}

function compareSortChartValue(a, b) {

    if (!a.value)
        return 1
    if (!b.value)
        return -1
    if (a.value > b.value)
        return -1;
    if (a.value < b.value)
        return 1;
    return 0;
}

export default function InfosFundModal(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const clientOn = useSelector(state => state.clientOn)

    const { open, fund, isFull,
        handleCloseInfosFundModal } = props;

    const chartRef = useRef(null);

    const [dataToTable, setDataToTable] = useState([]);
    const [dataToChart, setDataToChart] = useState();
    const [optionsChart, setOptionsChart] = useState(null);
    const [referenceDate, setReferenceDate] = useState(null);

    const [resolutionName, setResolutionName] = useState('3.922');

    const [loading, setLoading] = useState(false);

    function doFormatDataToChartAndTable(composition) {
        const toTable = [];
        const toChart = [];
        Object.entries(composition).map(([key, row]) => {
            row.composicao.forEach(element => {
                toTable.push(element)
            });

            const formattedProportion = applyMaskAmountFreeDecimals(row.proporcao, null, 3)
            toChart.push({
                label: key + " (" + formattedProportion + "%)",
                value: row.proporcao
            });
        })

        return { toTable, toChart }
    }

    async function loadInfosFund(fund) {

        setLoading(true);

        const localFundCompositionFund = await doGetFundComposition(fund, isFull);
        console.log("COMPOSITION: ", localFundCompositionFund);


        if (localFundCompositionFund) {

            const { json_composition: compositionFund, reference_date } = localFundCompositionFund;

            setReferenceDate(reference_date);
            const { toTable, toChart } = doFormatDataToChartAndTable(compositionFund)
            console.log("toTable: ", toTable);

            // const fundsInComposition = toTable.filter(el => el.descricao == 'Cotas de Fundos');
            // if (fundsInComposition && fundsInComposition.length > 0) {

            //     let cnpjs = fundsInComposition.reduce(
            //         (previous, current) => previous + "'" + current.ticker + "', ",
            //         "("
            //     )
            //     cnpjs = cnpjs.slice(0, -2) + ')';

            //     console.log('CNPJS: ', cnpjs);
            //     const articlesResponse = await doGetArticlesByCnpjList(cnpjs, clientOn.client.selectedMonth, clientOn.client.selectedYear);

            //     if (articlesResponse.success) {

            //         const articlesRows = articlesResponse.body.rows;
            //         if (articlesRows.length > 0) {
            //             console.log('articlesRows: ', articlesRows);

            //             fundsInComposition.forEach(fund => {
            //                 const matchArticles = articlesRows.find(el => el.cnpj == fund.ticker);
            //                 console.log('matchArticles: ', matchArticles);
            //                 fund.device_abbreviation = matchArticles && matchArticles[0] && matchArticles[0].device_abbreviation
            //             });
            //             console.log('fundsInComposition: ', fundsInComposition);
            //         }


            //         setResolutionName(articlesResponse.body.resolutionName)
            //     }
            // }

            //TABLE
            setDataToTable(toTable.sort(compareSortRentValue));

            //CHART
            setDataToChart(formatDataToPieChart(toChart.sort(compareSortChartValue)));

            const optionsChart = {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: 'Composição por classe',
                    fontSize: 22,
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    fullWidth: true,
                    onHover: (evt, legendItem) => {

                        const chart = chartRef.current.chartInstance;
                        const index = chart.data.labels.indexOf(legendItem.text);
                        const rect = chart.canvas.getBoundingClientRect();
                        const point = chart.getDatasetMeta(0).data[index].getCenterPoint();
                        const e = new MouseEvent('mousemove', {
                            clientX: rect.left + point.x,
                            clientY: rect.top + point.y
                        });
                        chart.canvas.dispatchEvent(e);
                    }
                },
            };

            setOptionsChart(optionsChart);
        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                type: 'warning',
                message: 'Não foram encontradas informações de composição desse fundo',
                show: true,
            }));
            //handleCloseInfosFundModal();
        }

        setLoading(false);


    }

    useEffect(() => {

        if (open && fund) {
            console.log("fund to infos: ", fund);
            setDataToChart(undefined)
            setDataToTable(undefined)
            loadInfosFund(fund);
        }

    }, [open])

    return (
        <Dialog
            className={classes.root}
            fullWidth={true}
            maxWidth={'lg'}
            open={open}
            onClose={() => handleCloseInfosFundModal()}>
            <DialogTitle
                className={classes.dialogTitle}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                    <div>{`${isFull ? 'Carteira raíz do fundo:' : 'Carteira do fundo:'} ${fund?.fund_name}`}</div>
                    <div>{`Referência: ${moment.utc(referenceDate).format("DD/MM/YYYY")}`}</div>
                </div>
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>

                {
                    !loading ?
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    fund ?
                                        <ComdinheiroInfosFund fund={fund} />
                                        : null
                                }


                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    dataToChart ?
                                        <div className={classes.divChart}>
                                            <Pie data={dataToChart}
                                                options={optionsChart ? optionsChart : defaultOptionsChart}
                                                ref={chartRef} />
                                        </div>
                                        : <Typography>Não foram encontradas informações de composição do fundo</Typography>
                                }
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                {
                                    dataToTable && dataToTable.length ?

                                        <div className={dataToTable.some(el => el.nome_fundo) ? classes.customHeightCell : ""}>
                                            <MyTable
                                                style={{ marginTop: '8px' }}
                                                noscroll
                                                size="small">
                                                {/* <colgroup>
                                            <col width="15%" />
                                            <col width="30%" />
                                            <col width="15%" />
                                            <col width="5%" />
                                            <col width="15%" />
                                            <col width="10%" />
                                            <col width="10%" />
                                        </colgroup> */}
                                                <colgroup>
                                                    <col width="35%" />
                                                    <col width="25%" />
                                                    <col width="20%" />
                                                    <col width="20%" />
                                                </colgroup>

                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Ativo</TableCell>
                                                        <TableCell align="left">Descrição</TableCell>
                                                        {/* <TableCell align="left">Razão Social</TableCell> */}
                                                        {/* <TableCell align="left">{resolutionName ? resolutionName : 'Resolução'}</TableCell> */}
                                                        <TableCell align="right">Valor</TableCell>
                                                        <TableCell align="right">(%)</TableCell>
                                                        {/* <TableCell align="center">Data</TableCell> */}

                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        dataToTable.map((row, index) => (

                                                            <TableRow key={'composition-' + index}>

                                                                <TableCell align="left">
                                                                    {
                                                                        row.nome_fundo ?
                                                                            <div>
                                                                                <div className={classes.ellipsis}>{row.nome_fundo}</div>
                                                                                <div style={{ color: 'black', fontWeight: '900', fontSize: '12px' }}>{row.ticker}</div>
                                                                            </div>
                                                                            :
                                                                            row.ticker
                                                                    }
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {row.descricao}
                                                                </TableCell>
                                                                {/* <TableCell align="left">
                                                            {row.razao_social_do_fundo}
                                                        </TableCell> */}
                                                                {/* <TableCell align="left">
                                                            {row.device_abbreviation ? row.device_abbreviation : '-'}
                                                        </TableCell> */}
                                                                <TableCell align="right" style={{ color: greenOrRed(row.valor_final) }}>
                                                                    {row.valor_final ? applyMaskAmount(row.valor_final, true) : '-'}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {row.proporcao + '%'}
                                                                </TableCell>
                                                                {/* <TableCell align="center">
                                                                    {row.data_da_carteira}
                                                                </TableCell> */}

                                                            </TableRow>


                                                        ))

                                                    }
                                                </TableBody>

                                            </MyTable>
                                        </div>
                                        : null
                                }

                            </Grid>


                        </Grid>

                        :
                        <MyContentLoadModal show={true} message={'Carregando composição...'} />
                }




            </DialogContent>


            <DialogActions>

                <UnoButton
                    float={'right'}
                    onClick={() =>
                        handleCloseInfosFundModal()} color="secondary">
                    {'Fechar'}
                </UnoButton>



            </DialogActions>

        </Dialog>
    );
}