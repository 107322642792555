import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { login } from '../../API/user'
import { useHistory, useLocation } from "react-router-dom";
import Zoom from '@material-ui/core/Zoom';

//import Logo from '../../assets/imgs/logop.png'
import LogoUno from '../../assets/imgs/logo_uno_full_azul.svg'
import Banner from '../../assets/imgs/banner_uno.png';

import MuiAlert from '@material-ui/lab/Alert';

import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../actions'
import MyTextField from '../utils/MyTextField';
import { CircularProgress } from '@material-ui/core';
import { unoTheme } from '../../assets/styles/unoTheme';
import { isKeyPressedEnter } from '../utils/utils';
import ResetingPasswordModal from '../modals/ResetingPasswordModal';
import { APP_VERSION } from '../../API/config';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lemaef.com.br/">
                LEMA
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(' + Banner + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    inputGrid: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '200px',
        marginBottom: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        width: '100%'
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    forgotPassword: {
        color: unoTheme.mainColor,
        textAlign: 'right',
        fontSize: '0.9em',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignInSide() {
    const classes = useStyles();
    const history = useHistory();

    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);

    const [inputCpf, setInputCpf] = useState("");
    const [openLoadingBackdrop, setOpenLoadingBackdrop] = useState(false);
    const [messageFeedback, setMessageFeedback] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);
    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const onChangeCpf = value => {
        setInputCpf(value);
    };

    const onChangePassword = event => {
        setInputPassword(event.target.value);
    };

    async function callLogin(cpf, password) {

        setMessageFeedback('');
        setOpenLoadingBackdrop(true);

        const response = await login(cpf, password);
        if (response.success) {

            localStorage.setItem('token', response.body.token);

            dispatch(allActions.userActions.setUser({}))
            setOpenLoadingBackdrop(false);
        } else {
            setMessageFeedback(response.body.message);
            setOpenLoadingBackdrop(false);
        }
    }

    const handleEnter = (event) => {

        console.log("HANDLE ENTER");
        event.preventDefault();
        if (isKeyPressedEnter(event.keyCode)) {
            return callLogin(inputCpf, inputPassword);
        }
    }

    useEffect(() => {

        if (currentUser
            && currentUser.user
            && currentUser.user.name != "JsonWebTokenError"
            && currentUser.loggedIn) {

            history.push('/')
        }

    }, [currentUser]);

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={8} className={classes.image} />
            <Grid className={classes.inputGrid} item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img className={classes.logo}
                        src={LogoUno}></img>
                    {APP_VERSION}
                    <br />
                    <Zoom
                        key={'messageFeedBack'}
                        in={messageFeedback != ""}
                        timeout={transitionDuration}
                        style={{
                            transitionDelay: `${messageFeedback != "" ? transitionDuration.exit : 0}ms`,
                        }}
                        unmountOnExit>
                        <Alert severity="warning" className={classes.alert}>{messageFeedback}</Alert>
                    </Zoom>
                    <br />

                    <form className={classes.form} onKeyUp={handleEnter}>
                        <MyTextField
                            id="cpf"
                            fullWidth={true}
                            value={inputCpf}
                            name="cpf"
                            size={'small'}
                            onChange={onChangeCpf}
                            variant="outlined"
                            helperText={"Insira um CPF válido"}
                            autoFocus={true}
                            mask={['cpf']}
                            label="CPF" />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            size={'small'}
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            onChange={onChangePassword}
                            value={inputPassword}
                            autoComplete="current-password" />

                        <Typography className={classes.forgotPassword}
                            onClick={() => setOpenResetPasswordModal(true)}>
                            Esqueceu a senha?
                        </Typography>

                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => callLogin(inputCpf, inputPassword)}>
                            Entrar
                        </Button>
                        <Box mt={5}>

                            {messageFeedback}
                            <Copyright />
                        </Box>
                    </form>
                </div>
                {
                    openLoadingBackdrop ?
                        <div className={classes.rightDivBackdrop}>
                            <CircularProgress className={'progress'} />
                        </div> : null
                }
            </Grid>

            <ResetingPasswordModal open={openResetPasswordModal}
                handleCloseResetingPasswordModal={(message) => {
                    if (message) {
                        setMessageFeedback(message);
                    }
                    setOpenResetPasswordModal(false)
                }} />
        </Grid>
    );
}