import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { tabs } from '../../../../assets/styles/unoTheme';
import { Route, useHistory, Switch, useLocation } from 'react-router';
import { MyTabs, MyTab } from "../../../utils/MyTabs";
import ActionsHistory from './ActionsHistory';
import AccessHistory from './AccessHistory';
import ConciliationScreen from './ConciliationScreen';
import SearchBar from '../../../utils/SearchBar';
import MyTooltip from '../../../utils/MyHelpers/MyTooltip';
import CPF_AND_NAME_TEXT from "../../../utils/MyHelpers/HelperTexts/SearchBar";
import menuItems from '../../../dashboard/menuItems';
import { path } from '../../../utils/routesPaths';
import MyAutoComplete from '../../../utils/MyAutoComplete';
import { listAllClients } from '../../../../API/client';
import ClientUpdateLogs from './ClientUpdateLogs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red',
  },
  tabs: tabs,
  tabsWithRight: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacing(2),
    alignItems: 'center',

    '& .MuiToggleButtonGroup-root': {
      alignItems: 'center',

      '& .MuiToggleButton-label': {
        whiteSpace: 'nowrap'
      }
    }
  },
  // Form
  inputSearch: {
    width: "300px",
    background: "white"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  toStickyHeader: {
    display: 'flex',
    height: '100%',
  }
}));

export default function LogsScreen({
  setShowMonths,
  setShowYears,
  locationOnScreen,
  setLocationOnScreen
}) {

  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const location = useLocation();


  const [allClients, setAllClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const handleChangeClient = (event, newValue) => {
    setSelectedClient(newValue)
  };

  const handleChangeTabValue = (event, newTabValue) => {

    setTabValue(newTabValue);

    const HISTORY = {
      clientUpdates: 0,
      actions: 1,
      accesses: 2,
      conciliations: 3
    };

    switch (newTabValue) {
      case HISTORY.clientUpdates:
        history.push('/administration/logs/clientUpdates');
        break;
      case HISTORY.actions:
        history.push('/administration/logs/actions');
        break;
      case HISTORY.accesses:
        history.push('/administration/logs/accesses');
        break;
      case HISTORY.conciliations:
        history.push('/administration/logs/conciliations');
        break;
      default:
        break;
    }
  };

  function redirect() {
    const LOGS_INDEX = 3;
    const PATH_OF_LOG_SUBMENU = menuItems.backofficeMenu[LOGS_INDEX].path;
    if (location.pathname === PATH_OF_LOG_SUBMENU) {
      history.push(path.administration.logs.clientUpdates);
      const HOME_TAB = 0;
      setTabValue(HOME_TAB);
    };
  };

  async function loadAllClients() {

    const responseAllClients = await listAllClients();
    if (responseAllClients.success) {
      setAllClients(responseAllClients.body.rows);
    } else {
      setAllClients([])
    }


  }

  useEffect(() => {
    // if ((location && !locationOnScreen)
    //   || (location && location.pathname != locationOnScreen.pathname)) {
    //   const isUserFirstTimeOnScreen = location.pathname === path.administration.logs.root;
    //   if (isUserFirstTimeOnScreen) {
    //     setShowMonths(false);
    //     setShowYears(false);
    //   };
    //   redirect();
    //   setLocationOnScreen(location);
    // }

    if (!allClients) {
      loadAllClients();
    }
  }, [location])

  return (
    <div className={classes.root}>

      <div className={classes.tabsWithRight}>
        <div className={classes.tabs}>
          <MyTabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTabValue}
            aria-label="disabled tabs example"
          >
            <MyTab label="Edições cliente" />
            <MyTab label="Ações" />
            <MyTab label="Acessos" />
            <MyTab label="Conciliações" />
          </MyTabs>
        </div>

        {
          tabValue == 0 || tabValue == 1 ?
            <>

              {
                allClients ?
                  <MyAutoComplete
                    options={allClients}
                    autoComplete
                    customWidth={'380px'}
                    // customPadding={'6px 16px'}
                    // customHeight={'25px'}
                    value={
                      selectedClient
                    }
                    onChange={(event, newValue) => {
                      handleChangeClient(event, newValue)
                    }}
                    label={"Filtrar por cliente"}
                    getOptionLabel={(option) => option.label_name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={(option) => (
                      <React.Fragment key={'autocomplete-' + option.id}>
                        <span>{option.label_name}</span>
                      </React.Fragment>
                    )}
                  /> : null
              }

            </>
            :
            <SearchBar
              name='search_query'
              mask='text_cpf'
              size='small'
              variant='outlined'
              label='Nome ou CPF'
              setSearchQuery={setSearch}
            />

        }


        {/* <MyTooltip
          hasAlert
          alertMessage={<CPF_AND_NAME_TEXT searchColumnTitle="usuário" />}
          tooltipTitle="Como funciona a busca?"
        /> */}
      </div>

      <Switch>
        <Route path="/administration/logs/clientUpdates">
          <ClientUpdateLogs selectedClient={selectedClient} />
        </Route>
        <Route path="/administration/logs/actions">
          <ActionsHistory selectedClient={selectedClient} />
        </Route>
        <Route path="/administration/logs/accesses">
          <AccessHistory search={search} />
        </Route>
        <Route path="/administration/logs/conciliations">
          <ConciliationScreen search={search} />
        </Route>
      </Switch>
    </div >
  );
}






