import API from './config'

export async function listAllInstituitions() {

    try {

        const response = await API.get('/instituitions')

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listActivesInstituitions() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/instituitions/actives', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error)

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function getInstituitionIdByInstituitionName(instituitionName) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/instituitions/getInstituitionIdByInstituitionName', { instituition_name: instituitionName }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error)

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function getInstituitionIdByInstituitionCod(instituitionCod) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/instituitions/getInstituitionIdByInstituitionCod', { instituition_cod: instituitionCod }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error)

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function updateInstituition(instituitionId, status) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/instituition', {
            id: instituitionId,
            status: status,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}
