/**
 * Sets a report state based on the given report type.
 * @typedef {{
* show: boolean;
* reportType: 'distribuition' | 'fullReport' | 'fundsRanking' | 'demonstrative' | 'batchAprs';
* message: string;
* }} ReportLoadObj
* @param {ReportLoadObj} reportLoadObj
* @returns {{
* type: string;
* payload: reportLoadObj;
* }} No return value.
*/
const setReportLoad = (reportLoadObj) => {
    return {
        type: "SET_REPORT_LOAD",
        payload: reportLoadObj
    }
}


export default {
    setReportLoad
}