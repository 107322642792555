import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getAccessHistoryLogs, getUserAccessHistoryLogs } from '../../../../API/log_action';

import { unoTheme, headerSearch } from '../../../../assets/styles/unoTheme'

import moment from 'moment';
import { useDispatch } from 'react-redux';
import allActions from '../../../../actions';
import { applyMaskCpf, hasOnlyNumbers, isValidCpf, justNumbers } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red',
  },
  headerSearch: headerSearch,
  inputSearch: {
    width: "300px",
    background: "white"
  },
  toStickyHeader: {
    display: 'flex',
    height: '100%',
  },
  rightDivBackdrop: {
    backgroundColor: 'white',
    position: 'absolute',
    opacity: 0.7,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .progress': {
      color: unoTheme.mainColor
    }
  },
}));

export default function AccessHistory({ search }) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [accessHistoryLogs, setAccessHistoryLogs] = useState([]);

  async function doGetLogActions() {
    dispatch(allActions.innerLoadActions.setInnerLoad({
      show: true,
      loadMessage: 'Carregando acessos...'
    }));

    let response = null;
    let isSearchInputValueEmpty = search === '';
    if (isSearchInputValueEmpty) {
      response = await getAccessHistoryLogs();
    } else {
      response = await getUserAccessHistoryLogs(search);
    }

    const accessHistoryLogsData = response.body.rows;

    if (response.success && response.body) {
      const hasAccessHistoryLogsData = accessHistoryLogsData.length > 0 ? true : false;
      if (hasAccessHistoryLogsData) {
        setAccessHistoryLogs(accessHistoryLogsData);
        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
      } else {
        setAccessHistoryLogs([]);
        dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem acessos' }));
      }
    } else {
      console.error("RESPONSE ERROR: ", response.error);
      dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
    }
  }

  useEffect(() => {
    doGetLogActions();
  }, [search]);

  return (
    <div className={classes.root}>
      <div class={'defaultScrollWithHeaderContent'}>
        <MyTable
          stickyHeader
          nofixed={true}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Data de Login</TableCell>
              <TableCell align="left">Nome do usuário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessHistoryLogs.map((row, index) => (
              <TableRow key={'log-access-' + index}>
                <TableCell align="left" style={{ color: unoTheme.mainColor }}>{moment(row.login_date).format("DD/MM/YYYY - HH:mm:ss")}</TableCell>
                <TableCell align="left">{row.username.toUpperCase()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MyTable>
      </div>
    </div>
  );
}