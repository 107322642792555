import API from './config'

export async function getStatusesDair() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/status_dpin_dair/getStatusesDair', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getStatusesDpin() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/status_dpin_dair/getStatusesDpin', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function changeStatusDpin(statusDpinId,
    clientId,
    userId,
    year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/status_dpin_dair/changeStatusDpin', {
            status_dpin_id: statusDpinId,
            client_id: clientId,
            user_id: userId,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function changeStatusDair(statusDairId,
    clientId,
    userId,
    month,
    year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/status_dpin_dair/changeStatusDair', {
            status_dair_id: statusDairId,
            client_id: clientId,
            user_id: userId,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

