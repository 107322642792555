import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

//redux
import { useSelector, useDispatch } from 'react-redux'

import { getArrayYears, applyMaskAmount, applyMaskAccount, getMonthName } from '../utils/utils';
import UnoButton from '../utils/UnoButton';

import { Box, Typography } from '@material-ui/core';

import { unoTheme, myLabelInput } from '../../assets/styles/unoTheme'

import InfoIcon from '@material-ui/icons/Info';

import moment from 'moment'

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';

import Rating from '@material-ui/lab/Rating';
import MyTextField from '../utils/MyTextField';
import { saveUserRate } from '../../API/user_feedback';
import allActions from '../../actions';

const optionsRating = {
    1: {
        rating: 1,
        label: 'Muito Insatisfeito',
    },
    2: {
        rating: 2,
        label: 'Insatisfeito',
    },
    3: {
        rating: 3,
        label: 'Neutro',
    },
    4: {
        rating: 4,
        label: 'Satisfeito',
    },
    5: {
        rating: 5,
        label: 'Muito Satisfeito',
    },
};

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',

    },

    dialogContent: {
        '& .MuiBox-root': {
            textAlign: 'center',
        },

        '& .iconsRating': {

            paddingTop: '16px',

            '& svg': {
                fontSize: '3rem',
                color: "#aaaaaa",
                cursor: 'pointer',
                transition: 'all .5s ease',

                '&:hover': {
                    transform: 'scale(1.1)',
                },

                '&.selected': {
                    transform: 'scale(1.1)',
                }
            },

            '& svg.icon1': {

                '&:hover': {
                    color: '#e02025',

                }
            },
            '& svg.icon1.selected': {

                color: '#e02025',
            },

            '& svg.icon2': {

                '&:hover': {
                    color: '#f47950',

                }
            },
            '& svg.icon2.selected': {

                color: '#f47950',
            },
            '& svg.icon3': {

                '&:hover': {
                    color: '#fcb040',

                }
            },
            '& svg.icon3.selected': {

                color: '#fcb040',
            },
            '& svg.icon4': {

                '&:hover': {
                    color: '#91ca61',

                }
            },
            '& svg.icon4.selected': {

                color: '#91ca61',
            },
            '& svg.icon5': {

                '&:hover': {
                    color: '#3ab54a',

                }
            },
            '& svg.icon5.selected': {

                color: '#3ab54a',
            },

        }
    },

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },


}));

export default function RatingUNOModal(props) {

    const { user } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const { handleModalRatingUno, open } = props;

    const [hoveredRating, setHoveredRating] = useState(null);
    const [selectedRating, setSelectedRating] = useState(null);
    const [sugestionFeedback, setSugestionFeedback] = useState("");

    const [validateMessage, setValidateMessage] = useState("");

    async function doRating(selectedRating,
        user,
        sugestionFeedback) {

        if (!selectedRating) {
            setValidateMessage("Selecione o seu nível de satisfação")
        } else {
            const response = await saveUserRate(user.id, selectedRating.rating, sugestionFeedback);
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: "Avaliação registrada com sucesso! Obrigado!",
                type: 'success',
            }))
            handleModalRatingUno(true);
        }


    }

    useEffect(() => {

    }, [])

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
        //onClose={() => handleModalRatingUno(false)}
        >
            <DialogTitle
                className={classes.dialogTitleApr}>
                {
                    <><InfoIcon className={[classes.spacingRight2, classes.blue].join(' ')} />Pesquisa de satisfação</>
                }


            </DialogTitle>


            <DialogContent
                className={classes.dialogContent}>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Na escala abaixo, marque qual seu nível de satisfação com a <span style={{ color: unoTheme.mainColor }}>#ExperiênciaUno</span>.</Typography>
                    <div class={'iconsRating'}>
                        <SentimentVeryDissatisfiedIcon className={'icon1' + (selectedRating && selectedRating.rating == 1 ? ' selected' : '')}
                            onMouseOver={() => setHoveredRating(optionsRating[1])}
                            onMouseOut={() => setHoveredRating(null)}
                            onClick={() => setSelectedRating(optionsRating[1])} />

                        <SentimentDissatisfiedIcon className={'icon2' + (selectedRating && selectedRating.rating == 2 ? ' selected' : '')}
                            onMouseOver={() => setHoveredRating(optionsRating[2])}
                            onMouseOut={() => setHoveredRating(null)}
                            onClick={() => setSelectedRating(optionsRating[2])} />

                        <SentimentSatisfiedIcon className={'icon3' + (selectedRating && selectedRating.rating == 3 ? ' selected' : '')}
                            onMouseOver={() => setHoveredRating(optionsRating[3])}
                            onMouseOut={() => setHoveredRating(null)}
                            onClick={() => setSelectedRating(optionsRating[3])} />
                        <SentimentSatisfiedAltIcon className={'icon4' + (selectedRating && selectedRating.rating == 4 ? ' selected' : '')}
                            onMouseOver={() => setHoveredRating(optionsRating[4])}
                            onMouseOut={() => setHoveredRating(null)}
                            onClick={() => setSelectedRating(optionsRating[4])} />
                        <SentimentVerySatisfiedIcon className={'icon5' + (selectedRating && selectedRating.rating == 5 ? ' selected' : '')}
                            onMouseOver={() => setHoveredRating(optionsRating[5])}
                            onMouseOut={() => setHoveredRating(null)}
                            onClick={() => setSelectedRating(optionsRating[5])} />
                    </div>
                    <Typography>{(selectedRating && selectedRating.label) || (hoveredRating && hoveredRating.label) ?
                        ((selectedRating && selectedRating.label) || (hoveredRating && hoveredRating.label)) : '-'}</Typography>
                    {!selectedRating ?
                        <Typography style={{ color: unoTheme.errorColor, fontSize: '13px' }}>{validateMessage}</Typography> : null}
                    {/* <Rating
                        className={classes.iconsRating}
                        defaultValue={0}
                        size="large"
                        getLabelText={(value) => customIcons[value].label}
                        IconContainerComponent={IconContainer}
                    /> */}
                </Box>


                <Typography style={{ display: 'flex', alignItems: 'center' }}><ChatOutlinedIcon /> <span style={{ marginLeft: '10px' }}> Escreva abaixo caso tenha alguma sugestão:</span></Typography>

                <MyTextField
                    value={sugestionFeedback}
                    onChange={(value) => setSugestionFeedback(value)}
                    variant="outlined"
                    multiline={true}
                    rows={8}
                    rowsMax={8}
                    margin="dense"
                    placeholder="Sugestão..."
                    fullWidth />


            </DialogContent>




            <DialogActions className={classes.actionsFlexRight}>

                {/* <UnoButton                
                    onClick={() => handleModalRatingUno(false)} color="secondary">
                    {'Fechar'}
                </UnoButton> */}

                <UnoButton
                    onClick={() => doRating(selectedRating, user, sugestionFeedback)}
                    color="primary">
                    {'Avaliar'}
                </UnoButton>
            </DialogActions>




        </Dialog >
    );
}