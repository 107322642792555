import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'fontsource-roboto';
import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux'
import rootReducer from './reducers'
import { Provider } from 'react-redux'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PoppinsWoff2 from './assets/fonts/Poppins-Regular.ttf';
import Manrope from './assets/fonts/Manrope.ttf';
import { unoTheme } from './assets/styles/unoTheme';

import '@fontsource/manrope/300.css';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';
import { applyMiddleware } from 'redux';
import { notificationMiddleware } from './reducers/notifications';
import { compose } from 'redux';

const middlewares = applyMiddleware(notificationMiddleware);

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(
    middlewares,
    window.__REDUX_DEVTOOLS_EXTENSION__()
  ) :
    middlewares
)

const manrope = {
  fontFamily: 'Manrope',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Manrope'),
    url(${Manrope}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Manrope, Arial',
  },
  palette: {
    primary: {
      main: unoTheme.mainColor
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        //'@font-face': [poppins],
        // '@font-face': [manropeBold],
        '@font-face': [manrope],
      },
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
