import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
import MyTextField from '../../utils/MyTextField';

//UnoButton
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import UnoButton from '../../utils/UnoButton';

//BoostrapInput
import MySelect from '../../utils/MySelect';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Grid
import { Grid } from "@material-ui/core";

import {
    applyMaskAmount,
    getYearsByYearUntilNow,
    parseFloatByPtCurrency
} from "../../utils/utils";

//API
import { createClientInflationRate, deleteClientInflationRate, updateClientInflationRate } from '../../../API/inflation_rate';

import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment';
import { getPortfolioClosedsInPeriodByClient } from '../../../API/client';
import ConfirmActionRightContent from './ConfirmActionRightContent';
import { unoTheme } from '../../../assets/styles/unoTheme';

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3)
    },

    main: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        textAlign: 'left',

        '& .MuiPaper-root': {
            height: 'calc(100% - 184px)',
        }
    },

    accordionAccount: {
        marginBottom: theme.spacing(2),
    },
    accordionSummary: {
        //background: lemaTheme.secondaryColor,
        //color: "white",
        '&:nth-child(1)': {
            marginBottom: '10px'
        }
    },
    iconWhite: {
        color: 'white',
    },
    accordionDetails: {
        background: '#ffffff'
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    inputsAccount: {
        width: '100%',
    },
    cellIcon: {
        padding: '0px'
    },
    formNewApplication: {
        width: "100%",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    itemRegime: {
        fontSize: "0.6em !important",
    },
    alignLeft: {
        textAlign: "left",
        '& fieldset': {

            '& legend': {
                fontSize: '0.9em !important',
            }

        }

    },
    legendeRadioGroup: {
        fontSize: '0.9em !important',
    },
    headerSearch: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        }
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    input: {
        display: 'none',
    },
    green: {
        color: "green",
        fontWeight: 'bold'
    },
    red: {
        color: "red",
        fontWeight: 'bold'
    },
    blue: {
        color: "blue",
        fontWeight: 'bold'
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    hiddenIcon: {
        visibility: 'hidden',
    },
    bottom: {

        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        '& .bottomButtons': {
            paddingTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }

    },

    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

}));

export default function NewClientInflationRate(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const clientOn = useSelector(state => state.clientOn)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const currentUser = useSelector(state => state.currentUser)

    const [rates, setRates] = useState(['IPCA', 'INPC']);
    const [selectedRate, setSelectedRate] = useState(0);
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(0);
    const [tax, setTax] = useState('');
    const [expectedRent, setExpectedRent] = useState('');
    const [liabilityDuration, setLiabilityDuration] = useState('');
    const [actuary, setActuary] = useState('');

    const [confirmingAction, setConfirmingAction] = useState(false);


    //deleção do ativo de título
    function handleCancel() {
        setConfirmingAction(false);
    }

    async function handleConfirm(year) {

        setConfirmingAction(false)
        setOpenBackdrop(true)
        //Verificar se é posssível excluir
        if (year) {

            const firstDay = moment.utc(new Date(year, 0, 1)).format('DD/MM/YYYY'); //pegar o ano todo desde janeiro
            const lastDay = moment.utc(new Date(year, 12, 0)).format('DD/MM/YYYY'); //pegar o ano todo até dezembro

            const responsePortfolioClosedsInPeriod = await getPortfolioClosedsInPeriodByClient(clientOn.client.id, firstDay, lastDay);
            console.log('responsePortfolioClosedsInPeriod:', responsePortfolioClosedsInPeriod);
            if (responsePortfolioClosedsInPeriod.success) {
                //Não pode haver registro de rentabilide no período desse ano
                if (responsePortfolioClosedsInPeriod.body.length === 0) {
                    doDeleteClientInflateRate(content.data.id);
                } else {
                    setOpenBackdrop(false)
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'warning',
                        message: `A meta de ${year} não pode ser excluída pois já existem lançamentos para esse período`
                    }))
                }
            } else {
                setOpenBackdrop(false)
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: `Falha ao verificar verificar informações da meta`
                }))
            }

        }


    }

    //form            
    const [submitted, setSubmitted] = useState('');


    function loadInfos(data) {

        setSelectedRate(data.use_ipca ? 0 : 1);

        for (let i = 0; i < data.years.length; i++) {

            //console.log("TESTANDO: ", data.years[i] + "==" + data.year);
            if (data.years[i] == data.year) {
                console.log("ANO: ", i);
                setSelectedYear(i);
                break;
            }
        }

        setTax(applyMaskAmount(data.tax));
        setExpectedRent(applyMaskAmount(data.expected_rent));
        setLiabilityDuration(data.liability_duration ? applyMaskAmount(data.liability_duration) : '');
        setActuary(data.actuary ? data.actuary : '');

    }

    async function doCreateClientInflationRate(clientId) {

        setSubmitted(true);
        setOpenBackdrop(true)

        if (tax != ""
            && expectedRent != "") {

            let clientInflationRate = {

                client_id: clientId,
                use_ipca: selectedRate == 0,
                use_inpc: selectedRate == 1,
                year: years[selectedYear],
                tax: parseFloatByPtCurrency(tax),
                expected_rent: parseFloatByPtCurrency(expectedRent),
                liability_duration: parseFloatByPtCurrency(liabilityDuration),
                actuary: actuary,

            }

            console.log("InflatioRate: ", clientInflationRate)
            if (content.update) {
                //inclusindo o id do rate a ser atualizado
                clientInflationRate.id = content.data.id;

                const responseInflationRate = await updateClientInflationRate(clientInflationRate);

                if (responseInflationRate.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Meta atualizada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao atualizar a meta: " + responseInflationRate.body.message
                    }))
                }

            } else {

                const responseInflationRate = await createClientInflationRate(clientInflationRate);

                if (responseInflationRate.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Meta cadastrada com sucesso!"
                    }))

                } else {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao cadastrar a meta: " + responseInflationRate.body.message
                    }))

                }
            }


            setOpenBackdrop(false)
            dispatch(allActions.rightContentActions.update())

        } else {
            setOpenBackdrop(false)
        }



    }

    async function doDeleteClientInflateRate(inflationRateId) {
        console.log("Delete");
        if (inflationRateId) {
            const deleteResponse = await deleteClientInflationRate(inflationRateId);
            if (deleteResponse.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: deleteResponse.body.message
                }))
                dispatch(allActions.rightContentActions.update())
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao excluir meta"
                }))
            }
        }

        setOpenBackdrop(false)

    }

    useEffect(() => {

        const yearsUntilNow = getYearsByYearUntilNow('2015');
        yearsUntilNow.push(yearsUntilNow[yearsUntilNow.length - 1] + 1);
        setYears(yearsUntilNow);
        content.data.years = yearsUntilNow;
        console.log("CONTENT: ", content);
        if (content.update) {

            loadInfos(content.data);
        }

    }, []);

    const rolesDisabled = useMemo(() => currentUser?.user?.isViewer, [currentUser]);

    return (
        <div className={classes.root}>

            {
                !confirmingAction ?
                    <>
                        <div className={classes.header}>
                            <IconButton
                                className={classes.hiddenIcon}>
                                <ArrowBackIcon />
                            </IconButton>


                            <div>
                                <Typography
                                    variant={'h6'}
                                    align={'left'}>

                                    {content.update ? "Editar meta" : "Cadastrar meta"}

                                </Typography>

                            </div>


                            <IconButton
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MySelect
                                        disabled={rolesDisabled}
                                        outerlabel={'ANO'}
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        options={years.map((row, index) => (
                                            <option key={'year-meta-' + index} value={index}>{row}</option>
                                        ))}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MySelect
                                        disabled={rolesDisabled}
                                        outerlabel={'Índice'}
                                        value={selectedRate}
                                        onChange={(e) => setSelectedRate(e.target.value)}
                                        options={rates.map((row, index) => (
                                            <option key={'rate-' + index} value={index}>{row}</option>
                                        ))}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MyTextField
                                        disabled={rolesDisabled}
                                        align={'right'}
                                        size={'small'}
                                        outerlabel={'Taxa'}
                                        value={tax}
                                        onChange={(value) => setTax(value)}
                                        validator={tax == "" && submitted ? true : undefined}
                                        helperText={"Insira o valor da taxa"}
                                        variant="outlined"
                                        placeholder={'0,00'}
                                        mask={["currency"]} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MyTextField
                                        disabled={rolesDisabled}
                                        align={'right'}
                                        size={'small'}
                                        outerlabel={'Rentabilidade esperada'}
                                        value={expectedRent}
                                        onChange={(value) => setExpectedRent(value)}
                                        validator={expectedRent == "" && submitted ? true : undefined}
                                        helperText={"Insira o valor da rentabilidade esperada"}
                                        variant="outlined"
                                        placeholder={'0,00'}
                                        mask={["currency"]} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MyTextField
                                        disabled={rolesDisabled}
                                        align={'right'}
                                        size={'small'}
                                        outerlabel={'Duração do passivo'}
                                        value={liabilityDuration}
                                        onChange={(value) => setLiabilityDuration(value)}
                                        variant="outlined"
                                        placeholder={'0,00'}
                                        mask={["currency"]} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>

                                    <MyTextField
                                        disabled={rolesDisabled}
                                        size={'small'}
                                        outerlabel={'Atuário'}
                                        value={actuary}
                                        onChange={(value) => setActuary(value)}
                                        variant="outlined"
                                        placeholder={'Atuário'} />
                                </Grid>

                            </Grid>

                            <br />
                            <div className={classes.bottom}>

                                {!rolesDisabled && <div className={'bottomButtons'}>

                                    <UnoButton
                                        onClick={() => setConfirmingAction(true)}
                                        type='errorClean'>
                                        {"Excluir meta"}
                                    </UnoButton>


                                    <UnoButton
                                        onClick={() => doCreateClientInflationRate(clientOn.client.id)}
                                        type={!content.update ? "primary" : "success"}>
                                        {content.update ? "Atualizar meta" : "Criar meta"}
                                    </UnoButton>

                                </div>}

                            </div>

                        </div>



                    </>

                    :

                    <ConfirmActionRightContent
                        message={`Tem certeza que quer excluir a meta de ${content?.data?.year}?`}
                        handleCancel={handleCancel}
                        handleConfirm={() => handleConfirm(content?.data?.year)}
                    />
            }


            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }

        </div >
    );
}



