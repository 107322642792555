import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { timeStampToPtDate, isValidCep, applyMaskPhone, applyMaskCpf } from '../../utils/utils'
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../actions";

import { Grid } from "@material-ui/core";

//Teste field 
import MyInfo from '../../utils/MyInfo'
import { isValidCnpj, isValidCpf, isValidEmail, justNumbers, formatJsonLabel } from '../../utils/utils'

import Button from '@material-ui/core/Button';

//Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import _ from 'lodash';
import { getDetailQuota } from "../../../API/quotas-api";

import moment from 'moment'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    bold: {
        fontWeight: 'bold',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    paperForm: {
        padding: theme.spacing(2),
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    btnRightWithInput: {
        boxSizing: 'border-box',
        background: '#28a745',
        border: 'solid 1px #bbbbbb',
        borderLeft: 'none',
        borderRadius: '0px 4px 4px 0px',
        cursor: "pointer",
        color: "white",
        transition: 'all .1s ease',
        borderColor: '#28a745',
        '&:hover': {
            background: '#218838',
            borderColor: '#1e7e34',
        },
        '& span': {
            fontSize: '15px',
        },
        '& div': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '100%',
            justifyContent: 'center',
        }
    },
    quotaRow: {
        cursor: 'pointer'
    }
}));

export default function QuotaDetailScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const fullLoad = useSelector(state => state.fullLoad)

    const [quota, setQuota] = useState(undefined)
    const [quotaDocuments, setQuotaDocuments] = useState([])
    const [quotaHistoricTaxs, setQuotaHistoricTaxs] = useState([])
    const [quotaRegistredData, setQuotaRegistredData] = useState({})
    const [quotaMovimentation, setQuotaMovimentation] = useState({})
    const [quotaHistoricSerie, setQuotaHistoricSerie] = useState({})
    const [quotaTaxs, setQuotaTaxs] = useState({})

    // dados-cadastrais: {codigo_fundo: "430803", nome_fantasia: "ITAU FLEXPREV ESTRAT USD X EURO MULT FI", razao_social: "ITAÚ FLEXPREV ESTRATÉGIA USD X EURO MULTIMERCADO FUNDO DE INVESTIMENTO", cnpj_fundo: "25341083000103", codigo_isin: "BREUR9CTF006", …}
    // documentos: [{…}]
    // historico_taxas: [{…}]
    // movimentacao: {prazo_emissao_cotas: "0", prazo_pagamento_resgate: "1", prazo_conversao_resgate: "0", aplicacao_inicial_minima: 1, aplicacao_adicional_minima: 1, …}
    // prestadores: {administrador: {…}, gestores: Array(1), distribuidores: Array(1), custodiantes: Array(1), controlador_ativo: {…}, …}
    // serie_historica: {patrimonio_liquido: 7218780.2, valor_cota: 12.0215, captacao: 85000, resgate: 26061.22, numero_cotistas: 1, …}
    // taxas: {unidade: "

    async function getDetail(quotaToDetail) {

        //let response = await getDetailQuota(quotaToDetail.codigo_fundo);
        //let response = await getDetailQuota(quotaToDetail.cnpj_fundo);
        let response = await getDetailQuota('272604');

        console.log("QUOTA DETAIL: ", response.body);
        console.log("JSON DETAIL: ", JSON.stringify(response.body));

        if (response.success) {

            setQuota(quota);
            setQuotaDocuments(response.body.documentos);
            setQuotaHistoricTaxs(response.body.historico_taxas);
            setQuotaRegistredData(response.body['dados-cadastrais']);
            setQuotaMovimentation(response.body['movimentacao']);
            setQuotaHistoricSerie(response.body['serie_historica'])
            setQuotaTaxs(response.body['taxas']);

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao obter detalhes do fundo'
            }))
        }

    }

    useEffect(() => {

        if (!quota) {
            setQuota(location.state.quota)
        } else {
            getDetail(quota);
        }

    }, [quota]);

    function doUpdateQuota() {

    }

    function goToLink(event, url) {
        event.preventDefault();
        window.open(url)
    }

    function renderQuotaRegistredData(data) {
        let rows = [];
        for (const item in data) {
            let value = data[item];
            if (item.includes('data')) {
                value = moment.utc(data[item]).format('DD/MM/YYYY');
                //console.log("VALUE: ", value)
            }
            rows.push(
                <TableRow>
                    <TableCell align="left" className={classes.bold}>{formatJsonLabel(item)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                </TableRow>
            )
        }

        return rows;
    }

    function renderQuotaMovimentation(data) {
        let rows = [];
        for (const item in data) {
            //console.log("ITEM: ", item)
            //console.log(item.includes('data'))
            let value = data[item];
            if (item.includes('data')) {
                value = moment.utc(data[item]).format('DD/MM/YYYY');
                //console.log("VALUE: ", value)
            }
            rows.push(
                <TableRow>
                    <TableCell align="left" className={classes.bold}>{formatJsonLabel(item)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                </TableRow>
            )
        }

        return rows;
    }

    function renderQuotaHistoricSerie(data) {
        let rows = [];
        for (const item in data) {
            //console.log("ITEM: ", item)
            //console.log(item.includes('data'))
            let value = data[item];
            if (item.includes('data')) {
                value = moment.utc(data[item]).format('DD/MM/YYYY');
                //console.log("VALUE: ", value)
            }
            rows.push(
                <TableRow>
                    <TableCell align="left" className={classes.bold}>{formatJsonLabel(item)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                </TableRow>
            )
        }

        return rows;
    }

    function renderQuotaTax(data) {
        let rows = [];
        for (const item in data) {
            //console.log("ITEM: ", item)
            //console.log(item.includes('data'))
            let value = data[item];
            if (item.includes('data')) {
                value = moment.utc(data[item]).format('DD/MM/YYYY');
                //console.log("VALUE: ", value)
            }
            rows.push(
                <TableRow>
                    <TableCell align="left" className={classes.bold}>{formatJsonLabel(item)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                </TableRow>
            )
        }

        return rows;
    }


    return (
        <div className={classes.root}>

            <div className={classes.headerDetail}>
                <div className={classes.leftHeaderDetail}>
                    <IconButton onClick={() => history.goBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" align="left">
                        {quota ?
                            quota.nome_fantasia : null
                        }
                    </Typography>

                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => doUpdateQuota()}>
                    {"Atualizar fundo"}
                </Button>
            </div>



            <Divider />
            <br />

            {
                quota ?
                    <form className={classes.rootForm} noValidate autoComplete="off">

                        <Typography variant={'body1'}>
                            {'Dados Cadastrais'}
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">

                                <TableBody>
                                    {
                                        quotaRegistredData ? renderQuotaRegistredData(quotaRegistredData) : null
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <br />
                        <Typography variant={'body1'}>
                            {'Documentos'}
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableTh} align="center">Código fundo</TableCell>
                                        <TableCell className={classes.tableTh} align="left">Nome arquivo</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Extensão</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Tipo</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {quotaDocuments.map((row) => (
                                        <TableRow hover
                                            className={classes.quotaRow}
                                            onClick={(e) => goToLink(e, row.url)}>
                                            <TableCell align="center">{row.codigo_fundo}</TableCell>
                                            <TableCell align="left" component="th" scope="row">{row.nome_arquivo}</TableCell>
                                            <TableCell align="center">{row.extensao}</TableCell>
                                            <TableCell align="center">{row.tipo_documento}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>



                        <br />
                        <Typography variant={'body1'}>
                            {'Histórico de taxas'}
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableTh} align="center">Taxa de performance</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Data atualização</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Inicio vigência adm</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Taxa composta</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Taxa de entrada</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Taxa de saída</TableCell>
                                        <TableCell className={classes.tableTh} align="center">Unidade</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quotaHistoricTaxs.map((row) => (
                                        <TableRow hover
                                            className={classes.quotaRow}>
                                            <TableCell align="center">{row.cobra_taxa_performance}</TableCell>
                                            <TableCell align="center">{moment.utc(row.data_atualizacao).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{moment.utc(row.data_inicio_vigencia_taxa_adm).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell align="center">{row.taxa_composta}</TableCell>
                                            <TableCell align="center">{row.taxa_entrada}</TableCell>
                                            <TableCell align="center">{row.taxa_saida}</TableCell>
                                            <TableCell align="center">{row.unidade}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <br />

                        <Typography variant={'body1'}>
                            {'Movimentação'}
                        </Typography>


                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">

                                <TableBody>
                                    {
                                        quotaMovimentation ? renderQuotaMovimentation(quotaMovimentation) : null
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <br />
                        <Typography variant={'body1'}>
                            {'Prestadores'}
                        </Typography>


                        <Paper className={classes.paperForm}></Paper>
                        <br />
                        <Typography variant={'body1'}>
                            {'Série Histórica'}
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">

                                <TableBody>
                                    {
                                        quotaHistoricSerie ? renderQuotaHistoricSerie(quotaHistoricSerie) : null
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <br />
                        <Typography variant={'body1'}>
                            {'Taxas'}
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">

                                <TableBody>
                                    {
                                        quotaTaxs ? renderQuotaTax(quotaTaxs) : null
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>

                        <br />


                    </form> : null
            }

        </div >
    );
}



