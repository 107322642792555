import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import { getAdvisorOrAdminByNameOrCPF, getAdvisorOrAdminByNameOrCPFByConsulting, listAllAdvisorsAndAdmins, listAllAdvisorsAndAdminsByConsulting, updateStatus } from '../../../API/user'

import { applyMaskPhone, getConsultingId } from '../../utils/utils'
import { useDispatch, useSelector } from "react-redux";

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme';

import { isEmpty } from "lodash";
import allActions from "../../../actions";
import CPF_AND_NAME_TEXT from "../../utils/MyHelpers/HelperTexts/SearchBar";
import MyTooltip from '../../utils/MyHelpers/MyTooltip';
import { roles } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(even)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },

        '&:hover': {
            background: unoTheme.hoverTable,
        },
        '& .MuiTableCell-sizeSmall:last-child': {
            paddingRight: '24px',
        }
    },
    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    }
}));

export default function AdvisorsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [appearFab, setAppearFab] = useState(true);
    const [search, setSearch] = useState("");
    let timeoutReference = null;

    const [advisors, setAdvisors] = useState([]);

    const currentUser = useSelector(state => state.currentUser);

    async function getAdvisors() {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando consultores...'
        }));

        let response = null;

        if (isEmpty(search)) {
            //response = await listAllAdvisors();
            //response = await listAllAdvisorsAndAdmins();
            response = await listAllAdvisorsAndAdminsByConsulting(getConsultingId(currentUser));
        } else {
            //response = await getAdvisorOrAdminByNameOrCPF(search);
            response = await getAdvisorOrAdminByNameOrCPFByConsulting(search, getConsultingId(currentUser));
        }

        if (response.success && response.body) {

            const advisorData = response.body.rows;

            if (!isEmpty(advisorData)) {
                setAdvisors(advisorData);
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            } else {
                setAdvisors([]);
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem consultores' }));
            }
        } else {
            console.error("RESPONSE ERROR: ", response.error);
            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        }
    }

    async function updateStatusAdvisor(event, userId, status) {

        event.stopPropagation();

        const response = await updateStatus(userId, status);
        if (response.success) {

            getAdvisors();

        } else {
            console.log("RESPONSE ERROR: ", response.error)
        }

    }

    //form new client
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        getAdvisors();
    }, [search]);


    const delayedChange = (searchInputValue) => {
        timeoutReference = setTimeout(() => {
            setSearch(searchInputValue);
        }, 800)
    }

    function changeSearch(event) {
        let searchInputValue = event.target.value;
        clearTimeout(timeoutReference);
        delayedChange(searchInputValue);
    }

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Consultores
                    </Typography>
                </div>
                <div className={classes.rightHeader}>

                    <TextField id="standard-basic"
                        onChange={(event) => changeSearch(event)}
                        className={classes.inputSearch}
                        label="Nome ou CPF"
                        variant="outlined"
                        size="small" />
                    <MyTooltip
                        hasAlert
                        alertMessage={<CPF_AND_NAME_TEXT searchColumnTitle="nome do consultor" />}
                        tooltipTitle="Como funciona a busca?"
                    />
                </div>

            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    selectable='true'
                    stickyHeader
                    size="small">
                    <colgroup>
                        <col width="55%" />
                        {/* <col width="15%" /> */}
                        <col width="20%" />
                        <col width="15%" />
                        {/* <col width="15%" /> */}
                        <col width="10%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome do consultor</TableCell>
                            {/* <TableCell align="left">CPF</TableCell> */}
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="center">Contato</TableCell>
                            {/* <TableCell align="center">Data cadastro</TableCell> */}
                            <TableCell align="center">Situação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {advisors.map((row) => (
                            <>
                                <TableRow
                                    key={row.id} onClick={() => history.push(
                                        {
                                            pathname: "/administration/newAdvisor",
                                            state: { advisor: row }
                                        }
                                    )}>

                                    <TableCell align="left" component="th" scope="row">{row.name}</TableCell>
                                    {/* <TableCell align="left" >{row.cpf}</TableCell> */}
                                    <TableCell align="left" >{row.email}</TableCell>
                                    <TableCell align="center">{applyMaskPhone(row.phone_number)}</TableCell>
                                    {/* <TableCell align="center">{timeStampToPtDate(row.created_date)}</TableCell> */}
                                    <TableCell align="center">
                                        <IconButton
                                            size={'small'}
                                            onClick={(event) => updateStatusAdvisor(event, row.id, !row.active)}
                                            aria-label="show 4 new mails" color="inherit">
                                            {row.active ?
                                                <CheckCircleOutlineRoundedIcon className={classes.green} />
                                                :
                                                <HighlightOffRoundedIcon className={classes.red} />
                                            }
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </MyTable>
            </div>

            {
                currentUser
                    && currentUser.user
                    && (currentUser.user.role == "ROLE_ADMIN"
                        || currentUser.user.role == roles.superadmin.title) ?
                    <Zoom
                        in={appearFab}
                        unmountOnExit>
                        <Fab className={classes.fabAdd}
                            onClick={() => history.push('/administration/newAdvisor')}
                            color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Zoom>
                    :
                    null
            }

        </div>
    );
}



