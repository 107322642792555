import moment from 'moment'
import { justNumbers, isDateBeforePortfolioClosed, parseFloatByPtCurrency } from '../components/utils/utils'
import { doGetClientBalancesValidations } from './AccountController'

function nullTest(value) {

    if (true
        //value != undefined
        //&& value != "x"
        // && value != ""
        // && value != "-"
        // && value != " "
    ) {

        return value;

    } else {
        return null;
    }
}

/**
 * Normalizando os balancas importados da planilha
 * @param {array} balancesExcel
 * @param {array} assets
 * @param {array} accounts
 * @returns {array} objetos formatados prontos para serem salvos no banco
 */
export function formatBalancesValidatioFromExcel(validations, assets, accounts, year) {

    let toReturn = [];

    // account_id: null
    // asset_id: 1
    // balance: 4545.45
    // balance_validation_id: undefined
    // month_validation: 6
    // year_validation: 2020    

    let months = [
        'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'
    ]

    console.log("///////////////////////////")
    console.log("INICIOOOOOOOOOOOOOOOOOOO")
    console.log("///////////////////////////")
    validations.forEach(excelData => {
        //console.log("ELEMENT VALIDATION: ", excelData)

        //el -> asset
        //excelData -> vindo da planilha do excel                        

        //Caso haja retorno para esse filtro indica que é uma linha do excel referente a um ativo

        //console.log("ASSETS TO COMPARE: ", assets);
        let filteredAssets = assets.filter(el =>
            el.cnpj == excelData.cnpj
            && el.number_account == justNumbers(excelData.cc));

        //console.log("FILTERED ASSETS [" + excelData.cnpj + "][" + justNumbers(excelData.cc) + "]: ", filteredAssets)
        //console.log("FILTERED ASSETS length: ", filteredAssets.length)

        //O ativo foi encontrado
        if (filteredAssets && filteredAssets.length == 1) {

            let assetLoop = filteredAssets[0];
            console.log("ASSET LOOP VALIDATION: ", assetLoop);

            for (let i = 0; i < months.length; i++) {

                //Só inserior os saldos que existem
                //console.log("Tentando incluir: ", months[i]);
                //console.log("ELEMENT VALIDATION: ", excelData)
                //if (nullTest(excelData[months[i] + '_valor']) || (nullTest(excelData[months[i] + '_cota']))) {                

                if (excelData[months[i] + '_valor'] != null) {


                    let quotaAmount = excelData[months[i] + '_cota'];
                    if (typeof quotaAmount === 'string' || quotaAmount instanceof String) {
                        quotaAmount = parseFloatByPtCurrency(quotaAmount);
                    }
                    // it's something else

                    toReturn.push({
                        account_id: null,
                        asset_id: assetLoop.id,
                        balance: excelData[months[i] + '_valor'],
                        quota_amount: quotaAmount,
                        // balance: excelData[months[i] + '_valor'],
                        // quota_amount: excelData[months[i] + '_cota'],

                        // key_valor: months[i] + '_valor',
                        // key_cota: months[i] + '_cota',
                        balance_validation_id: undefined,
                        month_validation: (i + 1),
                        year_validation: year,
                        assetInit: assetLoop.asset_init,
                    });

                }
                // }


            }

        } else if (excelData.cc) { //Caso não seja um ativo pode ser que seja uma conta
            //Verifica-se a que conta se refere
            let filteredAccounts = accounts.filter(el =>
                el.number_account == justNumbers(excelData.cc) && el.cod == excelData.cnpj);

            if (filteredAccounts.length >= 2) {
                console.log('filteredAccounts: ', filteredAccounts);
            }

            //A conta foi encontrada
            if (filteredAccounts && filteredAccounts.length == 1) {
                let accountLoop = filteredAccounts[0];

                for (let i = 0; i < months.length; i++) {

                    // if (nullTest(excelData[months[i] + '_valor'])) {
                    toReturn.push({
                        account_id: accountLoop.id,
                        asset_id: null,
                        balance: excelData[months[i] + '_valor'],
                        quota_amount: null,
                        balance_validation_id: undefined,
                        month_validation: (i + 1),
                        year_validation: year,
                        accountInit: accountLoop.account_init,
                    });

                    //console.log("Incluido saldo CONTA: ", toReturn[toReturn.length - 1]);
                    // }

                }
            }
        }


    });

    console.log("TO RETURN FORMAT VALIDATIONS: ", toReturn);

    return toReturn;
}

/**
 * Filtrando as validações novas ou atualizadas para evitar salvar dois saldos repetidos
 * Caso seja um novo será inserido, caso os valores sejam diferentes é feito o update
 * @param {array} validations
 * @param {array} assetsBalances
 * @returns {array} objetos formatados prontos para serem salvos no banco
 */
export async function compareValidationsToInsertOrUpdate(
    validations,
    assetsBalances, selectedYear, clientId, portfolioClosed) {

    console.log("validations: ", validations);
    console.log("assetsBalances: ", assetsBalances);

    let oldValidations = [];
    //Validações para meses que já foram lançados
    let invalidValidations = [];
    let newValidations = [];

    //loop de todas as validações de ativos formatadas
    validations.filter(el => el.asset_id).forEach(validationLoop => {


        let isNewValidation = true;
        //loop nos obejtos assets, que possuem objetos de meses instanciados de 1 a 12
        Object.entries(assetsBalances).map(([key, row], index) => {


            //loop nos objets de cada asset 1 a 12, se compara a validação para ver se já existe nesse mês do cliente
            for (let i = 1; i <= 12; i++) {

                let assetValidationLoop = row[i];

                if (assetValidationLoop.asset_id == validationLoop.asset_id
                    && assetValidationLoop.month_validation
                    && assetValidationLoop.year_validation) { //garantir que seja um registro que possui validação, pois podem ser objetos vazios {}

                    if (assetValidationLoop.month_validation == validationLoop.month_validation
                        && assetValidationLoop.year_validation == validationLoop.year_validation) {

                        isNewValidation = false;

                    }
                }

            }

        })

        if (isNewValidation) {

            if (validationLoop.balance != null || validationLoop.quota_amount != null) {

                const dateToCompare = '01/' + validationLoop.month_validation.toString().padStart(2, '0') + '/' + validationLoop.year_validation;
                if ((portfolioClosed
                    //Verificando se data é posterior ao fechamento da carteira
                    && isDateBeforePortfolioClosed(dateToCompare, portfolioClosed))
                    //Verificando se data é posterior ao inicio do ativo
                    || isDateBeforePortfolioClosed(dateToCompare, moment.utc(validationLoop.assetInit).format('MM/YYYY'))) {
                    invalidValidations.push(validationLoop);
                } else {
                    newValidations.push(validationLoop);
                }

            }

        } else {

            oldValidations.push(validationLoop);

        }

    });

    let formattedAccounts = await doGetClientBalancesValidations(selectedYear, clientId);

    //loop de todas as validações de contas formatadas
    validations.filter(el => el.account_id).forEach(validationLoop => {


        let isNewValidation = true;
        //loop nos obejtos assets, que possuem objetos de meses instanciados de 1 a 12
        Object.entries(formattedAccounts['accounts']).map(([key, row], index) => {


            //loop nos objets de cada asset 1 a 12, se compara a validação para ver se já existe nesse mês do cliente
            for (let i = 1; i <= 12; i++) {

                let accountValidationLoop = row[i];

                if (accountValidationLoop.account_id == validationLoop.account_id
                    && accountValidationLoop.month_validation
                    && accountValidationLoop.year_validation) { //garantir que seja um registro que possui validação, pois podem ser objetos vazios {}

                    if (accountValidationLoop.month_validation == validationLoop.month_validation
                        && accountValidationLoop.year_validation == validationLoop.year_validation) {

                        isNewValidation = false;

                    }
                }

            }

        })

        if (isNewValidation) {

            if (validationLoop.balance != null || validationLoop.quota_amount != null) {

                const dateToCompare = '01/' + validationLoop.month_validation.toString().padStart(2, '0') + '/' + validationLoop.year_validation;
                if ((portfolioClosed && isDateBeforePortfolioClosed(dateToCompare, portfolioClosed))
                    || isDateBeforePortfolioClosed(dateToCompare, moment.utc(validationLoop.accountInit).format('MM/YYYY'))) {
                    invalidValidations.push(validationLoop);
                } else {
                    newValidations.push(validationLoop);
                }

            }

        } else {

            oldValidations.push(validationLoop);

        }

    });

    console.log("oldValidations: ", oldValidations);
    console.log("newValidations: ", newValidations);
    console.log("invalidValidations: ", invalidValidations);

    return newValidations;
}