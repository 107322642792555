import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import MyTable from '../../utils/MyTable';




import { useDispatch } from "react-redux";
import allActions from "../../../actions";



import { headerSearch, unoTheme } from '../../../assets/styles/unoTheme';

import { getClientByAccountNumber } from "../../../API/account";
import { codsInnerLoad } from "../../utils/MyInnerLoad";
import MyTextField from "../../utils/MyTextField";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    }
}));

export default function SearchAccountsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [appearFab, setAppearFab] = useState(true);

    const [clientByAccount, setClientByAccount] = useState([]);

    async function doGetClientByAccount(accountNumber) {
        if (accountNumber === "") {
            setClientByAccount([])
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Aguardando entrada...",
                cod: codsInnerLoad.searchAccounts
            }));
            return
        }
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando retornos...',
            cod: codsInnerLoad.searchAccounts
        }));
        let response = await getClientByAccountNumber(accountNumber)
        if (response.success) {
            if (response.body.rowCount === 0) {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: 'Essa conta não existe no sistema!',
                }));
                setClientByAccount([])
            }
            else {
                console.log('response: ', response)
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: false,
                    cod: codsInnerLoad.searchAccounts
                }));
                setClientByAccount(response.body.rows)
            }

        }
        else {
            console.error('Erro na consulta!')
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Houve um problema na consulta!",
                cod: codsInnerLoad.searchAccounts
            }));
        }
    }

    useEffect(() => {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            emptyMessage: "Aguardando entrada...",
            cod: codsInnerLoad.searchAccounts
        }));
    }, []);

    //Controles do input SEARCH
    const [searchText, setSearchText] = useState("");

    //Referência para que se possa cancelar o Timeout em caso de nova entrada
    let timeoutObj = null;

    const delayedChange = (text) => {

        timeoutObj = setTimeout(() => {
            console.log("You typed: " + text)
            doGetClientByAccount(text)
        }, 800)
    }

    function changeSearch(value) {

        console.log("E: ", value);
        console.log("SearchText: ", value.toUpperCase())
        clearTimeout(timeoutObj);
        delayedChange(value)
    }

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <div className={classes.leftHeaderDetail}>

                    <Typography variant="h6" gutterBottom align="left">
                        Cliente por Conta
                    </Typography>
                </div>
                <div className={classes.rightHeader}>

                    <MyTextField
                        id="standard-basic"
                        className={classes.inputSearch}
                        onChange={(value) => changeSearch(value)}
                        type="number"
                        label="Buscar..."
                        variant="outlined"
                        size="small" />
                </div>

            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    onScroll={(value) => setAppearFab(value)}
                    stickyHeader
                    size="small"
                    selectable='false'>
                    <colgroup>
                        <col width="45%" />
                        <col width="25%" />
                        <col width="15%" />
                        <col width="15%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Cliente</TableCell>
                            <TableCell align="left">Município - UF</TableCell>
                            <TableCell align="left">Conta</TableCell>
                            <TableCell align="left">Agência</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientByAccount.map((row, index) => (
                            <TableRow key={'account-by-client' + index}>
                                <TableCell align="left">{row.client_name}</TableCell>
                                <TableCell align="left">{row.client_city_state}</TableCell>
                                <TableCell align="left">{row.client_account_number}</TableCell>
                                <TableCell align="left">{row.client_agency ? row.client_agency : "----------"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MyTable>
            </div>
        </div>
    );
}
