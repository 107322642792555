import _ from 'lodash';
import moment from 'moment';
import {
    listClientAccountsWithBalancesByDateAndRegime
} from '../API/account';
import {
    getClientLastQuota
} from '../API/client';
import {
    getClientAllAssetsByDateAndRegime,
    getClientAssetByCnpjAndAccountId,
    getClientAssetByCnpjAndNumberAccount,
    getClientAssetsInitPortfolio
} from '../API/fund';
import {
    getAmortizationsByTitleAssetsIds,
    getClientAllAssetsTitleByDateAndRegime
} from '../API/title';
import {
    getClientAllTransactionByDateRangeAndRegime,
} from '../API/transaction';
//import { cotas } from './testeCotas2'
import { checkIsSameBalance, checkIsSameQuotas, formatAnbimaDateToPt, getInClauseByAttr, getLastDayInMonthByMonthAndYear, removeWholeRescuesAssets, removeWholeRescuesAssetsTitles } from '../components/utils/utils';
import { doGetDisponibilityAccountsByPeriod } from './AccountController';
import { formatFirstRentToSave } from './ClientController';
import { getCDIAndSave, getCotasAndSaveByListAssets, getIGPMAndSave, getInfosTitlesAndSaveByListCodTitles } from './ComdinheiroController';
import { calculateFundRentsInPortfolio } from './FundController';
import { getLocalInfosByListFunds } from './FundLocalQuotasController';
import { calculateRentTitles, groupAssetTitles, isGenericTitle, organizeHistoricAmortizationsInAssetsTitles } from './TitleController';


//const MODELO_COTIZACAO = 'NEW_PL';
const MODELO_COTIZACAO = 'PL';
const REMOVER_TITLES_COTIZACAO = false;

const CPJS_TO_REMOVE_TEST = [];
//const CPJS_TO_REMOVE_TEST = ['11.328.882/0001-35'];
//['15.154.441/0001-15'];

export const NO_INIT_QUOTA = "NO_INIT_QUOTA";

function addAlert(assetLoop, alert) {

    if (!assetLoop.alerts) {
        assetLoop.alerts = [];
    }
    assetLoop.alerts.push(alert);
}

/**
 * Modulando operações de Pl e Rentabilidade
 * @param {obj} Asset ou SubAsset
 * @returns {obj} objFormatado
 */
function operationAssetPlRent(assetLoop, quotas) {

    assetLoop.rent = {};
    assetLoop.rentPercent = {};

    //Inicializando soma das cotas para cada tipo de operação
    //Essa informação é exibida no detalhamento da coleta de saldos
    //Demanda Patrick para facilitação do DAIR
    assetLoop.sumQuotasApplication = 0.0;
    assetLoop.sumQuotasRescues = 0.0;


    //assetLoop.totalPeriodRent = 0;

    //Faz necessário o preenchimento da quantidade de cotas na tela de Ativos e Contas
    assetLoop.qtd_quota = assetLoop.quota_amount_before < 0.0001 ? 0 : assetLoop.quota_amount_before;
    assetLoop.quota_amount_before = parseFloat(assetLoop.quota_amount_before);

    if (assetLoop.qtd_quota == null) { //Quando não foi informada a quantidade de cotas do mês anterior do Ativo na tela de Ativos e Contas
        assetLoop.noBeforeQtdQuota = true;
    }

    assetLoop.transactionMap = {};

    //diaryPortfolioPl
    //Objeto que guardo os PL dia a dia, mapa dia > Pl

    //#### RENTABILIDADE DA CARTEIRA 1 #####
    assetLoop.diaryPortfolioPl = {};

    //PL inicializado com o saldo anterior validado
    assetLoop.pl = parseFloat(assetLoop.balance_before);

    assetLoop.sumAmortizationAmount = 0;

    for (let i = 0; i < quotas.length; i++) {

        let amortizationAmount = 0.0;

        let quotaLoop = quotas[i];

        const amountQuota = quotaLoop['valor_cota'];
        const dateQuota = quotaLoop['data_referencia'];

        //Verificando se há transações para esse subAsset
        //Filtrando as transações para o dia dessa cota;

        //console.log("TRANSACAÇÕES DIA [" + dateQuota + "]: ", listTransactionsInDay);
        //Calculando a resultante do dia APLICAÇÕES - RESGATES                        
        let resultTransactionAmount = 0;

        //Caso não tenha havido aplicação nem resgate apenas repete-se a quantidade de cotas que já se obtinha 

        //O pl deve ser calculadoa assim pois para cálculo de cota da carteira não se deve por exemplo considerar o valor de uma aplicação
        assetLoop.pl_pre_app_rescues = parseFloat(assetLoop.qtd_quota) * amountQuota;

        //Calculando quotas a serem somadas a partir do resultado das movimentações
        if (assetLoop.transactions) {

            //let listTransactionsInDay = assetLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota);
            let listTransactionsInDay = assetLoop.transactions.filter(el => moment.utc(el.transaction_date).format('YYYY-MM-DD') == dateQuota
                //&& el.operation_id !== 3//Amortização não é considerada para o saldo e nem para a rentabilidade 
            );

            //Utilizado para fazer a rentabilidade do fundo na carteira
            let applicationsAmount = 0.0;
            let rescuesAmount = 0.0;

            if (listTransactionsInDay) {

                listTransactionsInDay.forEach(transactionLoop => {

                    if (transactionLoop.operation_id == 1) { //Aplicação

                        resultTransactionAmount += parseFloat(transactionLoop.amount);

                        //Obtendo quantidade de quotas da movimentação
                        transactionLoop.result_qtd_quotas = transactionLoop.amount / amountQuota;
                        assetLoop.sumQuotasApplication += transactionLoop.result_qtd_quotas;

                        applicationsAmount += parseFloat(transactionLoop.amount);

                    } else if (transactionLoop.operation_id == 2) {//Resgate

                        resultTransactionAmount -= parseFloat(transactionLoop.amount);

                        //Obtendo quantidade de quotas da movimentação
                        transactionLoop.result_qtd_quotas = transactionLoop.amount / amountQuota;
                        assetLoop.sumQuotasRescues += transactionLoop.result_qtd_quotas;

                        rescuesAmount += parseFloat(transactionLoop.amount);

                    } else if (transactionLoop.operation_id == 3) {//Amortização

                        //Amortização não é considerada para o saldo e nem para a rentabilidade
                        //Obtendo quantidade de quotas da movimentação
                        //transactionLoop.result_qtd_quotas = transactionLoop.amount / amountQuota;
                        amortizationAmount += parseFloat(transactionLoop.amount);
                        assetLoop.sumAmortizationAmount += amortizationAmount;
                    }
                });

                //Quando há aplicação ou resgate converte-se o valor a cotas e soma-se as cotas anteriores 
                let qtdQuotasPreTransactionsDay = assetLoop.qtd_quota;

                if (parseFloat(amountQuota) != 0) {
                    assetLoop.qtd_quota = (resultTransactionAmount / amountQuota) + parseFloat(assetLoop.qtd_quota);
                }

                if (amountQuota == 0) {
                    addAlert(assetLoop, "Cota do dia " + dateQuota + " igual a 0");
                }

            }

        }

        let oldPl = assetLoop.pl;

        //Cálculo da rentabilidade no dia 

        //1. Calculando o PL do dia com a quantidade de cotas multiplicado pelo valor atual da quota

        // console.log('assetLoop.qtd_quota: ', assetLoop.qtd_quota);
        // console.log('amountQuota: ', amountQuota);
        if (parseFloat(amountQuota) != 0) {
            assetLoop.pl = (parseFloat(assetLoop.qtd_quota) * parseFloat(amountQuota));
        }


        //#### RENTABILIDADE DA CARTEIRA 2 #####
        assetLoop.diaryPortfolioPl[dateQuota] = {

            pl: assetLoop.pl_pre_app_rescues + amortizationAmount, //PL do dia sem considerar os rendimentos das movimentações
            //pl: assetLoop.pl_pre_app_rescues, //PL do dia sem considerar os rendimentos das movimentações
            //Para rentabilidade bater nos 0,70 em Natal adicionar amortização no pl acima
            newPl: assetLoop.pl,
            //newPl: assetLoop.pl,
            result_app_rescues: resultTransactionAmount - amortizationAmount,
            theAmortization: amortizationAmount//Adicionando tratamento para amortização

        };

        //2. A rentabilidade do dia é o PL do dia calculado na linha anterior, menos o pl anterior, menos a resultante das movimentações (pois foram valores retirados e/ou acrescentados diretamente)       

        let dayRent = assetLoop.pl - oldPl - resultTransactionAmount; //Guardando quanto rendeu no dia;


        assetLoop.rent[dateQuota] = dayRent;

        if (oldPl !== 0) {

            assetLoop.rentPercent[dateQuota] = (dayRent / oldPl) * 100;

        } else {

            assetLoop.rentPercent[dateQuota] = 0;

        }

        //Guardando o valor resultante das operações do dia para futuro calculo de rentabilidade da CARTEIRA
        assetLoop.transactionMap[dateQuota] = {
            pl: assetLoop.pl,
            resultTransactionAmount: resultTransactionAmount,
        }

        //3. Em um terceiro momento, soma-se os objetos 'rent' de cada subAtivo, por dia e depois soma-se todos os dias do mês para rendimento total do ativo

        //CONSOLE IMPORTANT
        // console.log("[" + dateQuota + "] > " + "ValorCota: " + amountQuota + " / " + "AppResg: "
        //     + resultTransactionAmount + " / " + "qtd_quota: " + assetLoop.qtd_quota
        //     + " / " + "PL: " + assetLoop.pl + " / " + "RENT: " + assetLoop.rent[dateQuota]);

    }


    assetLoop.qtd_quotas_sensibilized_final = parseFloat(assetLoop.qtd_quota);

    if (assetLoop.is_poupanca) {

        console.log('assetLoop: ', assetLoop);
        console.log('quotas: ', quotas);

        const lastQuotaDay = quotas[quotas.length - 1]['data_referencia'];
        assetLoop.diaryPortfolioPl[lastQuotaDay].newPl = assetLoop.balance_now;
        assetLoop.pl = assetLoop.balance_now;
        assetLoop.qtd_quotas_sensibilized_final = assetLoop.quota_amount_now;

    }

}

//Normalizando as cotas de um fundo que não possui cotas na anbima Ex: FP2 MULTIESTRATÉGIA FIP
function normalizeSpecialAsset(asset, model) {

    console.log("MODEL: ", model);
    //Rentabilidade aplixada apenas no último dia, para simular toda a rentabilidade
    //e não interferir na rentabilidade da carteira    
    let lastKey = null;
    Object.entries(model).map(([key, row]) => {
        row.pl = asset.balance_before;
        row.newPl = asset.balance_before;
        row.result_app_rescues = 0;

        lastKey = key;
    })

    //Aplicando variação no último dia de cota
    model[lastKey].pl = asset.balance_before;
    model[lastKey].newPl = asset.balance_now;

    asset.diaryPortfolioPl = _.cloneDeep(model);

    console.log("Normalized special fund: ", asset.diaryPortfolioPl);

    let averageQuota = null;

    asset.pl = asset.balance_before;
    //Faz necessário o preenchimento da quantidade de cotas na tela de Ativos e Contas
    asset.qtd_quota = asset.quota_amount_before;

    if (!asset.average_quota_before) {

        if (asset.qtd_quota != 0) {

            averageQuota = asset.pl / asset.qtd_quota;

        } else {

            averageQuota = 0;

        }

    } else {

        averageQuota = asset.average_quota_before;

    }

    //Array que vai guardar as quantidades de quotas que serão utilizadas no cálculo da cota média
    let arrayToAverageQuotas = [];
    arrayToAverageQuotas.push({
        qtdQuotas: parseFloat(asset.qtd_quota), //Quantidade de cotas inicial
        balanceQuotas: parseFloat(asset.qtd_quota), //Quantidade de cotas inicial
        averageQuota: averageQuota, //Cota média inicial        
    })

    asset.infoAverageQuota = arrayToAverageQuotas[arrayToAverageQuotas.length - 1];
    asset.infoAverageQuota.lastQuota = asset.balance_now / asset.qtd_quota;

}

function clearQuotasModel(quotasModel) {

    const quotasModelClone = _.cloneDeep(quotasModel);
    Object.entries(quotasModelClone).map(([key, row]) => {
        row.valor_cota = 1;
        row.patrimonio_liquido = 1;
    });

    console.log('quotasModelClone: ', quotasModelClone);
    return quotasModelClone;

}

function normalizeQuotasSpecialAssets(assets, diaryModel, quotasModel) {

    console.log('quotasModel: ', quotasModel);

    assets.filter(el => el.special).forEach(specialAsset => {

        const specialDiary = _.cloneDeep(diaryModel);
        const daysQuotasNotFound = [];
        const finalQuotasNormalized = [];

        // console.log("ESPECIAL ASSET: ", specialAsset.fund_name);
        // console.log("ESPECIAL ASSET: ", _.cloneDeep(specialAsset));
        // console.log("diaryModel: ", specialDiary);
        // console.log("QUOTA MODEL: ", quotasModel);

        if (!specialAsset.is_poupanca) {

            let quotasToRemove = false;
            specialAsset.quotas_anbima.forEach(quotaAnbima => {

                if (quotaAnbima.valor_cota == null) {
                    quotasToRemove = true;
                    quotaAnbima.remove = true;
                }

                if (specialDiary[quotaAnbima.data_referencia]) {
                    specialDiary[quotaAnbima.data_referencia].match = true;
                }

            });

            if (quotasToRemove) {
                const cleanQuotasAnbima = specialAsset.quotas_anbima.filter(el => el.remove == null || el.remove == undefined || el.remove == false);
                specialAsset.quotas_anbima = [...cleanQuotasAnbima];
            }

            let lastExistenceQuota = null;
            Object.entries(specialDiary).map(([key, row]) => {

                const existenceQuota = specialAsset.quotas_anbima.find(el => el.data_referencia == key
                    //&& el.valor_quota != null
                );
                // console.log('existenceQuota: ', existenceQuota);
                // console.log('lastExistenceQuota: ', existenceQuota);

                if (!lastExistenceQuota && !existenceQuota) {

                    //Obtendo última cota do mês anterior
                    if (specialAsset.quotas_anbima_before && specialAsset.quotas_anbima_before.length >= 1) {
                        finalQuotasNormalized.push({
                            ...specialAsset.quotas_anbima_before[specialAsset.quotas_anbima_before.length - 1],
                            data_referencia: key,
                            replicated: true,
                        });
                    }

                } else {

                    finalQuotasNormalized.push({

                        data_referencia: key,
                        patrimonio_liquido: existenceQuota ? existenceQuota.patrimonio_liquido : lastExistenceQuota.patrimonio_liquido,
                        valor_cota: existenceQuota ? existenceQuota.valor_cota : lastExistenceQuota.valor_cota,
                        valor_cota_default: existenceQuota ? existenceQuota.valor_cota_default : lastExistenceQuota.valor_cota_default,
                        replicated: !existenceQuota ? true : false, //indicando que foi uma cota replicada
                    });
                }



                //Atualizando ultima cota existente
                if (existenceQuota != null) {

                    lastExistenceQuota = existenceQuota;

                }

                if (!row.match) {
                    daysQuotasNotFound.push(key)
                }
            })



            // console.log('daysQuotasNotFound: ', daysQuotasNotFound);
            specialAsset.quotas_anbima = finalQuotasNormalized;

        } else {

            specialAsset.quotas_anbima = clearQuotasModel(quotasModel);
        }

        if (specialAsset.subAssets) {

            specialAsset.subAssets.forEach(subAssetLoop => {

                operationAssetPlRent(subAssetLoop, specialAsset.quotas_anbima);

            });

        } else { //Caso o ativo não tenha subassets faz o cálculo em cima na camada do ativo normalmente

            operationAssetPlRent(specialAsset, specialAsset.quotas_anbima);

        }

        calculateFundRentsInPortfolio(specialAsset);
    });
}



/**
 * Recebe os assets e busca por um modelo de cotas a ser replicado em fundos especiais
 * @param {array} finalAssets
 * @returns {obj} modelo de cotas
 */
function getQuotasModel(finalAssets) {

    if (!finalAssets || finalAssets.length === 0) {
        return {
            diaryPortfolioPlModel: {},
            quotasModel: {}
        }
    }

    let maxKeysAssetModel = -1;
    let maxAssetModel = finalAssets[0];
    finalAssets.forEach(assetLoop => {

        if (assetLoop.diaryPortfolioPl) {

            const countKeys = Object.keys(assetLoop.diaryPortfolioPl).length;
            if (countKeys > maxKeysAssetModel) {
                maxAssetModel = assetLoop;
                maxKeysAssetModel = countKeys;
            }

        }

    });

    const diaryPortfolioPlModel = _.cloneDeep(maxAssetModel.diaryPortfolioPl);
    const quotasModel = _.cloneDeep(maxAssetModel.quotas_anbima);

    return {
        diaryPortfolioPlModel: diaryPortfolioPlModel,
        quotasModel: quotasModel
    }
}

/**
 * Recebendo assets agrupados e formatados para cálculo de rentabilidade (Rendimento pelas quotas diárias)
 * @param {array} formattedAssets
 * @returns {array} ativos formatados com rentabilidade (rent)
 */
function calculatePlRent(finalAssets, allQuotas) {

    console.log("Passando calculatePlRent ####################");
    console.log("ALL QUOTAS: ", allQuotas);
    //Fazendo um loop para correr por todos os ativos formatados    

    //Modelo de cotas do mês de algum fundo que possua cota para ser utilizado no caso de um fundo especial
    // let diaryPortfolioPlModel = null;
    // let quotasModel = null;    

    // if (diaryPortfolioPlModel == null) {
    //     diaryPortfolioPlModel = _.cloneDeep(assetLoop.diaryPortfolioPl);
    //     quotasModel = _.cloneDeep(assetQuotas)
    // }

    finalAssets.forEach(assetLoop => {

        //Inicializando todos os PLs
        assetLoop.pl = 0;
        assetLoop.init_pl = assetLoop.balance_before;
        //console.log("PL do [" + assetLoop.fund_name + "]: " + assetLoop.pl);
        if (assetLoop.subAssets) {
            assetLoop.subAssets.forEach(subAssetLoop => {
                subAssetLoop.pl = 0;
            });
        }

        //console.log("All quotas: ", allQuotas);

        //const assetQuotas = allQuotas[assetLoop.fund_id];
        const assetQuotas = assetLoop.quotas_anbima;
        //Atribuindo cotas ao asset (importante para exibição das cotas na tela de coleta de saldos)            
        //assetLoop.quotas_anbima = assetQuotas;

        //console.log("ASSET QUOTAS PARA CALCULO DE PL: ", assetQuotas);
        // let assetQuotas = [];
        // console.log("ASSET QUOTAS: ", assetQuotas);

        //Caso o asset possua subAssets (quando tem mais de uma conta pro mesmo fundo)
        //O cálculo deve ir em cada subasset e ser calculado pelas transacções dele
        //assetQuotas pode ser 404 quando o fundo não foi encontrado na API (código anbima errado)

        if (!assetLoop.special && !assetLoop.is_poupanca) { //fundo especial é processado no método normalizeQuotasSpecialAssets
            //removendo também ativos de poupança pis não possuem cota

            if (assetQuotas && assetQuotas.length > 0) {

                if (assetLoop.subAssets) {

                    assetLoop.subAssets.forEach(subAssetLoop => {

                        operationAssetPlRent(subAssetLoop, assetQuotas);

                    });

                } else { //Caso o ativo não tenha subassets faz o cálculo em cima na camada do ativo normalmente

                    operationAssetPlRent(assetLoop, assetQuotas);

                }


                //Copiando modelo para possível utilização de referência entre fundos com cotas inconsistentes
                // if (diaryPortfolioPlModel == null) {
                //     diaryPortfolioPlModel = _.cloneDeep(assetLoop.diaryPortfolioPl);
                //     quotasModel = _.cloneDeep(assetQuotas)
                // }

            } else { //Informando que não foram encontradas as quotas desse fundo para esse mês

                console.log("Passando NO QUOTAS ####################");

                assetLoop.noQuotas = true;
                assetLoop.noQuotasCod = assetQuotas;
                //console.log("CALCULANDO SALDO SEM COTAS");
                //No caso de não haver cotas se coloca no pl a soma do saldo anterior mais o resultado das movimentações 'resultAmount'       
                if (assetLoop.subAssets) {

                    assetLoop.subAssets.forEach(subAssetLoop => {

                        //console.log(parseFloat(subAssetLoop.balance_before) + "+" + parseFloat(subAssetLoop.resultAmount));
                        subAssetLoop.pl = parseFloat(subAssetLoop.balance_before) + parseFloat(subAssetLoop.resultAmount);
                    });


                } else {

                    //console.log("PL do [" + assetLoop.fund_name + "]: " + "(" + assetLoop.balance_before + ")" + " + " + "(" + assetLoop.resultAmount + ") = " + (parseFloat(assetLoop.balance_before) + parseFloat(assetLoop.resultAmount)));
                    assetLoop.pl = parseFloat(assetLoop.balance_before) + parseFloat(assetLoop.resultAmount);
                    //console.log("PL: ", assetLoop.pl);                

                }

            }

            //Deve ser feito de forma global, o ativo como um todo com o pacote de transações por dia
            //Ou seja, por cnpj
            calculateFundRentsInPortfolio(assetLoop);
        }


    });

    ///////////////////////////////////
    //Caso em que é um fundo especial//
    ///////////////////////////////////

    const { diaryPortfolioPlModel, quotasModel } = getQuotasModel(finalAssets);

    console.log('diaryPortfolioPlModel: ', diaryPortfolioPlModel);
    console.log('quotasModel: ', quotasModel);
    if (diaryPortfolioPlModel && quotasModel) {

        normalizeQuotasSpecialAssets(finalAssets, diaryPortfolioPlModel, quotasModel);

    }

}

export function organizeAssetsAndTransactions(realAssets, transactions) {

    const toReturnAssets = [];
    //console.log("AGRUPANDO ASSETS: ", realAssets);
    //console.log("COTAS: ", quotas);
    realAssets.forEach(element => {

        element.resultAmount = !element.resultAmount ? 0.0 : element.resultAmount
        element.totalApplication = element.totalApplication == undefined ? 0.0 : element.totalApplication
        element.totalRescue = element.totalRescue == undefined ? 0.0 : element.totalRescue
        element.totalAmortization = element.totalAmortization == undefined ? 0.0 : element.totalAmortization

        element.balance_before = element.balance_before != undefined ? parseFloat(element.balance_before) : null;

        element.balance_now = element.balance_now != undefined ? parseFloat(element.balance_now) : null;

        element.quota_amount_now = element.quota_amount_now != undefined ? parseFloat(element.quota_amount_now) : null;

        element.quota_amount_before = element.quota_amount_before != undefined ? parseFloat(element.quota_amount_before) : null;

        let filtereds = toReturnAssets.filter(el => el.fund_id == element.fund_id);

        //Esse fundo ainda não está na lista
        if (filtereds.length === 0) {

            toReturnAssets.push(_.cloneDeep(element));

        } else {

            //Fundo já está na lista, devem ser somados os saldos
            let fundLoop = filtereds[0];

            //Caso ainda não possua o array subAssets cria-se o fundo e já o inclui-se para depois receber o novo asset identificado com o mesmo cnpj
            if (!fundLoop.subAssets) {

                fundLoop.subAssets = []
                fundLoop.subAssets.push(_.cloneDeep(fundLoop))

            }

            fundLoop.subAssets.push(_.cloneDeep(element))

            //A soma de null com null dá zero então pra evitar parecer que foi informado o saldo zero melhor fazer esses ifs
            if (fundLoop.balance_before != undefined && element.balance_before != undefined) {
                fundLoop.balance_before += element.balance_before
            } else if (fundLoop.balance_before == undefined && element.balance_before != undefined) {
                fundLoop.balance_before = element.balance_before
            } else if (fundLoop.balance_before == undefined && element.balance_before == undefined) {
                fundLoop.balance_before = undefined
            }

            if (fundLoop.balance_now != undefined && element.balance_now != undefined) {
                fundLoop.balance_now += element.balance_now
            } else if (fundLoop.balance_now == undefined && element.balance_now != undefined) {
                fundLoop.balance_now = element.balance_now
            } else if (fundLoop.balance_now == undefined && element.balance_now == undefined) {
                fundLoop.balance_now = undefined
            }

            if (fundLoop.quota_amount_now != undefined && element.quota_amount_now != undefined) {
                fundLoop.quota_amount_now += element.quota_amount_now
            } else if (fundLoop.quota_amount_now == undefined && element.quota_amount_now != undefined) {
                fundLoop.quota_amount_now = element.quota_amount_now
            } else if (fundLoop.quota_amount_now == undefined && element.quota_amount_now == undefined) {
                fundLoop.quota_amount_now = undefined
            }

            if (fundLoop.quota_amount_before != undefined && element.quota_amount_before != undefined) {
                fundLoop.quota_amount_before += element.quota_amount_before
            } else if (fundLoop.quota_amount_before == undefined && element.quota_amount_before != undefined) {
                fundLoop.quota_amount_before = element.quota_amount_before
            } else if (fundLoop.quota_amount_before == undefined && element.quota_amount_before == undefined) {
                fundLoop.quota_amount_before = undefined
            }


        }
    });

    transactions.forEach(element => {


        //Encontrando o asset que vai receber essa movimentação no array transactions
        let filteredAssets = toReturnAssets.filter(el => el.fund_id == element.fund_id);

        if (filteredAssets && filteredAssets.length == 1) {
            let assetLoop = filteredAssets[0];

            if (!assetLoop.pl) {
                assetLoop.pl = 0;
            }

            if (!assetLoop.transactions) {
                assetLoop.transactions = [];
                assetLoop.resultAmount = 0.0;
            }

            if (element.operation_id == 1) { //APLICAÇÃO

                assetLoop.resultAmount = assetLoop.resultAmount + parseFloat(element.amount);
                assetLoop.totalApplication = assetLoop.totalApplication + parseFloat(element.amount);

            } else if (element.operation_id == 2) { //RESGATE

                assetLoop.resultAmount = assetLoop.resultAmount - parseFloat(element.amount);
                assetLoop.totalRescue = assetLoop.totalRescue + parseFloat(element.amount);

            } else if (element.operation_id == 3) { //AMORTIZAÇÃO

                //REALIZAR AMORTIZAÇÃO
                //assetLoop.resultAmount = assetLoop.resultAmount + parseFloat(element.amount);
                assetLoop.totalAmortization = assetLoop.totalAmortization + parseFloat(element.amount);

            }

            //Adicionando transaction ao subAsset caso exista        
            if (assetLoop.subAssets && assetLoop.subAssets.length > 0) {

                let filteredSubAssets = assetLoop.subAssets.filter(el =>
                    el.account_id == element.account_id
                );

                let subAssetLoop = filteredSubAssets[0];

                if (subAssetLoop) {
                    //console.log("subAssetLoop: ", subAssetLoop);

                    if (!subAssetLoop.transactions) {
                        subAssetLoop.transactions = [];
                        subAssetLoop.resultAmount = 0.0;
                    }

                    if (element.operation_id == 1) { //APLICAÇÃO

                        subAssetLoop.resultAmount = subAssetLoop.resultAmount + parseFloat(element.amount);
                        subAssetLoop.totalApplication = subAssetLoop.totalApplication + parseFloat(element.amount);

                    } else if (element.operation_id == 2) { //RESGATE

                        subAssetLoop.resultAmount = subAssetLoop.resultAmount - parseFloat(element.amount);
                        subAssetLoop.totalRescue = subAssetLoop.totalRescue + parseFloat(element.amount);

                    } else if (element.operation_id == 3) { //AMORTIZAÇÃO

                        //REALIZAR AMORTIZAÇÃO
                    }

                    subAssetLoop.transactions.push(element);
                }



            }

            //Adcionando movimentação ao asset
            //console.log("ADICIONANDO: ", element);
            assetLoop.transactions.push(element);
        }

    });

    return toReturnAssets;
}

//Ordenando transações por data
function compareDateTransaction(a, b) {

    if (moment(a.transaction_date).valueOf() < moment(b.transaction_date).valueOf())
        return -1;
    if (moment(a.transaction_date).valueOf() > moment(b.transaction_date).valueOf())
        return 1;
    return 0;
}

//Agrupando por fundo mesmo que sejam de contas diferentes
/**
 * Formatando ativos e transações para tabela
 * @param {array} transactions
 * @param {array} fundAssets
 * @param {array} titleAssets
 * @param {String} startDate
 * @param {String} endDate
 * @param {Boolean} doQuotas
 * @returns {array} ativos formatados e agrupados
 */
export async function formatToAssetsTable(transactions, realAssets, titleAssets, startDate, endDate, doQuotas, keepWholeRescuesAssets) {

    let toReturnAssets = organizeAssetsAndTransactions(realAssets, transactions);
    let offAssets = [];

    if (!keepWholeRescuesAssets) {
        //Removendo ativos que não possuem movimentações e possuem saldo atual 0
        //São os ativos que sofreram resgate total
        const responseRemove = removeWholeRescuesAssets(toReturnAssets);

        //Assets fiiltrados após remove
        toReturnAssets = responseRemove.newAssets;
        //Assets que foram removidos por não possuírem saldos (resgate total)
        offAssets = responseRemove.offAssets;

        console.log("OFF ASSETS: ", offAssets);
    }


    /////////////////////////////////////////////////////////////
    ///OBTENDO COTAS////////////////////////////////////////////
    ///////////////////////////////////////////////////////////

    //Obter cotas dos fundos
    //Obter da base local quando possível, fazer a requisição dos fundos que ainda não possuem as cotas do mês na base local
    //Dentro da obtenção de cotas também se faz a obtenção das informações dos fundos 
    const responseAssetsAndCotas = await getCotasAndSaveByListAssets(toReturnAssets, startDate, endDate, doQuotas);

    console.log("RESPONSE ASSETS AND COTAS: ", responseAssetsAndCotas);
    console.log("titleAssets: ", titleAssets);
    //console.log("responseTitlesAndInfos: ", toReturnTitles);
    const filteredQuotas = responseAssetsAndCotas.cotas;
    toReturnAssets = responseAssetsAndCotas.toReturnAssets;

    //Verificando possíveis cotas inconsistentes
    let invalidQuotas = verifyConsistencyAnbimaQuotas(toReturnAssets);
    calculatePlRent(toReturnAssets, filteredQuotas);

    //OPERANDO TÍTULOS
    const toReturnTitlesAssets = await getInfosTitlesAndSaveByListCodTitles(titleAssets, startDate, endDate);

    //Obtendo Informações de CDI
    const responseCDI = await getCDIAndSave(startDate, endDate);
    const responseIGPM = await getIGPMAndSave(startDate, endDate);

    const toReturnFundsAssets = _.cloneDeep(toReturnAssets);

    //Incluindo ativos de título nos assets gerais
    if (toReturnTitlesAssets && toReturnTitlesAssets.length > 0) {

        toReturnTitlesAssets.forEach(element => {
            element.cdi_month = responseCDI;
            element.igpm_month = responseIGPM;
        });

        //Adicionando TÌTULOS aos ativos de retorno
        transactions.filter(el => el.title_asset_id != null).forEach(transactionTitle => {

            console.log("TO RETURN ASSETS TITLES: ", toReturnTitlesAssets);
            console.log("transactionTitle: ", transactionTitle);

            const filteredAssets = toReturnTitlesAssets.filter(el =>
                el.title_id == transactionTitle.title_id
                && el.id == transactionTitle.title_asset_id
                && el.account_id == transactionTitle.account_id);

            if (filteredAssets.length == 1) {
                console.log("FILTERED TITLE: ", filteredAssets);
                const assetTitle = filteredAssets[0];
                if (!assetTitle.transactions) {
                    assetTitle.transactions = [];
                }

                //Adicionando movimentações aos títulos
                assetTitle.transactions.push(transactionTitle);

            }


        });

        if (toReturnTitlesAssets) {
            calculateRentTitles(toReturnTitlesAssets);
        }

        //Agrupando assets titles de CDB por título/conta/liquidez
        const groupedTitlesAssets = [];
        toReturnTitlesAssets.forEach(element => {

            if (element.sector == 'PRIVADO'
                && element.with_liquidity == true) {
                //Agrupar título
                const titleAlreadyGrouped = groupedTitlesAssets.find(el =>
                    el.title_id == element.title_id &&
                    el.account_id == element.account_id
                );
                console.log('titleAlreadyGrouped: ', titleAlreadyGrouped);
                if (titleAlreadyGrouped) {

                    if (!titleAlreadyGrouped.subAssetsTitles) titleAlreadyGrouped.subAssetsTitles = [];

                    titleAlreadyGrouped.balance_now += element.balance_now
                    titleAlreadyGrouped.balance_before += element.balance_before
                    titleAlreadyGrouped.quota_amount_now += element.quota_amount_now
                    titleAlreadyGrouped.quota_amount_before += element.quota_amount_before
                    titleAlreadyGrouped.pl += element.pl

                    if (element.transactions) {

                        if (!titleAlreadyGrouped.transactions) {

                            titleAlreadyGrouped.transactions = [];
                        }

                        element.transactions.forEach(transaction => {

                            titleAlreadyGrouped.transactions.push(transaction);

                        });

                        titleAlreadyGrouped.transactions.sort(compareDateTransaction);

                    }

                    titleAlreadyGrouped.subAssetsTitles.push(_.cloneDeep(element));

                } else {

                    const newGroupedAssetTitle = _.cloneDeep(element);
                    newGroupedAssetTitle.subAssetsTitles = [];
                    newGroupedAssetTitle.subAssetsTitles.push(_.cloneDeep(element));
                    groupedTitlesAssets.push(newGroupedAssetTitle);

                }

            } else {
                groupedTitlesAssets.push(_.cloneDeep(element));
            }

        });

        const justActiveAssetsTitles = removeWholeRescuesAssetsTitles(groupedTitlesAssets);

        //toReturnAssets.push.apply(toReturnAssets, toReturnTitlesAssets);
        toReturnAssets.push.apply(toReturnAssets, justActiveAssetsTitles);

    }

    //Verificando se o fundo está totalmente conciliado pois pode ser um fundo com várias contas
    //portanto vários assets
    toReturnAssets.forEach(element => {

        if (element.subAssets) {
            //Conciliando conciliações
            let allConc = true;
            let groupConciliaded = false;

            element.subAssets.forEach(elementSegregated => {

                //console.log("PL CONCILIAÇÃO: ", elementSegregated.pl);
                //if (elementSegregated.balance_before + elementSegregated.resultAmount != elementSegregated.balance_now) {
                if (!checkIsSameBalance(elementSegregated.pl, elementSegregated.balance_now)
                    || !checkIsSameQuotas(elementSegregated.quota_amount_now, elementSegregated.qtd_quotas_sensibilized_final)) {

                    //console.log("///////////////////")
                    //console.log(elementSegregated);

                    if (!elementSegregated.balance_now_conc) {
                        //console.log("E não tem balance_conc: ", elementSegregated.balance_now_conc);
                        allConc = false;
                    }

                    if (elementSegregated.balance_now_conc) {
                        //Caso haja pelo menos um asset conciliado, para que exiba-se o label de CONCILIADO
                        element.inProgressConc = true;
                        groupConciliaded = true;
                    }

                }

            });

            if (allConc && groupConciliaded) {
                //if (allConc) {
                element.balance_now_conc = true;

            } else if (!allConc) {

                element.balance_now_conc = false;

            }

        }

        //console.log("Element balance_now: ", element.balance_now);

    });

    // console.log('PRE RETURN: ', {
    //     assets: toReturnAssets,
    //     titles: toReturnTitlesAssets,
    //     invalidQuotas: invalidQuotas
    // });

    return {
        assets: toReturnAssets,
        funds: toReturnFundsAssets,
        titles: toReturnTitlesAssets,
        invalidQuotas: invalidQuotas,
        offAssets: offAssets,
        cdi: responseCDI,
    };

}

function getCleanModelDiary(assets) {

    const modelDiary = assets.find(el => el.modelAnbimaQuotas == true);
    // console.log("ASSETS TO MODEL: ", assets);
    // console.log('modelDiary: ', modelDiary);
    // console.log('modelDiary.diaryPortfolioPl: ', modelDiary.diaryPortfolioPl);

    if (modelDiary && modelDiary.diaryPortfolioPl) {
        const cleanModelDiary = {};
        Object.entries(modelDiary.diaryPortfolioPl).map(([key, row]) => {

            cleanModelDiary[key] = {
                pl: 0,
                newPl: 0,
                result_app_rescues: 0
            }

        })
        return cleanModelDiary;
    } else {

        if (assets[0]) {
            const cleanModelDiary = _.cloneDeep(assets[0].diaryPortfolioPl);
            console.log('cleanModelDiary: ', cleanModelDiary);
            if (cleanModelDiary) {
                Object.entries(cleanModelDiary).map(([key, row]) => {


                    row.pl = 0;
                    row.newPl = 0;
                    row.result_app_rescues = 0;

                })
                return cleanModelDiary;
            } else {
                return {}
            }

        }

    }

    return null;

}

/**
 * Retorna a soma total dos rendimentos a partir dos assets formatados
 * Movimentações
 * PL
 * Rentabilidade
 * Mapa de transações por dia
 * @param {array} assetsFormatted 
 * @param {Object} initPortfolioQuota objeto com as informações de quot da carteira do mês anterior
 * @param {array} doQuotas indica se devem ser consideradas as quotas
 * @returns {double} totalResultAmount
 */
export function sumResultAmountPLRent(assets, initPortfolioQuota, doQuotas) {

    console.log("ASSETS SUM RESULT AMOUNT PL RENT: ", assets);

    let totalResultAmount = 0.0; //SOMA DOS PLS COM RENTABILIDADES DAS COTAS
    let totalBeforeAmount = 0.0; //SOMA DOS SALDOS ANTERIORES
    let totalRentMoneyInMonth = 0.0; //SOMA DAS RENTABILIDADES EM DINHEIRO
    let totalRentMoneyInMonthFunds = 0.0; //SOMA DAS RENTABILIDADES EM DINHEIRO
    let totalRentMoneyInMonthTitles = 0.0; //SOMA DAS RENTABILIDADES EM DINHEIRO
    //let totalRentPercentInMonth = 1; //SOMA DAS RENTABILIDADES EM DINHEIRO
    let totalTransactionMap = undefined; //Objeto com result de transações agrupadas por dia


    //fullDiaryPl:

    let fullDiaryPl = undefined;
    let fullDiaryPlWithTitles = undefined;
    let fullDiaryPlJustFunds = undefined;
    let fullDiaryPlJustTitles = undefined;
    let lastDiaryPortfolioPl = null;

    const rentMonthByFundMap = {};

    const arrayBalancesBefores = [];

    assets.forEach(assetLoop => {

        //Somando PLs e Rentabilidades
        //Se o asset tem subassets deve haver o laço para somar os Pls dos subAssets
        //Caso não tenha subasseets o PL já foi calculado corretamente no método 'calculatePlRent'
        if (assetLoop.subAssets) {

            assetLoop.pl = 0;

            //Quantidade de cotas final
            assetLoop.qtd_quotas_sensibilized_final = 0;

            assetLoop.rent = undefined;

            assetLoop.noBeforeQtdQuota = false;

            //Somando a soma de cotas de cada subasset
            assetLoop.sumQuotasApplication = 0;
            assetLoop.sumQuotasRescues = 0;

            assetLoop.subAssets.forEach(subAssetLoop => {

                arrayBalancesBefores.push(subAssetLoop.balance_before);

                //Grantindo que o asset pai tenha corretamente o valor do noBeforeQuota, que deve ser true se pelo menos um dos subsassets for true
                //Depois que ele vira true deve sempre permanecer true                
                if (!assetLoop.noBeforeQtdQuota) {
                    assetLoop.noBeforeQtdQuota = subAssetLoop.noBeforeQtdQuota;
                }

                //Atribuindo a rentabilidade do próprio subasset
                //subAssetLoop.qtd_quotas_sensibilized_final = parseFloat(subAssetLoop.qtd_quota);

                assetLoop.pl += subAssetLoop.pl;
                assetLoop.qtd_quotas_sensibilized_final += parseFloat(subAssetLoop.qtd_quotas_sensibilized_final);
                assetLoop.sumQuotasApplication += subAssetLoop.sumQuotasApplication;
                assetLoop.sumQuotasRescues += subAssetLoop.sumQuotasRescues;

                //Para o caso da rentabilidade do asset pai ser undefined singnifica que é a primeira vez
                //que passa pelo laço, portanto, recebe o objeto inteiro do primeiro subasset
                //que posteriomente será somado atributo a atributo aos respectivos subassets
                if (doQuotas) {

                    //O diaryPortfolioPl é nulo no assetLoop caso seja um assetLoop com subAssets, nesse modo não foi criado diaryPortfolioPl
                    //para ele, pois apenas os subAssets que foram enviados para o método operationAssetPlRent
                    if (!assetLoop.diaryPortfolioPl) {

                        assetLoop.rent = _.cloneDeep(subAssetLoop.rent);

                        //Para soma de transações por dia
                        assetLoop.transactionMap = _.cloneDeep(subAssetLoop.transactionMap);

                        //Somando os PLs diários
                        assetLoop.diaryPortfolioPl = _.cloneDeep(subAssetLoop.diaryPortfolioPl);



                    } else {

                        if (assetLoop.rent) {
                            Object.entries(assetLoop.rent).map(([key, row]) => {

                                assetLoop.rent[key] += subAssetLoop.rent[key];
                                //assetLoop.diaryPl[key] += subAssetLoop.diaryPl[key];
                                assetLoop.diaryPortfolioPl[key].pl += subAssetLoop.diaryPortfolioPl[key].pl;
                                assetLoop.diaryPortfolioPl[key].newPl += subAssetLoop.diaryPortfolioPl[key].newPl;
                                assetLoop.diaryPortfolioPl[key].result_app_rescues += subAssetLoop.diaryPortfolioPl[key].result_app_rescues;


                                if (assetLoop.transactionMap && subAssetLoop.transactionMap) {

                                    assetLoop.transactionMap[key].pl += subAssetLoop.transactionMap[key].pl;
                                    assetLoop.transactionMap[key].resultTransactionAmount += subAssetLoop.transactionMap[key].resultTransactionAmount;

                                }


                            })
                        }


                    }


                }


            });
        } else {

            //Caso o asset não possua subassets apenas se atribui a quantidade de cotas a varíavel qtd_quotas_final
            if (assetLoop.cnpj == '41.745.796/0001-99') {
                console.log("NOVAMENTE AQUI: ", parseFloat(assetLoop.qtd_quota));
            }

            if (assetLoop.title_id) {
                assetLoop.qtd_quotas_sensibilized_final = parseFloat(assetLoop.qtd_quota);
            }


            arrayBalancesBefores.push(assetLoop.balance_before);


        }

        /////////////////////////////////////
        /////////////////////////////////////
        //SOMA TOTAL DOS SALDOS DOS ATIVOS///
        /////////////////////////////////////
        /////////////////////////////////////

        //console.log("SOMANDO TOTAL: ", assetLoop.balance_now);
        totalResultAmount += assetLoop.balance_now;

        //Somando os saldos anteriores para cálculo de rentabilidade da carteira
        totalBeforeAmount += assetLoop.balance_before;

        //Somando rentabilidade em dinheiro da carteira no Mês
        totalRentMoneyInMonth += assetLoop.rentMoneyInMonth;
        if (assetLoop.title_id) {
            totalRentMoneyInMonthTitles += assetLoop.rentMoneyInMonth;
        } else {
            rentMonthByFundMap[assetLoop.fund_id] = assetLoop.rentMoneyInMonth;
            //console.log("SOMANDO totalRentMoneyInMonthFunds: ", assetLoop.rentMoneyInMonth);
            totalRentMoneyInMonthFunds += assetLoop.rentMoneyInMonth;
            //console.log("resultado: ", totalRentMoneyInMonthFunds);
        }

        //Deve ser somado também as possíveis amortizações do fundo
        // if (assetLoop.totalAmortization) {
        //     console.log("Adicionando amortização: ", assetLoop);
        //     totalRentMoneyInMonth += assetLoop.totalAmortization;
        // }

        /////////////////////////////////////
        /////////////////////////////////////
        //SOMA TOTAL DOS SALDOS DOS ATIVOS///
        /////////////////////////////////////
        /////////////////////////////////////

        //Construindo objeto de result de transações por dia entre os ativos
        if (!totalTransactionMap) {

            totalTransactionMap = _.cloneDeep(assetLoop.transactionMap);

        } else {

            Object.entries(totalTransactionMap).map(([key, row]) => {

                if (assetLoop.transactionMap && assetLoop.transactionMap[key]) {
                    totalTransactionMap[key].pl += assetLoop.transactionMap[key].pl;
                    totalTransactionMap[key].resultTransactionAmount += assetLoop.transactionMap[key].resultTransactionAmount;
                }

            })

        }

    });

    //console.log("MAP XXX: ", JSON.stringify(rentMonthByFundMap))

    //PASSO 2 : Somando rentabilidade em percentual da carteira no mês
    //totalRentPercentInMonth = (totalRentPercentInMonth - 1) * 100;

    //Inicializando fullDiaryPl
    if (doQuotas) {

        fullDiaryPl = getCleanModelDiary(assets);
    }

    //Cacular rent total do mês e aproveitando o loop também calculando a participação pelo PL e o totalResultAmount
    //somando todos os atrivutos do objeto rent 
    assets.forEach(assetLoop => {

        //Caulando PARTICIPAÇÃO (Porcentagem do pl em relação ao total)             

        //Quotas não consideradas a participação é obtido fazendo a conta com o próprio balance_now, o saldo validado
        assetLoop.participation = totalResultAmount !== 0 ? ((assetLoop.balance_now / totalResultAmount) * 100).toFixed(2) : 0;
        //Caso hajam subassets calculando a participação de cada um em relação ao pl total do asset
        if (assetLoop.subAssets) {

            assetLoop.subAssets.forEach(subAssetLoop => {

                //Quotas não consideradas a participação é obtido fazendo a conta com o próprio balance_now, o saldo validado
                subAssetLoop.participation = totalResultAmount !== 0 ? ((parseFloat(subAssetLoop.balance_now) / totalResultAmount) * 100).toFixed(2) : 0;

            });
        }

        //Agrupando os objetos diaryPortfolioPl dos assetsPais no Objeto fullDiaryPl, que será usado para salvar a rentabilidade da cartiera do mês
        //entre os fundos
        if (doQuotas) {

            if (assetLoop.diaryPortfolioPl) {

                if (assetLoop.title_id && REMOVER_TITLES_COTIZACAO) {

                } if (CPJS_TO_REMOVE_TEST.includes(assetLoop.cnpj)) {
                    //removendo CNPJ para ver se influi na carteira
                } else {
                    Object.entries(assetLoop.diaryPortfolioPl).map(([key, row]) => {

                        // console.log("KEY: ", key);
                        // console.log("ROW: ", row);

                        //KEY representa o dia
                        if (fullDiaryPl[key]) {
                            fullDiaryPl[key].pl += row.pl;
                            fullDiaryPl[key].newPl += row.newPl;
                            fullDiaryPl[key].result_app_rescues += row.result_app_rescues;
                        }

                    })
                }

            }

        }


    });

    //Estruturando oobejtos que serão utilizados para cálculo da rentabildiade da carteira
    // date_quota
    // pl
    // result_app_rescues
    // qtd_quotas
    // quota_value    
    /******************************************
    ***CALCULO DA QUOTA DIARIA DA CARTEIRA*****
    *******************************************/

    //Na posse de initPortfolioQuota, que se refere ao objeto da última quota do mês anterior a o que se está requisitando
    //Fazendo as operações necessárias para obtenção do quota_value, add_quotas e qtd_quotas para cada dia de rendimento da carteira
    console.log('CALCULO DA QUOTA DIARIA DA CARTEIRA')
    console.log('initPortfolioQuota: ', initPortfolioQuota)
    console.log('fullDiaryPl ANTES: ', _.cloneDeep(fullDiaryPl))

    if (MODELO_COTIZACAO == 'NEW_PL_2') {
        if (doQuotas && fullDiaryPl && initPortfolioQuota) { //Pode ser que o initPortfolioQuota seja null, caso não seja um mês passível de ser fechado
            Object.entries(fullDiaryPl).map(([key, row]) => {

                //console.log("FUL DIARY ROW: ", row);

                if (!lastDiaryPortfolioPl) { //No caso de ser a montagem do primeiro dia do mês

                    initPortfolioQuota.qtd_quotas = initPortfolioQuota.qtd_quotas ? parseFloat(initPortfolioQuota.qtd_quotas) : 0;
                    initPortfolioQuota.pl = parseFloat(initPortfolioQuota.pl);
                    initPortfolioQuota.newPl = initPortfolioQuota.newPl ? parseFloat(initPortfolioQuota.newPl) : 0;
                    initPortfolioQuota.quota_value = parseFloat(initPortfolioQuota.quota_value)

                    //Para o caso de qtd_quotas zerado ou pl zerado repete-se o valor da cota anterior

                    // PL = (equivale ao patrimonio do início do dia, antes das aplicações) 
                    // /
                    // qtd_quotas = (quantidade de cotas do dia anterior)
                    row.quota_value = initPortfolioQuota.qtd_quotas == 0 || row.newPl == 0 ?
                        initPortfolioQuota.quota_value
                        :
                        row.newPl / initPortfolioQuota.qtd_quotas; //quota diária da carteira                
                    //row.quota_value = row.newPl / initPortfolioQuota.qtd_quotas; //quota diária da carteira
                    row.add_quotas = row.quota_value != 0 ? row.result_app_rescues / row.quota_value : 0; //Cotas adicionadas (ou removidas) da carteira
                    row.qtd_quotas = initPortfolioQuota.qtd_quotas + row.add_quotas;

                    row.rent_day = ((row.quota_value / initPortfolioQuota.quota_value) - 1) * 100;
                    // console.log("row.quota_value: ", row.quota_value);
                    // console.log("initPortfolioQuota: ", initPortfolioQuota);
                    // console.log("initPortfolioQuota.quota_value: ", initPortfolioQuota.quota_value);
                    // console.log("RENT DAY: ", row.rent_day);

                } else {

                    //console.log("lastDiaryPortfolioPl: ", lastDiaryPortfolioPl)

                    //Para o caso de qtd_quotas zerado ou pl zerado repete-se o valor da cota anterior
                    row.quota_value = lastDiaryPortfolioPl.qtd_quotas == 0 || row.newPl == 0 ? lastDiaryPortfolioPl.quota_value : row.newPl / lastDiaryPortfolioPl.qtd_quotas; //quota diária da carteira

                    row.add_quotas = row.quota_value != 0 ? row.result_app_rescues / row.quota_value : 0; //Cotas adicionadas (ou removidas) da carteira
                    row.qtd_quotas = lastDiaryPortfolioPl.qtd_quotas + row.add_quotas;

                    row.rent_day = ((row.quota_value / lastDiaryPortfolioPl.quota_value) - 1) * 100;

                }

                lastDiaryPortfolioPl = row;

            })
        }
    } else {
        if (doQuotas && fullDiaryPl && initPortfolioQuota) { //Pode ser que o initPortfolioQuota seja null, caso não seja um mês passível de ser fechado
            Object.entries(fullDiaryPl).map(([key, row]) => {

                //console.log("FUL DIARY ROW: ", row);

                if (!lastDiaryPortfolioPl) { //No caso de ser a montagem do primeiro dia do mês

                    initPortfolioQuota.qtd_quotas = initPortfolioQuota.qtd_quotas ? parseFloat(initPortfolioQuota.qtd_quotas) : 0;
                    initPortfolioQuota.pl = parseFloat(initPortfolioQuota.pl);
                    initPortfolioQuota.newPl = initPortfolioQuota.newPl ? parseFloat(initPortfolioQuota.newPl) : 0;
                    initPortfolioQuota.quota_value = parseFloat(initPortfolioQuota.quota_value)

                    //Para o caso de qtd_quotas zerado ou pl zerado repete-se o valor da cota anterior

                    // PL = (equivale ao patrimonio do início do dia, antes das aplicações) 
                    // /
                    // qtd_quotas = (quantidade de cotas do dia anterior)
                    row.quota_value = initPortfolioQuota.qtd_quotas == 0 || row.pl == 0 ?
                        initPortfolioQuota.quota_value
                        :
                        row.pl / initPortfolioQuota.qtd_quotas; //quota diária da carteira                
                    row.add_quotas = row.quota_value != 0 ? row.result_app_rescues / row.quota_value : 0; //Cotas adicionadas (ou removidas) da carteira
                    row.qtd_quotas = initPortfolioQuota.qtd_quotas + row.add_quotas;

                    row.rent_day = ((row.quota_value / initPortfolioQuota.quota_value) - 1) * 100;
                    // console.log("row.quota_value: ", row.quota_value);
                    // console.log("initPortfolioQuota: ", initPortfolioQuota);
                    // console.log("initPortfolioQuota.quota_value: ", initPortfolioQuota.quota_value);
                    // console.log("RENT DAY: ", row.rent_day);

                } else {

                    //console.log("lastDiaryPortfolioPl: ", lastDiaryPortfolioPl)

                    //Para o caso de qtd_quotas zerado ou pl zerado repete-se o valor da cota anterior
                    row.quota_value = lastDiaryPortfolioPl.qtd_quotas == 0 || row.pl == 0 ? lastDiaryPortfolioPl.quota_value : row.pl / lastDiaryPortfolioPl.qtd_quotas; //quota diária da carteira

                    row.add_quotas = row.quota_value != 0 ? row.result_app_rescues / row.quota_value : 0; //Cotas adicionadas (ou removidas) da carteira
                    row.qtd_quotas = lastDiaryPortfolioPl.qtd_quotas + row.add_quotas;

                    row.rent_day = ((row.quota_value / lastDiaryPortfolioPl.quota_value) - 1) * 100;

                }

                lastDiaryPortfolioPl = row;

            })
        }
    }



    if (!initPortfolioQuota) {
        fullDiaryPl = NO_INIT_QUOTA
    }

    console.log('fullDiaryPl DEPOIS: ', _.cloneDeep(fullDiaryPl))
    //console.log(JSON.stringify(fullDiaryPl))
    // console.log('fullDiaryPlWithTitles DEPOIS: ', _.cloneDeep(fullDiaryPlWithTitles))
    // console.log('fullDiaryPlJustTitles DEPOIS: ', _.cloneDeep(fullDiaryPlJustTitles))
    //console.log(JSON.stringify(fullDiaryPlWithTitles))


    //SUPER PRINT
    // console.log("LAST QUOTA: ", initPortfolioQuota);
    // console.log("FULL DIARY: ", fullDiaryPl);
    // console.log("FULL DIARY: ", JSON.stringify(fullDiaryPl));
    // console.log("FINAL ASSETS PL RENT: ", assets)
    //console.log("TOTAL RESULT AMOUNT: ", totalResultAmount);

    console.log("totalBeforeAmount: ", totalBeforeAmount);
    console.log("arrayBalancesBefores: ", arrayBalancesBefores.sort((a, b) => a - b));

    return {
        totalResultAmount: totalResultAmount,
        fullDiaryPl: fullDiaryPl,
        totalBeforeAmount: totalBeforeAmount,
        totalRentMoneyInMonth: totalRentMoneyInMonth,
        totalRentMoneyInMonthFunds: totalRentMoneyInMonthFunds,
        totalRentMoneyInMonthTitles: totalRentMoneyInMonthTitles,
        //totalRentPercentInMonth: totalRentPercentInMonth,
        totalTransactionMap: totalTransactionMap,
    }
}

//Somando os saldos das contas
function getTotalDisponibilityInAccounts(accounts) {

    let totalNow = 0;
    let totalBefore = 0;
    if (accounts) {
        accounts.forEach(acc => {
            totalNow += acc.balance_now != null ? parseFloat(acc.balance_now) : 0;
            totalBefore += acc.balance_before != null ? parseFloat(acc.balance_before) : 0;
        });

        return {
            now: totalNow,
            before: totalBefore
        };
    } else {
        return {
            now: 0,
            before: 0,
        };
    }

}

/**
 * Retorna a lista de assets, subassets e suas movimentações
 * @param {int} clientId
 * @param {int} month
 * @param {int} year
 * @param {int} accountRegimeId [Financeiro, previdenciario, taxa adminstrativa] 
 * @param {int} doQuotas indica se o método deve fazer a consulta de quotas na api, não é sempre necessário
 * @param {String} portfolioInit utilizado para a criação da primeira cota da carteira
 * @param {boolean} keepWholeRescuesAssets utilizado para confirmar a condição de manter ou não os fundos totalmente resgatados
 * @param {String} startDatePeriod utilizado para realizar o filtro corretamente de ativos em caso de relatório de período
 * @returns {array} objetos formatados prontos para serem salvos no banco
 */
export async function getAssetInfos(clientId,
    month,
    year,
    accountRegimeId,
    doQuotas, portfolioInit, keepWholeRescuesAssets, startDatePeriod) {

    let initPortfolioQuota = null;

    if (doQuotas) {
        //Obtendo a cota incial a ser usada nos cálculos de rentabilidade da carteira
        // console.log("MONTH INIT COTA: ", month);
        // console.log("YEAR INIT COTA: ", year);
        const responseLastQuota = await getClientLastQuota(clientId, month, year);

        console.log("RESPONSE LAST QUOTA: ", responseLastQuota);
        if (responseLastQuota.success) {

            //Caso seja um array contém a última cota
            if (Array.isArray(responseLastQuota.body)) {

                initPortfolioQuota = responseLastQuota.body[0];
            } else {

                //Mas pdoe ser no formato
                //{message: "HAS_COTA"} que indica que não possui a última cota do mês mas apenas por conta do último mês
                //não ser o mês de fechamento da carteira
                //o init portfolioquota se mantem null
                initPortfolioQuota = null;

            }
            console.log("Init Portfolio Quota: ", initPortfolioQuota);

        } else {

            //Existe a possibilidade da quota não ser encontrada portanto deve-se criar a quota,
            //Geralmente se refere a quota inicial de abertura de carteira
            //Deve partir dos saldos iniciais do cliente
            console.log("Quota inicial não encontrada");

            //Obter mês de início da carteira

            if (portfolioInit) {

                console.log("portfolioInit ", portfolioInit);

                const initPortfolioMonth = parseInt(moment.utc(portfolioInit).format('MM'));
                const initPortfolioYear = parseInt(moment.utc(portfolioInit).format('YYYY'));

                let responseAssets = await getClientAssetsInitPortfolio(clientId,
                    initPortfolioMonth, initPortfolioYear,
                    accountRegimeId);

                if (responseAssets.success
                    && responseAssets.body
                    && responseAssets.body.rows
                    && responseAssets.body.rows.length > 0) {

                    const realAssets = responseAssets.body.rows;
                    //let responseRent = await saveClientRent(formatFirstRentToSave(clientId, realAssets, lastDay, agoMonth, agoYear));

                    console.log("ASSETS IN INIT PORTFOLIO: ", realAssets);

                    const lastDay = getLastDayInMonthByMonthAndYear(initPortfolioMonth, initPortfolioYear);

                    const initPortfolioQuotaToSave = formatFirstRentToSave(clientId, realAssets, lastDay, initPortfolioMonth, initPortfolioYear);

                    //Agora deixando para salvar a cota apenas no primeiro lançamento

                    // const responseRent = await saveClientRent(initPortfolioQuotaToSave);
                    // const responseLastQuota = await getClientLastQuota(clientId, month, year);
                    // if (responseLastQuota.success) {

                    //     initPortfolioQuota = responseLastQuota.body[0];
                    //     console.log("Init Portfolio Quota: ", initPortfolioQuota);

                    // }
                    //console.log("RESPONSE RENT: ", responseRent);
                    initPortfolioQuota = initPortfolioQuotaToSave;

                } else {
                    console.log("Ainda sem ativos para criar rentabilidade inicial");
                }

            }



        }
    }

    //const responseAccounts = await listClientAccountsByRegime(clientId, accountRegimeId);
    const responseAccounts = await listClientAccountsWithBalancesByDateAndRegime(clientId, accountRegimeId, month, year);

    if (responseAccounts.success) {

        if (month && year) {


            var firstDay = new Date(year, parseInt(month) - 1, 1);
            var lastDay = new Date(year, parseInt(month), 0);

            //Verificando se existem contaas
            if (firstDay && lastDay) {

                let responseFormattedAssets = await getTransactionsByDateRange(
                    clientId,
                    //moment.utc(firstDay).format("DD/MM/YYYY"),
                    moment(firstDay).format("DD/MM/YYYY"),
                    //moment.utc(lastDay).format("DD/MM/YYYY"),
                    moment(lastDay).format("DD/MM/YYYY"),
                    accountRegimeId,
                    initPortfolioQuota, doQuotas, keepWholeRescuesAssets, startDatePeriod)

                if (responseFormattedAssets.success) {

                    return {
                        ...responseFormattedAssets,
                        accounts: responseAccounts.body.rows,
                        initPortfolioQuota: initPortfolioQuota,
                        //Valor dos saldos nas contas DISPONIBILIDADE
                        totalDisponibilityInAccounts: getTotalDisponibilityInAccounts(responseAccounts.body.rows),
                    }

                } else {

                    return {
                        ...responseFormattedAssets,
                    }

                }
            }
        } else {

            //Caso não haja month e year (Ainda não foi feito o cadastro de nenhuma transação)
            return {

                success: true,
                accounts: responseAccounts.body.rows,
                assetsFormatted: [],
                transactions: [],
                totalResultAmount: 0
            }
        }

    } else {

        console.log("error: ", responseAccounts.error)
        return {
            success: false,
            message: 'Falha ao obter contas do cliente',
        }

    }


}

function verifyConsistencyAnbimaQuotas(assets) {

    const filteredQuotas = {};

    //montando filteredQuotas
    assets.forEach(element => {
        filteredQuotas[element.fund_id] = element.quotas_anbima;
    });

    //console.log("FILTERED COTAS assets: ", assets);
    //console.log("FILTERED COTAS verifyConsistencyAnbimaQuotas: ", filteredQuotas);

    //Mapa que guarda os inicios dos ativos para
    //Servirá para verificar se a cota invalida na verdade não é devido ser uma data anterior ao inicio da cotização do fundo
    const initsEndsAssets = {};
    assets.forEach(asset => {

        if (!initsEndsAssets[asset.fund_id]) {

            initsEndsAssets[asset.fund_id] = {};
            initsEndsAssets[asset.fund_id].init = asset.uno_date_quota_init;
            initsEndsAssets[asset.fund_id].end = asset.uno_date_quota_end;

        }


    });

    let invalidQuotas = null;
    let maxLength = null;

    //console.log("OBJECT KEYS: ", Object.keys(filteredQuotas).length);

    //Obtendo o tamanho máximo de cotas, tentando achar um fundo que tenha as cotas de todos os dias do mês
    let keyMaxLenght = null;
    Object.entries(filteredQuotas).map(([key, row]) => {

        if (row && row.length > 0) {
            if (row.length > maxLength) {
                maxLength = row.length;
                keyMaxLenght = key;
            }

        }

    })

    console.log("MAX LENGHT: ", keyMaxLenght);
    const modelFund = assets.find(el => el.fund_id == keyMaxLenght);
    if (modelFund) {
        modelFund.modelAnbimaQuotas = true; //sinalizando que esse é o modelo de cotas a ser seguido
    }


    //Obtendo modelo com todos os dias do mês do de um fundo consistente
    let model = null;
    Object.entries(filteredQuotas).map(([key, row]) => {

        //console.log("ROW HGT: ", row);

        if (row && row.length == maxLength && model == null) {
            model = _.cloneDeep(row);
        }

        if (row && (row.length < maxLength || row.lenght == 0)) {

            console.log("ENTRANDO INVALID: ", key);

            if (!invalidQuotas) {
                invalidQuotas = {};
            }

            invalidQuotas[key] = {
                invalids: [],
                allQuotas: row,
            }

        }

    })

    console.log("MODEL: ", model);
    console.log("Invalid Cotas: ", invalidQuotas);

    //let realInvalidQuotas = null;
    if (invalidQuotas) {
        Object.entries(invalidQuotas).map(([key, row]) => {

            if (model) {
                for (let q = 0; q < model.length; q++) {

                    let haveData = false;

                    for (let r = 0; r < row.allQuotas.length; r++) {

                        //console.log(model[q].data_referencia + "==" + row.allQuotas[r].data_referencia);
                        //Testa a data para cada uma das sequencias de datas dos demais modelos
                        //Ex: 02/02/2021 e sai testando para todas a lista de cotas 
                        //Caso exista essa data OK, caso não exista posteriormente é intro
                        if (model[q].data_referencia == row.allQuotas[r].data_referencia) {
                            haveData = true;
                        }

                    }

                    if (!haveData) {

                        //Adicionando verificação de inicio de fundo
                        // console.log("model[q].data_referencia: ", model[q].data_referencia);
                        // console.log("initFund: ", initsAssets[key]);
                        // console.log("TESTE: ", moment(model[q].data_referencia).isAfter(initsAssets[key]))

                        if (
                            (
                                initsEndsAssets[key].init == null
                                || moment.utc(model[q].data_referencia).isSameOrAfter(initsEndsAssets[key].init))
                            &&
                            (

                                initsEndsAssets[key].end == null
                                ||
                                moment.utc(model[q].data_referencia).isSameOrBefore(initsEndsAssets[key].end)
                            )) {

                            invalidQuotas[key].invalids.push(formatAnbimaDateToPt(model[q].data_referencia));
                        }
                    }

                }
            }

            //Atribuindo remove invalid caso os dias inválidos tenham sido todos evitados devido o fundo ter iniciado no meio do mês
            if (invalidQuotas[key].invalids.length == 0) {
                invalidQuotas[key].removeInvalid = true;
            }

        })


        //removendo do objeto final caso seja um fundo special
        let specialAssets = assets.filter(el => el.special);

        console.log("SPECIAL ASSETS: ", specialAssets);

        specialAssets.forEach(assetLoop => {

            //let removeInvalid = false;
            console.log("INVALID QUOTAS: ", invalidQuotas);
            console.log("Fund: ", assetLoop.fund_id);

            if (invalidQuotas[assetLoop.fund_id]) {

                invalidQuotas[assetLoop.fund_id].removeInvalid = true;

            }

        });

        // }

    }

    let finalInvalidQuotas = null;

    if (invalidQuotas) {

        Object.entries(invalidQuotas).map(([key, row]) => {


            if (!row.removeInvalid) {

                if (!finalInvalidQuotas) {
                    finalInvalidQuotas = {}
                }

                finalInvalidQuotas[key] = row;
            }

        });
    }

    console.log("finalInvalidQuotas: ", finalInvalidQuotas);

    return finalInvalidQuotas;

}

//Neste método verifica-se se o fundo já possui informações salvas localmente
//Caso não possua é feita a requisição a API para obtenção dessas informações
//Posteriormente as informações da API são salvas na base local do fundo
async function getDadosCadastraisFunds(listFunds) {

}

async function getTransactionsByDateRange(
    clientId,
    startDate,
    endDate,
    accountRegimeId,
    initPortfolioQuota,//initPortfolioQuota objeto de cota do mês anterior que será utilizado nos cálculos do mês vigente
    doQuotas, //doQuotas indicando se deve ou não fazer o processamento de quotas da API
    keepWholeRescuesAssets,
    startDatePeriod) {

    console.log("CONSULTA CLIENTE: ", clientId);
    console.log("CONSULTA START DATE: ", startDate);
    console.log("CONSULTA END DATE: ", endDate);
    console.log("ACCOUNT REGIME ID: ", accountRegimeId);
    console.log("START DATE PERIOD: ", startDatePeriod);

    //Simulando API DE COTAS       
    //let filteredQuotas = {};

    //let responseAssets = await getClientAllAssetsByDate(clientId, endDate);
    // let responseAssets = await getClientAllAssetsByDateAndRegime(clientId,
    //     startDate,
    //     endDate,
    //     accountRegimeId);

    //Ativos de fundo e de título
    let promisesAssets = [];

    promisesAssets.push(new Promise(async (resolve, reject) => {

        let response = await getClientAllAssetsByDateAndRegime(clientId,
            startDate,
            endDate,
            accountRegimeId, startDatePeriod);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promisesAssets.push(new Promise(async (resolve, reject) => {

        let response = await getClientAllAssetsTitleByDateAndRegime(clientId,
            startDate,
            endDate,
            accountRegimeId);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    //const compositionFunds = await getCompositionFunds();

    const reponseAllAssets = await Promise.all(promisesAssets);

    if (reponseAllAssets) {

        console.log("RESPONSE ALL ASSETS: ", reponseAllAssets);
        console.log(_.cloneDeep(reponseAllAssets[1].rows.find(el => el.id == 91)));

        const fundAssets = reponseAllAssets[0].rows;

        const titleAssets = reponseAllAssets[1].rows;

        //Obter amortizações dos títulos
        if (titleAssets.length > 0) {
            const idsTitleAssetsToInClause = getInClauseByAttr(titleAssets, 'id');
            console.log('idsTitleAssetsToInClause: ', idsTitleAssetsToInClause);
            const responseAmortizations = await getAmortizationsByTitleAssetsIds(idsTitleAssetsToInClause, endDate);
            console.log('responseAmortizations: ', responseAmortizations)

            if (responseAmortizations.success) {

                organizeHistoricAmortizationsInAssetsTitles(titleAssets, responseAmortizations.body.rows);
            }
        }

        //let responseTransactions = await getClientAllTransactionByDateRange(clientId, startDate, endDate);
        let responseTransactions = await getClientAllTransactionByDateRangeAndRegime(clientId, startDate, endDate, accountRegimeId);

        //Verificação se as cotas estão todas ok
        if (responseTransactions.success) {

            const formatted = await formatToAssetsTable(
                responseTransactions.body.rows,
                fundAssets,
                titleAssets,
                startDate,
                endDate,
                doQuotas,
                keepWholeRescuesAssets
            );

            let objectResultAmount = sumResultAmountPLRent(formatted.assets,
                initPortfolioQuota,
                doQuotas); //Enviando o parametro doQuotas aqui também pois caso não sejam
            //consideradas as Quotas os cálculos de participação devem ser feitos a partir dos saldos validados (carteiras já fechadas)

            return {

                success: true,
                resolutionName: reponseAllAssets[0].resolutionName,
                assetsFormatted: !doQuotas ? groupAssetTitles(formatted.assets) : formatted.assets,
                fundAssets: formatted.fundAssets,
                titleAssets: formatted.titles,
                transactions: responseTransactions.body.rows,
                fullDiaryPl: objectResultAmount.fullDiaryPl,
                totalResultAmount: objectResultAmount.totalResultAmount,
                totalBeforeAmount: objectResultAmount.totalBeforeAmount,
                totalRentMoneyInMonth: objectResultAmount.totalRentMoneyInMonth,
                totalRentMoneyInMonthFunds: objectResultAmount.totalRentMoneyInMonthFunds,
                totalRentMoneyInMonthTitles: objectResultAmount.totalRentMoneyInMonthTitles,
                totalRentPercentInMonth: objectResultAmount.totalRentPercentInMonth,
                totalTransactionMap: objectResultAmount.totalTransactionMap,
                invalidQuotas: formatted.invalidQuotas,
                offAssets: formatted.offAssets,
                cdi: formatted.cdi,

            }

        } else {

            return {
                success: false,
                message: 'Falha ao obter transações',
            }

        }
    } else {

        return {
            success: false,
            message: 'Falha ao obter todos os ativos',
        }
    }




}

export function getFormattedAgencyAssetToValidations(agency) {

    if (agency) {
        return "=ag=" + agency;
    }

    return "";
}

/**
 * Formatando estruura para exibição da tabela de saldo de contas
 * @param {array} yearSelected
 * @param {array} assetsClient
 * @param {array} balancesAssetValidations
 * @returns {array} objetos formatados prontos para serem salvos no banco
 */
export function formatBalancesAssetsValidationToShow(yearSelected, assets, balancesValidations) {

    console.log("ENTRANDO NO FORMAT: ", yearSelected)
    console.log("BALANCES VALIDATIONS: ", balancesValidations)
    console.log("ASSETS VALIDATIONS: ", assets)

    let formatted = {}

    formatted['assets'] = {}

    assets.forEach(element => {

        let initAsset = 1;
        let y = parseInt(moment.utc(element.asset_init).format('YYYY'))

        //console.log('comparando: ', y)
        //console.log('comparando: ', yearSelected)
        if (y >= yearSelected) { //Se o ano de inicio do asset for maior ou igual ao ano selecionado

            if (y == yearSelected) {
                //Se o ano for igual inicia-se a partir do mês do inicio do asset
                initAsset = parseInt(moment.utc(element.asset_init).format('MM'));
            } else {
                //Se o ano for maior o asset não é exibido
                initAsset = 0
            }

        }

        const mainCod = element.fund_name + "=" + element.number_account + getFormattedAgencyAssetToValidations(element.agency);

        formatted['assets']
        [mainCod] = {
            init: initAsset,
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {},
            7: {},
            8: {},
            9: {},
            10: {},
            11: {},
            12: {},
            infosAssets: {
                fund_name: element.fund_name,
                cnpj: element.cnpj,
                number_account: element.number_account,
                account_regime_id: element.account_regime_id
            }
        }

    });

    balancesValidations.forEach(element => {

        const mainCod = element.fund_name + "=" + element.asset_number_account + getFormattedAgencyAssetToValidations(element.asset_agency);
        //Caso não existe ainda no formatted
        if (!formatted
            .assets[mainCod]) {
            formatted
                .assets[mainCod] = {}
            formatted
                .assets[mainCod][element.month_validation] = {}
        }

        formatted
            .assets[mainCod
        ][element.month_validation] = element

        if (!formatted
            .assets[mainCod]['infosAssets']) {
            formatted
                .assets[mainCod]
            ['infosAssets'] = {
                fund_name: element.fund_name,
                cnpj: element.cnpj,
                number_account: element.asset_number_account
            }

        }

        // let cod = element.year_validation
        //     + '-assets-'
        //     + element.fund_name
        //     + "-"
        //     + element.asset_agency
        //     + "-"
        //     + element.asset_number_account


        // element.codInput = cod;

    });

    console.log("Formatted: ", formatted);
    console.log("Formatted Keys: ", formatted?.assets ? Object.keys(formatted?.assets) : "");

    return formatted;
}

//Verifica se o ativo já foi lançado em alguma carteira pela da de início dele comparando com a data de portfolio closed do cliente
export function checkAssetIsLaunched(assetInit, portfolioClosed) {

    if (!portfolioClosed) {
        return false;
    }

    let splittedPortolioClosed = portfolioClosed.split('/');
    let monthPClosed = parseInt(splittedPortolioClosed[0]);
    let yearPClosed = parseInt(splittedPortolioClosed[1]);

    let monthAssetInit = parseInt(moment.utc(assetInit).format('MM'));
    let yearAssetInit = parseInt(moment.utc(assetInit).format('YYYY'));

    //Se o ano de inicio for maior que o ano selecionado, ainda não foi lançada
    console.log('parseInt(yearAssetInit) > parseInt(year): ', yearAssetInit + '>' + yearPClosed);
    console.log('monthAssetInit > monthPClosed: ', monthAssetInit + '>' + monthPClosed);
    if (yearAssetInit > yearPClosed) {
        return false;
    } else if (yearAssetInit == yearPClosed && monthAssetInit > monthPClosed) {
        //Se o ano de inicio for igual ao ano selecionado e o mês de início for maior que o mês fechado, ainda não foi lançada        
        return false;
    } else {
        return true;
    }

}

//Verifica se o cliente já possui esse ativo pelo clientId, CNPJ do fundo e o id da conta
export async function checkIsClientAssetByCnpjAndAccount(cnpj, accountId) {

    // console.log("CNPJ: ", cnpj)
    // console.log("ACCOUNT: ", accountId)

    let response = await getClientAssetByCnpjAndAccountId(cnpj, accountId);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id;

    } else {

        return null;

    }

}

export async function checkIsClientAssetByCnpjAndNumberAccount(cnpj, numberAccount) {

    // console.log("CNPJ: ", cnpj)
    // console.log("ACCOUNT: ", accountId)

    let response = await getClientAssetByCnpjAndNumberAccount(cnpj, numberAccount);

    if (response.success
        && response.body
        && response.body.id) {

        return response.body.id;

    } else {

        return null;

    }

}

export function simpleSumBalancesParticipation(assets) {

    let totalBalanceNow = 0.0; //SOMA DOS PLS COM RENTABILIDADES DAS COTAS
    let totalBalanceBefore = 0.0; //SOMA DOS SALDOS ANTERIORES    

    assets.forEach(assetLoop => {

        /////////////////////////////////////
        //SOMA TOTAL DOS SALDOS DOS ATIVOS///
        /////////////////////////////////////     

        if (assets.subAssets && assets.subAssets.length > 0) {
            assets.subAssets.forEach(subAsset => {

                subAsset.balance_now = parseFloat(subAsset.balance_now);
                totalBalanceNow += subAsset.balance_now;
                //Somando os saldos anteriores para cálculo de rentabilidade da carteira
                subAsset.balance_before = subAsset.balance_before;
                totalBalanceBefore += parseFloat(subAsset.balance_before);
            });

        } else {

            assetLoop.balance_now = parseFloat(assetLoop.balance_now);
            totalBalanceNow += assetLoop.balance_now;
            //Somando os saldos anteriores para cálculo de rentabilidade da carteira
            assetLoop.balance_before = parseFloat(assetLoop.balance_before);
            totalBalanceBefore += assetLoop.balance_before;
        }

        // if (assetLoop.transactions) {

        //     //Somando valores de aplicações
        //     assetLoop.totalApplications = assetLoop.transactions.filter(el => el.operation_id == 1).reduce(
        //         (previous, current) => previous + parseFloat(current.amount),
        //         0
        //     )

        //     //Somando valores de resgates
        //     assetLoop.totalRescues = assetLoop.transactions.filter(el => el.operation_id == 2).reduce(
        //         (previous, current) => previous + parseFloat(current.amount),
        //         0
        //     )
        // }


    });

    //Cacular rent total do mês e aproveitando o loop também calculando a participação pelo PL e o totalBalanceNow
    //somando todos os atrivutos do objeto rent 
    assets.forEach(assetLoop => {

        //Quotas não consideradas a participação é obtido fazendo a conta com o próprio balance_now, o saldo validado
        assetLoop.participation = totalBalanceNow !== 0 ? ((parseFloat(assetLoop.balance_now) / totalBalanceNow) * 100).toFixed(2) : 0;

        //Caso hajam subassets calculando a participação de cada um em relação ao pl total do asset
        if (assetLoop.subAssets) {

            assetLoop.subAssets.forEach(subAssetLoop => {

                //Quotas não consideradas a participação é obtido fazendo a conta com o próprio balance_now, o saldo validado
                subAssetLoop.participation = totalBalanceNow !== 0 ? ((parseFloat(subAssetLoop.balance_now) / totalBalanceNow) * 100).toFixed(2) : 0;

            });
        }

    });


    //SUPER PRINT
    // console.log("LAST QUOTA: ", initPortfolioQuota);
    // console.log("FULL DIARY: ", fullDiaryPl);
    // console.log("FULL DIARY: ", JSON.stringify(fullDiaryPl));
    // console.log("FINAL ASSETS PL RENT: ", assets)
    // console.log("TOTAL RESULT AMOUNT: ", totalBalanceNow);

    return {
        totalBalanceNow: totalBalanceNow,
        totalBalanceBefore: totalBalanceBefore,
    }
}

export async function getSimpleNormalizedAssets(clientId, month, year, accountRegimeId) {

    const firstDay = new Date(year, parseInt(month) - 1, 1);
    const lastDay = new Date(year, parseInt(month), 0);

    console.log('PARAMS: ', clientId, month, year);

    //Ativos de fundo e de título
    const promisesAssets = [];

    promisesAssets.push(new Promise(async (resolve, reject) => {

        const response = await getClientAllAssetsByDateAndRegime(clientId,
            moment.utc(firstDay).format('DD/MM/YYYY'),
            moment.utc(lastDay).format('DD/MM/YYYY'),
            accountRegimeId);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promisesAssets.push(new Promise(async (resolve, reject) => {

        const response = await getClientAllAssetsTitleByDateAndRegime(clientId,
            moment.utc(firstDay).format('DD/MM/YYYY'),
            moment.utc(lastDay).format('DD/MM/YYYY'),
            accountRegimeId);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promisesAssets.push(new Promise(async (resolve, reject) => {

        const responseBalancesInAccounts = await doGetDisponibilityAccountsByPeriod(clientId,
            null,
            moment.utc(firstDay).format('DD/MM/YYYY'),
            moment.utc(lastDay).format('DD/MM/YYYY'));

        if (responseBalancesInAccounts.success) {

            resolve(responseBalancesInAccounts.body);

        } else {

            resolve(false);

        }
    }))

    //const compositionFunds = await getCompositionFunds();

    const reponseAllAssets = await Promise.all(promisesAssets);

    //let realAssets = responseAssets.body.rows;

    if (reponseAllAssets) {

        console.log("RESPONSE ALL ASSETS: ", JSON.parse(JSON.stringify(reponseAllAssets)));
        const fundAssets = reponseAllAssets[0].rows;
        const titleAssets = reponseAllAssets[1].rows;
        const dosponilitys = reponseAllAssets[2].rows;
        // if (responseAssets.success) {

        let fundLocalQuotaIds = [];

        //console.log("ASSETS RANGE DATE: ", responseAssets.body)
        for (let i = 0; i < fundAssets.length; i++) {

            if (fundAssets[i].fund_local_quota_id && !fundLocalQuotaIds.includes(fundAssets[i].fund_local_quota_id)) {

                fundLocalQuotaIds.push(fundAssets[i].fund_local_quota_id);
            }
        }

        const fundWithInfosAssets = await getLocalInfosByListFunds(fundAssets, fundLocalQuotaIds);
        normalizeInfosInTitles(titleAssets);

        const realAssets = [];
        realAssets.push.apply(realAssets, fundWithInfosAssets);
        realAssets.push.apply(realAssets, titleAssets);

        //Removendo ativos que foram resgatados totalmente
        const responseWholeRescueAssets = removeWholeRescuesAssets(realAssets);
        const finalSimpleAssets = responseWholeRescueAssets.newAssets;

        // let responseSimpleSum = simpleSumBalancesParticipation(realAssets);
        let responseSimpleSum = simpleSumBalancesParticipation(finalSimpleAssets);

        console.log("ASSETS RANGE DATE SIMPLE: ", finalSimpleAssets)


        const simpleFundsIds = [];
        const simpleFunds = [];
        finalSimpleAssets.forEach(asset => {

            if (!simpleFundsIds.includes(asset.fund_id)) {
                simpleFundsIds.push(asset.fund_id);
                simpleFunds.push({
                    id: asset.fund_id,
                    fund_name: asset.fund_name,
                    cnpj: asset.cnpj,
                    device_abbreviation: asset.device_abbreviation,
                    benchmark: asset.benchmark,
                });
            }
        });

        return {
            success: true,
            //assets: realAssets,
            assets: finalSimpleAssets,
            simpleFunds: simpleFunds,
            totalBalanceNow: responseSimpleSum.totalBalanceNow,
            totalBalanceBefore: responseSimpleSum.totalBalanceBefore
        };

    } else {

        return {
            success: true,
            body: "ERRO AO TRAZER ATIVOS NORMALIZADOS",
        };

    }

}

export async function getPeriodAssets(clientId, startDate, endDate, accountRegimeId) {

    //Ativos de fundo e de título
    const promisesAssets = [];

    promisesAssets.push(new Promise(async (resolve, reject) => {

        let response = await getClientAllAssetsByDateAndRegime(clientId,
            startDate,
            endDate,
            accountRegimeId);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    promisesAssets.push(new Promise(async (resolve, reject) => {

        let response = await getClientAllAssetsTitleByDateAndRegime(clientId,
            startDate,
            endDate,
            accountRegimeId);

        if (response.success) {

            resolve(response.body);

        } else {

            resolve(false);

        }
    }))

    const reponseAllAssets = await Promise.all(promisesAssets);

    //let realAssets = responseAssets.body.rows;

    if (reponseAllAssets) {

        console.log("RESPONSE ALL ASSETS: ", reponseAllAssets);
        // const fundAssets = reponseAllAssets[0].rows;
        // const titleAssets = reponseAllAssets[1].rows;

        // let fundLocalQuotaIds = [];
        // for (let i = 0; i < fundAssets.length; i++) {

        //     if (fundAssets[i].fund_local_quota_id && !fundLocalQuotaIds.includes(fundAssets[i].fund_local_quota_id)) {

        //         fundLocalQuotaIds.push(fundAssets[i].fund_local_quota_id);
        //     }
        // }

        // const fundWithInfosAssets = await getLocalInfosByListFunds(fundAssets, fundLocalQuotaIds);
        // normalizeInfosInTitles(titleAssets);

        // const realAssets = [];
        // realAssets.push.apply(realAssets, fundWithInfosAssets);
        // realAssets.push.apply(realAssets, titleAssets);

        // //Removendo ativos que foram resgatados totalmente
        // const responseWholeRescueAssets = removeWholeRescuesAssets(realAssets);
        // const finalSimpleAssets = responseWholeRescueAssets.newAssets;

        // let responseSimpleSum = simpleSumBalancesParticipation(finalSimpleAssets);

        // console.log("ASSETS RANGE DATE SIMPLE: ", finalSimpleAssets)

        // return {
        //     success: true,
        //     assets: finalSimpleAssets,
        //     totalBalanceNow: responseSimpleSum.totalBalanceNow,
        //     totalBalanceBefore: responseSimpleSum.totalBalanceBefore
        // };

    } else {

        return {
            success: true,
            body: "ERRO AO TRAZER ATIVOS NORMALIZADOS",
        };

    }

}

function normalizeInfosInTitles(titles) {

    const LABEL_TITLES = "TÍTULOS";
    const LABEL_PROPERTIES = "IMÓVEIS";
    titles.forEach(element => {

        if (isGenericTitle(element)) {

            element.administrator_anbima = LABEL_PROPERTIES;
            element.manager_anbima = LABEL_PROPERTIES;
            element.benchmark = LABEL_PROPERTIES;

        } else {

            element.administrator_anbima = LABEL_TITLES;
            element.manager_anbima = LABEL_TITLES;
            element.benchmark = LABEL_TITLES;
        }
    });

}

