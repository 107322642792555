import React from 'react';
import Button from '@material-ui/core/Button';
import { green, deepOrange } from '@material-ui/core/colors';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    success: {
        color: "white",
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    warning: {
        color: "white",
        backgroundColor: deepOrange[500],
        '&:hover': {
            backgroundColor: deepOrange[700],
        },
    },

}));

export default function MyButton(props) {

    const { type, className } = props
    const classes = useStyles();

    return (
        <Button
            {...props}
            className={
                [
                    type ? classes[type] : null,
                    className
                ]
            } />
    );
}