import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from 'react-router-dom';

import { checkUserIsRole, roles } from '../../utils/user.js';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import { headerSearch, toogleButton, unoTheme } from '../../../assets/styles/unoTheme';

import { Grid } from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info';

import {
  applyMaskAmount,
  applyMaskAmountFreeDecimals,
  makeChartCompare,
  makeChartEvolutionPL,
  var5
} from '../../utils/utils';

import MyChartWrapper from '../../utils/MyChartWrapper';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

//API
import { getClientInflationRates } from '../../../API/inflation_rate';
import { formatTargetsToShow, loadClientDiaryByRange, loadClientDiaryPlsByLimit } from '../../../controllers/ClientController';

//Charts
import { Bar } from 'react-chartjs-2';

import CircularProgress from '@material-ui/core/CircularProgress';

import MySelect from '../../utils/MySelect';

//UnoButton
import PrintIcon from '@material-ui/icons/Print';
import UnoButton from '../../utils/UnoButton';

//Menu
import { Tooltip } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty } from 'lodash';
import { path } from '../../utils/routesPaths';

import { SurveysAPI } from '../../../API/surveys.js';
import { checkUserAlreadyRated } from "../../../API/user_feedback";
import CustomReportModal from '../../modals/CustomReportModal';
import RatingUNOModal from "../../modals/RatingUNOModal.js";
import SelectReportPeriodModal from "../../modals/SelectReportPeriodModal";
import SurveyModal from '../../modals/SurveyModal.js';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #000000"
    },
    color: theme.palette.common.white
  },
  tooltip: {
    fontSize: '15px',
    backgroundColor: theme.palette.common.white,
    border: "1px solid #000000",
    color: "#000000"
  }
}))(Tooltip);

const ReportMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    '& ul': {
      padding: '0px',
    }
  },

})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const optionsChart = {
  legend: {
    display: false
  },
  // legend: {
  //     display: true,
  //     position: 'bottom',
  //     fullWidth: true,
  // },
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return applyMaskAmount(tooltipItem.yLabel, true);
      }
    }
  },
  animation: {

    //duration: 1,
    // onComplete: function () {
    //     var chartInstance = this.chart,
    //         ctx = chartInstance.ctx;
    //     ctx.textAlign = 'center';
    //     ctx.fillStyle = "rgba(0, 0, 0, 1)";
    //     ctx.textBaseline = 'bottom';
    //     // Loop through each data in the datasets
    //     this.data.datasets.forEach(function (dataset, i) {
    //         var meta = chartInstance.controller.getDatasetMeta(i);
    //         meta.data.forEach(function (bar, index) {
    //             var data = dataset.data[index];
    //             ctx.fillText(applyMaskAmount(data.y, true), bar._model.x, bar._model.y - 5);
    //         });
    //     });
    // }
  },
  // dataset: {
  //     barPercentage: 0.5,
  // },
  scales: {
    yAxes: [{
      ticks: {

        //beginAtZero: true,
        // begin: 500000,
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return applyMaskAmount(value, true);
        }
      }
    }],
    xAxes: [{
      //barPercentage: 0.5,
      stacked: true,
    }]
  },


  // scales: {
  //     y: {
  //         ticks: {
  //             // Include a dollar sign in the ticks
  //             callback: function (value, index, values) {
  //                 return applyMaskAmount(value, true);
  //             }
  //         }
  //     }
  // }
}

const optionsCompareChart = {

  legend: {
    display: true,
    position: 'bottom',
    align: "start",
    fullWidth: true,
  },
  // legend: {
  //     display: false,
  // },
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem) {
        return applyMaskAmount(tooltipItem.yLabel) + "%";
      }
    }
  },
  animation: {
    // onComplete: function () {
    //     var chartInstance = this.chart,
    //         ctx = chartInstance.ctx;
    //     ctx.textAlign = 'center';
    //     ctx.fillStyle = "rgba(0, 0, 0, 1)";
    //     ctx.textBaseline = 'bottom';
    //     // Loop through each data in the datasets
    //     this.data.datasets.forEach(function (dataset, i) {
    //         var meta = chartInstance.controller.getDatasetMeta(i);
    //         meta.data.forEach(function (bar, index) {
    //             var data = dataset.data[index];
    //             console.log("DATA COMPARE: ", data);
    //             if (data.y) {
    //                 ctx.fillText(applyMaskAmount(data.y) + "%", bar._model.x, bar._model.y - 5);
    //             }

    //         });
    //     });
    // }
  },
  // dataset: {
  //     barPercentage: 0.5,
  // },
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        // Include a dollar sign in the ticks
        callback: function (value, index, values) {
          return value + "%";
        }
      }
    }],
    xAxes: [{
      barPercentage: 0.5
    }]
  }

  // scales: {
  //     y: {
  //         ticks: {
  //             // Include a dollar sign in the ticks
  //             callback: function (value, index, values) {
  //                 return applyMaskAmount(value, true);
  //             }
  //         }
  //     }
  // }
}

const minHeightCardMobile = '80px';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${unoTheme.topToolbarSize})`,
    overflowY: 'scroll',
    paddingBottom: theme.spacing(3),
  },
  headerSearch: headerSearch,
  toogleButton: toogleButton,

  main: {
    width: 'calc(100% - 24px)',
    //RESPONSIVE_MOBILE_CODE
    '@media(max-width: 768px)': {
      width: '100% !important',
      padding: '0px 24px 24px 24px',

      '& .indicator': {
        minHeight: `${minHeightCardMobile} !important`,
        maxHeight: '${minHeightCardMobile} !important',
      }
    },

  },

  rightHeader: {
    display: 'flex',
    alignItems: 'center',

    //RESPONSIVE_MOBILE_CODE
    '@media(max-width: 768px)': {
      '& .toogleBottomIncome, .buttonReportIncome': {
        display: 'none !important',
      }
    },

  },

  mainPaper: {
    padding: '12px',
    borderRadius: '10px',
    fontWeight: 500,
  },

  gridPaper: {
    padding: '12px',
    borderRadius: '10px',
    minHeight: '120px',
    maxHeight: '120px',
    fontWeight: 500,

    //RESPONSIVE_MOBILE_CODE
    '@media(max-width: 768px)': {
      padding: '6px',
      minHeight: `${minHeightCardMobile} !important`,
      maxHeight: `${minHeightCardMobile} !important`,

      '& .infos': {

        padding: '0px !important',

        '& .valueIndicator': {
          paddingTop: '0px !important'
        },

        '& .subLabelFlexIndicator': {
          paddingTop: '0px !important'
        }

      }

    },


  },

  chartWrapper: {
    height: '180px',
  },

  loadTop: {

    marginTop: theme.spacing(2),
    height: '15px !important',
    width: '15px !important',
    color: unoTheme.mainColor,

  },

  indicators: {

    '& .indicator': {
      minHeight: '120px',
      alignItems: 'center',
      height: '100%',

      '& .infos': {
        width: '100%',
        padding: theme.spacing(1),

        '& .labelIndicator': {
          fontSize: '14px',
          color: unoTheme.defaultTextColor,
        },

        '& .valueIndicator': {
          paddingTop: theme.spacing(1),
          fontSize: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: unoTheme.defaultTextColor,
        },

        '& .valueIndicator.green': {
          color: unoTheme.greenMoney,
        },

        '& .valueIndicator.red': {
          color: unoTheme.redMoney,
        },

        '& .subLabelIndicator': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px',
          color: unoTheme.defaultTextColor,
        },

        '& .subLabelIndicator.green': {
          color: unoTheme.greenMoney,
        },

        '& .subLabelIndicator.red': {
          color: unoTheme.redMoney,
        },

        '& .flexIndicator': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',

          '& .subLabelFlexIndicator': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '12px',
            fontSize: '12px',
            color: unoTheme.defaultTextColor,
          },

          '& .valueIndicator': {
            paddingTop: '0px !important',
          }

        },

        '& .percentSpan': {
          marginLeft: '2px',
          marginTop: '-8px',
          fontSize: '11px',
        },

        '& .cifraSpan': {
          marginRight: '2px',
          marginBottom: '-6px',
          fontSize: '11px',
        }
      },

      '& .infosMobile': {
        display: 'none',
      },

      '& .verticaBorder': {
        borderRadius: '30%',
        height: '50%',
        minWidth: '2px',
        background: '#E4E7EB',
      },

      '& .verticaBorderHide': {
        height: '40px',
        minWidth: '2px',
        background: 'white',
      },

      //RESPONSIVE_MOBILE_CODE
      '@media(max-width: 768px)': {
        '& .infosMobile': {
          display: 'block',
        },
      },
    },

    '& .indicator:last-child': {
      borderRight: 'none',
    },
  },

  chartHeader: {
    minHeight: '56px',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      marginRight: theme.spacing(1),
    },

    '& .right': {
      display: 'flex',
      alignItems: 'center',
    }
  },

  plEvolution: {
    marginTop: theme.spacing(2),

    '& .labelChart': {
      textAlign: 'left',
      fontSize: '14px',
      color: unoTheme.defaultTextColor,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    }
  },

  graphsPaper: {
    '& .graphGridPaper': {
      height: '100%',
    }
  },

  reportToolTipDiv: {
    cursor: 'pointer',
    textAlign: 'center'
  }
}));

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.dark
    },
  },
  tooltip: {
    fontSize: '14px',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    },
  },
}));

export default function IncomeScreen({ handleSelectSubMenu }) {

  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();

  //REPONSIVE_MOBILE_CODE
  const location = useLocation();
  const innerLoad = useSelector(state => state.innerLoad)
  //REPONSIVE_MOBILE_CODE

  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.currentUser)
  const clientOn = useSelector(state => state.clientOn)

  const [showModalCustomReport, setShowModalCustomReport] = useState(false);

  const [clientOnScreen, setClientOnScreen] = useState(undefined);

  const [noInfo, setNoInfo] = useState(undefined);
  const [topData, setTopData] = useState(undefined);

  const [dataEvolutionPL, setDataEvolutionPL] = useState(undefined);
  const memoEvolutionPlOptionsChart = useMemo(() => {

    const doBeginZero = dataEvolutionPL?.labels.length <= 6;

    return {
      legend: {
        display: false
      },
      // legend: {
      //     display: true,
      //     position: 'bottom',
      //     fullWidth: true,
      // },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return applyMaskAmount(tooltipItem.yLabel, true);
          }
        }
      },
      animation: {

        //duration: 1,
        // onComplete: function () {
        //     var chartInstance = this.chart,
        //         ctx = chartInstance.ctx;
        //     ctx.textAlign = 'center';
        //     ctx.fillStyle = "rgba(0, 0, 0, 1)";
        //     ctx.textBaseline = 'bottom';
        //     // Loop through each data in the datasets
        //     this.data.datasets.forEach(function (dataset, i) {
        //         var meta = chartInstance.controller.getDatasetMeta(i);
        //         meta.data.forEach(function (bar, index) {
        //             var data = dataset.data[index];
        //             ctx.fillText(applyMaskAmount(data.y, true), bar._model.x, bar._model.y - 5);
        //         });
        //     });
        // }
      },
      // dataset: {
      //     barPercentage: 0.5,
      // },
      scales: {
        yAxes: [{
          ticks: {

            beginAtZero: doBeginZero,
            // begin: 500000,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return applyMaskAmount(value, true);
            }
          }
        }],
        xAxes: [{
          //barPercentage: 0.5,
          stacked: true,
        }]
      },


      // scales: {
      //     y: {
      //         ticks: {
      //             // Include a dollar sign in the ticks
      //             callback: function (value, index, values) {
      //                 return applyMaskAmount(value, true);
      //             }
      //         }
      //     }
      // }
    }
  }, [dataEvolutionPL])

  const [optionsEvolutionPLChart, setOptionsEvolutionPLChart] = useState(undefined);
  const [dataCompareChart, setDataCompareChart] = useState(undefined);

  const [formats, setFormats] = React.useState(() => '36');
  // const [formats, setFormats] = React.useState(() => 'ano');
  const [formatsOnScreen, setFormatsOnScreen] = React.useState('36');
  // const [formatsOnScreen, setFormatsOnScreen] = React.useState('ano');

  const [rentsPeriod, setRentsPeriod] = useState([]);
  const [targetsPeriod, setTargetsPeriod] = useState([]);

  const handleFormat = (event, newFormats) => {
    if (newFormats && newFormats.length) {
      setFormats(newFormats);
    }
  };

  const [typesChart, setTypesChart] = useState(['Valores mensais', 'Valores acumulados']);
  const [typeChartSelected, setTypeChartSelected] = useState(0);

  //SURVEYS
  const [openSurvey, setOpenSurvey] = useState(false);

  const checkOpenSurvey = async () => {

    const responseShowOrNot = await SurveysAPI.getShowOrNotBySurveyIdAndClientId(1, clientOn.client.id);
    if (responseShowOrNot.success && responseShowOrNot.body) {

      const { show } = responseShowOrNot.body;
      setOpenSurvey(show)
    } else {

      setOpenSurvey(false);
    }

  }

  //Menu relatório
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseReportMenu = () => setAnchorEl(null);

  const handleChangeTypeChartPeriod = (value) => {

    console.log("Value: ", value);
    setTypeChartSelected(value);
    setDataCompareChart(makeChartCompare(rentsPeriod, targetsPeriod, value));

  };

  //report period
  const [showSelectReportPeriodModal, setShowSelectReportPeriodModal] = useState(false);

  function openSelectReportPeriodModal() {
    handleCloseReportMenu();
    dispatch(allActions.rightContentActions.setRightContent({
      path: 'configReport',
      client: clientOn.client,
    }))
    //setShowSelectReportPeriodModal(true)
  }

  function doReport2(configReport) {

    setShowSelectReportPeriodModal(false);
    if (configReport) {
      dispatch(allActions.reportLoadActions.setReportLoad({
        show: true,
        ...configReport,
        message: 'Gerando relatório...',
      }));
    }
  }

  const [showModalRatingUNO, setShowModalRatingUNO] = useState(false);

  async function loadInfos(clientId) {

    let diarys = await loadClientDiaryByRange(clientId,
      clientOn.client.selectedPortfolioMonth,
      clientOn.client.selectedPortfolioYear, formats);

    console.log('diarys lala: ', diarys);

    const { mapAssetsBalances, periodToRemove } = diarys;

    const rentsToVar = await loadClientDiaryPlsByLimit(clientId, clientOn.client.selectedPortfolioMonth,
      clientOn.client.selectedPortfolioYear, 252); //LimitToVar

    console.log("RENTS TO VAR: ", rentsToVar);

    //Carregando gráfico de evolução de patrímonio

    setDataEvolutionPL(makeChartEvolutionPL(diarys.mapAssetsBalances, diarys.mapAccountsBalances, periodToRemove));

    console.log("DIARYS RENT MONHTS: ", diarys.rentMonths);
    const rentMonthActual = diarys.rentMonths[clientOn.client.selectedPortfolioMonth + "/" + clientOn.client.selectedPortfolioYear];
    console.log("rentMonthActual: ", rentMonthActual);

    const responseInflates = await getClientInflationRates(clientId);

    console.log("responseInflates: ", _.cloneDeep(responseInflates));
    console.log("diarys: ", diarys.rentMonths);
    let formattedTargets = formatTargetsToShow(responseInflates.body.rows, diarys.rentMonths);
    formattedTargets.reverse();

    //Carregando gráfico de evolução de patrímonio
    //formatando dados nos gráficos
    setRentsPeriod(diarys.arrayRents);
    setTargetsPeriod(formattedTargets);
    setDataCompareChart(makeChartCompare(diarys.arrayRents, formattedTargets, 0));

    //Inicializando gráfico com dataMonth
    setTypeChartSelected(0);

    console.log("Formatted targets: ", formattedTargets);
    let targetActualMonth = null;
    for (let i = 0; i < formattedTargets.length; i++) {
      let target = formattedTargets[i];
      if (target.month == clientOn.client.selectedPortfolioMonth && target.year == clientOn.client.selectedPortfolioYear) {
        targetActualMonth = target;
        break;
      }
    }

    console.log("Target actual month: ", targetActualMonth);

    let keyActualMonth = clientOn.client.selectedPortfolioMonth + "/" + clientOn.client.selectedPortfolioYear;
    //informações inciais
    console.log("DIARYS: ", diarys);

    if (diarys
      && diarys.rentMonths
      && diarys.arrayRents.length > 0
      && diarys.rentMonths[keyActualMonth]) {

      console.log('mapAssetsBalances: ', mapAssetsBalances);

      let topData = {
        // plMonth: parseFloat(diarys.rentMonths[keyActualMonth].diarys[
        //     diarys.rentMonths[keyActualMonth].diarys.length - 1
        // ].new_pl)
        plMonth:
          mapAssetsBalances[keyActualMonth]
          + (diarys.mapAccountsBalances[keyActualMonth] ? diarys.mapAccountsBalances[keyActualMonth] : 0), //Adicionando saldo de disponibilidades
        rentMonth: rentMonthActual.rent,
        rentPorcentMonth: rentMonthActual.rent,
        rentPorcentAcum: rentMonthActual.rentAcum,

        targetMonth: targetActualMonth ? (targetActualMonth.use_ipca ? targetActualMonth.taxMonthIpca : targetActualMonth.taxMonthInpc) : null,
        targetAccumulated: targetActualMonth ? (targetActualMonth.use_ipca ? targetActualMonth.taxAcumIpca : targetActualMonth.taxAcumInpc) : null,
      }

      topData = {
        ...topData,
        gapMonth: topData.rentMonth - topData.targetMonth,
        gapAccumulated: topData.rentPorcentAcum - topData.targetAccumulated,
        returnAccumulated: 8174.78,
        var: rentsToVar ? var5(rentsToVar) : null,

        subIndicatorValue1: topData.rentMonth,
        subIndicatorValue2: -6.96,
      }

      console.log("TOP DATA: ", topData);

      setTopData(topData)

    }

    dispatch(allActions.innerLoadActions.setInnerLoad({
      show: false,
    }))

  }

  function cleanData() {
    setTopData(undefined);
    setDataEvolutionPL(undefined);
  }

  function doReport(reportType) {

    handleCloseReportMenu();

    let isMessageDashboard = reportType == 'dashboard';
    dispatch(allActions.reportLoadActions.setReportLoad({
      show: true,
      reportType,
      periodReport: formats,
      message: isMessageDashboard ? 'Gerando relatório...' : 'Gerando relatório geral...',
    }));
  }

  function handleCustomReportModal(reportTypeList) {



    if (Array.isArray(reportTypeList)) {

      console.log("Report type list: ", reportTypeList);
      dispatch(allActions.reportLoadActions.setReportLoad({
        show: true,
        reportType: 'custom',
        reportTypeList: reportTypeList,
        message: 'Gerando relatório personalizado...',
      }));
    }

    handleCloseReportMenu();
    setShowModalCustomReport(false);

  }

  async function doCheckRatingUNO() {


    if (currentUser && currentUser.user) {
      if (checkUserIsRole(currentUser, [roles.manager, roles.submanager])) {
        const response = await checkUserAlreadyRated(currentUser.user.id);
        console.log("RESPONSE CHECK RATING: ", response);

        if (!response) {
          setShowModalRatingUNO(true);
        }
      }
    }

  }

  function handleModalRatingUno(action) {
    setShowModalRatingUNO(false);
  }

  useEffect(() => {
    if (clientOn?.client?.id && currentUser?.user?.id) {
      if (checkUserIsRole(currentUser, [roles.manager, roles.submanager])) {
        checkOpenSurvey()
      }
    }
  }, [clientOn?.client?.id, currentUser?.user?.id])

  useEffect(() => {
    dispatch(allActions.innerLoadActions.setInnerLoad({
      show: false,
    }))

    //Primeiro load
    //Ou quando altera-se o cleinte
    //Ou quando muda-se a faixa de meses
    if (clientOn
      && clientOn.client
      && clientOn.client.selectedPortfolioMonth
      && clientOn.client.selectedPortfolioYear
      && (!clientOnScreen
        || clientOn.client.id != clientOnScreen.id
        || formats != formatsOnScreen)) {


      cleanData()

      setClientOnScreen(_.cloneDeep(clientOn.client));
      setFormatsOnScreen(formats);
      loadInfos(clientOn.client.id);


      //checagem de user rating
      //doCheckRatingUNO();
    }

    //Quando alterea-se o mês ou o ano
    if (clientOn
      && clientOn.client
      && clientOn.client.selectedPortfolioMonth
      && clientOn.client.selectedPortfolioYear
      && clientOnScreen
      && clientOnScreen.id == clientOn.client.id
      && (clientOn.client.selectedPortfolioMonth != clientOnScreen.selectedPortfolioMonth
        || clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear)) {

      //cleanData()
      setTopData(undefined);

      setClientOnScreen(_.cloneDeep(clientOn.client));
      loadInfos(clientOn.client.id);
    }

    // console.log('INV.NORMAL 2: ', normalinvOk(
    //     5 / 100,
    //     0,
    //     1
    // ));
    // console.log('INV.NORMAL 1: ', normsInv(
    //     5 / 100,
    //     0,
    //     1
    // ));


  }, [clientOn, formats])

  return (
    <div className={[classes.root, "switchScreenRoot"].join(' ')}>
      <div className={classes.headerSearch}>

        <Typography variant="h6" gutterBottom align="left">
          {'Dashboard'}
        </Typography>

        <div className={classes.rightHeader}>

          <ToggleButtonGroup
            className={[classes.toogleButton, 'toogleBottomIncome'].join(' ')}
            style={{ marginRight: '16px' }}
            exclusive value={formats} onChange={handleFormat} aria-label="text formatting">
            <ToggleButton value="ano" aria-label="Ano">
              {'Ano'}
            </ToggleButton>
            <ToggleButton value="12" aria-label="12 meses">
              {'12 meses'}
            </ToggleButton>
            <ToggleButton value="24" aria-label="24 meses">
              {'24 meses'}
            </ToggleButton>
            <ToggleButton value="36" aria-label="36 meses">
              {'36 meses'}
            </ToggleButton>

          </ToggleButtonGroup>

          <div className={'buttonReportIncome'} style={{ marginRight: '16px' }}>
            {
              rentsPeriod != null && rentsPeriod.length > 0 && targetsPeriod != null && targetsPeriod.length > 0 ?
                <>
                  <UnoButton
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    size={'small'}
                    //onClick={(event) => setAnchorEl(event.currentTarget)}
                    onClick={() => openSelectReportPeriodModal()}
                    type='primary'
                    startIcon={<PrintIcon />}>
                    {"Gerar relatório"}
                  </UnoButton>

                  <ReportMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseReportMenu}>
                    <MenuItem onClick={() => doReport('fullReport')}>
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Geral" />
                    </MenuItem>
                    <MenuItem onClick={() => doReport('dashboard')}>
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Resumido" />
                    </MenuItem>
                    <MenuItem onClick={() => setShowModalCustomReport(true)}>
                      <ListItemIcon>
                        <PrintIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Personalizado" />
                    </MenuItem>
                  </ReportMenu>
                </>
                : (isEmpty(targetsPeriod) ?
                  <Tooltip
                    leaveDelay={500}
                    placement='bottom'
                    arrow
                    classes={tooltipClasses}
                    interactive
                    title={
                      <div
                        className={classes.reportToolTipDiv}
                        onClick={() => {
                          const INVESTIMENTS_MENU = 4;
                          handleSelectSubMenu(INVESTIMENTS_MENU, path.investiments.targets);
                        }}
                      >
                        É necessário cadastrar a meta!<br />
                        <u>
                          Clique para cadastrar
                        </u>
                      </div>
                    }
                  >
                    <div>
                      <UnoButton
                        size='small'
                        type='secondary'
                        startIcon={<PrintIcon />}
                        disabled
                      >
                        Gerar relatório
                      </UnoButton>
                    </div>
                  </Tooltip>
                  : null
                )
            }
          </div>
        </div>


      </div>

      <div className={classes.main}>
        <Grid container spacing={2} className={classes.indicators}>
          <Grid item xs={12} sm={12} md={4} className={'indicator'}>
            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>

              <div className={'infos'}>
                <Typography className={'labelIndicator'}>
                  {'Patrimônio'}
                </Typography>
                {
                  topData ?
                    <>
                      <Typography className={`valueIndicator ${topData.plMonth >= 0 ? 'green' : 'red'}`} style={{ fontSize: '28px' }}>
                        <span className={'cifraSpan'}>R$</span>{applyMaskAmount(topData.plMonth)}
                      </Typography>
                      {/* <Typography className={`subLabelIndicator ${topData.subIndicatorValue1 > 0 ? 'green' : 'red'}`}>
                                                {
                                                    topData.subIndicatorValue1 > 0 ?
                                                        <UpIcon /> : <DownIcon />
                                                }
                                                {applyMaskAmount(topData.subIndicatorValue1)}%
                                            </Typography> */}
                    </>
                    :
                    <div>
                      <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                    </div>
                }



              </div>

            </Paper>

          </Grid>

          <Grid item xs={6} sm={6} md={2} className={'indicator'}>
            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>
              <div className={'infos'}>

                <Typography className={'labelIndicator'}>
                  {'Rentabilidade'}
                </Typography>
                {
                  topData ?
                    <>
                      <div className={'flexIndicator'}>
                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Mês'}
                          </Typography>
                          <LightTooltip style={{ fontSize: '24px' }} arrow title={applyMaskAmountFreeDecimals(topData.rentPorcentMonth) + "%"}>
                            <Typography className={`valueIndicator ${topData.rentPorcentMonth > 0 ? 'green' : 'red'}`}>
                              {applyMaskAmount(topData.rentPorcentMonth)}<span className={'percentSpan'}>%</span>
                            </Typography>
                          </LightTooltip>
                        </div>

                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Acum.'}
                          </Typography>
                          <Typography className={`valueIndicator ${topData.rentPorcentAcum > 0 ? 'green' : 'red'}`}>
                            {applyMaskAmount(topData.rentPorcentAcum)}<span className={'percentSpan'}>%</span>
                          </Typography>
                        </div>
                      </div>
                    </>
                    :
                    <div>
                      <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                    </div>
                }


              </div>

            </Paper>
          </Grid>

          <Grid item xs={6} sm={6} md={2} className={'indicator'}>
            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>
              <div className={'infos'}>
                <Typography className={'labelIndicator'}>
                  {'Meta'}
                </Typography>
                {
                  topData ?
                    <>
                      <div className={'flexIndicator'}>
                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Mês'}
                          </Typography>
                          <Typography className={`valueIndicator ${topData.targetMonth > 0 ? 'green' : 'red'}`}>
                            {topData.targetMonth ? applyMaskAmount(topData.targetMonth) : '-'}<span className={'percentSpan'}>%</span>
                          </Typography>
                        </div>

                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Acum.'}
                          </Typography>
                          <Typography className={`valueIndicator ${topData.targetAccumulated > 0 ? 'green' : 'red'}`}>
                            {topData.targetAccumulated ? applyMaskAmount(topData.targetAccumulated) : '-'}<span className={'percentSpan'}>%</span>
                          </Typography>
                        </div>
                      </div>
                    </>
                    :
                    <div>
                      <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                    </div>
                }

              </div>
            </Paper>
          </Grid>

          <Grid item xs={6} sm={6} md={2} className={'indicator'}>
            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>
              <div className={'infos'}>
                <Typography className={'labelIndicator'}>
                  <Tooltip
                    placement='top'
                    arrow
                    classes={tooltipClasses}
                    interactive
                    title={'Diferença entre RENTABILIDADE e META'}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}>
                      {'Gap'} <InfoIcon style={{
                        fontSize: '18px',
                        marginLeft: '6px',
                        color: unoTheme.mainColor,
                      }} />
                    </div>
                  </Tooltip>
                </Typography>
                {
                  topData ?
                    <>
                      <div className={'flexIndicator'}>
                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Mês'}
                          </Typography>
                          <Typography className={`valueIndicator ${topData.gapMonth > 0 ? 'green' : 'red'}`}>
                            {topData.gapMonth ? applyMaskAmount(topData.gapMonth) : '-'}<span className={'percentSpan'}>p.p.</span>
                          </Typography>
                        </div>

                        <div className={'subIndicator'}>
                          <Typography className={'subLabelFlexIndicator'}>
                            {'Acum.'}
                          </Typography>
                          <Typography className={`valueIndicator ${topData.gapAccumulated > 0 ? 'green' : 'red'}`}>
                            {topData.gapAccumulated ? applyMaskAmount(topData.gapAccumulated) : '-'}<span className={'percentSpan'}>p.p.</span>
                          </Typography>
                        </div>
                      </div>
                    </>
                    :
                    <div>
                      <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                    </div>
                }

              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={6} md={2} className={'indicator'}>
            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>

              <div className={'infos'}>
                <Typography className={'labelIndicator'}>
                  <span>{'VaR'}<span style={{ fontSize: "10px", marginLeft: "2px" }}>1,252</span></span>
                </Typography>
                {
                  topData ?
                    <>
                      <Typography className={`valueIndicator ${topData.var > 0 || topData.var == null ? 'green' : 'red'}`}>
                        {topData.var !== null ? applyMaskAmountFreeDecimals(topData.var, null, 4) + '%' : '-'}
                      </Typography>
                      {/* <Typography className={`subLabelIndicator ${topData.subIndicatorValue1 > 0 ? 'green' : 'red'}`}>
                                                {
                                                    topData.var > 0 || topData.var == null > 0 ?
                                                        <UpIcon /> : <DownIcon />
                                                }
                                                {'- '}%
                                            </Typography> */}
                    </>
                    :
                    <div>
                      <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                    </div>
                }

              </div>
            </Paper>
          </Grid>
        </Grid>

        {/* <Paper className={[classes.mainPaper, classes.plEvolution].join(' ')} elevation={1}>
                    <Typography className={'labelChart'}>{'Evolução do Patrimônio'}</Typography>
                    <MyChartWrapper height={'240px'}
                        loading={!dataEvolutionPL || !optionsEvolutionPLChart}>
                        <Bar data={dataEvolutionPL}
                            //options={optionsChart}
                            options={optionsEvolutionPLChart}
                            height={'130px'} />
                    </MyChartWrapper>

                </Paper> */}

        <Paper className={[classes.mainPaper, classes.plEvolution].join(' ')} elevation={1}>
          <Typography className={'labelChart'}>{'Evolução do Patrimônio'}</Typography>
          <MyChartWrapper height={'240px'}
            loading={!dataEvolutionPL}>
            <Bar data={dataEvolutionPL}
              options={memoEvolutionPlOptionsChart}
              height={'130px'} />
          </MyChartWrapper>
        </Paper>

        <Paper className={[classes.mainPaper, classes.plEvolution].join(' ')} elevation={1}>
          <div className={classes.chartHeader}>

            <Typography className={'labelChart'} style={{ paddingBottom: '0px', paddingTop: '0px' }}>{'Comparativo (Rentabilidade x Metas)'}</Typography>
            <div className={'right'}>
              <MySelect
                style={{ marginRight: '8px' }}
                value={typeChartSelected}
                verysmall
                onChange={(e) => handleChangeTypeChartPeriod(e.target.value)}
                options={typesChart.map((row, index) => (

                  <option key={'type-chart-' + index} value={index}>{row}</option>

                ))}
              />
            </div>
          </div>

          <MyChartWrapper height={'240px'}
            loading={!dataCompareChart}>
            <Bar data={dataCompareChart} options={optionsCompareChart} />
          </MyChartWrapper>

        </Paper>

      </div>

      <CustomReportModal
        open={showModalCustomReport}
        handleCustomReportModal={handleCustomReportModal} />

      <SelectReportPeriodModal
        client={clientOn && clientOn.client ? clientOn.client : null}
        open={showSelectReportPeriodModal}
        doReportPeriod={doReport2} />

      <RatingUNOModal
        user={currentUser && currentUser.user}
        open={showModalRatingUNO}
        handleModalRatingUno={handleModalRatingUno} />

      <SurveyModal
        surveyId={1}
        user={currentUser && currentUser.user}
        client={clientOn && clientOn.client}
        open={openSurvey}
        handleClickSurvey={() => setOpenSurvey(false)}
      />

    </div >
  );
}



