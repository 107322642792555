import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';

//redux
import MyDatePicker from '../utils/MyDatePicker'

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

//API
import { resetPortfolio } from '../../API/client'

//Controllers
import { saveLogResetPortfolio } from '../../controllers/LogActionController'

import UnoButton from '../utils/UnoButton';

import { Divider, Grid, Typography } from '@material-ui/core';

import { unoTheme, myLabelInput, toogleButton } from '../../assets/styles/unoTheme'
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from 'moment'
import { formatPortfolioClosed, getFirstDayMonth, getFirstPtDayMonth, getLastDayMonth, getLastMomentDayMonth, getLastPtDayMonth, getMonthsByArray, getMonthsUntilPortfolioInit, getQuartersByMonthsArray, getSemestersByMonthsArray } from '../utils/utils';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MySelect from '../utils/MySelect';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    contentModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        alignItems: 'center',
        paddingBottom: theme.spacing(4),

        '& .titlesModal': {
            fontWeight: 'bold',
            marginBottom: '16px',
        },

        '& .periodSelect': {
            marginTop: '24px',
            width: '100%',
            padding: '24px 0px 0px 0px',
            borderTop: '1px solid #ced4da',
            textAlign: 'center',

            '& .selectors': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },

            '& .alertNoPeriod': {
                marginTop: '26px',
                color: 'red',
                fontSize: '12px',
                width: '180px',
            },

            '& .formPersonalizaded': {
                textAlign: 'left',
                marginTop: '16px',
                padding: '16px',
                backgroundColor: '#eeeeee',
                borderRadius: '10px',

                '& .MuiTypography-root': {
                    fontSize: '13px',
                }
            }


        },
    },

    toogleButton: toogleButton,

    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    actionsFlexRight: {
        display: 'flex',
        alignItems: 'center',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitle: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            //justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },
    tableTh: {
        fontWeight: 'bold'
    },
    assetsToCreateRow: {
        '& .MuiTableCell-root': {
            fontSize: '12px !important',
        }
    },
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.redMoney,
    },
    blue: {
        color: "blue",
    },
    warningColor: {
        color: unoTheme.warningColor
    },
    label: myLabelInput,
    afirmativeText: {
        color: 'black'
    },
    noPaddingLeft: {
        paddingLeft: '0px',
    },
    noPaddingRight: {
        paddingRight: '0px !important',
    },

    progress: {
        color: unoTheme.mainColor,
        textAlign: 'center',
        '& .MuiCircularProgress-root': {
            width: '25px !important',
            height: '25px !important',
        },

        '& h6': {
            fontSize: '14px !important',
        }
    }


}));

export default function SelectReportPeriodModal(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { client, open,
        doReportPeriod } = props;

    // The first commit of Material-UI
    // const [selectedMonth, setSelectedMonth] = useState(months[0]);
    // const [selectedYear, setSelectedYear] = useState(years[0]);
    const [selectedDate, setSelectedDate] = useState(new Date(2020, 12, 1));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [loader, setLoader] = useState(false);
    const [validateMessage, setValidateMessage] = useState("");

    const [formattedPortfolioYearsMonths, setFormattedPortfolioYearsMonths] = React.useState();

    const [reportMode, setReportMode] = React.useState('general');
    const handleReportMode = (event, newFormats) => {
        if (newFormats && newFormats.length) {
            initState();
            setReportMode(newFormats);
        }
    };

    const [state, setState] = React.useState({
        dashboard: false,
        portfolio: false,
        distribuition: false,
        articles: false,
        targets: false,
        myFunds: false,
    });

    function initState() {
        setState({
            dashboard: false,
            portfolio: false,
            distribuition: false,
            articles: false,
            targets: false,
            myFunds: false,
        })
    }

    const [mainPeriod, setMainPeriod] = React.useState('month');
    const handleMainPeriod = (event, newFormats) => {
        if (newFormats && newFormats.length) {
            setMainPeriod(newFormats);
        }
    };

    const [optionsMonths, setOptionsMonths] = React.useState([]);
    const [optionsQuarters, setOptionsQuarters] = React.useState([]);
    const [optionsSemesters, setOptionsSemesters] = React.useState([]);
    const [optionsYears, setOptionsYears] = React.useState([]);


    const [monthPeriod, setMonthPeriod] = React.useState(0);
    function handleMonthPeriod(value) {
        setMonthPeriod(value)
    }

    const [quarterPeriod, setQuarterPeriod] = React.useState(0);
    function handleQuarterPeriod(value) {
        setQuarterPeriod(value)
    }

    const [semesterPeriod, setSemesterPeriod] = React.useState(0);
    function handleSemesterPeriod(value) {
        setSemesterPeriod(value)
    }

    const [yearPeriod, setYearPeriod] = React.useState(0);
    function handleYearPeriod(value) {

        console.log('formattedPortfolioYearsMonths onChange: ', formattedPortfolioYearsMonths)

        if (value != yearPeriod) {

            setOptionsMonths(getMonthsByArray(formattedPortfolioYearsMonths.months[
                optionsYears[value]
            ]));

            setOptionsQuarters(getQuartersByMonthsArray(formattedPortfolioYearsMonths.months[
                optionsYears[value]
            ]));

            setOptionsSemesters(getSemestersByMonthsArray(formattedPortfolioYearsMonths.months[
                optionsYears[value]
            ]));

            if (!formattedPortfolioYearsMonths.months[
                optionsYears[value]
            ].includes(monthPeriod)) {

                setYearPeriod(formattedPortfolioYearsMonths.months[
                    optionsYears[value]
                ][0]);

            }
        }
        setYearPeriod(value)
    }

    function formatConfigReport() {

        const configReport = {};
        const yearReport = optionsYears[yearPeriod];

        if (mainPeriod == 'month') {

            const monthReport = optionsMonths[monthPeriod].id;

            configReport.startDate = getFirstPtDayMonth(monthReport, yearReport);
            configReport.endDate = getLastPtDayMonth(monthReport, yearReport);

        } else if (mainPeriod == 'quarter') {

            const { startMonth, endMonth } = optionsQuarters[quarterPeriod];

            configReport.startDate = getFirstPtDayMonth(startMonth, yearReport);
            configReport.endDate = getLastPtDayMonth(endMonth, yearReport);

        } else if (mainPeriod == 'semester') {

            const { startMonth, endMonth } = optionsSemesters[semesterPeriod];

            configReport.startDate = getFirstPtDayMonth(startMonth, yearReport);
            configReport.endDate = getLastPtDayMonth(endMonth, yearReport);

        }

        if (reportMode == 'general') {
            configReport.reportType = 'fullReport';
        } else {
            configReport.reportType = 'custom';
        }

        console.log('configReport: ', configReport);
        return configReport
    }

    useEffect(() => {

        // console.log("Months: ", months);
        // console.log("Years: ", years);

        if (client) {

            setMainPeriod('month');
            setReportMode('general');

            const monthsUntilPortfolioInit = getMonthsUntilPortfolioInit(client.portfolio_init, client.portfolio_closed);
            console.log('formattedPortfolioYearsMonths: ', monthsUntilPortfolioInit);

            setOptionsYears(monthsUntilPortfolioInit.years);

            //Inicializando selectedPortfolioMonth e selectedPortfolioYear do client com os últimos registros obtidos            

            const monthsPortfolio = monthsUntilPortfolioInit.months;
            const yearsPortfolio = monthsUntilPortfolioInit.years;



            if (yearsPortfolio
                && monthsPortfolio) {

                const selectedPeriodYear = yearsPortfolio.length - 1;
                setYearPeriod(selectedPeriodYear);

                setOptionsMonths(getMonthsByArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                setOptionsQuarters(getQuartersByMonthsArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                setOptionsSemesters(getSemestersByMonthsArray(monthsPortfolio[
                    yearsPortfolio[selectedPeriodYear]
                ]));

                if (monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]]) {
                    setMonthPeriod(monthsPortfolio[yearsPortfolio[yearsPortfolio.length - 1]].length - 1);
                }
            }


            setFormattedPortfolioYearsMonths(monthsUntilPortfolioInit);
        }



    }, [open])

    const {
        dashboard,
        portfolio,
        distribuition,
        articles,
        targets,
        myFunds,
    } = state;

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={() => doReportPeriod(false)}>
            <DialogTitle
                className={classes.dialogTitle}>
                {'Configurações de relatório'}
            </DialogTitle>

            <DialogContent
                className={classes.contentModal}>


                {/* <Typography className={'titlesModal'}>{"Selecione o modelo"}</Typography> */}

                <ToggleButtonGroup
                    className={classes.toogleButton}
                    exclusive value={mainPeriod} onChange={handleMainPeriod} aria-label="text formatting">
                    <ToggleButton value="month" aria-label="mensal">
                        {'Mensal'}
                    </ToggleButton>
                    <ToggleButton value="quarter" aria-label="trimestral">
                        {'Trimestral'}
                    </ToggleButton>
                    <ToggleButton value="semester" aria-label="semestral">
                        {'Semestral'}
                    </ToggleButton>

                </ToggleButtonGroup>

                <div className="periodSelect">

                    {/* <Typography className={'titlesModal'}>{"Selecione os relatórios"}</Typography> */}


                    <ToggleButtonGroup
                        className={classes.toogleButton}
                        exclusive value={reportMode} onChange={handleReportMode} aria-label="text formatting">
                        <ToggleButton value="general" aria-label="mensal">
                            {'Geral'}
                        </ToggleButton>

                        <ToggleButton value="personalizaded" aria-label="trimestral">
                            {'Personalizado'}
                        </ToggleButton>

                    </ToggleButtonGroup>

                    {
                        reportMode == 'personalizaded' ?
                            <FormGroup className={'formPersonalizaded'}>
                                <Grid container spacing={1}>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={dashboard} onChange={handleChange} name='dashboard' />}
                                            label="Dashboard"
                                        />

                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={portfolio} onChange={handleChange} name='portfolio' />}
                                            label="Carteira"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={distribuition} onChange={handleChange} name='distribuition' />}
                                            label="Distribuição"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={articles} onChange={handleChange} name='articles' />}
                                            label="Enquadramentos"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={targets} onChange={handleChange} name='targets' />}
                                            label="Metas"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" checked={myFunds} onChange={handleChange} name='myFunds' />}
                                            label="Fundos"
                                        />
                                    </Grid>

                                </Grid>






                            </FormGroup> : null
                    }


                </div>

                <div className="periodSelect">

                    <Typography className={'titlesModal'}>{"Selecione o período"}</Typography>

                    <div className={'selectors'}>


                        <MySelect
                            outerlabel={'Ano'}
                            value={yearPeriod}
                            onChange={(e) => handleYearPeriod(e.target.value)}
                            options={optionsYears.map((row, index) => (
                                <option key={'year-period-' + index} value={index}>{row}</option>
                            ))}
                        />

                        <div style={{ width: '16px' }}></div>

                        {

                            mainPeriod == 'month' ?
                                <MySelect
                                    outerlabel={'Mês'}
                                    value={monthPeriod}
                                    onChange={(e) => handleMonthPeriod(e.target.value)}
                                    options={optionsMonths.map((row, index) => (
                                        <option key={'month-period-' + index} value={index}>{row.name}</option>
                                    ))}
                                /> : null
                        }

                        {

                            mainPeriod == 'quarter' ?

                                <>

                                    {
                                        optionsQuarters && optionsQuarters.length ?
                                            <MySelect
                                                outerlabel={'Trimestre'}
                                                value={quarterPeriod}
                                                onChange={(e) => handleQuarterPeriod(e.target.value)}
                                                options={optionsQuarters.map((row, index) => (
                                                    <option key={'quarter-period-' + index} value={index}>{row.name}</option>
                                                ))}
                                            /> : <span className={'alertNoPeriod'}>{'Não há trimestre completo para o ano selecionado'}</span>
                                    }

                                </> : null

                        }

                        {

                            mainPeriod == 'semester' ?
                                <>

                                    {
                                        optionsSemesters && optionsSemesters.length ?
                                            <MySelect
                                                outerlabel={'Semestre'}
                                                value={semesterPeriod}
                                                onChange={(e) => handleSemesterPeriod(e.target.value)}
                                                options={optionsSemesters.map((row, index) => (
                                                    <option key={'semester-period-' + index} value={index}>{row.name}</option>
                                                ))}
                                            /> : <span className={'alertNoPeriod'}>{'Não há semestre completo para o ano selecionado'}</span>
                                    }

                                </> : null

                        }


                    </div>
                </div>




            </DialogContent>

            <DialogActions className={classes.actionsFlex}>

                <UnoButton onClick={() =>
                    doReportPeriod(false)} color="secondary">
                    {'Cancelar'}
                </UnoButton>

                <UnoButton
                    className={classes.spaceRight2}
                    onClick={() => doReportPeriod(formatConfigReport())}
                    color="primary">
                    {"Gerar relatório"}
                </UnoButton>

            </DialogActions>

        </Dialog >
    );
}