import API from './config'

export async function createTransaction(transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/create', transaction, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createSingleTransaction(transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/createSingleTransaction', transaction, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteTransaction(transactionId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/transactions/delete/' + transactionId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getOperations() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/transactions/getOperations', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAllTransactions(accountsClient) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    let paramAccounts = [];
    for (let i = 0; i < accountsClient.length; i++) {
        paramAccounts.push(accountsClient[i].id);
    }

    try {

        const response = await API.post('/transactions/listAll', {
            inAccounts: paramAccounts
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//export async function getClientAllTransactionByDateRange(accountsClient, startDate, endDate) {
export async function getClientAllTransactionByDateRange(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/listByDateRange', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            startDate: startDate,
            endDate: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as transações de acordo com o regime de conta no caso de cliente com segregação
export async function getClientAllTransactionByDateRangeAndRegime(clientId, startDate, endDate, regimeId, orderByNumberApr) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/listByDateRangeAndRegime', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            regime_id: regimeId,
            startDate: startDate,
            endDate: endDate,
            order_by_number_apr: orderByNumberApr,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as amortizações de acordo com o regime de conta no caso de cliente com segregação
export async function getClientAllAmortizations(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/transactions/getClientAllAmortizations/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as transações de acordo com o regime de conta no caso de cliente com segregação
export async function getAllTransactionsUntilDateAndRegime(clientId, endDate, regimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/listAllTransactionsUntilDateAndRegime', {
            client_id: clientId,
            regime_id: regimeId,
            endDate: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as aprs de acordo com o regime de conta no caso de cliente com segregação e ordenado por número de APR
export async function getAprsByDateRangeAndRegime(clientId, startDate, endDate, regimeId, order) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getAprsByDateRangeAndRegime', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            regime_id: regimeId,
            startDate: startDate,
            endDate: endDate,
            order: order
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as aprs avulsas de acordo com o regime de conta no caso de cliente com segregação e ordenado por número de APR
export async function getSingleAprsByDateRangeAndRegime(clientId, startDate, endDate, regimeId, order) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getSingleAprsByDateRangeAndRegime', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            regime_id: regimeId,
            startDate: startDate,
            endDate: endDate,
            order: order
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo a apr para report
export async function getAprWithClientDataByIdTransaction(transactionId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getAprWithClientDataByIdTransaction', {
            transaction_id: transactionId,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo a single apr para report
export async function getSingleAprWithClientDataByIdTransaction(transactionId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getSingleAprWithClientDataByIdTransaction', {
            transaction_id: transactionId,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as transações para report de aprs em lote
export async function getAprsWithClientDataByDateRange(clientId, startDate, endDate, regimeId, order) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getAprsWithClientDataByDateRange', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            regime_id: regimeId,
            startDate: startDate,
            endDate: endDate,
            order: order,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo as transações para report de aprs avulsas em lote
export async function getSinglesAprsWithClientDataByYear(clientId, year, regimeId, order) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getSinglesAprsWithClientDataByYear', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            regime_id: regimeId,
            year: year,
            order: order,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Trazendo transações que possuem APRS
export async function getTransactionsByDateRangeWithAPR(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getTransactionsByDateRangeWithAPR', {
            client_id: clientId,
            startDate: startDate,
            endDate: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}


export async function getBlankTransactionsByClientIdAndDateRange(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/getBlankTransactionsByClientIdAndDateRange', {
            client_id: clientId,
            startDate: startDate,
            endDate: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateClientAprDataTransactions(clientId, newClientAprDataId, arrayTransactionsIds, referenceDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/updateClientAprDataTransactions', {
            client_id: clientId,
            new_client_apr_data_id: newClientAprDataId,
            arra_transactions_ids: arrayTransactionsIds,
            reference_date: referenceDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}


export async function checkImportedDataClient(clientId, validFirstDate, mode) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    // let paramAccounts = [];
    // for (let i = 0; i < accountsClient.length; i++) {
    //     paramAccounts.push(accountsClient[i].id);
    // }

    try {

        const response = await API.post('/transactions/checkImportedDataClient', {
            //inAccounts: paramAccounts,
            client_id: clientId,
            valid_first_date: validFirstDate,
            mode: mode,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function checkAlreadyImportedMonth(month, year, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/checkAlreadyImportedMonth', {
            month: month,
            year: year,
            client_id: clientId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function checkAlreadyImportedYear(year, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/checkAlreadyImportedYear', {
            year: year,
            client_id: clientId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getDatesWithTrasactions(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    // let paramAccounts = [];
    // for (let i = 0; i < accountsClient.length; i++) {
    //     paramAccounts.push(accountsClient[i].id);
    // }

    try {

        const response = await API.get('/transactions/getDatesWithTrasactions/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getYearsWithTrasactions(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/transactions/getYearsWithTrasactions/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateTransaction(transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/transaction/update', transaction, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateApr(transactionId, numberApr, aprDescription, clientAprDataId, editDescription) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("TransactionId: ", transactionId);

        const response = await API.put('/transaction/updateApr', {
            id: transactionId,
            number_apr: numberApr,
            apr_description_id: !editDescription ? aprDescription : null,
            client_apr_data_id: clientAprDataId,
            description: aprDescription,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateNumberApr(transactionId, newNumberApr) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("TransactionId: ", transactionId);

        const response = await API.put('/transaction/updateNumberApr', {
            id: transactionId,
            new_number_apr: newNumberApr,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateSingleApr(transactionId, numberApr, aprDescription, clientAprDataId, editDescription) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("TransactionId: ", transactionId);

        const response = await API.put('/transaction/updateSingleApr', {
            id: transactionId,
            number_apr: numberApr,
            apr_description_id: !editDescription ? aprDescription : null,
            client_apr_data_id: clientAprDataId,
            description: aprDescription,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteSingleTransaction(transactionId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {


        const response = await API.delete('/transaction/deleteSingleTransaction/' + transactionId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function cleanAPRtransaction(transactionId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("TransactionId: ", transactionId);

        const response = await API.put('/transaction/cleanAPRtransaction', { id: transactionId }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function cleanSingleAPRtransaction(transactionId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("TransactionId: ", transactionId);

        const response = await API.put('/transaction/cleanSingleAPRtransaction', { id: transactionId }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createClientAprData(clientAprData) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transaction/createClientAprData', clientAprData, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR CREATE CLIENT APR DATA: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteClientTransactionsInMonth(clientId, month) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transaction/deleteClientTransactionsInMonth', {
            client_id: clientId,
            month: month
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function checkDuplicateSingleTransaction(transaction) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/checkDuplicateSingleTransaction', transaction, config)

        return response.data;

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sumTransactionsInMonthByPeriod(clientId, startDate, endDate, search, segmentId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/sumTransactionsInMonthByPeriod', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            search: search ? search : '',
            segment_id: segmentId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function allClientsSumTransactionsByPeriodAndConsultingId(startDate, endDate, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/allClientsSumTransactionsByPeriodAndConsultingId', {
            start_date: startDate,
            end_date: endDate,
            consulting_id: consultingId
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sumTitlesTransactionsInMonthByPeriod(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/sumTitlesTransactionsInMonthByPeriod', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sumTransactionsInPeriodByFundAndOperation(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/sumTransactionsInPeriodByFundAndOperation', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            data: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sumTitlesTransactionsInPeriodByTitleAssetIdAndOperation(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/transactions/sumTitlesTransactionsInPeriodByTitleAssetIdAndOperation', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            data: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getTransactionsByAsset(assetId) {

    const config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/transactions/getTransactionsByAsset?asset_id=${assetId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}


