import React, { useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MyTextField from "./MyTextField";


const useStyles = customWidth => customPadding => customHeight => makeStyles((theme) => ({
    root: {

        background: 'white',
        borderRadius: '10px',

        '& .MuiInputBase-root': {

            padding: '0px !important',
        },

        '& input': {
            padding: customPadding ? customPadding + ' !important' : '11.5px 16px !important',
            height: customHeight ? customHeight : 'unset'
        },

        width: customWidth ? customWidth : '100%',
        '& .MuiFormControl-root': {
            margin: '0px',
            '& .MuiInputBase-root': {
                margin: '0px',
            }
        },

        '& *': {
            borderRadius: '10px',
            border: 'none !important',
        },
        '& .MuiOutlinedInput-input': {
            border: 'solid 1px #E4E7EB !important',

        },

        '& .MuiSvgIcon-root': {
            color: unoTheme.mainColor,
        },

        '& .MuiFormLabel-root': {
            marginTop: '-5px',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            display: 'none',
        },
        '& .MuiFormLabel-root.MuiFormLabel-filled': {
            display: 'none',
        },

        '& .MuiFormHelperText-root': {
            marginLeft: "0px",
        }

    },

    normalLayout: {
        '& .MuiTextField-root': {
            marginTop: '5px',
        },
        '& .MuiInputBase-input': {
            padding: '4.5px !important',
        },
    },
    label: myLabelInput,

    errorLabel: {
        color: 'red',
    }

}));

export default function MyAutoComplete(props) {

    const {
        label,
        outerlabel,
        normallayout,
        customWidth,
        customPadding,
        customHeight,
        validator,
        helperText
    } = props

    const classes = useStyles(customWidth)(customPadding)(customHeight)();

    return (
        <div className={normallayout ? classes.normalLayout : classes.root}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={[classes.label, validator ? classes.errorLabel : ""].join(" ")}
                        style={{ marginBottom: '5px' }}>
                        {outerlabel}
                    </Typography> : null
            }

            <Autocomplete
                {...props}
                renderInput={(params) => <TextField
                    error={validator}
                    helperText={validator && helperText ? helperText : ""}
                    label={label ? label : null} {...params}
                    margin="normal"
                    variant="outlined" />}
            />

        </div>


    )
}