import API from './config'

export async function createImprovement(improvement) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/improvements/createImprovement', improvement, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }
}

export async function updateImprovement(improvement) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/improvements/updateImprovement', improvement, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function deleteImprovement(id) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.delete('/improvements/deleteImprovement/' + id, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllImprovements() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/improvements/listAllImprovements', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllImprovementTypes() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/improvements/listAllImprovementTypes', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}
