import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";

//Router
import {
    Switch,
    Route
} from "react-router-dom";

// Screens
import FundsScreen from './FundsScreen';
import TitlesScreen from './TitlesScreen';

// Utils
import { MyTabs, MyTab } from "../../../utils/MyTabs";
import AddIcon from '@material-ui/icons/Add';
import UnoButton from '../../../utils/UnoButton';
import SearchBar from '../../../utils/SearchBar';

// Styles
import { useIndexStyles } from "./styles";
import { administrationFundsScreenPaths, path } from "../../../utils/routesPaths";
import menuItems from "../../../dashboard/menuItems";
import { updateAllFundInfosComdinheiro } from "../../../../API/fund";
import SelectDateModal from "../../../modals/SelectDateModal";
import { checkUserIsRole, roles } from "../../../utils/user";

/**
 * Componente para:
 * - Exibição de abas de fundos e títulos para administrador;
 * - Lidar com redirecionamento de abas;
 */
export default function FundsAndTitlesScreen({
    setShowMonths,
    setShowYears,
    locationOnScreen,
    setLocationOnScreen
}) {

    const classes = useIndexStyles();

    const currentUser = useSelector(state => state.currentUser)

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [search, setSearch] = useState("");

    const [tabValue, setTabValue] = useState(0);
    const IS_FUNDS_TAB = tabValue === 0;

    const [tabs,] = useState([
        {
            label: 'Fundos',
            path: path.administration.funds.all,
            disabled: false,
        },
        {
            label: 'Títulos',
            path: path.administration.funds.titles,
            disabled: false,
        }
    ]);

    const [flagDoReportBatchAprs, setFlagDoReportBatchAprs] = React.useState(0);

    function resetFlag() {

        setFlagDoReportBatchAprs(false);

    };

    const redirectTab = (newTabValue) => {
        tabs.forEach((tab, index) => {
            if (newTabValue === index) {
                return history.push(tab.path);
            }
        });
    };

    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);
        redirectTab(newValue);
    };

    function redirect() {
        const FUNDS_INDEX = 2;
        const PATH_OF_THE_FIRST_BACKOFFICE_SUBMENU = menuItems.backofficeMenu[FUNDS_INDEX].path;
        if (location.pathname === PATH_OF_THE_FIRST_BACKOFFICE_SUBMENU) {
            history.push(administrationFundsScreenPaths.home);
            const HOME_TAB = 0;
            setTabValue(HOME_TAB);
        };
    };

    const [openSelectDateModal, setOpenSelectDateModal] = useState();



    const doUpdateAllFundsInfosComdinheiro = async (date) => {

        console.log("props: ", date);
        const month = date.month() + 1;
        const year = date.year()

        const response = await updateAllFundInfosComdinheiro(month, year);
        console.log("response: ", response);
    }

    useEffect(() => {
        if ((location && !locationOnScreen)
            || (location && location.pathname != locationOnScreen.pathname)) {
            const isUserFirstTimeOnScreen = location.pathname === path.administration.funds.root;
            if (isUserFirstTimeOnScreen) {
                setShowMonths(false);
                setShowYears(false);
            };
            redirect();
            setLocationOnScreen(location);
        }
    }, [location]);

    return (
        <div className={classes.root}>

            <div className={classes.tabsWithRight}>
                <div className={classes.tabs}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}
                        aria-label="disabled tabs example">

                        {tabs.map((tab, index) => {
                            return <MyTab label={tab.label} value={index} disabled={tab.disabled} />
                        })}

                    </MyTabs>

                    {/* <div className={classes.bottomTabs}></div> */}
                </div>

                {IS_FUNDS_TAB ?
                    // <form>
                    <SearchBar
                        style={{ width: "200px" }}
                        name='search_query'
                        size='large'
                        mask='text_cnpj'
                        variant='outlined'
                        label='Nome ou CNPJ'
                        setSearchQuery={setSearch}
                    />
                    // </form>
                    : null}

                <div className={classes.rightHeader}>
                    {
                        IS_FUNDS_TAB ?

                            //Botões dos fundos                            

                            <UnoButton
                                variant="contained"
                                type='primary'
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'newFund',
                                }))}
                                startIcon={<AddIcon />}>
                                {"NOVO FUNDO"}
                            </UnoButton>

                            :
                            //Botões dos títulos   


                            <UnoButton
                                variant="contained"
                                type='primary'
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'newTitle',
                                }))}
                                startIcon={<AddIcon />}>
                                {"NOVO TÍTULO"}
                            </UnoButton>


                    }

                    {
                        checkUserIsRole(currentUser, [roles.superadmin]) &&
                        <UnoButton
                            variant="contained"
                            type='warning'
                            onClick={() => setOpenSelectDateModal(true)}>
                            {"ATUALIZAR INFOS COMDINHEIRO"}
                        </UnoButton>
                    }


                </div>
            </div>


            <Switch className={classes.switch}>
                <Route path={path.administration.funds.all}>
                    <FundsScreen search={search} />
                </Route>
                <Route path={path.administration.funds.titles}>
                    <TitlesScreen />
                </Route>
            </Switch>

            <SelectDateModal open={openSelectDateModal}
                handleAction={doUpdateAllFundsInfosComdinheiro} />
        </div>
    );
}



