import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";
//API
import { getAllUsersClientByRole } from '../../../../API/client';
import { updateStatus } from '../../../../API/user';
// Styles
import { headerSearch, unoTheme } from '../../../../assets/styles/unoTheme';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
// Utils
import { applyMaskPhone } from "../../../utils/utils";
import SituationCell from "../../../utils/SituationCell";
import MyTable from '../../../utils/MyTable';
import { roles } from '../../../utils/user.js';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
}));


export default function ClientSubManagersScreen({ clientOnScreen }) {

    const classes = useStyles();

    const rightContent = useSelector(state => state.rightContent)

    const [submanagers, setSubmanagers] = useState([]);

    const dispatch = useDispatch();

    const [appearFab, setAppearFab] = useState(true);

    async function doGetAllClientSubManagers(client) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando subgestores...'
        }));

        const response = await getAllUsersClientByRole(client.id, roles.submanager.id);
        const submanagersFromResponse = response.body.rows;

        if (response.success) {
            const isSubmanagersResponseEmpty = isEmpty(submanagersFromResponse);
            if (isSubmanagersResponseEmpty) {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem subgestores registrados' }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            };

            setSubmanagers(submanagersFromResponse);
        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    }

    async function updateStatusSubManager(e, usarId, status) {
        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Atualizando situação do subgestor...'
        }));

        const response = await updateStatus(usarId, status);
        if (response.success) {

            await doGetAllClientSubManagers(clientOnScreen);

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Situação do subgestor atualizada com sucesso"
            }));

            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    };

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        if (clientOnScreen) {
            doGetAllClientSubManagers(clientOnScreen);
        }

        if (rightContent && rightContent.action) {
            doGetAllClientSubManagers(clientOnScreen);
            dispatch(allActions.rightContentActions.setRightContent(null))
        };

        return () => {
            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        };

    }, [clientOnScreen, rightContent]);

    return (

        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable
                    onScroll={(value) => setAppearFab(value)}
                    stickyHeader
                    size="small"
                    selectable='true'>
                    <colgroup>
                        <col width="55%" />
                        <col width="20%" />
                        <col width="15%" />
                        <col width="10%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="center">Contato</TableCell>
                            <TableCell align="center">Situação</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submanagers.map((row, index) => (
                            <TableRow
                                key={'table-row-submanager-client-' + row.id}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'newClientSubManager',
                                    update: true,
                                    clientOnScreen,
                                    data: row
                                }))}>

                                <TableCell align="left" component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="center" >{applyMaskPhone(row.phone_number)}</TableCell>

                                <SituationCell align="center" data={row} updateStatus={updateStatusSubManager} />
                            </TableRow>
                        ))}
                    </TableBody>
                </MyTable>
            </div>

            <Zoom
                in={appearFab}
                unmountOnExit>
                <Fab className={classes.fabAdd}
                    onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                        path: 'newClientSubManager',
                        clientOnScreen
                    }))}>
                    <AddIcon />
                </Fab>
            </Zoom>
        </div >
    );
}


