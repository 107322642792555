import React, { useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myLabelInput } from "../../assets/styles/unoTheme";
import Switch from '@material-ui/core/Switch';


const MainSwitch = withStyles({
    switchBase: {
        //color: unoTheme.greenMoney,
        '&$checked': {
            color: unoTheme.mainColor,
        },
        '&$checked + $track': {
            backgroundColor: unoTheme.mainColor,
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',

        '& .MuiSwitch-root': {
            marginTop: '4px',
        }

    },
    disabled: {
        background: '#eeeeee'
    },
    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    noMarginTop: {
        marginTop: '5px'
    }


}));

export default function MyTextField(props) {

    const {
        outerlabel,
    } = props

    const classes = useStyles();

    return (
        <div className={classes.root}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={classes.label}>
                        {outerlabel}
                    </Typography> : null
            }

            <MainSwitch
                {...props} />

        </div>


    )
}