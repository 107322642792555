import { makeStyles } from '@material-ui/core/styles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import _ from 'lodash';

//API
import { createAccount, listClientAccounts } from '../../../API/account';
import {
    checkHasAssetsInClient,
    createBalanceValidation,
    getClientInvestmentsBalancesValidationsByYearAndRegime
} from '../../../API/client';
import { createAsset, getAllClientAssetsByRegime, getFundByCnpj } from '../../../API/fund';

import { Backdrop, Fade, Typography } from "@material-ui/core";
import { headerSearch, unoTheme } from '../../../assets/styles/unoTheme';
import { applyMaskAccount, applyMaskAmount, getConsultingId, stringPtDateToMoment } from "../../utils/utils";

//Tables
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MyTable from '../../utils/MyTable';

import Tooltip from '@material-ui/core/Tooltip';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

//Import excel
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';

//Utils
import { checkAssetCreatedAfterPortfolioClosed, isFileForClientOn, justNumbers, removeAccents } from '../../utils/utils';

import moment from 'moment';

import {
    compareValidationsToInsertOrUpdate,
    formatBalancesValidatioFromExcel
} from '../../../controllers/BalanceValidationController';

import PublishIcon from '@material-ui/icons/Publish';

import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { createTransaction } from "../../../API/transaction";
import {
    POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS,
    doGetAccountIdByNumberAccount,
    doGetAccountIdByNumberAccountAndAgency,
    doGetAccountIdByNumberAccountAndAgencyAndInstituitionCod,
    doGetAccountIdByNumberAccountAndInstituitionCod,
    doGetAccountIdByNumberAccountAndInstituitionCodAndCnpj,
    doGetInstituitionIdByInstituitionCod
} from '../../../controllers/AccountController';
import { checkIsClientAssetByCnpjAndAccount, formatBalancesAssetsValidationToShow, getFormattedAgencyAssetToValidations } from '../../../controllers/AssetsController';
import { PdfReaderController } from "../../../controllers/PdfReaderController";
import PluggyController from "../../../controllers/PluggyController";
import { SystemValidationsController } from "../../../controllers/SystemValidationsController";
import DataImportFromPdfModal from "../../modals/DataImportFromPdfModal";
import DataImportModalValidationBalances from '../../modals/DataImportModalValidationBalances';
import SelectInstituitionModal from "../../modals/SelectInstituitionModal";
import { checkUserIsRole, roles } from "../../utils/user";
import { useSpeedDial } from "./BalancesCollectScreen";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney,

    },
    greenIcon: {
        color: unoTheme.greenMoney,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }

    },
    pendent: {
        color: unoTheme.warningColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }
    },
    selectableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '& td:first-child': {
            paddingLeft: '16px !important',
        },
        '&:hover': {
            background: unoTheme.hoverTable,
        }

    },
    tabs: {
        height: '64px',
        minHeight: '64px',
        width: '100%',
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',

        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: unoTheme.mainColor,
        },

        '& .MuiTabs-indicator': {
            backgroundColor: unoTheme.mainColor,
        }
    },
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
    headerYear: {
        background: unoTheme.mainColor,
        color: 'white',
    },
    table: {

        '& .MuiTableRow-head': {
            borderRadius: '10px',
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 16px 16px 16px',
                background: 'white',
            },
        },

        '& .MuiTableCell-root': {

            padding: '4px',
            maxWidth: '0px',

            '& .spanAsset': {
                color: 'blue'
            }

        }

    },
    labelCell: {
        fontSize: '0.8em',
    },
    labelCellTop: {
        fontSize: '0.8em',
        fontWeight: 'bold'
    },
    labelCellBottom: {
        fontSize: '0.8em',
    },
    validInput: {
        cursor: 'pointer',
        '& input': {
            cursor: 'pointer',
        }
    },
    customDisable: {
        '& .MuiOutlinedInput-root': {
            background: "#e7ffe5 !important",
        },
    },
    smallText: {
        fontSize: '.9em'
    },

    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    input: {
        display: 'none',
    },



}));

export default function ValidationFundsScreen(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)
    const rightContent = useSelector(state => state.rightContent)
    const scrollDialog = useSelector(state => state.scrollDialog);

    const updateClientItemId = useMemo(() => scrollDialog?.update, [scrollDialog]);

    const [clientOnScreen, setClientOnScreen] = useState(undefined);
    const [regimeOnScreen, setRegimeOnScreen] = useState(undefined);
    const [assetsClient, setAssetsClient] = React.useState(undefined);
    const [appearFab, setAppearFab] = useState(true);

    //Import Excel
    const inputFile = useRef(null);
    const [file, setFile] = useState(undefined);
    const [data, setData] = useState([]);
    const [dataListFormatted, setDataListFormatted] = useState(undefined);

    //Import PDF extratos    
    const inputFilePDf = useRef(null);
    const [filesPDFs, setFilesPDFs] = useState(undefined);

    const [dataToTables, setDataToTables] = React.useState(undefined);

    //Modal validação from excel
    const [showImportModal, setShowImportModal] = useState(false);
    const [newValidationsOK, setNewValidationsOK] = useState(undefined);
    const [dataToInsert, setDataToInsert] = useState(undefined);

    function handleModalImport(open) {
        setFile(undefined);
        setShowImportModal(open);
    }
    //Modal validação from pdfs
    const [showImportFromPdfModal, setShowImportFromPdfModal] = useState(false);
    const [dataToInsertFromPdf, setDataToInsertFromPdf] = useState(undefined);

    function handleModalImportFromPdf(open) {
        setFilesPDFs(undefined);
        setShowImportFromPdfModal(open);
    }

    async function doGetClientBalancesValidations(selectedYear, clientId, regime) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...'
        }));

        //const responseAssets = await getAllClientAssets(clientOn.client.id);
        const responseAssets = await getAllClientAssetsByRegime(clientOn.client.id, regime);
        if (responseAssets.success) {

            console.log('ASSETS CLIENT: ', responseAssets.body.rows)
            setAssetsClient(responseAssets.body.rows)

        } else {

            console.log("ERROR ASSETS: ", responseAssets.error)

        }

        //const responseBv = await getClientInvestmentsBalancesValidationsByYear(selectedYear, clientId);
        const responseBv = await getClientInvestmentsBalancesValidationsByYearAndRegime(selectedYear, clientId, regime);

        if (responseBv.success) {

            console.log("RESPONSE: ", responseBv)
            setDataToTables(formatBalancesAssetsValidationToShow(selectedYear,
                responseAssets.body.rows,
                responseBv.body.rows));

        } else {
            console.log("error: ", responseBv.error)
        }


        if (responseAssets && responseAssets.body && responseAssets.body.rows
            && responseAssets.body.rows.length == 0) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: 'Este cliente ainda não possui ativos cadastrados'
            }));
        } else {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }
    }

    async function updateBalancesTable(clientId, assets, selectedYear, regime) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...'
        }));

        //const responseBv = await getClientInvestmentsBalancesValidationsByYear(selectedYear, clientId);
        const responseBv = await getClientInvestmentsBalancesValidationsByYearAndRegime(selectedYear, clientId, regime);

        if (responseBv.success) {

            console.log("RESPONSE: ", responseBv)
            setDataToTables(formatBalancesAssetsValidationToShow(selectedYear,
                assets,
                responseBv.body.rows));

        } else {
            console.log("error: ", responseBv.error)
        }

        if (assets && assets.length == 0) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: 'Este cliente ainda não possui ativos cadastrados'
            }));
        } else {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
            }));
        }


    }

    const openFundDetails = useCallback((data) => {
        dispatch(allActions.rightContentActions.setRightContent({
            path: 'detailBalanceValidation',
            data,
        }));
    }, [dispatch]);

    function renderItems(mode, obj, selectedYear) {

        let monthsItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

        return _.map(monthsItems, (m) => {

            let element = {}
            let key = {}

            const mainCod = obj.fund_name + "=" + obj.number_account + getFormattedAgencyAssetToValidations(obj.agency);

            // console.log("MONTANDO: ");
            // console.log(obj.fund_name);
            // console.log(obj.number_account);
            // console.log(obj);

            element =
                dataToTables
                ['assets'] //accounts ou assets
                [mainCod]
                [m];

            key = mainCod

            if (element) {

                if (element.balance_status) {

                    // console.log("M: ", m)
                    // console.log("INIT: ", obj.main.init)

                    if (m >= obj.main.init) {
                        return (

                            <TableCell
                                key={key + "-" + m}
                                align="center"
                                className={[classes.validInput, classes.green].join(' ')}
                                onClick={() => openFundDetails(element)}>
                                <Tooltip
                                    title={applyMaskAmount(element.balance ? element.balance : 0, true)}
                                    placement="top"
                                    arrow>
                                    <Typography className={[classes.smallText, element.balance_status ? classes.greenIcon : classes.pendent].join(' ')}>
                                        <CheckCircleIcon />
                                    </Typography>
                                </Tooltip>
                            </TableCell>

                        )
                    } else {
                        return (
                            <TableCell
                                key={key + "-" + m}
                                align="center" >
                                <Typography className={classes.smallText}>
                                    {'-'}
                                </Typography>
                            </TableCell>
                        )
                    }
                } else {

                    // console.log("M: ", m)
                    // console.log("INIT: ", obj.main.init)

                    if (m >= obj.main.init) {
                        return (

                            <TableCell
                                key={key + "-" + m}
                                align="center"
                                className={[classes.validInput, classes.green].join(' ')}
                                onClick={() => openFundDetails(element)}>
                                <Typography className={[classes.smallText, element.balance_status ? classes.green : classes.pendent].join(' ')}>
                                    <ReportProblemIcon />
                                </Typography>
                            </TableCell>
                        )
                    } else {
                        return (
                            <TableCell
                                key={key + "-" + m}
                                align="center" >
                                <Typography className={classes.smallText}>
                                    {'-'}
                                </Typography>
                            </TableCell>
                        )
                    }


                }

                // }

            }

        })
    }

    function renderLodashAssets(assets, selectedYear) {

        return _.map(assets, (row, key) => {

            row.selectedYear = selectedYear;

            const mainCod = row.fund_name + "=" + row.number_account + getFormattedAgencyAssetToValidations(row.agency);
            row.main =
                dataToTables
                ['assets']
                [mainCod]

            if (row && row.main && row.main.init != 0) {
                return (

                    <TableRow key={key + '-balance-asset'}
                        className={classes.selectableRow}
                        onClick={() => openFundDetails(row)}>
                        <TableCell align="left">
                            <Typography className={classes.labelCellTop} noWrap>{'CC: ' + (row.number_account ? applyMaskAccount(row.number_account) : '-')}</Typography>
                            <Typography className={classes.labelCellBottom} noWrap>{row.fund_name}</Typography>
                            <div style={{ color: 'black', fontWeight: '900', fontSize: '12px' }}>{row.cnpj}</div>
                        </TableCell>

                        {renderItems('assets', row, selectedYear)}

                    </TableRow >
                )
            }
        })
    }

    //Import Excel
    function resetImportVariables() {

        console.log("Reset")
        setFile(undefined);
        setData([]);
        setDataListFormatted(undefined);

    }

    //PDf reset
    function resetImportVariablesPdfs() {

        console.log("Reset")
        setFilesPDFs(undefined)

    }

    function handleChangeFile(e) {

        console.log("Change file")
        const files = e.target.files;
        if (files && files[0]) {
            setFile(files[0])
        }

        e.target.value = null;

    };

    //Import pdf extrato
    function handleChangeFilesPDFs(e) {

        const files = e.target.files;
        if (files && files.length) {
            const arrayFiles = [];
            Object.entries(files).map(([key, file]) => {
                console.log("file: ", file);
                arrayFiles.push(file);
            });
            setFilesPDFs(arrayFiles);
            // setFilesPDFs(files[0]);
        }

        e.target.value = null;

    };

    const { transitionDuration, useStyles: speedDialUseStyles } = useSpeedDial();
    const speedDialClasses = speedDialUseStyles();

    const [openFab, setOpenFab] = useState(false);

    const handleCloseFab = () => {
        setOpenFab(false);
    };

    function doClickinputFile() {
        handleCloseFab();
        inputFile.current.click();
    }

    function doClickinputPDFFile() {
        handleCloseFab();
        inputFilePDf.current.click();
    }

    const balancesSheetName = 'SALDOS';

    function handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            //const wsname = wb.SheetNames[0];
            const wsname = balancesSheetName;
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);

            console.log("FILE CONVERTIDO: ", data);

            /* Update state */
            //setData([])
            //console.log("DATA: ", data);
            setData(data);
            setFile(undefined);

        };

        if (rABS) {
            //reader.readAsBinaryString(this.state.file);
            reader.readAsBinaryString(file);
        } else {
            //reader.readAsArrayBuffer(this.state.file);
            reader.readAsArrayBuffer(file);
        };
    }

    //Busca o primeiro saldo válido do asset
    //Pois o ativo pode começar no meio do ano, a coluna dez_X_valor estaria vazia
    //Também retorna naturalmente o início do asset de acordo com a primeira coluna preenchida    
    function getBalanceAndInitByLine(validationLoop, year) {
        // Adicionando parâmetro [isFirstInputBalances] que indica se o ativo deve ser criado com o saldo anterior informado ou saldo 0
        //Caso apresentado no cliente CAÇADOR - SC

        if (validationLoop['dez_' + year + '_valor'] != null) {

            console.log("RETORNOU INIT: ", validationLoop['dez_' + year + '_valor']);
            return {
                balance: validationLoop['dez_' + year + '_valor'],
                quota_amount: validationLoop['dez_' + year + '_cota'],
                init: '01/12/' + year,
                isTransaction: false,
            };
        }

        console.log("NÃO RETORNOU INIT: ", validationLoop['dez_' + year + '_valor']);

        let months = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'
        ]

        //OLD COD

        //Vai testando as colunas até achar a primeira preenchida
        for (let i = 0; i < months.length; i++) {

            //mReturn tem que ser semre 1 mês anterior
            //Caso seja 0 deve ser o mês 12
            let mReturn = i;
            let yReturn = year;
            if (mReturn == 0) {
                mReturn = 12;
                yReturn--;
            }
            if (mReturn.toString().length == 1) {
                mReturn = "0" + mReturn;
            }

            if (validationLoop[months[i] + '_valor'] != null) {

                console.log("NAO INIT X: ", '01/' + mReturn + '/' + (yReturn + 1))
                return {
                    balance: 0,
                    balanceInValidation: validationLoop[months[i] + '_valor'],
                    //balance: validationLoop[months[i] + '_valor'],
                    quota_amount: 0,
                    quotaInValidation: validationLoop[months[i] + '_cota'],
                    //quota_amount: validationLoop[months[i] + '_cota'],
                    init: '01/' + mReturn + '/' + (yReturn + 1),

                    //Caso o ativo comece no meio do ano deve-se sinalizar isTransaction para que 
                    //no método createAsset seja criado um balance_validation com valor 0 para o mês anterior                    
                    isTransaction: true,
                };
            }
        }

    }

    function normalizeIfFirstInputBalance(assets) {

        let olderInit = null;
        //Obtendo ativo mais antigo na carteira
        assets.forEach(assetLoop => {

            console.log('assetLoop: ', assetLoop);
            if (olderInit == null) {
                olderInit = assetLoop.asset_init;
            } else {
                if (assetLoop.asset_init && stringPtDateToMoment(assetLoop.asset_init, true).isBefore(stringPtDateToMoment(olderInit, true))) {
                    olderInit = assetLoop.asset_init;
                }
            }

        });

        if (olderInit != null && olderInit.split('/')[1] != 12) { //indica que não são ativos que começam no início do ano
            //Caso sejam ativos que começam no ínicio do ano nem é necessária alteração do
            console.log('ENTROU NORMALIZACAO DE SALDOS INICIAIS');
            assets.forEach(assetLoop => {

                if (assetLoop.asset_init == olderInit) {
                    assetLoop.balance = assetLoop.balanceInValidation;
                    assetLoop.quota_amount = assetLoop.quotaInValidation;
                }

            });
        }

    }

    async function doPreInsertsValidation(validations, invalidColumns, transactionsToInsert, transactionsInExtract, invalidExtracts, instituitionPdfs) {

        console.log("VALIDATIONS TTT: ", _.cloneDeep(validations));

        const noVincAccountsTesteds = [];

        const fundsWithClasses = [];

        let clientId = clientOn && clientOn.client ? clientOn.client.id : null;
        let clientPortfolioInit = clientOn && clientOn.client ? clientOn.client.portfolio_init : null;
        const clientPortfolioClosed = clientOn && clientOn.client ? clientOn.client.portfolio_closed : null;
        let clientYear = clientOn && clientOn.client ? clientOn.client.selectedValidationYear : null;
        let numbersAccountsToCreate = [];
        let accountsToCreate = [];
        let accountsWhite = 0;
        let assetsInvalidAccount = [];
        let invalidsFunds = [];
        let invalidsInstituitions = [];
        let instituitions = [];
        let blankAssets = [];

        //campos cnpjs inconsistentes
        let invalidCnpjs = [];

        let assetsToCreate = [];

        let mapFunds = [];

        let duplicatedAccounts = [];

        //Utilizado para verificar a validade da planilha, com movimentações apenas do mesmo ano
        let yearsToInsert = [];

        console.log("Validations: ", validations);
        console.log("invalidColumns: ", invalidColumns);

        if (validations && validations.length > 0
            && invalidColumns && invalidColumns.length == 0) {

            const hasAssets = await checkHasAssetsInClient(clientId);
            const isFirstInputBalances = !hasAssets;
            console.log('isFirstInputBalances: ', isFirstInputBalances);

            //Verificar inconsistência na sequência, caso hajam criações de ativos de mês anterior eles devem vir todos no topo da lista       
            for (let i = 0; i < validations.length; i++) {

                let validationLoop = validations[i];

                ///////////////////////////////////////////////
                ///////INICIO DA VERIFICAÇÃO DAS CONTAS////////
                //////////////////////////////////////////////

                //Corrigindo quando a conta tiver em formato inteiro
                //Ex: BB 1 quando deveria ser 001
                validationLoop['cnpj'] = validationLoop['cnpj'].toString();
                if (validationLoop['cnpj'].length < 3 && validationLoop['cnpj'] != null) {
                    validationLoop['cnpj'] = validationLoop['cnpj'].padStart(3, "0");
                }

                if (validationLoop['cnpj'].length === 3 && !instituitions.includes(validationLoop['cnpj'])) {

                    instituitions.push(validationLoop['cnpj']);
                }

                if (validationLoop['cc']) {
                    validationLoop['cc'] = validationLoop['cc'].toString();

                    //Indicando que existe uma agência
                    if (validationLoop['cc'].includes('/')) {
                        const splittedCC = validationLoop['cc'].split('/');
                        validationLoop['cc'] = parseInt(justNumbers(splittedCC[0]))
                        validationLoop['agency'] = parseInt(justNumbers(splittedCC[1]))
                        validationLoop['agency'] = validationLoop['agency'].toString()
                    } else {
                        validationLoop['cc'] = parseInt(justNumbers(validationLoop['cc']))
                    }

                    validationLoop['cc'] = validationLoop['cc'].toString()
                }


                //indicando que é conta
                if (validationLoop['cc'] && clientId
                    && validationLoop['cnpj'].length == 3) {

                    console.log('validationLoop[agency]: ', validationLoop['agency']);

                    if (validationLoop['agency']) {
                        validationLoop.account_id = await doGetAccountIdByNumberAccountAndAgencyAndInstituitionCod(
                            clientId,
                            validationLoop['cc'],
                            validationLoop['agency'],
                            validationLoop['cnpj']
                        );
                    } else {
                        validationLoop.account_id = await doGetAccountIdByNumberAccountAndInstituitionCod(
                            clientId,
                            validationLoop['cc'],
                            validationLoop['cnpj']
                        );
                    }

                    console.log("RETORNO CONTA: ", validationLoop.account_id);

                } else {

                    validationLoop.account_id = 'NOT_FOUND';
                    accountsWhite++;

                }



                //Evitando interferências de espaços no CNPJ
                validationLoop['cnpj'] = validationLoop['cnpj'].trim();

                if (validationLoop.account_id == 'NOT_FOUND') {

                    if (validationLoop['cc'] && validationLoop['cnpj'].length == 3) { //indica que é conta

                        console.log("Conta não encontrada: ", validationLoop);

                        const numberAccountAndCodInstituition = justNumbers(validationLoop['cc']) + '-' + validationLoop['cnpj'];

                        //if (!numbersAccountsToCreate.includes(justNumbers(validationLoop['cc']))) { //TO-DO adicionar validação de codigo da instituição junto da conta
                        if (!numbersAccountsToCreate.includes(numberAccountAndCodInstituition)) { //TO-DO adicionar validação de codigo da instituição junto da conta

                            let responseInstituition = await doGetInstituitionIdByInstituitionCod(validationLoop['cnpj']);
                            //console.log("insittuition: ", responseInstituition);

                            if (responseInstituition == 'NOT_FOUND') {

                                invalidsInstituitions.push(validationLoop['cnpj']);

                            } else {

                                let accountRegimeId = 1; //Previdênciário por default
                                if (validationLoop['segregacao'] && validationLoop['segregacao'] != "") {

                                    let segregatioToTeste = removeAccents(validationLoop['segregacao']).toUpperCase();
                                    switch (segregatioToTeste) {
                                        case 'PREVIDENCIARIO':
                                            accountRegimeId = 1;
                                            break;
                                        case 'FINANCEIRO':
                                            accountRegimeId = 2;
                                            break;
                                        case 'TAXA ADMINISTRATIVA':
                                            accountRegimeId = 3;
                                            break;

                                    }
                                }

                                numbersAccountsToCreate.push(numberAccountAndCodInstituition);

                                console.log("CHAMANDO BALANCE AND INIT: ");

                                let balanceAndInit = getBalanceAndInitByLine(validationLoop, (clientYear - 1));

                                console.log("BALANCE AND INIT: ", balanceAndInit);

                                accountsToCreate.push({

                                    client_id: clientId,
                                    instituition_id: responseInstituition.id,
                                    instituition_name: responseInstituition.description,
                                    // account_init: moment.utc(clientPortfolioInit).format('DD/MM/YYYY'),
                                    account_init: balanceAndInit ? balanceAndInit.init : null,
                                    balance: balanceAndInit ? balanceAndInit.balance : null,
                                    account_regime_id: accountRegimeId,
                                    agency: validationLoop['agencia'],
                                    number_account: justNumbers(validationLoop['cc'])

                                });
                            }


                        }
                    }

                }


                ///////////////////////////////////////////////
                ///////INICIO DA VERIFICAÇÃO DOS ATIVOS////////
                //////////////////////////////////////////////

                let fundId = undefined;

                if (validationLoop['cnpj']
                    && validationLoop['cnpj'].length == 18) { //indicando que é um fundo
                    let responseFundByCnpj = await getFundByCnpj(validationLoop['cnpj']);

                    //Verificando se há um ativo para criação na conta sem vínculo, caso exista, verifica-se se a conta sem vínculo já está criada
                    //Caso não esteja inclui-se ela na criação
                    if (POSSIBLE_NO_VINC_ACCOUNTS_NUMBERS.includes(validationLoop['cc']) && !noVincAccountsTesteds.includes(validationLoop['cc'])) {

                        noVincAccountsTesteds.push(validationLoop['cc']);

                        //verificando se a conta já existe          
                        let maybeExists = await doGetAccountIdByNumberAccount(clientId, validationLoop['cc']);

                        const numberAccountAndCodInstituition = justNumbers(validationLoop['cc']) + '-' + validationLoop['cnpj'];

                        if (!numbersAccountsToCreate.includes(numberAccountAndCodInstituition) && maybeExists == "NOT_FOUND") {

                            let responseInstituition = await doGetInstituitionIdByInstituitionCod('000');

                            let accountRegimeId = 1; //Previdênciário por default                        

                            numbersAccountsToCreate.push(numberAccountAndCodInstituition);

                            accountsToCreate.push({

                                client_id: clientId,
                                instituition_id: responseInstituition.id,
                                instituition_name: responseInstituition.description,
                                account_init: moment.utc(clientPortfolioInit).format('DD/MM/YYYY'),
                                balance: 0,
                                account_regime_id: accountRegimeId,
                                agency: validationLoop['agency'] ? validationLoop['agency'] : "",
                                number_account: validationLoop['cc']

                            });

                        }
                    }

                    //Existe o fundo cadastrado na base de dados
                    if (responseFundByCnpj.success
                        && responseFundByCnpj.body.rows[0] != undefined) {

                        if (responseFundByCnpj.body.rows.length > 1) {
                            console.log("EXISTE FUNDO CLASSE B: ", responseFundByCnpj);
                            console.log('validationLoop.account_id: ', validationLoop.account_id);

                            if (fundsWithClasses.length == 0) {

                                fundsWithClasses.push.apply(fundsWithClasses, responseFundByCnpj.body.rows);

                                console.log('fundsWithClasses: ', fundsWithClasses);
                                console.log('validationLoop[cnpj]: ', validationLoop['cnpj']);
                                console.log('validationLoop.account_id: ', validationLoop.account_id);
                            }
                        }

                        fundId = responseFundByCnpj.body.rows[0].id;



                        //Checando se o cliente já possui esse Ativo
                        let assetId = null;
                        if (validationLoop.account_id != "NOT_FOUND") {

                            assetId = await checkIsClientAssetByCnpjAndAccount(
                                validationLoop['cnpj'],
                                validationLoop.account_id
                            )

                        } else if (validationLoop.account_id == "NOT_FOUND") {

                            console.log('BUSCANDO ATIVO: ', validationLoop);
                            if (validationLoop['agency']) {
                                validationLoop.account_id = await doGetAccountIdByNumberAccountAndAgency(clientId, validationLoop['cc'], validationLoop['agency']);
                            } else {
                                validationLoop.account_id = await doGetAccountIdByNumberAccount(clientId, validationLoop['cc']);
                            }
                            //validationLoop.account_id = await doGetAccountIdByNumberAccountAndInstituitionCod(clientId, validationLoop['cc']);

                            if (validationLoop.account_id == 'CONTAS_DUPLICADAS') {

                                duplicatedAccounts.push({ cnpj: validationLoop['cnpj'], cc: validationLoop['cc'] });

                            } else {

                                assetId = await checkIsClientAssetByCnpjAndAccount(
                                    validationLoop['cnpj'],
                                    validationLoop.account_id
                                )

                                if (assetId != null
                                    && fundsWithClasses.some(el => el.cnpj == validationLoop['cnpj'])) {
                                    fundsWithClasses.pop();
                                }

                            }

                        }

                        if (validationLoop['cc'] == "0") {

                            assetsInvalidAccount.push({
                                account: validationLoop['cc'],
                                fund: validationLoop['nome_ativo'],
                            })
                        } else {
                            if (validationLoop.account_id != 'CONTAS_DUPLICADAS') {
                                if (!assetId
                                    &&
                                    (assetsToCreate
                                        .filter(el =>
                                            el.fund_id == fundId
                                            && el.account_id == validationLoop.account_id
                                            && el.number_account == validationLoop['cc'])).length == 0) {  //Checando se já não é um ativo a ser criado

                                    console.log('CRIAÇÃO DO ATIVO:');

                                    //Caso não possua o ativo desse ser criado                        
                                    let balanceAndInit = getBalanceAndInitByLine(validationLoop, (clientYear - 1));
                                    const asset = {
                                        account_id: validationLoop.account_id,
                                        number_account: validationLoop['cc'],
                                        fund_id: fundId,
                                        balance: balanceAndInit ? balanceAndInit.balance : null,
                                        balanceInValidation: balanceAndInit ? balanceAndInit.balanceInValidation : null,
                                        fundInfos: responseFundByCnpj.body.rows[0],
                                        asset_init: balanceAndInit ? balanceAndInit.init : null,
                                        quota_amount: balanceAndInit ? balanceAndInit.quota_amount : null,
                                        quotaInValidation: balanceAndInit ? balanceAndInit.quotaInValidation : null,
                                        isTransaction: balanceAndInit ? balanceAndInit.isTransaction : null,
                                        balanceSameInit: balanceAndInit ? balanceAndInit.isTransaction : null, //flag utilizada para criar a validação de saldo corretamente
                                        //No caso de ser um saldo criado da planilha de saldos a data de inicio do ativo já é a data a ser criado balance_validation com valor 0
                                    }

                                    assetsToCreate.push(asset);

                                }
                            }

                        }

                        mapFunds[validationLoop['cnpj'] + "=" + validationLoop['cc']] = fundId;

                    } else {

                        //console.log("ERROR FUND NOT FOUND: ", validationLoop['cnpj'])

                        let filtered = invalidsFunds.filter(el => el.cnpj == validationLoop['cnpj']);
                        if (filtered && filtered.length == 0) {
                            invalidsFunds.push({
                                fund_name: validationLoop['nome_ativo'],
                                cnpj: validationLoop['cnpj']
                            });
                        }

                    }

                } else {

                    console.log("LINHA QUE NÃO FOI INDICADA COMO FUNDO: ", validationLoop);

                }

                if (validationLoop['cnpj'] &&
                    (validationLoop['cnpj'].lenght > 3
                        && validationLoop['cnpj'].length < 18)
                    ||
                    (validationLoop['cnpj'].length > 18)) {


                    invalidCnpjs.push(validationLoop['cnpj']);

                }



            }

            //Normalizar assets relativo a isFirstInputBalances
            if (isFirstInputBalances) {
                normalizeIfFirstInputBalance(assetsToCreate);
            }
        }

        blankAssets = assetsToCreate.filter(el => el.asset_init === null);

        if (fundsWithClasses.length == 1) {
            fundsWithClasses.pop();
        }

        if (invalidColumns.length > 0
            || accountsToCreate.length > 0
            || invalidsFunds.length > 0
            || accountsWhite > 0
            || validations.length == 0
            || invalidsInstituitions.length == 0
            //|| yearsToInsert.length != 1
            || assetsInvalidAccount.lenght > 0
            || duplicatedAccounts.length > 0
            || invalidCnpjs.length > 0
            || blankAssets.length > 0
            || fundsWithClasses
        ) {
            console.log("Não será possível realizar as inserções")
            console.log("Colunas não encontradas: ", invalidColumns)
            console.log("Contas: ", accountsToCreate)
            console.log("Contas em branco: ", accountsWhite)
            console.log("Fundos: ", invalidsFunds)
            console.log("Instituitions: ", invalidsInstituitions)
            console.log("Anos: ", yearsToInsert)
            console.log("Invalid assets: ", assetsInvalidAccount)
            console.log("duplicatedAccounts: ", duplicatedAccounts)
            console.log("fundsWithClasses: ", fundsWithClasses)
            //console.log("Meses: ", monthsToInsert)
            // dispatch(allActions.mainAlertActions.setMainAlert({
            //     show: true,
            //     type: 'warning',
            //     message: "Existem contas ou fundos não cadastradas, Contas: " + invalidsAccounts + " / " + "Fundos: " + invalidsFunds + " / " + "Anos:" + yearsToInsert
            // }))
        }

        let validationsOK = [];
        let validationsNotOK = [];

        //Verificando se todos os saldos informados são do ano selecionado no select de ano
        if (invalidColumns.length == 0) {

            validations.forEach(v => {


                if (v['nome_ativo'] != ""
                    && v['nome_ativo'] != " "
                    && v['segregacao']) {

                    //console.log("V: ", v);

                    //Verificando se há a coluna 'dez_ANO-ANTERIOR_valor' que indica que está sendo 
                    //informada a planilha do ano correto
                    // if (v['dez_' + (parseInt(clientOn.client.selectedValidationYear) - 1) + '_valor'] != undefined) {
                    //     validationsOK.push(v);
                    // } else {
                    //     validationsNotOK.push(v);
                    // }

                    console.log("VALIDATION HERE: ", _.cloneDeep(v));
                    validationsOK.push(v);

                }
            });
        }

        setNewValidationsOK(validationsOK);

        setDataListFormatted(undefined);
        setData([]);

        const invalidInitAssets = checkAssetCreatedAfterPortfolioClosed(assetsToCreate.filter(el => el.asset_init), clientPortfolioClosed);

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))

        const toInsert = {
            client: clientOn.client,

            assets: assetsToCreate,
            blankAssets: blankAssets,
            //Ativos com inicio irregular, inicio para antes do fechamento da carteira
            invalidInitAssets: invalidInitAssets,

            invalidColumns: invalidColumns,
            accountsToCreate: accountsToCreate,
            invalidsFunds: invalidsFunds,
            invalidsInstituitions: invalidsInstituitions,
            accountsWhite: accountsWhite,
            assetsInvalidAccount: assetsInvalidAccount,
            yearsToInsert: yearsToInsert,

            invalidCnpjs: invalidCnpjs,

            validations: validations,

            selectedValidationYear: clientOn.client.selectedValidationYear,
            clientPortfolioClosed: clientPortfolioClosed,

            validationsNotOK: validationsNotOK,
            invalidColumns: invalidColumns,

            duplicatedAccounts: duplicatedAccounts, //Caso em que por algum motivo houve duplicação das contas
            instituitions: instituitions,

            fundsWithClasses: fundsWithClasses,

        }

        if (!transactionsToInsert) {
            setDataToInsert(toInsert);
            handleModalImport(true);
        } else {


            const normalizedAccountsFromPdf = await PdfReaderController.normalizeAccountsAssetsAndTransactions(toInsert, transactionsToInsert, transactionsInExtract, instituitionPdfs);
            toInsert.normalizedAccountsFromPdf = normalizedAccountsFromPdf;
            toInsert.invalidExtracts = invalidExtracts;
            setDataToInsertFromPdf(toInsert);
            handleModalImportFromPdf(true);
        }




    }

    async function initImport(changesToImport) {

        setShowImportModal(false);

        if (changesToImport && changesToImport.dupplicatedAccounts) {
            console.log('dataToInsert: ', dataToInsert);
            console.log('changesToImport: ', changesToImport);
            //Em caso de contas duplicadas fazer a revalidação das contasDuplicadas

            let allSuccess = true;
            for (let i = 0; i < dataToInsert.assets.length; i++) {
                const element = dataToInsert.assets[i];
                if (element.account_id == 'CONTAS_DUPLICADAS') {

                    const acc = changesToImport.dupplicatedAccounts.find(accDupplicated => accDupplicated.cnpj === element.fundInfos.cnpj);

                    //element.account_id = await doGetAccountIdByNumberAccountAndInstituitionCod(dataToInsert.client.id,
                    element.account_id = await doGetAccountIdByNumberAccountAndInstituitionCodAndCnpj(dataToInsert.client.id,
                        element.number_account,
                        dataToInsert.instituitions[parseInt(acc.instituitionSelected)],
                        element.fundInfos.cnpj
                    );

                    if (element.account_id == 'NOT_FOUND') {

                        dispatch(allActions.mainAlertActions.setMainAlert({
                            show: true,
                            type: 'warning',
                            message: 'Informações inconsistentes. Verifique se as instituições foram informadas corretamente'
                        }))
                    }

                }
            }

        }
        doInsertsValidation(dataToInsert);

    }

    const formatInitDate = (init) => {
        const splited = init.split('/');
        const m = parseInt(splited[0]);
        const prevM = m - 1;
        const finalM = prevM === 0 ? 12 : prevM.toString().padStart(2, "0");
        const y = prevM === 0 ? parseInt(splited[1]) - 1 : splited[1];
        return '01/' + finalM + '/' + y;

    }

    async function initImportFromPdf(regimesNewAccountsStructure) {

        setShowImportFromPdfModal(false);

        console.log("dataToInsertFromPdf: ", dataToInsertFromPdf);
        //Formatando contas a serem criadas
        const { normalizedAccountsFromPdf } = dataToInsertFromPdf;
        const clientId = dataToInsertFromPdf.client.id;
        console.log("regimesNewAccountsStructure: ", regimesNewAccountsStructure);


        const accountsToCreate = normalizedAccountsFromPdf.filter(el => el.isNew).map(el => ({
            client_id: clientId,
            instituition_id: el.instituitionId,
            account_regime_id: regimesNewAccountsStructure[el.accountId],
            number_account: el.cc,
            agency: el.agency,
            balance: 0,
            account_init: formatInitDate(el.init),
        }))

        console.log("accountsToCreate: ", accountsToCreate);

        doInsertsValidationFromPdf(dataToInsertFromPdf, accountsToCreate);
    }

    async function doInsertsValidation(dataToInsert) {

        console.log("DATA TO INSERT: ", dataToInsert)

        //Verificando contas a serem criadas
        if (dataToInsert.accountsToCreate
            && dataToInsert.accountsToCreate.length > 0) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando contas..."
            }))

            for (let i = 0; i < dataToInsert.accountsToCreate.length; i++) {

                const acc = dataToInsert.accountsToCreate[i];
                const responseAcc = await createAccount(acc);

                if (responseAcc.success) {

                    console.log("RESPONSE ACC: ", responseAcc.body);
                    //Inserir id da conta no asset        
                    //Caso existam assets a serem criados verifica-se se tinha algum esperando por um id de conta
                    if (dataToInsert.assets
                        && dataToInsert.assets.length > 0) {

                        let assetsWaitingAcc = dataToInsert.assets.filter(el =>
                            el.account_id == "NOT_FOUND"
                            && el.number_account == responseAcc.body.account.number_account
                        );

                        assetsWaitingAcc.forEach(element => {

                            element.account_id = responseAcc.body.account.id;

                        });

                    }
                }
            }


        }

        //console.log("ASSETS TO CREATE: ", dataToInsert.assets);

        //Utilizado para garantir que na formatação das validações todos os assets (já cadastrados e os novos cadastrados)
        //Estejam disponíveis para montar os objetos de validação

        //Para caso de haver erro na criação dos ativos
        let assetsErrorToCreate = [];

        let listLocalClientAssets = assetsClient ? assetsClient : [];
        if (dataToInsert.assets
            && dataToInsert.assets.length > 0) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando Ativos..."
            }))

            let assetsToInsert = dataToInsert.assets

            for (let a = 0; a < assetsToInsert.length; a++) {

                let assetLoop = assetsToInsert[a]

                let responseCreateAsset = await createAsset(assetLoop);
                if (responseCreateAsset.success) {

                    //console.log("ASSET CRIADO COM SUCESSO")
                    //console.log("RESPONSE ASSET: ", responseCreateAsset.body.rows[0]);
                    listLocalClientAssets.push(responseCreateAsset.body.rows[0]);
                    // setAssetsClient(listLocalClientAssets);

                } else {

                    console.log("ERRO AO CRIAR ASSET")
                    assetsErrorToCreate.push(assetLoop);

                }
            }

        }

        //Obtem-se também as contas do usuário pois também serão cadastrados saldos de conta
        const responseAccs = await listClientAccounts(clientOn.client.id);

        //Envia-se os assets do cliente com intuito de obter o asset id a partir do CNPJ
        //envia-se nulo no parâmetro de accounts
        let formattedData = formatBalancesValidatioFromExcel(dataToInsert.validations,
            listLocalClientAssets,
            responseAccs.body.rows,
            clientOn.client.selectedValidationYear);

        //setDataToInsert(formattedData);


        //Verificando quais estão diferentes ou quais são novos
        //Processamento necessário para evitar duplicação de saldos no banco
        let filteredFormattedData = await compareValidationsToInsertOrUpdate(formattedData,
            dataToTables['assets'], clientOn.client.selectedValidationYear, clientOn.client.id, clientOn.client.portfolio_closed);

        console.log("filteredFormattedData: ", filteredFormattedData);

        if (filteredFormattedData && filteredFormattedData.length > 0) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando saldos..."
            }))

            let allSuccess = true;
            for (let index = 0; index < filteredFormattedData.length; index++) {
                const element = filteredFormattedData[index];

                //console.log("Cadastrando saldo: ", element);
                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: true,
                    message: "Cadastrando saldos... " + parseFloat((index + 1) / filteredFormattedData.length * 100).toFixed(2) + "%"
                }))

                let response = await createBalanceValidation(element);

                if (!response.success) {
                    allSuccess = false;
                }

            }

            if (allSuccess) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Saldos de '
                        + clientOn.client.selectedValidationYear
                        + ' cadastrados com sucesso! [ ' + filteredFormattedData.length + " ]",
                    type: 'success'

                }))

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Ocorreu falha no cadastro de um ou mais saldos de '
                        + clientOn.client.selectedValidationYear,
                    type: 'warning'

                }))
            }

            doGetClientBalancesValidations(clientOn.client.selectedValidationYear,
                clientOn.client.id, regime);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

        } else {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            if (assetsErrorToCreate.length > 0) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Alguns ativos não puderam ser criados, verifique se está sendo informado corretamente o CÓDIGO da insituição nas linhas de CONTA na coluna CNPJ',
                    type: 'error'

                }))
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Todos os saldos presentes na planilha já haviam sido importados',
                    type: 'warning'

                }))
            }



            handleModalImport(false);

        }

    }

    async function doInsertsValidationFromPdf(dataToInsertFromPdf, accountsToCreate) {

        const { normalizedAccountsFromPdf, transactionsInExtracts } = dataToInsertFromPdf;

        if (accountsToCreate.length) {
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando contas..."
            }))

            for (let i = 0; i < accountsToCreate.length; i++) {

                const acc = accountsToCreate[i];
                const responseAcc = await createAccount(acc);

                if (responseAcc.success) {

                    console.log("RESPONSE ACC: ", responseAcc.body);
                    //Inserir id da conta no asset        
                    //Caso existam assets a serem criados verifica-se se tinha algum esperando por um id de conta
                    const theNewAccount = normalizedAccountsFromPdf.find(el =>
                        el.cc == acc.number_account
                        && el.agency === acc.agency
                        && el.instituitionId === acc.instituition_id
                    );

                    theNewAccount.assets.forEach(element => {

                        const { asset } = element;
                        asset.account_id = responseAcc.body.account.id;

                    });

                    const splittedInit = acc.account_init.split("/");
                    const m = parseInt(splittedInit[1]);
                    const y = parseInt(splittedInit[2]);

                    const initBalance = {
                        account_id: responseAcc.body.account.id,
                        asset_id: null,
                        balance: 0,
                        quota_amount: 0,
                        balance_validation_id: undefined,
                        month_validation: m,
                        year_validation: y,
                    }
                    const responseCreateInitBalance = await createBalanceValidation(initBalance);
                }
            }
        }

        //Para caso de haver erro na criação dos ativos
        const assetsErrorToCreate = [];

        const listLocalClientAssets = assetsClient ? assetsClient : [];

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Cadastrando Ativos..."
        }))

        const assetsToInsert = [];
        normalizedAccountsFromPdf.forEach(account => {
            account.assets.filter(el => el.asset.isNew).forEach(assetInAccount => {
                const { asset } = assetInAccount;
                assetsToInsert.push({
                    account_id: asset.account_id,
                    fund_id: asset.fundInfos.id,
                    balance: 0,
                    quota_amount: 0,
                    asset_init: formatInitDate(asset.month),
                    cc: account.cc,
                    agency: account.agency,
                    cnpj: asset.fundInfos.id,
                    month: asset.month,
                });
            });
        })

        for (let a = 0; a < assetsToInsert.length; a++) {

            const assetLoop = assetsToInsert[a]

            let responseCreateAsset = await createAsset(assetLoop);
            if (responseCreateAsset.success) {

                const createdAsset = responseCreateAsset.body.rows[0];
                listLocalClientAssets.push(createdAsset);

                //Criando validação de saldo inicial
                const splittedInit = assetLoop.asset_init.split("/");
                const m = parseInt(splittedInit[1]);
                const y = parseInt(splittedInit[2]);

                const initBalance = {
                    account_id: null,
                    asset_id: createdAsset.id,
                    balance: 0,
                    quota_amount: 0,
                    balance_validation_id: undefined,
                    month_validation: m,
                    year_validation: y,
                }
                const responseCreateInitBalance = await createBalanceValidation(initBalance);

            } else {

                console.log("ERRO AO CRIAR ASSET")
                assetsErrorToCreate.push(assetLoop);

            }
        }

        console.log("assetsToInsert: ", assetsToInsert);

        //Obtem-se também as contas do usuário pois também serão cadastrados saldos de conta
        const responseAccs = await listClientAccounts(clientOn.client.id);

        //Envia-se os assets do cliente com intuito de obter o asset id a partir do CNPJ
        //envia-se nulo no parâmetro de accounts
        let formattedData = formatBalancesValidatioFromExcel(dataToInsertFromPdf.validations,
            listLocalClientAssets,
            responseAccs.body.rows,
            clientOn.client.selectedValidationYear);

        console.log("formattedData: ", formattedData);

        let filteredFormattedData = await compareValidationsToInsertOrUpdate(formattedData,
            dataToTables['assets'], clientOn.client.selectedValidationYear, clientOn.client.id, clientOn.client.portfolio_closed);

        console.log("filteredFormattedData: ", filteredFormattedData);

        if (filteredFormattedData && filteredFormattedData.length > 0) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando saldos..."
            }))

            let allSuccess = true;
            for (let index = 0; index < filteredFormattedData.length; index++) {
                const element = filteredFormattedData[index];

                //console.log("Cadastrando saldo: ", element);
                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: true,
                    message: "Cadastrando saldos... " + parseFloat((index + 1) / filteredFormattedData.length * 100).toFixed(2) + "%"
                }))

                let response = await createBalanceValidation(element);

                if (!response.success) {
                    allSuccess = false;
                }

            }

            if (allSuccess) {

                if (transactionsInExtracts?.length) {
                    doInsertsTransactionsFromPdf(transactionsInExtracts, listLocalClientAssets)
                }

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Saldos de '
                        + clientOn.client.selectedValidationYear
                        + ' cadastrados com sucesso! [ ' + filteredFormattedData.length + " ]",
                    type: 'success'

                }))

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Ocorreu falha no cadastro de um ou mais saldos de '
                        + clientOn.client.selectedValidationYear,
                    type: 'warning'

                }))
            }

            doGetClientBalancesValidations(clientOn.client.selectedValidationYear,
                clientOn.client.id, regime);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

        } else {

            if (transactionsInExtracts?.length) {
                doInsertsTransactionsFromPdf(transactionsInExtracts, listLocalClientAssets)
            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                if (assetsErrorToCreate.length > 0) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: 'Alguns ativos não puderam ser criados, verifique se está sendo informado corretamente o CÓDIGO da insituição nas linhas de CONTA na coluna CNPJ',
                        type: 'error'

                    }))
                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        message: 'Todos os saldos presentes na planilha já haviam sido importados',
                        type: 'warning'

                    }))
                }



                handleModalImport(false);
            }


        }

    }

    function checkIsClientAssetByCnpjAndAccountTransactions(listLocalClientAssets, cnpj, accountId) {

        let assets = listLocalClientAssets.filter(el => el.cnpj == cnpj && el.account_id == accountId)
        if (assets && assets.length == 1) {
            return assets[0].id;
        } else {
            return null;
        }
    }

    async function doInsertsTransactionsFromPdf(transactionsInExtracts, localAssets) {

        const responseAccs = await listClientAccounts(clientOn.client.id);
        const responeTransactionsToInsert = await SystemValidationsController.doPreInsertsTransactions(
            transactionsInExtracts,
            [],
            [],
            assetsClient,
            responseAccs.body.rows,
            clientOn, true, dispatch
        );

        let allSuccess = true;


        const transactionsToInsert = responeTransactionsToInsert.transactions;
        console.log("transactionsToInsert: ", transactionsToInsert);
        const listLocalClientAssets = localAssets;
        console.log("listLocalClientAssets: ", listLocalClientAssets);

        //Caso hajam meses que necessitaram ser limpos, array com os que já foram
        const monthsAlreadyDeleted = [];

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Cadastrando Movimentações..."
        }))

        for (let i = 0; i < transactionsToInsert.length; i++) {

            //console.log("####################################################");
            // const monthTransaction = parseInt(transactionsToInsert[i].transaction_date.substring(3, 5));
            // if (!monthsAlreadyDeleted.includes(monthTransaction) && data.needCleanMonth.includes(monthTransaction)) {
            //     const responseDeleteTransactionsInMonth = await deleteClientTransactionsInMonth(data.client.id, monthTransaction);
            // }

            let transactionLoop = transactionsToInsert[i];

            if (!transactionLoop.asset_id) {
                let assetId = checkIsClientAssetByCnpjAndAccountTransactions(listLocalClientAssets, transactionLoop.cnpj, transactionLoop.account_id)
                transactionLoop.asset_id = assetId;
            }


            console.log("transactionLoop: ", transactionLoop);

            //console.log("Incluindo: ", transactionLoop)
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando movimentações... " + parseFloat((i + 1) / transactionsToInsert.length * 100).toFixed(2) + "%"
            }))

            let responseInsertTransaction = await createTransaction(transactionLoop);

            if (!responseInsertTransaction.success) {
                allSuccess = false;
            }
            console.log("Sucesso: ", responseInsertTransaction.success)

        }

        if (allSuccess) {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Movimentações cadastradas com sucesso!"
            }))
        } else {
            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'warning',
                message: "Erro ao cadastrar uma ou mais movimentações"
            }))
        }
    }

    //Fim import excel

    function verifyColumnsExcel(year, validationLoop) {

        //console.log("TESTE DE COLUNAS: ", validationLoop);
        let invalidColumns = [];
        let leastAMonth = [];
        if (validationLoop['segregacao'] == undefined) {
            invalidColumns.push('segregacao');
        }
        if (validationLoop['cc'] == undefined) {
            invalidColumns.push('cc');
        }
        if (validationLoop['cnpj'] == undefined) {
            invalidColumns.push('cnpj');
        }

        //COTAS
        if (validationLoop['dez_' + (year - 1) + '_cota'] == undefined) {
            invalidColumns.push('dez_' + (year - 1) + '_cota');
        }
        if (validationLoop['jan_cota'] != undefined) {
            leastAMonth.push('jan_cota');
        }
        if (validationLoop['fev_cota'] != undefined) {
            leastAMonth.push('fev_cota');
        }
        if (validationLoop['mar_cota'] != undefined) {
            leastAMonth.push('mar_cota');
        }
        if (validationLoop['abr_cota'] != undefined) {
            leastAMonth.push('abr_cota');
        }
        if (validationLoop['mai_cota'] != undefined) {
            leastAMonth.push('mai_cota');
        }
        if (validationLoop['jun_cota'] != undefined) {
            leastAMonth.push('jun_cota');
        }
        if (validationLoop['jul_cota'] != undefined) {
            leastAMonth.push('jul_cota');
        }
        if (validationLoop['ago_cota'] != undefined) {
            leastAMonth.push('ago_cota');
        }
        if (validationLoop['set_cota'] != undefined) {
            leastAMonth.push('set_cota');
        }
        if (validationLoop['out_cota'] != undefined) {
            leastAMonth.push('out_cota');
        }
        if (validationLoop['nov_cota'] != undefined) {
            leastAMonth.push('nov_cota');
        }
        if (validationLoop['dez_cota'] != undefined) {
            leastAMonth.push('dez_cota');
        }

        //VALOR
        if (validationLoop['dez_' + (year - 1) + '_valor'] == undefined) {
            invalidColumns.push('dez_' + (year - 1) + '_valor');
        }
        if (validationLoop['jan_valor'] != undefined) {
            leastAMonth.push('jan_valor');
        }
        if (validationLoop['fev_valor'] != undefined) {
            leastAMonth.push('fev_valor');
        }
        if (validationLoop['mar_valor'] != undefined) {
            leastAMonth.push('mar_valor');
        }
        if (validationLoop['abr_valor'] != undefined) {
            leastAMonth.push('abr_valor');
        }
        if (validationLoop['mai_valor'] != undefined) {
            leastAMonth.push('mai_valor');
        }
        if (validationLoop['jun_valor'] != undefined) {
            leastAMonth.push('jun_valor');
        }
        if (validationLoop['jul_valor'] != undefined) {
            leastAMonth.push('jul_valor');
        }
        if (validationLoop['ago_valor'] != undefined) {
            leastAMonth.push('ago_valor');
        }
        if (validationLoop['set_valor'] != undefined) {
            leastAMonth.push('set_valor');
        }
        if (validationLoop['out_valor'] != undefined) {
            leastAMonth.push('out_valor');
        }
        if (validationLoop['nov_valor'] != undefined) {
            leastAMonth.push('nov_valor');
        }
        if (validationLoop['dez_valor'] != undefined) {
            leastAMonth.push('dez_valor');
        }

        return {
            invalidColumns: invalidColumns,
            leastAMonth: leastAMonth
        };
    }

    const { regime } = props

    useEffect(() => {

        console.log("User effect Validation Investiments")

        if (clientOn && clientOn.client
            && (
                !clientOnScreen
                || clientOnScreen.id != clientOn.client.id
                || regime != regimeOnScreen
            ) && clientOn.client.selectedValidationYear) {

            console.log('PROCESSO 1')
            console.log("CLIENT ON SCREEN: ", clientOnScreen)
            console.log("CLIENT", clientOn.client)
            console.log("REGIME: ", regime)

            setData(undefined)
            setDataListFormatted(undefined)

            setRegimeOnScreen(regime);
            setClientOnScreen(_.cloneDeep(clientOn.client));

            doGetClientBalancesValidations(clientOn.client.selectedValidationYear,
                clientOn.client.id,
                regime);

        } else if (clientOn
            && clientOn.client
            && clientOnScreen
            && clientOn.client.selectedValidationYear
            && (clientOn.client.selectedValidationYear != clientOnScreen.selectedValidationYear)) {

            console.log('PROCESSO 2')
            setRegimeOnScreen(regime);
            setData(undefined)
            setDataListFormatted(undefined)

            setClientOnScreen(_.cloneDeep(clientOn.client));
            updateBalancesTable(clientOn.client.id,
                assetsClient,
                clientOn.client.selectedValidationYear,
                regime);

        }

        //Quando salva-se o que foi feito na tela de rightcontent e precisa-se atualizar os dados 
        //da tela principal
        if (rightContent && rightContent.action) {
            console.log('PROCESSO 3')
            setRegimeOnScreen(regime);
            dispatch(allActions.rightContentActions.setRightContent(null))
            //updateBalancesTable(clientOn.client.id, assetsClient, clientOn.client.selectedValidationYear);
            doGetClientBalancesValidations(clientOn.client.selectedValidationYear,
                clientOn.client.id, regime);
        }

        //Inicio import Excel
        if (file) {

            console.log('PROCESSO 4')
            console.log("HANDLE FILE: ", file)

            if (clientOn && clientOn.client) {

                const responseValidFile = isFileForClientOn(file, clientOn.client, 'SALDOS')
                if (responseValidFile.success) {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: true,
                        message: "Validando planilha de saldos..."
                    }))

                    handleFile();

                } else {

                    setDataToInsert({

                        client: clientOn.client,
                        currentFileName: file.name,
                        rightFileName: responseValidFile.rightName + '.xlsm',
                        accountsToCreate: [],
                        assets: [],
                        invalidInitAssets: [],

                    });

                    handleModalImport(true);
                }
            }






        }

        if (data && data.length > 0
            && !dataListFormatted) {

            console.log('PROCESSO 5')
            console.log("HANDLE FILE2")

            let dataLFormatted = [];
            let response = undefined;
            let columnsOK = false; //No caso de pelo menos uma linha retornar 0 na verificação de colunas inválidas mostra que a tabela está com as colunas corretas            
            let invalidColumns = undefined; //No caso de pelo menos uma linha retornar 0 na verificação de colunas inválidas mostra que a tabela está com as colunas corretas            
            console.log("DATA: ", data);
            for (let i = 0; i < data.length; i++) {


                //Verificando se há alguma coluna invalida
                if (data[i] != undefined
                    && data[i]['cnpj']
                    && data[i]['cc']) {

                    //console.log("TESTANDO COLUNAS COM: ", data[i]);
                    response = verifyColumnsExcel(clientOn.client.selectedValidationYear, data[i]);

                    //Caso hajam as colunas necessárias e exista pelo menos um mês com dado   
                    //console.log("RESPONSE COLUMNS: ", response);
                    if (response.invalidColumns == 0
                        && response.leastAMonth.length > 0) {

                        //console.log("ENTRANDO COLUMNS OK: ", data[i]);

                        if (!columnsOK) {
                            columnsOK = true;
                        }

                    } else if (!invalidColumns && response.invalidColumns.length > 0) {

                        invalidColumns = response.invalidColumns
                    }

                    dataLFormatted.push(data[i]);

                }


            }

            setData([])

            console.log("COLUMNS OK: ", columnsOK)

            setDataListFormatted({
                data: columnsOK ? dataLFormatted : [],
                invalidColumns: !columnsOK && response ? response.invalidColumns : [], //Caso as colunas não estejam ok em alguma instancia envia-se as colunas não ok
            });

        }

        if (dataListFormatted
            && clientOnScreen.id == clientOn.client.id
            && clientOn.client.selectedValidationYear == clientOnScreen.selectedValidationYear) {

            console.log('PROCESSO 6')
            //console.log("HANDLE FILE3: ", dataListFormatted)
            doPreInsertsValidation(dataListFormatted.data, dataListFormatted.invalidColumns);
            //doInsertsValidation(dataListFormatted);
        }
        //Fim import excel

    }, [
        clientOn,
        rightContent,
        file,
        dataListFormatted,
        regime
    ]);

    const doProccessPdf = async (instituition) => {
        setOpenSelectedInstituitionModal(false);
        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: `Realizando a leitura dos pdfs...(1/${filesPDFs.length})`,
        }))
        console.log("filesPDFs: ", filesPDFs);
        const responseExtracts = await PdfReaderController.processExtractsPdfs(instituition, filesPDFs, clientOn.client.selectedValidationYear, dispatch);
        const { extracts, invalidExtracts } = responseExtracts;
        console.log("response extract pdf: ", responseExtracts);
        const responseAccs = await listClientAccounts(clientOn.client.id);
        const transactionsToInsert = await SystemValidationsController.doPreInsertsTransactions(extracts.transactions, [], [], assetsClient, responseAccs.body.rows, clientOn, true, dispatch);
        console.log("SystemValidationsController transactionsToInsert: ", transactionsToInsert);
        await doPreInsertsValidation(extracts.balances, [], transactionsToInsert, extracts.transactions, invalidExtracts, instituition);
        dispatch(allActions.fullLoadActions.setFullLoad({
            show: false,
        }))

    }

    const [openSelectedInstituitionModal, setOpenSelectedInstituitionModal] = useState();

    useEffect(() => {

        console.log("PDFs selecionados 1: ", filesPDFs);
        if (!filesPDFs) return;
        console.log("PDFs selecionados 2: ", filesPDFs);
        setOpenSelectedInstituitionModal(true);
        // doProccessPdf(filePDF);


    }, [filesPDFs])

    const displayErrorMessageOnPluggyError = () => {
        dispatch(allActions.mainAlertActions.setMainAlert({
            show: true,
            type: 'error',
            message: "Houve um problema ao importar com pluggy!"
        }));
    };

    const [clientPluggyItemId, setClientPluggyItemId] = useState();

    const fetchClientPluggyItemId = useCallback(async () => {
        setClientPluggyItemId(clientOn?.client?.pluggy_item_id ?? (await PluggyController.fetchClientItem(clientOn?.client?.id)).data.clientPluggyItemId);
    }, [clientOn]);

    useEffect(() => {
        fetchClientPluggyItemId();
    }, [fetchClientPluggyItemId]);

    useEffect(() => {
        if (updateClientItemId) {
            fetchClientPluggyItemId();
            dispatch(allActions.scrollDialogActions.setScrollDialog({
                ...scrollDialog,
                update: false
            }));
        };
    }, [dispatch, fetchClientPluggyItemId, updateClientItemId]);

    const JOAO_PESSOA_ID = 4;
    const SAO_GONCALO_CE_ID = 185;
    const isClientOnPluggyClient = useMemo(() => clientOn?.client && (clientOn?.client.id === JOAO_PESSOA_ID || clientOn?.client.id === SAO_GONCALO_CE_ID), [clientOn]);
    const isCurrentUserAdvisor = useMemo(() => currentUser?.user && currentUser?.user.isAdvisor, [currentUser]);
    const isCurrentUserSuperAdmin = useMemo(() => currentUser?.user && currentUser?.user.isSuperAdmin, [currentUser]);
    const isCurrentUserAbleToIntegrate = useMemo(() => ((isClientOnPluggyClient && isCurrentUserAdvisor) || (isClientOnPluggyClient && isCurrentUserSuperAdmin) && clientPluggyItemId), [isClientOnPluggyClient, isCurrentUserAdvisor, isCurrentUserSuperAdmin, clientPluggyItemId]);

    /**
       * @type {import('@material-ui/lab').SpeedDialActionProps[]}
       */
    const speedDialActions = useMemo(() => [
        {
            htmlFor: 'file',
            key: 'import-fab',
            icon: <PublishIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Importar saldos',
            onClick: () => doClickinputFile()
        },
        {
            htmlFor: 'file',
            key: 'read-pdf',
            icon: <PublishIcon />,
            tooltipOpen: true,
            tooltipTitle: 'Ler PDF',
            onClick: () => doClickinputPDFFile(),
            FabProps: {
                disabled: getConsultingId(currentUser) != 1 || !checkUserIsRole(currentUser, [roles.superadmin, roles.admin, roles.advisor])
            },

        },
        // {
        //     key: 'import-statement',
        //     icon: <PublishIcon />,
        //     tooltipOpen: true,
        //     onClick: isCurrentUserAbleToIntegrate ? async () => {
        //         try {
        //             dispatch(allActions.innerLoadActions.setInnerLoad({
        //                 show: true,
        //                 loadMessage: 'Importando movimentações...'
        //             }));
        //             const { data } = await PluggyController.getTransactions(clientPluggyItemId);
        //             dispatch(allActions.scrollDialogActions.setScrollDialog({
        //                 open: true,
        //                 data,
        //                 type: 'pluggy_integration'
        //             }));
        //         } catch (error) {
        //             displayErrorMessageOnPluggyError();
        //         };
        //         dispatch(allActions.innerLoadActions.setInnerLoad({
        //             show: false,
        //         }));
        //     } : undefined,
        //     FabProps: {
        //         disabled: !isCurrentUserAbleToIntegrate
        //     },
        //     tooltipTitle: `Importar saldos da Pluggy${isCurrentUserAbleToIntegrate ? '' : ' (em breve)'}`
        // },
    ], [isCurrentUserAbleToIntegrate, clientPluggyItemId]);

    return (
        <div className={classes.root}>

            <div class={'defaultScrollWithHeaderContent'}>

                {
                    dataToTables
                        && dataToTables
                        && dataToTables['assets'] && assetsClient ?
                        <MyTable
                            onScroll={(value) => setAppearFab(value)}
                            stickyHeader
                            size="small"
                            selectable="true"
                            customclass={classes.table}>
                            <colgroup>
                                <col width="25%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTh} align="left">Fundo/Conta</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jan</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Fev</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Mar</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Abr</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Mai</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jun</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Jul</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Ago</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Set</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Out</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Nov</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Dez</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {assetsClient
                                    && assetsClient.length > 0
                                    && clientOn && clientOn.client ? renderLodashAssets(assetsClient, clientOn.client.selectedValidationYear) : null}
                            </TableBody>

                        </MyTable> : null
                }

            </div>

            <div>
                <input
                    ref={inputFile}
                    className={classes.input}
                    id="file"
                    multiple
                    accept={SheetJSFT}
                    type="file"
                    onClick={() => resetImportVariables()}
                    onChange={handleChangeFile}
                />
            </div>

            <div>
                <input
                    ref={inputFilePDf}
                    className={classes.input}
                    id="file-pdf"
                    multiple
                    accept={"application/pdf"}
                    type="file"
                    onClick={() => resetImportVariablesPdfs()}
                    onChange={handleChangeFilesPDFs}
                />
            </div>

            {
                currentUser && currentUser.user
                    && (
                        currentUser.user.role == "ROLE_SUPER_ADMIN"
                        || currentUser.user.role == "ROLE_ADMIN"
                        || currentUser.user.role == "ROLE_ADVISOR"
                    ) ?
                    <>
                        <Backdrop
                            onClick={handleCloseFab}
                            style={{ zIndex: '999' }}
                            open={openFab} />
                        <Fade
                            in={appearFab}
                            timeout={transitionDuration}
                            unmountOnExit>
                            <div className={speedDialClasses.speedDial}>
                                <SpeedDial
                                    ariaLabel="Ativos e Contas"
                                    onClick={() => setOpenFab(!openFab)}
                                    hidden={false}
                                    icon={<SpeedDialIcon />}
                                    open={openFab}
                                    direction='up'>
                                    {speedDialActions.filter(el => !el?.FabProps?.disabled).map((props) => (<SpeedDialAction {...props} />))}
                                </SpeedDial>
                            </div>
                        </Fade>
                    </>
                    : null
            }


            <DataImportModalValidationBalances
                open={showImportModal}
                initImport={initImport}
                handleModalImport={handleModalImport}
                dataToInsert={dataToInsert}
            />

            <DataImportFromPdfModal
                open={showImportFromPdfModal}
                initImport={initImportFromPdf}
                handleModalImport={handleModalImportFromPdf}
                dataToInsert={dataToInsertFromPdf}
            />

            <SelectInstituitionModal
                open={openSelectedInstituitionModal}
                handleSelectedInstituition={(instituition) => doProccessPdf(instituition)}
                handleClose={() => setOpenSelectedInstituitionModal(false)} />
        </div>
    );
}



