import React from 'react';
import { makeStyles, IconButton, Box, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import allActions from "../../../../../../actions";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { unoTheme } from '../../../../../../assets/styles/unoTheme';

export const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5)
    },
    spaceFiller: {
        padding: theme.spacing(1.5),
        width: 48,
    },
    loadingWrapper: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progress: {
        color: unoTheme.mainColor
    }
}));

export const Wrapper = ({ children }) => {
    const classes = useStyles();

    return <Box className={classes.header}>{children}</Box>;
}

export const GoBackButton = () => {
    const dispatch = useDispatch();

    return (
        <IconButton onClick={() => dispatch(allActions.rightContentActions.update())}>
            <ArrowBackIcon />
        </IconButton>
    );
}

export const CloseButton = () => {
    const dispatch = useDispatch();

    return (
        <IconButton
            onClick={() => dispatch(allActions.rightContentActions.update())}>
            <CloseIcon />
        </IconButton>
    );
};

export const SpaceFiller = () => {
    const classes = useStyles();

    return <Box className={classes.spaceFiller}></Box>;
};

export const Loading = () => {
    const classes = useStyles();

    return (
        <Box className={classes.loadingWrapper}>
            <CircularProgress className={classes.progress} />
        </Box>
    );
}