import { LOGOUT_TYPE } from "../reducers/currentUser"

const setUser = (userObj) => {
    return {
        type: "SET_USER",
        payload: userObj
    }
}

const logOut = () => {
    return {
        type: LOGOUT_TYPE
    }
}

export default {
    setUser,
    logOut
}