import API, { defaultConfig } from './config';
import { getLastDayInMonthByMonthAndYear } from '../components/utils/utils'

export async function getClientById(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientById/' + clientId, config)

        console.log("Response CLIENT: ", response)

        if (response && response.data
            && response.data.rows &&
            response.data.rows.length == 1) {
            return {
                success: true,
                body: response.data.rows[0]
            };
        } else {
            return {
                success: false,
            };
        }



    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientsByCityId(cityId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("CITY TO GET: ", cityId);
        const response = await API.get('/clients/getClientsByCityId/' + cityId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllActiveClients(consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/activeClients/' + consultingId, config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientsAndLaunchs(orderDirection, advisorId, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientsAndLaunchs?orderDirection=' + (orderDirection ? orderDirection : 'ASC') + (advisorId ? '&advisorId=' + advisorId : '')
            + ('&consultingId=' + consultingId), config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientsTargets(orderDirection, advisorId, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientsTargets?orderDirection=' + (orderDirection ? orderDirection : 'ASC') + (advisorId ? '&advisorId=' + advisorId : '')
            + ('&consultingId=' + consultingId), config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientsPolicys(orderDirection, advisorId, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientsPolicys?orderDirection=' + (orderDirection ? orderDirection : 'ASC') + (advisorId ? '&advisorId=' + advisorId : '')
            + ('&consultingId=' + consultingId), config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getActuarysInfos(year, advisorId, consultingId, search) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getActuarysInfos?' + (advisorId ? 'advisorId=' + advisorId : '')
            + ('&consultingId=' + consultingId)
            + ('&year=' + year)
            + '&search=' + (!search ? "" : search), config)

        console.log("Response: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getStatusDairClientsByYear(offset, limit, year, advisorId, consultingId, search, dairStatusIdFilter, monthFilterDair, snapshotDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getStatusDairClientsByYear?offset=' + offset
            + '&limit=' + limit
            + '&year=' + year
            + '&consultingId=' + consultingId
            + (advisorId ? '&advisorId=' + advisorId : '')
            + '&search=' + (!search ? "" : search)
            + '&dairStatusIdFilter=' + (!dairStatusIdFilter ? "" : dairStatusIdFilter)
            + '&monthFilterDair=' + (!monthFilterDair ? "" : monthFilterDair)
            + (snapshotDate ? '&snapshotDate=' + snapshotDate : '')
            , config)

        //console.log('getClientsClousuresByYear: ', response);
        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getStatusDpinClientsByYear(offset, limit, year, advisorId, consultingId, search) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getStatusDpinClientsByYear?offset=' + offset
            + '&limit=' + limit
            + '&year=' + year
            + '&consultingId=' + consultingId
            + (advisorId ? '&advisorId=' + advisorId : '')
            + '&search=' + (!search ? "" : search)
            , config)

        //console.log('getClientsClousuresByYear: ', response);
        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listUserActiveClients(userId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/userActiveClients/' + userId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllClientsWithManagers() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/clientsWithManagers', config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllClients(offset, orderByDirectionPortfolioClosed) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        let route = offset || offset === 0 ? `/clients?offset=${offset}` : '/clients';
        //Adicionando direção de ordenação em caso de haver o parâmetro orderByDirectionPortfolioClosed
        route = orderByDirectionPortfolioClosed ? route + `&order_by_postfolio_closed=${orderByDirectionPortfolioClosed}` : route;

        const response = await API.get(route, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function listAllClientsByConsulting(offset, orderByDirectionPortfolioInit, orderByDirectionPortfolioClosed, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        let route = offset || offset === 0 ? `/clientsByConsulting?offset=${offset}` : '/clients';
        //Adicionando direção de ordenação em caso de haver o parâmetro orderByDirectionPortfolioClosed
        route = orderByDirectionPortfolioInit ? route + `&order_by_postfolio_init=${orderByDirectionPortfolioInit}` : route;
        route = orderByDirectionPortfolioClosed ? route + `&order_by_postfolio_closed=${orderByDirectionPortfolioClosed}` : route;
        route = route + `&consulting_id=${consultingId}`

        const response = await API.get(route, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllUsersClientByRole(clientId, roleId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllUsersClientByRole?client_id=${clientId}&role_id=${roleId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllManagers() {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllManagers`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientsBySearch(search) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllClientsBySearch?search=${search}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

};

export async function getAllClientsConsultingBySearch(search, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllClientsConsultingBySearch?search=${search}&consulting_id=${consultingId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

};

export async function listAprDescriptions(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/aprDescriptions', { client_id: clientId }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAprDescriptionById(aprDescriptionId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getAprDescriptionById', { apr_description_id: aprDescriptionId }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createClient(client) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/create', client, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function saveNewAprDescription(clientId, title, description) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/saveNewAprDescription', {
            client_id: clientId,
            title: title,
            description: description,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateStatus(clientId, status) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/updateClientStatus', {
            id: clientId,
            status: status,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateClient(clientId, client) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("CLIENT NA API: ", client);

    try {

        const response = await API.put('/clients/update', {
            id: clientId,
            client: client,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}


/**
 * Reseta o fechameto da carteira
 * @param {int} clientId 
 * @param {Date} compareDate data que vai ser usada para a comparação, update e delete de informações
 * @param {String} dateToPortfolioClosed string de fechamento da carteira Ex: 9/2021 
 * @param {Boolean} updateDate indica se deve ou não ser atualizado o fechamento da carteira 
 */
export async function resetPortfolio(clientId, compareDate, dateToPortfolioClosed, updateDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/clients/resetPortfolio', {
            client_id: clientId,
            to_compare: compareDate,
            to_portfolio: dateToPortfolioClosed,
            update: updateDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function resetAssets(clientId, toDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/resetAssets', {
            client_id: clientId,
            to_date: toDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function resetAccounts(clientId, toDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/resetAccounts', {
            client_id: clientId,
            to_date: toDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function resetTransactions(clientId, toDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/resetTransactions', {
            client_id: clientId,
            to_date: toDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateClientClosedMonth(clientId, month) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/client/updateClientClosedMonth', {
            client_id: clientId,
            month: month,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateRentInBalancesValidation(balanceValidationId, rent, averageQuota) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    console.log("RENT: ", rent);

    try {

        const response = await API.post('/client/updateRentInBalancesValidation', {

            balance_validation_id: balanceValidationId,
            rent: rent,
            average_quota: averageQuota,

        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data);

        return {
            success: false,
            body: error.response.data
        };
    }

}
export async function getClientBalancesValidations(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientBalancesValidations/' + clientId, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientBalancesValidationsByYear(selectedYear, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientBalancesValidationsByYear', {
            client_id: clientId,
            year: selectedYear,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientAccountsBalancesValidationsByYear(selectedYear, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientAccountsBalancesValidationsByYear', {
            client_id: clientId,
            year: selectedYear,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientInvestmentsBalancesValidationsByYear(selectedYear, clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientInvestmentsBalancesValidationsByYear', {
            client_id: clientId,
            year: selectedYear,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientInvestmentsBalancesValidationsByYearAndRegime(selectedYear, clientId, accountRegimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientInvestmentsBalancesValidationsByYearAndRegime', {
            client_id: clientId,
            year: selectedYear,
            account_regime_id: accountRegimeId,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientTitlesBalancesValidationsByYearAndRegime(selectedYear, clientId, accountRegimeId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientTitlesBalancesValidationsByYearAndRegime', {
            client_id: clientId,
            year: selectedYear,
            account_regime_id: accountRegimeId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientBalancesValidationsByMonthYear(clientId, m, y) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getClientBalancesValidationsByMonthYear', {
            client_id: clientId,
            month: m,
            year: y,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getBalancesRentsByRange(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/getBalancesRentsByRange', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllRentsFundsClient(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getAllRentsFundsClient/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR: ", error.response.data)

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientLastQuota(clientId, month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getClientLastQuota', {
            client_id: clientId,
            month: month,
            year: year
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getPortfolioClosed(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/getPortfolioClosed/' + clientId, config)

        if (response.data.rows && response.data.rows[0]) {

            return {
                success: true,
                body: response.data.rows[0].portfolio_closed
            };
        } else {
            return {
                success: false,
                body: null
            };

        }

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function saveClientRent(clientRent) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/saveClientRent', clientRent, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function saveFundClientRent(fundClientRent) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/saveFundClientRent', fundClientRent, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function saveTitleClientRent(titleClientRent) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/saveTitleClientRent', titleClientRent, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientDiaryPlsByRange(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getClientDiaryPlsByRange', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getPortfolioClosedsInPeriodByClient(clientId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getPortfolioClosedsInPeriodByClient', {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllRentsClientsByPeriod(startDate, endDate, monthBeforeDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getAllRentsClientsByPeriod', {
            start_date: startDate,
            end_date: endDate,
            month_before_date: monthBeforeDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllMaxQuotasClientsByMonthAndYearAndConsultingId(month, year, consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getAllMaxQuotasClientsByMonthAndYearAndConsultingId', {
            month: month,
            year: year,
            consulting_id: consultingId
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientPortfolioRentsByLimit(clientId, month, year, limit) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const endDate = getLastDayInMonthByMonthAndYear(month, year);

        const response = await API.post('/client/getClientPortfolioRentsByLimit', {
            client_id: clientId,
            limit: limit,
            end_date: endDate,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function setBalanceReconciliaded(balanceValidationId,
    userId,
    status,
    typeAsset //fund ou title
) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.put('/client/setBalanceReconciliaded', {
            status: status,
            user_id: userId,
            balance_validation_id: balanceValidationId,
            type_asset: typeAsset,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientConciliations(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/getAllClientConciliations/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllClientConciliationsByUser(clientId, searchInputValue) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllClientConciliationsByUser?client_id=${clientId}&search=${searchInputValue}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createBalanceValidation(balanceValidation) { //objId pode ser uma account ou um asset

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/createBalanceValidation', balanceValidation, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function createBalanceTitleValidation(balanceValidation) { //objId pode ser uma account ou um asset

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/createBalanceTitleValidation', balanceValidation, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

//Excluir todos os balances validations salvos no banco, utilizado quando se importa 
//os saldos de uma planilha
export async function deleteBalancesYear(clientId, year) { //objId pode ser uma account ou um asset

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/deleteBalancesYear', {
            client_id: clientId,
            year: year,
        }, config)

        //console.log("RESPONSE NO SERVICE: ", response)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}


export async function getClientAprDataById(clientAprDataId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientAprDataById/' + clientAprDataId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function getClientAprData(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientAprData/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function getClientAprDataByReferenceDate(clientId, referenceDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/clients/getClientAprDataByReferenceDate?client_id=' + clientId + '&reference_date=' + referenceDate, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function updateClientAprData(clientId, data) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/createUpdateClientPolicy', {
            client_id: clientId,
            data: data,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function createUpdateClientPolicy(clientPolicy) {

    console.log("CLIENT POLICY TO SAVE: ", clientPolicy)

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/clients/createUpdateClientPolicy', clientPolicy, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response ? error.response.data : error
        };
    }

}

export async function updateLogoClient(clientId, data64) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/updateLogoClient', {
            client_id: clientId,
            img_base64: data64,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateClientCadprevInfos(clientId, infosCadprev) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/updateClientCadprevInfos', {
            client_id: clientId, infos_cadprev: infosCadprev
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getClientGesconInfos(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/getClientGesconInfos/' + clientId, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function updateClientGesconInfos(clientId, infosGescon) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/updateClientGesconInfos', {
            client_id: clientId, infos: infosGescon
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function checkHasAssetsInClient(clientId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/checkHasAssetsInClient/' + clientId, config)

        return response.data;

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllRelationsClients(month, year) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/client/getAllRelationsClients?month=' + month + '&year=' + year, config)

        console.log("RESPONSE: ", response);
        return {
            success: true,
            body: response.data
        }

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getBalancesPortfolioMonthByPeriodAndRegime(clientId, regimeId, startDate, endDate) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getBalancesPortfolioMonthByPeriodAndRegime', {
            client_id: clientId,
            regime_id: regimeId,
            startDate: startDate,
            endDate: endDate
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getBalancesAndSumTransactionsPortfolioInMonthByPeriod(clientId, startDate, endDate, search, segmentId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/client/getBalancesAndSumTransactionsPortfolioInMonthByPeriod', {
            client_id: clientId,
            startDate: startDate,
            endDate: endDate,
            search: search ? search : '',
            segment_id: segmentId,
        }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getUpdateLogList(clientId) {

    try {

        const response = await API.get('/client/getUpdateLogList' + (clientId ? `?client_id=${clientId}` : ""), defaultConfig)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("Error: ", error);

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function getAllActivesClientsIdsAndNamesByConsultingId(consultingId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get(`/client/getAllActivesClientsIdsAndNamesByConsultingId?consulting_id=${consultingId}`, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("Error: ", error.response.data);

        return {
            success: false,
            body: error.response.data
        };
    }

}
