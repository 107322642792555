import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useStyles } from '..';

/**
 * @param {{
 * title: string
 * subtitle: string
 * }} param0
 * @returns 
 */
export const CustomDialogTitle = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <DialogTitle id="scroll-dialog-title">
            {title}
            <div className={classes.titleDescriptionStyle}>
                {subtitle}
            </div>
        </DialogTitle>
    )
};