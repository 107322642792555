import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import MyTextField from './MyTextField';
import MySelect from './MySelect';
import { createImprovement, deleteImprovement, getAllImprovementTypes, updateImprovement } from '../../API/improvement';
import UnoButton from './UnoButton';
import { useDispatch } from 'react-redux';
import allActions from '../../actions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    actionsFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    titleDescriptionStyle: {
        fontSize: '14px'
    }
}))

export default function MyFormDialog(props) {
    const {
        open,
        receivedImprovement,
        deleteTrigger,
        handleListUpdate,
        handleDeleteImprovement,
        handleDeleteTrigger,
        handleClose } = props

    const classes = useStyles()
    const dispatch = useDispatch()

    const [submitted, setSubmitted] = useState(false);

    const [inputImprovementTitle, setInputImprovementTitle] = useState("");
    const [inputImprovementDescription, setInputImprovementDescription] = useState("");
    const [inputImprovementType, setInputImprovementType] = useState(null);
    const [inputImprovementVideoUrl, setInputImprovementVideoUrl] = useState("");

    const [improvementTypes, setImprovementTypes] = useState(null);

    const [receivedImprovementId, setReceivedImprovementId] = useState(null);

    async function doGetImprovementTypes() {
        const response = await getAllImprovementTypes();

        if (response.success && response.body) {
            setImprovementTypes(response.body.rows);
            setInputImprovementType(response.body.rows[0].id);
        } else {
            console.error("RESPONSE ERROR: ", response.error);
        }
    }

    async function doSaveImprovement() {

        setSubmitted(true);

        let improvement = {
            title: inputImprovementTitle,
            description: inputImprovementDescription,
            video_url: inputImprovementVideoUrl,
            improvement_type_id: inputImprovementType,
            created_date: moment.utc(new Date()),
            modified_date: moment.utc(new Date())
        }

        //validação prévia
        if (inputImprovementDescription != "") {

            setSubmitted(false);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Cadastrando melhoria..."
            }))

            let response = await createImprovement(improvement);

            if (response.success) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Melhoria cadastrada com sucesso"
                }));

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                handleListUpdate()
                handleClose()
                clearForm()
            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Erro ao cadastrar a melhoria: " + response.body.message
                }));
            }
        }
    }

    async function doUpdateImprovement() {

        setSubmitted(true);

        let improvement = {
            title: inputImprovementTitle,
            description: inputImprovementDescription,
            video_url: inputImprovementVideoUrl,
            improvement_type_id: inputImprovementType,
            modified_date: moment.utc(new Date()),
            id: receivedImprovementId
        }

        //validação prévia
        if (inputImprovementDescription != "") {

            setSubmitted(false);

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: true,
                message: "Atualizando melhoria..."
            }))

            let response = await updateImprovement(improvement);

            if (response.success) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: "Melhoria atualizada com sucesso"
                }));

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                handleListUpdate()
                handleClose()
            } else {

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: false,
                }))

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Erro ao atualizar a melhoria: " + response.body.message
                }));
            }
        }
    }

    async function doDeleteImprovement() {

        dispatch(allActions.fullLoadActions.setFullLoad({
            show: true,
            message: "Excluindo melhoria..."
        }))

        let response = await deleteImprovement(receivedImprovementId);
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Melhoria excluída com sucesso!"
            }))

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            handleListUpdate()
            handleClose()

        } else {

            dispatch(allActions.fullLoadActions.setFullLoad({
                show: false,
            }))

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: "Erro ao excluir melhoria!"
            }))
        }
    }

    function clearForm() {
        setInputImprovementTitle("");
        setInputImprovementDescription("");
        if (improvementTypes) {
            setInputImprovementType(improvementTypes[0].id)
        }
        setInputImprovementVideoUrl("");
    }

    function fillForm(element) {
        setInputImprovementTitle(element.improvement_title);
        setInputImprovementDescription(element.improvement_description);
        setInputImprovementType(element.improvement_type_id)
        setInputImprovementVideoUrl(element.video_url);
        setReceivedImprovementId(element.improvement_id)
    }

    useEffect(() => {
        doGetImprovementTypes();
    }, []);

    useEffect(() => {

        clearForm()

        if (receivedImprovement) {
            fillForm(receivedImprovement)
        }
    }, [receivedImprovement]);

    useEffect(() => {
        if (deleteTrigger) {
            doDeleteImprovement()
            handleDeleteTrigger()
        }
    }, [deleteTrigger]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {receivedImprovement ? 'Atualização de' : 'Nova'} Melhoria
                    <div className={classes.titleDescriptionStyle}>
                        Insira os dados para {receivedImprovement ? 'atualização da' : 'registro da nova'} melhoria
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MyTextField
                                value={inputImprovementTitle}
                                onChange={(value) => setInputImprovementTitle(value)}
                                outerlabel={'Título'}
                                placeholder="Insira um título"
                                validator={inputImprovementTitle == "" && submitted ? true : undefined}
                                helperText={"Insira o título"} />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                value={inputImprovementDescription}
                                onChange={(value) => setInputImprovementDescription(value)}
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                rowsMax={5}
                                margin="dense"
                                outerlabel={'Descrição'}
                                type="text"
                                placeholder="Insira a descrição da melhoria"
                                validator={inputImprovementDescription == "" && submitted ? true : undefined}
                                helperText={"Insira a descrição"}
                                fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <MySelect
                                outerlabel={'Tipo de Melhoria'}
                                value={inputImprovementType}
                                onChange={(event) => setInputImprovementType(event.target.value)}
                                options={improvementTypes && improvementTypes.map((row, index) => (
                                    <option key={'improvement-type-select-' + index} value={row.id}>{row.label}</option>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                value={inputImprovementVideoUrl}
                                onChange={(value) => setInputImprovementVideoUrl(value)}
                                variant="outlined"
                                margin="dense"
                                outerlabel={'URL'}
                                type="url"
                                placeholder="Insira o link do vídeo"
                                fullWidth />
                        </Grid>
                    </Grid>
                </DialogContent >

                <DialogActions className={classes.actionsFlex}>
                    <UnoButton
                        onClick={handleClose}
                        color="secondary">
                        Voltar
                    </UnoButton>
                    {
                        receivedImprovement
                            ?
                            <div style={{ display: 'flex' }}>
                                <UnoButton
                                    onClick={handleDeleteImprovement}
                                    color="secondary">
                                    Excluir
                                </UnoButton>
                                <UnoButton
                                    onClick={doUpdateImprovement}
                                    color="primary">
                                    Atualizar
                                </UnoButton>
                            </div>
                            :
                            <UnoButton
                                onClick={doSaveImprovement}
                                color="primary">
                                Cadastrar
                            </UnoButton>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
