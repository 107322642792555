import API from './config'

export const ClientRiskDataAPI = {

    async createData(clientId, month, year) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post(`/client_risk_data/createData`, {
                client_id: clientId,
                month: month,
                year: year,
            }, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },


    async getClientRiskData(clientId, month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/client_risk_data?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getClientRiskMarketData(clientId, month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/client_risk_data/getMarketData?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },
    async getClientRiskArticlesData(clientId, month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/client_risk_data/getArticlesData?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },
    async getClientRiskBenchmarksData(clientId, month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/client_risk_data/getBenchmarksData?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },
    async getClientRiskPolicyData(clientId, month, year) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/client_risk_data/getPolicyData?client_id=${clientId}&month=${month}&year=${year}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async saveData(clientId, month, year, type, data) {

        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.patch(`/client_risk_data/saveData`, {
                client_id: clientId,
                month: month,
                year: year,
                type: type,
                data: data,
            }, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },


}