import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

//Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import { headerSearch, unoTheme } from '../../../assets/styles/unoTheme';
import {
    applyMaskAccount,
    applyMaskAmount,
    getMonthName,
    getTitleCod,
    verifyClientPortfolioIsSgregated
} from "../../utils/utils";
// import NewAssetModal from "../../modals/NewAssetModal";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import Tooltip from '@material-ui/core/Tooltip';


import { getAssetInfos } from '../../../controllers/AssetsController';
import { loadClientDiaryByRange } from '../../../controllers/ClientController';

import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import PrintIcon from '@material-ui/icons/Print';

import { GENERIC_ASSETS } from "../../../controllers/TitleController";
import { codsInnerLoad } from '../../utils/MyInnerLoad';
import { disponibilizationDicionary } from "../../utils/dicionarys";

//Import excel
import XLSX from 'xlsx';
import { downloadBlob, s2ab } from "../../../controllers/ExcelController";
import { reportTypes } from "../../utils/ReportTypes";
import { getRegimeTypes } from "../../utils/regimeTypes";

const rightDivWidth = 450;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        //boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.common.black,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    headerSearch: headerSearch,

    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableContainer: {
        padding: '0px 18px 16px 18px',
        borderRadius: '10px 0px 0px 10px',
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                backgroundColor: 'white',
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    clickableHead: {
        cursor: 'pointer',
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    green: {
        color: unoTheme.greenMoney
    },

    leftChart: {
        padding: theme.spacing(2),
        width: '50%',
    },

    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },

    //RightDiv
    mainRoot: {
        height: '100%',
        width: '100%',
        transition: 'all .3s ease',
    },
    rightDiv: {
        height: '100%',
        minWidth: rightDivWidth,
        width: rightDivWidth,
        background: 'white',
        transition: 'all .3s ease',
        position: 'fixed',
        right: 0,
        //paddingRight: theme.spacing(2),
        marginRight: rightDivWidth * (-1),
    },
    rightDivOpen: {
        height: '100%',
        //marginRight: rightDivWidth - 24,
        marginRight: rightDivWidth,
        borderRight: `solid 24px ${unoTheme.menuContent}`,
    },
    rightDivClose: {
        height: '100%',
        marginRight: '0px',
    },
    rightDivContentOpen: {
        marginRight: '0px',

    },
    rightDivContentClose: {
        marginRight: rightDivWidth * (-1),
    },
    leftRightDiv: {
        background: unoTheme.menuContent,
        height: '100%',
        width: '24px',
        minWidth: '24px',
    },
    mainRightDiv: {
        float: 'right',
        height: '100%',
        //width: rightDivWidth - 24,
        //minWidth: rightDivWidth - 24,        
        width: rightDivWidth,
        minWidth: rightDivWidth,
    },
    openButton: {
        transition: 'all .3s ease',
    },
    rightDivButtonOpen: {
        opacity: 0,
        cursor: 'unset'
    },
    rightDivButtonClose: {
        opacity: 1,
        cursor: 'pointer',
    },
    headerRightDiv: {
        display: "flex",
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTypography-root': {
            marginBottom: '0px',
        },

        '& .MuiIconButton-root': {
            padding: '8px',
        }
    },
    spacingLeft1: {
        marginLeft: theme.spacing(1)
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    tableFixed: {
        tableLayout: 'fixed',
    },
    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'white',
                background: unoTheme.mainColor,
            },
        },

        '& .disponibilityLine': {
            '& .MuiTableCell-footer': {
                color: 'black',
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },

    tableWithFooter: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    footerTable: {
        color: 'white',
        margin: '0px 18px 18px 18px',
        borderRadius: '10px',
        minHeight: '32px',
        backgroundColor: unoTheme.mainColor,
        width: 'calc (100%-32px)',
        height: '32px',
        display: 'flex',
        alignItems: 'center',

        '& .MuiTypography-root': {
            fontSize: '12px',
        },

        '& .d': {
            padding: '16px',
        },
        '& .d1': {
            textAlign: 'left',
            width: "39%"
        },
        '& .d2': {
            width: "15%",
            textAlign: 'right',
        },
        // '& .d4': {
        //     width: "35%"
        // }
    },

    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
}));

export default function AssetsScreen(props) {

    const { setCountBlankAprs } = props;

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const [clientOnScreen, setClientOnScreen] = useState(undefined);

    const [appearFab, setAppearFab] = useState(true);

    const [resolutionName, setResolutionName] = useState(null);

    const [assetsFormatted, setAssetsFormatted] = React.useState([]);
    const [totalResultAmount, setTotalResultAmount] = useState(0);
    const [totalRentPercentInMonth, setTotalRentPercentInMonth] = useState(0);
    const [totalRentMoneyInMonth, setTotalRentMoneyInMonth] = useState(0);
    const [totalDisponibilityInAccounts, setTotalDisponibilityInAccounts] = useState({
        now: 0,
        before: 0
    });

    const [rentMonthMode, setRentMonthMode] = useState('percent')

    //Charts
    const [dataChartParticipation, setDataChartParticipation] = useState(false);



    const useRowStyles = makeStyles({
        root: {
            borderBottom: 'unset',
            '& > *': {
                borderBottom: 'unset',
            },
            '& .MuiTableCell-sizeSmall:last-child': {
                paddingRight: '24px',
            },

            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },


        },
        oddRow: {
            backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            '& .MuiTableRow-root.maybeOdd': {
                background: `${unoTheme.secondaryTuplaTable}`,
            },
        },
        noRadiusBottom: {
            '& .MuiTableCell-root:first-child': {
                borderTopLeftRadius: '10px !important',
                borderBottomLeftRadius: '0px !important',
                backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            },
            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px !important',
                borderBottomRightRadius: '0px !important',
                backgroundColor: `${unoTheme.secondaryTuplaTable} !important`,
            },
        },
        green: {
            color: unoTheme.greenMoney,
        },
        red: {
            color: unoTheme.errorColor,
        },
        blue: {
            color: "blue",
        },
        ok: {
            color: unoTheme.greenMoney,
            cursor: 'pointer',

            fontSize: '1.2em',

        },
        pendent: {
            color: unoTheme.warningColor,
            cursor: 'pointer',
            fontSize: '1.2em',

        },

        disponibilizationWithTip: {
            cursor: 'pointer',
            fontWeight: 'bold',
        },

        hiddenIcon: {
            visibility: 'hidden'
        },
        arrowTableIcon: {
            background: unoTheme.mainColor,
            color: 'white',
            borderRadius: '4px',
            height: '22px',
            width: '22px',

            '&:hover': {
                color: 'white',
                background: unoTheme.hoverMainButtonColor
            }
        },
        noPaddingCell: {
            padding: '0px !important',
            height: '1px',
        },
        ramification1: {
            display: 'flex',
            height: '100% !important',
            width: '100% !important',
            alignItems: 'center',

            '& .rLeft': {
                height: '100%',
                width: '50%',
                marginLeft: '7px',
                '& .lTop': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },

                '& .lBottom': {
                    height: '50%',
                    width: '100%',
                    borderRight: 'solid 1px #dddee0',
                },
            },

            '& .rRight': {
                height: '100%',
                width: '50%',
                '& .tTop': {
                    height: '50%',
                    width: '100%',
                    borderBottom: `solid 1px #dddee0`,
                },

                '& .tBottom': {
                    height: '50%',
                    width: '100%',
                },
            },

            '& .littleBall': {
                height: '7px',
                width: '8px',
                borderRadius: '50%',
                background: unoTheme.mainColor,
                marginTop: '-1.5px',
            }

        },
        tableFixed: {
            tableLayout: 'fixed',
        },
        borderOnOpen: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        // lastSubAssetBorderBottom: {
        //     '& > *': {
        //         borderBottom: 'unset !important',
        //     },
        // }

    });

    function getColGroup() {
        return (
            <colgroup>
                <col width="5%" />
                <col width="30%" />
                <col width="15%" />
                <col width="10%" />
                <col width="7%" />
                <col width="9%" />
                <col width="14%" />
                <col width="10%" />
            </colgroup>
        )
    }

    function Row(props) {
        const { row, index } = props;
        const [open, setOpen] = React.useState(false);
        const classes = useRowStyles();

        const isTitle = row.title_id != null;

        let disponibilization = null;
        if (row.local_liquidity) {
            disponibilization = disponibilizationDicionary(row.local_liquidity);
        } else if (row['dados_cadastrais']) {
            disponibilization = disponibilizationDicionary(row['dados_cadastrais'].disponibilization);
        }

        return (
            <React.Fragment>
                <TableRow key={'asset-' + index}
                    className={[
                        index % 2 != 1 ? classes.oddRow : "",
                        classes.root,
                        open && index % 2 != 1 ? classes.noRadiusBottom : ""
                    ].join(' ')}>
                    <TableCell align="center">

                        <IconButton aria-label="expand row" size="small"
                            className={[
                                (!row.subAssets || (row.subAssets && row.subAssets.length) == 0)
                                    && (!row.subAssetsTitles || (row.subAssetsTitles && row.subAssetsTitles.length) == 0) ?
                                    classes.hiddenIcon : "", classes.arrowTableIcon].join(' ')}
                            onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>

                    </TableCell>
                    <TableCell>
                        {!isTitle ? row.fund_name : getTitleCod(row)}
                    </TableCell>
                    <TableCell align="right" className={classes.green}>
                        {
                            row.balance_now != undefined ?
                                applyMaskAmount(row.balance_now, true)
                                : 'Não informado'
                        }
                    </TableCell>

                    {/* Participação */}
                    <TableCell align="right">
                        {row.participation}%
                    </TableCell>

                    {/* Disponibilidade */}
                    <TableCell align="center">
                        {disponibilization ?
                            (
                                disponibilization.tip ?
                                    <LightTooltip title={disponibilization.tip} placement="bottom" arrow>
                                        <span className={classes.disponibilizationWithTip}>{disponibilization.label}</span>
                                    </LightTooltip>

                                    :

                                    disponibilization.label
                            )

                            : '-'}

                    </TableCell>

                    <TableCell align="center">
                        {row.device_abbreviation ? row.device_abbreviation : '-'}
                    </TableCell>

                    {/* Retorno mensal */}

                    {
                        row.fund_name === GENERIC_ASSETS ? //exibindo "-" no caso de imóvel
                            <TableCell align={"right"}>
                                -
                            </TableCell>
                            :
                            <TableCell align={"right"}>
                                {
                                    rentMonthMode == 'percent'
                                        ?
                                        applyMaskAmount(row.rentPercentInMonth, null, true) + '%'
                                        :
                                        applyMaskAmount(row.rentMoneyInMonth, true)
                                }
                            </TableCell>
                    }


                    {/* <TableCell
                        align={"center"}>
                        {
                            row.aprs ?

                                <CheckCircleIcon className={classes.ok} style={{ verticalAlign: 'middle' }} />
                                :
                                <LightTooltip title={"Existe uma ou mais movimentações com ausência de APR"} placement="bottom" arrow>
                                    <ReportProblemIcon className={classes.pendent} style={{ verticalAlign: 'middle' }} />
                                </LightTooltip>
                        }

                    </TableCell> */}

                    <TableCell
                        align={"center"}>
                        {row["dados_cadastrais"]?.administration_tax != null ?
                            applyMaskAmount(row["dados_cadastrais"].administration_tax) + "%" : "-"}

                    </TableCell>

                </TableRow>
                <TableRow
                    className={[classes.innerTableRow, index % 2 == 0 ? classes.oddRow : "", open ? "" : classes.borderOnOpen].join(' ')}>
                    <TableCell style={{ padding: 0 }} colSpan={8}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box>
                                <Table size="small"
                                    className={classes.tableFixed}>
                                    {/* <colgroup>
                                        <col width="5%" />
                                        <col width="34%" />
                                        <col width="15%" />
                                        <col width="13%" />
                                        <col width="11%" />
                                        <col width="16%" />
                                        <col width="6%" />
                                    </colgroup> */}
                                    {getColGroup()}

                                    {

                                        row
                                            && row.subAssets ?
                                            <TableBody>
                                                {row.subAssets.map((subAsset, index) => (
                                                    <TableRow key={'sub-asset-' + index}
                                                        className={[classes.root].join(' ')}>
                                                        <TableCell align="center"
                                                            className={classes.noPaddingCell}>

                                                            <div className={classes.ramification1}>

                                                                <div className={'rLeft'}>
                                                                    <div className={'lTop'}>

                                                                    </div>
                                                                    {
                                                                        index == row.subAssets.length - 1 ?
                                                                            null
                                                                            :
                                                                            <div className={'lBottom'}>

                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className={'rRight'}>
                                                                    <div className={'tTop'}>

                                                                    </div>

                                                                    <div className={'tBottom'}>

                                                                    </div>

                                                                </div>
                                                                <div className={'littleBall'}></div>
                                                            </div>

                                                            {/* <IconButton aria-label="expand row" size="small"
                                                                className={[classes.arrowTableIcon].join(' ')}>
                                                                <KeyboardArrowUpIcon />
                                                            </IconButton> */}

                                                        </TableCell>
                                                        <TableCell>
                                                            <span>CC: {applyMaskAccount(subAsset.number_account)} {subAsset.agency ? "/ Ag: " + subAsset.agency : ""}</span>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.green}>
                                                            {
                                                                subAsset.balance_now != 'Não informado' ?
                                                                    applyMaskAmount(subAsset.balance_now, true)
                                                                    : 'Não informado'
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {subAsset.participation}%
                                                        </TableCell>
                                                        {/* Disponibilidade */}
                                                        <TableCell align="center">{'-'}</TableCell>
                                                        {/* Enquadramento */}
                                                        <TableCell align="center">{'-'}</TableCell>
                                                        {/* Retorno mensal */}
                                                        <TableCell align="right">{'-'}</TableCell>

                                                        <TableCell align="center"
                                                            className={classes.pendent}>
                                                            {/* <ReportProblemIcon /> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody> : null
                                    }

                                    {

                                        row
                                            && row.subAssetsTitles ?
                                            <TableBody>
                                                {row.subAssetsTitles.map((subAsset, index) => (
                                                    <TableRow key={'sub-asset-title' + index}
                                                        className={[classes.root].join(' ')}>
                                                        <TableCell align="center"
                                                            className={classes.noPaddingCell}>

                                                            <div className={classes.ramification1}>

                                                                <div className={'rLeft'}>
                                                                    <div className={'lTop'}>

                                                                    </div>
                                                                    {
                                                                        index == row.subAssetsTitles.length - 1 ?
                                                                            null
                                                                            :
                                                                            <div className={'lBottom'}>

                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className={'rRight'}>
                                                                    <div className={'tTop'}>

                                                                    </div>

                                                                    <div className={'tBottom'}>

                                                                    </div>

                                                                </div>
                                                                <div className={'littleBall'}></div>
                                                            </div>

                                                        </TableCell>
                                                        <TableCell>
                                                            <span><span style={{ color: 'blue' }}>{getTitleCod(subAsset)}</span> / CC: {applyMaskAccount(subAsset.number_account)} {subAsset.agency ? "/ Ag: " + subAsset.agency : ""}</span>
                                                        </TableCell>
                                                        <TableCell align="right" className={classes.green}>
                                                            {
                                                                subAsset.balance_now != 'Não informado' ?
                                                                    applyMaskAmount(subAsset.balance_now, true)
                                                                    : 'Não informado'
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {subAsset.participation}%
                                                        </TableCell>
                                                        {/* Disponibilidade */}
                                                        <TableCell align="center">{'-'}</TableCell>
                                                        {/* Enquadramento */}
                                                        <TableCell align="center">{'-'}</TableCell>
                                                        {/* Retorno mensal */}
                                                        <TableCell align="right">{'-'}</TableCell>

                                                        <TableCell align="center"
                                                            className={classes.pendent}>
                                                            {/* <ReportProblemIcon /> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody> : null
                                    }

                                    {/* {
                                        row.transactions && row.transactions.length > 0 ?
                                            <TableFooter>
                                                <TableRow key={'footer-t-transactions'}>
                                                    <TableCell align="left">Total:</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="right">{applyMaskAmount(row.resultAmount, true)}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableFooter> : null
                                    } */}
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    const loadInfosAssets = useCallback(async (clientId, month, year, accountRegimeId, doQuotas) => {

        console.log("PASSANFO LOAD INFOS ASSETS");

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            cod: codsInnerLoad.assetsScreen,
            loadMessage: 'Carregando ativos...',
        }));

        //Caso o month e o year sejam undefined (quando ainda não existem movimentações cadastradas)
        //dentro do percusso desse método deve se parar no momento em que trás as contas
        let responseInfosAssets = await getAssetInfos(clientId, month, year, accountRegimeId, doQuotas);
        let lastDiaryMonth = await loadClientDiaryByRange(clientId,
            month,
            year, 1);

        console.log('lastDiaryMonth: ', lastDiaryMonth);

        console.log("### INFO ASSETS: ", responseInfosAssets);
        if (responseInfosAssets.success) {

            //Atualizando componentes da tela            
            //setAssetsFormatted(responseInfosAssets.assetsFormatted.filter(ass => ass.balance_now > 0));
            setAssetsFormatted(responseInfosAssets.assetsFormatted);



            setResolutionName(responseInfosAssets.resolutionName);
            //calcParticipation(responseInfosAssets.totalResultAmount, responseInfosAssets.assetsFormatted);
            setTotalResultAmount(responseInfosAssets.totalResultAmount);
            setTotalRentMoneyInMonth(responseInfosAssets.totalRentMoneyInMonth);

            console.log("RENTABILIDADE FUNDOS E TITULOS: ", {
                funds: responseInfosAssets.totalRentMoneyInMonthFunds,
                titles: responseInfosAssets.totalRentMoneyInMonthTitles,
            })

            console.log('VALOR CARTEIRA lastDiaryMonth.arrayRents: ', lastDiaryMonth.arrayRents);
            setTotalRentPercentInMonth(lastDiaryMonth.arrayRents && lastDiaryMonth.arrayRents.length === 1 ? lastDiaryMonth.arrayRents[0].rent : 0);
            setTotalDisponibilityInAccounts(responseInfosAssets.totalDisponibilityInAccounts);
            //verifyAPRS(responseInfosAssets.assetsFormatted);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                message: responseInfosAssets.message,
            }))

        }

        if (responseInfosAssets && responseInfosAssets.assetsFormatted.length == 0) {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                emptyMessage: "Não existem ativos na carteira no mês para esse regime de conta",
                cod: codsInnerLoad.assetsScreen
            }));
        } else {
            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: false,
                cod: codsInnerLoad.assetsScreen
            }));
        }



    }, [])

    async function doReport() {


        const regimesArray = getRegimeTypes(verifyClientPortfolioIsSgregated(clientOn));
        const regimeLabel = accountRegimeId ? regimesArray[accountRegimeId] : regimesArray[0];

        console.log('regimeLabel: ', regimeLabel);

        dispatch(allActions.reportLoadActions.setReportLoad({
            show: true,
            reportType: reportTypes.portfolio.cod,
            periodReport: 'mensal',
            regimeLabel: regimeLabel,
            accountRegimeId: accountRegimeId, //Adicionando parametro para gerar relatório com segregacao
            message: 'Gerando o relatório...'
        }))

    }

    function doExcelPortfolio(fileName, month, year, items, resolutionName) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        console.log("items: ", items);

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Carteira",
            Author: "UNO",
        }

        const sheetName = "Carteira " + getMonthName(month) + "-" + year;
        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push(['ATIVO', 'CNPJ', 'SALDO', 'PARTICIPAÇÃO', 'DISPONIBILIZAÇÃO', resolutionName, 'RET.MENSAL(%)', 'RET.MENSAL(R$)', 'APRS PREENCHIDAS'])

        items.forEach(element => {
            ws_data.push([
                element.fund_name,
                element.cnpj,
                applyMaskAmount(element.balance_now),
                element.participation,
                element['dados_cadastrais'] ? disponibilizationDicionary(element['dados_cadastrais'].disponibilization).label : '-',
                element.device_abbreviation,
                applyMaskAmount(element.rentPercentInMonth, null, true) + '%',
                applyMaskAmount(element.rentMoneyInMonth, true),
                element.aprs ? 'SIM' : 'NÃO'
            ])
        });

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    const {
        accountRegimeId,
        flagDoExcelPortfolio,
        resetFlag,
    } = props

    useEffect(() => {


        //Caso em que alterou-se o cliente
        if (clientOn && clientOn.client
            && (!clientOnScreen || clientOn.client.id != clientOnScreen.id)) {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                cod: codsInnerLoad.assetsScreen,
                loadMessage: 'Carregando ativos...',
            }));

            setAssetsFormatted([]);
            setTotalResultAmount(0);
            setTotalRentMoneyInMonth(0);
            setTotalRentPercentInMonth(0);

            //atualizando account regime para testar numa possível mudança
            clientOn.client.accountRegimeId = accountRegimeId;
            setClientOnScreen(_.cloneDeep(clientOn.client));
            if (clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear) {

                loadInfosAssets(clientOn.client.id,
                    clientOn.client.selectedPortfolioMonth,
                    clientOn.client.selectedPortfolioYear,
                    accountRegimeId,
                    false);

            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Este cliente ainda não possui nenhum mês com ativos fechados",
                    cod: codsInnerLoad.assetsScreen
                }));
            }

            //Caso em que alterou-se o mês ou o ano no controller no dashboard
        } else if (clientOn && clientOn.client && clientOnScreen
            && clientOn.client.id == clientOnScreen.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
            && clientOn.client.portfolio_closed != clientOnScreen.portfolio_closed) {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando ativos...',
                cod: codsInnerLoad.assetsScreen
            }));

            setAssetsFormatted([]);
            setTotalResultAmount(0);
            setTotalRentMoneyInMonth(0);
            setTotalRentPercentInMonth(0);

            clientOn.client.accountRegimeId = accountRegimeId;
            setClientOnScreen(_.cloneDeep(clientOn.client));
            //loadData(clientOn.client.id, clientOn.client.selectedMonth, clientOn.client.selectedYear);
            let splittedClosedMonth = "";
            if (clientOn.client.portfolio_closed) {
                splittedClosedMonth = clientOn.client.portfolio_closed.split('/');
                loadInfosAssets(clientOn.client.id,
                    splittedClosedMonth[0],
                    splittedClosedMonth[1],
                    accountRegimeId,
                    false);
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({
                    show: true,
                    emptyMessage: "Este cliente ainda não possui nenhum mês com ativos fechados",
                    cod: codsInnerLoad.assetsScreen
                }));
            }

            //Caso em que alterou-se o mês ou o ano
        } else if (clientOn && clientOn.client && clientOnScreen
            && clientOn.client.id == clientOnScreen.id
            && clientOn.client.selectedPortfolioMonth && clientOn.client.selectedPortfolioYear
            && (
                clientOn.client.selectedPortfolioMonth != clientOnScreen.selectedPortfolioMonth
                ||
                clientOn.client.selectedPortfolioYear != clientOnScreen.selectedPortfolioYear
                ||
                accountRegimeId != clientOnScreen.accountRegimeId
            )
        ) {

            dispatch(allActions.innerLoadActions.setInnerLoad({
                show: true,
                loadMessage: 'Carregando ativos...',
                cod: codsInnerLoad.assetsScreen
            }));

            setAssetsFormatted([]);
            setTotalResultAmount(0);
            setTotalRentMoneyInMonth(0);
            setTotalRentPercentInMonth(0);

            clientOn.client.accountRegimeId = accountRegimeId;
            setClientOnScreen(_.cloneDeep(clientOn.client));

            loadInfosAssets(clientOn.client.id,
                clientOn.client.selectedPortfolioMonth,
                clientOn.client.selectedPortfolioYear,
                accountRegimeId,
                false);

        }

        if (flagDoExcelPortfolio) {

            console.log("CHAMANDEO DO EXCEL PORTFOLIO:");
            if (clientOn.client.selectedPortfolioMonth &&
                clientOn.client.selectedPortfolioYear &&
                assetsFormatted && assetsFormatted.length) {

                doExcelPortfolio(
                    clientOn.client.label_name + "_CARTEIRA_" + getMonthName(clientOn.client.selectedPortfolioMonth) + "_" + clientOn.client.selectedPortfolioYear,
                    clientOn.client.selectedPortfolioMonth,
                    clientOn.client.selectedPortfolioYear,
                    assetsFormatted,
                    resolutionName
                )
            }

            resetFlag();
        }

    }, [clientOn,
        accountRegimeId,
        flagDoExcelPortfolio])

    return (
        <div className={classes.root}>

            <div className={classes.mainRoot}>
                <div class={'defaultScrollWithHeaderContent'}>

                    {
                        assetsFormatted && assetsFormatted.length > 0 ?

                            <TableContainer component={Paper} className={classes.tableContainer} elevation={0}>
                                <Table stickyHeader className={classes.table} size="small" >

                                    {getColGroup()}
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="left">Ativo</TableCell>
                                            <TableCell align="right">Saldo</TableCell>
                                            <TableCell align="right">
                                                <LightTooltip title={"Participação na carteira"} placement="top" arrow>
                                                    <span>Particip.</span>
                                                </LightTooltip>

                                            </TableCell>
                                            <TableCell align="center">
                                                <LightTooltip title={"Disponibilidade de resgate"} placement="top" arrow>
                                                    <span>Disp.</span>
                                                </LightTooltip>
                                            </TableCell>
                                            <TableCell align="center">
                                                {resolutionName ? resolutionName : '-'}
                                            </TableCell>
                                            <TableCell align="right" className={classes.clickableHead} onClick={() => rentMonthMode == 'value' ? setRentMonthMode('percent') : setRentMonthMode('value')}>
                                                {`Ret. ${rentMonthMode == 'value' ? '(R$)' : '(%)'}`}
                                            </TableCell>
                                            {/* <TableCell align="center">APRs</TableCell> */}
                                            <TableCell align="center">TX ADM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {assetsFormatted.map((row, index) => (
                                            <Row key={'asset-formatted-portfolio-' + index} row={row} index={index} />
                                        ))}
                                    </TableBody>

                                    {
                                        assetsFormatted && assetsFormatted.length > 0 ?
                                            <TableFooter className={classes.myTableFooter}>
                                                <TableRow className='totalLineInvestiments' key={'footer-t-assets-collect-investiments'}>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">TOTAL INVESTIMENTOS</TableCell>
                                                    <TableCell align="right"><span className={'spanTotal'}>{totalResultAmount ? applyMaskAmount(totalResultAmount, true) : "-"}</span></TableCell>
                                                    <TableCell align="right"><span className={'spanTotal'}>100%</span></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="right"><span className={'spanTotal'}>{

                                                        rentMonthMode == 'percent'
                                                            ?
                                                            (!accountRegimeId ? applyMaskAmount(totalRentPercentInMonth) + '%' : '-')
                                                            :
                                                            (totalRentMoneyInMonth ? applyMaskAmount(totalRentMoneyInMonth, true) : "-")


                                                    }</span></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                                <TableRow className='disponibilityLine' key={'footer-t-assets-collect-disponibility'}>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">DISPONIBILIDADE</TableCell>
                                                    <TableCell align="right"><span className={'spanTotal'}>{totalDisponibilityInAccounts.now ? applyMaskAmount(totalDisponibilityInAccounts.now, true) : "-"}</span></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                                <TableRow className='totalLinePL' key={'footer-t-assets-collect-total'}>
                                                    <TableCell align="left"></TableCell>
                                                    <TableCell align="left">TOTAL PATRIMÔNIO</TableCell>
                                                    <TableCell align="right"><span className={'spanTotal'}>{totalResultAmount != null && totalDisponibilityInAccounts.now != null ?
                                                        applyMaskAmount(totalResultAmount + totalDisponibilityInAccounts.now, true) : '-'}</span></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableFooter> : null
                                    }

                                    {/* <TableFooter className={classes.myTableFooter}>
                                        <TableRow key={'footer-t-assets'}>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell align="right">
                                                <span className={'spanTotal'}>{applyMaskAmount(totalResultAmount, true)}</span>
                                            </TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                        </TableRow>
                                    </TableFooter> */}


                                </Table>
                            </TableContainer>
                            :
                            null
                        // <MyInnerLoad
                        //     size={'small'}
                        //     message={'Carregando ativos...'} />
                    }

                </div>



                {/* <NewAssetModal
                open={openAssetModal}
                handleClose={(update) => handleCloseAssetModal(update)}
                asset={assetSelected} /> */}
            </div>

            {
                clientOn
                    && clientOn.client
                    && clientOn.client.portfolio_closed ?
                    <Zoom
                        in={appearFab}
                        unmountOnExit>

                        {/* <Fab className={classes.fabAdd}
                    onClick={() => doOpenAccountModal()}
                    color="primary" aria-label="add">
                    <AddIcon />
                </Fab> */}
                        <Fab className={classes.fabAdd}
                            onClick={() => doReport()}
                            color="primary" aria-label="add">
                            <PrintIcon />
                        </Fab>
                    </Zoom> : null
            }

        </div>
    );
}



