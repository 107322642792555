import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import InputAdornment from '@material-ui/core/InputAdornment';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import { listAllAdvisors, updateUser } from '../../../API/user'
import { getInfosByCep } from '../../../API/cep'
import { getCityIdByCityName } from '../../../API/city'

import { timeStampToPtDate, isValidCep, applyMaskPhone, applyMaskCpf } from '../../utils/utils'
import { useDispatch } from "react-redux";
import allActions from "../../../actions";

import { Grid } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';

//Teste field 
import MyTextField from '../../utils/MyTextField'
import { isValidCnpj, isValidCpf, isValidEmail, justNumbers } from '../../utils/utils'

import Button from '@material-ui/core/Button';

import _ from 'lodash';

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        // '& .MuiTextField-root': {
        //     margin: theme.spacing(1),
        //     width: '25ch',
        // },
        textAlign: "left",
    },
    paperForm: {
        padding: theme.spacing(2),
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    headerDetail: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    btnRightWithInput: {
        boxSizing: 'border-box',
        background: '#28a745',
        border: 'solid 1px #bbbbbb',
        borderLeft: 'none',
        borderRadius: '0px 4px 4px 0px',
        cursor: "pointer",
        color: "white",
        transition: 'all .1s ease',
        borderColor: '#28a745',
        '&:hover': {
            background: '#218838',
            borderColor: '#1e7e34',
        },
        '& span': {
            fontSize: '15px',
        },
        '& div': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '100%',
            justifyContent: 'center',
        }
    }
}));

export default function AdvisorsDetailScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [advisor, setAdvisor] = useState(undefined)
    const [initAdvisor, setInitAdvisor] = useState(undefined);

    const [passwordReseted, setPasswordReseted] = useState(false);

    //form new client
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {

        if (!advisor) {
            setAdvisor(location.state.advisor)
        } else {
            initForm(advisor);
        }

        if (passwordReseted) {
            setInputPasswordAdvisor("");
        }

    }, [advisor, passwordReseted]);

    const [inputNameAdvisor, setInputNameAdvisor] = useState("");
    function changeNameAdvisor(value) {
        setInputNameAdvisor(value)
    }

    const [inputEmailAdvisor, setInputEmailAdvisor] = useState("");
    function changeEmailAdvisor(value) {
        setInputEmailAdvisor(value)
    }

    const [inputCpfAdvisor, setInputCpfAdvisor] = useState("");
    function changeCpfAdvisor(value) {
        setInputCpfAdvisor(value)
    }

    const [inputPhoneAdvisor, setInputPhoneAdvisor] = useState("");
    function changePhoneAdvisor(value) {
        setInputPhoneAdvisor(value)
    }

    const [inputPasswordAdvisor, setInputPasswordAdvisor] = useState("");
    function changePasswordAdvisor(value) {
        setInputPasswordAdvisor(value)
    }

    async function doUpdateAdvisor() {

        setSubmitted(true);

        let newAdvisor = {
            cpf: justNumbers(inputCpfAdvisor),
            password: passwordReseted ? inputPasswordAdvisor : null,
            name: inputNameAdvisor,
            email: inputEmailAdvisor,
            phone_number: justNumbers(inputPhoneAdvisor),
            role: 2,
        }

        //validação prévia
        if (isValidCpf(newAdvisor.cpf)
            &&
            (
                (passwordReseted && newAdvisor.password != "" && newAdvisor.password.length >= 6)
                ||
                (!passwordReseted)
            )
            && newAdvisor.name != ""
            && isValidEmail(newAdvisor.email)) {

            setSubmitted(false);

            let passwordInputed = initAdvisor.password;
            if (!passwordReseted) {
                initAdvisor.password = null;
            }

            if (!_.isEqual(initAdvisor, newAdvisor)) {

                console.log("INIT ADVISOR: ", initAdvisor)
                console.log("new advisor: ", newAdvisor)

                dispatch(allActions.fullLoadActions.setFullLoad({
                    show: true,
                    message: "Atualizando consultor..."
                }))

                let response = await updateUser(advisor.id, newAdvisor);

                if (response.success) {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: "Consultor atualizado com sucesso"

                    }));

                    //console.log("Response advisor: ", response.body);
                    setInitAdvisor({
                        cpf: justNumbers(response.body.cpf),
                        password: response.body.password,
                        name: response.body.name,
                        email: response.body.email,
                        phone_number: justNumbers(response.body.phone_number),
                        role: 2,
                    })

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }))

                } else {

                    dispatch(allActions.fullLoadActions.setFullLoad({
                        show: false,
                    }))

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: "Erro ao atualizar o consultor: " + response.body.message

                    }));
                }
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'warning',
                    message: "Nenhuma alteração foi realizada"

                }));
            }

            initAdvisor.password = passwordInputed;
        }


    }

    function initForm(advisor) {

        console.log("ADVISOR: ", advisor)
        setInputNameAdvisor(advisor.name);
        setInputEmailAdvisor(advisor.email);
        setInputCpfAdvisor(applyMaskCpf(advisor.cpf));
        setInputPhoneAdvisor(advisor.phone_number);
        setInputPasswordAdvisor(advisor.password);

        setInitAdvisor({
            cpf: justNumbers(advisor.cpf),
            password: advisor.password,
            name: advisor.name,
            email: advisor.email,
            phone_number: justNumbers(advisor.phone_number),
            role: 2,
        })
    }

    // function clearForm() {

    //     setInputNameAdvisor("");
    //     setInputEmailAdvisor("");
    //     setInputCpfAdvisor("");
    //     setInputPhoneAdvisor("");
    //     setInputPasswordAdvisor("");
    // }


    return (
        <div className={classes.root}>

            <div className={classes.headerDetail}>
                <div className={classes.leftHeaderDetail}>
                    <IconButton onClick={() => history.goBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h4" align="left">
                        {advisor ?
                            advisor.name : null
                        }
                    </Typography>

                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => doUpdateAdvisor()}>
                    {"Atualizar consultor"}
                </Button>
            </div>



            <Divider />
            <br />
            <Paper className={classes.paperForm}>
                <form className={classes.rootForm} noValidate autoComplete="off">

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={7}>
                            <MyTextField required
                                id="advisor_name"
                                label="Nome"
                                size={'small'}
                                validator={inputNameAdvisor == "" && submitted}
                                variant="outlined"
                                onChange={changeNameAdvisor}
                                helperText={"Insira um nome"}
                                value={inputNameAdvisor} />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <MyTextField
                                id="advisor_email"
                                value={inputEmailAdvisor}
                                variant="outlined"
                                size={'small'}
                                onChange={changeEmailAdvisor}
                                validator={!isValidEmail(inputEmailAdvisor) && submitted}
                                helperText={"Insira um email válido"}
                                label="Email" />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <MyTextField
                                id="advisor_cpf"
                                value={inputCpfAdvisor}
                                onChange={changeCpfAdvisor}
                                size={'small'}
                                variant="outlined"
                                validator={!isValidCpf(inputCpfAdvisor) && submitted}
                                helperText={"Insira um CPF válido"}
                                mask={['cpf']}
                                label="CPF" />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <MyTextField
                                id="advisor_phone"
                                value={inputPhoneAdvisor}
                                size={'small'}
                                onChange={changePhoneAdvisor}
                                mask={['phone']}
                                variant="outlined"
                                label="Telefone" />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            {
                                passwordReseted ?
                                    <MyTextField
                                        id="advisor_password"
                                        value={inputPasswordAdvisor}
                                        onChange={changePasswordAdvisor}
                                        size={'small'}
                                        autoFocus
                                        variant="outlined"
                                        validator={inputPasswordAdvisor == ""
                                            && inputPasswordAdvisor.length < 6
                                            && submitted}
                                        helperText={"Insira uma senha de 6 dígitos"}
                                        label="Senha"
                                    />
                                    :
                                    <Grid container>
                                        <Grid item xs={8} sm={8} md={8}>
                                            <MyTextField
                                                id="advisor_password"
                                                value={inputPasswordAdvisor}
                                                onChange={changePasswordAdvisor}
                                                size={'small'}
                                                disabled={true}
                                                variant="outlined"
                                                withRightBtn={true}
                                                validator={inputPasswordAdvisor == ""
                                                    && inputPasswordAdvisor.length < 6
                                                    && submitted}
                                                helperText={"Insira uma senha de 6 dígitos"}
                                                label="Senha"
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}
                                            className={classes.btnRightWithInput}
                                            onClick={() => setPasswordReseted(true)}>
                                            <div>
                                                <span>Resetar</span>
                                                {/* <span>senha</span> */}
                                            </div>
                                        </Grid>
                                    </Grid>

                            }

                        </Grid>
                    </Grid>
                    <br />

                </form>
            </Paper>

        </div>
    );
}



