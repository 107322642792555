import API from './config'

import { getMonthName } from '../components/utils/utils'

export async function launchedWalletEmail(client, toEmail) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    let subject = "Disponibilização da Carteira " + getMonthName(client.selectedMonth) + "/" + client.selectedYear;

    try {

        const response = await API.post('/mail/launchedWalletEmail', {
            email_to: toEmail,
            subject: subject,
            text: "Carteira disponibilizada no sistema acesse https://unoapp.com.br",
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sendResetingPassword(toEmail, h) {

    const subject = "[UNO] Redefinição de Senha";

    try {

        const response = await API.post('/mail/sendResetingPassword', {
            email_to: toEmail,
            subject: subject,
            h: h,
        })

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function sendGeneralEmail(subject, toEmail, html) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/mail/sendGeneralEmail', {
            email_to: toEmail,
            subject: subject,
            html: html,
        }, config)

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

export async function saveReqPass(email, cpf) {

    try {

        const response = await API.post('/mail/saveReqPass',
            { cpf, email }
        )

        return {
            success: true,
            body: response
        };

    } catch (error) {

        return {
            success: false,
            body: error.response.data
        };
    }

}

