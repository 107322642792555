import React, { useEffect, useState, useCallback } from "react";
import { Route, Switch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import MyAutoComplete from '../../../utils/MyAutoComplete';
import MyTable from '../../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import { updateStatus, listAllClients, listAllActiveClients, getAllClientsBySearch, getAllUsersClientByRole, getAllManagers, getAllRelationsClients, listAllClientsByConsulting, getAllClientsConsultingBySearch } from '../../../../API/client';

import { removeAccents, applyMaskAmount, formatPortfolioClosed, applyMaskPhone, getConsultingId } from '../../../utils/utils'
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../actions";


import moment from 'moment'

import { tabs, unoTheme, headerSearch } from '../../../../assets/styles/unoTheme'

import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SituationCell from "../../../utils/SituationCell";
import MyTooltip from '../../../utils/MyHelpers/MyTooltip';
import UnoButton from '../../../utils/UnoButton';
import { path } from '../../../utils/routesPaths';
import { isEmpty } from 'lodash';
import SearchBar from "../../../utils/SearchBar";

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { MyTabs, MyTab } from "../../../utils/MyTabs";
import {
    formatClientsPorfolioLaunchs,
    formatClientsTargets,
    formatClientsPolicys,
    getColorSuitability
} from "../../../../controllers/ClientController";

import { Pie } from 'react-chartjs-2';

import OffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import PrintIcon from '@material-ui/icons/Print';
//Import excel
import XLSX from 'xlsx';
import { getActiveAdvisors, getActiveAdvisorsAndAdmins, getActiveAdvisorsAndAdminsByConsulting } from "../../../../API/user";
import { ListItemIcon, ListItemText, Menu, MenuItem, TableFooter, Tooltip, withStyles } from "@material-ui/core";
import SelectDateModal from "../../../modals/SelectDateModal";
import { roles } from "../../../utils/user";

const ReportMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',

        '& ul': {
            padding: '0px',
        }
    },

})((props) => (
    <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const optionsChart = {
    responsive: true,
    //maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    myTooltip: {
        //marginLeft: theme.spacing(0.25)
    },
    headerSearch: headerSearch,
    table: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },
    leftHeaderDetail: {
        display: "flex",
        alignItems: "baseline",
    },
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        fontWeight: 'bold'
    },
    accordionNewClient: {
        marginBottom: theme.spacing(2),
    },
    heading: {
        display: 'flex',
        alignItems: 'center'
    },
    labelHeading: {
        marginTop: '2px'
    },
    rootForm: {
        textAlign: "left",
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    myDatePicker: {
        marginTop: '-6px'
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },
    fabAdd: {
        color: 'white',
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '0px !important',
        }
    },

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },

    divTable: {
        width: '40%',
        maxWidth: '40%',
    },

    divChart: {
        width: '60%',
        maxWidth: '60%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    divTableTargets: {
        width: '35%',
        maxWidth: '35%',
    },

    divChartTargets: {
        width: '65%',
        maxWidth: '65%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    greenIcon: {
        color: unoTheme.greenMoney,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }

    },

    redIcon: {
        color: unoTheme.redMoney,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '1.2em',
        }

    },

    myTableFooter: {
        cursor: "pointer",
        borderBottom: 'unset',
        '& > *': {
            borderBottom: 'unset',
        },

        '& .totalLineInvestiments': {
            '& .MuiTableCell-root': {
                color: 'black',
                borderBottom: 'unset',
                background: unoTheme.mainGrayColor,
            },
        },

        '& .totalLinePL': {
            '& .MuiTableCell-root': {
                borderBottom: 'unset',
                color: 'white',
                background: unoTheme.mainColor,
            },
        },

        '& .disponibilityLine': {
            '& .MuiTableCell-footer': {
                color: 'black',
            },
        },

        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },

        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        },

        '& .spanTotal': {
            //color: unoTheme.greenMoney,
            fontSize: '14px',
        }
    },
}));

// export const TODOS_ADVISOR = {
//     id: 999999,
//     name: 'Todos'
// }

function RowClient(props) {
    const { row, updateStatusClient, goToDetailClient } = props;
    const [open, setOpen] = React.useState(false);

    return (
        // <React.Fragment key={'fragment-client-' + row.id}>
        <TableRow
            key={'table-row-client-' + row.id}
            // className={classes.root}
            onClick={() => goToDetailClient(row)}>
            <TableCell align="center" >{row.id}</TableCell>

            <TableCell align="left" scope="row">{

                <Tooltip arrow title={row.advisor_name}>

                    <span>{row.label_name}</span>
                </Tooltip>
                //row.rpps_name_initial && row.rpps_name_initial != "" ? row.rpps_name_initial : row.rpps_name
            }</TableCell>

            {/* <TableCell align="left" component="th" scope="row">{
                row.rpps_name && row.rpps_name != "" ? row.rpps_name : row.label_name
            }</TableCell> */}
            {/* <TableCell align="center" >{row.uf}</TableCell> */}
            {/* <TableCell align="left">{row.cpf_cnpj}</TableCell> */}
            <TableCell align="center" style={{ color: getColorSuitability(row.suitability) }}>{row.suitability ? row.suitability : '-'}</TableCell>
            {/* <TableCell align="center">{timeStampToPtDate(row.portfolio_init)}</TableCell> */}
            <TableCell align="center">{moment.utc(row.portfolio_init).format('MM/YYYY')}</TableCell>
            <TableCell align="center">{row.portfolio_closed ? row.portfolio_closed : '-'}</TableCell>
            <SituationCell align="center" data={row} updateStatus={updateStatusClient} />
        </TableRow>
        // </React.Fragment>
    );
}

export default function ClientList(props) {

    const { selectedYearClosure } = props;


    const currentUser = useSelector(state => state.currentUser)

    const classes = useStyles();

    const activeClients = useSelector(state => state.activeClients)

    const [search, setSearch] = useState("");
    const [clients, setClients] = useState([]);

    const [selectedOrderByPostfolioInit, setSelectedOrderByPostfolioInit] = useState(null);
    const [selectedOrderByPostfolioClosed, setSelectedOrderByPostfolioClosed] = useState(null);
    const [iconOrderPortfolioInit, setIconOrderPortfolioInit] = useState(null);
    const [iconOrder, setIconOrder] = useState(null);

    const LIMIT = 50;
    const handleChange = (value) => {
        setPage(page + value);
    };
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(clients.length);

    const dispatch = useDispatch();
    const history = useHistory();

    const [appearFab, setAppearFab] = useState(true);

    //Acompanhamento de carteiras
    const [launchPortofolioClientsTable, setLaunchPortofolioClientsTable] = useState(null);
    const [dataToChart, setDataToChart] = useState(null);

    //Acompanhamento de metas
    const [infosToClientTargets, setInfosToClientTargets] = useState(null);

    //Acompanhamento de políticas
    const [infosToClientPolicys, setInfosToClientPolicys] = useState(null);

    const [tabValue, setTabValue] = React.useState(0);
    const handleChangeTabValue = (event, newValue) => {
        setTabValue(newValue);

        switch (newValue) {
            case 1:

                history.push(path.administration.clients.closures);
                break;

            default:
                history.push(path.administration.clients.all);
                break;
        }
    };

    const [totalClients, setTotalClients] = React.useState(null);

    //Filtros de gerancial fechamento
    const [activeAdvisors, setActiveAdvisors] = useState(null);
    const [selectedAdvisor, setSelectedAdvisor] = useState(null);
    const handleAdvisorSelectedClient = (event, newValue) => {
        //console.log('NEW VALUE: ', newValue);
        if (newValue) {

            reloadDataManagers(newValue.id);
            //doFormatClientsPorfolioLaunchs(newValue.id)
        } else {
            reloadDataManagers(null);
            //doFormatClientsPorfolioLaunchs(null)
        }
        setSelectedAdvisor(newValue);
    }

    //Menu relatório
    const [anchorEl, setAnchorEl] = useState(null);
    const handleCloseReport = () => setAnchorEl(null);

    const [showSelectDateExcelRelationsModal, setShowSelectDateExcelRelationsModal] = useState(false);

    function reloadDataManagers(advisorId) {

        //advisorId = parseInt(advisorId);
        console.log('tabValue: ', tabValue);

        switch (tabValue) {
            case 0:

                break;
            case 1:
                doFormatClientsPorfolioLaunchs(advisorId)
                break;
            case 2:
                doFormatClientsTargets(advisorId)
                break;
            case 3:
                doFormatClientsPolicys(advisorId)
                break;

            default:
                break;
        }
    }

    async function doGetActiveAdvisorsAndAdmins() {
        //const response = await getActiveAdvisorsAndAdmins();
        const response = await getActiveAdvisorsAndAdminsByConsulting(getConsultingId(currentUser));
        if (response.success) {
            //response.body.rows.unshift(TODOS_ADVISOR)
            setActiveAdvisors(response.body.rows);
        }
    }

    async function updateActiveClients() {

        const response = await listAllActiveClients(getConsultingId(currentUser));

        if (response.success) {
            dispatch(allActions.clientActions.setActiveClients(response.body.rows));
        }

    }

    async function getAllClients(search, orderByDirectionPortfolioInit, orderByDirectionPortfolioClosed) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando clientes...'
        }));

        let response = null;
        let isSearch = !isEmpty(search);
        if (isSearch) {
            setIconOrder(null);
            setIconOrderPortfolioInit(null)
            setSelectedOrderByPostfolioClosed(null);
            setSelectedOrderByPostfolioInit(null);
            //response = await getAllClientsBySearch(search);
            response = await getAllClientsConsultingBySearch(search, getConsultingId(currentUser));
        } else {
            const offset = page - 1;

            response = await listAllClientsByConsulting(offset, orderByDirectionPortfolioInit, orderByDirectionPortfolioClosed, getConsultingId(currentUser));

        };

        const clientsFromResponse = response.body.rows;

        if (response.success) {
            const isClientsResponseEmpty = isEmpty(clientsFromResponse);

            if (isClientsResponseEmpty) {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: true, emptyMessage: 'Não existem clientes registrados' }));
            } else {
                dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
            };

            console.log('clientsFromResponse: ', clientsFromResponse);
            setClients(clientsFromResponse);
            if (!isSearch) {
                setCount(response.body.clientsRowCount);
            };
        } else {
            console.error("RESPONSE ERROR: ", response.error)
            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        }
    }

    async function updateStatusClient(e, clientId, status) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Atualizando situação do cliente...'
        }));

        const response = await updateStatus(clientId, status);
        if (response.success) {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: "Situação do cliente atualizada com sucesso"
            }));

            await updateActiveClients();

            await getAllClients(search);

            dispatch(allActions.innerLoadActions.setInnerLoad({ show: false }));
        } else {
            console.error("RESPONSE ERROR: ", response.error)
        }
    }

    function orderByPortfolioClosed(orderBy) {

        if (search === '' || search === undefined) {
            setIconOrderPortfolioInit(null)
            setSelectedOrderByPostfolioInit(null);

            let style = { verticalAlign: 'bottom' };
            if (orderBy === null) {
                orderBy = 'ASC';
                setIconOrder(<ArrowDropUp style={style} />);
            } else if (orderBy === 'ASC') {
                orderBy = 'DESC';
                setIconOrder(<ArrowDropDown style={style} />);
            } else if (orderBy === 'DESC') {
                orderBy = null;
                setIconOrder(null);
            }

            setSelectedOrderByPostfolioClosed(orderBy);

            getAllClients(search, null, orderBy);
        }


    }

    function orderByPortfolioInit(orderBy) {

        if (search === '' || search === undefined) {

            setIconOrder(null);
            setSelectedOrderByPostfolioClosed(null);

            let style = { verticalAlign: 'bottom' };
            if (orderBy === null) {
                orderBy = 'ASC';
                setIconOrderPortfolioInit(<ArrowDropUp style={style} />);
            } else if (orderBy === 'ASC') {
                orderBy = 'DESC';
                setIconOrderPortfolioInit(<ArrowDropDown style={style} />);
            } else if (orderBy === 'DESC') {
                orderBy = null;
                setIconOrderPortfolioInit(null);
            }

            setSelectedOrderByPostfolioInit(orderBy);

            getAllClients(search, orderBy, null);
        }


    }

    async function doFormatClientsPorfolioLaunchs(advisorId) {
        //const response = await formatClientsPorfolioLaunchs('DESC', advisorId);
        const response = await formatClientsPorfolioLaunchs('DESC', advisorId, getConsultingId(currentUser));
        if (response) {
            console.log("RESPONSE TABLE: ", response.table);
            setTotalClients(response.totalClients);
            setLaunchPortofolioClientsTable(response.table);
            setDataToChart(response.chart);
        } else {
            setTotalClients(null);
            setLaunchPortofolioClientsTable(null);
            setDataToChart(null);
        }

    }

    //Verifica se o cliente precisa de demanda para esse ano e retorna o elemento para preencher a célula
    function verifyDemandAndFilledYear(client, year) {

        if (year >= client.initYearTarget
            && year <= client.endYearTarget) {

            //Cliente tem que preencher a meta

            //Verificar se meta foi preenchida
            if (
                year >= client.initYearTarget
                && year <= client.endYearTarget
                && (client.registredTargets && client.registredTargets.includes(year))
            ) {

                return <Typography className={classes.greenIcon}>
                    <CheckCircleIcon />
                </Typography>

            } else {
                return <Typography className={classes.redIcon}>
                    <OffIcon />
                </Typography>
            }

        } else {
            return "-";
        }
    }

    async function doFormatClientsTargets(advisorId) {
        setLaunchPortofolioClientsTable(null);
        setInfosToClientTargets(null);
        const response = await formatClientsTargets('DESC', advisorId, getConsultingId(currentUser));
        if (response) {
            setInfosToClientTargets(response);
        }

    }

    async function doFormatClientsPolicys(advisorId) {
        const response = await formatClientsPolicys('DESC', advisorId, getConsultingId(currentUser));
        if (response) {
            setInfosToClientPolicys(response);
        }

    }

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    function downloadBlob(blob, name = 'file.txt') {
        if (
            window.navigator &&
            window.navigator.msSaveOrOpenBlob
        ) return window.navigator.msSaveOrOpenBlob(blob);

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = data;
        link.download = name;

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

    function doExcelTable(titleFile, sheetName, data, columns) {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Gerando planilha...',
        }));

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Gestores e Subgestores",
            Author: "UNO",
        }

        wb.SheetNames.push(sheetName);

        const ws_data = [];

        ws_data.push(columns)

        ws_data.push.apply(ws_data, data);

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets[sheetName] = ws;
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

        downloadBlob(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), titleFile + '.xlsx');

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    async function doExcelClients() {

        const responseManagers = await getAllManagers();

        if (responseManagers.success) {
            console.log("response.body.rows: ", responseManagers.body.rows);

            const dataToShow = [];
            responseManagers.body.rows.forEach(element => {
                dataToShow.push([
                    element.label_name,
                    element.name,
                    element.role,
                    element.cpf,
                    element.email,
                    element.phone_number ? applyMaskPhone(element.phone_number) : '-'
                ]);
            });
            doExcelTable(
                'Usuários UNO ' + moment.utc(new Date()).format('DD/MM/YYYY'),
                'Usuários',
                dataToShow,
                ['CLIENTE', 'NOME', 'PERMISSÃO', 'CPF', 'E-MAIL', 'TELEFONE'],
            )
        }

    }

    async function doExcelRelationships(month, year) {

        handleCloseReport();

        // console.log('doExcelRelationships:');
        // console.log(month);
        // console.log(year);

        const responseRelationsClients = await getAllRelationsClients(month + 1, year);
        // const responseRelationsClients = await getAllRelationsClients(12, 2021);
        // const responseRelationsClients = await getAllRelationsClients(12, 2022);
        console.log('responseRelationsClients: ', responseRelationsClients);

        const dataToShowAdministrators = [];
        const dataToShowManagers = [];
        if (responseRelationsClients.success) {

            const mapRelations = {};
            responseRelationsClients.body.rows.forEach(relation => {

                if (!mapRelations[relation.label_name]) {
                    mapRelations[relation.label_name] = {
                        administrators: [],
                        managers: [],
                    };
                }

                const infos = relation.json_fund_infos ? JSON.parse(relation.json_fund_infos) : null;
                if (infos) {

                    const administratorName = infos['dados_cadastrais'].administrator_name;

                    if (!mapRelations[relation.label_name].administrators.includes(administratorName)) {
                        mapRelations[relation.label_name].administrators.push(administratorName)
                    }

                    const managerName = infos['dados_cadastrais'].manager_name;

                    if (!mapRelations[relation.label_name].managers.includes(managerName)) {
                        mapRelations[relation.label_name].managers.push(managerName)
                    }

                }

            });

            console.log('mapRelations: ', mapRelations);
            Object.entries(mapRelations).map(([key, clientRelations]) => {

                clientRelations.administrators.forEach(administrator => {
                    dataToShowAdministrators.push([
                        key,
                        administrator
                    ]);
                });

                clientRelations.managers.forEach(manager => {
                    dataToShowManagers.push([
                        key,
                        manager
                    ]);
                });

            })
        }

        console.log(dataToShowAdministrators);

        doExcelTable(
            'Relacionamentos Administradores',
            'Administradores',
            dataToShowAdministrators,
            ['CLIENTE', 'ADMINISTRADOR'],
        )

        console.log(dataToShowManagers);

        doExcelTable(
            'Relacionamentos Gestores',
            'Gestores',
            dataToShowManagers,
            ['CLIENTE', 'GESTOR'],
        )

    }

    function handleAction(action) {
        setShowSelectDateExcelRelationsModal(false);
        if (action) {
            const date = moment.utc(action);
            doExcelRelationships(action.month(), action.year());
        }
    }


    useEffect(() => {
        getAllClients(search, selectedOrderByPostfolioInit, selectedOrderByPostfolioClosed);
        //doGetActiveAdvisors();

        if (currentUser && currentUser.user &&
            (currentUser.user.role == roles.admin.title
                || currentUser.user.role == roles.superadmin.title)) {

            doGetActiveAdvisorsAndAdmins();

        } else {

            setSelectedAdvisor(currentUser.user);

        }


    }, [search, page]);


    return (
        <>

            <div className={classes.tabsWithRight}>
                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    <MyTabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTabValue}>

                        <MyTab label="Clientes" />
                        <MyTab label={"Fechamentos"} onClick={() => !launchPortofolioClientsTable ? doFormatClientsPorfolioLaunchs(selectedAdvisor ? selectedAdvisor.id : null) : null} />
                        {/* <MyTab label={"Fechamentos"} /> */}
                        <MyTab label={"Metas"} onClick={() => !infosToClientTargets ? doFormatClientsTargets(selectedAdvisor ? selectedAdvisor.id : null) : null} />
                        <MyTab label={"Políticas"} onClick={() => !infosToClientPolicys ? doFormatClientsPolicys(selectedAdvisor ? selectedAdvisor.id : null) : null} />

                    </MyTabs>


                </div>

                {
                    tabValue === 0 ?
                        <div className={classes.rightHeader}>

                            <SearchBar
                                name='search_query'
                                mask='text_cnpj'
                                size='large'
                                spacingRight
                                variant='outlined'
                                label='Nome ou CNPJ'
                                setSearchQuery={setSearch}
                            />

                            {
                                getConsultingId(currentUser) == 1 ?
                                    null
                                    :
                                    <UnoButton
                                        variant="contained"
                                        type='primary'
                                        onClick={() => history.push(path.administration.client.details.newClient)}
                                        startIcon={<AddIcon />}>
                                        {"NOVO CLIENTE"}
                                    </UnoButton>
                            }



                            <UnoButton
                                style={{ marginLeft: '16px' }}
                                size={'small'}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                //onClick={() => doExcelClients()}
                                type='success'
                                startIcon={<PrintIcon />}>
                                {"Relatórios"}
                                {/* {"Gestores e Subgestores"} */}
                            </UnoButton>

                            <ReportMenu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseReport}>
                                <MenuItem onClick={() => doExcelClients()}>
                                    <ListItemIcon>
                                        <PrintIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Gestores e Subgestores" />
                                </MenuItem>
                                {/* <MenuItem onClick={() => doExcelRelationships()}> */}
                                <MenuItem onClick={() => setShowSelectDateExcelRelationsModal(true)}>
                                    <ListItemIcon>
                                        <PrintIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Relacionamentos" />
                                </MenuItem>
                            </ReportMenu>

                        </div>
                        : <>
                            {
                                tabValue === 1
                                    || tabValue === 2
                                    || tabValue === 3
                                    && (currentUser && currentUser.user &&
                                        (currentUser.user.role == roles.admin.title
                                            || currentUser.user.role == roles.superadmin.title)) ? //exibido apenas para o super admin
                                    <div className={classes.rightHeader}>

                                        {
                                            activeAdvisors ?
                                                <MyAutoComplete
                                                    options={activeAdvisors}
                                                    autoComplete
                                                    customWidth={'420px'}
                                                    value={
                                                        selectedAdvisor ? selectedAdvisor : null
                                                    }
                                                    onChange={(event, newValue) => {
                                                        console.log("New value: ", newValue)
                                                        handleAdvisorSelectedClient(event, newValue)
                                                    }}
                                                    label={'Consultor'}
                                                    placeholder={"Selecione o consultor"}
                                                    getOptionLabel={(option) => option.name.toUpperCase()}
                                                    getOptionSelected={(option, value) => option.id === value.id}
                                                    renderOption={(option) => (
                                                        <React.Fragment key={'autocomplete-' + option.id}>
                                                            <span>{option.name.toUpperCase()}</span>
                                                        </React.Fragment>
                                                    )}
                                                /> : <Typography variant='body1' style={{ whiteSpace: 'nowrap', marginRight: '16px' }}>{selectedAdvisor && selectedAdvisor.name.toUpperCase()}</Typography>
                                        }

                                    </div> : null
                            }
                        </>
                }



            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                {
                    tabValue == 0 ?
                        <MyTable
                            pagination={isEmpty(search) && clients && clients.length > 0}
                            itemspage={LIMIT}
                            totalitems={count}
                            handlePage={handleChange}
                            onScroll={(value) => setAppearFab(value)}
                            stickyHeader
                            size="small"
                            selectable='true'>
                            <colgroup>
                                <col width="5%" />
                                <col width="35%" />
                                {/* <col width="7%" /> */}
                                <col width="20%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="10%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ID</TableCell>
                                    <TableCell align="left">RPPS</TableCell>
                                    {/* <TableCell align="center">UF</TableCell> */}
                                    {/* <TableCell align="left">CNPJ</TableCell> */}
                                    <TableCell align="center">SUITABILITY</TableCell>
                                    <TableCell align="center" onClick={() => orderByPortfolioInit(selectedOrderByPostfolioInit)}>Início carteira {iconOrderPortfolioInit}</TableCell>
                                    <TableCell align="center" onClick={() => orderByPortfolioClosed(selectedOrderByPostfolioClosed)}>
                                        Fim carteira {iconOrder}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* Situação */}
                                        <MyTooltip
                                            className={classes.myTooltip}
                                            iconSize='small'
                                            tooltipTitle='Indica se o cliente está ativo ou não.'
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clients.map((row) => (
                                    <RowClient
                                        key={'row-client-' + row.id}
                                        row={row}
                                        goToDetailClient={(row) => history.push(
                                            {
                                                pathname: path.administration.client.details.newClient,
                                                state: { client: row }
                                            }
                                        )}
                                        updateStatusClient={(e, id, active) => updateStatusClient(e, id, active)} />
                                ))}
                            </TableBody>
                        </MyTable>
                        :
                        <>

                            {/* FECHAMENTOS */}
                            {
                                tabValue == 1 ?


                                    <>
                                        <div className={classes.divTable}>
                                            <MyTable
                                                stickyHeader
                                                size="small"
                                                selectable='true'>
                                                <colgroup>
                                                    <col width="50%" />
                                                    <col width="25%" />
                                                    <col width="25%" />
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Lançamento</TableCell>
                                                        <TableCell align="center">Qtd</TableCell>
                                                        <TableCell align="center">%</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {launchPortofolioClientsTable && launchPortofolioClientsTable.map((row, index) => (
                                                        <TableRow
                                                            onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                                                path: 'clientsFromTable',
                                                                data: {
                                                                    label: row.launch,
                                                                    qtd: row.qtd,
                                                                    clients: row.clients
                                                                },
                                                            }))}
                                                            key={'portfolio-launch-client-' + index}>
                                                            <TableCell align="left" >{formatPortfolioClosed(row.launch)}</TableCell>
                                                            <TableCell align="center" >{row.qtd}</TableCell>
                                                            <TableCell align="center" >{row.percent}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                {
                                                    totalClients ?
                                                        <TableFooter className={classes.myTableFooter}>

                                                            <TableRow className='totalLinePL' key={'footer-fechamentos-total'}>
                                                                <TableCell align="left">Total clientes</TableCell>
                                                                <TableCell align="center">{totalClients}</TableCell>
                                                                <TableCell align="center">100%</TableCell>
                                                            </TableRow>
                                                        </TableFooter> : null
                                                }

                                            </MyTable>
                                        </div>


                                        <div className={classes.divChart}>
                                            <Pie data={dataToChart}
                                                options={optionsChart}
                                                redraw={true}
                                            />
                                        </div>
                                    </>

                                    // <Switch className={classes.switch}>
                                    //     <Route path={path.administration.clients.closures}>
                                    //         <ClosuresScreen
                                    //             selectedYearClosure={selectedYearClosure}
                                    //             advisorId={selectedAdvisor && selectedAdvisor.id}
                                    //             launchPortofolioClientsTable={launchPortofolioClientsTable}
                                    //             totalClients={totalClients} />
                                    //     </Route>
                                    // </Switch>
                                    :
                                    <>

                                        {
                                            tabValue == 2 ?

                                                <>
                                                    <div className={classes.divTableTargets}>
                                                        <MyTable
                                                            stickyHeader
                                                            size="small"
                                                            selectable='true'>


                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">Ano</TableCell>
                                                                    <TableCell align="center">Demanda</TableCell>
                                                                    <TableCell align="center">Regist.</TableCell>
                                                                    <TableCell align="center">%</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {infosToClientTargets && infosToClientTargets.table.map((row, index) => (
                                                                    <TableRow
                                                                        key={'targets-clients-' + index}>
                                                                        <TableCell align="left" >{row.year}</TableCell>
                                                                        <TableCell align="center" >{row.demandInYear.length}</TableCell>
                                                                        <TableCell align="center" >{row.registredInYear.length}</TableCell>
                                                                        <TableCell align="center" >{applyMaskAmount(row.registredInYear.length / row.demandInYear.length * 100) + "%"}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </MyTable>
                                                    </div>


                                                    <div className={classes.divChartTargets}>
                                                        <MyTable
                                                            stickyHeader
                                                            size="small"
                                                            selectable='true'>
                                                            <colgroup>
                                                                <col width="30%" />
                                                            </colgroup>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">Cliente</TableCell>
                                                                    {infosToClientTargets && infosToClientTargets.yearsToTable.map((year, indexYear) => (

                                                                        <TableCell align="center" >{year}</TableCell>

                                                                    ))}

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {infosToClientTargets && infosToClientTargets.allClients.map((client, index) => (
                                                                    <TableRow
                                                                        key={'targets-clients-detail' + index}>
                                                                        <TableCell align="left" >{client.label_name}</TableCell>

                                                                        {infosToClientTargets && infosToClientTargets.yearsToTable.map((year, indexYear) => (

                                                                            <TableCell align="center" >{verifyDemandAndFilledYear(client, year)}</TableCell>

                                                                        ))}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </MyTable>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className={classes.divTableTargets}>
                                                        <MyTable
                                                            stickyHeader
                                                            size="small"
                                                            selectable='true'>


                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">Ano</TableCell>
                                                                    <TableCell align="center">Demanda</TableCell>
                                                                    <TableCell align="center">Regist.</TableCell>
                                                                    <TableCell align="center">%</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {infosToClientPolicys && infosToClientPolicys.table.map((row, index) => (
                                                                    <TableRow
                                                                        key={'targets-clients-' + index}>
                                                                        <TableCell align="left" >{row.year}</TableCell>
                                                                        <TableCell align="center" >{row.demandInYear.length}</TableCell>
                                                                        <TableCell align="center" >{row.registredInYear.length}</TableCell>
                                                                        <TableCell align="center" >{applyMaskAmount(row.registredInYear.length / row.demandInYear.length * 100) + "%"}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </MyTable>
                                                    </div>


                                                    <div className={classes.divChartTargets}>
                                                        <MyTable
                                                            stickyHeader
                                                            size="small"
                                                            selectable='true'>
                                                            <colgroup>
                                                                <col width="30%" />
                                                            </colgroup>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left">Cliente</TableCell>
                                                                    {infosToClientPolicys && infosToClientPolicys.yearsToTable.map((year, indexYear) => (

                                                                        <TableCell align="center" >{year}</TableCell>

                                                                    ))}

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {infosToClientPolicys && infosToClientPolicys.allClients.map((client, index) => (
                                                                    <TableRow
                                                                        key={'targets-clients-detail' + index}>
                                                                        <TableCell align="left" >{client.label_name}</TableCell>

                                                                        {infosToClientPolicys && infosToClientPolicys.yearsToTable.map((year, indexYear) => (

                                                                            <TableCell align="center" >{verifyDemandAndFilledYear(client, year)}</TableCell>

                                                                        ))}
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </MyTable>
                                                    </div>
                                                </>
                                        }

                                    </>
                            }

                        </>


                }

            </div>

            <SelectDateModal open={showSelectDateExcelRelationsModal}
                handleAction={handleAction} />

            {/* <Zoom
                in={appearFab}
                unmountOnExit>
                <Fab className={classes.fabAdd}
                    onClick={() => history.push(path.administration.client.details.newClient)}>
                    <AddIcon />
                </Fab>
            </Zoom> */}
        </>
    );
}


