import API from './config'

export async function getFilesByDir(dir) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.post('/server_files/getFilesByDir', { dir: dir }, config)

        return {
            success: true,
            body: response.data
        };

    } catch (error) {

        console.log("ERROR");

        return {
            success: false,
            body: error.response ? error.response.data : 'NO_DATA_ERROR'
        };
    }

}

