import React, { useEffect, useRef } from "react";
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { indigo } from "@material-ui/core/colors";
import VMasker from 'vanilla-masker';
import { Typography } from '@material-ui/core';
import { unoTheme, myInput, myLabelInput } from "../../assets/styles/unoTheme";
import InputBase from '@material-ui/core/InputBase';
import NativeSelect from '@material-ui/core/NativeSelect';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'

    },
    disabled: {
        background: '#eeeeee'
    },
    withRightBtn: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '4px 0px 0px 4px !important'
        },
    },
    center: {

        '& input': {
            textAlign: 'center !important'
        }

    },
    left: {

        '& input': {
            textAlign: 'left !important'
        }

    },
    right: {

        '& input': {
            textAlign: 'right !important'
        }

    },
    label: myLabelInput,
    marginTop: {
        marginTop: '5px'
    },
    hiddenOption: {
        display: 'none',
    },

    errorSelect: {
        "& .MuiNativeSelect-root": {
            border: 'solid 1px red',
        },

        '& .Mui-error': {
            marginLeft: '0px',
            fontSize: '0.75rem',
            marginTop: '3px',
            textAlign: 'left',
            fontWeight: '400',
            lineHeight: '1.66',
            color: '#f44336',
        },

        '& .MuiTypography-root': {
            color: 'red',
        }

    },

    rawSelect: {

        '& .MuiNativeSelect-select.MuiNativeSelect-select': {
            paddingLeft: '5px',
            height: '16px',
            background: 'none',
            lineHeight: '15px',
            //border: 'none',
            boxShadow: 'none !important'
        }
    }


}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        height: unoTheme.heightInput,
        borderRadius: 6,
        position: 'relative',
        backgroundColor: unoTheme.defaultInputBackground,
        border: '1px solid #ced4da',
        fontSize: 16,
        lineHeight: '22px',
        padding: '6px 26px 6px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const BootstrapVerySmallInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        //width: '70px',
        borderRadius: 6,
        position: 'relative',
        backgroundColor: unoTheme.defaultInputBackground,
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '0px 26px 0px 12px',
        lineHeight: '18px',
        height: '26.250px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function MySelect(props) {

    const {
        outerlabel,
        options,
        verysmall,
        initblank,
        helperText,
        validator,
        rawSelect,
        customWidth
    } = props

    const classes = useStyles();
    const inputRef = useRef();

    function renderOptions() {

        //console.log("RENDER OPTIONS")
        options.unshift(<option key={'hidden-opt'} className={classes.hiddenOption} selected></option>)
        return (
            options
        )
    }

    return (
        <div className={[classes.root, validator ? classes.errorSelect : ""].join(' ')} style={{ width: customWidth ? customWidth : "auto" }}>

            {
                outerlabel && outerlabel != "" ?
                    <Typography
                        className={classes.label}>
                        {outerlabel}
                    </Typography> : null
            }

            <NativeSelect
                ref={inputRef}
                className={[
                    outerlabel != undefined
                        && outerlabel != "" ? classes.marginTop : "",
                    rawSelect ? classes.rawSelect : ''].join(' ')}
                {...props}
                input={verysmall ? <BootstrapVerySmallInput /> : <BootstrapInput />}>
                {initblank ? renderOptions() : options}
            </NativeSelect>

            {
                validator ?
                    <p class="Mui-error">{helperText}</p>
                    : null
            }


        </div>


    )
}