import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

//Api
import { getResolutionVersions, listLegislationSegments, listLegislationArticles, listLegislationSegmentsByVersionId } from '../../../API/legislation_article'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'

//UnoButton
import UnoButton from "../../utils/UnoButton";

import MySelect from "../../utils/MySelect";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            color: 'white',
            background: unoTheme.mainColor,
            '& > *': {
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white',
                }

            }
        }
    },
    overflow: {
        overflowY: 'scroll',
    },

    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        padding: '0px !important',
    },

    sectionTableCellData: {
        display: 'table-cell',
        padding: '0px !important',
    },

    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '25%',
        },
        '& .blue': {
            color: 'blue',
        },
    },

}));

export default function AdminLegislationSegmentsScreen() {

    const classes = useStyles();

    const dispatch = useDispatch();

    const rightContent = useSelector(state => state.rightContent)

    const [resolutionsVersions, setResolutionsVersions] = useState([]);
    const [resolutionVersionSelected, setResolutionVersionSelected] = useState(null);
    const [legislationSegments, setListLegislationSegments] = useState([]);

    async function getLegislation() {

        let responseResolutionsVersions = await getResolutionVersions();

        if (responseResolutionsVersions.success
            && responseResolutionsVersions.body
            && responseResolutionsVersions.body.rows
            && responseResolutionsVersions.body.rows.length > 0) {

            setResolutionsVersions(responseResolutionsVersions.body.rows);
            setResolutionVersionSelected(0);

            loadSegments(responseResolutionsVersions.body.rows[0].id);

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar versões de resolução'
            }))
        }

    }

    async function loadSegments(resolutionVersionId) {

        let responseLegislationSegments = await listLegislationSegmentsByVersionId(resolutionVersionId);

        if (responseLegislationSegments.success) {

            console.log("SEGMENTS: ", responseLegislationSegments.body.rows);
            setListLegislationSegments(responseLegislationSegments.body.rows);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os segmentos de legislação'
            }))

        }
    }

    const handleChangeResoltuoinVersionSelected = (value) => {

        //console.log("YEAR: ", yearChartSelected);
        setResolutionVersionSelected(value);
        loadSegments(resolutionsVersions[value].id);

    };

    useEffect(() => {


        if (legislationSegments.length == 0) {
            getLegislation();
        }

        //No caso de ser uma atualização do rightContent limpa-se o rightContent
        if (rightContent && rightContent.action) {

            console.log("ENTROU RIGHT CONTENT");
            loadSegments(resolutionsVersions[resolutionVersionSelected].id);
            dispatch(allActions.rightContentActions.setRightContent(null))

        }

    }, [rightContent]);

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <div className='groupHorizontalFlex'>
                    <Typography variant="h6" gutterBottom align="left">
                        {'Segmentos de resolução'}
                    </Typography>

                    <MySelect
                        style={{ marginLeft: '16px' }}
                        value={resolutionVersionSelected}
                        verysmall
                        onChange={(e) => handleChangeResoltuoinVersionSelected(e.target.value)}
                        options={resolutionsVersions.map((row, index) => (

                            <option key={'resolution-version-' + index} value={index}>{row.resolution_name}</option>

                        ))}
                    />

                    <div style={{ marginLeft: '16px' }}>

                        <span>{resolutionsVersions[resolutionVersionSelected] ? resolutionsVersions[resolutionVersionSelected].description : ''}</span>

                    </div>



                </div>

            </div>

            <div class={'defaultScrollWithHeaderContent'}>
                <MyTable stickyHeader size="small"
                    selectable="true">
                    {/* selectable="true"> */}
                    <colgroup>
                        <col width="25%" />
                        <col width="15%" />
                        <col width="60%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell align="center">
                                {'ID'}
                            </TableCell> */}
                            <TableCell align="left">
                                {'Descrição'}
                            </TableCell>
                            <TableCell align="center">
                                {'Limite'}
                            </TableCell>
                            <TableCell className={classes.sectionTableCell}
                                align="center">
                                <div>{'Limites de Pró-Gestão'}</div>
                                <div className={classes.multipleHorizontalTableCell}>
                                    <div>{'PG I'}</div>
                                    <div>{'PG II'}</div>
                                    <div>{'PG III'}</div>
                                    <div>{'PG IV'}</div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {legislationSegments.map((row) => (
                            <TableRow key={'segment-' + row.id}
                                onClick={() => dispatch(allActions.rightContentActions.setRightContent({
                                    path: 'detailAdminLegislationSegment',
                                    data: row
                                }))}>
                                {/* <TableCell align="center">
                                    {row.id}
                                </TableCell> */}
                                <TableCell align="left">
                                    {row.description}
                                </TableCell>
                                <TableCell align="center">
                                    {row.limit_segment}%
                                </TableCell>
                                <TableCell className={classes.sectionTableCellData} align="center">
                                    {

                                        <div className={classes.multipleHorizontalTableCell}>
                                            <div className={'blue'}>{row.limit_pg1 ? row.limit_pg1 + '%' : '-'}</div>
                                            <div className={'blue'}>{row.limit_pg2 ? row.limit_pg2 + '%' : '-'}</div>
                                            <div className={'blue'}>{row.limit_pg3 ? row.limit_pg3 + '%' : '-'}</div>
                                            <div className={'blue'}>{row.limit_pg4 ? row.limit_pg4 + '%' : '-'}</div>
                                        </div>

                                    }

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </MyTable>
            </div>

        </div >
    );
}



