import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import allActions from '../../../actions';
import { unoTheme, useColors } from '../../../assets/styles/unoTheme';
import MyTable from '../../utils/MyTable';
import { applyMaskAmount, applyMaskAccount, getTitleCod, formatPtStringDate } from '../../utils/utils';
import { checkFinalLabelDistribuitionTable } from '../../utils/distribution';
import moment from 'moment';

const oldDiffColor = "#ffebe6";
const newDiffColor = "#e3fcef";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '& .MuiTableContainer-root': {
      height: 'calc(100vh - 103px) !important',
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    backgroundColor: unoTheme.hoverTable,
  },
  leftHeader: {

    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center',
  },
  fundLabel: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#1C2228'
  },
  accountLabel: {
    fontSize: '14px',
    color: unoTheme.secondaryColor,
  },
  spacingLeft2: {
    marginLeft: theme.spacing(2)
  },
  green: {
    color: unoTheme.greenMoney,
  },
}));


export default function ClientUpdates({ content }) {
  const dispatch = useDispatch();

  const {
    data: { client_id, client_label: clientLabel, old_info_json, new_info_json, created_date, username }
  } = content;

  const classes = useStyles();
  const colors = useColors();

  const [changesAttrs, setChangesAttrs] = useState([]);
  const [oldInfo, setOldInfo] = useState();
  const [newInfo, setNewInfo] = useState();

  const renderDiffCell = (attr, type) => {

    return (
      <TableCell align="left" style={{ background: type === "old" ? oldDiffColor : newDiffColor }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

          <div style={{ fontWeight: 'bold' }}>{type === "old" ? "-" : "+"}</div>
          <div>{type === "old" ? oldInfo[attr] : newInfo[attr]}</div>

        </div >
      </TableCell>
    )
  }

  const diffLines = useMemo(() => {
    const lines = [];

    if (changesAttrs.length && oldInfo && newInfo && Object.keys(oldInfo).length && Object.keys(newInfo).length) {
      changesAttrs.forEach((attr, index) => {
        lines.push(
          <TableRow>
            <TableCell style={{ background: 'white' }}><span style={{ fontWeight: 'bold' }}>{attr}</span></TableCell>
            <TableCell style={{ background: 'white' }}></TableCell>
          </TableRow>
        )
        lines.push(<TableRow key={'changesAttrs-' + index} style={{ marginBottom: "8px" }}>
          {renderDiffCell(attr, 'old')}
          {renderDiffCell(attr, 'new')}
        </TableRow>)
        lines.push(
          <TableRow>
            <TableCell style={{ background: 'white' }}></TableCell>
            <TableCell style={{ background: 'white' }}></TableCell>
          </TableRow>
        )
      });
    }
    return lines;
  }, [oldInfo, newInfo])


  useEffect(() => {

    const oldInfoParsed = JSON.parse(old_info_json);
    setChangesAttrs(Object.keys(oldInfoParsed));
    setOldInfo(oldInfoParsed);
    setNewInfo(JSON.parse(new_info_json));

  }, [client_id])

  return (
    <div className={classes.root}>

      <Paper style={{ marginBottom: '4px' }}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div>
              <Typography
                align={'left'}
                className={classes.fundLabel}>
                Alterações
              </Typography>
              <Typography
                align={'left'}
                className={classes.accountLabel}>

                <span><span style={{ color: unoTheme.mainColor }}>{clientLabel}</span></span>
                <span style={{ marginLeft: '8px' }}><span className={colors.green}>{moment.utc(created_date).format('DD/MM/YYYY - HH:mm:ss')}</span></span>
                <span style={{ marginLeft: '8px' }}>{"-"}</span>
                <span style={{ marginLeft: '8px' }}>{username}</span>

              </Typography>
            </div>
          </div>

          <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
            <CloseIcon />
          </IconButton>

        </div>
      </Paper>
      <MyTable
        stickyHeader
        nofixed={true}
        size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Info. antiga</TableCell>
            <TableCell align="left">Info. atual</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffLines}
        </TableBody>
      </MyTable>
    </div>
  )
}
