import React, { useEffect } from 'react';
import MyInfo from "../utils/MyInfo";
import { applyMaskAmount } from "../utils/utils";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { unoTheme } from '../../assets/styles/unoTheme';

const useStyles = makeStyles((theme) => ({

    root: {
        padding: '24px'
    },

    secondaryBackgroundPaper: {
        padding: '24px',
        backgroundColor: unoTheme.hoverTable,
        borderRadius: '10px',
        height: "100%",
    }

}));

export default function ComdinheiroInfosFund(props) {

    const classes = useStyles();

    const { fund } = props;

    useEffect(() => {
        console.log("FUND IN ComdinheiroInfosFund: ", fund);
    })


    return (
        <>
            {
                fund && fund['dados_cadastrais'] ?

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className={classes.secondaryBackgroundPaper}>
                                <MyInfo
                                    outerlabel={'CNPJ:'}
                                    direction={'horizontal'}
                                    label={fund.cnpj} >
                                </MyInfo >
                                <MyInfo outerlabel={'CNPJ Gestor:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].manager_cnpj}>
                                </MyInfo>
                                <MyInfo outerlabel={'Gestão:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].manager_name}>
                                </MyInfo>
                                <MyInfo outerlabel={'CNPJ Administrador:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].administrator_cnpj}>
                                </MyInfo>
                                <MyInfo outerlabel={'Administrador:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].administrator_name}>
                                </MyInfo>
                                <MyInfo outerlabel={'Taxa de Administração:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].administration_tax + "%"}>
                                </MyInfo>
                                <MyInfo outerlabel={'Taxa de administração (Máxima):'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].max_administration_tax ?
                                        fund['dados_cadastrais'].max_administration_tax + "%" : "-"}>
                                </MyInfo>
                                <MyInfo outerlabel={'Benchmark:'}
                                    direction={'horizontal'}
                                    label={fund['benchmark'] ? fund['benchmark'] : (fund['benchmark_anbima'] ? fund['benchmark_anbima'] : '-')}>
                                </MyInfo>
                                <MyInfo outerlabel={'Início fundo:'}
                                    direction={'horizontal'}
                                    label={fund['dados_cadastrais'].fund_init}>
                                </MyInfo>
                            </div>


                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <div className={classes.secondaryBackgroundPaper}>
                                <MyInfo outerlabel={'Patrimônio líquido:'}
                                    direction={'horizontal'}
                                    label={applyMaskAmount(fund.dados_cadastrais.pl_anbima, true)}>
                                </MyInfo>
                                <MyInfo outerlabel={'Aplicação mínima:'}
                                    direction={'horizontal'}
                                    label={applyMaskAmount(fund.dados_cadastrais.min_application, true)}>
                                </MyInfo>
                                <MyInfo outerlabel={'Aplicação adicional:'}
                                    direction={'horizontal'}
                                    label={applyMaskAmount(fund.dados_cadastrais.aditional_application, true)}>
                                </MyInfo>
                                <MyInfo outerlabel={'Saldo mínimo:'}
                                    direction={'horizontal'}
                                    label={applyMaskAmount(fund.dados_cadastrais.min_balance, true)}>
                                </MyInfo>
                                <MyInfo outerlabel={'Resgate Mínimo:'}
                                    direction={'horizontal'}
                                    label={applyMaskAmount(fund.dados_cadastrais.min_rescue, true)}>
                                </MyInfo>
                                <MyInfo outerlabel={'Conversão de cota para Aplicação:'}
                                    direction={'horizontal'}
                                    label={fund.dados_cadastrais.conversion_application_quota}>
                                </MyInfo>
                                <MyInfo outerlabel={'Conversão de cota para Resgate:'}
                                    direction={'horizontal'}
                                    label={fund.dados_cadastrais.conversion_rescue_quota}>
                                </MyInfo>
                                <MyInfo outerlabel={'Disponibilização dos Recursos Resgatados:'}
                                    direction={'horizontal'}
                                    label={fund.dados_cadastrais.disponibilization}>
                                </MyInfo>
                            </div>

                        </Grid>
                    </Grid>
                    : null
            }
        </>

    )

}