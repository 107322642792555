import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import { SheetJSFT } from './types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';

import { getJsDateFromExcel } from 'excel-date-to-js'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    topImportExcel: {
        display: 'flex',
    }
}));

export default function ExcelReader() {

    const classes = useStyles();

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         file: {},
    //         data: [],
    //         cols: []
    //     }
    //     this.handleFile = this.handleFile.bind(this);
    //     this.handleChange = this.handleChange.bind(this);
    // }

    const [file, setFile] = useState({});
    const [data, setData] = useState([]);
    const [dataMY, setDataMY] = useState({});
    //const [dataMY, setDataMY] = useState([]);
    const [cols, setCols] = useState([]);
    const [tablesMY, setTableMY] = useState([]);
    const [headers, setHeaders] = useState([]);

    function handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) {
            setFile(files[0])
        }
    };

    useEffect(() => {

        if (data && data.length > 0) {

            //console.log(JSON.stringify(data, null, 2));

            console.log("DATA: ", data)

            let dataMonthFormatted = {};
            for (let i = 0; i < data.length; i++) {

                if (data[i].DataOperacao) {

                    data[i].dataFormatada = moment.utc(getJsDateFromExcel(data[i].DataOperacao)).add(1, 'days').format('DD/MM/YYYY');

                    let periodMonthYear = data[i].dataFormatada.substring(3);
                    //console.log("PERIOD: ", periodMonthYear)
                    if (!dataMonthFormatted[periodMonthYear]) {
                        dataMonthFormatted[periodMonthYear] = [];
                    }

                    delete data[i].DataOperacao

                    dataMonthFormatted[periodMonthYear].push(data[i]);

                    if (headers.length == 0) {
                        let preEntries = Object.entries(data[i]);
                        let entries = [];
                        for (let j = 0; j < preEntries.length; j++) {
                            entries.push(preEntries[j][0]);
                        }
                        setHeaders(entries);
                    }
                }
            }

            //console.log("MOVIMENTACOES POR MES: ", dataMonthFormatted)
            if (Object.entries(dataMY).length == 0) {
                setDataMY(dataMonthFormatted);
            }


            //oldHeaders
            // if (headers.length == 0) {
            //     let preEntries = Object.entries(data[0]);
            //     let entries = [];
            //     for (let i = 0; i < preEntries.length; i++) {
            //         entries.push(preEntries[i][0]);
            //     }
            //     setHeaders(entries);
            // }                        


        } else if (data.length == 0 && headers.length != 0) {
            setHeaders([])
        }

        console.log("USE EFFECT");

    }, [data, headers, dataMY])

    function renderTables(dataMonthFormatted) {

        let tables = [];
        Object.entries(dataMonthFormatted).forEach(([key, monthYear]) => {
            tables.push(
                <>
                    <br />
                    <Typography align={'left'} variant={'h6'}>
                        Lançamentos de {key}
                    </Typography>
                    <Divider />
                    <br />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    {
                                        headers && headers.length > 0 ?

                                            headers.map((item) => (
                                                <TableCell align={"center"}>{item}</TableCell>
                                            ))

                                            : null
                                    }
                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {monthYear.map((row, index) => (
                                    <TableRow key={row[headers[0] + '-' + index]}>
                                        {
                                            headers && headers.length > 0 ?

                                                headers.map((item, index) => (
                                                    <TableCell align={"center"}>
                                                        {row[headers[index]]}
                                                    </TableCell>
                                                ))

                                                : null
                                        }

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
        })

        return tables;
    }

    function handleFile() {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws);
            /* Update state */
            setData([])
            setData(data);
            setCols(make_cols(ws['!ref']));
            // this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
            //     console.log(JSON.stringify(this.state.data, null, 2));
            // });

        };

        if (rABS) {
            //reader.readAsBinaryString(this.state.file);
            reader.readAsBinaryString(file);
        } else {
            //reader.readAsArrayBuffer(this.state.file);
            reader.readAsArrayBuffer(file);
        };
    }

    return (
        <div>
            <div className={classes.topImportExcel}>
                <div>
                    <input
                        className={classes.input}
                        id="file"
                        multiple
                        accept={SheetJSFT}
                        type="file"
                        onChange={handleChange}
                    />
                    <label htmlFor="file">
                        <Button variant="contained" color="primary" component="span">
                            Upload
                        </Button>
                    </label>
                </div>

                <Button variant="contained"
                    color="secondary"
                    type='submit'
                    onClick={handleFile}>
                    Processar
                </Button>
            </div>

            <br />

            {/* <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {
                                headers && headers.length > 0 ?

                                    headers.map((item) => (
                                        <TableCell align={"center"}>{item}</TableCell>
                                    ))

                                    : null
                            }
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row[headers[0]]}>
                                {
                                    headers && headers.length > 0 ?

                                        headers.map((item, index) => (
                                            <TableCell align={"center"}>
                                                {row[headers[index]]}
                                            </TableCell>
                                        ))

                                        : null
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}

            <br />
            <Divider />

            {renderTables(dataMY)}


        </div>

    )
}