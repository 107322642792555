import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyButton from '../../utils/MyButton'
import MyTextField from '../../utils/MyTextField'
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Tooltip from '@material-ui/core/Tooltip';

//Accordion
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//API
import { listAprDescriptions, getAprDescriptionById, saveNewAprDescription, getClientAprDataById, getClientAprDataByReferenceDate } from '../../../API/client'
import { updateApr, createClientAprData, cleanAPRtransaction, updateSingleApr, deleteSingleTransaction, cleanSingleAPRtransaction, getTransactionsByDateRangeWithAPR, updateClientAprDataTransactions } from '../../../API/transaction'
import { getAllCertifications } from '../../../API/certification'

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import PrintIcon from '@material-ui/icons/Print';

import { unoTheme } from "../../../assets/styles/unoTheme";
import MySelect from '../../utils/MySelect';
import MyDatePicker from '../../utils/MyDatePicker';
import UnoButton from '../../utils/UnoButton';

import { applyMaskAccount, applyMaskAmount, isValidCpf } from '../../utils/utils';

import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";

import _ from 'lodash';

import RenewIcon from '@material-ui/icons/Cached';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmActionRightContent from './ConfirmActionRightContent'

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { checkUserIsRole, roles } from '../../utils/user';
import UpdateClientAprDataTransactionsModal from '../../modals/UpdateClientAprDataTransactionsModal';
import UpdateNumbersAprsTransactionsModal from '../../modals/UpdateNumbersAprsTransactionsModal';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    root: {
        //height: '100vh',
        width: '100%',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
        backgroundColor: unoTheme.hoverTable,
        minHeight: '118px',
        maxHeight: '118px',
        height: '118px',
    },

    leftHeader: {

        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
    },
    labelYear: {
        fontSize: '36px',
        paddingRight: theme.spacing(2),
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    headerDate: {
        display: 'flex',
        alignItems: 'center',
        color: unoTheme.secondaryColor,
        fontSize: '12px',
        '& svg': {
            marginRight: '5px',
        },
        '& > *': {
            fontSize: '14px',
        }
    },

    accountLabel: {
        fontSize: '14px',
        color: unoTheme.secondaryColor,
    },

    fundLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#1C2228'
    },

    operation1: {
        textTransform: 'uppercase',
        color: unoTheme.greenMoney,
    },

    operation2: {
        textTransform: 'uppercase',
        color: unoTheme.redMoney,
    },

    operation3: {
        textTransform: 'uppercase',
        color: 'blue',
    },

    main: {
        overflowY: 'scroll',
        height: 'calc(100vh - 118px)',
        //padding: theme.spacing(3),

        display: 'flex',
        flexDirection: 'column',

        textAlign: 'left',

        // '& .MuiPaper-root': {
        //     height: 'calc(100% - 184px)',
        // }
        '& .subMain': {
            padding: theme.spacing(3),
        },

        '& .MuiAccordion-root': {
            //background: '#fbfdd9',
        },

        '& .MuiAccordionSummary-root': {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },

        '& .MuiAccordionDetails-root': {
            padding: '8px 24px 16px',
        }
    },

    heading: {
        display: 'flex',
        alignItems: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            marginRight: theme.spacing(1),
            color: unoTheme.mainColor,
        }
    },

    labelCellTop: {
        fontSize: '13px',
        fontWeight: '600'
    },
    labelCellBottom: {
        fontSize: '13px',
        fontColor: unoTheme.defaultTextColor
    },

    bottom: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },

    buttonsBottom: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },

    spacingRight2: {
        marginRight: theme.spacing(2),
    },
    formControl: {
        //margin: theme.spacing(1), 
        width: '100%',
        textAlign: 'left'
    },
    dialogTitleApr: {
        '& h2': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between'
        }

    },
    noMarginTop: {
        marginTop: "0px",
    },

    hiddenIcon: {
        visibility: 'hidden',
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },
    validationMessage: {
        color: unoTheme.errorColor,
        fontSize: '14px',
        textAlign: 'right',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }

}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function AprModal(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const clientOn = useSelector(state => state.clientOn)
    const currentUser = useSelector(state => state.currentUser)

    const { content } = props

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [openClientAprData, setOpenClientAprData] = useState(false);

    const [confirmingAction, setConfirmingAction] = useState(false);
    const [confirmingCleanSingleAction, setConfirmingCleanSingleAction] = useState(false);
    const [confirmingDeleteAction, setConfirmingDeleteAction] = useState(false);

    const [hideCpf, setHideCpf] = useState(false);

    //Data apr
    const [numberApr, setNumberApr] = useState('');

    const [clientAprData, setClientAprData] = useState({});

    const [preponentName, setPreponentName] = useState('');
    const [preponentCpf, setPreponentCpf] = useState('');
    const [responsableOperationName, setResponsableOperationName] = useState('');
    const [responsableOperationCpf, setResponsableOperationCpf] = useState('');
    const [managerName, setManagerName] = useState('');
    const [managerCpf, setManagerCpf] = useState('');

    const [certifications, setCertifications] = useState([]);
    const [certificationSelected, setCertificationSelected] = useState(0);
    // The first commit of Material-UI
    const [selectedValidityDate, setSelectedValidityDate] = useState(new Date());
    const handleDateChange = (date) => {
        setSelectedValidityDate(date);
    };

    const [descriptionsApr, setDescriptionsApr] = useState([]);
    const [descriptionAprSelected, setDescriptionAprSelected] = useState([]);
    const [areaAprDescription, setAreaAprDescription] = useState("");

    const [registerDescriptionNewApw, setDescriptionRegisterNewApw] = useState("");

    const [usingHintApr, setUsingHintApr] = useState(false);

    function handleChangeDescriptionAprSelected(event) {
        setDescriptionAprSelected(event.target.value)
        setAreaAprDescription(descriptionsApr[event.target.value].description);
    }

    function handleChangeAreaAprDescription(value) {

        //Ao mudar texto, automaticamente o modo de descrição se transforma em EDITAR DESCRIÇÃO APR
        setDescriptionAprSelected(0);
        setAreaAprDescription(value.substring(0, 1000));

        if (value.length > 1000) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "warning",
                message: "O texto de descrição de APR deve possuir no máximo 1000 caracteres"
            }))
        }

    }

    const [inputTitleNewAprDescription, setInputTitleNewAprDescription] = useState("");
    const [inputAreaNewAprDescription, setInputAreaNewAprDescription] = useState("");

    function handleChangeTitleNewApr(value) {
        setInputTitleNewAprDescription(value)
    }

    function handleChangeAreaNewApr(value) {

        setInputAreaNewAprDescription(value.substring(0, 1000))
        if (value.length > 1000) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: "warning",
                message: "O texto de descrição de APR deve possuir no máximo 1000 caracteres"
            }))
        }
    }

    const [transactionsToUpdateClientAprData, setTransactionsToUpdateClientAprData] = useState();
    const [newClientAprDataId, setNewClientAprDataId] = useState(false);
    const [referenceDateClientAprData, setReferenceDateClientAprData] = useState();

    async function verifyCorrectionClientAprData(clientId, clientAprDataId, transactionDate) {

        if (checkUserIsRole(currentUser, [roles.admin, roles.superadmin, roles.advisor])) {

            console.log('clientAprDataId: ', clientAprDataId);
            console.log('transactionDate: ', transactionDate);
            const response = await getTransactionsByDateRangeWithAPR(clientId, moment.utc(transactionDate).format('DD/MM/YYYY'), '31/12/9999');
            console.log("response transactions UPDATE: ", response);
            if (response.success && response.body && response.body.rows && response.body.rows.length > 0) {
                console.log('verifyCorrectionClientAprData: ', response)
                setTransactionsToUpdateClientAprData(response.body.rows);
                setReferenceDateClientAprData(transactionDate);
                setNewClientAprDataId(clientAprDataId)
            } else {
                dispatch(allActions.rightContentActions.update())
            }
        } else {
            dispatch(allActions.rightContentActions.update())
        }

    }

    const [transactionsToUpdateNumbersApr, setTransactionsToUpdateNumbersApr] = useState();
    const [newNumberApr, setNewNumberApr] = useState(false);
    const [referenceDateNumberApr, setReferenceDateNumberApr] = useState();

    async function verifyCorrectionNumbersAprs(clientId, numberApr, transactionDate) {

        if (checkUserIsRole(currentUser, [roles.admin, roles.superadmin])) {

            const yearApr = numberApr.split('/')[1];
            const response = await getTransactionsByDateRangeWithAPR(clientId, moment.utc(transactionDate).format('DD/MM/YYYY'), '31/12/' + yearApr);
            console.log("response transactions UPDATE: ", response);
            if (response.success && response.body && response.body.rows && response.body.rows.length > 0) {
                console.log('verifyCorrectionClientAprData: ', response)
                setTransactionsToUpdateNumbersApr(response.body.rows);
                setReferenceDateNumberApr(transactionDate);
                setNewNumberApr(numberApr)
            } else {
                dispatch(allActions.rightContentActions.update())
            }
        } else {
            dispatch(allActions.rightContentActions.update())
        }

    }

    async function handleUpdateTransactionsClientAprData() {

        setTransactionsToUpdateClientAprData(null);
        setReferenceDateClientAprData(null);
        setNewClientAprDataId(null);
        dispatch(allActions.rightContentActions.update())

    }

    async function handleUpdateTransactionsNumbersAprs() {

        setTransactionsToUpdateNumbersApr(null);
        setReferenceDateNumberApr(null);
        setNewNumberApr(null);
        dispatch(allActions.rightContentActions.update())

    }

    async function doSaveApr(t, clientId, print) {

        console.log("SALVAR: ", t)
        setSubmitted(true);
        setOpenBackdrop(true);

        let transactionToPrint = null;

        const prevNumberApr = t.number_apr;

        let description = null;
        let editDescription = true;
        console.log("DESCRIPTION APR SELECTED: ", descriptionAprSelected);

        if (descriptionAprSelected == 0) { //Indica que é uma nova descrição

            description = areaAprDescription;

        } else {

            editDescription = false;
            description = descriptionsApr[descriptionAprSelected].id

        }

        console.log("SUBMITTED: ", submitted);
        console.log("numberApr: ", numberApr);
        console.log("prevNumberApr: ", prevNumberApr);

        //Caso haja descrição ele passa
        if (preponentName != ""
            && isValidCpf(preponentCpf)
            && responsableOperationName != ""
            && isValidCpf(responsableOperationCpf)
            && managerName != ""
            && isValidCpf(managerCpf)
            && description != undefined && description != ""
            && numberApr != "" && numberApr != null
            && selectedValidityDate != undefined) {

            setSubmitted(false);

            let newClientAprData = {
                certification_id: certifications[certificationSelected].id != 0 ? certifications[certificationSelected].id : null, //testando caso seja "NÃO POSSUI"
                certification_validity: certifications[certificationSelected].id != 0 ? moment.utc(selectedValidityDate).format("DD/MM/YYYY") : null,
                name_preponent: preponentName,
                cpf_preponent: preponentCpf,
                name_responsable: responsableOperationName,
                cpf_responsable: responsableOperationCpf,
                name_manager: managerName,
                cpf_manager: managerCpf,
            }

            console.log(newClientAprData);

            //Inicilizado com o id do clinetAprData inicializado na tela
            let clientAprDataId = null;

            const copyToCompareClientAprData = {
                ...clientAprData,
            }

            if (copyToCompareClientAprData.certification_validity) {
                copyToCompareClientAprData.certification_validity = moment.utc(copyToCompareClientAprData.certification_validity).format('DD/MM/YYYY');
            }

            delete copyToCompareClientAprData.client_id
            delete copyToCompareClientAprData.created_date
            delete copyToCompareClientAprData.modified_date
            delete copyToCompareClientAprData.id
            delete copyToCompareClientAprData.reference_date

            //Verificar se as informações de gestão são as mesmas ou são diferentes 
            //Caso seja diferentes deve ser criado um novo client_apr_data
            console.log('clientAprData ', JSON.stringify(copyToCompareClientAprData));
            console.log('newClientAprData: ', JSON.stringify(newClientAprData));
            let doVerifyClientAprData = false;
            if (!clientAprData || !_.isEqual(copyToCompareClientAprData, newClientAprData)) {


                newClientAprData.client_id = clientId;
                newClientAprData.reference_date = moment.utc(t.transaction_date).format("DD/MM/YYYY");
                let responseClietAprData = await createClientAprData(newClientAprData);

                //Em caso de sucesso a resposta é o objeto clientAprData
                if (responseClietAprData.success) {
                    clientAprDataId = responseClietAprData.body.id;

                    //verificar se foi alterada client_apr_data e existem aprs posteriores com uma client_apr_data diferente
                    doVerifyClientAprData = true;

                } else {
                    console.log("ERROR: ", responseClietAprData.error)
                }

            } else if (clientAprData) {

                clientAprDataId = clientAprData.id;
            }

            console.log('clientAprDataId: ', clientAprDataId);

            let response = null


            if (content.data.isSingleTransaction) {

                response = await updateSingleApr(t.id,
                    numberApr,
                    description,
                    clientAprDataId,
                    editDescription);

            } else {

                response = await updateApr(t.id,
                    numberApr,
                    description,
                    clientAprDataId,
                    editDescription);

            }


            if (response.success) {

                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'success',
                    message: 'APR Atualizada com sucesso!'
                }))

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: 'Falha ao atualizar APR!'
                }))
            }

            if (print) {

                dispatch(allActions.rightContentActions.update())

                // let transactionToPrint = response.body;
                // transactionToPrint.number_apr = numberApr;
                // transactionToPrint.operation = content.data.transactionSelected.operation;
                // transactionToPrint.client_apr_data = newClientAprData;
                // transactionToPrint.client_apr_data.certification_name = certifications[certificationSelected].entity
                //     + ' - '
                //     + certifications[certificationSelected].name;
                // //Caso seja uma descrição editada a descriição é a variável description
                // //Caso seja um predefinido a descrição carregada e a title
                // transactionToPrint.apr_description = editDescription ? description : descriptionsApr[descriptionAprSelected].title

                //createReportApr(transactionToPrint)
                dispatch(allActions.reportLoadActions.setReportLoad({
                    show: true,
                    reportType: content.data.isSingleTransaction ? "singleApr" : "apr",
                    aprId: t.id,
                    hideCpf: hideCpf,
                    message: 'Gerando APR...'
                }))

            } else if (doVerifyClientAprData || (prevNumberApr != null && prevNumberApr != numberApr)) {
                if (doVerifyClientAprData) {
                    await verifyCorrectionClientAprData(clientId, clientAprDataId, content.data.transactionSelected.transaction_date);
                } else {
                    console.log("numberAprnumberApr: ", numberApr);
                    console.log("numberApr.includes: ", numberApr.includes("/"));
                    if (numberApr.includes("/")) {
                        await verifyCorrectionNumbersAprs(clientId, numberApr, content.data.transactionSelected.transaction_date);
                    }
                }
            } else {
                dispatch(allActions.rightContentActions.update())
            }

        }

        setOpenBackdrop(false);
    }

    async function loadInfos(clientId, data) {

        const response = await listAprDescriptions(clientId);

        if (response.success) {

            let responseCerts = await getAllCertifications();

            console.log("Certifications: ", responseCerts.body.rows);

            if (responseCerts.success) {

                console.log("APRS DESC: ", response.body.rows)

                response.body.rows.unshift({
                    title: "Editar descrição da apr",
                    description: "",
                });

                setDescriptionsApr(response.body.rows != undefined ? response.body.rows : [])

                responseCerts.body.rows.unshift({
                    certification_entity_id: 0,
                    description: "",
                    entity: "",
                    id: 0,
                    name: "NÃO POSSUI -",
                });

                setCertifications(responseCerts.body.rows);

                //Veridicando se o client_apr_data_id da transação é o id da última apr salva, que já é enviado dá tela de APR Screen
                //Caso seja igual apenas carrega-se a informação que já está no objeto data.clientAprData
                //Caso não seja se obtém o clientAprData pelo id informado na transação;
                let finalClientAprData = null;

                if (data.transactionSelected.client_apr_data_id != undefined) {

                    const responseClientAprData = await getClientAprDataById(data.transactionSelected.client_apr_data_id);
                    console.log("Response client apr data: ", responseClientAprData);
                    if (responseClientAprData.success) {
                        finalClientAprData = responseClientAprData.body;
                    }
                } else {
                    const referenceDate = moment.utc(data.transactionSelected.transaction_date).format("DD/MM/YYYY");
                    const responseClientAprData = await getClientAprDataByReferenceDate(clientId, referenceDate)
                    console.log("referenceDate: ", referenceDate)
                    console.log("responseClientAprData referenceDate: ", responseClientAprData)
                    if (responseClientAprData.success && responseClientAprData.body?.rows?.length === 1) {
                        finalClientAprData = responseClientAprData.body.rows[0];
                    }
                }


                if (numberApr != null && numberApr != '') {
                    data.transactionSelected.number_apr = numberApr;
                }

                initInfos(data.transactionSelected, response.body.rows, finalClientAprData, data.hintNumberApr);
            }


        }
    }

    async function initInfos(transaction, descriptions, clientAprData, hintNumberApr) {

        console.log("TRANSACTION: ", transaction)

        //Iniciando dados do cliente padrão caso houverem (Preponente, responsável, gestor, certificado, validade)
        if (clientAprData) {

            //Usado na comparação
            setClientAprData(clientAprData);

            setPreponentName(clientAprData.name_preponent);
            setPreponentCpf(clientAprData.cpf_preponent);
            setResponsableOperationName(clientAprData.name_responsable);
            setResponsableOperationCpf(clientAprData.cpf_responsable);
            setManagerName(clientAprData.name_manager);
            setManagerCpf(clientAprData.cpf_manager);
            setCertificationSelected(clientAprData.certification_id ? clientAprData.certification_id : 0);
            setSelectedValidityDate(moment.utc(clientAprData.certification_validity));
        } else {
            setOpenClientAprData(true);
        }

        if (hintNumberApr && !transaction.number_apr) {

            setNumberApr(hintNumberApr)
            setUsingHintApr(true);

        } else {

            setNumberApr(transaction.number_apr)
        }

        //No caso de não haver nenhum tipo de descrição para essa APR
        if ((transaction.apr_description == undefined ||
            transaction.apr_description == "" || transaction.apr_description == null)
            && (transaction.apr_description_id == undefined || transaction.apr_description_id == null)) {
            setDescriptionAprSelected(0)
        } else {

            //Caso em que há descrição do tipo aberto
            if (transaction.apr_description != undefined
                && transaction.apr_description != "") {

                setDescriptionAprSelected(0)
                setAreaAprDescription(transaction.apr_description)
            } else {


                //Caso em que há descrição do tipo pré-definido
                let responseAprDescription = await getAprDescriptionById(transaction.apr_description_id);

                if (responseAprDescription.success) {

                    for (let i = 0; i < descriptions.length; i++) {
                        if (responseAprDescription.body.rows[0].id == descriptions[i].id) {
                            setDescriptionAprSelected(i)
                        }
                    }

                    setAreaAprDescription(responseAprDescription.body.rows[0].description)
                }

            }
        }

        setOpenBackdrop(false)
    }

    const [submittedNewApr, setSubmittedNewApr] = useState(false);

    async function doSaveDescriptionApr() {

        setOpenBackdrop(true)
        setSubmittedNewApr(true);

        if (inputTitleNewAprDescription != "" && inputAreaNewAprDescription != "") {
            let response = await saveNewAprDescription(clientOn.client.id,
                inputTitleNewAprDescription,
                inputAreaNewAprDescription);

            if (response.success) {

                loadInfos(clientOn.client.id, content.data);
                setInputTitleNewAprDescription("");
                setInputAreaNewAprDescription("");
                setSubmittedNewApr(false);
                setDescriptionRegisterNewApw(false);
                setValidationMessage('')

            } else {

                setSubmittedNewApr(false);
                setValidationMessage('Erro ao salvar texto padrão para APR')
            }

        }

        setOpenBackdrop(false)


    }

    //Método que limpa a descrição e o número da APR torando a APR marcada como não preenchidate
    async function doCleanAPR(transactionSelected) {

        setOpenBackdrop(true);

        const responseClean = await cleanAPRtransaction(transactionSelected.id);
        if (responseClean.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'APR Atualizada com sucesso!'
            }))

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao atualizar APR!'
            }))
        }

        setOpenBackdrop(false);

        dispatch(allActions.rightContentActions.update())
    }

    async function doCleanSingleAPR(transactionSelected) {

        setOpenBackdrop(true);

        const responseClean = await cleanSingleAPRtransaction(transactionSelected.id);
        if (responseClean.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'APR Atualizada com sucesso!'
            }))

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao atualizar APR!'
            }))
        }

        setOpenBackdrop(false);

        dispatch(allActions.rightContentActions.update())
    }

    async function doDeleteSingleTransaction(transactionSelected) {

        setOpenBackdrop(true);

        const responseClean = await deleteSingleTransaction(transactionSelected.id);
        if (responseClean.success) {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'success',
                message: 'APR removida com sucesso!'
            }))

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao remover APR!'
            }))
        }

        setOpenBackdrop(false);

        dispatch(allActions.rightContentActions.update())
    }

    //clean APR
    function handleCancel() {
        setConfirmingAction(false);
    }

    function handleCancelCleanSingle() {
        setConfirmingCleanSingleAction(false);
    }

    function handleConfirm() {
        doCleanAPR(content.data.transactionSelected);
    }

    function handleConfirmCleanSingle() {
        doCleanSingleAPR(content.data.transactionSelected);
    }

    function handleCancelDelete() {
        setConfirmingDeleteAction(false);
    }

    function handleConfirmDelete() {
        doDeleteSingleTransaction(content.data.transactionSelected);
    }

    useEffect(() => {

        if (content
            && content.data
            && content.data.transactionSelected
            && clientOn
            && clientOn.client) {

            console.log("DATA: ", content.data);

            setOpenBackdrop(true)
            //Evitar que a animação trave
            setTimeout(() => {
                loadInfos(clientOn.client.id, content.data);
            }, 500)

        }


    }, [])

    return (
        <div className={classes.root}>

            {/* <div className={classes.header}>
                <IconButton className={classes.hiddenIcon}>
                    <CloseIcon />
                </IconButton>

                <div>
                    <Typography
                        variant={'h6'}
                        align={'left'}>
                        {
                            !registerNewApw ?
                                "APR" : "Cadastrar novo texto padrão"
                        }

                    </Typography>

                </div>


                <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                    <CloseIcon />
                </IconButton>

            </div> */}
            {
                !confirmingAction
                    && !confirmingDeleteAction
                    && !confirmingCleanSingleAction ?

                    <>
                        <div className={classes.header}>

                            <div className={classes.leftHeader}>
                                <div className={classes.headerDate}>
                                    <EventIcon />
                                    <Typography
                                        align={'left'}

                                        className={classes.labelMonth}>
                                        {content && content.data && content.data.transactionSelected && content.data.transactionSelected.transaction_date
                                            ? moment.utc(content.data.transaction_date).format('DD/MM/YYYY') : ""}
                                    </Typography>
                                </div>

                                <div>

                                    <Typography
                                        align={'left'}
                                        className={classes.fundLabel}>
                                        {content && content.data && content.data.transactionSelected ? content.data.transactionSelected.fund_name : ""}
                                    </Typography>
                                    {content && content.data && content.data.transactionSelected && content.data.transactionSelected.account ?
                                        <Typography
                                            align={'left'}
                                            className={classes.accountLabel}>
                                            <span className={classes['operation' + content.data.transactionSelected.operation_id]}>{content.data.transactionSelected.operation}</span>
                                            <span className={classes.spacingLeft2}>Ag: {content.data.transactionSelected.agency}</span>
                                            <span className={classes.spacingLeft2}>CC: {applyMaskAccount(content.data.transactionSelected.account)}</span>

                                        </Typography>
                                        : null
                                    }

                                </div>

                            </div>

                            <IconButton onClick={() => dispatch(allActions.rightContentActions.setRightContent(null))}>
                                <CloseIcon />
                            </IconButton>

                        </div>


                        <div className={classes.main}>

                            {
                                registerDescriptionNewApw ?
                                    <div className={'subMain'}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <MyTextField
                                                    labelclassname={classes.noMarginTop}
                                                    value={inputTitleNewAprDescription}
                                                    onChange={handleChangeTitleNewApr}
                                                    variant="outlined"
                                                    margin="dense"
                                                    outerlabel={'Título'}
                                                    type="text"
                                                    validator={inputTitleNewAprDescription == "" && submittedNewApr}
                                                    placeholder="Insira um título"
                                                    helperText="O título deve ser preenchido"
                                                    id="inputTitleNewAprDescription"
                                                    fullWidth />
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <MyTextField
                                                    value={inputAreaNewAprDescription}
                                                    onChange={handleChangeAreaNewApr}
                                                    variant="outlined"
                                                    multiline={true}
                                                    rows={5}
                                                    rowsMax={5}
                                                    margin="dense"
                                                    outerlabel={'Descrição'}
                                                    placeholder="Insira a descrição da APR"
                                                    validator={inputAreaNewAprDescription == "" && submittedNewApr}
                                                    helperText="Uma descrição deve ser fornecida"
                                                    type="text"
                                                    id="inputAreaNewAprDescription"
                                                    fullWidth />

                                                <span style={{ float: 'right', fontSize: '12px' }}>Caracteres {inputAreaNewAprDescription.length}/1000</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    :
                                    <>

                                        {/* Inicio de dados do cliente da APR */}
                                        <Accordion expanded={openClientAprData} onChange={() => setOpenClientAprData(!openClientAprData)}>
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                expandIcon={<ExpandMoreIcon />}>
                                                <Typography className={classes.heading}>
                                                    <InfoIcon />{'Informações da Gestão do RPPS'}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>

                                                    {/* PROPONENTE SE TORNAM O REPRESENTANTE LEGAL */}
                                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={preponentName}
                                                            onChange={(value) => setPreponentName(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'Representante Legal'}
                                                            type="text"
                                                            validator={preponentName == "" && submitted}
                                                            helperText="O nome do Representante Legal deve ser preenchido"
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={preponentCpf}
                                                            onChange={(value) => setPreponentCpf(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'CPF Representante Legal'}
                                                            type="text"
                                                            mask={["cpf"]}
                                                            validator={!isValidCpf(preponentCpf) && submitted}
                                                            helperText="Informe um cpf válido"
                                                            fullWidth />

                                                    </Grid>

                                                    {/* MUDANDO A ORDEM EXIBIDA O ANTIGO AUTORIZADOR/GESTOR SOBE */}
                                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={managerName}
                                                            onChange={(value) => setManagerName(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'Proponente/Gestor'}
                                                            type="text"
                                                            validator={managerName == "" && submitted}
                                                            helperText="O nome do Proponente/Gestor deve ser preenchido"
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={managerCpf}
                                                            onChange={(value) => setManagerCpf(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'CPF Proponente/Gestor'}
                                                            type="text"
                                                            mask={["cpf"]}
                                                            validator={!isValidCpf(managerCpf) && submitted}
                                                            helperText="Informe um cpf válido"
                                                            fullWidth />
                                                    </Grid>

                                                    {/* O ANTIGO LIQUIDANTE DESCE */}
                                                    <Grid item lg={7} md={7} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={responsableOperationName}
                                                            onChange={(value) => setResponsableOperationName(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'Liquidante'}
                                                            type="text"
                                                            validator={responsableOperationName == "" && submitted}
                                                            helperText="O nome do Responsável pela liquidação da operação deve ser preenchido"
                                                            fullWidth />
                                                    </Grid>
                                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                                        <MyTextField
                                                            labelclassname={classes.noMarginTop}
                                                            value={responsableOperationCpf}
                                                            onChange={(value) => setResponsableOperationCpf(value)}
                                                            variant="outlined"
                                                            margin="dense"
                                                            outerlabel={'CPF Liquidante'}
                                                            type="text"
                                                            mask={["cpf"]}
                                                            validator={!isValidCpf(responsableOperationCpf) && submitted}
                                                            helperText="Informe um cpf válido"
                                                            fullWidth />
                                                    </Grid>


                                                    <Grid item lg={5} md={5} sm={12} xs={12}>
                                                        <MySelect
                                                            outerlabel={'Certificação'}
                                                            value={certificationSelected}
                                                            onChange={(e) => setCertificationSelected(e.target.value)}
                                                            options={certifications.map((row, index) => (
                                                                <option key={'cert-' + index} value={index}>{row.entity + ' - ' + row.name}</option>
                                                            ))}>

                                                        </MySelect>
                                                    </Grid>

                                                    <Grid item xs={12} sm={4} md={4} lg={4}>

                                                        <MyDatePicker
                                                            disabled={certificationSelected == 0}
                                                            className={classes.myDatePicker}
                                                            margin="dense"
                                                            outerlabel="Validade"
                                                            format="DD/MM/YYYY"
                                                            value={selectedValidityDate}
                                                            onChange={handleDateChange} />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>


                                        {/* Fim dados do cliente da APR */}
                                        <div className={'subMain'}>
                                            <Grid container spacing={2}>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <MyTextField
                                                        labelclassname={classes.noMarginTop}
                                                        value={usingHintApr ? content.data.hintNumberApr : numberApr}
                                                        onChange={(value) => {
                                                            setUsingHintApr(false);
                                                            setNumberApr(value);
                                                        }}
                                                        variant="outlined"
                                                        margin="dense"
                                                        outerlabel={'Número / ANO'}
                                                        endadornment={usingHintApr ?
                                                            <Tooltip
                                                                placement='bottom'
                                                                arrow
                                                                interactive
                                                                title={'Número de APR sugerido a partir do preenchimento das suas últimas APRs'}>
                                                                <InfoIcon style={{ color: 'green', fontSize: '0.95em', cursor: 'pointer' }} />
                                                            </Tooltip>
                                                            : null}
                                                        type="text"
                                                        validator={(numberApr == "" || numberApr == null) && submitted}
                                                        helperText="Prencha o número da APR"
                                                        fullWidth />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <MyTextField
                                                        labelclassname={classes.noMarginTop}
                                                        value={content.data && content.data.transactionSelected
                                                            && content.data.transactionSelected.amount ? applyMaskAmount(content.data.transactionSelected.amount) : ""}
                                                        variant="outlined"
                                                        margin="dense"
                                                        outerlabel={'Valor'}
                                                        type="text"
                                                        disabled
                                                        fullWidth />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <MyTextField
                                                        labelclassname={classes.noMarginTop}
                                                        value={content.data && content.data.transactionSelected && content.data.transactionSelected.transaction_date ?
                                                            moment.utc(content.data.transactionSelected.transaction_date).format('DD/MM/YYYY') : ""}
                                                        variant="outlined"
                                                        margin="dense"
                                                        outerlabel={'Data'}
                                                        type="text"
                                                        disabled
                                                        fullWidth />
                                                </Grid>
                                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                                    <MyTextField
                                                        className={classes['operation' + (content.data && content.data.transactionSelected && content.data.transactionSelected.operation_id
                                                            ? content.data.transactionSelected.operation_id : "")]}
                                                        labelclassname={classes.noMarginTop}
                                                        value={content.data && content.data.transactionSelected && content.data.transactionSelected.operation ? content.data.transactionSelected.operation : ""}
                                                        variant="outlined"
                                                        margin="dense"
                                                        outerlabel={'Operação'}
                                                        type="text"
                                                        disabled
                                                        fullWidth />
                                                </Grid>





                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <MySelect
                                                        outerlabel={'Títulos predefinidos'}
                                                        value={descriptionAprSelected}
                                                        onChange={(e) => handleChangeDescriptionAprSelected(e)}
                                                        options={descriptionsApr.map((row, index) => (
                                                            <option key={index} value={index}>{row.title}</option>
                                                        ))}>

                                                    </MySelect>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <MyTextField
                                                        value={areaAprDescription}
                                                        onChange={handleChangeAreaAprDescription}
                                                        variant="outlined"
                                                        multiline={true}
                                                        rows={7}
                                                        rowsMax={7}
                                                        margin="dense"
                                                        validator={areaAprDescription == "" && submitted}
                                                        helperText={'Uma descrição deve ser informada'}
                                                        outerlabel={'Descrição'}
                                                        type="text"
                                                        fullWidth />

                                                    <span style={{ float: 'right', fontSize: '12px' }}>Caracteres {areaAprDescription.length}/1000</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                            }

                            <br />
                            <div className={classes.bottom}>


                                {
                                    validationMessage != '' ?
                                        <Typography className={classes.validationMessage}>
                                            {validationMessage}
                                        </Typography>
                                        : null

                                }

                                <>
                                    {
                                        registerDescriptionNewApw ?
                                            <div className={classes.buttonsBottom}>

                                                <UnoButton
                                                    size="small"
                                                    color='primary'
                                                    type='error'
                                                    onClick={() => setDescriptionRegisterNewApw(false)}>
                                                    {'Cancelar'}
                                                </UnoButton>

                                                <UnoButton
                                                    type={'primary'}
                                                    onClick={() => doSaveDescriptionApr()} color="primary">
                                                    {'Salvar'}
                                                </UnoButton>
                                            </div>

                                            :
                                            <div className={classes.buttonsBottom}>

                                                <UnoButton
                                                    size="small"
                                                    color='primary'
                                                    startIcon={<AddIcon />}
                                                    onClick={() => setDescriptionRegisterNewApw(true)}>
                                                    {'Criar Texto Padrão'}
                                                </UnoButton>

                                                <div>

                                                    {
                                                        content && content.data && content.data.isSingleTransaction ?
                                                            <UnoButton
                                                                type='errorClean'
                                                                isIconButton
                                                                onClick={() => setConfirmingDeleteAction(true)}
                                                                myIcon={<DeleteIcon />}
                                                                size='small' /> : null
                                                    }

                                                    {
                                                        content && content.data && content.data.isSingleTransaction ?
                                                            <UnoButton
                                                                type='defaultClean'
                                                                isIconButton
                                                                onClick={() => setConfirmingCleanSingleAction(true)}
                                                                myIcon={<RenewIcon />}
                                                                size='small' />
                                                            :
                                                            <UnoButton
                                                                type='defaultClean'
                                                                isIconButton
                                                                onClick={() => setConfirmingAction(true)}
                                                                myIcon={<RenewIcon />}
                                                                size='small' />
                                                    }

                                                    <Tooltip
                                                        placement='top'
                                                        arrow
                                                        interactive
                                                        title={hideCpf ? 'Não serão completamente exibidos so CPFs da Gestão' : 'Serão exibidos CPFs da Gestão'}>
                                                        <UnoButton
                                                            className={classes.spacingRight2}
                                                            type='defaultClean'
                                                            isIconButton
                                                            onClick={() => setHideCpf(!hideCpf)}
                                                            myIcon={hideCpf ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                            size='small' />
                                                    </Tooltip>


                                                    <UnoButton
                                                        className={classes.spacingRight2}
                                                        type={'success'}
                                                        startIcon={<PrintIcon />}
                                                        onClick={() => doSaveApr(content.data.transactionSelected, clientOn.client.id, true)}>
                                                        {'Imprimir APR'}
                                                    </UnoButton>

                                                    <UnoButton
                                                        type={'primary'}
                                                        onClick={() => doSaveApr(content.data.transactionSelected, clientOn.client.id, false)}>
                                                        {'Salvar APR'}
                                                    </UnoButton>
                                                </div>
                                            </div>

                                    }
                                </>
                            </div>
                        </div>
                    </>

                    :

                    <>

                        {
                            confirmingDeleteAction ? <ConfirmActionRightContent
                                message={`Tem certeza que quer excluir essa APR?`}
                                handleCancel={handleCancelDelete}
                                handleConfirm={handleConfirmDelete}
                            /> : null
                        }

                        {
                            confirmingAction ? <ConfirmActionRightContent
                                message={`Tem certeza que quer remover os dados de preenchimento dessa APR?`}
                                handleCancel={handleCancel}
                                handleConfirm={handleConfirm}
                            /> : null
                        }

                        {
                            confirmingCleanSingleAction ? <ConfirmActionRightContent
                                message={`Tem certeza que quer remover os dados de preenchimento dessa APR?`}
                                handleCancel={handleCancelCleanSingle}
                                handleConfirm={handleConfirmCleanSingle}
                            /> : null
                        }

                    </>


            }
            {
                openBackdrop ?
                    <div className={classes.rightDivBackdrop}>
                        <CircularProgress className={'progress'} />
                    </div> : null
            }

            <UpdateClientAprDataTransactionsModal
                clientId={clientOn?.client?.id}
                newClientAprDataId={newClientAprDataId}
                referenceDate={referenceDateClientAprData}
                transactionsToUpdate={transactionsToUpdateClientAprData}
                handleAction={(action) => handleUpdateTransactionsClientAprData(action)}
            />

            <UpdateNumbersAprsTransactionsModal
                clientId={clientOn?.client?.id}
                newNumberApr={newNumberApr}
                referenceDate={referenceDateNumberApr}
                transactionsToUpdate={transactionsToUpdateNumbersApr}
                handleAction={(action) => handleUpdateTransactionsNumbersAprs(action)}
            />
        </div >
    );
}