import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from "react";

import TableContainer from '@material-ui/core/TableContainer';

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

//Controllers

import { headerSearch, unoTheme } from '../../../assets/styles/unoTheme';

import { CircularProgress, Grid, Paper, TextField } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Remove from '@material-ui/icons/HighlightOff';
import { Bar } from "react-chartjs-2";
import { getHistoricoRentabilidadesPorPeriodo, getInfosAndRiskFundComdinheiroByDate, getTablePatrimonioComdinheiroByPeriod } from "../../../API/comdinheiro-api";
import { getAllFunds } from "../../../API/fund";
import { getLocalBenchmarks } from "../../../API/local_benchmaks";
import { REQ_COMDINHEIRO_TYPE } from "../../../API/reqs_comdinheiro";
import MyAutoComplete from "../../utils/MyAutoComplete";
import MySelect from "../../utils/MySelect";
import UnoButton from "../../utils/UnoButton";
import { applyMaskAmount, applyMaskAmountComdinheiro, applyMaskAmountComdinheiroFreeDecimals, formatMillionNumber, formatStringToDisplay, getDayMonthsAgo, getReferenceDate, isValidCnpj } from "../../utils/utils";

const maxFundsInCompare = 5;
const widthFundsInCompare = 15;
const widthCompare = 100 - widthFundsInCompare;

const optionsChartRentsMonth = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    //return applyMaskAmount(value, true);
                    // return formatMillionNumber(value);
                    return value + '%';
                }
            }
        }],
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel) + '%';
            }
        }
    },

}

const optionsChartPatrimonio = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    //return applyMaskAmount(value, true);
                    // return formatMillionNumber(value);
                    return formatMillionNumber(value);
                }
            }
        }],
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel, true);
            }
        }
    },

}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    toStickyHeader: {
        display: 'flex',
        height: '100%',
    },
    rightHeader: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
    },

    fundsInCompare: {
        overflowY: 'scroll',
        width: `${widthFundsInCompare}%`,
        height: '100%',
        padding: '16px',

        '&::-webkit-scrollbar': {
            width: '5px',
        },

        '&::-webkit-scrollbar-track': {
            background: '#c3c3c3',
        },
        '&::-webkit-scrollbar-thumb': {
            background: unoTheme.mainColor,
            borderRadius: '10px',
        },

        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
        '& .fundInCompare': {


            '& .cardFund': {

                borderRadius: '4px',
                padding: '8px 16px',
                textAlign: 'left',
                '& .cardFundTop': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                    '& .segment': {
                        fontSize: '12px',
                        marginBottom: '4px'
                    },

                    '& .removeFund': {
                        cursor: 'pointer',
                    },
                },


                '& p': {
                    margin: '0px',
                },

                '& .fundName': {
                    fontSize: '12px',
                },
                '& .cnpj': {
                    fontSize: '12px',
                    fontWeight: 'bold'
                },
            }

        }
    },

    dividerFunds: {
        width: '5px',
        backgroundColor: unoTheme.mainColor,
        borderRadius: '20px',
        height: 'calc(100% - 32px)',
        marginTop: '16px',
        padding: '16px 0px',
    },

    comparativeLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },

    centerButton: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',



        '& .compareButton': {

            marginTop: -38.750,
        }
    },

    compareCharts: {
        padding: '16px',
        display: 'flex',
        flex: 1,
        width: `calc(${widthCompare}% - 5px)`,
        maxWidth: `calc(${widthCompare}% - 5px)`,
        height: '100%',
        overflowY: 'scroll',

        '& .loadChart': {
            borderRadius: '10px',
            background: "#eeeeee",
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },

        '& .loadPatrimony': {
            height: '400px',
        },

        '& .loadRiskReturn': {
            height: '200px',
        },
    },
    gridPaperTable: {
        borderRadius: '10px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '& .title': {
            color: '#768191',
        },

        '& .MuiTypography-root': {
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        }
    },
    divChart: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        justifyContent: 'center',


        '& .chartHeader': {
            minHeight: '56px',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            //paddingTop: theme.spacing(2),
            paddingBottom: '12px',
            paddingRight: '13px',

            '& .MuiTypography-root': {
                marginRight: '8px',
            },

            '& .right': {
                display: 'flex',
                alignItems: 'center',
            }
        },

        '& .chartWrapper': {
            height: '350px',
            width: '100%',
        },

        '& .chartWrapper2': {
            height: '450px',
            width: '100%',
        },

        '& .title': {
            textAlign: 'left',
        }

    },
    loadTop: {
        marginRight: '10px',
        height: '15px !important',
        width: '15px !important',
        color: unoTheme.mainColor,

    },

    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: 'white',
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },


            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: 'black',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },

            '& .MuiTableRow-root.benchmarkRow': {
                backgroundColor: unoTheme.mainColor + ' !important',
                '& .MuiTableCell-root': {
                    color: 'white !important',
                }

            },
        },


    },

}));

export default function CompareFundsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.currentUser)
    const clientOn = useSelector(state => state.clientOn)
    //const [clientReturns, setClientReturns] = useState([]);
    const [allFunds, setAllFunds] = useState(null);
    const [fundSelectedToCompare, setFundSelectedToCompare] = useState(null);
    const [fundsInCompare, setFundsInCompare] = useState([]);
    const [mapCnpjsFunds, setMapCnpjsFunds] = useState(null);

    const [allBenchmarks, setAllBenchmarks] = useState(null);
    const [benchmarkSelectedToCompare, setBenchmarkSelectedToCompare] = useState(null);
    const [benchamrksInCompare, setBenchamrksInCompare] = useState([]);

    //Charts
    const [typesChart, setTypesChart] = useState(['Valores mensais', 'Valores acumulados']);
    const [typeChartSelected, setTypeChartSelected] = useState(0);
    const handleChangeTypeChartPeriod = (value) => {

        setTypeChartSelected(value);
        formatDataToRentsChart(mapCnpjsFunds, allRentsFundsData, periodRentsSelected, parseInt(value));

    };
    const [loadPatrimonyChart, setLoadPatrimonyChart] = useState(false);
    const [periods, setPeriods] = useState(['12 meses', '24 meses', '36 meses']);
    const [allPatrimonyData, setAllPatrimonyData] = useState([]);
    const [periodChartPatrimonioSelected, setPeriodChartPatrimonioSelected] = useState(null);
    const handleChangeChartPatrimonioPeriod = (value) => {

        setPeriodChartPatrimonioSelected(value);
        formatDataToPatrimonyChart(mapCnpjsFunds, allPatrimonyData.byMonth, parseInt(value));

    };

    const [loadRiskAndReturnTable, setLoadRiskAndReturnTable] = useState(false);
    const [allRiskReturnData, setAllRiskReturnData] = useState([]);

    const [loadRentsFundsChart, setLoadloadRentsFundsChart] = useState(false);
    const [allRentsFundsData, setAllRentsFundsData] = useState([]);

    const [dataPatrimonyChart, setDataPatrimonyChart] = useState();
    const [dataRentsChart, setDataRentsChart] = useState();

    const [periodRentsSelected, setPeriodRentsSelected] = useState(null);
    const handleChangeChartRentsPeriod = (value) => {

        setPeriodRentsSelected(value);
        formatDataToRentsChart(mapCnpjsFunds, allRentsFundsData, parseInt(value), typeChartSelected);

    };
    //END CHARTS

    const fundsInCompareDivRef = useRef(null)

    const [inCompare, setInCompare] = useState(false);

    async function doGetAllFunds() {
        const responseAllFunds = await getAllFunds();

        if (responseAllFunds.success) {
            setAllFunds(responseAllFunds.body.rows)
        }
    }

    async function doGetAllBenchmarks() {
        const response = await getLocalBenchmarks();
        if (response.success) {

            setAllBenchmarks(response.body.rows);

        }
    }

    function addFundToCompare(fundToCompare) {


        if (fundToCompare && !fundsInCompare.some(el => el.id == fundToCompare.id)) {
            if (fundsInCompare.length < maxFundsInCompare) {

                setInCompare(false);

                const newFundsToCompare = [...fundsInCompare];
                newFundsToCompare.push(fundToCompare);
                setFundsInCompare(newFundsToCompare);

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Número máximo de fundos atingidos',
                    type: 'warning'
                }))
            }
        } else {
            if (fundToCompare) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Fundo já selecionado',
                    type: 'warning'
                }))
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Selecione um fundo',
                    type: 'warning'
                }))
            }

        }



    }

    function removeFundToCompare(index) {

        setInCompare(false);

        fundsInCompare.splice(index, 1)
        const newFundsToCompare = [...fundsInCompare];
        setFundsInCompare(newFundsToCompare);


    }

    function addBenchmarkToCompare(becnhmarkToCompare) {

        if (becnhmarkToCompare && !benchamrksInCompare.some(el => el.id == becnhmarkToCompare.id)) {
            if (benchamrksInCompare.length < 1) {

                setInCompare(false);

                const newBenchmarksToCompare = [...benchamrksInCompare];
                newBenchmarksToCompare.push(becnhmarkToCompare);
                setBenchamrksInCompare(newBenchmarksToCompare);
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Número máximo de benchmarks atingidos',
                    type: 'warning'
                }))
            }
        } else {
            if (becnhmarkToCompare) {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Benchmark já selecionado',
                    type: 'warning'
                }))
            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    message: 'Selecione um benchmark',
                    type: 'warning'
                }))
            }

        }



    }

    function removeBenchmarkToCompare(index) {

        setInCompare(false);

        benchamrksInCompare.splice(index, 1)
        const newBenchmarksToCompare = [...benchamrksInCompare];
        setBenchamrksInCompare(newBenchmarksToCompare);

    }

    function renderFundsInCompare(funds) {


        const layoutGridFundsInCompare = [];
        funds.forEach((element, index) => {
            layoutGridFundsInCompare.push(
                <Grid className="fundInCompare" item key={'fund-in-compare-' + index} md={12} sm={12} lg={12}>
                    <Paper className={'cardFund'} elevation={3}>
                        <div className={'cardFundTop'}>

                            <p className="segment" style={{ color: unoTheme.chartColorsCompareFunds[element.legislation_segment_id_v2] }}>{element.segment_v2}</p>
                            <p className="removeFund" onClick={() => removeFundToCompare(index)}><Remove /></p>
                        </div>
                        <p className="fundName">{element.fund_name}</p>
                        <p className="cnpj">{element.cnpj}</p>
                    </Paper>
                </Grid>
            )
        });

        return layoutGridFundsInCompare;

    }

    function renderBenchmarksInCompare(benchmarks) {

        const layoutGridBenchmarksInCompare = [];
        benchmarks.forEach((element, index) => {
            layoutGridBenchmarksInCompare.push(
                <Grid className="fundInCompare" item key={'fund-in-compare-' + index} md={12} sm={12} lg={12}>
                    <Paper className={'cardFund'} elevation={3}>
                        <div className={'cardFundTop'}>

                            <p className="segment" style={{ color: unoTheme.chartColorsCompareFunds[16] }}>Benchmark</p>
                            <p className="removeFund" onClick={() => removeBenchmarkToCompare(index)}><Remove /></p>
                        </div>
                        <p className="fundName">{element.name}</p>
                        <p className="cnpj">{ }</p>
                    </Paper>
                </Grid>
            )
        });

        return layoutGridBenchmarksInCompare;

    }

    function changeComdinheiroBenchmarkToLocalBenchmark(riskReturnMap, mapFundsByCnpj) {

        const newRiskReturnMap = {};
        Object.entries(riskReturnMap).map(([key, row]) => {
            if (key != '  CNPJ do Fundo') {

                if (!isValidCnpj(key)) {


                    const localBenchmarkFound = allBenchmarks.find(el => el.comdinheiro_name.toUpperCase() == key.toUpperCase());

                    if (localBenchmarkFound) {

                        newRiskReturnMap[localBenchmarkFound.name] = row;
                    } else {

                        newRiskReturnMap[key] = row;

                    }

                } else {

                    newRiskReturnMap[key] = row;
                }
            }
        })



        return newRiskReturnMap;

    }

    async function doCompare(funds, benchmarks) {

        setInCompare(true);
        setDataRentsChart(null)
        setDataPatrimonyChart(null)
        setPeriodChartPatrimonioSelected(0);
        setPeriodRentsSelected(0);

        const cnpjs = [];
        const mapFundsByCnpj = {};
        funds.forEach(element => {
            cnpjs.push(element.cnpj);
            mapFundsByCnpj[element.cnpj] = element;
        });


        setMapCnpjsFunds(mapFundsByCnpj);

        //Obtendo data de 36 meses atrás
        const endDate = getReferenceDate();
        const startDate = getDayMonthsAgo(endDate, 37);

        setLoadPatrimonyChart(true);
        setLoadRiskAndReturnTable(true)
        setLoadloadRentsFundsChart(true)

        const responseRents = await getHistoricoRentabilidadesPorPeriodo(cnpjs, benchmarks, startDate, endDate);

        if (responseRents) {
            setAllRentsFundsData(responseRents.body)
            setLoadloadRentsFundsChart(false)
            formatDataToRentsChart(mapFundsByCnpj, responseRents.body, 0, 0);
        } else {
            setAllRentsFundsData(null)
            setLoadloadRentsFundsChart(false)
        }

        const responsePatrimony = await getTablePatrimonioComdinheiroByPeriod(startDate, endDate, cnpjs, benchmarks, REQ_COMDINHEIRO_TYPE.COMPARADOR_FUNDOS);
        setAllPatrimonyData(responsePatrimony.body);
        setLoadPatrimonyChart(false);
        formatDataToPatrimonyChart(mapFundsByCnpj, responsePatrimony.body.byMonth, 0);

        const responseRiskReturn = await getInfosAndRiskFundComdinheiroByDate(endDate, cnpjs, benchmarks, REQ_COMDINHEIRO_TYPE.COMPARADOR_FUNDOS);


        setAllRiskReturnData(changeComdinheiroBenchmarkToLocalBenchmark(responseRiskReturn.body, mapFundsByCnpj))
        setLoadRiskAndReturnTable(false)

    }

    function getDataPatrimonioChart(fundsValues, labels, mapFunds) {

        console.log('mapCnpjsFunds: ', mapCnpjsFunds);

        const datasets = [];

        let index = 0;
        Object.entries(fundsValues).map(([key, values]) => {
            datasets.push(
                {
                    label: mapFunds ? mapFunds[key].fund_name : key,
                    fill: false,
                    backgroundColor: unoTheme.chartColorsCompareFunds[index],
                    borderColor: unoTheme.chartColorsCompareFunds[index],
                    pointBackgroundColor: unoTheme.chartColorsCompareFunds[index],
                    pointBorderColor: unoTheme.chartColorsCompareFunds[index],
                    pointHoverBackgroundColor: unoTheme.chartColorsCompareFunds[index],
                    pointHoverBorderColor: unoTheme.chartColorsCompareFunds[index],
                    data: values,
                    type: 'line',
                },
            )
            index++;
        })

        console.log('datasets: ', datasets);

        let dataChartPatrimonio = {
            labels: labels,
            datasets: datasets,
        };

        return dataChartPatrimonio;
    }

    function getDataRentsChart(fundsValues, labels, mapFunds) {

        console.log('fundsValues: ', fundsValues);

        const datasets = [];

        let index = 0;
        Object.entries(fundsValues).map(([key, values]) => {

            if (isValidCnpj(key)) {

                //Fundos em linha
                datasets.push(
                    {
                        label: mapFunds && mapFunds[key] ? mapFunds[key].fund_name : key,
                        fill: false,
                        backgroundColor: unoTheme.chartColorsCompareFunds[index],
                        borderColor: unoTheme.chartColorsCompareFunds[index],
                        pointBackgroundColor: unoTheme.chartColorsCompareFunds[index],
                        pointBorderColor: unoTheme.chartColorsCompareFunds[index],
                        pointHoverBackgroundColor: unoTheme.chartColorsCompareFunds[index],
                        pointHoverBorderColor: unoTheme.chartColorsCompareFunds[index],
                        data: values,
                        type: 'line',
                    },
                )

            } else {
                //Becnhmark em barra
                datasets.push(
                    {
                        label: mapFunds && mapFunds[key] ? mapFunds[key].fund_name : key,
                        backgroundColor: unoTheme.chartColors[0],
                        data: values,
                    },
                )
            }
            index++;
        })


        let dataChartRents = {
            labels: labels,
            datasets: datasets,
        };

        return dataChartRents;
    }

    function getDataPatrimonyChartByPeriod(data, period) {
        //Inicializando select de chart year        
        let dataChartPatrimonio = [];
        let labels = [];

        //O período pode ser 0, 1 ou 2 corresponde ao valor do select por isso deve ser acrescido de 1
        let resultPeriod = ((period + 1) * 12);
        console.log("Result period: ", resultPeriod);
        let dataSlice = data.slice(36 - resultPeriod, 37)
        console.log("FORMAT DATA: ", dataSlice);

        const valuesToPatrimonyChart = {};
        const cnpjs = [];
        fundsInCompare.forEach(fundInCompare => {

            cnpjs.push(fundInCompare.cnpj);
            valuesToPatrimonyChart[fundInCompare.cnpj] = [];
        });

        //Fazendo o slice com o período 12, 24, 36                       

        dataSlice.forEach(value => {

            cnpjs.forEach(cnpj => {
                valuesToPatrimonyChart[cnpj].push({
                    y: value[cnpj]
                })
            });

            labels.push(value.data);
        });

        return {
            valuesToPatrimonyChart: valuesToPatrimonyChart,
            labels: labels,
        }
    }

    function formatDataToPatrimonyChart(mapFunds, data, period) {

        console.log("DATA PATRIMONIO: ", data);

        const { valuesToPatrimonyChart, labels } = getDataPatrimonyChartByPeriod(data, period);

        setDataPatrimonyChart(getDataPatrimonioChart(valuesToPatrimonyChart, labels, mapFunds));

    }

    function getDataRentsChartByTypeAndPeriod(data, period, typeChart) {
        const labels = [];
        const valuesToRentsChart = {};
        const cnpjs = [];
        fundsInCompare.forEach(fundInCompare => {

            cnpjs.push(fundInCompare.cnpj);
            valuesToRentsChart[fundInCompare.cnpj] = [];
        });

        benchamrksInCompare.forEach(benchInCompare => {

            cnpjs.push(benchInCompare.comdinheiro_name);
            valuesToRentsChart[benchInCompare.comdinheiro_name] = [];
        });

        //O período pode ser 0, 1 ou 2 corresponde ao valor do select por isso deve ser acrescido de 1
        let resultPeriod = ((period + 1) * 12);
        console.log("Result period rents: ", resultPeriod);
        let dataSlice = data.slice(36 - resultPeriod, 37)
        console.log("FORMAT DATA rents: ", dataSlice);

        console.log('cnpjs: ', cnpjs);
        console.log('valuesToRentsChart: ', valuesToRentsChart);

        //Inicializando variável para valor acumulado
        const acumValueToCnpj = {};
        cnpjs.forEach(cnpj => {

            acumValueToCnpj[cnpj] = 1;

        });

        dataSlice.forEach(value => {

            if (typeChart == 0) { //retornos mensais

                cnpjs.forEach(cnpj => {
                    valuesToRentsChart[cnpj].push({
                        y: value[cnpj]
                    })
                });

            } else { //valores acumulados

                cnpjs.forEach(cnpj => {

                    acumValueToCnpj[cnpj] *= (1 + (value[cnpj] / 100));

                    valuesToRentsChart[cnpj].push({
                        y: parseFloat(parseFloat((acumValueToCnpj[cnpj] - 1) * 100).toFixed(2))
                    })

                });
            }

            labels.push(value.data);


        });


        const newValuesToRentsChart = {};
        Object.entries(valuesToRentsChart).map(([key, row]) => {
            if (key != '  CNPJ do Fundo') {

                if (!isValidCnpj(key)) {

                    const localBenchmarkFound = allBenchmarks.find(el => el.comdinheiro_name.toUpperCase() == key.toUpperCase());

                    if (localBenchmarkFound) {

                        newValuesToRentsChart[localBenchmarkFound.name] = row;
                    } else {

                        newValuesToRentsChart[key] = row;

                    }

                } else {

                    newValuesToRentsChart[key] = row;
                }
            }
        })

        return {
            valuesToRentsChart: newValuesToRentsChart, labels
        }
    }

    function formatDataToRentsChart(mapFunds, data, period, typeChart) {
        console.log("DATA RENTS: ", data);
        if (data) {

            const { valuesToRentsChart, labels } = getDataRentsChartByTypeAndPeriod(data, period, typeChart);

            setDataRentsChart(getDataRentsChart(valuesToRentsChart, labels, mapFunds));
        }

    }

    function renderRowsFundRiskReturn1(data) {

        const rowsRiskReturn = [];
        Object.entries(data).map(([key, row]) => {

            if (key != '  CNPJ do Fundo') {
                rowsRiskReturn.push(<TableRow className={!isValidCnpj(key) ? 'benchmarkRow' : ''} key={'compare-fund-risco-retorno-fund-' + key}>
                    <TableCell align="left">{mapCnpjsFunds[key] && mapCnpjsFunds[key].fund_name ? mapCnpjsFunds[key].fund_name : key.toUpperCase()}</TableCell>
                    <TableCell align="center">{row.risco_retorno.cota ? applyMaskAmountComdinheiroFreeDecimals(row.risco_retorno.cota) : '-'}</TableCell>
                    <TableCell align="center">{row.dados_cadastrais && row.dados_cadastrais.pl_anbima ? applyMaskAmountComdinheiro(row.dados_cadastrais.pl_anbima) : '-'}</TableCell>
                    <TableCell align="center">{row.dados_cadastrais && row.dados_cadastrais.num_cotistas ? applyMaskAmountComdinheiro(row.dados_cadastrais.num_cotistas) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.volatilidade ? applyMaskAmountComdinheiro(row.risco_retorno.volatilidade) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.var ? applyMaskAmountComdinheiro(row.risco_retorno.var) : '-'}</TableCell>
                </TableRow>)
            }

        })

        return rowsRiskReturn;

    }

    function renderRowsFundRiskReturn2(data) {

        const rowsRiskReturn = [];
        Object.entries(data).map(([key, row]) => {
            if (key != '  CNPJ do Fundo') {
                rowsRiskReturn.push(<TableRow className={!isValidCnpj(key) ? 'benchmarkRow' : ''} key={'compare-fund-risco-retorno-fund-' + key}>
                    <TableCell align="left">{mapCnpjsFunds[key] && mapCnpjsFunds[key].fund_name ? mapCnpjsFunds[key].fund_name : key.toUpperCase()}</TableCell>
                    <TableCell align="center">{row.risco_retorno.perda_maxima ? applyMaskAmountComdinheiro(row.risco_retorno.perda_maxima) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.indice_sharpe ? applyMaskAmountComdinheiro(row.risco_retorno.indice_sharpe) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.retorno_maximo ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_maximo) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.retorno_minimo ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_minimo) : '-'}</TableCell>
                    <TableCell align="center">{row.risco_retorno.retorno_medio ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_medio) : '-'}</TableCell>
                </TableRow>)
            }

        })

        return rowsRiskReturn;

    }


    function formatArrysRiskAndReturn(tableData) {

        const returnTableData = [];

        Object.entries(tableData).map(([key, row]) => {
            if (key != '  CNPJ do Fundo') {

                row.fund_name = mapCnpjsFunds[key] && mapCnpjsFunds[key].fund_name ? mapCnpjsFunds[key].fund_name : key.toUpperCase();

                row.risco_retorno.cota = row.risco_retorno.cota ? applyMaskAmountComdinheiroFreeDecimals(row.risco_retorno.cota) : '-';

                if (row.dados_cadastrais) {
                    row.dados_cadastrais.pl_anbima = row.dados_cadastrais.pl_anbima ? applyMaskAmountComdinheiro(row.dados_cadastrais.pl_anbima) : '-';
                    row.dados_cadastrais.num_cotistas = row.dados_cadastrais.num_cotistas ? applyMaskAmountComdinheiro(row.dados_cadastrais.num_cotistas) : '-';
                } else {
                    row.dados_cadastrais = {
                        pl_anbima: '-',
                        num_cotistas: '-',
                    }
                }

                row.risco_retorno.volatilidade = row.risco_retorno.volatilidade ? applyMaskAmountComdinheiro(row.risco_retorno.volatilidade) : '-';
                row.risco_retorno.var = row.risco_retorno.var ? applyMaskAmountComdinheiro(row.risco_retorno.var) : '-';

                row.risco_retorno.perda_maxima = row.risco_retorno.perda_maxima ? applyMaskAmountComdinheiro(row.risco_retorno.perda_maxima) : '-';
                row.risco_retorno.indice_sharpe = row.risco_retorno.indice_sharpe ? applyMaskAmountComdinheiro(row.risco_retorno.indice_sharpe) : '-';
                row.risco_retorno.retorno_maximo = row.risco_retorno.retorno_maximo ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_maximo) : '-';
                row.risco_retorno.retorno_minimo = row.risco_retorno.retorno_minimo ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_minimo) : '-';
                row.risco_retorno.retorno_medio = row.risco_retorno.retorno_medio ? applyMaskAmountComdinheiro(row.risco_retorno.retorno_medio) : '-';

                if (!isValidCnpj(key)) {

                    row.isBenchmark = true;

                }

                returnTableData.push(row)
            }

        })

        return returnTableData;

    }

    async function doReport() {


        if (dataRentsChart && dataPatrimonyChart && allRiskReturnData) {

            const { valuesToRentsChart, labels: labelsRents } = getDataRentsChartByTypeAndPeriod(allRentsFundsData, 2, 1);
            const { valuesToPatrimonyChart, labels: labelsPatrimony } = getDataPatrimonyChartByPeriod(allPatrimonyData.byMonth, 2); //36 meses



            dispatch(allActions.reportLoadActions.setReportLoad({
                show: true,
                reportType: 'compareFunds',
                fundsInCompare: fundsInCompare,
                benchamrksInCompare: benchamrksInCompare,
                chartRentsMonthData: getDataRentsChart(valuesToRentsChart, labelsRents, mapCnpjsFunds),
                chartPlEvolutionData: getDataPatrimonioChart(valuesToPatrimonyChart, labelsPatrimony, mapCnpjsFunds),
                tableData: formatArrysRiskAndReturn(allRiskReturnData),
                message: 'Gerando comparativo...',

            }))
        } else {

        }



    }

    useEffect(() => {

        if (!allFunds) {
            doGetAllFunds();
            doGetAllBenchmarks();
        }

        // dispatch(allActions.innerLoadActions.setInnerLoad({
        //     show: true,
        //     emptyMessage: "Selecione os fundos que deseja comparar"
        // }));

    }, [clientOn]);

    useEffect(() => {
        if (fundsInCompareDivRef && fundsInCompareDivRef.current) {
            fundsInCompareDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }, [fundsInCompare, benchamrksInCompare])

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Comparar Fundos
                </Typography>

                <div className={classes.rightHeader}>

                    <MyAutoComplete
                        onChange={(event, newValue) => {
                            setFundSelectedToCompare(newValue);
                        }}
                        customWidth={'380px'}
                        options={allFunds}
                        autoComplete
                        label={'Nome do fundo ou CNPJ'}
                        filterOptions={(options, state) => {
                            if (!options) return [];
                            return options.filter(element => element.cnpj
                                .includes(state.inputValue)
                                || element.fund_name.toLowerCase().includes(state.inputValue.toLowerCase()));
                        }}
                        value={fundSelectedToCompare ? fundSelectedToCompare : null}
                        getOptionLabel={(option) => formatStringToDisplay(option.fund_name, 5, '...')}
                        renderOption={(option) => (
                            <React.Fragment key={'autocomplete-fund-compare-' + option.id}>
                                <span className={classes.bold}>{option.fund_name}</span>
                            </React.Fragment>
                        )}
                        renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                    />

                    <UnoButton
                        size={'small'}
                        style={{ marginLeft: '16px', marginRight: '16px' }}
                        onClick={() => addFundToCompare(fundSelectedToCompare)}
                        type='success'
                        startIcon={<Add />}>
                        {"FUNDO"}
                    </UnoButton>

                    <MyAutoComplete
                        onChange={(event, newValue) => {
                            setBenchmarkSelectedToCompare(newValue);
                        }}
                        customWidth={'170px'}
                        options={allBenchmarks}
                        autoComplete
                        label={'Benchmark'}
                        value={benchmarkSelectedToCompare ? benchmarkSelectedToCompare : null}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment key={'autocomplete-benchmark-' + option.id}>
                                <span className={classes.textAutoComplete}>{option.name}</span>
                            </React.Fragment>
                        )}
                        renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
                    />

                    <UnoButton
                        size={'small'}
                        style={{ marginLeft: '16px', }}
                        onClick={() => addBenchmarkToCompare(benchmarkSelectedToCompare)}
                        type='warning'
                        startIcon={<Add />}>
                        {"Benchmark"}
                    </UnoButton>

                    {
                        inCompare && dataPatrimonyChart && dataRentsChart
                            //&& checkUserIsRole(currentUser, [roles.superadmin]) 
                            ?
                            <UnoButton
                                isIconButton
                                onClick={() => doReport()}
                                size='small'
                                type='primary'
                            /> : null
                    }


                    {/* <Typography>{fundsInCompare && fundsInCompare.length + "/4"}</Typography> */}
                </div>
            </div>
            <div class={'defaultScrollWithHeaderContent'}>

                {(fundsInCompare && fundsInCompare.length) || (benchamrksInCompare && benchamrksInCompare.length) ?
                    <div className={classes.fundsInCompare}>

                        <Grid container spacing={2} ref={fundsInCompareDivRef}>
                            {renderFundsInCompare(fundsInCompare)}
                            {renderBenchmarksInCompare(benchamrksInCompare)}
                        </Grid>
                    </div>
                    : null}

                {/* {(fundsInCompare && fundsInCompare.length >= 1) || (benchamrksInCompare && benchamrksInCompare.length >= 1) ?
                    <div className={classes.dividerFunds}></div>
                    : null} */}

                {!inCompare ?
                    <div className={classes.comparativeLabel}>

                        {fundsInCompare && fundsInCompare.length >= 2 ?


                            <UnoButton
                                className={'compareButton'}
                                size={'small'}
                                style={{ marginRight: '16px' }}
                                onClick={() => doCompare(fundsInCompare, benchamrksInCompare)}
                                type='primary'>
                                {"Realizar comparativo"}
                            </UnoButton>


                            :
                            <Typography>{'Selecione os fundos que deseja comparar'}</Typography>

                        }
                    </div> : null}

                {inCompare ?
                    <div className={classes.compareCharts}>
                        <Grid container spacing={2}>

                            <Grid item lg={12} md={12} sm={12}>

                                {
                                    loadRentsFundsChart ?
                                        <div className={'loadChart loadPatrimony'}>
                                            <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                                            <Typography variant={'body1'} className={'title'}>Rentabilidades</Typography>
                                        </div>
                                        :
                                        <div className={classes.divChart}>
                                            {allRentsFundsData ?
                                                <div className={'chartHeader'}>

                                                    <Typography variant={'body1'} className={'title'}>Rentabilidades Mensais</Typography>
                                                    <div className={'right'}>
                                                        <MySelect
                                                            style={{ marginRight: '8px' }}
                                                            value={typeChartSelected}
                                                            verysmall
                                                            onChange={(e) => handleChangeTypeChartPeriod(e.target.value)}
                                                            options={typesChart.map((row, index) => (

                                                                <option key={'type-chart-' + index} value={index}>{row}</option>

                                                            ))}
                                                        />

                                                        <MySelect
                                                            value={periodRentsSelected}
                                                            verysmall
                                                            onChange={(e) => handleChangeChartRentsPeriod(e.target.value)}
                                                            options={periods.map((row, index) => (

                                                                <option key={'chart-period-rents-' + index} value={index}>{row}</option>

                                                            ))}
                                                        />
                                                    </div>
                                                </div> : null
                                            }

                                            {allRentsFundsData ?
                                                <div className={'chartWrapper2'}>
                                                    <Bar data={dataRentsChart} options={optionsChartRentsMonth} />
                                                </div>
                                                : null
                                            }



                                        </div>
                                }

                            </Grid>
                            <Grid item lg={12} md={12} sm={12}></Grid>
                            <Grid item lg={12} md={12} sm={12}>

                                {/* <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}> */}

                                {
                                    loadPatrimonyChart ?
                                        <div className={'loadChart loadPatrimony'}>
                                            <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                                            <Typography variant={'body1'} className={'title'}>Evolução de patrimônio</Typography>
                                        </div>
                                        :
                                        <div className={classes.divChart}>
                                            <div className={'chartHeader'}>

                                                <Typography variant={'body1'} className={'title'}>Evolução do Patrimônio (Em Milhões)</Typography>
                                                <div className={'right'}>
                                                    <MySelect
                                                        value={periodChartPatrimonioSelected}
                                                        verysmall
                                                        onChange={(e) => handleChangeChartPatrimonioPeriod(e.target.value)}
                                                        options={periods.map((row, index) => (

                                                            <option key={'chart-period-' + index} value={index}>{row}</option>

                                                        ))}
                                                    />
                                                </div>
                                            </div>


                                            <div className={'chartWrapper'}>
                                                <Bar data={dataPatrimonyChart} options={optionsChartPatrimonio} />
                                            </div>

                                        </div>
                                }

                                {/* </Paper> */}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12}></Grid>
                            <Grid item lg={12} md={12} sm={12}>


                                {
                                    loadRiskAndReturnTable ?
                                        <div className={'loadChart loadRiskReturn'}>
                                            <CircularProgress disableShrink className={classes.loadTop} color="inherit" />
                                            <Typography variant={'body1'} className={'title'}>Risco e Retorno</Typography>
                                        </div>
                                        :

                                        <div className={classes.divChart}>
                                            <div className={'chartHeader'}>
                                                <Typography variant={'body1'} className={'title'}>Tabelas de Risco x Retorno (12m)</Typography>
                                            </div>

                                            <TableContainer>
                                                <Table className={classes.tableBlade} size={'small'}>
                                                    <colgroup>
                                                        <col width="25%" />
                                                    </colgroup>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="center">Cota</TableCell>
                                                            <TableCell align="center">PL</TableCell>
                                                            <TableCell align="center">Cotistas</TableCell>
                                                            <TableCell align="center">Volatilidade</TableCell>
                                                            <TableCell align="center">VaR</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {allRiskReturnData ? renderRowsFundRiskReturn1(allRiskReturnData) : null}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <br />
                                            <TableContainer>
                                                <Table className={classes.tableBlade} size={'small'}>
                                                    <colgroup>
                                                        <col width="25%" />
                                                    </colgroup>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left"></TableCell>
                                                            <TableCell align="center">Perda Máx.</TableCell>
                                                            <TableCell align="center">Índice Sharpe</TableCell>
                                                            <TableCell align="center">Retorno Máx.</TableCell>
                                                            <TableCell align="center">Retorno Mín.</TableCell>
                                                            <TableCell align="center">Retorno Médio</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {allRiskReturnData ? renderRowsFundRiskReturn2(allRiskReturnData) : null}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                }

                            </Grid>


                        </Grid>
                    </div> : null}




            </div>
        </div >
    );
}



