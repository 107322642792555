import { ClientRiskDataAPI } from "../API/client_risk_data"


export const CLIENT_RISK_TYPES = {
    MARKET: 'market_data',
    ARTICLES: 'articles_data',
    BENCHMARKS: 'benchmarks_data',
    POLICY: 'policy_data',
}

export const ClientRiskDataController = {

    async createData(clientId, month, year) {

        const response = await ClientRiskDataAPI.createData(clientId, month, year);
        if (response.success) {

            console.log('createData risk: ', response);
            const responseToReturn = {
                able: true,

            }

            if (response.body?.rows?.[0]) {
                const responseData = response.body.rows[0];
                const {
                    articles_data,
                    benchmarks_data,
                    market_data,
                } = responseData;

                responseToReturn.data = {
                    articles_data: articles_data,
                    benchmarks_data: benchmarks_data,
                    market_data: market_data,
                }
            }

            return responseToReturn;

        } else {
            return null;
        }

    },

    async getLocalData(clientId, month, year, type) {

        let response = null;

        if (type === CLIENT_RISK_TYPES.MARKET) {
            response = await ClientRiskDataAPI.getClientRiskMarketData(clientId, month, year);
        } else if (type === CLIENT_RISK_TYPES.ARTICLES) {
            response = await ClientRiskDataAPI.getClientRiskArticlesData(clientId, month, year);
        } else if (type === CLIENT_RISK_TYPES.BENCHMARKS) {
            response = await ClientRiskDataAPI.getClientRiskBenchmarksData(clientId, month, year);
        } else if (type === CLIENT_RISK_TYPES.POLICY) {
            response = await ClientRiskDataAPI.getClientRiskPolicyData(clientId, month, year);
        }

        console.log('response data ' + type + ':  ', response);

        if (response && response.success && response.body.rows && response.body.rows[0]) {
            const data = response.body.rows[0];
            if (data[type]) {
                return JSON.parse(data[type]);
            } else {
                return null;
            }
        } else {
            return null;
        }

    },

    async saveData(clientId, month, year, type, data) {

        const response = await ClientRiskDataAPI.saveData(clientId, month, year, type, JSON.stringify(data));

        console.log('response market data: ', response);
        if (response.success) {
            console.log("SUCESSO LOCAL SAVE RISK DATA!")
        } else {
            console.log("FALHA LOCAL SAVE RISK DATA!")
        }

    }
}