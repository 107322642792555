
/*
{
    show: false,
    dataToDistriuitionChart: undefined,
    dataToDistribuitionReport: undefined,
    message: "",        
}
*
*/

const reportLoad = (state = null, action) => {
    switch (action.type) {
        case "SET_REPORT_LOAD":
            return action.payload
        default:
            return state
    }
}

export default reportLoad;