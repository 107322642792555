import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';

import { unoTheme, tabs, headerSearch } from '../../../assets/styles/unoTheme'


import { MyTabs, MyTab } from "../../utils/MyTabs";
import AdminArticlesTableScreen from "./AdminArticlesTableScreen"

import { Tooltip } from "@material-ui/core";

import MySelect from "../../utils/MySelect";

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

//Api
import { getResolutionVersions, listLegislationSegmentsByVersionId } from '../../../API/legislation_article'

//Router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    headerSearch: headerSearch,
    inputSearch: {
        width: "300px",
        background: "white"
    },
    tableTh: {
        //fontWeight: 'bold',
        background: unoTheme.mainColor,
        color: 'white',
    },
    selectableRow: {
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
            //padding: '3px 12px 3px 8px !important',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: unoTheme.secondaryTuplaTable,
        },
        '&:hover': {
            //color: 'white',
            background: unoTheme.hoverTable,
        }
    },

    table: {
        tableLayout: 'fixed',
        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                color: unoTheme.defaultTextColor,
                fontWeight: '500',
                textTransform: 'uppercase',
                fontSize: '12px',
                padding: '16px 24px 16px 16px',
            },
        },
    },

    overflow: {
        overflowY: 'scroll',
    },
    tableSideGraph: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableArticles: {
        width: '100%',

    },
    rightGraphs: {
        width: '30%',
    },
    clickAble: {
        cursor: 'pointer'
    },
    sectionTableCell: {
        display: 'table-cell',
        color: 'white',
        paddingBottom: theme.spacing(1),
    },
    multipleHorizontalTableCell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around',
        '& > div': {
            width: '33%',
        }
    },
    toogleButton: {
        maxHeight: '40px',
    },

    errorArticle: {
        color: unoTheme.errorColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .MuiSvgIcon-root': {
            fontSize: '20px',
            marginRight: '5px',
        }

    },
    tabs: tabs,
    shortWidthTab: {
        '& .MuiTab-root': {
            minWidth: '100px !important',
        }
    },
    customTabs: {
        width: 'auto !important',
    },
    fabEdit: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },
    fabDone: {
        position: 'absolute',
        backgroundColor: green[500],
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: green[700],
        }
    },

    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },


}));

export default function AdminLegislationArticlesScreen() {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [resolutionsVersions, setResolutionsVersions] = useState(null);
    const [resolutionVersionSelected, setResolutionVersionSelected] = useState(null);

    const [tabs, setTabs] = React.useState(null);
    const [legislationSegments, setListLegislationSegments] = useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [editTargets, setEditTargets] = React.useState(false);

    const handleChangeTabValue = (event, newValue) => {

        setTabValue(newValue);

        // if (newValue == 0) {
        //     history.push('/administration/admin_legislation_articles/fixed')
        // } else if (newValue == 1) {
        //     history.push('/administration/admin_legislation_articles/variable')
        // } else {
        //     history.push('/administration/admin_legislation_articles/external')
        // }

    };

    const handleChangeResoltuoinVersionSelected = (value) => {

        //console.log("YEAR: ", yearChartSelected);
        setResolutionVersionSelected(value);
        loadSegments(resolutionsVersions[value].id);

    };

    async function doGetResolutionVersions() {

        let responseResolutionsVersions = await getResolutionVersions();

        if (responseResolutionsVersions.success
            && responseResolutionsVersions.body
            && responseResolutionsVersions.body.rows
            && responseResolutionsVersions.body.rows.length > 0) {

            setResolutionsVersions(responseResolutionsVersions.body.rows);
            setResolutionVersionSelected(1);

            loadSegments(responseResolutionsVersions.body.rows[1].id);

        } else {
            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar versões de resolução'
            }))
        }

    }

    async function loadSegments(resolutionVersionId) {

        let responseLegislationSegments = await listLegislationSegmentsByVersionId(resolutionVersionId);

        if (responseLegislationSegments.success) {

            console.log("SEGMENTS: ", responseLegislationSegments.body.rows);
            let myTabs = [];
            setListLegislationSegments(responseLegislationSegments.body.rows);
            responseLegislationSegments.body.rows.forEach(segment => {

                myTabs.push(<MyTab label={segment.description} />);

            });
            setTabs(myTabs);

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                show: true,
                type: 'error',
                message: 'Falha ao listar os segmentos de legislação'
            }))

        }
    }

    useEffect(() => {

        if (!resolutionsVersions) {

            doGetResolutionVersions();
        }

    }, [])

    return (
        <div className={classes.root}>



            <div className={classes.tabsWithRight}>

                <div className={[classes.tabs, classes.shortWidthTab].join(' ')}>

                    {
                        tabs ?

                            <MyTabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleChangeTabValue}>

                                {tabs}
                            </MyTabs>
                            : null

                    }
                </div>


                <MySelect
                    style={{ marginLeft: '16px', width: '120px' }}
                    value={resolutionVersionSelected}
                    verysmall
                    onChange={(e) => handleChangeResoltuoinVersionSelected(e.target.value)}
                    options={resolutionsVersions && resolutionsVersions.map((row, index) => (

                        <option key={'resolution-version-' + index} value={index}>{row.resolution_name}</option>

                    ))}
                />

                {/* <div style={{ marginLeft: '16px' }}>

                    <span>{resolutionsVersions && resolutionsVersions[resolutionVersionSelected] ? resolutionsVersions[resolutionVersionSelected].description : ''}</span>

                </div> */}


                {/* <div className={classes.bottomTabs}></div> */}
            </div>

            <Switch className={classes.switch}>
                <Route path="/administration/admin_legislation_articles/list">
                    <AdminArticlesTableScreen
                        legislationSegments={legislationSegments}
                        segment={legislationSegments[tabValue] ? legislationSegments[tabValue] : null}
                        resolutionVersionSelected={resolutionsVersions ? resolutionsVersions[resolutionVersionSelected] : null} />
                </Route>
            </Switch>

        </div>
    );
}



