import API from './config'

export const DistributorsAPI = {

    async getAll(search) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/distributors/getAll${search ? `?search=${search}` : ""}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async getClientFundDistributors(fundId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/distributors/getClientFundDistributors/${fundId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async save(distributor) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = distributor.id ?
                await API.put(`/distributors/update`, distributor, config)
                :
                await API.post(`/distributors/create`, distributor, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async createClientFundDistributor(clientFundDistributor) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response =
                await API.post(`/distributors/createClientFundDistributor`, clientFundDistributor, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async deleteClientFundDistributor(clientFundDistributorId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response =
                await API.delete(`/distributors/deleteClientFundDistributor/${clientFundDistributorId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async delete(distributorId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.delete(`/distributors/delete/${distributorId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },


}