import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { updatePassByHash } from '../../API/user';

import Banner from '../../assets/imgs/banner_uno.png';

import { CircularProgress, InputAdornment } from '@material-ui/core';
import { unoTheme } from '../../assets/styles/unoTheme';
import MyTextField from '../utils/MyTextField';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import LogoLema from '../../assets/imgs/LEMA/logop.png';
import UnoButton from '../utils/UnoButton';
import { isValidEmail, justNumbers } from '../utils/utils';
import { sendContactEmail } from '../../API/lema';

const useStyles = makeStyles((theme) => ({

    image: {
        backgroundImage: 'url(' + Banner + ')',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    inputGrid: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: '200px',
        marginBottom: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        width: '100%'
    },
    rightDivBackdrop: {
        backgroundColor: 'white',
        position: 'absolute',
        opacity: 0.7,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& .progress': {
            color: unoTheme.mainColor
        }
    },

    forgotPassword: {
        color: unoTheme.mainColor,
        textAlign: 'right',
        fontSize: '0.9em',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        }
    },

    resetDiv: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#130f40",


        '& .formReset': {
            padding: '32px 0px',
            overflowY: "scroll",
            height: '100%',
            '& .paperForm': {
                padding: '32px'
            }
        },

    }



}));

export default function ContactLema() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [office, setOffice] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');

    const [submmited, setSubmmited] = useState(false);

    const [success, setSuccess] = useState(false);

    const [processing, setProcessing] = useState(false);

    async function doResetPass() {

        setProcessing(true);

        setSubmmited(true);

        if (name
            && isValidEmail(email)
            && (justNumbers(phone).length >= 10)) {

            const responseContact = await sendContactEmail(name, email, phone, office, city, message);
            if (responseContact.success) {
                setSuccess(true);
            }
        }

        setProcessing(false);

    }

    useEffect(() => {



    }, []);

    return (
        <div className={classes.resetDiv}>

            {
                !success ?
                    <>
                        <Grid container className={'formReset'} square>
                            <Grid item xs={0} sm={3} md={4}>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}
                                className={'paperForm'}
                                component={Paper} elevation={6}>
                                <img className={classes.logo}
                                    src={LogoLema}></img>
                                <br />

                                <Typography>Preencha o formulário para que nossos consultores possam entrar em contato</Typography>
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={name}
                                    onChange={(value) => setName(value)}
                                    autoFocus={true}
                                    label="Nome completo*"
                                    helperText={'O nome é requerido'}
                                    validator={!name && submmited ? true : false} />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={email}
                                    onChange={(value) => setEmail(value)}
                                    autoFocus={true}
                                    label="E-mail*"
                                    helperText={'O email é requerido'}
                                    validator={!isValidEmail(email) && submmited ? true : false} />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={phone}
                                    onChange={(value) => setPhone(value)}
                                    autoFocus={true}
                                    label="DDD + Celular*"
                                    mask={['phone']}
                                    helperText={'O número de celular é requerido'}
                                    validator={justNumbers(phone).length < 10 && submmited ? true : false} />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={office}
                                    onChange={(value) => setOffice(value)}
                                    autoFocus={true}
                                    label="Cargo no RPPS" />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={city}
                                    onChange={(value) => setCity(value)}
                                    autoFocus={true}
                                    label="Município + UF" />
                                <br />
                                <br />
                                <MyTextField
                                    disabled={processing}
                                    value={message}
                                    onChange={(value) => setMessage(value)}
                                    autoFocus={true}
                                    multiline={true}
                                    rows={5}
                                    rowsMax={5}
                                    label="Mensagem (opcional)" />
                                <br />
                                <br />
                                <br />
                                <UnoButton
                                    disabled={processing}
                                    onClick={() => doResetPass()}
                                    fullWidth={true}
                                    type='primary'>
                                    {!processing ? "Enviar" : <div style={{ display: "flex", alignItems: 'center', }}>Enviando... <CircularProgress style={{ marginLeft: '16px' }} size={24}
                                        disableShrink color="inherit" /></div>}
                                </UnoButton>


                                <div style={{ marginTop: '16px' }}>Ou entre em contato: <span style={{ fontWeight: 'bold' }}>85 99868-3664</span></div>


                            </Grid>
                            <Grid item xs={0} sm={3} md={4}>
                            </Grid>

                        </Grid>
                    </>
                    :
                    <Paper elevation={3} style={{ padding: '24px 32px' }}>
                        <img className={classes.logo}
                            src={LogoLema}></img>
                        <br />
                        <Typography component="legend">Formulário enviado com sucesso 🎉</Typography>
                        <Typography>Aguarde nosso contato!</Typography>
                    </Paper>
            }

        </div>

    );
}