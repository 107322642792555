import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

//Api
import { listAllLegislationArticles } from '../../../API/legislation_article'
import { createFund, getAllFunds } from '../../../API/fund'
import { getQuotas, getToken } from '../../../API/quotas-api'

//redux
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../actions";

import { unoTheme, headerSearch } from '../../../assets/styles/unoTheme'
import UnoButton from "../../utils/UnoButton";

//Import excel
import XLSX from 'xlsx';
import { SheetJSFT } from '../../utils/types';
import { getJsDateFromExcel } from 'excel-date-to-js'
import PublishIcon from '@material-ui/icons/Publish';

import MyInnerLoad from '../../utils/MyInnerLoad'
import { justNumbers } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    input: {
        display: 'none',
    },
    headerSearch: headerSearch,
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

}));

export default function AnbimaFundsScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const clientOn = useSelector(state => state.clientOn)
    const [listFunds, setListFunds] = useState(undefined);
    const [itemsPage, setItemsPage] = useState(15);
    const [page, setPage] = useState(1);

    const [searchText, setSearchText] = useState("");

    const delayedChange = (text) => {
        setTimeout(() => {
            setSearchText(text)
        }, 800)
    }

    function changeSearch(e) {

        delayedChange(e.target.value.toUpperCase())
        clearTimeout(delayedChange);
    }

    async function getAllFundsAnbima() {

        const response = await getQuotas();

        if (response.success) {
            console.log("Fundos anbima obtidos com sucesso!");

            setListFunds(response.body)
            let anbimaFunds = response.body;

            let responseListFunds = await getAllFunds();
            let fundsUnoWithCod = [];
            let count = 0;

            if (responseListFunds.success) {

                let unoFunds = responseListFunds.body.rows;

                anbimaFunds.forEach(anbimaFund => {

                    unoFunds.forEach(unoFund => {

                        let cnpjUno = justNumbers(unoFund.cnpj)
                        // console.log("#############");
                        // console.log("Comparando 1: ", anbimaFund.cnpj_fundo);
                        // console.log("Comparando 2: ", cnpjUno);
                        if (anbimaFund.cnpj_fundo == cnpjUno) {

                            unoFund.anbima_cod = anbimaFund.codigo_fundo;

                            console.log("Adicionando [" + count + "]" + ": ", unoFund);

                            fundsUnoWithCod.push(unoFund);
                        }

                        count++;

                    });

                });

                console.log("UNO FUNDS WITH CODs: ", fundsUnoWithCod);

            } else {
                dispatch(allActions.mainAlertActions.setMainAlert({
                    show: true,
                    type: 'error',
                    message: "Falha ao obter lista de fundos"
                }))
            }

        } else {
            console.log("RESPONSE ERROR: ", response.error)
            if (response.body == "RENOVAR_TOKEN") {
                const tryNewToken = await getToken();

                if (tryNewToken.success) {

                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'success',
                        message: 'Sucesso ao obter novo token'
                    }))

                    localStorage.setItem("anbimaToken", tryNewToken.body.access_token)
                    getAllFunds();

                } else {
                    dispatch(allActions.mainAlertActions.setMainAlert({
                        show: true,
                        type: 'error',
                        message: 'Falha ao obter token'
                    }))
                }
            }
        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));
    }

    function goToDetailQuota(quota) {

        history.push(
            {
                pathname: "/quotaDetail",
                state: { quota: quota }
            }
        )
    }

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            loadMessage: 'Carregando informações...',
        }));
        getAllFundsAnbima();

    }, [])

    return (
        <div className={classes.root}>

            <div className={classes.headerSearch}>

                <Typography variant="h6" gutterBottom align="left">
                    Fundos
                </Typography>
                <div className={classes.rightHeader}>

                    {
                        listFunds && listFunds.length > 0 ?
                            <>
                                <TextField id="standard-basic"
                                    onChange={(event) => changeSearch(event)}
                                    className={classes.inputSearch}
                                    label="Buscar..."
                                    variant="outlined"
                                    size="small" />

                            </> : null
                    }

                </div>

            </div>
            <div class={'defaultScrollWithHeaderContent'}>
                {
                    listFunds ?

                        <MyTable
                            pagination={searchText == '' && listFunds && listFunds.length > 0}
                            itemspage={itemsPage}
                            totalitems={listFunds ? listFunds.length : 0}
                            handlePage={(value) => setPage(page + value)}
                            stickyHeader
                            selectable="true"
                            size="small">
                            <colgroup>
                                {/* <col width="5%" /> */}
                                <col width="10%" />
                                <col width="50%" />
                                <col width="25%" />
                                <col width="15%" />
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell className={classes.tableTh} align="center">Cod</TableCell> */}
                                    <TableCell className={classes.tableTh} align="center">Código Fundo</TableCell>
                                    <TableCell className={classes.tableTh} align="left">Nome do fundo</TableCell>
                                    <TableCell className={classes.tableTh} align="center">CNPJ</TableCell>
                                    <TableCell className={classes.tableTh} align="center">Segmento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(searchText == '' ? listFunds
                                    .slice(
                                        (page * itemsPage) - (itemsPage),
                                        itemsPage + (itemsPage * (page - 1))
                                    ) : listFunds).map((row, index) => (
                                        <>
                                            {
                                                searchText == "" || (
                                                    row.cnpj_fundo.includes(searchText)
                                                    || row.nome_fantasia.includes(searchText)) ?
                                                    <TableRow
                                                        className={classes.quotaRow}
                                                        key={'fund-' + index}
                                                        onClick={() => goToDetailQuota(row)}>
                                                        {/* <TableCell align="center">{row.id}</TableCell> */}
                                                        <TableCell align="center">{row.codigo_fundo}</TableCell>
                                                        <TableCell align="left" component="th" scope="row">{row.nome_fantasia}</TableCell>
                                                        <TableCell align="center">{row.cnpj_fundo}</TableCell>
                                                        <TableCell align="center">{
                                                            row.classe_anbima == 'Renda Fixa' ?
                                                                'RF'
                                                                :
                                                                <>
                                                                    {
                                                                        row.classe_anbima == 'Renda Variável' ?
                                                                            'RV'
                                                                            :
                                                                            'IE'
                                                                    }
                                                                </>
                                                        }</TableCell>
                                                    </TableRow> : null
                                            }
                                        </>
                                    ))}
                            </TableBody>
                        </MyTable>

                        :
                        <>
                            <MyInnerLoad message={'Carregando fundos...'} />
                        </>
                }


            </div>

        </div >
    );
}



