import API from './config'

export const SurveysAPI = {

    async getShowOrNotBySurveyIdAndClientId(surveyId, clientId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.get(`/surveys/getShowOrNotBySurveyIdAndClientId?survey_id=${surveyId}&client_id=${clientId}`, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

    async postClick(surveyId, clientId, userId) {
        const config = {
            headers: {
                'x-access-token': localStorage.getItem('token'),
            }
        }

        try {

            const response = await API.post(`/surveys/postClick`, {
                survey_id: surveyId,
                client_id: clientId,
                user_id: userId,
            }, config);

            return {
                success: true,
                body: response.data
            };

        } catch (error) {

            return {
                success: false,
                body: error.response.data
            };
        }
    },

}