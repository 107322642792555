const fullLoad = (state = {
    show: false,
    message: "",
}, action) => {
    switch (action.type) {
        case "SET_FULL_LOAD":
            return action.payload
        default:
            return state
    }
}

export default fullLoad;