import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MyTextField from '../../utils/MyTextField';
import MyTable from '../../utils/MyTable';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import _ from 'lodash';

import moment from 'moment';

//redux
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../../../actions'

import { unoTheme } from '../../../assets/styles/unoTheme'


import { applyMaskAmount, applyMaskAccount } from "../../utils/utils";

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { checkUserIsRole, roles } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    green: {
        color: unoTheme.greenMoney,
    },
    red: {
        color: unoTheme.errorColor,
    },

    blue: {
        color: 'blue',
    },

    ok: {
        color: unoTheme.greenMoney,

        fontSize: '1.2em',

    },
    pendent: {
        color: unoTheme.warningColor,

        fontSize: '1.2em',

    },

    invalidTransaction: {
        background: '#ff7878 !important',
    }
}));

export default function TransactionsTable(props) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { transactions, clientAccounts, clientFunds, clientAssets, clientOperations } = props;

    const currentUser = useSelector(state => state.currentUser)

    return (

        <MyTable
            stickyHeader
            selectable="true"
            size="small">
            <colgroup>
                <col width="10%" />
                <col width="40%" />
                <col width="10%" />
                <col width="10%" />
                <col width="25%" />
                <col width="10%" />
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell align='center'>Data</TableCell>
                    <TableCell align='left'>Fundo</TableCell>
                    <TableCell align='center'>Operação</TableCell>
                    <TableCell align="center">Conta</TableCell>
                    <TableCell align='right'>Valor</TableCell>
                    <TableCell align="center">APR</TableCell>
                </TableRow>
            </TableHead>
            {transactions ?
                <TableBody>
                    {transactions.map((transactionRow, index) => (
                        <TableRow
                            className={transactionRow.invalidTransaction ? classes.invalidTransaction : null}
                            key={'apr-transaction-' + index}
                            onClick={() =>
                                //Verificar role
                                checkUserIsRole(currentUser,
                                    [roles.admin,
                                    roles.superadmin,
                                    roles.advisor]) ?

                                    dispatch(allActions.rightContentActions.setRightContent({
                                        path: 'newTransaction',
                                        update: true,
                                        transactionToUpdate: transactionRow,
                                        data: {
                                            accounts: clientAccounts,
                                            funds: clientFunds,
                                            assets: clientAssets,
                                            operations: clientOperations,
                                        }
                                    }))
                                    : null
                            }>
                            <TableCell align="center">{moment.utc(transactionRow.transaction_date).format("DD/MM/YYYY")}</TableCell>
                            <TableCell align="left">{transactionRow.fund_name}</TableCell>
                            <TableCell align="center">{transactionRow.operation}</TableCell>
                            <TableCell align="center">{applyMaskAccount(transactionRow.account)}</TableCell>
                            <TableCell align="right" className={
                                transactionRow.operation == 'Aplicação' ? classes.green : (
                                    transactionRow.operation == 'Resgate' ? classes.red : classes.blue
                                )
                            }>{applyMaskAmount(transactionRow.amount, true)}</TableCell>
                            <TableCell
                                align="center">
                                {
                                    transactionRow.apr_description_id || (transactionRow.apr_description != "" && transactionRow.apr_description != null) ?

                                        <CheckCircleIcon className={classes.ok} style={{ verticalAlign: 'middle' }} />
                                        :
                                        <ReportProblemIcon className={classes.pendent} style={{ verticalAlign: 'middle' }} />
                                }

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                :
                null
            }

        </MyTable>
    );
}



