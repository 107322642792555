// * Docs
import FundsAndTitlesScreen from './index';

// Imports
import { makeStyles } from "@material-ui/core";
import { headerSearch, tabs, unoTheme } from "../../../../assets/styles/unoTheme";

/**
 * Refere-se a {@link FundsAndTitlesScreen}.
 */
export const useIndexStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    tabsWithRight: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: theme.spacing(2),
        alignItems: 'center',

        '& .MuiToggleButtonGroup-root': {
            alignItems: 'center',

            '& .MuiToggleButton-label': {
                whiteSpace: 'nowrap'
            }
        }
    },
    tabs: tabs,
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            marginLeft: theme.spacing(2)
        },
        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },

    flexDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    /**
     * ! Está comentada em {@link FundsAndTitlesScreen}
     */
    bottomTabs: {
        height: '24px',
        width: '100%',
        background: unoTheme.menuContent,
    },
}));

export const useFundsAdministrationStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    input: {
        display: 'none',
    },
    headerSearch: headerSearch,
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

    fabAdd: {
        position: 'absolute',
        backgroundColor: unoTheme.mainColor,
        right: theme.spacing(4),
        bottom: unoTheme.bottomFab,
        '&:hover': {
            background: unoTheme.hoverMainButtonColor,
        }
    },

}));

export const useTitlesStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    green: {
        color: 'green'
    },
    red: {
        color: 'red',
    },
    iconButton: {
        padding: '5px',
    },
    input: {
        display: 'none',
    },
    headerSearch: headerSearch,
    rightHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    inputSearch: {
        width: "300px",
        background: "white"
    },
    spacingLeft2: {
        marginLeft: theme.spacing(2)
    },

}));