import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

//Redux
import { useDispatch } from "react-redux";
import allActions from "../../../actions";

import { headerSearch, toogleButton, unoTheme } from "../../../assets/styles/unoTheme";

import MyInfo from "../../utils/MyInfo";

import UnoButton from "../../utils/UnoButton";
import {
    applyMaskAmount, applyMaskAmountComdinheiro,
    formatMillionNumber, getFirstBeforeBusinessDay,
    getLastsMonthsInArray,
    getTheLastDayCompleteMonthPt, isMonthAbleToQuotas
} from "../../utils/utils";

//Charts
import { Bar } from 'react-chartjs-2';

import MySelect from '../../utils/MySelect';

import { getBladeComdinheiroByDate } from '../../../controllers/ComdinheiroController';


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import moment from 'moment';

const optionsCompareChart = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel) + "%";
            }
        }
    },

    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    return value + "%";
                }
            }
        }],
    },

}

const optionsChartPatrimonio = {
    // title: {
    //     display: true,
    //     text: 'Polveri',
    //     fontSize: 18
    // },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        fullWidth: true,
    },
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                    //return applyMaskAmount(value, true);
                    // return formatMillionNumber(value);
                    return formatMillionNumber(value);
                }
            }
        }],
    },

    tooltips: {
        callbacks: {
            label: function (tooltipItem) {
                return applyMaskAmount(tooltipItem.yLabel, true);
            }
        }
    },

}

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${unoTheme.topToolbarSize})`,
        overflowY: 'scroll',
        paddingBottom: theme.spacing(3),
    },

    main: {
        width: 'calc(100% - 24px)',
    },

    generalInformations: {
        //padding: theme.spacing(3),
        //margin: '-24px',
        '& .mainInfos': {
            '& .myInfo': {
                '& .MuiTypography-root': {
                    fontSize: '13px',
                }

            }
        },

        '& .myInfo': {
            padding: '6px 0px',
            borderBottom: 'solid 1px #E4E7EB'
        },

        '& .myInfo:last-child': {
            padding: '6px 0px',
            borderBottom: 'none'
        },

    },

    gridPaper: {
        height: '100%',
        borderRadius: '10px',
        padding: theme.spacing(3),

        '& .title': {
            color: '#768191',
        },
    },

    gridPaperTable: {
        borderRadius: '10px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),

        '& .title': {
            color: '#768191',
        },

        '& .MuiTypography-root': {
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        }
    },

    headerSearch: headerSearch,

    labelHeader: {
        fontSize: '16px',
        color: unoTheme.defaultTextColor,
        fontWeight: 600,
    },
    switchs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    progressInput: {
        width: '20px !important',
        height: '20px !important',
    },
    selectable: {
        '& input': {

            cursor: 'pointer',
            color: unoTheme.mainColor,
        }
    },
    toogleButton: toogleButton,

    divChart: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        //display: 'flex',
        //alignItems: 'center',
        //flexDirection: 'column',
        justifyContent: 'center',
        // paddingTop: theme.spacing(3),
        // paddingLeft: theme.spacing(3),
        // paddingRight: theme.spacing(3),
        // paddingBottom: theme.spacing(3),

        '& .chartHeader': {
            minHeight: '56px',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            //paddingTop: theme.spacing(2),
            paddingBottom: '12px',
            paddingRight: '13px',

            '& .MuiTypography-root': {
                marginRight: theme.spacing(1),
            },

            '& .right': {
                display: 'flex',
                alignItems: 'center',
            }
        },

        '& .chartWrapper': {
            height: '350px',
            width: '100%',
        }


        // '& canvas': {
        //     width: '600px !important',
        //     [theme.breakpoints.down(800)]: {
        //         width: '400px !important',
        //     }
        // }
    },

    tableBlade: {

        tableLayout: 'fixed',


        '& .MuiTableRow-head': {
            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '4px 4px',
                fontWeight: "bold",
                color: '#1C2228',
                wordWrap: 'break-word',
            },

            '& .MuiTableCell-root:first-child': {
                paddingLeft: '16px',
                borderRight: 'solid 1.5px #E4E7EB'
            },
            '& > *': {
                borderBottom: 'unset',
            },
        },

        '& .MuiTableBody-root': {

            '& .MuiTableRow-root': {
                borderRadius: '10px',
                '&:nth-of-type(odd)': {
                    backgroundColor: unoTheme.secondaryTuplaTable,
                    //backgroundColor: 'red',
                },

                '&:nth-of-type(even)': {
                    backgroundColor: unoTheme.mainColor,
                    color: 'white',

                    '& .MuiTableCell-root': {
                        color: 'white',
                    },
                    //backgroundColor: 'red',
                },

                '& > *': {
                    borderBottom: 'unset',
                },
            },

            '& .MuiTableCell-root': {
                fontSize: '12px',
                padding: '12px 4px',
                color: '#768191',
            },

            '& .MuiTableCell-root:first-child': {
                borderRight: 'solid 1.5px #E4E7EB',
                paddingLeft: '16px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
            },

            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
            },
        },


    }


}));

export default function BladeFundScreen() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useLocation();
    const history = useHistory();

    const [fundScreen, setFundScreen] = useState(undefined);

    const [referenceDate, setReferenceDate] = useState(null);
    const [periods, setPeriods] = useState(['12 meses', '24 meses', '36 meses']);

    const [dataRetornosChart, setDataRetornosChart] = useState(null);
    const [periodChartSelected, setPeriodChartSelected] = useState(null);

    const [dataPatrimonioChart, setDataPatrimonioChart] = useState(null);
    const [periodChartPatrimonioSelected, setPeriodChartPatrimonioSelected] = useState(null);

    const [typesChart, setTypesChart] = useState(['Valores mensais', 'Valores acumulados']);
    const [typeChartSelected, setTypeChartSelected] = useState(null);

    const handleChangeTypeChartPeriod = (value) => {

        setTypeChartSelected(value);
        formatDataToRetornosChart(fundScreen['comdinheiro'].returnTable, fundScreen.last12LabelsMonths, value);

    };

    const handleChangeChartPeriod = (value) => {

        setPeriodChartSelected(value);

        formatDataToRetornosChart(periods[value], fundScreen.returnTable, typeChartSelected);

    };

    const handleChangeChartPatrimonioPeriod = (value) => {

        setPeriodChartPatrimonioSelected(value);
        formatDataToPatrimonioChart(fundScreen.comdinheiro.patrimonio36.byMonth, parseInt(value), fundScreen.cnpj);

    };

    const [formats, setFormats] = useState(() => '12');
    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    function getDataRetornosChart(d1, d2, labelMonths) {

        console.log("GET DATA CHART: ", d1);
        console.log("GET DATA CHART: ", d2);

        let dataChartInflationRates = {
            labels: labelMonths,
            datasets: [
                {
                    label: 'Fundo',
                    backgroundColor: "transparent",
                    borderColor: unoTheme.chartColors[1],
                    pointBackgroundColor: unoTheme.chartColors[1],
                    pointBorderColor: unoTheme.chartColors[1],
                    pointHoverBackgroundColor: unoTheme.chartColors[1],
                    pointHoverBorderColor: unoTheme.chartColors[1],
                    data: d1,
                    type: 'line',
                },
                {
                    label: 'Bechmark',
                    backgroundColor: unoTheme.chartColors[0],
                    data: d2
                },


            ]
        };

        return dataChartInflationRates;
    }

    function formatDataToRetornosChart(data, labelMonths, type) {

        console.log("FORMAT DATA: ", data);

        //Inicializando select de chart year        
        let dataChartFund = [];
        let dataChartBenchmark = [];

        //Valores mensais
        if (type == 0) {

            for (let m = 0; m < labelMonths.length; m++) {

                let key = labelMonths[m];

                if (m == 11) {
                    key = "Mes_atual"
                }

                dataChartFund.push({
                    y: data['fund'][key],
                    id: 'm-fund-' + key
                })
                dataChartBenchmark.push(data['benchmark'][key])



            }

        } else {

            //Valores acumulados

            let acumValueFund = 1;
            let acumValueBenchmark = 1;
            for (let m = 0; m < labelMonths.length; m++) {

                let key = labelMonths[m];

                if (m == 11) {
                    key = "Mes_atual"
                }

                acumValueFund *= data['fund'][key] ? (1 + (data['fund'][key] / 100)) : 1;
                acumValueBenchmark *= data['benchmark'][key] ? (1 + (data['benchmark'][key] / 100)) : 1;

                dataChartFund.push({
                    y: parseFloat(parseFloat((acumValueFund - 1) * 100).toFixed(2)),
                    id: 'm-fund-' + key
                })
                dataChartBenchmark.push(parseFloat(parseFloat((acumValueBenchmark - 1) * 100).toFixed(2)))



            }
        }



        setDataRetornosChart(
            getDataRetornosChart(dataChartFund,
                dataChartBenchmark, labelMonths));

    }

    function getDataPatrimonioChart(values, labels) {

        let dataChartPatrimonio = {
            labels: labels,
            datasets: [
                {
                    label: 'Fundo',
                    backgroundColor: "transparent",
                    borderColor: unoTheme.chartColors[1],
                    pointBackgroundColor: unoTheme.chartColors[1],
                    pointBorderColor: unoTheme.chartColors[1],
                    pointHoverBackgroundColor: unoTheme.chartColors[1],
                    pointHoverBorderColor: unoTheme.chartColors[1],
                    data: values,
                    type: 'line',
                },
            ]
        };

        return dataChartPatrimonio;
    }

    function formatDataToPatrimonioChart(data, period, cnpj) {

        console.log("DATA PATRIMONIO: ", data);
        console.log("CNPJ: ", cnpj);


        //Inicializando select de chart year        
        let dataChartPatrimonio = [];
        let labels = [];

        //O período pode ser 0, 1 ou 2 corresponde ao valor do select por isso deve ser acrescido de 1
        let resultPeriod = ((period + 1) * 12);
        console.log("Result period: ", resultPeriod);
        let dataSlice = data.slice(36 - resultPeriod, 37)
        console.log("FORMAT DATA: ", dataSlice);

        //Fazendo o slice com o período 12, 24, 36
        dataSlice.forEach(value => {
            dataChartPatrimonio.push({
                y: value[cnpj],
            })
            labels.push(value.data);
        });


        setDataPatrimonioChart(getDataPatrimonioChart(dataChartPatrimonio, labels));

    }

    async function loadInfos(fund, dispatch) {

        let prevReferenceDate = getFirstBeforeBusinessDay(getTheLastDayCompleteMonthPt());

        console.log("LAST DAY COMPLETE MONTH: ", prevReferenceDate);

        const splittedreferenceDate = prevReferenceDate.split('/');
        const m = parseInt(splittedreferenceDate[1]);
        const y = parseInt(splittedreferenceDate[2]);
        if (!isMonthAbleToQuotas(m, parseInt(y))) {
            if (m == 1) {
                prevReferenceDate = moment(new Date(y - 1, 12, 0)).format('DD/MM/YYYY');
            } else {
                prevReferenceDate = moment(new Date(y, m - 1, 0)).format('DD/MM/YYYY');
            }
        }

        setReferenceDate(prevReferenceDate);

        const response = await getBladeComdinheiroByDate(prevReferenceDate, fund, dispatch);

        if (response.success) {

            fund = {
                ...fund,
                ...response.body
            }

            //obtendo labels dos últimos 12 meses
            fund.last12LabelsMonths = getLastsMonthsInArray(prevReferenceDate, 12);


            if (fund.comdinheiro && fund.comdinheiro.patrimonio36) {

                formatDataToPatrimonioChart(fund.comdinheiro.patrimonio36.byMonth, 0, fund.cnpj);
                formatDataToRetornosChart(fund.comdinheiro.returnTable, fund.last12LabelsMonths, 0)
                setFundScreen(fund);
            }

        } else {

            dispatch(allActions.mainAlertActions.setMainAlert({
                message: "Falha ao obter informações da lâmina",
                type: "error",
                show: true
            }));

            history.goBack();

        }

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: false,
        }));

    }

    function showRegulamento(link) {
        window.open(link)
    }

    useEffect(() => {

        dispatch(allActions.innerLoadActions.setInnerLoad({
            show: true,
            //extraMargin: true,
            loadMessage: 'Carregando informações...',
        }));


        if (location && location.state) {

            console.log("FUND ALL: ", location.state);
            const fund = {

                ...location.state.fund,
                fundId: location.state.fund.id ?? location.state.fund.fundId,

                //tabela de retorno
                returnTable: [
                    {
                        return: 'Fundo',
                    },
                    {
                        return: '',
                    }
                ],

                lastYearLabelsMonths: [
                ]

            };

            loadInfos(fund, dispatch)
        }

    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.headerSearch}>

                <Typography variant="h6" align="left" style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    {location && location.state && location.state.fund ? location.state.fund.fund_name : ''}

                    {
                        fundScreen && fundScreen.cnpj && fundScreen['comdinheiro'] ?
                            <CheckCircleIcon style={{
                                marginLeft: '5px',
                                color: unoTheme.greenMoney,
                                fill: unoTheme.greenMoney
                            }} />

                            :

                            <>
                                {
                                    fundScreen ?
                                        <ReportProblemIcon style={{
                                            marginLeft: '5px',
                                            color: unoTheme.warningColor,
                                            fill: unoTheme.warningColor,
                                        }} /> : null
                                }

                            </>
                    }

                </Typography>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        referenceDate ?
                            <Typography variant={'body2'} style={{ marginRight: '16px' }}>
                                {"Data de referência: " + referenceDate}
                            </Typography> : null
                    }

                    {

                        fundScreen
                            && fundScreen['dadosCadastrais']
                            && fundScreen['dadosCadastrais'].link_regulamento != undefined
                            && fundScreen['dadosCadastrais'].link_regulamento != "" ?

                            <UnoButton
                                style={{ marginRight: '16px' }}
                                size={'small'}
                                className={classes.spacingLeft1}
                                onClick={() => showRegulamento(fundScreen['dadosCadastrais'].link_regulamento)}
                                type='primary'
                                startIcon={<ImportContactsIcon />}>
                                {"Regulamento"}
                            </UnoButton> : null
                    }
                </div>



                {/* <ToggleButtonGroup
                    className={classes.toogleButton}
                    style={{ marginRight: '8px' }}
                    exclusive value={formats} onChange={handleFormat} aria-label="text formatting">
                    <ToggleButton value="12" aria-label="12 meses">
                        {'12 meses'}
                    </ToggleButton>
                    <ToggleButton value="24" aria-label="24 meses">
                        {'24 meses'}
                    </ToggleButton>
                    <ToggleButton value="36" aria-label="36 meses">
                        {'36 meses'}
                    </ToggleButton>
                    <ToggleButton value="48" aria-label="48 meses">
                        {'48 meses'}
                    </ToggleButton>

                </ToggleButtonGroup> */}

            </div>
            {
                fundScreen ?
                    <div className={classes.main}>
                        <div className={classes.generalInformations}>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={12}>
                                    <Paper className={[classes.gridPaper, 'mainInfos'].join(' ')} elevation={1}>
                                        <Typography className={'title'}>
                                            {'Características'}
                                        </Typography>
                                        <br />

                                        <MyInfo
                                            outerlabel={'CNPJ:'}
                                            direction={'horizontal'}
                                            label={fundScreen.cnpj}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'CNPJ Gestor:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].manager_cnpj}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Gestão:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].manager_name}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'CNPJ Administrador:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].administrator_cnpj}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Administrador:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].administrator_name}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Taxa de Administração:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].administration_tax + "%"}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Taxa de administração (Máxima):'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].max_administration_tax ?
                                                fundScreen.comdinheiro['dadosCadastrais'].max_administration_tax + "%" : "-"}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Benchmark:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['realBenchmark'] ? fundScreen.comdinheiro['realBenchmark'] : "-"}>
                                        </MyInfo>
                                        {/* <MyInfo outerlabel={'Código de referência:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro.ref_cod}>
                                        </MyInfo> */}
                                        <MyInfo outerlabel={'Enquadramento:'}
                                            direction={'horizontal'}
                                            label={fundScreen.device_abbreviation}>
                                        </MyInfo>
                                    </Paper>

                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>
                                    <Paper className={[classes.gridPaper, 'mainInfos'].join(' ')} elevation={1}>
                                        <Typography className={'title'}>
                                            {'Informações Operacionais'}
                                        </Typography>
                                        <br />

                                        <MyInfo outerlabel={'Início fundo:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].fund_init}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Patrimônio líquido:'}
                                            direction={'horizontal'}
                                            label={applyMaskAmount(fundScreen.comdinheiro['dadosCadastrais'].pl_anbima, true)}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Núm. Cotistas:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].num_cotistas}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Aplicação mínima:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].min_application ?
                                                applyMaskAmount(fundScreen.comdinheiro['dadosCadastrais'].min_application, true) : '-'}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Aplicação adicional:'}
                                            direction={'horizontal'}
                                            label={applyMaskAmount(fundScreen.comdinheiro['dadosCadastrais'].aditional_application, true)}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Saldo mínimo:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].min_balance ?
                                                applyMaskAmount(fundScreen.comdinheiro['dadosCadastrais'].min_balance, true) : '-'}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Resgate Mínimo:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].min_rescue ?
                                                applyMaskAmount(fundScreen.comdinheiro['dadosCadastrais'].min_rescue, true) : '-'}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Conversão de cota para Aplicação:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].conversion_application_quota}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Conversão de cota para Resgate:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].conversion_rescue_quota}>
                                        </MyInfo>
                                        <MyInfo outerlabel={'Disponibilização dos Recursos Resgatados:'}
                                            direction={'horizontal'}
                                            label={fundScreen.comdinheiro['dadosCadastrais'].disponibilization}>
                                        </MyInfo>
                                    </Paper>
                                </Grid>

                                {
                                    fundScreen && fundScreen.description != null && fundScreen.description != "" ?
                                        <Grid item lg={12} md={12} sm={12}>

                                            <Paper className={[classes.gridPaper].join(' ')} elevation={1}>

                                                <Typography variant={'body1'} className={'title'} style={{ paddingBottom: '24px' }}>Resumo do fundo</Typography>

                                                <Typography variant={'body2'} style={{ textAlign: 'justify' }}>
                                                    {fundScreen.description}
                                                </Typography>

                                            </Paper>
                                        </Grid>
                                        : null
                                }


                                <Grid item lg={12} md={12} sm={12}>
                                    <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}>

                                        <Typography variant={'body1'} className={'title'}>Tabela de retornos</Typography>

                                        <TableContainer>
                                            <Table className={classes.tableBlade} size={'small'}>
                                                <colgroup>
                                                    <col width="12%" />
                                                    {/* <col width="5%" /> */}
                                                    <col width="6%" />
                                                    <col width="6%" />
                                                    <col width="6%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                    <col width="5%" />
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Retorno(%)</TableCell>
                                                        {/* <TableCell align="center">Início</TableCell> */}
                                                        <TableCell align="center">Ano</TableCell>
                                                        <TableCell align="center">{'12'}<br />{'meses'}</TableCell>
                                                        <TableCell align="center">{'24'}<br />{'meses'}</TableCell>
                                                        <TableCell align="center">{'36'}<br />{'meses'}</TableCell>
                                                        {fundScreen.last12LabelsMonths.map((row, index) => (

                                                            <TableCell key={'h-return-table-' + index} align="center">{row}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    <TableRow key={'blade-fund-t1'}>

                                                        <TableCell align="left">{"Fundo"}</TableCell>
                                                        {/* <TableCell align="center">{applyMaskAmount(row.init)}</TableCell> */}

                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund["Ano_atual"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund["12_meses"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund["24_meses"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund["36_meses"])}</TableCell>

                                                        {fundScreen.last12LabelsMonths.map((labelMonth, index) => (

                                                            <TableCell align="center">
                                                                {
                                                                    index == fundScreen.last12LabelsMonths.length - 1 ?
                                                                        applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund["Mes_atual"])
                                                                        :
                                                                        applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.fund[labelMonth])
                                                                }
                                                            </TableCell>

                                                        ))}

                                                    </TableRow>
                                                    <TableRow key={'blade-benchmark-t1'}>
                                                        <TableCell align="left">{fundScreen.comdinheiro['realBenchmark'] ? fundScreen.comdinheiro['realBenchmark'] : "-"}</TableCell>
                                                        {/* <TableCell align="center">{applyMaskAmount(row.init)}</TableCell> */}
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark["Ano_atual"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark["12_meses"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark["24_meses"])}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark["36_meses"])}</TableCell>

                                                        {fundScreen.last12LabelsMonths.map((labelMonth, index) => (
                                                            <TableCell align="center">
                                                                {

                                                                    index == fundScreen.last12LabelsMonths.length - 1 ?
                                                                        applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark["Mes_atual"])
                                                                        :
                                                                        applyMaskAmountComdinheiro(fundScreen['comdinheiro'].returnTable.benchmark[labelMonth])

                                                                }
                                                            </TableCell>
                                                        ))}

                                                    </TableRow>

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>

                                    <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}>

                                        <Typography variant={'body1'} className={'title'}>Tabela de Risco x Retorno</Typography>

                                        <TableContainer>
                                            <Table className={classes.tableBlade} size={'small'}>
                                                <colgroup>
                                                    <col width="15%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    <col width="9.44%" />
                                                    {/* <col width="8.1%" />
                                                    <col width="8.1%" /> */}
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Risco x Retorno (12m)</TableCell>
                                                        <TableCell align="center">Cota</TableCell>
                                                        <TableCell align="center">PL</TableCell>
                                                        <TableCell align="center">Volatilidade</TableCell>
                                                        <TableCell align="center">Perda máxima</TableCell>
                                                        <TableCell align="center">VaR</TableCell>
                                                        <TableCell align="center">Índice Sharpe</TableCell>
                                                        <TableCell align="center">Retorno Máximo</TableCell>
                                                        <TableCell align="center">Retorno Mínimo</TableCell>
                                                        <TableCell align="center">Retorno Médio</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={'blade-fund-risco-retorno-fund'}>
                                                        <TableCell align="left">{"Fundo"}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.cota.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.pl.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.volatilidade.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.perda_maxima.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.var.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.indice_sharpe.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_maximo.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_minimo.fund)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_medio.fund)}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'blade-fund-risco-retorno-benchmark'}>
                                                        <TableCell align="left">{fundScreen.comdinheiro['realBenchmark'] ? fundScreen.comdinheiro['realBenchmark'] : "-"}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.cota.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.pl.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.volatilidade.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.perda_maxima.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.var.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.indice_sharpe.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_maximo.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_minimo.benchmark)}</TableCell>
                                                        <TableCell align="center">{applyMaskAmountComdinheiro(fundScreen['comdinheiro'].riscoRetorno.retorno_medio.benchmark)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                                {
                                    dataRetornosChart ?

                                        <Grid item lg={6} md={6} sm={12}>

                                            <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}>

                                                <div className={classes.divChart}>
                                                    <div className={'chartHeader'}>

                                                        <Typography variant={'body1'} className={'title'}>Fundo x Benchmark</Typography>
                                                        <div className={'right'}>
                                                            <MySelect
                                                                style={{ marginRight: '8px' }}
                                                                value={typeChartSelected}
                                                                verysmall
                                                                onChange={(e) => handleChangeTypeChartPeriod(e.target.value)}
                                                                options={typesChart.map((row, index) => (

                                                                    <option key={'type-chart-' + index} value={index}>{row}</option>

                                                                ))}
                                                            />
                                                            {/* <MySelect
                                                                value={periodChartSelected}
                                                                verysmall
                                                                onChange={(e) => handleChangeChartPeriod(e.target.value)}
                                                                options={periods.map((row, index) => (

                                                                    <option key={'chart-period-' + index} value={index}>{row}</option>

                                                                ))}
                                                            /> */}
                                                        </div>
                                                    </div>


                                                    <div className={'chartWrapper'}>
                                                        <Bar data={dataRetornosChart} options={optionsCompareChart} />
                                                    </div>

                                                </div>
                                            </Paper>
                                        </Grid>

                                        : null
                                }

                                {
                                    dataPatrimonioChart ?

                                        <Grid item lg={6} md={6} sm={12}>

                                            <Paper className={[classes.gridPaperTable].join(' ')} elevation={1}>

                                                <div className={classes.divChart}>
                                                    <div className={'chartHeader'}>

                                                        <Typography variant={'body1'} className={'title'}>Evolução do Patrimônio (Em Milhões)</Typography>
                                                        <div className={'right'}>
                                                            <MySelect
                                                                value={periodChartPatrimonioSelected}
                                                                verysmall
                                                                onChange={(e) => handleChangeChartPatrimonioPeriod(e.target.value)}
                                                                options={periods.map((row, index) => (

                                                                    <option key={'chart-period-' + index} value={index}>{row}</option>

                                                                ))}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className={'chartWrapper'}>
                                                        <Bar data={dataPatrimonioChart} options={optionsChartPatrimonio} />
                                                    </div>

                                                </div>
                                            </Paper>
                                        </Grid>

                                        : null
                                }

                            </Grid>
                        </div>


                    </div>
                    : null
            }

        </div >
    );
}
