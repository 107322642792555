import { normalizeAccounts } from '../controllers/AccountController';
import API from './config'

export async function checkUserAlreadyRated(userId) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        const response = await API.get('/user_feedback/checkUserAlreadyRated/' + userId, config)

        return response.data
            && response.data.rows
            && response.data.rows.length ? true : false;

    } catch (error) {

        console.log("ERROR: ", error.response)

        return null;
    }

}


export async function saveUserRate(userId, rating, sugestion) {

    let config = {
        headers: {
            'x-access-token': localStorage.getItem('token'),
        }
    }

    try {

        console.log("SUGESTION: ", sugestion);

        const response = await API.post('/user_feedback/saveUserRate', {
            user_id: userId,
            rating: rating,
            sugestion: sugestion
        }, config)

        return { success: true };

    } catch (error) {

        console.log("ERROR: ", error.response)

        return { success: false };
    }

}

